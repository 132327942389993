import axios from 'axios';
import jwtDecode from 'jwt-decode';
import type { Dispatch } from 'react';

import type { IJWTToken, IPermissionToken } from '../slices/authSlice';
import { setPermissions } from '../slices/authSlice';

interface RefreshResponse {
    data?: {
        data?: {
            accessToken: string;
            refreshToken: string;
            permissionToken: string;
            isAdminToken: string;
        };
    };
}

export const getNewTokens = async (
    refreshToken: string | null,
): Promise<{ refreshToken: string; accessToken: string }> => {
    if (!refreshToken || typeof refreshToken !== 'string') {
        throw new Error('errors.refreshTokenMustBeString');
    }
    const response: RefreshResponse = await axios.post('/auth/refresh', { refreshToken });
    if (
        response.data?.data?.refreshToken &&
        response.data.data.accessToken &&
        response.data.data.permissionToken &&
        response.data.data.isAdminToken
    ) {
        localStorage.setItem('access_token', response.data.data.accessToken);
        localStorage.setItem('refresh_token', response.data.data.refreshToken);
        localStorage.setItem('permission_token', response.data.data.permissionToken);
        localStorage.setItem('is_admin_token', response.data.data.isAdminToken);

        return {
            refreshToken: response.data.data.refreshToken,
            accessToken: response.data.data.accessToken,
        };
    } else {
        throw new Error('errors.noRefreshToken');
    }
};

export const isRefreshTokenExpired = () => {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
        const refreshTokenDecoded: IJWTToken = jwtDecode(refreshToken);
        return refreshTokenDecoded.exp * 1000 < Date.now();
    }
};

export const refreshPermissionsFromPermissionToken = (dispatch: Dispatch<unknown>) => {
    const permissionToken: string | null = localStorage.getItem('permission_token');
    if (permissionToken) {
        const permissionTokenDecoded: IPermissionToken = jwtDecode(permissionToken);
        dispatch(setPermissions(permissionTokenDecoded.permissions));
    }
};
