import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { EngagementMatching } from '../components/BudgetLinesMatchingEngagementsModal';

import type { RootState } from '../store';
import { refreshPermissionsFromPermissionToken } from '../utils/tokens';
import type { PaginatedPayload, QueryParams, ServerError, SimulationStatus } from './common';
import { errorHandler } from './common';
import type { LabelValue } from './labelSlice';
import type { IOperationOrganization, IOrganization } from './organizationSlice';
import type { TvaCode, TvaCodeFormData } from './tvaCodeSlice';

export type Operation = {
    id: number;
    internalNumber: string;
    label: string;
    longLabel: string;
    description: string;
    totalBudgetAmountTtc: number;
    status:
        | 'preliminary_studies'
        | 'in_conception'
        | 'in_progress'
        | 'completed'
        | 'archived'
        | 'canceled';
    clientId: string;
    clientOrganization?: IOrganization;
    projectOwnerId: string;
    projectOwnerOrganization?: IOrganization;
    delegateProjectOwnerId: string | null;
    delegateProjectOwnerOrganization?: IOrganization;
    constructionSiteName: string | null;
    address1: string | null;
    address2: string | null;
    postalCode: string | null;
    city: string | null;
    country: string | null;
    fiduciaryEngagementNumberMandatory: boolean;
    createdAt: string;
    updatedAt: string;
    finishedAt: string;
    labelValues: LabelValue[];
    plannedStartingDate: string;
    lastSimulationAt: string;
    lastEngagementsUpdatedAt: string | null;
    hasEngagement?: boolean;
    groupCodeSequenceId: number;
    tvaCodes: TvaCode[];
    engagementMatching?: EngagementMatching[];
    operationOrganizations?: IOperationOrganization[];
};

export interface OperationFormData {
    id?: number;
    label: string;
    longLabel: string;
    description: string;
    status: Operation['status'];
    clientId: string;
    projectOwnerId: string;
    delegateProjectOwnerId: string | null;
    constructionSiteName: string | null;
    address1: string | null;
    address2: string | null;
    postalCode: string | null;
    city: string | null;
    country: string | null;
    labelValues: LabelValue[];
    plannedStartingDate: string | null;
    groupCodeSequenceId: number;
    tvaCodes: TvaCodeFormData[];
}

type OperationPayload = PaginatedPayload<Operation>;

type OperationState = {
    loading: boolean;
    error: string | null;
    operationIdAdded: number | null;
    operationsById: Record<number, Operation>;
    operationsByPage: Record<number, number[] | null>;
    queryString: string;
    totalOperations: number;
    // this is used to keep track wether we are on organizations/:id/operations or /operations
    // and reset state if changing from one to the other
    currentOrganizationId: string | undefined;
    simulationStatusByOperationId: Record<number, SimulationStatus>;
    getOperationsLoading: boolean;
    operationIdDeleted: Operation['id'] | null;
};

export const initialState: OperationState = {
    operationsById: {},
    loading: false,
    error: null,
    operationIdAdded: null,
    operationsByPage: {},
    queryString: '',
    totalOperations: 0,
    currentOrganizationId: undefined,
    simulationStatusByOperationId: {},
    getOperationsLoading: false,
    operationIdDeleted: null,
};

export const getOperations = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    OperationPayload & {
        page: number;
        queryString: string;
        organizationId: string | undefined;
    },
    // First argument to the payload creator
    QueryParams,
    {
        rejectValue: ServerError;
    }
>(
    'OPERATION/GETALL',
    async (
        {
            page = 1,
            query,
            match = 'any',
            fields,
            orderBy = 'internal_number',
            order = 'desc',
            pageSize = 12,
            organizationId,
            permissionNeeded,
        },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `&match=${match}`;
            if (query) {
                queryString += `&query=${query}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            if (permissionNeeded) {
                queryString += `&permissionNeeded=${permissionNeeded}`;
            }

            queryString += `&order=${order}`;

            const path = organizationId
                ? `/organizations/${organizationId}/operations?page=${
                      page - 1
                  }&pageSize=${pageSize}${queryString}`
                : `/operations?page=${page - 1}&pageSize=${pageSize}${queryString}`;

            const response = await axios.get<OperationPayload>(path);

            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { ...response.data, page, queryString, organizationId };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey || error.response?.status === 499) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    },
);
export const getOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Operation,
    // First argument to the payload creator
    Operation['id'],
    {
        rejectValue: ServerError;
    }
>('OPERATION/GET', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: Operation }>(`/operations/${id}`);

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});
export const createOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Operation,
    // First argument to the payload creator
    OperationFormData,
    {
        rejectValue: ServerError;
    }
>('OPERATION/POST', async (operationValues, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post<{ data?: Operation; token: string }>(
            '/operations',
            operationValues,
        );
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        if (response.data.token) {
            const newPermissionToken = response.data.token;
            localStorage.setItem('permission_token', newPermissionToken);
            refreshPermissionsFromPermissionToken(dispatch);
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const updateOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Operation,
    // First argument to the payload creator
    OperationFormData,
    {
        rejectValue: ServerError;
    }
>('OPERATION/PUT', async (operationValues, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: Operation }>(
            `/operations/${operationValues.id}`,
            operationValues,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const deleteOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { id: Operation['id'] },
    // First argument to the payload creator
    { id: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('OPERATION/DELETE', async ({ id }, { rejectWithValue }) => {
    try {
        await axios.delete<{ data?: Operation['id'] }>(`/operations/${id}`);
        return { id };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getOperationHasEngagement = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { hasEngagement: boolean; operationId: Operation['id'] },
    // First argument to the payload creator
    { operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('GET/OPERATION_HAS_ENGAGEMENT', async ({ operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/operations/${operationId}/operation-has-engagements`);

        return { hasEngagement: response.data.data, operationId };
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getOperationSimulation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { status: SimulationStatus; operationId: Operation['id'] },
    // First argument to the payload creator
    { operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('OPERATION_SIMULATION/GET', async ({ operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/operations/${operationId}/simulation`);

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getOperationEngagementsMatch = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { id: Operation['id']; engagementMatching: EngagementMatching[] },
    // First argument to the payload creator
    { id: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('OPERATION_ENGAGEMENTS_MATCH/GET', async ({ id }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: EngagementMatching[] }>(
            `/operations/${id}/engagements-match`,
        );
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return { engagementMatching: response.data.data, id };
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updateOperationEngagementsMatch = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    void,
    // First argument to the payload creator
    { id: Operation['id']; engagementMatching: EngagementMatching[] },
    {
        rejectValue: ServerError;
    }
>('OPERATION_ENGAGEMENTS_MATCH/PUT', async ({ id, engagementMatching }, { rejectWithValue }) => {
    try {
        await axios.put(`/operations/${id}/engagements-match`, engagementMatching);
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const slice = createSlice({
    name: 'operation',
    initialState,
    reducers: {
        resetOperationStatus(state, action) {
            state.simulationStatusByOperationId[action.payload.operationId] = null;
        },
    },
    extraReducers(builder) {
        // Get all
        builder.addCase(getOperations.pending, (state, { meta }) => {
            if (meta.arg.organizationId !== state.currentOrganizationId) {
                state.currentOrganizationId = meta.arg.organizationId;
                state.operationsByPage = {};
            }
            state.loading = true;
            state.error = null;
            state.getOperationsLoading = true;
        });
        builder.addCase(getOperations.fulfilled, (state, { payload }) => {
            if (payload.queryString !== state.queryString) {
                state.queryString = payload.queryString;
                state.operationsByPage = {};
            }
            if (payload.data && payload.data.length > 0) {
                payload.data.forEach((item: Operation) => {
                    state.operationsById[item.id] = item;
                });
                if (payload.page) {
                    state.operationsByPage[payload.page] = payload.data.map(
                        (item: Operation) => item.id,
                    );
                }
            } else {
                state.operationsByPage = {};
            }
            state.totalOperations = payload.total;

            state.loading = false;
            state.getOperationsLoading = false;
        });
        builder.addCase(getOperations.rejected, errorHandler());
        // Get one
        builder.addCase(getOperation.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOperation.fulfilled, (state, { payload }) => {
            state.operationsById[payload.id] = {
                ...state.operationsById[payload.id],
                ...payload,
            };
            state.loading = false;
        });
        builder.addCase(getOperation.rejected, errorHandler());
        // Create
        builder.addCase(createOperation.pending, (state) => {
            state.loading = true;
            state.operationIdAdded = null;
            state.error = null;
        });
        builder.addCase(createOperation.fulfilled, (state, { payload }) => {
            state.operationsById[payload.id] = payload;
            state.operationIdAdded = payload.id;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(
            createOperation.rejected,
            errorHandler((state, action) => {
                state.operationIdAdded = null;
            }),
        );
        // Update
        builder.addCase(updateOperation.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateOperation.fulfilled, (state, { payload }) => {
            state.operationsById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateOperation.rejected, errorHandler());
        // Get operation has engagement
        builder.addCase(getOperationHasEngagement.pending, (state, action) => {
            state.error = null;
        });
        builder.addCase(getOperationHasEngagement.fulfilled, (state, { payload, meta }) => {
            state.operationsById[payload.operationId] = {
                ...state.operationsById[payload.operationId],
                hasEngagement: payload.hasEngagement,
            };
            state.error = null;
        });
        builder.addCase(getOperationHasEngagement.rejected, errorHandler());
        // Get simulation status
        builder.addCase(getOperationSimulation.pending, (state, action) => {
            state.error = null;
        });
        builder.addCase(getOperationSimulation.fulfilled, (state, { payload, meta }) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- want to go through the condition even if null
            if (payload.status && payload.status !== null) {
                state.simulationStatusByOperationId[payload.operationId] = payload.status;
            } else {
                state.simulationStatusByOperationId[meta.arg.operationId] = null;
            }
            state.error = null;
        });
        builder.addCase(getOperationSimulation.rejected, errorHandler());
        // Get Operation Engagement Matching
        builder.addCase(getOperationEngagementsMatch.pending, (state) => {
            state.error = null;
        });
        builder.addCase(getOperationEngagementsMatch.fulfilled, (state, { payload }) => {
            state.operationsById[payload.id].engagementMatching = payload.engagementMatching;
            state.error = null;
        });
        builder.addCase(getOperationEngagementsMatch.rejected, errorHandler());
        // Update Operation Engagement Matching
        builder.addCase(updateOperationEngagementsMatch.pending, (state) => {
            state.error = null;
        });
        builder.addCase(updateOperationEngagementsMatch.fulfilled, (state, { payload }) => {
            state.error = null;
        });
        builder.addCase(updateOperationEngagementsMatch.rejected, errorHandler());
        builder.addCase(deleteOperation.pending, (state) => {
            state.loading = true;
            state.operationIdDeleted = null;
            state.error = null;
        });
        builder.addCase(deleteOperation.fulfilled, (state, { payload }) => {
            Object.entries(state.operationsByPage).forEach(([pageId, ids]) => {
                if (ids?.includes(payload.id)) {
                    state.operationsByPage[Number(pageId)]?.splice(ids.indexOf(payload.id), 1);
                }
            });
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.operationsById[payload.id];
            state.loading = false;
            state.operationIdDeleted = payload.id;
        });
        builder.addCase(deleteOperation.rejected, errorHandler());
    },
});

export const getOperationName = (operation: Operation | null) => {
    let name = null;
    if (operation) {
        name = operation.label;
    }
    return name;
};

export const selectError = (state: RootState) => state.operation.error;
export const selectOperationsByPage = (page: number) => (state: RootState) =>
    state.operation.operationsByPage[page]?.map(
        (id: number) => state.operation.operationsById[id],
    ) ?? [];
export const selectOperation =
    (id: Operation['id']) =>
    (state: RootState): Operation | undefined =>
        state.operation.operationsById[id];
export const selectOperations = (state: RootState) => Object.values(state.operation.operationsById);
export const selectGetOperationsLoading = (state: RootState) =>
    state.operation.getOperationsLoading;
export const selectTotalOperations = (state: RootState) => state.operation.totalOperations;
export const selectOperationIdAdded = (state: RootState) => state.operation.operationIdAdded;
export const selectIsLoading = (state: RootState) => state.operation.loading;
export const selectOperationName =
    (id: Operation['id'] | undefined) =>
    (state: RootState): string | null => {
        let name = null;
        if (id) {
            const operation = selectOperation(id)(state);
            if (operation?.label) {
                name = operation.label;
            }
        }
        return name;
    };
export const selectOperationHasEngagement =
    (id: Operation['id'] | undefined) =>
    (state: RootState): boolean =>
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
        id && state.operation.operationsById[id]
            ? Boolean(state.operation.operationsById[id].hasEngagement)
            : false;
export const selectOperationInternalNumber =
    (id: Operation['id'] | undefined) =>
    (state: RootState): string | null => {
        let internalNumber = null;
        if (id) {
            const operation = selectOperation(id)(state);
            if (operation?.internalNumber) {
                internalNumber = operation.internalNumber;
            }
        }
        return internalNumber;
    };
export const selectOperationSimulation = (operationId: number | undefined | null) =>
    function (state: RootState) {
        if (operationId && state.operation.simulationStatusByOperationId[operationId]) {
            return state.operation.simulationStatusByOperationId[operationId];
        }
        return null;
    };
export const selectOperationEngagementMatching =
    (id: Operation['id']) =>
    (state: RootState): EngagementMatching[] =>
        state.operation.operationsById[id].engagementMatching ?? [];
export const selectOperationIdDeleted = (state: RootState) => state.operation.operationIdDeleted;

// Actions added into the `reducers` part
export const { resetOperationStatus } = slice.actions;

export default slice.reducer;
