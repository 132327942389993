import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Icon from './Icon';
import Text from './Text';

import { colors } from '../constants/colors';

const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${colors.other.white};
    padding: 0.5rem;
`;

const PagesHint = styled(Text)`
    margin: auto 1.125rem;
    flex: 1 1 40%;
    text-align: right;
`;

const ListPart = styled.div`
    display: flex;
    flex: 1 1 60%;
`;

type ItemProps = {
    selected?: boolean;
    style?: React.CSSProperties;
};

const Item = styled.div<ItemProps>`
    display: flex;
    align-items: center;
    width: 2rem;
    height: 2rem;
    font-family: Rubik;
    justify-content: center;
    background-color: ${(props) => (props.selected ? colors.blue.B400 : null)};
    color: ${(props) => (props.selected ? colors.other.white : colors.blue.B400)};
    border-radius: 0.1875rem;
    cursor: pointer;
`;

export type Props = {
    nbPagesMax?: number;
    pageNeighbours?: number;
    // For a controlled component
    currentPage?: number;
    initialPage?: number;
    nbItemsPerPage?: number;
    nbItemsTotal: number;
    onPageNumberClicked: (param: number) => void;
    pageUrl: string;
    shouldChangePageUrl?: boolean;
};

const Pagination: React.FC<Props> = ({
    currentPage,
    initialPage = 1,
    nbItemsTotal,
    nbItemsPerPage = 12,
    nbPagesMax,
    pageNeighbours = 2,
    onPageNumberClicked,
    pageUrl,
    shouldChangePageUrl = true,
    ...rest
}) => {
    const range = (from: number, to: number, step = 1) => {
        let i = from;
        const rangeArray = [];
        while (i <= to) {
            rangeArray.push(i);
            i += step;
        }
        return rangeArray;
    };

    let nbPages: number = Math.ceil(nbItemsTotal / nbItemsPerPage);
    if (nbPagesMax && nbPagesMax < Math.ceil(nbItemsTotal / nbItemsPerPage)) {
        nbPages = nbPagesMax;
    }

    const generateList = () => {
        /**
         * totalNumbers: the total page numbers to show on the control
         */
        const totalNumbers = pageNeighbours * 2 + 1;
        if (nbPages > totalNumbers) {
            const startPage = Math.max(2, pageSelected - pageNeighbours);
            const endPage = Math.min(nbPages - 1, pageSelected + pageNeighbours);
            let pages = range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = nbPages - endPage > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) ... {5 6} [7] {8 9} (10)
                case hasLeftSpill && !hasRightSpill: {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [0, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} ... (10)
                case !hasLeftSpill && hasRightSpill: {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, 0];
                    break;
                }

                // handle: (1) ... {4 5} [6] {7 8} ... (10)
                case hasLeftSpill && hasRightSpill:
                default: {
                    pages = [0, ...pages, 0];
                    break;
                }
            }
            return [1, ...pages, nbPages];
        }
        return range(1, nbPages);
    };

    const generateText = () => {
        const first: number = nbItemsPerPage * (pageSelected - 1) + 1;
        let last: number = nbItemsPerPage * pageSelected;
        let total: number = nbItemsTotal;
        let ofMore = false;
        if (nbPagesMax && nbItemsTotal > nbItemsPerPage * nbPagesMax) {
            total = nbItemsPerPage * nbPagesMax;
            ofMore = true;
        }
        if (nbItemsPerPage * pageSelected > nbItemsTotal) {
            last = nbItemsTotal;
        }
        return ofMore
            ? String(
                  t('paginationMore', {
                      first,
                      last,
                      total,
                  }),
              )
            : String(
                  t('pagination', {
                      first,
                      last,
                      total,
                  }),
              );
    };

    const { t } = useTranslation();
    const [pageSelected, setPageSelected] = useState<number>(initialPage);
    const elemList = generateList();
    const text = generateText();
    const navigate = useNavigate();

    // For a controlled behaviour
    useEffect(() => {
        if (currentPage) {
            setPageSelected(currentPage);
        }
    }, [currentPage]);

    const handleChangePage = (param: string | number) => {
        let page: number = pageSelected;
        if (param === '+') {
            page += 1;
        } else if (param === '-') {
            page -= 1;
        } else {
            page = Number(param);
        }
        setPageSelected(page);
        onPageNumberClicked(page);
        if (shouldChangePageUrl) {
            navigate(`?page=${page}`);
        }
    };

    return (
        <Container {...rest}>
            <PagesHint font="Rubik" size="0.75rem" color={colors.neutral.N400}>
                {text}
            </PagesHint>
            <ListPart>
                <Item onClick={() => pageSelected !== 1 && handleChangePage('-')}>
                    <Icon
                        name="ArrowBackIos"
                        color={String(pageSelected === 1 ? colors.neutral.N300 : colors.blue.B400)}
                        width="1rem"
                    />
                </Item>
                {elemList.map((elem: number, index: number) => (
                    <Item
                        key={elem}
                        data-testid={String(
                            elem === pageSelected ? 'activePageNumber' : 'pageNumber',
                        )}
                        onClick={() => elem !== 0 && handleChangePage(elem)}
                        selected={elem === pageSelected}
                    >
                        {elem === 0 ? '...' : elem.toString()}
                    </Item>
                ))}
                <Item onClick={() => pageSelected !== nbPages && handleChangePage('+')}>
                    <Icon
                        name="ArrowForwardIos"
                        color={String(
                            pageSelected === nbPages ? colors.neutral.N300 : colors.blue.B400,
                        )}
                        width="1rem"
                    />
                </Item>
            </ListPart>
        </Container>
    );
};
export default Pagination;
