import React from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import { useField } from 'formik';

import { parseDateString } from '../utils/date';

import type { Permission } from '../slices/authSlice';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import DateInputField from './DateInputField';
import PermissionAwareDisplay from './PermissionAwareDisplay';

type Props = {
    editable?: boolean;
    permissionsRequired: Permission[];
    name: string;
    label?: string;
    subLabel?: string;
    'data-testid'?: string;
    errorTestId?: string;
    value?: string | null | undefined;
    whiteBackground?: boolean;
    noMarginTop?: boolean;
    isOptional?: boolean;
    isMonthPicker?: boolean;
    style?: React.CSSProperties;
    minAllowedDate?: Date;
    disabled?: boolean;
};

const PermissionAwareDateInputField: React.FC<Props> = ({
    editable,
    name,
    label,
    permissionsRequired,
    noMarginTop,
    value,
    isOptional,
    isMonthPicker,
    minAllowedDate,
    ...props
}) => {
    const { t } = useTranslation();
    const hasRightToEdit = usePermissionsCheck(permissionsRequired);
    const [field] = useField(name);

    const fieldLabel = isOptional ? `${label} ${t('general.optional')}` : label;

    let valueDisplay = '-';
    if (value) {
        valueDisplay = format(parseDateString(value), isMonthPicker ? 'MM/yyyy' : 'dd/MM/yyyy');
    }
    if (field.value) {
        valueDisplay = format(
            parseDateString(field.value),
            isMonthPicker ? 'MM/yyyy' : 'dd/MM/yyyy',
        );
    }
    return hasRightToEdit && editable ? (
        <DateInputField
            {...props}
            name={name}
            label={fieldLabel}
            value={value}
            data-testid={props['data-testid']}
            isMonthPicker={isMonthPicker}
            minAllowedDate={minAllowedDate}
        />
    ) : (
        <PermissionAwareDisplay
            data-testid={props['data-testid']}
            label={fieldLabel}
            value={valueDisplay}
            style={noMarginTop ? { marginTop: 0 } : undefined}
        />
    );
};

export default PermissionAwareDateInputField;
