import type { RefObject, PropsWithChildren } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';

import VerticalTabs from './VerticalTabs';
import GridCol from './GridCol';

const ScrollableSection = styled(GridCol)<{
    topOffset?: string;
}>`
    height: calc(100vh - ${({ topOffset }) => topOffset ?? '17rem'});
    overflow-y: auto;
    padding: 0 1rem;
`;

const ScrollableMenu = styled.div`
    padding: 0 1rem;
    width: 12rem;
`;

export type TabForScroll = {
    text: string;
    ref: RefObject<HTMLDivElement>;
};

type Props = {
    tabs: TabForScroll[];
    selectedTab?: TabForScroll;
    topOffset?: string;
};

const ScrollableSidebarLayout: React.FC<PropsWithChildren<Props>> = ({
    children,
    tabs,
    topOffset,
    ...rest
}) => {
    const [selectedScrollTab, setSelectedScrollTab] = useState<TabForScroll>(tabs[0]);

    // Scroll
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error, @typescript-eslint/ban-ts-comment -- necessary
    // @ts-ignore
    const isIE11 = Boolean(window.msCrypto);

    const scrollToSection = (sectionCardRef: RefObject<HTMLDivElement>) => {
        let scrollableParent = document.querySelector('.scrollable-sidebar-layout-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (sectionCardRef.current && scrollableParent) {
            scrollableParent.scrollTop = sectionCardRef.current.offsetTop;
        }
    };

    const isInView = (element: RefObject<HTMLDivElement>) => {
        const rect = element.current?.getBoundingClientRect();

        let scrollableMainParent = document.querySelector('.scrollable-sidebar-layout-content');

        if (isIE11) {
            scrollableMainParent = scrollableMainParent?.childNodes[0] as Element;
        }

        return (
            rect &&
            rect.top >= 0 &&
            scrollableMainParent &&
            rect.bottom <= scrollableMainParent.clientHeight
        );
    };

    const handleOnScroll = () => {
        let previousInView = false;
        tabs.forEach((tab: TabForScroll, index: number) => {
            if (isInView(tab.ref) && !previousInView) {
                setSelectedScrollTab(tabs[index]);
                previousInView = true;
            } else {
                previousInView = false;
            }
        });
    };

    const verticalMenuTabs = tabs.map((tab: TabForScroll, index: number) => ({
        text: tab.text,
        onClick() {
            setSelectedScrollTab(tab);
            scrollToSection(tab.ref);
        },
        isActive: selectedScrollTab.text === tab.text,
    }));

    return (
        <>
            <ScrollableSection
                className="scrollable-sidebar-layout-content"
                onScroll={handleOnScroll}
                topOffset={topOffset}
            >
                {children}
            </ScrollableSection>
            <ScrollableMenu>
                <VerticalTabs tabs={verticalMenuTabs} style={{ marginLeft: '1.875rem' }} />
            </ScrollableMenu>
        </>
    );
};

export default ScrollableSidebarLayout;
