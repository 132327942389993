import React from 'react';

import { colors } from '../constants/colors';
import { PercentageFormatter } from '../utils/formatters';
import Badge from './Badge';

const OVER_100_PERCENTAGE_COLOR = colors.red.R100;
const OVER_80_PERCENTAGE_COLOR = colors.yellow.Y100;
const NORMAL_PERCENTAGE_BACKGROUND_COLOR = colors.neutral.N75;

const isValidNumber = (value: number) => Number.isFinite(value) && !Number.isNaN(value);

const getPercentageRangeBackgroundColor = (value: number) => {
    if (!isValidNumber(value)) {
        return NORMAL_PERCENTAGE_BACKGROUND_COLOR;
    }

    if (value >= 1) {
        return OVER_100_PERCENTAGE_COLOR;
    } else if (value >= 0.8 && value < 1) {
        return OVER_80_PERCENTAGE_COLOR;
    }
    return NORMAL_PERCENTAGE_BACKGROUND_COLOR;
};

export const getDisplayedPercentage = (value: number): string | number => {
    if (!isValidNumber(value)) {
        return '-';
    }
    return PercentageFormatter.format(value);
};

type Props = {
    value: number;
    style?: React.CSSProperties;
};

/**
 * @param value Number (not yet multiplied by 100) or Infinity
 */
const PercentageBadge: React.FC<Props> = ({ value, style }) => (
    <Badge
        text={getDisplayedPercentage(value)}
        background={getPercentageRangeBackgroundColor(value)}
        textColor={colors.blue.B400}
        fontWeight={400}
        textSize="0.875rem"
        style={style}
        isNumeral
    />
);

export default PercentageBadge;
