import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Style
import { colors } from '../../constants/colors';
import { styles } from '../../constants/styles';

// Utils
import { NumberFormatter, formatNumberWithLeadingZeros } from '../../utils/formatters';

// Slices
import type {
    EngagementInvoiceFormData,
    MarketInvoice,
    MarketInvoiceFormData,
    MarketInvoiceCalculatedData,
} from '../../slices/invoiceSlice';
import type { Permission } from '../../slices/authSlice';

// Components
import Text from '../Text';
import PermissionAwareText from '../PermissionAwareText';

const HeaderRow = styled.div`
    display: flex;
    width: 100%;
    background-color: ${colors.neutral.N75};
    line-height: 1.5rem;
    border-radius: ${styles.borderRadiusSmall};
    margin-left: 0 !important;
    margin-bottom: 0.5rem;
`;

const HeaderText = styled(Text)`
    margin: 0.6rem 0 0.5rem;
    line-height: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: right;
`;

const InfoRowText = styled(Text)`
    margin-bottom: 0.3125rem;
`;

const InfoRow = styled.div<{ borderBottom?: boolean }>`
    display: flex;
    width: 100%;
    margin-bottom: ${({ borderBottom }) => (borderBottom ? '0.5rem' : '0')};
    margin-left: 0 !important;
    border-bottom: ${({ borderBottom }) =>
        borderBottom ? `solid 0.0625rem ${colors.neutral.N75}` : 'none'};
    line-height: 1.5rem;
`;
const InfoRowAmount = styled(InfoRowText)`
    text-align: right;
    padding: 0 1rem;
    font-family: ${styles.numeralsFont};
`;

const InfoRowAmountDiv = styled.div`
    margin-bottom: 0.3125rem;
    text-align: right;
    padding: 0 1rem;
    font-family: ${styles.numeralsFont};
`;

const TotalText = styled(HeaderText)`
    color: ${colors.blue.B500};
`;

const NumberCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.5rem;
    width: 5vw;
    h2,
    h6,
    p {
        padding: 0;
    }
`;

const AmmountCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.5rem;
    width: 13vw;
    h2,
    h6,
    p {
        padding: 0;
    }
`;

const LabelCol = styled.div<{ windowWidth: number }>`
    display: flex;
    flex: 1;
    h2,
    h6,
    p {
        padding: 0;
    }
    // 100vw (Winwdow) - 15rem (Sidebar) - 3*(13vw width + 2*0,5rem padding) (3 AmmountCol)  - 1*5vw (1 NumberCol) - 2*3rem (padding) => 56vw - 24rem
    // if windowWidth > 1375 scroll menu is present so - 12rem width also => 61vw - 36rem
    min-width: calc(56vw ${({ windowWidth }) => (windowWidth > 1375 ? '- 36rem' : '- 24rem')});

    // below grid break point, Sidebar width is reduced to 6rem
    // 100vw (Winwdow) - 6rem (Sidebar) - 3*(13vw width + 2*0,5rem padding) (3 AmmountCol)  - 1*5vw (1 NumberCol) - 2*3rem (padding) => 56vw - 15rem
    @media (max-width: ${styles.gridBreakpoint}) {
        min-width: calc(56vw - 15rem);
    }
    align-items: center;
    padding: 0 0.5rem;
`;

const HorizontalScrollContent = styled.div<{ windowWidth: number }>`
    // 100vw (Winwdow) - 15rem (Sidebar) - 3*(13vw width + 2*0,5rem padding) (3 AmmountCol)  - 1*5vw (1 NumberCol) - 2*3rem (padding) => 56vw - 24rem
    // if windowWidth > 1375 scroll menu is present so - 12rem width also => 61vw - 36rem
    min-width: calc(56vw ${({ windowWidth }) => (windowWidth > 1375 ? '- 36rem' : '- 24rem')});

    // below grid break point, Sidebar width is reduced to 6rem
    // 100vw (Winwdow) - 6rem (Sidebar) - 3*(13vw width + 2*0,5rem padding) (3 AmmountCol)  - 1*5vw (1 NumberCol) - 2*3rem (padding) => 56vw - 15rem
    @media (max-width: ${styles.gridBreakpoint}) {
        min-width: calc(56vw - 15rem);
    }
`;

type Props = {
    invoiceNumber: MarketInvoice['invoiceNumber'] | null;
    formikValues: MarketInvoiceFormData;
    calculatedData: MarketInvoiceCalculatedData;
    windowWidth: number;
    isEditMode: boolean;
    permissionsRequired: Permission[];
};

const MarketInvoicePricesVariation: React.FC<Props> = ({
    invoiceNumber,
    formikValues,
    calculatedData,
    windowWidth,
    isEditMode,
    permissionsRequired,
}) => {
    const { t } = useTranslation();

    const header = (
        <HeaderRow>
            <NumberCol>
                <HeaderText
                    type="H100"
                    style={{ textAlign: 'left', paddingLeft: '0px' }}
                    uppercased
                >
                    {t('invoice.priceVariation.number')}
                </HeaderText>
            </NumberCol>
            <LabelCol windowWidth={windowWidth}>
                <HeaderText
                    type="H100"
                    style={{ paddingLeft: '0px', textAlign: 'left' }}
                    uppercased
                >
                    {t('invoice.priceVariation.engagementLabel')}
                </HeaderText>
            </LabelCol>
            <AmmountCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.priceVariation.currentCumulatedHt')}
                </HeaderText>
            </AmmountCol>
            <AmmountCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.priceVariation.previousCumulatedHt')}
                </HeaderText>
            </AmmountCol>
            <AmmountCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.priceVariation.monthlyAmount')}
                </HeaderText>
            </AmmountCol>
        </HeaderRow>
    );

    const information = (
        <>
            {[...formikValues.engagementInvoices].map(
                (formikInvoice: EngagementInvoiceFormData, index: number) => {
                    const calculatedInvoice: EngagementInvoiceFormData | undefined =
                        calculatedData.engagementInvoices[index];

                    return calculatedInvoice ? (
                        <InfoRow key={calculatedInvoice.id}>
                            <NumberCol>
                                <InfoRowText>
                                    {invoiceNumber &&
                                        formatNumberWithLeadingZeros(invoiceNumber, 3)}
                                </InfoRowText>
                            </NumberCol>
                            <LabelCol windowWidth={windowWidth}>
                                <InfoRowText>{calculatedInvoice.engagementLabel}</InfoRowText>
                            </LabelCol>
                            <AmmountCol>
                                <InfoRowAmountDiv>
                                    <PermissionAwareText
                                        editable={isEditMode}
                                        permissionsRequired={permissionsRequired}
                                        numberType="decimal"
                                        name={`engagementInvoices[${index}].currentCumulatedRevisionsAmountHt`}
                                        type="text"
                                        data-testid={`engagementInvoices[${index}].currentCumulatedRevisionsAmountHt`}
                                        errorTestId={`engagementInvoices[${index}].currentCumulatedRevisionsAmountHt`}
                                        marginTop="0"
                                        whiteBackground
                                    />
                                </InfoRowAmountDiv>
                            </AmmountCol>
                            <AmmountCol>
                                <InfoRowAmount>
                                    {NumberFormatter.format(
                                        calculatedInvoice.previousCumulatedRevisionsAmountHt,
                                    )}
                                </InfoRowAmount>
                            </AmmountCol>
                            <AmmountCol>
                                <InfoRowAmount>
                                    {NumberFormatter.format(calculatedInvoice.revisionsAmountHt)}
                                </InfoRowAmount>
                            </AmmountCol>
                        </InfoRow>
                    ) : null;
                },
            )}
        </>
    );

    const total = (
        <HeaderRow>
            <LabelCol windowWidth={windowWidth}>
                <TotalText type="H500">{t('invoice.priceVariation.total')}</TotalText>
            </LabelCol>
            <AmmountCol>
                <TotalText type="H500" uppercased isNumeral>
                    {NumberFormatter.format(calculatedData.currentCumulatedRevisionsAmountHt)}
                </TotalText>
            </AmmountCol>
            <AmmountCol>
                <TotalText type="H500" uppercased isNumeral>
                    {NumberFormatter.format(calculatedData.previousCumulatedRevisionsAmountHt)}
                </TotalText>
            </AmmountCol>
            <AmmountCol>
                <TotalText type="H500" uppercased isNumeral>
                    {NumberFormatter.format(calculatedData.revisionsAmountHt)}
                </TotalText>
            </AmmountCol>
        </HeaderRow>
    );

    return (
        <HorizontalScrollContent windowWidth={windowWidth}>
            <div style={{ display: 'inline-block' }}>
                {header}
                {information}
                {total}
            </div>
        </HorizontalScrollContent>
    );
};

export default MarketInvoicePricesVariation;
