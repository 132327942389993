import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import type { TableHeaders } from './Table';
import Table from './Table';
import Status from './Status';
import GridCol from './GridCol';
import GridRow from './GridRow';
import ErrorMessage from './ErrorMessage';
import InfoMessage from './InfoMessage';
import type { EmptyStateProps } from './EmptyState';
import EmptyState from './EmptyState';
import CardList from './CardList';

// Assets
import Building from '../assets/images/building_icone.svg';

// Constants & utils
import { colors } from '../constants/colors';

import type { IOrganization, IOrganizationFormData } from '../slices/organizationSlice';
import { selectError } from '../slices/organizationSlice';
import type { ListViewType } from '../slices/common';
import type { RenderCellMapType } from './TableRow';
import Loader from './Loader';

type Props = {
    organization?: IOrganizationFormData | null;
    organizations: IOrganization[] | null;
    shouldShowList?: boolean;
    createButton?: JSX.Element;
    onOrganizationClick?: (organization: IOrganization | Partial<IOrganization>) => void;
    columnSizes: string;
    tableRowHeight?: string;
    tableHeaders: TableHeaders;
    renderCellMap: RenderCellMapType<IOrganization | Partial<IOrganization>>;
    checkIfDisabled?: (organization: IOrganization) => boolean;
    checkIfSelected?: (organization: IOrganization) => boolean;
    viewType: ListViewType;
    emptyState: EmptyStateProps | null;
    info: string | null;
    searchError: string | null;
    isLoading: boolean;
    isSireneLoading?: boolean;
    searchingOrganizationsForAffectation?: boolean;
};

const OrganizationsList: React.FunctionComponent<Props> = ({
    organization,
    organizations,
    shouldShowList = true,
    createButton,
    onOrganizationClick,
    columnSizes,
    tableRowHeight,
    tableHeaders,
    renderCellMap,
    checkIfDisabled,
    checkIfSelected,
    viewType,
    emptyState,
    info,
    searchError,
    isLoading,
    isSireneLoading = false,
    searchingOrganizationsForAffectation = false,
}) => {
    const { t } = useTranslation();

    const serverError = useSelector(selectError);

    const emptyStateImage: { imageName: EmptyStateProps['imageName'] } = {
        imageName: 'SearchDocument',
    };

    const is403Error = serverError === 'errors.noOrganizationYouHaveAccessTo';

    const newOrganizationHeader = {
        ...tableHeaders,
        createButton: '',
    };

    const renderCellMapNewOrganisation = {
        ...renderCellMap,
        createButton() {
            return createButton;
        },
    };

    const noErrorOrEmptyState = !searchError && !emptyState;
    const noFetching = !isLoading && !isSireneLoading;
    const notFoundOnOurDB = !organizations || organizations.length === 0;

    return (
        <>
            <GridRow>
                <GridCol>
                    {noFetching && !serverError && emptyState ? (
                        <EmptyState
                            {...emptyState}
                            {...emptyStateImage}
                            data-testid={emptyState['data-testid']}
                        />
                    ) : null}
                    {serverError && !is403Error && (
                        <ErrorMessage data-testid="errorServer" translationKey={serverError} />
                    )}

                    {searchingOrganizationsForAffectation && (
                        // eslint-disable-next-line react/jsx-no-useless-fragment -- necessary fragment
                        <>
                            {noFetching && info && (
                                <InfoMessage
                                    text={info}
                                    style={{ marginTop: 0 }}
                                    data-testid="infoMessage"
                                />
                            )}
                        </>
                    )}

                    {!searchingOrganizationsForAffectation && (
                        <>
                            {noFetching && noErrorOrEmptyState && info && (
                                <InfoMessage text={info} data-testid="infoMessage" />
                            )}
                            {noFetching && notFoundOnOurDB && searchError && (
                                <ErrorMessage
                                    data-testid="errorSiret"
                                    translationKey={searchError}
                                />
                            )}
                            {noFetching &&
                                noErrorOrEmptyState &&
                                notFoundOnOurDB &&
                                !organization && (
                                    <EmptyState
                                        data-testid="errorNoOrganizations"
                                        {...emptyStateImage}
                                        titleTranslationKey={
                                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
                                            is403Error && serverError
                                                ? serverError
                                                : 'errors.noOrganizationsTitle'
                                        }
                                        textTranslationKey={
                                            is403Error ? '' : 'errors.noOrganizationsText'
                                        }
                                    />
                                )}
                        </>
                    )}
                </GridCol>
            </GridRow>
            <GridRow>
                <GridCol>
                    {(isLoading || isSireneLoading) && <Loader />}
                    {noFetching && noErrorOrEmptyState && organization && (
                        <Table
                            headers={newOrganizationHeader}
                            rows={[organization] as unknown as IOrganization[]}
                            columnSizes={columnSizes}
                            renderCellMap={renderCellMapNewOrganisation}
                            style={{ background: colors.neutral.N50 }}
                        />
                    )}
                    {shouldShowList && viewType === 'card' && (
                        <CardList
                            data={organizations}
                            mapDataToCardProps={(
                                cardOrganization: IOrganization | Partial<IOrganization>,
                            ) => ({
                                headerTitle: cardOrganization.denomination ?? '',
                                headerSubtitle: `Siret: ${cardOrganization.siret}`,
                                headerTrailingComponent: (
                                    <Status
                                        status={cardOrganization.estActif ? 'active' : 'inactive'}
                                    />
                                ),
                                bodyData: {
                                    [tableHeaders.estPrive as string]: cardOrganization.estPrive
                                        ? t('organization.private')
                                        : t('organization.public'),
                                    [tableHeaders.codePostal as string]: `${cardOrganization.codePostal}, ${cardOrganization.libelleCommune}`,
                                },
                                isTooltipOneLine: true,
                                bodyImageUrl: Building,
                                bodyImageAlt: "Image descriptive de l'entreprise",
                            })}
                            onCardClick={onOrganizationClick}
                        />
                    )}
                    {organizations && shouldShowList && viewType === 'table' && (
                        <Table
                            headers={tableHeaders}
                            rows={organizations}
                            columnSizes={columnSizes}
                            renderCellMap={renderCellMap}
                            onRowClick={onOrganizationClick}
                            checkIfDisabled={checkIfDisabled}
                            checkIfSelected={checkIfSelected}
                            rowHeight={tableRowHeight}
                            shouldCapitalize
                        />
                    )}
                </GridCol>
            </GridRow>
        </>
    );
};

export default OrganizationsList;
