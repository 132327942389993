import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from '../../components/MainLayout';
import Dashboard from '../../components/Dashboard';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { selectUser } from '../../slices/authSlice';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import EmptyState from '../../components/EmptyState';

const EmptyStateContainer = styled.div`
    display: flex;
    height: 100%;
`;

type Props = {
    headerProps: HeaderProps;
};

const OperationDashboard: React.FC<Props> = ({ headerProps }) => {
    const { operationId }: { operationId?: string; organizationId?: string } = useParams();
    const user = useSelector(selectUser);
    const hasRightToReadDashboard = usePermissionsCheck([{ code: 'DASHBOARD_READ', operationId }]);

    return (
        <MainLayout header={<Header {...headerProps} />} smallContentSidePadding>
            {!user && <Loader />}
            {hasRightToReadDashboard ? (
                <Dashboard operationId={operationId} />
            ) : (
                <EmptyStateContainer>
                    <EmptyState titleTranslationKey="dashboard.cannotAccessOperationDashboard" />
                </EmptyStateContainer>
            )}
        </MainLayout>
    );
};

export default OperationDashboard;
