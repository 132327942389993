import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Classes } from '@blueprintjs/core';

import { capitalize } from '../../utils/capitalizeText';
import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import GridRow from '../../components/GridRow';
import GridCol from '../../components/GridCol';
import Pagination from '../../components/Pagination';
import SearchField from '../../components/SearchField';
import type { ButtonProps } from '../../components/Button';
import Button from '../../components/Button';
import EmptyState from '../../components/EmptyState';
import Loader from '../../components/Loader';
import Tooltip from '../../components/Tooltip';
import { colors } from '../../constants/colors';
import type { ListViewType } from '../../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../../slices/common';
import Building from '../../assets/images/building_icone.svg';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import type {
    OperationOrganizationPayload,
    IOperationOrganization,
    AffectationTypeOption,
    OperationOrganizationsQueryParams,
} from '../../slices/organizationSlice';
import {
    selectOrganizationsByOperationIdAndPage,
    getOrganizationsByOperation,
    selectIsLoading,
    selectTotalOrganizations,
    disaffectOrganizationFromOperation,
    selectError,
    resetError,
    selectDisaffectOrganizationToOperationFulfilled,
    getAffectationTypeOptions,
} from '../../slices/organizationSlice';
import { getOperation, selectOperation } from '../../slices/operationSlice';
import Status from '../../components/Status';
import CardList from '../../components/CardList';
import Table from '../../components/Table';
import { usePageNumber } from '../../hooks/usePageNumber';
import AffectOrganizationToOperationModal from '../../components/AffectOrganizationToOperationModal';
import { showFlag } from '../../components/Flag';
import Text from '../../components/Text';
import Modal from '../../components/Modal';
import Popover, { PopoverContent, PopoverItem } from '../../components/Popover';
import Elevation from '../../components/Elevation';
import {
    getUsersByOperation,
    selectTotalUsersAffectedToCurrentOperation,
} from '../../slices/userSlice';
import Icon from '../../components/Icon';
import OptionDropdown from '../../components/dropdown/OptionDropdown';
import { useAppDispatch } from '../../store';

const ViewToggle = styled.div`
    display: flex;
    div:first-child {
        margin: 0 1rem 0 0;
    }
    justify-content: flex-end;
`;

const ContextualMenuIcon = styled(Icon)`
    margin-right: 1rem;
    cursor: pointer;
`;

const ContextualMenuContainer = styled.div`
    display: flex;
    justify-self: flex-end;
    margin-left: auto;
`;

type Props = {
    headerProps: HeaderProps;
};

const OrganizationsByOperation: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const {
        operationId,
    }: {
        operationId?: string;
    } = useParams();

    const hasRightToReadOrganizationsAffectations = usePermissionsCheck([
        {
            code: 'OPERATIONS_ORGANIZATIONS_READ',
            operationId,
        },
    ]);
    const hasRightToAffectOrganizations = usePermissionsCheck([
        {
            code: 'OPERATIONS_ORGANIZATIONS_ADD',
            operationId,
        },
    ]);
    const hasRightToDisaffectOrganizations = usePermissionsCheck([
        {
            code: 'OPERATIONS_ORGANIZATIONS_DELETE',
            operationId,
        },
    ]);
    const hasRightToEditOrganizationsAffectations = usePermissionsCheck([
        {
            code: 'OPERATIONS_ORGANIZATIONS_EDIT',
            operationId,
        },
    ]);
    const pageNumber = usePageNumber();
    const defaultQueryParams = {
        page: pageNumber,
        operationId: Number(operationId),
        shouldOrderByAffectationType: true,
        orderBy: '[organization.denomination]',
        order: '[asc]',
    };
    const [queryParams, setQueryParams] =
        useState<OperationOrganizationsQueryParams>(defaultQueryParams);
    const [selectedAffectationType, setSelectedAffectationType] = useState<
        IOperationOrganization['affectationType'] | 'no_selection'
    >('no_selection');
    const [viewType, setViewType] = useState<ListViewType>(
        localStorage.getItem('organization_list_type') === 'table' ? 'table' : 'card',
    );
    const [isAffectOrganizationToOperationModalOpen, setIsAffectOrganizationToOperationModalOpen] =
        useState<boolean>(false);
    const [
        isDisaffectOrganizationFromOperationPending,
        setIsDisaffectOrganizationFromOperationPending,
    ] = useState<boolean>(false);
    const [operationOrganizationIdToDelete, setOperationOrganizationIdToDelete] = useState<
        OperationOrganizationPayload['id'] | undefined
    >();
    const [isDisaffectOrganizationModalOpen, setIsDisaffectOrganizationModalOpen] =
        useState<boolean>(false);
    const [selectedOperationOrganization, setSelectedOperationOrganization] = useState<
        OperationOrganizationPayload | undefined
    >();

    const totalOrganizations = useSelector(selectTotalOrganizations);
    const totalUsers = useSelector(selectTotalUsersAffectedToCurrentOperation);
    const isLoading = useSelector(selectIsLoading);
    const operation = useSelector(selectOperation(Number(operationId)));
    const serverError = useSelector(selectError);
    const operationOrganizations = useSelector(
        selectOrganizationsByOperationIdAndPage(operationId, queryParams.page),
    ) as OperationOrganizationPayload[];
    const disaffectOrganizationFromOperationFulfilled = useSelector(
        selectDisaffectOrganizationToOperationFulfilled,
    );
    const [openedPopOverOperationOrganizationId, setOpenedPopOverOperationOrganizationId] =
        useState<number | null>();

    const ContextualMenuContainerRef = useRef<HTMLDivElement>(null);
    const popoverMenuRef = useRef<HTMLDivElement>(null);

    const handleClickOutsideContextualMenuIcon = (event: MouseEvent) => {
        const target = event.target as Node;
        if (
            ContextualMenuContainerRef.current &&
            !ContextualMenuContainerRef.current.contains(target) &&
            popoverMenuRef.current &&
            !popoverMenuRef.current.contains(target)
        ) {
            setOpenedPopOverOperationOrganizationId(null);
        }
    };

    useEffect(() => {
        // https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
        document.addEventListener('mousedown', handleClickOutsideContextualMenuIcon);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideContextualMenuIcon);
        };
    }, []);

    useEffect(() => {
        setQueryParams({ ...queryParams, affectationType: selectedAffectationType });
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on every dependencies changes
    }, [dispatch, selectedAffectationType]);

    useEffect(() => {
        // To avoid dispatching twice (at render and then when updating queryParams because of selectedAffectationType)
        if (queryParams.affectationType) {
            void dispatch(getOrganizationsByOperation(queryParams));
        }
        if (operationId) {
            void dispatch(getOperation(Number(operationId)));
        }
    }, [dispatch, queryParams, operationId, selectedAffectationType]);

    useEffect(() => {
        void dispatch(getUsersByOperation({ operationId: Number(operationId), page: pageNumber }));
    }, [dispatch, operationId, pageNumber]);

    useEffect(() => {
        if (
            isDisaffectOrganizationFromOperationPending &&
            disaffectOrganizationFromOperationFulfilled
        ) {
            setOperationOrganizationIdToDelete(undefined);
            setIsDisaffectOrganizationFromOperationPending(false);
            showFlag(
                'success',
                t('general.success'),
                t('operationOrganization.successfullyDisaffected'),
            );
            void dispatch(getOrganizationsByOperation(queryParams));
        }
    }, [
        isDisaffectOrganizationFromOperationPending,
        disaffectOrganizationFromOperationFulfilled,
        dispatch,
        queryParams,
        t,
    ]);

    useEffect(() => {
        if (serverError) {
            showFlag('error', '', t(serverError));
            dispatch(resetError());
        }
    }, [dispatch, serverError, t]);

    const computeButtonsOutOfProps = () => {
        const buttons: ButtonProps[] = [];
        if (hasRightToAffectOrganizations) {
            buttons.push({
                'data-testid': 'affectAnOrganization',
                text: t('operationOrganization.affectAnOrganization'),
                aspect: 'primary',
                onClick() {
                    setIsAffectOrganizationToOperationModalOpen(true);
                },
            });
        }

        return buttons;
    };

    // Handle view toggler
    const createViewClickHandler = (type: 'card' | 'table') => () => {
        setViewType(type);
        localStorage.setItem('organizations_list_type', type);
    };

    // Organizations Table(List)
    const headers = {
        denomination: t('organization.list.headers.denomination'),
        siret: t('organization.list.headers.siret'),
        estActif: t('organization.list.headers.estActif'),
        estPrive: t('organization.list.headers.estPrive'),
        codePostal: t('organization.list.headers.codePostal'),
        actions: t('general.actions'),
    };

    const statusLabelStyle: React.CSSProperties = {
        color: colors.blue.B400,
        fontSize: '0.75rem',
        fontWeight: 400,
    };

    const checkIfOperationOrganizationContextualMenuIsOpen = (operationOrganizationId: number) =>
        openedPopOverOperationOrganizationId === operationOrganizationId;

    const handleOpenedPopOverOperationOrganizationId = (operationOrganizationId: number) => {
        setOpenedPopOverOperationOrganizationId(
            checkIfOperationOrganizationContextualMenuIsOpen(operationOrganizationId)
                ? null
                : operationOrganizationId,
        );
    };

    const renderPopOverContent = (
        operationOrganization: OperationOrganizationPayload | Partial<OperationOrganizationPayload>,
    ) => (
        <PopoverContent>
            {hasRightToEditOrganizationsAffectations && (
                <PopoverItem
                    className={Classes.POPOVER_DISMISS}
                    onClick={(e) => {
                        setSelectedOperationOrganization(
                            operationOrganization as OperationOrganizationPayload,
                        );
                        setIsAffectOrganizationToOperationModalOpen(true);
                    }}
                    data-testid="updateAffectation"
                >
                    {t('operationOrganization.updateAffectation')}
                </PopoverItem>
            )}
            {hasRightToDisaffectOrganizations && (
                <PopoverItem
                    className={Classes.POPOVER_DISMISS}
                    onClick={(e) => {
                        if (operationOrganization.id) {
                            setOperationOrganizationIdToDelete(operationOrganization.id);
                            setIsDisaffectOrganizationModalOpen(true);
                        }
                    }}
                    data-testid="disaffectOrganization"
                >
                    {t('operationOrganization.disaffectAnOrganization')}
                </PopoverItem>
            )}
        </PopoverContent>
    );

    const renderCellMap = {
        denomination(
            operationOrganization:
                | OperationOrganizationPayload
                | Partial<OperationOrganizationPayload>,
            key: string,
            shouldCapitalize?: boolean,
        ) {
            const denomination =
                typeof operationOrganization.organization?.denomination === 'string' &&
                shouldCapitalize
                    ? capitalize(operationOrganization.organization.denomination)
                    : operationOrganization.organization?.denomination;
            return (
                <Tooltip
                    content={denomination as string}
                    textStyle={{
                        type: 'H300',
                        style: { overflow: 'hidden', fontWeight: 400, margin: 0 },
                    }}
                    isOneLine
                >
                    {denomination}
                </Tooltip>
            );
        },
        estPrive(
            operationOrganization:
                | OperationOrganizationPayload
                | Partial<OperationOrganizationPayload>,
            key: string,
            shouldCapitalize?: boolean,
        ) {
            if (operationOrganization.organization?.estPublic) {
                return shouldCapitalize
                    ? capitalize(t('organization.public'))
                    : t('organization.public');
            } else if (operationOrganization.organization?.estPrive) {
                return shouldCapitalize
                    ? capitalize(t('organization.private'))
                    : t('organization.private');
            }
            return '';
        },
        codePostal(
            operationOrganization:
                | OperationOrganizationPayload
                | Partial<OperationOrganizationPayload>,
            key: string,
            shouldCapitalize?: boolean,
        ) {
            const ville = operationOrganization.organization?.libelleCommune ?? '';
            const codePostal = operationOrganization.organization?.codePostal ?? '';
            const address = shouldCapitalize
                ? `${codePostal}, ${capitalize(ville)}`
                : `${codePostal}, ${ville}`;
            return (
                <Tooltip
                    content={address}
                    textStyle={{
                        type: 'H300',
                        style: { overflow: 'hidden', fontWeight: 400, margin: 0 },
                    }}
                    isOneLine
                >
                    {address}
                </Tooltip>
            );
        },
        estActif(
            operationOrganization:
                | OperationOrganizationPayload
                | Partial<OperationOrganizationPayload>,
        ) {
            return (
                <Status
                    status={operationOrganization.organization?.estActif ? 'active' : 'inactive'}
                    squareStyle={{ margin: '0.0625rem 0.5rem 0' }}
                    labelStyle={statusLabelStyle}
                />
            );
        },
        siret(
            operationOrganization:
                | OperationOrganizationPayload
                | Partial<OperationOrganizationPayload>,
        ) {
            // Format siret number: 11001401600015 -> 110 014 016 00015
            const siret = operationOrganization.organization?.siret.toString().trim();

            if (!siret) {
                return 'No SIRET';
            }

            return `${siret.slice(0, 3)} ${siret.slice(3, 6)} ${siret.slice(6, 9)} ${siret.slice(
                9,
            )}`;
        },
        actions(
            operationOrganization:
                | OperationOrganizationPayload
                | Partial<OperationOrganizationPayload>,
        ) {
            return (
                <ContextualMenuContainer
                    ref={ContextualMenuContainerRef}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        if (operationOrganization.id) {
                            handleOpenedPopOverOperationOrganizationId(operationOrganization.id);
                        }
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    <Popover
                        isOpen={
                            Boolean(operationOrganization.id) &&
                            checkIfOperationOrganizationContextualMenuIsOpen(
                                operationOrganization.id as number,
                            )
                        }
                        renderGlobalStyle={false}
                    >
                        <ContextualMenuIcon name="MoreHoriz" data-testid="popOverOptions" />
                        <Elevation elevation="default">
                            <span ref={popoverMenuRef}>
                                {renderPopOverContent(operationOrganization)}
                            </span>
                        </Elevation>
                    </Popover>
                </ContextualMenuContainer>
            );
        },
    };

    const affectationTypeOptions: AffectationTypeOption[] = [
        { label: t('operationOrganizations.filterByAffectationType'), value: 'no_selection' },
        ...getAffectationTypeOptions(t),
    ];

    // Transformed this method in a useCallback
    // to avoid an infinite loop in AffectOrganizationToOperationModal
    // when affecting an organization to an operation
    // see: https://stackoverflow.com/a/62601621/4321128
    const onAffectOrganizationToOperationModalClose = useCallback(() => {
        setIsAffectOrganizationToOperationModalOpen(false);
        setSelectedOperationOrganization(undefined);
        void dispatch(getOrganizationsByOperation(queryParams));
    }, [dispatch, queryParams]);

    const handleSearch = (value: string) => {
        if (value === '') {
            setQueryParams({
                page: 1,
                operationId: operationId ? Number(operationId) : undefined,
                affectationType: selectedAffectationType,
                shouldOrderByAffectationType: true,
            });
        } else {
            setQueryParams({
                page: 1,
                query: value,
                match: 'any',
                operationId: operationId ? Number(operationId) : undefined,
                affectationType: selectedAffectationType,
                shouldOrderByAffectationType: true,
            });
        }
    };

    const shouldShowList = operationOrganizations.length > 0;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always falsy
    if (isLoading && !operationOrganizations) {
        return <Loader overlay />;
    }

    return (
        <>
            <Helmet>
                <title>{t('sidebar.organizations')}</title>
            </Helmet>
            <MainLayout
                header={
                    <Header
                        {...headerProps}
                        title={operation ? operation.label : 'sidebar.operation'}
                        tabs={headerProps.tabs?.map((tab, index) => {
                            if (index === 0) {
                                return { ...tab, text: `${tab.text} (${totalOrganizations})` };
                            } else if (index === 1) {
                                return { ...tab, text: `${tab.text} (${totalUsers})` };
                            }
                            return tab;
                        })}
                        buttons={computeButtonsOutOfProps()}
                        style={{
                            marginBottom: '1rem',
                        }}
                        showNavigation
                    />
                }
                footer={
                    totalOrganizations > 0 ? (
                        <Pagination
                            data-testid="pagination"
                            initialPage={queryParams.page}
                            currentPage={queryParams.page}
                            pageNeighbours={2}
                            nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                            nbItemsTotal={totalOrganizations}
                            onPageNumberClicked={(page: number) =>
                                setQueryParams({
                                    ...queryParams,
                                    page,
                                })
                            }
                            pageUrl="organizations"
                        />
                    ) : null
                }
                smallContentSidePadding
            >
                <GridRow
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    <GridCol smallScreen={10} defaultScreen={4}>
                        <SearchField
                            name="operationOrganizationsSearch"
                            data-testid="operationOrganizationsSearch"
                            onSearch={handleSearch}
                        />
                    </GridCol>
                    <GridCol smallScreen={6} defaultScreen={4}>
                        <OptionDropdown<AffectationTypeOption>
                            items={affectationTypeOptions}
                            name="affectationType"
                            initialNameDiplay={
                                affectationTypeOptions.find(
                                    (orderOption) => orderOption.value === selectedAffectationType,
                                )?.label
                            }
                            handleChange={(affectationTypeOption) =>
                                setSelectedAffectationType(affectationTypeOption.value)
                            }
                            data-testid="affectationType"
                            errorTestId="errorCalculatedFrom"
                            style={{ margin: '0', width: '100%' }}
                        />
                    </GridCol>
                    <GridCol smallScreen={2} defaultScreen={4}>
                        <ViewToggle>
                            <Button
                                onClick={createViewClickHandler('table')}
                                iconName="ViewList"
                                isPressed={viewType === 'table'}
                                data-testid="tableViewButton"
                                aspect="onlyIcon"
                                toggle
                            />
                            <Button
                                onClick={createViewClickHandler('card')}
                                iconName="ViewModule"
                                isPressed={viewType === 'card'}
                                data-testid="cardViewButton"
                                aspect="onlyIcon"
                                toggle
                            />
                        </ViewToggle>
                    </GridCol>
                </GridRow>
                {hasRightToReadOrganizationsAffectations &&
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
                    operationOrganizations &&
                    viewType === 'card' &&
                    shouldShowList &&
                    operationOrganizations.length > 0 && (
                        <CardList
                            data={operationOrganizations}
                            mapDataToCardProps={(
                                operationOrganization:
                                    | OperationOrganizationPayload
                                    | Partial<OperationOrganizationPayload>,
                            ) => ({
                                headerTitle: operationOrganization.organization?.denomination ?? '',
                                headerTrailingComponent: (
                                    <Status
                                        status={
                                            operationOrganization.organization?.estActif
                                                ? 'active'
                                                : 'inactive'
                                        }
                                    />
                                ),
                                bodyData: {
                                    [headers.siret]: String(
                                        operationOrganization.organization?.siret,
                                    ),
                                    [headers.estPrive]: operationOrganization.organization?.estPrive
                                        ? t('organization.private')
                                        : t('organization.public'),
                                    [headers.codePostal]: `${operationOrganization.organization?.codePostal}, ${operationOrganization.organization?.libelleCommune}`,
                                },
                                isTooltipOneLine: true,
                                bodyImageUrl: Building,
                                bodyImageAlt: "Image descriptive de l'entreprise",
                            })}
                            onCardClick={(
                                row:
                                    | OperationOrganizationPayload
                                    | Partial<OperationOrganizationPayload>,
                            ) =>
                                navigate(`/organizations/${row.organizationId}`, {
                                    state: { goBackTo: `${location.pathname}${location.search}` },
                                })
                            }
                            cardPopoverMenu={(
                                row:
                                    | OperationOrganizationPayload
                                    | Partial<OperationOrganizationPayload>,
                            ) => renderPopOverContent(row)}
                        />
                    )}
                {hasRightToReadOrganizationsAffectations &&
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
                    operationOrganizations &&
                    viewType === 'table' &&
                    shouldShowList &&
                    operationOrganizations.length > 0 && (
                        <Table
                            headers={headers}
                            rows={operationOrganizations}
                            columnSizes="3fr 3fr 2fr 2fr 4fr 1fr"
                            renderCellMap={renderCellMap}
                            onRowClick={(
                                row:
                                    | OperationOrganizationPayload
                                    | Partial<OperationOrganizationPayload>,
                            ) =>
                                navigate(`/organizations/${row.organizationId}`, {
                                    state: { goBackTo: location.pathname + location.search },
                                })
                            }
                            shouldCapitalize
                        />
                    )}
                {operationOrganizations.length === 0 && (
                    <EmptyState
                        data-testid="errorNoOrganizations"
                        imageName="Lists"
                        titleTranslationKey="errors.noOrganizationsTitle"
                    />
                )}
            </MainLayout>
            {operation && isAffectOrganizationToOperationModalOpen && (
                <AffectOrganizationToOperationModal
                    operationId={operation.id}
                    onModalClosed={onAffectOrganizationToOperationModalClose}
                    operationOrganization={selectedOperationOrganization}
                />
            )}
            {isDisaffectOrganizationModalOpen && (
                <Modal
                    isOpen={isDisaffectOrganizationModalOpen}
                    onCloseButtonPressed={() => {
                        setIsDisaffectOrganizationModalOpen(false);
                    }}
                    buttons={[
                        {
                            text: t('general.cancel'),
                            aspect: 'secondary',
                            'data-testid': 'cancelDelete',
                            onClick() {
                                setIsDisaffectOrganizationModalOpen(false);
                            },
                        },
                        {
                            text: t('general.confirm'),
                            aspect: 'primary',
                            'data-testid': 'confirm',
                            onClick() {
                                if (operationOrganizationIdToDelete) {
                                    setIsDisaffectOrganizationModalOpen(false);
                                    setIsDisaffectOrganizationFromOperationPending(true);
                                    void dispatch(
                                        disaffectOrganizationFromOperation({
                                            operationOrganizationId:
                                                operationOrganizationIdToDelete,
                                            operationId: Number(operationId),
                                        }),
                                    );
                                }
                            },
                        },
                    ]}
                    title={t('operationOrganization.disaffectAnOrganization')}
                    size="small"
                    iconName="Warning"
                    iconColor={colors.yellow.Y400}
                    centerTitle
                >
                    <Text style={{ margin: '0 0 1rem 0' }}>
                        {t('operationOrganization.confirmDisaffectation')}
                    </Text>
                </Modal>
            )}
        </>
    );
};

export default OrganizationsByOperation;
