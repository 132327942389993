import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Style
import { colors } from '../../constants/colors';
import { styles } from '../../constants/styles';

// Utils
import { NumberFormatter, formatNumberWithLeadingZeros } from '../../utils/formatters';

// Slices
import type {
    EngagementInvoiceFormData,
    MarketInvoice,
    MarketInvoiceFormData,
    MarketInvoiceCalculatedData,
} from '../../slices/invoiceSlice';
import type { Permission } from '../../slices/authSlice';

// Components
import Text from '../Text';
import PermissionAwareText from '../PermissionAwareText';
import PercentageBadge from '../PercentageBadge';

const HeaderRow = styled.div`
    display: flex;
    width: 100%;
    background-color: ${colors.neutral.N75};
    line-height: 1.5rem;
    border-radius: ${styles.borderRadiusSmall};
    margin-left: 0 !important;
    margin-bottom: 0.5rem;
`;

const HeaderText = styled(Text)`
    margin: 0.6rem 0 0.5rem;
    line-height: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: right;
`;

const InfoRowText = styled(Text)`
    margin-bottom: 0.3125rem;
`;

const InfoRowTextSmall = styled(InfoRowText)`
    text-align: left
    padding: 0 0.5rem;
`;

const InfoRow = styled.div<{ borderBottom?: boolean }>`
    display: flex;
    width: 100%;
    margin-bottom: ${({ borderBottom }) => (borderBottom ? '0.5rem' : '0')};
    margin-left: 0 !important;
    border-bottom: ${({ borderBottom }) =>
        borderBottom ? `solid 0.0625rem ${colors.neutral.N75}` : 'none'};
    line-height: 1.5rem;
`;

const InfoRowAmount = styled(InfoRowText)`
    font-family: ${styles.numeralsFont};
`;

const InfoRowAmountSmall = styled(InfoRowText)`
    text-align: right;
    padding: 0 0.5rem;
    font-family: ${styles.numeralsFont};
`;

const InfoRowAmountSmallDiv = styled.div`
    margin-bottom: 0.3125rem;
    text-align: right;
    padding: 0 0.5rem;
    font-family: ${styles.numeralsFont};
`;

const TotalText = styled(HeaderText)`
    color: ${colors.blue.B500};
`;

const SubTotalCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.5rem;
    width: 20vw;
    h2,
    h6,
    p {
        padding: 0;
    }
`;

const NumberCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.5rem;
    width: 5vw;
    h2,
    h6,
    p {
        padding: 0;
    }
`;

const PercentCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.5rem;
    width: 11vw;
    h2,
    h6,
    p {
        padding: 0 0.5rem;
    }
`;

const AmmountCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.5rem;
    width: 13vw;
    h2,
    h6,
    p {
        padding: 0;
    }
`;

const LabelCol = styled.div<{ windowWidth: number }>`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    width: 15vh;
    h2,
    h6,
    p {
        padding: 0;
    }
    align-items: center;
    padding: 0 0.5rem;
`;

const HorizontalScrollContent = styled.div<{ windowWidth: number }>`
    // 100vw (winwdow width) - 15rem (sidebar) - 2*3rem (padding) => - 21rem
    // if windowWidth > 1375 scroll menu is present so - 12rem => - 33rem
    width: calc(100vw ${({ windowWidth }) => (windowWidth > 1375 ? '- 33rem' : '- 21rem')});
    @media (max-width: ${styles.gridBreakpoint}) {
        width: calc(100vw - 12rem); // 100vw (winwdow width) - 6rem (sidebar) - 2*3rem (padding)
    }
    overflow-x: scroll;
`;

type Props = {
    invoiceNumber: MarketInvoice['invoiceNumber'] | null;
    formikValues: MarketInvoiceFormData;
    calculatedData: MarketInvoiceCalculatedData;
    windowWidth: number;
    isEditMode: boolean;
    permissionsRequired: Permission[];
};

const MarketInvoiceAvancement: React.FC<Props> = ({
    invoiceNumber,
    formikValues,
    calculatedData,
    windowWidth,
    isEditMode,
    permissionsRequired,
}) => {
    const { t } = useTranslation();

    const header = (
        <HeaderRow>
            <NumberCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.advance.number')}
                </HeaderText>
            </NumberCol>
            <LabelCol windowWidth={windowWidth}>
                <HeaderText type="H100" uppercased>
                    {t('invoice.advance.engagementLabel')}
                </HeaderText>
            </LabelCol>
            <AmmountCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.advance.currentCumulatedHt')}
                </HeaderText>
            </AmmountCol>
            <PercentCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.advance.currentCumulatedPercent')}
                </HeaderText>
            </PercentCol>
            <AmmountCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.advance.previousCumulatedHt')}
                </HeaderText>
            </AmmountCol>
            <PercentCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.advance.previousCumulatedPercent')}
                </HeaderText>
            </PercentCol>
            <AmmountCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.advance.monthlyAvancementHt')}
                </HeaderText>
            </AmmountCol>
            <AmmountCol>
                <HeaderText type="H100" style={{ padding: '0.625rem 0' }} uppercased>
                    {t('invoice.advance.monthlyAvancementTtc')}
                </HeaderText>
            </AmmountCol>
        </HeaderRow>
    );

    const renderEngagementInvoiceRows = (
        marketInvoiceNumber: MarketInvoice['invoiceNumber'] | null,
        invoiceType: string,
        formikInvoices: EngagementInvoiceFormData[],
    ) =>
        formikInvoices.map((formikInvoice: EngagementInvoiceFormData, index: number) => {
            let calculatedInvoices:
                | MarketInvoiceCalculatedData['initialMarketEngagementInvoices']
                | MarketInvoiceCalculatedData['otherEngagementInvoices'] = [];
            if (invoiceType === 'initialMarketEngagementInvoices') {
                calculatedInvoices = calculatedData.initialMarketEngagementInvoices;
            } else if (invoiceType === 'otherEngagementInvoices') {
                calculatedInvoices = calculatedData.otherEngagementInvoices;
            }

            const calculatedInvoice: EngagementInvoiceFormData = calculatedInvoices[index];

            // Cumulated percentage = cumulated avancement HT / engagement amount HT
            // To avoid loading engagement we do engagement amount HT = cumulated avancement HT - rest to invoice HT
            const currentCumulatedAvancemementsHtRatio =
                Number(formikInvoice.currentCumulatedAvancementsAmountHt) /
                (Number(formikInvoice.currentCumulatedAvancementsAmountHt) +
                    calculatedInvoice.restToInvoiceHt);

            // Cumulated percentage = cumulated avancement HT / engagement amount HT
            // To avoid loading engagement we do engagement amount HT = cumulated avancement HT - rest to invoice HT
            const previousCumulatedAvancemementsHtRatio =
                calculatedInvoice.previousCumulatedAvancementsAmountHt /
                (Number(formikInvoice.currentCumulatedAvancementsAmountHt) +
                    calculatedInvoice.restToInvoiceHt);

            return (
                <InfoRow key={calculatedInvoice.id}>
                    <NumberCol>
                        <InfoRowText>
                            {marketInvoiceNumber &&
                                formatNumberWithLeadingZeros(marketInvoiceNumber, 3)}
                        </InfoRowText>
                    </NumberCol>
                    <LabelCol windowWidth={windowWidth}>
                        <InfoRowTextSmall>{calculatedInvoice.engagementLabel}</InfoRowTextSmall>
                    </LabelCol>
                    <AmmountCol>
                        <InfoRowAmountSmallDiv>
                            <PermissionAwareText
                                editable={isEditMode}
                                permissionsRequired={permissionsRequired}
                                numberType="decimal"
                                name={`${invoiceType}[${index}].currentCumulatedAvancementsAmountHt`}
                                type="text"
                                data-testid={`${invoiceType}[${index}].currentCumulatedAvancementsAmountHt`}
                                errorTestId={`${invoiceType}[${index}].errorCurrentCumulatedAvancementsAmountHt`}
                                marginTop="0"
                                whiteBackground
                            />
                        </InfoRowAmountSmallDiv>
                    </AmmountCol>
                    <PercentCol>
                        <PercentageBadge value={currentCumulatedAvancemementsHtRatio} />
                    </PercentCol>
                    <AmmountCol>
                        <InfoRowAmountSmall>
                            {NumberFormatter.format(
                                calculatedInvoice.previousCumulatedAvancementsAmountHt,
                            )}
                        </InfoRowAmountSmall>
                    </AmmountCol>
                    <PercentCol>
                        <PercentageBadge value={previousCumulatedAvancemementsHtRatio} />
                    </PercentCol>
                    <AmmountCol>
                        <InfoRowAmountSmall>
                            {NumberFormatter.format(calculatedInvoice.avancementsAmountHt)}
                        </InfoRowAmountSmall>
                    </AmmountCol>
                    <AmmountCol>
                        <InfoRowAmountSmall>
                            {NumberFormatter.format(calculatedInvoice.avancementsAmountTtc)}
                        </InfoRowAmountSmall>
                    </AmmountCol>
                </InfoRow>
            );
        });

    const information = (
        <>
            {/* Sous total du marché initial (engagementType = market) */}
            <InfoRow borderBottom />
            <InfoRow borderBottom>
                <SubTotalCol>
                    <InfoRowText style={{ fontWeight: 500 }}>
                        {t('invoice.advance.initialMarket')}
                    </InfoRowText>
                </SubTotalCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(
                            calculatedData.currentCumulatedInitialMarketAvancementsHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <PercentCol />
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(
                            calculatedData.previousCumulatedInitialMarketAvancementsHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <PercentCol />
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(calculatedData.initialMarketAvancementsHt)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(calculatedData.initialMarketAvancementsTtc)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            {renderEngagementInvoiceRows(
                invoiceNumber,
                'initialMarketEngagementInvoices',
                formikValues.initialMarketEngagementInvoices,
            )}

            {/* Sous total des avenants (engagementType = amendment) */}
            <InfoRow borderBottom />
            <InfoRow borderBottom>
                <SubTotalCol>
                    <InfoRowText style={{ fontWeight: 500 }}>
                        {t('invoice.advance.amendmentTotal')}
                    </InfoRowText>
                </SubTotalCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(
                            calculatedData.currentCumulatedOtherWorksAvancementsHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <PercentCol />
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(
                            calculatedData.previousCumulatedOtherWorksAvancementsHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <PercentCol />
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(calculatedData.otherWorksAvancementsHt)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(calculatedData.otherWorksAvancementsTtc)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            {renderEngagementInvoiceRows(
                invoiceNumber,
                'otherEngagementInvoices',
                formikValues.otherEngagementInvoices,
            )}
        </>
    );

    const total = (
        <HeaderRow>
            <SubTotalCol>
                <TotalText type="H500">{t('invoice.advance.total')}</TotalText>
            </SubTotalCol>
            <AmmountCol>
                <InfoRowAmount style={{ fontWeight: 500 }}>
                    {NumberFormatter.format(calculatedData.currentCumulatedAvancementsTotalHt)}
                </InfoRowAmount>
            </AmmountCol>
            <PercentCol />
            <AmmountCol>
                <InfoRowAmount style={{ fontWeight: 500 }}>
                    {NumberFormatter.format(calculatedData.previousCumulatedAvancementsTotalHt)}
                </InfoRowAmount>
            </AmmountCol>
            <PercentCol />
            <AmmountCol>
                <InfoRowAmount style={{ fontWeight: 500 }}>
                    {NumberFormatter.format(calculatedData.avancementsTotalHt)}
                </InfoRowAmount>
            </AmmountCol>
            <AmmountCol>
                <TotalText type="H500" uppercased isNumeral>
                    {NumberFormatter.format(calculatedData.avancementsTotalTtc)}
                </TotalText>
            </AmmountCol>
        </HeaderRow>
    );

    return (
        <HorizontalScrollContent windowWidth={windowWidth}>
            <div style={{ display: 'inline-block' }}>
                {header}
                {information}
                {total}
            </div>
        </HorizontalScrollContent>
    );
};

export default MarketInvoiceAvancement;
