import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import { replaceTextValueIfNaNOrEmpty } from '../utils/formatters';

// Prop Types
type StyledProps = {
    font?: 'Gilroy' | 'Rubik' | string;
    size?: string;
    color?: string;
    uppercased?: boolean;
};

type StyledH1Props = {
    type?: 'H900' | 'H800' | 'H700' | 'H600';
    font?: 'Gilroy' | 'Rubik' | string;
    size?: string;
    color?: string;
    uppercased?: boolean;
};

type Props = {
    className?: string;
    type?:
        | 'default'
        | 'small'
        | 'H900'
        | 'H800'
        | 'H700'
        | 'H600'
        | 'H500'
        | 'H400'
        | 'H300'
        | 'H200'
        | 'H100';
    isNumeral?: boolean;
    font?: 'Gilroy' | 'Rubik' | string;
    size?: string;
    color?: string;
    uppercased?: boolean;
    style?: React.CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLParagraphElement>) => void;
};

export type TextProps = Props;

const computeFont = (props: TextProps, defaultFont: TextProps['font']) => {
    if (props.isNumeral) {
        return styles.numeralsFont;
    } else if (props.font) {
        return props.font;
    } else {
        return defaultFont;
    }
};

// Styled Components
const StyledParagraph = styled.p<StyledProps>`
    font-family: ${(props) => computeFont(props, 'Rubik')};
    font-style: normal;
    ${(props) => props.uppercased && `text-transform: uppercase`};
    font-weight: normal;
    font-size: ${(props) => (props.size ? props.size : '0.875rem')};
    line-height: 1.5rem;
    color: ${(props) => (props.color ? props.color : colors.blue.B400)};
`;

const StyledSmall = styled.p<StyledProps>`
    font-family: ${(props) => computeFont(props, 'Rubik')};
    font-style: normal;
    ${(props) => props.uppercased && `text-transform: uppercase`};
    font-weight: normal;
    font-size: ${(props) => (props.size ? props.size : '0.6875rem')};
    line-height: 0.875rem;
    color: ${(props) => (props.color ? props.color : colors.neutral.N400)};
`;

const StyledH1 = styled.h1<StyledH1Props>`
    font-family: ${(props) => computeFont(props, 'Gilroy')};
    font-style: normal;
    ${(props) => props.uppercased && `text-transform: uppercase`};
    font-weight: 800;
    color: ${(props) => (props.color ? props.color : colors.blue.B400)};

    ${(props) =>
        props.type === 'H900' &&
        `
        font-size: ${props.size ? props.size : '2.1875rem'};
        line-height: 2.5rem;
    `};

    ${(props) =>
        props.type === 'H800' &&
        `
        font-size: ${props.size ? props.size : '1.8125rem'};
        line-height: 2rem;
    `};

    ${(props) =>
        props.type === 'H700' &&
        `
        font-size: ${props.size ? props.size : '1.5rem'};
        line-height: 1.75rem;
    `};

    ${(props) =>
        props.type === 'H600' &&
        `
        font-size: ${props.size ? props.size : '1.125rem'};
        line-height: 1.5rem;
    `};
`;

const StyledH2 = styled.h2<StyledProps>`
    font-family: ${(props) => computeFont(props, 'Gilroy')};
    font-style: normal;
    ${(props) => props.uppercased && `text-transform: uppercase`};
    font-weight: 800;
    color: ${(props) => (props.color ? props.color : colors.blue.B400)};
    font-size: ${(props) => (props.size ? props.size : '1rem')};
    line-height: 1.1875rem;
`;

const StyledH3 = styled.h3<StyledProps>`
    font-family: ${(props) => computeFont(props, 'Rubik')};
    font-style: normal;
    ${(props) => props.uppercased && `text-transform: uppercase`};
    font-weight: 500;
    color: ${(props) => (props.color ? props.color : colors.blue.B400)};
    font-size: ${(props) => (props.size ? props.size : '0.875rem')};
    line-height: 1rem;
`;

const StyledH4 = styled.h4<StyledProps>`
    font-family: ${(props) => computeFont(props, 'Rubik')};
    font-style: normal;
    ${(props) => props.uppercased && `text-transform: uppercase`};
    font-weight: 500;
    color: ${(props) => (props.color ? props.color : colors.blue.B400)};
    font-size: ${(props) => (props.size ? props.size : '0.75rem')};
    line-height: 1rem;
`;

const StyledH5 = styled.h5<StyledProps>`
    font-family: ${(props) => computeFont(props, 'Rubik')};
    font-style: normal;
    ${(props) => props.uppercased && `text-transform: uppercase`};
    font-weight: 500;
    color: ${(props) => (props.color ? props.color : colors.neutral.N400)};
    font-size: ${(props) => (props.size ? props.size : '0.75rem')};
    line-height: 1rem;
`;

const StyledH6 = styled.h6<StyledProps>`
    font-family: ${(props) => computeFont(props, 'Rubik')};
    font-style: normal;
    ${(props) => props.uppercased && `text-transform: uppercase`};
    font-weight: 500;
    color: ${(props) => (props.color ? props.color : colors.neutral.N400)};
    font-size: ${(props) => (props.size ? props.size : '0.6875rem')};
    line-height: 1rem;
`;

const Text: React.FC<PropsWithChildren<Props>> = ({
    type = 'default',
    style,
    children,
    ...rest
}) => {
    const { isNumeral } = rest;
    const childrenValue = replaceTextValueIfNaNOrEmpty(children, isNumeral);

    return (
        <>
            {type === 'default' && (
                <StyledParagraph {...rest} style={style}>
                    {childrenValue}
                </StyledParagraph>
            )}
            {type === 'small' && (
                <StyledSmall {...rest} style={style}>
                    {childrenValue}
                </StyledSmall>
            )}
            {/* Headings */}
            {type === 'H900' && (
                <StyledH1 {...rest} style={style} type={type}>
                    {childrenValue}
                </StyledH1>
            )}
            {type === 'H800' && (
                <StyledH1 {...rest} style={style} type={type}>
                    {childrenValue}
                </StyledH1>
            )}
            {type === 'H700' && (
                <StyledH1 {...rest} style={style} type={type}>
                    {childrenValue}
                </StyledH1>
            )}
            {type === 'H600' && (
                <StyledH1 {...rest} style={style} type={type}>
                    {childrenValue}
                </StyledH1>
            )}
            {type === 'H500' && (
                <StyledH2 {...rest} style={style}>
                    {childrenValue}
                </StyledH2>
            )}
            {type === 'H400' && (
                <StyledH3 {...rest} style={style}>
                    {childrenValue}
                </StyledH3>
            )}
            {type === 'H300' && (
                <StyledH4 {...rest} style={style}>
                    {childrenValue}
                </StyledH4>
            )}
            {type === 'H200' && (
                <StyledH5 {...rest} style={style}>
                    {childrenValue}
                </StyledH5>
            )}
            {type === 'H100' && (
                <StyledH6 {...rest} style={style}>
                    {childrenValue}
                </StyledH6>
            )}
        </>
    );
};

export default Text;
