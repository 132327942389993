import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import Button from '../../components/Button';
import PublicPageLayout from '../../components/PublicPageLayout';

import { resetInactivityLogout, selectInactivityLogout } from '../../slices/authSlice';
import { useAppDispatch } from '../../store';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20%;
    margin-top: 33%;
`;

const InactivityLogout: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const inactivityLogout = useSelector(selectInactivityLogout);
    const location = useLocation();

    return (
        <>
            <Helmet>
                <title>InactivityLogout</title>
            </Helmet>
            {!inactivityLogout ? (
                <Navigate to={{ pathname: '/login' }} state={location.state} />
            ) : null}
            <PublicPageLayout>
                <Container>
                    <p>{t('errors.inactivityLogoutFirst')}</p>
                    <p>{t('errors.inactivityLogoutSecond')}</p>
                    <Button
                        size="large"
                        type="submit"
                        aspect="primary"
                        data-testid="inactivityLogoutOK"
                        text="OK"
                        onClick={() => dispatch(resetInactivityLogout())}
                    />
                </Container>
            </PublicPageLayout>
        </>
    );
};

export default InactivityLogout;
