import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import styled from 'styled-components';

import FormikTextField from '../../components/TextFieldWithFormik';
import { requestResetPasswordEmail } from '../../slices/authSlice';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import { colors } from '../../constants/colors';
import { styles } from '../../constants/styles';
import MarkAsBold from '../../utils/markAsBold';
import Logo from '../../components/Logo';
import PublicPageLayout from '../../components/PublicPageLayout';
import Text from '../../components/Text';
import { useAppDispatch } from '../../store';

const Container = styled.div`
    -ms-grid-row: 1;
    grid-area: content;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    max-width: 56.25rem;
    height: 100%;
    padding: 0 1em;
    margin: auto;
`;

const StepsContainer = styled.section`
    background: ${colors.neutral.N25};
    border-radius: ${styles.borderRadiusSmall};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    margin-top: 2em;
    min-height: 15.625rem;
`;

const Step = styled.article`
    display: flex;
    padding: 0.25rem 0;
`;

const Badge = styled.div`
    width: 2.625rem;
    height: 2.625rem;
    background: ${colors.neutral.N75};
    border-radius: ${styles.borderRadiusBig};
    font-family: Gilroy;
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: ${colors.neutral.N300};
    margin-right: 1.5em;
    padding: 1em;
`;

const StepText = styled.p`
    font-family: Roboto;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 175%;
    color: ${colors.neutral.N300};
    margin-top: 0.625rem;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ActionButton = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    & > * {
        margin: 0 0.875rem;
        &:first-child {
            margin: 0;
            margin-right: 0.875rem;
        }
        &:last-child {
            margin: 0;
            margin-left: 0.875rem;
        }
    }
`;

const PasswordReset: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [goBack, setGoBack] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (hasSubmitted) navigate('/reset-password-email-sent');
        if (goBack) navigate('/login');
    }, [hasSubmitted, goBack, navigate]);

    const searchSplit = window.location.search.split('?');
    const [key, value] = searchSplit.length > 1 ? searchSplit[1].split('=') : [];
    let email = '';
    if (key === 'email') {
        email = value;
    }

    const schema = Yup.object().shape({
        email: Yup.string().email(t('errors.invalidEmail')).required(t('errors.required')),
    });

    return (
        <PublicPageLayout>
            <Container>
                <Logo />
                <Text type="H700" style={{ textAlign: 'center', marginTop: '1.5em' }}>
                    {t('user.resetPassword')}
                </Text>
                <StepsContainer>
                    <Step>
                        <Badge>1</Badge>
                        <StepText>
                            <MarkAsBold translationKey="forgotPassword.stepOne" />
                        </StepText>
                    </Step>
                    <Step>
                        <Badge>2</Badge>
                        <div>
                            <StepText>
                                <MarkAsBold translationKey="forgotPassword.stepTwoPartOne" />
                            </StepText>
                            <StepText>
                                <MarkAsBold translationKey="forgotPassword.stepTwoPartTwo" />
                            </StepText>
                        </div>
                    </Step>
                    <Step>
                        <Badge>3</Badge>
                        <StepText>
                            <MarkAsBold translationKey="forgotPassword.stepThree" />
                        </StepText>
                    </Step>
                </StepsContainer>
                <Formik
                    initialValues={{
                        email,
                    }}
                    validationSchema={schema}
                    onSubmit={(values, actions) => {
                        void dispatch(requestResetPasswordEmail({ email: values.email }));
                        actions.setSubmitting(false);
                        setHasSubmitted(true);
                    }}
                >
                    {() => (
                        <StyledForm>
                            <FormikTextField
                                name="email"
                                type="email"
                                placeholder="abc@email.com"
                                data-testid="email"
                                errorTestId="emailError"
                                whiteBackground
                            />
                            <br />
                            <ActionButton>
                                <Button
                                    size="large"
                                    aspect="secondary"
                                    data-testid="resetButton"
                                    text={t('forgotPassword.cancel')}
                                    onClick={() => setGoBack(true)}
                                />
                                <Button
                                    size="large"
                                    type="submit"
                                    aspect="primary"
                                    data-testid="submitButton"
                                    text={t('forgotPassword.submit')}
                                />
                            </ActionButton>
                        </StyledForm>
                    )}
                </Formik>
            </Container>
            <Footer />
        </PublicPageLayout>
    );
};

export default PasswordReset;
