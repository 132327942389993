import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import SearchDocument from '../assets/empty-states/search_document.svg';
import Lines from '../assets/empty-states/lines.svg';
import Lists from '../assets/empty-states/lists.svg';
import User from '../assets/empty-states/user.svg';
import Operation from '../assets/empty-states/operation.svg';
import OperationBis from '../assets/empty-states/operation1.svg';
import ServerDown from '../assets/empty-states/server_down.svg';
import Budget from '../assets/empty-states/budget.svg';

import Text from './Text';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: auto;
    text-align: center;
`;

const ImageContainer = styled.img`
    align-self: center;
    margin-bottom: 2.5em;
`;

export type EmptyStateProps = {
    'data-testid'?: string;
    titleTranslationKey?: string;
    imageName?:
        | 'SearchDocument'
        | 'Lines'
        | 'Lists'
        | 'Operation'
        | 'OperationBis'
        | 'ServerDown'
        | 'User'
        | 'Budget'
        | 'Engagement';
    textTranslationKey?: string;
    button?: React.ReactNode;
};

const EmptyState: React.FC<EmptyStateProps> = ({
    titleTranslationKey,
    imageName,
    textTranslationKey,
    button,
    ...props
}) => {
    const { t } = useTranslation();

    let image;
    let imageAltTranslationKey;
    if (imageName) {
        switch (imageName) {
            case 'SearchDocument':
                image = SearchDocument;
                imageAltTranslationKey = 'altMessage.searchDocumentImage';
                break;
            case 'Lines':
                image = Lines;
                imageAltTranslationKey = 'altMessage.linesImage';
                break;
            case 'Lists':
                image = Lists;
                imageAltTranslationKey = 'altMessage.listsImage';
                break;
            case 'User':
                image = User;
                imageAltTranslationKey = 'altMessage.userImage';
                break;
            case 'Operation':
                image = Operation;
                imageAltTranslationKey = 'altMessage.operationImage';
                break;
            case 'OperationBis':
                image = OperationBis;
                imageAltTranslationKey = 'altMessage.operationImage';
                break;
            case 'ServerDown':
                image = ServerDown;
                imageAltTranslationKey = 'altMessage.serverImage';
                break;
            case 'Budget':
                image = Budget;
                imageAltTranslationKey = 'altMessage.budgetImage';
                break;
            case 'Engagement':
                image = Lines;
                imageAltTranslationKey = 'altMessage.engagementImage';
                break;
            default:
                console.error(`No image for name: ${imageName}.`);
                return null;
        }
    }

    return (
        <Container {...props}>
            {titleTranslationKey && <Text type="H500">{t(titleTranslationKey)}</Text>}
            {image && imageAltTranslationKey && (
                <ImageContainer src={image} alt={t(imageAltTranslationKey)} />
            )}
            {button && button}
            {textTranslationKey && <Text type="default">{t(textTranslationKey)}</Text>}
        </Container>
    );
};

export default EmptyState;
