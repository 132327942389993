import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    getBudgetLinesForEngagements,
    selectValidatedBudgetLines,
} from '../slices/engagementSlice';
import type { Operation } from '../slices/operationSlice';
import type { Budget } from '../slices/budgetSlice';
import { selectBudgetEngagementsMatch, getBudgetEngagementsMatch } from '../slices/budgetSlice';
import {
    getBudgetLinesForRefDropdown,
    selectBudgetLinesForDropdown,
} from '../slices/budgetLineSlice';
import type { EngagementMatching } from './BudgetLinesMatchingEngagementsModal';
import BudgetLinesMatchingEngagementsModal from './BudgetLinesMatchingEngagementsModal';
import { useAppDispatch } from '../store';

type Props = {
    onSubmit: (values: EngagementMatching[]) => void;
    onCancelClick: () => void;
    'data-testid'?: string;
    isOpen: boolean;
    operationId: Operation['id'];
    budgetId: Budget['id'];
};

const BudgetLinesMatchingEngagementsModalBeforeApprovingBudget = ({
    operationId,
    budgetId,
    ...props
}: Props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const budgetLinesNewBudget = useSelector(selectBudgetLinesForDropdown);
    const budgetLinesApprovedBudget = useSelector(selectValidatedBudgetLines);
    const engagementMatching = useSelector(selectBudgetEngagementsMatch(Number(budgetId)));

    useEffect(() => {
        void dispatch(
            getBudgetEngagementsMatch({
                operationId: Number(operationId),
                id: Number(budgetId),
            }),
        );
        void dispatch(
            getBudgetLinesForEngagements({
                operationId: Number(operationId),
            }),
        );
        void dispatch(
            getBudgetLinesForRefDropdown({
                operationId: Number(operationId),
                budgetId: Number(budgetId),
            }),
        );
    }, [budgetId, dispatch, operationId]);

    return (
        <BudgetLinesMatchingEngagementsModal
            {...props}
            currentBudgetLines={budgetLinesApprovedBudget}
            newBudgetLines={budgetLinesNewBudget}
            engagementMatching={engagementMatching}
            oldBudgetLinesHeaderTitle={t('budget.assignmentOnOldBudget')}
            newBudgetLinesHeaderTitle={t('budget.assignmentOnNewBudget')}
        />
    );
};
export default BudgetLinesMatchingEngagementsModalBeforeApprovingBudget;
