import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import Icon from './Icon';

const Container = styled.p`
    display: flex;
    border-radius: 0.3125rem;
    background-color: ${colors.neutral.N75};
    color: ${colors.blue.B400};
    border: 0.0938rem dashed ${colors.neutral.N200};
    font-family: Rubik;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding: 0.813rem 1rem;
    margin: 2rem 0;
    white-space: pre-line;
    max-width: 100%;
    align-items: center;
    svg {
        margin-right: 1.125rem;
    }
`;

type Props = {
    'data-testid'?: string;
    text?: string;
    translationKey?: string;
    style?: React.CSSProperties;
};

const ErrorMessage: React.FC<Props> = ({ text, translationKey, style, ...props }) => {
    const { t } = useTranslation();

    let message = text;
    if (translationKey) {
        message = t(translationKey);
    }

    return (
        <Container style={style} {...props}>
            <Icon name="Info" color={colors.blue.B400} />
            {message}
        </Container>
    );
};

export default ErrorMessage;
