/* eslint-disable no-nested-ternary -- necessary */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { capitalize } from '../../utils/capitalizeText';

// Components
import Text from '../../components/Text';
import MainLayout from '../../components/MainLayout';
import GridCol from '../../components/GridCol';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import Icon from '../../components/Icon';
import InformationCard from '../../components/InformationCard';
import Tooltip from '../../components/Tooltip';
import PermissionAwareText from '../../components/PermissionAwareText';
import PermissionAwareToggle from '../../components/PermissionAwareToggle';
import Loader from '../../components/Loader';
import { showFlag } from '../../components/Flag';

// Assets, constants, hooks & utils
import Building from '../../assets/images/building_icone.svg';
import { colors } from '../../constants/colors';
import { styles } from '../../constants/styles';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';

import type { IOrganizationEditableFormData, IOrganization } from '../../slices/organizationSlice';
import {
    getOrganization,
    selectOrganization,
    selectIsLoading,
    selectOrganizationName,
    updateOrganization,
    selectError,
} from '../../slices/organizationSlice';
import { useAppDispatch } from '../../store';

// Styled Components
const ElementContainer = styled.div`
    background-color: ${colors.neutral.N75};
    display: inline-block;
    padding: 0.625rem;
    border-radius: ${styles.borderRadiusSmall};
    margin-bottom: 1.5rem;
`;

const IconsWrapper = styled.div`
    max-width: 8.8125rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const InfoLine = styled.div`
    display: block;
    flex: 1 100%;
    margin: 0 0 1.5rem 0;
`;

const StyledEntry = styled(Text)`
    margin: 0 0 0.5rem 0;
`;

const StyledValue = styled(Text)`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

// Props
type Props = {
    headerProps: HeaderProps;
};

type LocationState = {
    organizationId?: string;
    goBackTo?: string;
    breadcrumbs?: Array<{ to: string; text: string }>;
};

const Organization: React.FunctionComponent<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const { organizationId } = useParams<{ organizationId: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const state: LocationState = location.state ?? {};
    const { width } = useWindowDimensions();
    const hasRightToEditOrganizations = usePermissionsCheck([
        { code: 'ORGANIZATIONS_EDIT', organizationId },
    ]);
    const hasRightToSetClientOrganization = usePermissionsCheck([
        { code: 'SET_ORGANIZATION_CLIENT' },
    ]);

    // State & Selectors
    const [isEditMode, setIsEditMode] = useState<boolean>(
        hasRightToEditOrganizations && location.search === '?edit',
    );
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const organization = useSelector(selectOrganization(organizationId));
    const [isClient, setIsClient] = useState<boolean>(organization?.estClient ?? false);
    const isLoading = useSelector(selectIsLoading);
    const organizationName = useSelector(selectOrganizationName(organizationId));
    const serverError = useSelector(selectError);

    // Data
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isLoading && !organization && organizationId) {
            void dispatch(getOrganization(organizationId));
        } else if (organization) {
            setIsClient(organization.estClient ?? false);
        }
    }, [dispatch, organizationId, isLoading, organization, setIsClient]);

    const error = t('errors.error');
    let serverErrorT = '';
    if (serverError) {
        serverErrorT = t(serverError);
    }
    const success = t('general.success');
    const successMessage = t('organization.saved');
    useEffect(() => {
        if (isUpdating) {
            if (serverError) {
                showFlag('error', error, serverErrorT);
                setIsUpdating(false);
                setIsEditMode(true);
            } else {
                showFlag('success', success, successMessage);
                setIsUpdating(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on every dependencies changes
    }, [serverError, error, serverErrorT, success, successMessage]);

    // Show loader if we have a param id and didn't fetch the organization yet
    if (organizationId && isLoading && !organization) {
        return <Loader overlay />;
    }

    // Validation schema
    const schema = Yup.object().shape({
        numeroRcs: Yup.string()
            .max(20, t('errors.tooLong', { number: 20 }))
            .nullable(),
        numeroTva: Yup.string()
            .max(16, t('errors.tooLong', { number: 16 }))
            .nullable(),
        estClient: Yup.boolean(),
    });

    // Siege/Headquarters
    const isHeadquarters = !organization?.etablissementSiegeSiret;
    const headquartersLink =
        organization?.etablissementSiegeSiret &&
        `/organizations/${organization.etablissementSiegeSiret}`;

    // Information styled data
    const adjustImageMargin = isEditMode ? { marginBottom: '0.5rem' } : { marginBottom: '1.5rem' };
    const image = (
        <ElementContainer style={adjustImageMargin}>
            <img
                src={Building}
                style={{ width: '7.565rem', height: '7.565rem' }}
                alt={t('altMessage.defaultOrganizationImage')}
            />
        </ElementContainer>
    );
    const imageEditIcons = isEditMode && (
        <IconsWrapper>
            <ElementContainer style={{ padding: '0.55rem' }}>
                <Icon name="Add" color={colors.neutral.N300} />
            </ElementContainer>
            <ElementContainer style={{ padding: '0.55rem' }}>
                <Icon name="DeleteOutline" color={colors.neutral.N300} />
            </ElementContainer>
            <ElementContainer
                style={{
                    padding: '0.55rem',
                    backgroundColor: colors.other.white,
                }}
            >
                <Tooltip content={t('organization.information.imageTooltipInfo')}>
                    <Icon name="Info" color={colors.neutral.N400} />
                </Tooltip>
            </ElementContainer>
        </IconsWrapper>
    );
    const siret = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {t('organization.information.siret')}
            </StyledEntry>
            <StyledValue>{organization?.siret}</StyledValue>
        </InfoLine>
    );
    const type = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {t('organization.information.type')}
            </StyledEntry>
            <StyledValue>
                {organization?.estPrive && t('organization.private')}
                {organization?.estPublic && t('organization.public')}
            </StyledValue>
        </InfoLine>
    );
    const denomination = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {t('organization.information.raisonSociale')}
            </StyledEntry>
            <StyledValue>{capitalize(organization?.denomination ?? '-')}</StyledValue>
        </InfoLine>
    );
    const numeroRcs = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {isEditMode
                    ? `${t('organization.information.numeroRcs')} ${t('general.optional')}`
                    : t('organization.information.numeroRcs')}
            </StyledEntry>
            {isEditMode ? (
                <PermissionAwareText
                    permissionsRequired={[{ code: 'ORGANIZATIONS_EDIT', organizationId }]}
                    name="numeroRcs"
                    placeholder={organization?.numeroRcs ?? ''}
                    type="text"
                    marginTop="0px"
                    data-testid="organizationNumeroRcs"
                    whiteBackground
                    editable
                />
            ) : (
                <StyledValue>{organization?.numeroRcs ?? t('organization.none')}</StyledValue>
            )}
        </InfoLine>
    );
    const libelleCommune = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {t('organization.information.libelleCommune')}
            </StyledEntry>
            <StyledValue>{capitalize(organization?.libelleCommune ?? '-')}</StyledValue>
        </InfoLine>
    );
    const noTVA = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {isEditMode
                    ? `${t('organization.information.noTVA')} ${t('general.optional')}`
                    : t('organization.information.noTVA')}
            </StyledEntry>
            {isEditMode ? (
                <PermissionAwareText
                    permissionsRequired={[{ code: 'ORGANIZATIONS_EDIT', organizationId }]}
                    name="numeroTva"
                    placeholder={organization?.numeroTva ?? ''}
                    type="text"
                    marginTop="0px"
                    data-testid="organizationNumeroTva"
                    whiteBackground
                    editable
                />
            ) : (
                <StyledValue>{organization?.numeroTva ?? t('organization.none')}</StyledValue>
            )}
        </InfoLine>
    );
    const categorieJuridique = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {t('organization.information.categorieJuridique')}
            </StyledEntry>
            <StyledValue>{organization?.categorieJuridique ?? '-'}</StyledValue>
        </InfoLine>
    );
    const siretSiege = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {t('organization.information.siretSiege')}
            </StyledEntry>
            <StyledValue>
                {headquartersLink ? (
                    <Link to={headquartersLink} data-testid="headquartersLink">
                        {organization.etablissementSiegeSiret}
                    </Link>
                ) : (
                    '-'
                )}
            </StyledValue>
        </InfoLine>
    );
    const codeApe = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {t('organization.information.codeApe')}
            </StyledEntry>
            <StyledValue>{organization?.activitePrincipale ?? '-'}</StyledValue>
        </InfoLine>
    );
    const isClientText = isClient ? t('general.yes') : t('general.no');
    const organismeClient = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {t('organization.information.organismeClient')}
            </StyledEntry>
            {isEditMode && hasRightToSetClientOrganization ? (
                <PermissionAwareToggle
                    permissionsRequired={[{ code: 'ORGANIZATIONS_EDIT', organizationId }]}
                    name="estClient"
                    aspect="small"
                    editable={isEditMode}
                    checked={isClient}
                    onChange={() => {
                        setIsClient(!isClient);
                    }}
                    data-testid="organizationEstClient"
                />
            ) : (
                <StyledValue>{isClientText}</StyledValue>
            )}
        </InfoLine>
    );
    const organismeSiege = (
        <InfoLine>
            <StyledEntry color={colors.neutral.N300}>
                {t('organization.information.organismeSiege')}
            </StyledEntry>
            <StyledValue>{isHeadquarters ? t('general.yes') : t('general.no')}</StyledValue>
        </InfoLine>
    );

    const getAdressDataLine1 = (org: IOrganization) => {
        const addressDataLine1 = [org.numeroVoie, org.typeVoie, org.libelleVoie]
            .filter(Boolean)
            .join(' ');
        return addressDataLine1;
    };

    // Address data
    const addressData = {
        [t('organization.address.billingAdress')]: organization
            ? getAdressDataLine1(organization)
            : '-',
        [t('organization.address.codePostal')]: organization?.codePostal
            ? organization.codePostal
            : '-',
        [t('organization.address.libelleCommune')]: organization?.libelleCommune
            ? capitalize(organization.libelleCommune)
            : '-',
        [t('organization.address.pays')]: organization?.estEtranger
            ? t('organization.address.etranger')
            : t('organization.address.france'),
    };

    const isIpadInPortrait = width <= styles.ipadVerticalWidth.px;
    const smallScreenColumnWidth = isIpadInPortrait ? 6 : 3;

    const removeSpaces = (value: string) => value.replace(/\s+/g, '');

    return (
        <Formik
            initialValues={{
                id: organizationId as string,
                numeroRcs: organization?.numeroRcs ?? null,
                numeroTva: organization?.numeroTva ?? null,
                estClient: isClient || false,
            }}
            validationSchema={schema}
            onSubmit={(values: IOrganizationEditableFormData, actions) => {
                void dispatch(
                    updateOrganization({
                        ...values,
                        ...(hasRightToSetClientOrganization ? { estClient: isClient } : {}),
                        numeroRcs: values.numeroRcs ? removeSpaces(values.numeroRcs) : null,
                        numeroTva: values.numeroTva ? removeSpaces(values.numeroTva) : null,
                    }),
                );
                setIsUpdating(true);
                actions.setSubmitting(false);
                setIsEditMode(false);
                navigate(location.pathname.replace('?edit', ''), { state, replace: true });
            }}
        >
            {(props: FormikProps<IOrganizationEditableFormData>) => (
                <MainLayout
                    header={
                        <Header
                            {...headerProps}
                            title={organizationName ?? headerProps.title}
                            showNavigation={!isEditMode}
                            buttons={
                                hasRightToEditOrganizations
                                    ? isEditMode
                                        ? [
                                              {
                                                  text: t('general.cancel'),
                                                  aspect: 'secondary',
                                                  type: 'button',
                                                  'data-testid': 'cancelUpdateOrganization',
                                                  onClick() {
                                                      setIsEditMode(false);
                                                      navigate(
                                                          location.pathname.replace('?edit', ''),
                                                          { state, replace: true },
                                                      );
                                                      props.handleReset();
                                                  },
                                              },
                                              {
                                                  text: t('general.submit'),
                                                  aspect: 'primary',
                                                  type: 'submit',
                                                  'data-testid': 'updateOrganization',
                                                  onClick() {
                                                      props.handleSubmit();
                                                  },
                                              },
                                          ]
                                        : [
                                              {
                                                  text: t('organization.modifyOrganization'),
                                                  aspect: 'primary',
                                                  type: 'button',
                                                  'data-testid': 'modifyOrganization',
                                                  onClick() {
                                                      setIsEditMode(true);
                                                      navigate(`${location.pathname}?edit`, {
                                                          state,
                                                          replace: true,
                                                      });
                                                  },
                                              },
                                          ]
                                    : []
                            }
                            showBackButton
                        />
                    }
                >
                    {organization && (
                        <Form>
                            <InformationCard
                                title={t('organization.information.informationLabel')}
                                status={organization.estActif ? 'active' : 'inactive'}
                                showStatus
                            >
                                <GridCol defaultScreen={3} smallScreen={smallScreenColumnWidth}>
                                    {image}
                                    {imageEditIcons}
                                    {isIpadInPortrait && (
                                        <>
                                            {denomination}
                                            {numeroRcs}
                                            {codeApe}
                                            {organismeClient}
                                        </>
                                    )}
                                </GridCol>

                                <GridCol defaultScreen={3} smallScreen={smallScreenColumnWidth}>
                                    {siret}
                                    {type}
                                    {categorieJuridique}
                                    {organismeSiege}
                                    {isIpadInPortrait && (
                                        <>
                                            <GridCol defaultScreen={3}>
                                                {denomination}
                                                {numeroRcs}
                                                {codeApe}
                                                {!isHeadquarters && siretSiege}
                                            </GridCol>

                                            <GridCol defaultScreen={3}>
                                                {libelleCommune}
                                                {noTVA}
                                                {organismeClient}
                                            </GridCol>
                                        </>
                                    )}
                                </GridCol>

                                {!isIpadInPortrait && (
                                    <>
                                        <GridCol
                                            defaultScreen={3}
                                            smallScreen={smallScreenColumnWidth}
                                        >
                                            {denomination}
                                            {numeroRcs}
                                            {codeApe}
                                            {!isHeadquarters && siretSiege}
                                        </GridCol>

                                        <GridCol
                                            defaultScreen={3}
                                            smallScreen={smallScreenColumnWidth}
                                        >
                                            {libelleCommune}
                                            {noTVA}
                                            {organismeClient}
                                        </GridCol>
                                    </>
                                )}
                            </InformationCard>
                            <InformationCard title={t('organization.address.addressLabel')}>
                                {Object.entries(addressData).map(
                                    ([entry, value]: [string, string], key: number) => (
                                        <GridCol
                                            key={entry}
                                            defaultScreen={3}
                                            smallScreen={smallScreenColumnWidth}
                                        >
                                            <InfoLine>
                                                <StyledEntry color={colors.neutral.N300}>
                                                    {entry}:
                                                </StyledEntry>
                                                <StyledValue>{value}</StyledValue>
                                            </InfoLine>
                                        </GridCol>
                                    ),
                                )}
                            </InformationCard>
                        </Form>
                    )}
                </MainLayout>
            )}
        </Formik>
    );
};

export default Organization;
