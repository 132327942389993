import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import type { FormikProps, FieldArrayRenderProps } from 'formik';
import { Form, Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';

import Button from './Button';
import Text from './Text';
import Icon from './Icon';
import Modal from './Modal';
import GridRow from './GridRow';
import GridCol from './GridCol';
import PermissionAwareDisplay from './PermissionAwareDisplay';
import Loader from './Loader';
import FormikOptionDropdown from './dropdown/FormikOptionDropdown';
import type { Option } from './SelectField';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import { CurrencyFormatter, formatNumberWithLeadingZeros } from '../utils/formatters';

import type { Engagement } from '../slices/engagementSlice';
import type { Market } from '../slices/marketSlice';
import type { BudgetLineForDropdown } from '../slices/budgetLineSlice';

const Container = styled.div`
    .bp4-dialog {
        display: flex;
        padding-bottom: 2rem;
    }
    .bp4-dialog-body {
        margin-top: 0;
        height: 50%;
        padding: 0 2rem;
    }
    .bp4-dialog-footer {
        margin: 0 2rem;
    }
`;

const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    justify-content: space-between;
`;

const ButtonsPart = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledForm = styled(Form)`
    overflow-y: scroll;
    flex: 1;
    margin: 1rem 0;
`;

const StyledPermissionAwareDisplay = styled(PermissionAwareDisplay)`
    p {
        margin: 0;
    }
`;

const LoaderContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
`;

const Header = styled.div`
    display: flex;
    width: 100%;
    background-color: ${colors.neutral.N75};
    border-radius: ${styles.borderRadiusSmall};
    opacity: 0.7;
    margin-top: 0.5rem;
    p {
        font-weight: 500;
        margin: 0.5rem 0;
    }
`;

const TableContent = styled.div`
    display: flex;
    width: 100%;
    h3 {
        font-weight: 400;
        margin: 0.5rem 0;
    }
    border-bottom: 0.0936rem solid ${colors.neutral.N75};
`;

const StyledCol = styled.div<{ width?: string }>`
    display: flex;
    align-items: center;
    padding: 0 1rem;
    ${({ width }) => width && `width: ${width};`}
    .bp4-popover-target {
        div {
            margin-bottom: 0 !important;
        }
    }
`;

type EngagementType = {
    id: Engagement['id'];
    engagementType: Engagement['engagementType'];
    label: Engagement['label'];
    amountHt: Engagement['amountHt'];
    budgetLineId: Engagement['budgetLineId'];
    initialBudgetLineId: Engagement['budgetLineId'];
    order?: Engagement['order'];
    matched: Engagement['matched'];
};

export type EngagementMatching = {
    id: Market['id'];
    label: Market['label'];
    externalNumber: Market['externalNumber'];
    sliceCode: Market['sliceCode'];
    internalNumber: Market['internalNumber'];
    holder: string | null;
    engagements: EngagementType[];
};
type FormData = {
    markets: EngagementMatching[];
};

type Props = {
    onSubmit: (values: EngagementMatching[]) => void;
    onCancelClick: () => void;
    isOpen: boolean;
    currentBudgetLines: BudgetLineForDropdown[];
    newBudgetLines: BudgetLineForDropdown[];
    engagementMatching: EngagementMatching[];
    oldBudgetLinesHeaderTitle: string;
    newBudgetLinesHeaderTitle: string;
};

const BudgetLinesMatchingEngagementsModal = ({
    onSubmit,
    onCancelClick,
    isOpen,
    currentBudgetLines,
    newBudgetLines,
    engagementMatching,
    oldBudgetLinesHeaderTitle,
    newBudgetLinesHeaderTitle,
}: Props) => {
    const { t } = useTranslation();
    const [hiddenIds, setHiddenIds] = useState<{
        marketIds: Array<Market['id']>;
        engagementIds: Array<Engagement['id']>;
    }>({ marketIds: [], engagementIds: [] });

    const budgetLinesOptions: Array<Option<number>> = newBudgetLines.map((budgetLine) => ({
        value: budgetLine.id,
        label: `${budgetLine.index} ${budgetLine.label}`,
    }));

    const getApprovedBudgetLineName = (initialBudgetLineId: Engagement['budgetLineId']) => {
        const line = currentBudgetLines.find(({ id }) => id === initialBudgetLineId);
        let name = '';
        if (line) {
            name = `${line.index} ${line.label}`;
        }
        return name;
    };

    const engagementLine = (engagement: EngagementType, name: string, index: number) => (
        <TableContent key={index}>
            <StyledCol style={{ flex: '1', justifyContent: 'flex-start' }}>
                <Text type="H400" color={colors.blue.B400}>
                    {engagement.order ? formatNumberWithLeadingZeros(engagement.order, 3) : '-'}
                </Text>
            </StyledCol>
            <StyledCol style={{ justifyContent: 'flex-start' }} width="15vw">
                <Text type="H400" color={colors.blue.B400}>
                    {engagement.label}
                </Text>
            </StyledCol>
            <StyledCol style={{ justifyContent: 'flex-start' }} width="10vw">
                <Text type="H400" color={colors.blue.B400}>
                    {t(`engagement.${engagement.engagementType}`)}
                </Text>
            </StyledCol>
            <StyledCol style={{ justifyContent: 'flex-end' }} width="13vw">
                <Text type="H400" color={colors.blue.B400} isNumeral>
                    {CurrencyFormatter.format(engagement.amountHt)}
                </Text>
            </StyledCol>
            <StyledCol style={{ justifyContent: 'flex-start' }} width="18vw">
                <Text type="H400" color={colors.blue.B400}>
                    {getApprovedBudgetLineName(engagement.initialBudgetLineId)}
                </Text>
            </StyledCol>
            <StyledCol style={{ justifyContent: 'flex-start' }} width="18vw">
                <Text type="H400" color={colors.blue.B400}>
                    {newBudgetLines.length > 0 ? (
                        <FormikOptionDropdown
                            items={budgetLinesOptions}
                            name={`${name}.budgetLineId`}
                            initialNameDiplay={
                                budgetLinesOptions.find(
                                    (referenceLine) =>
                                        referenceLine.value === engagement.budgetLineId,
                                )?.label ?? t('budget.selectALine')
                            }
                            data-testid="budgetLineId"
                            errorTestId="errorBudgetLineId"
                            style={{ margin: '0' }}
                            filterable
                        />
                    ) : null}
                </Text>
            </StyledCol>
        </TableContent>
    );

    const marketsList = (key: number, name: string) => (
        <Field key={key} name={name}>
            {(fieldProps: { field: { value: EngagementMatching; name: unknown } }) => (
                <div style={{ marginBottom: '1rem', width: '98%' }}>
                    <Text type="H400" color={colors.blue.B500} style={{ margin: '0.5rem 0' }}>
                        {fieldProps.field.value.label}
                    </Text>
                    <GridRow>
                        <GridCol defaultScreen={2}>
                            <StyledPermissionAwareDisplay
                                label={t('engagement.internalNumber')}
                                value={fieldProps.field.value.internalNumber}
                                style={{ marginTop: '0' }}
                            />
                        </GridCol>
                        <GridCol defaultScreen={2}>
                            <StyledPermissionAwareDisplay
                                label={t('engagement.externalNumber')}
                                value={fieldProps.field.value.externalNumber}
                                style={{ marginTop: '0' }}
                            />
                        </GridCol>
                        <GridCol defaultScreen={2}>
                            <StyledPermissionAwareDisplay
                                label={t('market.identity.slice')}
                                value={fieldProps.field.value.sliceCode}
                                style={{ marginTop: '0' }}
                            />
                        </GridCol>
                        <GridCol>
                            <StyledPermissionAwareDisplay
                                label={t('market.identity.organizationRepresentative')}
                                value={fieldProps.field.value.holder}
                                style={{ marginTop: '0' }}
                            />
                        </GridCol>
                    </GridRow>
                    <Header>
                        <StyledCol style={{ flex: '1', justifyContent: 'flex-start' }}>
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {t('engagement.list.number')}
                            </Text>
                        </StyledCol>
                        <StyledCol style={{ justifyContent: 'flex-start' }} width="15vw">
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {t('engagement.list.label')}
                            </Text>
                        </StyledCol>
                        <StyledCol style={{ justifyContent: 'flex-start' }} width="10vw">
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {t('engagement.list.type')}
                            </Text>
                        </StyledCol>
                        <StyledCol style={{ justifyContent: 'flex-end' }} width="13vw">
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {t('engagement.list.amountHt')}
                            </Text>
                        </StyledCol>
                        <StyledCol style={{ justifyContent: 'flex-start' }} width="18vw">
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {oldBudgetLinesHeaderTitle}
                            </Text>
                        </StyledCol>
                        <StyledCol style={{ justifyContent: 'flex-start' }} width="18vw">
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {newBudgetLinesHeaderTitle}
                            </Text>
                        </StyledCol>
                    </Header>
                    <FieldArray
                        name={`${fieldProps.field.name}.engagements`}
                        render={(arrayHelpers: FieldArrayRenderProps) => (
                            <div>
                                {fieldProps.field.value.engagements.map(
                                    (engagement: EngagementType, index: number) =>
                                        !hiddenIds.engagementIds.includes(engagement.id) &&
                                        engagementLine(
                                            engagement,
                                            `${arrayHelpers.name}[${index}]`,
                                            index,
                                        ),
                                )}
                            </div>
                        )}
                    />
                </div>
            )}
        </Field>
    );

    const getMarketIdsAndEngagementsIdsToHide = (markets: EngagementMatching[]) =>
        markets.reduce(
            (
                result: {
                    marketIds: number[];
                    engagementIds: number[];
                },
                market,
            ) => {
                let engagementsHiddenByMarket = 0;
                market.engagements.forEach((engagement) => {
                    if (engagement.budgetLineId && engagement.matched) {
                        result.engagementIds = [...result.engagementIds, engagement.id];
                        engagementsHiddenByMarket += 1;
                    }
                });
                if (engagementsHiddenByMarket === market.engagements.length) {
                    result.marketIds = [...result.marketIds, market.id];
                }
                return result;
            },
            { marketIds: [], engagementIds: [] },
        );

    const validationSchema = Yup.object().shape({
        markets: Yup.array().of(
            Yup.object().shape({
                engagements: Yup.array().of(
                    Yup.object().shape({
                        budgetLineId: Yup.number().required(t('errors.required')).nullable(),
                    }),
                ),
            }),
        ),
    });

    return (
        <Formik
            initialValues={{ markets: engagementMatching }}
            onSubmit={(values: FormData) => onSubmit(values.markets)}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {(props: FormikProps<FormData>) => (
                <Container>
                    <Modal
                        isOpen={isOpen}
                        onCloseButtonPressed={onCancelClick}
                        buttons={[
                            {
                                text: t('general.cancel'),
                                aspect: 'secondary',
                                'data-testid': 'cancelDelete',
                                onClick: onCancelClick,
                            },
                            {
                                text: t('general.save'),
                                aspect: 'primary',
                                'data-testid': 'save',
                                onClick() {
                                    props.handleSubmit();
                                },
                            },
                        ]}
                        specificWidth="90vw"
                        specificHeight="90vh"
                        shouldRenderGlobalStyle={false}
                        canOutsideClickClose={false}
                    >
                        <ModalContent>
                            <ModalHeader>
                                <Text color={colors.blue.B500} type="H700" style={{ margin: '0' }}>
                                    {t('budget.unrelatedEngagements')}
                                </Text>
                                <ButtonsPart>
                                    <Button
                                        text={t('general.all')}
                                        aspect="secondary"
                                        onClick={() => {
                                            setHiddenIds({ marketIds: [], engagementIds: [] });
                                        }}
                                        size="small"
                                        style={{ marginRight: '1.3125rem' }}
                                    />
                                    <Button
                                        text={t('budget.seeUnrelatedValues')}
                                        aspect="secondary"
                                        onClick={() => {
                                            setHiddenIds(
                                                getMarketIdsAndEngagementsIdsToHide(
                                                    props.values.markets,
                                                ),
                                            );
                                        }}
                                        bluePrintJsIconName={
                                            <Icon
                                                color={colors.neutral.N400}
                                                width="1.3rem"
                                                name="Refresh"
                                            />
                                        }
                                        size="small"
                                    />
                                </ButtonsPart>
                            </ModalHeader>
                            {engagementMatching.length > 0 &&
                            currentBudgetLines.length > 0 &&
                            newBudgetLines.length > 0 ? (
                                <StyledForm>
                                    <FieldArray
                                        name="markets"
                                        render={(arrayHelpers: FieldArrayRenderProps) =>
                                            props.values.markets.map(
                                                (market: EngagementMatching, index: unknown) =>
                                                    !hiddenIds.marketIds.includes(market.id) &&
                                                    marketsList(
                                                        market.id,
                                                        `${arrayHelpers.name}[${index}]`,
                                                    ),
                                            )
                                        }
                                    />
                                </StyledForm>
                            ) : (
                                <LoaderContainer>
                                    <Loader />
                                </LoaderContainer>
                            )}
                        </ModalContent>
                    </Modal>
                </Container>
            )}
        </Formik>
    );
};
export default BudgetLinesMatchingEngagementsModal;
