import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import type { BudgetStatus } from '../slices/budgetSlice';

export type StatusProps = {
    status: 'active' | 'inactive' | 'pending' | BudgetStatus;
    squareStyle?: React.CSSProperties | undefined;
    labelStyle?: React.CSSProperties;
    shouldCapitalize?: boolean;
};

const IsActiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`;

type SquareProps = {
    color: string;
};

const IsActiveSquare = styled.div<SquareProps>`
    background-color: ${({ color }) => color};
    height: 0.625rem;
    width: 0.625rem;
    margin: 0.3125rem 0.5rem 0 0;
`;

type LabelProps = {
    shouldCapitalize?: boolean;
};
const IsActiveLabel = styled.div<LabelProps>`
    white-space: nowrap;
    margin: 0;
    text-tranform: ${({ shouldCapitalize }) => (shouldCapitalize ? 'capitalize' : 'none')};
`;

const Status: React.FC<StatusProps> = ({ status, squareStyle, labelStyle, shouldCapitalize }) => {
    const { t } = useTranslation();
    let color: string;
    let translationKey: string;
    switch (status) {
        case 'active':
            color = colors.green.G400;
            translationKey = 'general.active';
            break;
        case 'inactive':
            color = colors.neutral.N75;
            translationKey = 'general.inactive';
            break;
        case 'pending':
            color = colors.yellow.Y400;
            translationKey = 'general.pending';
            break;
        case 'draft':
            color = colors.yellow.Y400;
            translationKey = 'budget.draft';
            break;
        case 'cancelled':
            color = colors.red.R400;
            translationKey = 'budget.cancelled';
            break;
        case 'replaced':
            color = colors.teal.T400;
            translationKey = 'budget.replaced';
            break;
        case 'approved':
            color = colors.green.G400;
            translationKey = 'budget.approved';
            break;
        default:
            color = colors.red.R500;
            translationKey = 'errors.noStatus';
            break;
    }

    return (
        <IsActiveContainer>
            <IsActiveSquare color={color} style={squareStyle} />
            <IsActiveLabel style={labelStyle}>{t(translationKey)}</IsActiveLabel>
        </IsActiveContainer>
    );
};

export default Status;
