import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Style
import { colors } from '../../constants/colors';
import { styles } from '../../constants/styles';

// Slices
import type { MarketInvoiceFormData, MarketInvoiceCalculatedData } from '../../slices/invoiceSlice';
import type { Permission } from '../../slices/authSlice';

// Components
import Text from '../Text';
import PermissionAwareText from '../PermissionAwareText';

// Utils
import { NumberFormatter } from '../../utils/formatters';

const HeaderRow = styled.div`
    display: flex;
    width: 100%;
    background-color: ${colors.neutral.N75};
    line-height: 1.5rem;
    border-radius: ${styles.borderRadiusSmall};
    margin-left: 0 !important;
    margin-bottom: 0.5rem;
`;

const HeaderText = styled(Text)`
    margin: 0.6rem 0 0.5rem;
    line-height: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: right;
`;

const InfoRowText = styled(Text)`
    margin-bottom: 0.3125rem;
`;

const FormulaSpan = styled.span`
    font-family: Rubik;
    font-weight: 200;
    font-size: 14px;
`;

const InfoRow = styled.div<{ borderBottom?: boolean }>`
    display: flex;
    width: 100%;
    margin-bottom: ${({ borderBottom }) => (borderBottom ? '0.5rem' : '0')};
    margin-left: 0 !important;
    border-bottom: ${({ borderBottom }) =>
        borderBottom ? `solid 0.0625rem ${colors.neutral.N75}` : 'none'};
    line-height: 1.5rem;
`;

const InfoRowAmount = styled(InfoRowText)`
    text-align: right;
    padding: 0 1rem;
    font-family: ${styles.numeralsFont};
`;

const InfoRowAmountDiv = styled.div`
    margin-bottom: 0.3125rem;
    text-align: right;
    padding: 0 1rem;
    font-family: ${styles.numeralsFont};
`;

const TotalText = styled(HeaderText)`
    color: ${colors.blue.B500};
`;

const AmmountCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.5rem;
    width: 13vw;
`;

const LabelCol = styled.div<{ windowWidth: number }>`
    display: flex;
    flex: 1;
    // 100vw (Winwdow) - 15rem (Sidebar) - 3*(13vw width + 2*0,5rem padding) (3 AmmountCol) - 2*3rem (padding) => 61vw - 24rem
    // if windowWidth > 1375 scroll menu is present so - 12rem width also => 61vw - 36rem
    min-width: calc(61vw ${({ windowWidth }) => (windowWidth > 1375 ? '- 36rem' : '- 24rem')});

    // below grid break point, Sidebar width is reduced to 6rem
    // 100vw (Winwdow) - 6rem (Sidebar) - 3*(13vw width + 2*0,5rem padding) (3 AmmountCol) - 2*3rem (padding) => 61vw - 15rem
    @media (max-width: ${styles.gridBreakpoint}) {
        min-width: calc(61vw - 15rem);
    }
    align-items: center;
    padding: 0 0.5rem;
`;

const HorizontalScrollContent = styled.div<{ windowWidth: number }>`
    // 100vw (Winwdow) - 15rem (Sidebar) - 3*(13vw width + 2*0,5rem padding) (3 AmmountCol) - 2*3rem (padding) => 61vw - 24rem
    // if windowWidth > 1375 scroll menu is present so - 12rem width -2*1rem padding also => 61vw - 38rem
    min-width: calc(61vw ${({ windowWidth }) => (windowWidth > 1375 ? '- 38rem' : '- 24rem')});

    // below grid break point, Sidebar width is reduced to 6rem
    // 100vw (Winwdow) - 6rem (Sidebar) - 3*(13vw width + 2*0,5rem padding) (3 AmmountCol) - 2*3rem (padding) => 61vw - 15rem
    @media (max-width: ${styles.gridBreakpoint}) {
        min-width: calc(61vw - 15rem);
    }
`;

type Props<T> = {
    calculatedData: MarketInvoiceCalculatedData;
    windowWidth: number;
    isEditMode: boolean;
    permissionsRequired: Permission[];
};

const MarketInvoiceSummary = <B extends MarketInvoiceFormData>({
    calculatedData,
    windowWidth,
    isEditMode,
    permissionsRequired,
}: Props<B>) => {
    const { t } = useTranslation();

    const header = (
        <HeaderRow>
            <LabelCol windowWidth={windowWidth}>
                <HeaderText type="H100" uppercased />
            </LabelCol>
            <AmmountCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.summary.currentSum')}
                </HeaderText>
            </AmmountCol>
            <AmmountCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.summary.previousSum')}
                </HeaderText>
            </AmmountCol>
            <AmmountCol>
                <HeaderText type="H100" uppercased>
                    {t('invoice.summary.monthlyAmount')}
                </HeaderText>
            </AmmountCol>
        </HeaderRow>
    );

    const information = (
        <>
            <InfoRow>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>
                        {t('invoice.summary.initialMarketAvancementsHt')}{' '}
                        <FormulaSpan>(1)</FormulaSpan>
                    </InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(
                            calculatedData.currentCumulatedInitialMarketAvancementsHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(
                            calculatedData.previousCumulatedInitialMarketAvancementsHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.initialMarketAvancementsHt)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow borderBottom>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>
                        {t('invoice.summary.advanceAmendmentHt')} <FormulaSpan>(2)</FormulaSpan>
                    </InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(
                            calculatedData.currentCumulatedOtherWorksAvancementsHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(
                            calculatedData.previousCumulatedOtherWorksAvancementsHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.otherWorksAvancementsHt)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow borderBottom>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText style={{ fontWeight: 500 }}>
                        {t('invoice.summary.avancementsTotalHt')}{' '}
                        <FormulaSpan>(3)=(1)+(2)</FormulaSpan>
                    </InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(calculatedData.currentCumulatedAvancementsTotalHt)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(calculatedData.previousCumulatedAvancementsTotalHt)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(calculatedData.avancementsTotalHt)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow borderBottom>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>
                        {t('invoice.summary.variationsAmountHt')} <FormulaSpan>(4)</FormulaSpan>
                    </InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.currentCumulatedRevisionsAmountHt)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.previousCumulatedRevisionsAmountHt)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.revisionsAmountHt)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow borderBottom>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText style={{ fontWeight: 500 }}>
                        {t('invoice.summary.totalWorkHt')} <FormulaSpan>(5)=(3)+(4)</FormulaSpan>
                    </InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(
                            calculatedData.currentCumulatedAvancementsAndRevisionsTotalHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(
                            calculatedData.previousCumulatedAvancementsAndRevisionsTotalHt,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(calculatedData.avancementsAndRevisionsTotalHt)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>
                        {t('invoice.summary.avancementTva')} <FormulaSpan>(6)</FormulaSpan>
                    </InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.avancementsTva)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>
                        {t('invoice.summary.revisionTva')} <FormulaSpan>(7)</FormulaSpan>
                    </InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.revisionTva)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow borderBottom>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>
                        {t('invoice.summary.taxesAdjustment')} <FormulaSpan>(8)</FormulaSpan>
                    </InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmountDiv>
                        <PermissionAwareText
                            editable={isEditMode}
                            permissionsRequired={permissionsRequired}
                            numberType="decimal"
                            name="taxesAdjustment"
                            type="text"
                            data-testid="taxesAdjustment"
                            errorTestId="errorTvaAdjustment"
                            marginTop="0"
                            whiteBackground
                        />
                    </InfoRowAmountDiv>
                </AmmountCol>
            </InfoRow>
            <InfoRow borderBottom>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText style={{ fontWeight: 500 }}>
                        {t('invoice.summary.totalWorkTtc')}{' '}
                        <FormulaSpan>(9)=(5)+(6)+(7)+(8)</FormulaSpan>
                    </InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmount style={{ fontWeight: 500 }}>
                        {NumberFormatter.format(calculatedData.avancementsAndRevisionsTotalTtc)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>{t('invoice.summary.holdbackTtc')}</InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmountDiv>
                        <PermissionAwareText
                            editable={isEditMode}
                            permissionsRequired={permissionsRequired}
                            numberType="decimal"
                            name="currentCumulatedHoldback"
                            type="text"
                            data-testid="currentCumulatedHoldback"
                            errorTestId="errorCurrentCumulatedHoldback"
                            marginTop="0"
                            whiteBackground
                        />
                    </InfoRowAmountDiv>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.previousCumulatedHoldback)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>{NumberFormatter.format(calculatedData.holdback)}</InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>{t('invoice.summary.initialAdvancesTtc')}</InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmountDiv>
                        <PermissionAwareText
                            editable={isEditMode}
                            permissionsRequired={permissionsRequired}
                            numberType="decimal"
                            name="currentCumulatedInitialAdvancePaymentTtc"
                            type="text"
                            data-testid="currentCumulatedInitialAdvancePaymentTtc"
                            errorTestId="errorCurrentCumulatedInitialAdvancePaymentTtc"
                            marginTop="0"
                            whiteBackground
                        />
                    </InfoRowAmountDiv>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(
                            calculatedData.previousCumulatedInitialAdvancePaymentTtc,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.initialAdvancePaymentTtc)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>{t('invoice.summary.advanceRecoveryTtc')}</InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmountDiv>
                        <PermissionAwareText
                            editable={isEditMode}
                            permissionsRequired={permissionsRequired}
                            numberType="decimal"
                            name="currentCumulatedAdvancePaymentTakebackTtc"
                            type="text"
                            data-testid="currentCumulatedAdvancePaymentTakebackTtc"
                            errorTestId="errorCurrentCumulatedAdvancePaymentTakebackTtc"
                            marginTop="0"
                            whiteBackground
                        />
                    </InfoRowAmountDiv>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(
                            calculatedData.previousCumulatedAdvancePaymentTakebackTtc,
                        )}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.advancePaymentTakebackTtc)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>{t('invoice.summary.warrantyDeductionTtc')}</InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmountDiv>
                        <PermissionAwareText
                            editable={isEditMode}
                            permissionsRequired={permissionsRequired}
                            numberType="decimal"
                            name="currentCumulatedDeductions"
                            type="text"
                            data-testid="currentCumulatedDeductions"
                            errorTestId="errorCurrentCumulatedDeductions"
                            marginTop="0"
                            whiteBackground
                        />
                    </InfoRowAmountDiv>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.previousCumulatedDeductions)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.deductions)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>{t('invoice.summary.bonusesTtc')}</InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmountDiv>
                        <PermissionAwareText
                            editable={isEditMode}
                            permissionsRequired={permissionsRequired}
                            numberType="decimal"
                            name="currentCumulatedBonusesTtc"
                            type="text"
                            data-testid="currentCumulatedBonusesTtc"
                            errorTestId="errorCurrentCumulatedBonusesTtc"
                            marginTop="0"
                            whiteBackground
                        />
                    </InfoRowAmountDiv>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.previousCumulatedBonusesTtc)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.bonusesTtc)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
            <InfoRow style={{ marginBottom: '1rem' }}>
                <LabelCol windowWidth={windowWidth}>
                    <InfoRowText>{t('invoice.summary.penaltiesTtc')}</InfoRowText>
                </LabelCol>
                <AmmountCol>
                    <InfoRowAmountDiv>
                        <PermissionAwareText
                            editable={isEditMode}
                            permissionsRequired={permissionsRequired}
                            numberType="decimal"
                            name="currentCumulatedPenalties"
                            type="text"
                            data-testid="currentCumulatedPenalties"
                            errorTestId="errorCurrentCumulatedPenalties"
                            marginTop="0"
                            whiteBackground
                        />
                    </InfoRowAmountDiv>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.previousCumulatedPenalties)}
                    </InfoRowAmount>
                </AmmountCol>
                <AmmountCol>
                    <InfoRowAmount>
                        {NumberFormatter.format(calculatedData.penalties)}
                    </InfoRowAmount>
                </AmmountCol>
            </InfoRow>
        </>
    );

    const total = (
        <HeaderRow>
            <LabelCol windowWidth={windowWidth}>
                <TotalText type="H500" style={{ textAlign: 'left', paddingLeft: '0px' }}>
                    {t('invoice.summary.totalToPayTtc')}
                </TotalText>
            </LabelCol>
            <AmmountCol>
                <TotalText type="H500" uppercased isNumeral>
                    {NumberFormatter.format(calculatedData.totalAmountTtc)}
                </TotalText>
            </AmmountCol>
        </HeaderRow>
    );
    return (
        <HorizontalScrollContent windowWidth={windowWidth}>
            <div style={{ display: 'inline-block' }}>
                {header}
                {information}
                {total}
            </div>
        </HorizontalScrollContent>
    );
};

export default MarketInvoiceSummary;
