import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError } from './common';
import { errorHandler } from './common';

export interface IPermission {
    id: number;
    code: string;
    description?: string;
    canBeUsedOnCustomRole: boolean;
}

interface IPermissionState {
    permissionsById: Record<number, IPermission>;
    loading: boolean;
    error: string | null;
}

export const initialState: IPermissionState = {
    permissionsById: {},
    loading: false,
    error: null,
};

export const getPermissions = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IPermission[],
    // First argument to the payload creator
    void,
    {
        rejectValue: ServerError;
    }
>('PERMISSION/GETALL', async (_, { rejectWithValue }) => {
    try {
        const pageSize = 200;
        const response = await axios.get<{ data?: IPermission[] }>(
            `/permissions?pageSize=${pageSize}`,
        );
        if (response.data.data && Array.isArray(response.data.data)) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const slice = createSlice({
    name: 'permission',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getPermissions.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPermissions.fulfilled, (state, { payload }) => {
            payload.forEach((item: IPermission) => {
                state.permissionsById[item.id] = item;
            });
            state.loading = false;
        });
        builder.addCase(getPermissions.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.permission.error;
export const selectPermissions = (state: RootState) =>
    Object.values(state.permission.permissionsById);
export const selectPermissionsThatCanBeUsedOnCustomRole = (state: RootState) =>
    Object.values(state.permission.permissionsById).filter(
        (permission) => permission.canBeUsedOnCustomRole,
    );
export const selectAllPermissionsByCodes =
    (permissionCodes: Array<IPermission['code']>) => (state: RootState) => {
        const permissions = selectPermissions(state);
        return permissions.filter((permission) =>
            permissionCodes.some((permissionCode) => permission.code === permissionCode),
        );
    };
export const selectPermission = (id: IPermission['id']) => (state: RootState) =>
    state.permission.permissionsById[id];
export const selectIsLoading = (state: RootState) => state.permission.loading;

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
