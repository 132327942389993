import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { isBefore } from 'date-fns';

import { showFlag } from './Flag';
import PermissionAwareDisplay from './PermissionAwareDisplay';
import PermissionAwareText from './PermissionAwareText';

import type {
    MarketsPriceHypothesis as MarketsPriceHypothesisEntity,
    MarketsPriceHypothesisFormData,
} from '../slices/marketsPriceHypothesisSlice';
import { selectError, updateMarketsPriceHypothesis } from '../slices/marketsPriceHypothesisSlice';
import type { Permission } from '../slices/authSlice';

import { colors } from '../constants/colors';
import { useAppDispatch } from '../store';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SeparatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0 0;
`;

const Separator = styled.div`
    width: 0.1rem;
    background-color: ${colors.neutral.N75};
    padding: 2rem 0 0 0;
    height: 70%;
`;

const YearContainer = styled.div`
    display: flex;
    margin: 0 1rem 0 0;
`;

const IncreasingFactorContainer = styled.div`
    display: flex;
    padding: 0 2rem 0 0;
`;

type Props = {
    marketsPriceHypothesis: MarketsPriceHypothesisEntity;
    permissionNeeded: Permission[];
    operationId: number;
    hasRightToEdit: boolean;
    separator: boolean;
    increasingFactorPlaceHolder?: number | null;
};

const MarketsPriceHypothesis: React.FC<Props> = ({
    marketsPriceHypothesis,
    permissionNeeded,
    operationId,
    hasRightToEdit,
    separator,
    increasingFactorPlaceHolder,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const serverError = useSelector(selectError);

    useEffect(() => {
        if (isUpdating && serverError) {
            showFlag('error', t('errors.error'), t(serverError));
            setIsUpdating(false);
        }
    }, [isUpdating, serverError, t]);

    const priceHypothesisSchema = Yup.object().shape({
        id: Yup.number().required(t('errors.required')),
        year: Yup.number().required(t('errors.required')),
        increasingFactor: Yup.number().test(
            'increasingFactor',
            t('markets.priceHypothesisIncreasingFactorMustBeBetween0And100'),
            (value) => {
                if (value) {
                    return value >= 0 && value <= 100;
                } else {
                    return false;
                }
            },
        ),
    });

    const initialValues: MarketsPriceHypothesisFormData = {
        ...marketsPriceHypothesis,
        increasingFactor:
            marketsPriceHypothesis.increasingFactor !== null
                ? (Number(marketsPriceHypothesis.increasingFactor) * 100).toFixed(2)
                : null,
    };

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={priceHypothesisSchema}
                onSubmit={(values: MarketsPriceHypothesisFormData, actions) => {
                    void dispatch(
                        updateMarketsPriceHypothesis({
                            marketsPriceHypothesis: {
                                ...values,
                                increasingFactor:
                                    values.increasingFactor !== null
                                        ? Number(values.increasingFactor) / 100
                                        : null,
                            },
                            operationId,
                        }),
                    );
                    setIsUpdating(true);
                    actions.setSubmitting(false);
                }}
                enableReinitialize
            >
                {(props: FormikProps<MarketsPriceHypothesisFormData>) => (
                    <Form style={{ width: '100%', display: 'flex', margin: '0 0 0.5rem 0' }}>
                        <YearContainer>
                            <PermissionAwareDisplay
                                label={t('general.year')}
                                value={marketsPriceHypothesis.year}
                                style={{
                                    marginTop: '1.5rem',
                                    marginBottom: '0',
                                    whiteSpace: 'nowrap',
                                }}
                            />
                        </YearContainer>
                        <IncreasingFactorContainer>
                            <PermissionAwareText
                                displayStyle={{
                                    margin: '1.5rem 0 0 0',
                                    whiteSpace: 'nowrap',
                                }}
                                marginTop="1.5rem"
                                marginBottom="0"
                                disabled={isBefore(
                                    new Date(`${marketsPriceHypothesis.year}-12-31`),
                                    new Date(),
                                )}
                                editable={hasRightToEdit}
                                permissionsRequired={permissionNeeded}
                                label={t('markets.increasingFactor')}
                                name="increasingFactor"
                                type="text"
                                placeholder={
                                    increasingFactorPlaceHolder !== null &&
                                    increasingFactorPlaceHolder !== undefined
                                        ? (Number(increasingFactorPlaceHolder) * 100).toFixed(2)
                                        : '0'
                                }
                                data-testid={`increasingFactor-${marketsPriceHypothesis.id}`}
                                errorTestId={`errorIncreasingFactor-${marketsPriceHypothesis.id}`}
                                onBlur={(e) => {
                                    if (props.dirty) {
                                        props.handleSubmit();
                                    }
                                }}
                                numberType="percentage"
                                whiteBackground
                                displayPlaceHolderIfNull
                            />
                        </IncreasingFactorContainer>
                    </Form>
                )}
            </Formik>
            {separator && (
                <SeparatorContainer>
                    <Separator />
                </SeparatorContainer>
            )}
        </Container>
    );
};

export default MarketsPriceHypothesis;
