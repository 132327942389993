import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import type { FormikProps } from 'formik';
import { replace, Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import format from 'date-fns/format';
import type { TFunction } from 'i18next';

import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import type { ButtonProps } from '../../components/Button';
import Button, { checkIfButtonTextIsAReactNode } from '../../components/Button';
import PermissionAwareText from '../../components/PermissionAwareText';
import PermissionAwareDisplay, { StyledLabel } from '../../components/PermissionAwareDisplay';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import InformationCard from '../../components/InformationCard';
import GridRow from '../../components/GridRow';
import GridCol from '../../components/GridCol';
import Text from '../../components/Text';
import Loader from '../../components/Loader';
import Address from '../../components/Address';
import Icon from '../../components/Icon';
import { showFlag } from '../../components/Flag';
import type { Option } from '../../components/SelectField';
import type { StatusProps } from '../../components/Status';
import Status from '../../components/Status';
import OrganizationDropdown from '../../components/dropdown/OrganizationDropdown';
import FormikOptionDropdown from '../../components/dropdown/FormikOptionDropdown';
import Modal from '../../components/Modal';
import OperationsAffectedToUser from '../../components/OperationsAffectedToUser';

import { getRoles } from '../../slices/roleSlice';
import type { IUserFormData, User } from '../../slices/userSlice';
import {
    createUser,
    selectError,
    selectUser,
    getUser,
    updateUser,
    checkEmailValidity,
    deleteUser,
    selectUserIdAdded,
    getOperationsOnWhichUserIsAffected,
    selectOperationsAffectedByUserId,
} from '../../slices/userSlice';
import {
    requestActivationEmail,
    selectUser as selectAuthenticatedUser,
    selectIsAdmin,
    loginAsUser,
} from '../../slices/authSlice';

import {
    getOrganization,
    selectOrganization,
    selectOrganizationName,
    selectOrganizationsForDropdown,
    getOrganizationsForDropdown,
} from '../../slices/organizationSlice';
import type { Site } from '../../slices/siteSlice';
import { selectAllSitesByOrganizationId, getSites } from '../../slices/siteSlice';
import { colors } from '../../constants/colors';

import { validateFormFields } from '../../utils/formValidation';
import ResetPasswordButton from '../../components/ResetPasswordButton';
import UserRolesOnOrganization from '../../components/UserRolesOnOrganization';
import type { QueryParams } from '../../slices/common';
import PermissionAwareToggle from '../../components/PermissionAwareToggle';
import { useAppDispatch } from '../../store';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const AddressRow = styled(GridRow)`
    background-color: ${colors.neutral.N25};
    padding: 1rem;
    height: 100%;
`;

const SiteContainer = styled.div<{ isEditMode: boolean }>`
    padding-top: ${({ isEditMode }) => (isEditMode ? '0.5rem' : '1rem')};
`;

const Separator = styled.div`
    background-color: ${colors.neutral.N75};
    height: 0.0625rem;
    width: 100%;
    margin: 2rem 0;
`;

const titleOptions: Array<Option<string | number>> = [
    {
        value: 'M.',
        label: 'M.',
    },
    {
        value: 'Mme.',
        label: 'Mme.',
    },
    {
        value: 'Mlle.',
        label: 'Mlle.',
    },
];

export const getUserFormSchema = (t: TFunction) =>
    Yup.object().shape({
        email: Yup.string().email(t('errors.invalidEmail')).required(t('errors.required')),
        siteId: Yup.number().typeError(t('errors.required')).required(t('errors.required')),
        profile: Yup.object().shape({
            firstName: Yup.string()
                .required(t('errors.required'))
                .max(255, t('errors.tooLong', { number: 255 })),
            lastName: Yup.string()
                .required(t('errors.required'))
                .max(255, t('errors.tooLong', { number: 255 })),
            title: Yup.string().required(t('errors.required')),
            occupation: Yup.string()
                .nullable()
                .max(255, t('errors.tooLong', { number: 255 })),
            mobilePhoneNumber: Yup.string()
                .nullable()
                .max(13, t('errors.tooLong', { number: 13 })),
            phoneNumber: Yup.string()
                .nullable()
                .max(13, t('errors.tooLong', { number: 13 })),
        }),
        roles: Yup.array().of(
            Yup.object().shape({
                id: Yup.number()
                    .nullable()
                    .required(t('errors.required'))
                    .typeError(t('errors.required')),
                userRoleId: Yup.number().nullable(),
                description: Yup.string().nullable(),
                operationId: Yup.number().nullable().typeError(t('errors.mustBeAnumber')),
                organizationId: Yup.number().nullable().typeError(t('errors.mustBeAnumber')),
            }),
        ),
    });

type Props = {
    headerProps: Partial<HeaderProps>;
};

type LocationState = {
    organizationId?: string;
    goBackTo?: string;
    breadcrumbs?: Array<{ to: string; text: string }>;
};

const UserProfile: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const serverError = useSelector(selectError);
    const {
        userId,
        organizationId,
        operationId,
    }: { userId?: string; organizationId?: string; operationId?: string } = useParams();
    let isCreation = false;
    if (userId === 'new') {
        isCreation = true;
    }

    let permissionNeeded = { code: 'USERS_EDIT', organizationId };
    if (isCreation) {
        permissionNeeded = { code: 'USERS_ADD', organizationId };
    }

    const usersEnablePermission = { code: 'USERS_ENABLE', organizationId };

    const hasRightToEditOrAddUsers = usePermissionsCheck([permissionNeeded]);
    const hasRightToDeleteUsers = usePermissionsCheck([{ code: 'USERS_DELETE', organizationId }]);
    const hasRightToEnableUsers = usePermissionsCheck([usersEnablePermission]);
    const hasRightToConnectAsUsers = usePermissionsCheck([
        { code: 'CONNECT_AS_USER', organizationId },
    ]);
    const hasRightToEditUserOrganization = usePermissionsCheck([
        {
            code: 'USERS_ORGANIZATION_EDIT',
            organizationId,
        },
    ]);

    const [requestActivationEmailDone, setRequestActivationEmailDone] = useState(false);
    const user: User | null | undefined = useSelector(
        selectUser(userId !== 'new' ? Number(userId) : null),
    );
    const operationsAffectedToUser = useSelector(
        selectOperationsAffectedByUserId(userId !== 'new' ? Number(userId) : null),
    );
    const userIdAdded = useSelector(selectUserIdAdded);
    const authenticatedUser = useSelector(selectAuthenticatedUser);

    const [selectedUserOrganizationId, setSelectedUserOrganizationId] = useState<
        string | undefined
    >(user?.organizationId);
    const [hasUserOrganizationChanged, setHasUserOrganizationChanged] = useState<boolean>(false);
    const organization = useSelector(selectOrganization(selectedUserOrganizationId));
    const organizationName = useSelector(selectOrganizationName(selectedUserOrganizationId));
    const sites = useSelector(
        selectAllSitesByOrganizationId(selectedUserOrganizationId),
        shallowEqual,
    );
    const sitesRef = useRef(sites);
    const organizations = useSelector(selectOrganizationsForDropdown);

    const initialSiteId = user?.siteId ?? sites.find((site: Site) => site.isDefault)?.id;
    const [userSiteId, setUserSiteId] = useState<number | undefined>(initialSiteId);

    const isAuthUserAdmin = useSelector(selectIsAdmin);
    const isEditedUserAdmin = Boolean(user?.roles?.find(({ name }) => name === 'ADMIN'));

    const location = useLocation();
    const navigate = useNavigate();

    const [emailError, setEmailError] = useState<string | undefined>();
    const [isEditMode, setIsEditMode] = useState<boolean>(
        location.search === '?edit' || isCreation,
    );
    const [isPending, setIsPending] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isUserOrganizationChangeModalOpen, setIsUserOrganizationChangeModalOpen] =
        useState<boolean>(false);
    const [operationsAffectedQueryParams, setOperationsAffectedQueryParams] = useState<QueryParams>(
        {
            page: 0,
            operationId: Number(operationId),
        },
    );

    const state: LocationState = location.state ?? {};

    const breadcrumbs = [
        ...(headerProps.breadcrumbs ?? []),
        {
            to: String(location.pathname),
            text: isCreation
                ? t('user.addUser')
                : `${user?.profile.firstName} ${user?.profile.lastName}`,
        },
    ];

    const shouldShowOnlyOneOperationAffected = Boolean(operationId);

    const handleUserStatusReset = () => {
        if (user) {
            if (user.isActive && user.deletedAt === null) {
                return 'active';
            } else if (user.deletedAt) {
                return 'inactive';
            }
        }
        return 'pending';
    };

    const [userStatus, setUserStatus] = useState<StatusProps['status']>(handleUserStatusReset());

    useEffect(() => {
        if (!isCreation && user) {
            setUserStatus(handleUserStatusReset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Don't want to rerender on each dependencies change
    }, [user, isCreation]);

    useEffect(() => {
        const defaultOrganizationIdOnUserCreation =
            organizationId ?? authenticatedUser?.organizationId;

        if (user) {
            setSelectedUserOrganizationId(user.organizationId);
        } else if (isCreation && defaultOrganizationIdOnUserCreation) {
            setSelectedUserOrganizationId(defaultOrganizationIdOnUserCreation);
        }
    }, [isCreation, authenticatedUser, organizationId, user]);

    useEffect(() => {
        if (selectedUserOrganizationId) {
            const oldUserOrganizationId = user?.organizationId;
            setHasUserOrganizationChanged(oldUserOrganizationId !== selectedUserOrganizationId);
        }
    }, [selectedUserOrganizationId, user]);

    useEffect(() => {
        if (selectedUserOrganizationId) {
            void dispatch(getOrganization(selectedUserOrganizationId));
        }
    }, [dispatch, selectedUserOrganizationId]);

    useEffect(() => {
        if (!hasRightToEditUserOrganization) {
            void dispatch(getOrganizationsForDropdown());
        }
    }, [dispatch, hasRightToEditUserOrganization]);

    useEffect(() => {
        if (userId && userId !== 'new') {
            void dispatch(getUser({ id: Number(userId), organizationId }));
            void dispatch(
                getOperationsOnWhichUserIsAffected({
                    id: Number(userId),
                    organizationId,
                    queryParams: operationsAffectedQueryParams,
                }),
            );
        }
    }, [dispatch, userId, organizationId, operationId, operationsAffectedQueryParams]);

    useEffect(() => {
        if (hasUserOrganizationChanged) {
            void dispatch(getRoles({ organizationId: selectedUserOrganizationId }));
            void dispatch(
                getSites({
                    page: 1,
                    pageSize: 50,
                    organizationId: selectedUserOrganizationId,
                }),
            );
            if (userId && userId !== 'new') {
                void dispatch(
                    getOperationsOnWhichUserIsAffected({
                        id: Number(userId),
                        organizationId,
                        queryParams: operationsAffectedQueryParams,
                    }),
                );
            }
            if (user && selectedUserOrganizationId === user.organizationId) {
                setUserSiteId(user.siteId);
            }
        }
    }, [
        dispatch,
        userId,
        user,
        organizationId,
        operationId,
        operationsAffectedQueryParams,
        hasUserOrganizationChanged,
        selectedUserOrganizationId,
    ]);

    const errorMessage = t('errors.error');
    let serverErrorMessage = '';
    if (serverError) {
        serverErrorMessage = t(serverError);
    }
    const successMessage = t('general.success');
    const savedMessage = t('user.profile.saved');

    useEffect(() => {
        if (isPending) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
                setIsPending(false);
                setIsEditMode(true);
            } else {
                showFlag('success', successMessage, savedMessage);
                setIsPending(false);
                if (isCreation && userIdAdded) {
                    navigate(`${location.pathname.split('/new')[0]}/${userIdAdded}`, {
                        state,
                        replace: true,
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Don't want to rerender on each dependencies change
    }, [
        serverError,
        errorMessage,
        serverErrorMessage,
        successMessage,
        savedMessage,
        userIdAdded,
        isCreation,
    ]);

    useEffect(() => {
        if (!isCreation && serverError === 'errors.userMustExist') {
            if (organizationId) {
                navigate(`/organizations/${organizationId}/users`);
            } else {
                navigate(`/settings/users`);
            }
        }
    }, [serverError, navigate, organizationId, isCreation]);

    useEffect(() => {
        if (user) {
            void dispatch(getRoles({ organizationId: user.organizationId }));
            void dispatch(
                getSites({
                    page: 1,
                    pageSize: 50,
                    organizationId: user.organizationId,
                }),
            );
        }
    }, [dispatch, user]);

    const siteOptions = sites.map((site: Site) => ({ label: site.label, value: site.id }));

    useEffect(() => {
        sitesRef.current = sites;
    }, [sites]);

    useEffect(() => {
        if (isCreation && !sitesRef.current.find((site: Site) => site.id === userSiteId)) {
            setUserSiteId(sitesRef.current.find((site: Site) => site.isDefault)?.id);
        } else if (user?.siteId && !userSiteId) {
            setUserSiteId(user.siteId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreation, sitesRef.current, user, userSiteId]);

    useEffect(() => {
        sitesRef.current = sites;
    }, [sites]);

    useEffect(() => {
        if (selectedUserOrganizationId) {
            if (user?.organizationId !== selectedUserOrganizationId) {
                setUserSiteId(sitesRef.current.find((site: Site) => site.isDefault)?.id);
            }
        }
    }, [selectedUserOrganizationId, sitesRef, user]);

    const debouncedValidation = useDebouncedCallback(async (value: string) => {
        try {
            await checkEmailValidity(value);
            setEmailError(undefined);
        } catch (e: unknown) {
            setEmailError(t('errors.emailNotAvailable'));
        }
    }, 500);

    if (userId && userId !== 'new' && !user) {
        return <Loader overlay />;
    }

    const computeAddressFromSiteId = (siteId: number | undefined) => {
        if (!siteId) {
            return {};
        }
        const site = sites.find((siteInstance: Site) => siteInstance.id === siteId);
        return {
            [t('general.address.billingAddress')]: site?.address1,
            [t('general.address.zip')]: site?.postalCode,
            [t('general.address.city')]: site?.city,
            [t('general.address.country')]: site?.country,
        };
    };
    const computeDeleteButtonOutOfProps = (): ButtonProps[] => {
        if (
            hasRightToEditOrAddUsers &&
            ((isEditedUserAdmin && isAuthUserAdmin) ||
                !isEditedUserAdmin ||
                (user && authenticatedUser && user.id === authenticatedUser.id))
        ) {
            const buttons: ButtonProps[] = [];

            if (hasRightToDeleteUsers && user && !user.cantBeDeleted && !user.deletedAt) {
                buttons.push({
                    'data-testid': 'deleteUser',
                    aspect: 'onlyIcon',
                    iconName: 'DeleteOutline',
                    onClick() {
                        setIsDeleteModalOpen(true);
                    },
                });
                return buttons;
            }
        }
        return [];
    };

    const computeEditButtonsOutOfProps = (props: FormikProps<IUserFormData>): ButtonProps[] => {
        if (
            hasRightToEditOrAddUsers &&
            ((isEditedUserAdmin && isAuthUserAdmin) ||
                !isEditedUserAdmin ||
                (user && authenticatedUser && user.id === authenticatedUser.id))
        ) {
            if (isEditMode) {
                return [
                    {
                        text: t('general.cancel'),
                        'data-testid': 'cancelUserProfile',
                        aspect: 'secondary',
                        onClick() {
                            if (isCreation) {
                                navigate(-1);
                            } else {
                                navigate(location.pathname.replace('?edit', ''), {
                                    state,
                                    replace: true,
                                });
                                setIsEditMode(false);
                                setUserStatus(handleUserStatusReset());
                                props.handleReset();
                                setSelectedUserOrganizationId(user?.organizationId);
                            }
                        },
                    },
                    {
                        text: t('general.saveModifications'),
                        'data-testid': 'submitUserProfile',
                        aspect: 'primary',
                        async onClick() {
                            await validateFormFields(['roles'], props);
                            props.handleSubmit();
                        },
                    },
                ];
            } else {
                const buttons: ButtonProps[] = [];

                buttons.push({
                    text: t('user.editInformation'),
                    'data-testid': 'modifyUserProfile',
                    aspect: 'primary',
                    bluePrintJsIconName: <Icon name="Edit" width="1.125rem" />,
                    onClick() {
                        navigate(`${location.pathname}?edit`, { state: true });
                        setIsEditMode(true);
                    },
                });

                return buttons;
            }
        }
        return [];
    };

    const handleOperationsAffectedSearch = (value: string) => {
        if (value === '') {
            setOperationsAffectedQueryParams({ page: 1, organizationId });
        } else {
            setOperationsAffectedQueryParams({
                page: 1,
                query: value,
                match: 'any',
                fields: [
                    'internal_number',
                    'label',
                    'long_label',
                    'postal_code',
                    'city',
                    'clientOrganization.denomination',
                    'clientOrganization.denomination_usuelle',
                    'clientOrganization.denomination_usuelle1',
                    'projectOwnerOrganization.denomination',
                    'projectOwnerOrganization.denomination_usuelle',
                    'projectOwnerOrganization.denomination_usuelle1',
                    'delegateProjectOwnerOrganization.denomination',
                    'delegateProjectOwnerOrganization.denomination_usuelle',
                    'delegateProjectOwnerOrganization.denomination_usuelle1',
                ],
                orderBy: 'label',
                order: 'asc',
                pageSize: 100,
                organizationId,
            });
        }
    };

    const handleUserStatusToggleChange = () => {
        if (userStatus === 'pending' || userStatus === 'active') {
            setUserStatus('inactive');
        } else {
            setUserStatus(user?.isActive ? 'active' : 'pending');
        }
    };

    const handleDeletedAtBeforeSubmit = (values: IUserFormData) => {
        if (user && !values.isEnabled) {
            return user.deletedAt ?? Date.now();
        }
        return null;
    };

    const keepUserRolesRespectingTheirScope = (values: IUserFormData) => {
        if (hasUserOrganizationChanged) {
            return values.roles.filter(
                (userRole) =>
                    // let's keep only user roles having the new organization as scope
                    userRole.userRoleOrganizationId === selectedUserOrganizationId ||
                    // and ADMIN role if present
                    (userRole.userRoleOrganizationId === null &&
                        userRole.userRoleOperationId === null),
            );
        }

        return values.roles;
    };

    const handleSubmit = (values: IUserFormData) => {
        const payload = {
            ...values,
            organizationId: selectedUserOrganizationId,
            siteId: userSiteId,
            deletedAt: handleDeletedAtBeforeSubmit(values),
            roles: keepUserRolesRespectingTheirScope(values),
        };
        delete payload.site;
        delete payload.isEnabled;

        if (isCreation) {
            void dispatch(createUser(payload));
        } else {
            void dispatch(updateUser({ user: payload }));
        }
        setIsPending(true);
        setIsEditMode(false);
        setIsUserOrganizationChangeModalOpen(false);
        navigate(location.pathname.replace('?edit', ''), { state, replace: true });
    };

    const userFormInitialValues: IUserFormData = {
        id: user?.id,
        email: user?.email ?? '',
        profile: {
            firstName: user?.profile.firstName ?? '',
            lastName: user?.profile.lastName ?? '',
            title: user?.profile.title ?? 'M.',
            mobilePhoneNumber: user?.profile.mobilePhoneNumber ?? '',
            phoneNumber: user?.profile.phoneNumber ?? '',
            occupation: user?.profile.occupation ?? '',
        },
        organizationId:
            selectedUserOrganizationId ?? organizationId ?? authenticatedUser?.organizationId ?? '',
        siteId: initialSiteId,
        roles: selectedUserOrganizationId !== user?.organizationId ? [] : user?.roles ?? [],
        isEnabled: user?.deletedAt === null ? true : undefined,
    };

    return (
        <Formik
            initialValues={userFormInitialValues}
            validationSchema={getUserFormSchema(t)}
            onSubmit={(values: IUserFormData) => {
                if (isCreation || !hasUserOrganizationChanged) {
                    handleSubmit(values);
                } else {
                    setIsUserOrganizationChangeModalOpen(true);
                }
            }}
            validateOnChange
            enableReinitialize
        >
            {(props: FormikProps<IUserFormData>) => {
                const handleRoleToggleClick = (
                    roleId: number,
                    checked: boolean,
                    isAdminRole: boolean,
                ) => {
                    if (checked) {
                        props.setFieldValue('roles', [
                            ...props.values.roles,
                            {
                                id: roleId,
                                userRoleOrganizationId: isAdminRole
                                    ? null
                                    : selectedUserOrganizationId,
                            },
                        ]);
                    } else {
                        props.setFieldValue(
                            'roles',
                            props.values.roles.filter((role) => role.id !== roleId),
                        );
                    }
                };
                return (
                    <MainLayout
                        header={
                            <Header
                                {...headerProps}
                                title={
                                    isCreation || !user
                                        ? t('user.addUser')
                                        : `${user.profile.firstName} ${user.profile.lastName}`
                                }
                                breadcrumbs={breadcrumbs}
                                isDirty={
                                    Object.keys(props.touched).length > 0 &&
                                    !props.isSubmitting &&
                                    isEditMode
                                }
                                buttons={computeDeleteButtonOutOfProps()}
                                showBackButton
                            />
                        }
                    >
                        <Container>
                            <Form>
                                <InformationCard
                                    title={t('organization.information.informationLabel')}
                                    bigHeaderSpace={false}
                                    headerTrailingComponent={
                                        <>
                                            {!isCreation &&
                                                user &&
                                                userStatus === 'active' &&
                                                hasRightToEditOrAddUsers && (
                                                    <ResetPasswordButton userEmail={user.email} />
                                                )}

                                            {computeEditButtonsOutOfProps(props).map(
                                                (button, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key -- no other choice than using index
                                                    <React.Fragment key={index}>
                                                        {checkIfButtonTextIsAReactNode(
                                                            button.text,
                                                        ) ? (
                                                            button.text
                                                        ) : (
                                                            <Button
                                                                {...button}
                                                                size="medium"
                                                                style={{ marginLeft: '1.3125rem' }}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ),
                                            )}
                                        </>
                                    }
                                >
                                    <GridCol defaultScreen={12}>
                                        <GridRow>
                                            <GridCol defaultScreen={3}>
                                                {isEditMode ? (
                                                    <FormikOptionDropdown
                                                        items={titleOptions}
                                                        name="profile.title"
                                                        label={t('user.profile.title')}
                                                        initialNameDiplay={
                                                            titleOptions.find(
                                                                ({ value }) =>
                                                                    value ===
                                                                    props.values.profile.title,
                                                            )?.label
                                                        }
                                                        data-testid="userProfileTitle"
                                                        errorTestId="errorUserProfileTitle"
                                                    />
                                                ) : (
                                                    <PermissionAwareDisplay
                                                        data-testid="userProfileTitle"
                                                        value={
                                                            titleOptions.find(
                                                                ({ value }) =>
                                                                    value ===
                                                                    props.values.profile.title,
                                                            )?.label
                                                        }
                                                        label={t('user.profile.title')}
                                                    />
                                                )}
                                            </GridCol>
                                            <GridCol defaultScreen={3}>
                                                <PermissionAwareText
                                                    editable={isEditMode}
                                                    permissionsRequired={[permissionNeeded]}
                                                    label={t('user.profile.lastName')}
                                                    name="profile.lastName"
                                                    type="text"
                                                    placeholder={t('user.profile.lastName')}
                                                    data-testid="userProfileLastName"
                                                    errorTestId="errorLastName"
                                                    marginTop="0"
                                                    whiteBackground
                                                    noMarginTop
                                                />
                                            </GridCol>
                                            <GridCol defaultScreen={3}>
                                                <PermissionAwareText
                                                    editable={isEditMode}
                                                    permissionsRequired={[permissionNeeded]}
                                                    label={t('user.profile.firstName')}
                                                    name="profile.firstName"
                                                    type="text"
                                                    placeholder={t('user.profile.firstName')}
                                                    data-testid="userProfileFirstName"
                                                    errorTestId="errorFirstName"
                                                    marginTop="0"
                                                    whiteBackground
                                                    noMarginTop
                                                />
                                            </GridCol>
                                            <GridCol
                                                defaultScreen={3}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                {!isCreation && !isEditMode && (
                                                    <Status status={userStatus} />
                                                )}
                                                {isEditMode &&
                                                    !isCreation &&
                                                    hasRightToEnableUsers && (
                                                        <PermissionAwareToggle
                                                            editable={isEditMode}
                                                            permissionsRequired={[
                                                                usersEnablePermission,
                                                            ]}
                                                            label={t(`general.${userStatus}`)}
                                                            name="isEnabled"
                                                            data-testid="userProfileManagerToggle"
                                                            checked={props.values.isEnabled}
                                                            onChange={handleUserStatusToggleChange}
                                                            style={{ paddingLeft: 0 }}
                                                        />
                                                    )}
                                            </GridCol>
                                        </GridRow>
                                        <GridRow style={{ marginTop: '1.5rem' }}>
                                            <GridCol
                                                defaultScreen={3}
                                                data-testid={
                                                    emailError ? 'emailErrorAlreadyTaken' : null
                                                }
                                            >
                                                <PermissionAwareText
                                                    editable={isEditMode}
                                                    permissionsRequired={[permissionNeeded]}
                                                    name="email"
                                                    label={t('user.list.email')}
                                                    type="email"
                                                    placeholder="bonjour@email.com"
                                                    data-testid="userProfileEmail"
                                                    errorTestId="errorEmail"
                                                    disabled={!isCreation}
                                                    onChange={(e) => {
                                                        void debouncedValidation(e.target.value);
                                                    }}
                                                    rightComponent={
                                                        emailError ? (
                                                            <Icon
                                                                tooltip={emailError}
                                                                name="Cancel"
                                                                color={colors.red.R400}
                                                            />
                                                        ) : undefined
                                                    }
                                                    marginTop="0"
                                                    whiteBackground
                                                    noMarginTop
                                                />
                                            </GridCol>
                                            <GridCol defaultScreen={3}>
                                                <PermissionAwareText
                                                    editable={isEditMode}
                                                    permissionsRequired={[permissionNeeded]}
                                                    label={t('user.profile.mobilePhone')}
                                                    name="profile.mobilePhoneNumber"
                                                    type="text"
                                                    placeholder={t('user.profile.mobilePhone')}
                                                    data-testid="userProfileMobilePhoneNumber"
                                                    errorTestId="errorMobilePhoneNumber"
                                                    marginTop="0"
                                                    whiteBackground
                                                    isOptional
                                                    noMarginTop
                                                />
                                            </GridCol>
                                            <GridCol defaultScreen={3}>
                                                <PermissionAwareText
                                                    editable={isEditMode}
                                                    permissionsRequired={[permissionNeeded]}
                                                    label={t('user.profile.phone')}
                                                    name="profile.phoneNumber"
                                                    type="text"
                                                    placeholder={t('user.profile.phone')}
                                                    data-testid="userProfilePhoneNumber"
                                                    errorTestId="errorPhoneNumber"
                                                    marginTop="0"
                                                    whiteBackground
                                                    isOptional
                                                    noMarginTop
                                                />
                                            </GridCol>
                                            <GridCol defaultScreen={3}>
                                                <PermissionAwareText
                                                    editable={isEditMode}
                                                    permissionsRequired={[permissionNeeded]}
                                                    label={t('user.profile.occupation')}
                                                    name="profile.occupation"
                                                    type="text"
                                                    placeholder={t('user.profile.occupation')}
                                                    data-testid="userProfileOccupation"
                                                    errorTestId="errorOccupation"
                                                    marginTop="0"
                                                    whiteBackground
                                                    isOptional
                                                    noMarginTop
                                                />
                                            </GridCol>
                                        </GridRow>
                                        <GridRow>
                                            <GridCol defaultScreen={12}>
                                                <Separator />
                                            </GridCol>
                                        </GridRow>
                                        {organization && (
                                            <>
                                                <GridRow style={{ marginBottom: '0.5rem' }}>
                                                    <GridCol defaultScreen={12}>
                                                        <Text type="H500" style={{ marginTop: 0 }}>
                                                            {t('user.organization')}
                                                        </Text>
                                                    </GridCol>
                                                </GridRow>
                                                <GridRow>
                                                    <GridCol defaultScreen={2}>
                                                        <PermissionAwareDisplay
                                                            data-testid="userProfileOrganizationId"
                                                            value={organization.siret}
                                                            label={t(
                                                                'user.profile.organization.siret',
                                                            )}
                                                            style={{ marginTop: '0' }}
                                                        />
                                                    </GridCol>
                                                    <GridCol defaultScreen={4}>
                                                        {(isCreation || isEditMode) &&
                                                        hasRightToEditUserOrganization ? (
                                                            <OrganizationDropdown
                                                                label={t(
                                                                    'user.profile.organization.name',
                                                                )}
                                                                initialOrganizationId={
                                                                    selectedUserOrganizationId
                                                                }
                                                                name="organizationId"
                                                                data-testid="userProfileOrganizationName"
                                                                items={organizations}
                                                                handleChange={({ id }) => {
                                                                    setSelectedUserOrganizationId(
                                                                        id,
                                                                    );
                                                                }}
                                                                filterable
                                                            />
                                                        ) : (
                                                            <PermissionAwareDisplay
                                                                data-testid="userProfileOrganizationName"
                                                                value={organizationName}
                                                                label={t(
                                                                    'user.profile.organization.name',
                                                                )}
                                                                style={{ marginTop: '0' }}
                                                            />
                                                        )}
                                                    </GridCol>
                                                    <GridCol defaultScreen={6}>
                                                        <StyledLabel color={colors.neutral.N300}>
                                                            {t('user.profile.rolesOnOrganization')}
                                                        </StyledLabel>
                                                        <UserRolesOnOrganization
                                                            organization={organization}
                                                            userRoles={props.values.roles}
                                                            isEditMode={isEditMode}
                                                            permissionNeeded={permissionNeeded}
                                                            onChange={handleRoleToggleClick}
                                                        />
                                                    </GridCol>
                                                </GridRow>
                                            </>
                                        )}
                                        <GridRow style={{ marginTop: '1rem' }}>
                                            <GridCol defaultScreen={4}>
                                                <SiteContainer isEditMode={isEditMode}>
                                                    {isEditMode && siteOptions.length > 0 ? (
                                                        <FormikOptionDropdown
                                                            items={siteOptions}
                                                            name="siteId"
                                                            label={t('user.profile.selectedSite')}
                                                            initialNameDiplay={
                                                                siteOptions.find(
                                                                    ({ value }) =>
                                                                        value === userSiteId,
                                                                )?.label ??
                                                                t('user.profile.selectASite')
                                                            }
                                                            handleChange={({ value }) =>
                                                                setUserSiteId(value)
                                                            }
                                                            data-testid="userProfileSiteId"
                                                            errorTestId="errorUserProfileSiteId"
                                                        />
                                                    ) : (
                                                        <PermissionAwareDisplay
                                                            data-testid="userProfileSiteId"
                                                            value={
                                                                siteOptions.find(
                                                                    ({ value }) =>
                                                                        value === userSiteId,
                                                                )?.label
                                                            }
                                                            label={t('user.profile.selectedSite')}
                                                            style={{ marginTop: '0' }}
                                                        />
                                                    )}
                                                </SiteContainer>
                                            </GridCol>
                                            <GridCol defaultScreen={8}>
                                                {userSiteId && (
                                                    <AddressRow>
                                                        <Address
                                                            colSizes={[4, 3, 3, 2]}
                                                            data={computeAddressFromSiteId(
                                                                userSiteId && Number(userSiteId),
                                                            )}
                                                        />
                                                    </AddressRow>
                                                )}
                                            </GridCol>
                                        </GridRow>
                                    </GridCol>
                                </InformationCard>
                                {!isCreation && (
                                    <>
                                        <InformationCard
                                            title={
                                                shouldShowOnlyOneOperationAffected
                                                    ? t('user.affectedOperation')
                                                    : t('user.affectedOperations')
                                            }
                                            bigHeaderSpace={false}
                                            showBadge={!shouldShowOnlyOneOperationAffected}
                                            badgeText={operationsAffectedToUser?.length}
                                            badgeTextColor={colors.blue.B400}
                                            badgeBackground={colors.neutral.N75}
                                            showSearchBar={!shouldShowOnlyOneOperationAffected}
                                            handleSearch={handleOperationsAffectedSearch}
                                        >
                                            <GridCol defaultScreen={12}>
                                                {operationsAffectedToUser && (
                                                    <OperationsAffectedToUser
                                                        operations={operationsAffectedToUser}
                                                        userRoles={user?.roles}
                                                    />
                                                )}
                                            </GridCol>
                                        </InformationCard>
                                        <InformationCard
                                            title={t('user.activity')}
                                            bigHeaderSpace={false}
                                            subtitle={
                                                user &&
                                                !user.isActive &&
                                                user.lastActivationEmailSentAt
                                                    ? t(
                                                          'user.profile.lastVerificationEmailSentAt',
                                                          {
                                                              date: format(
                                                                  new Date(
                                                                      user.lastActivationEmailSentAt,
                                                                  ),
                                                                  'dd/MM/yyyy HH:mm',
                                                              ),
                                                          },
                                                      )
                                                    : null
                                            }
                                            headerTrailingComponent={
                                                <>
                                                    {user && hasRightToConnectAsUsers && (
                                                        <Button
                                                            onClick={() => {
                                                                if (authenticatedUser) {
                                                                    void dispatch(
                                                                        loginAsUser({
                                                                            email: user.email,
                                                                            userImpersonating:
                                                                                authenticatedUser.id,
                                                                        }),
                                                                    );
                                                                }
                                                            }}
                                                            text={t('user.profile.loginAs')}
                                                            style={{ marginLeft: '1rem' }}
                                                        />
                                                    )}
                                                    {user &&
                                                        userStatus === 'pending' &&
                                                        hasRightToEditOrAddUsers && (
                                                            <Button
                                                                onClick={() => {
                                                                    void dispatch(
                                                                        requestActivationEmail({
                                                                            email: user.email,
                                                                        }),
                                                                    );
                                                                    setRequestActivationEmailDone(
                                                                        true,
                                                                    );
                                                                    setTimeout(() => {
                                                                        showFlag(
                                                                            'success',
                                                                            t('general.success'),
                                                                            t(
                                                                                'user.profile.sentActivationEmail',
                                                                            ),
                                                                        );
                                                                        void dispatch(
                                                                            getUser({
                                                                                id: Number(userId),
                                                                                organizationId,
                                                                            }),
                                                                        );
                                                                    }, 1000);
                                                                }}
                                                                disabled={
                                                                    requestActivationEmailDone
                                                                }
                                                                data-testid="requestActivationEmailButton"
                                                                aspect="secondary"
                                                                text={t(
                                                                    'user.profile.requestActivationEmail',
                                                                )}
                                                                style={{ marginLeft: '1rem' }}
                                                            />
                                                        )}
                                                </>
                                            }
                                        >
                                            <GridCol defaultScreen={12}>
                                                <GridRow>
                                                    <GridCol defaultScreen={3}>
                                                        <PermissionAwareDisplay
                                                            data-testid="UserProfileSelectedSite"
                                                            value={
                                                                user?.lastConnectedAt
                                                                    ? format(
                                                                          new Date(
                                                                              user.lastConnectedAt,
                                                                          ),
                                                                          'dd/MM/yyyy HH:mm',
                                                                      )
                                                                    : t('errors.dateNotDefined')
                                                            }
                                                            label={t(
                                                                'user.profile.lastConnectedAt',
                                                            )}
                                                            style={{ marginTop: '0' }}
                                                        />
                                                    </GridCol>
                                                    <GridCol defaultScreen={3}>
                                                        <PermissionAwareDisplay
                                                            data-testid="UserProfileSelectedSite"
                                                            value={
                                                                user?.createdAt
                                                                    ? format(
                                                                          new Date(user.createdAt),
                                                                          'dd/MM/yyyy HH:mm',
                                                                      )
                                                                    : t('errors.dateNotDefined')
                                                            }
                                                            label={t('user.profile.createdAt')}
                                                            style={{ marginTop: '0' }}
                                                        />
                                                    </GridCol>
                                                </GridRow>
                                            </GridCol>
                                        </InformationCard>
                                    </>
                                )}
                            </Form>
                            <Modal
                                isOpen={isDeleteModalOpen}
                                onCloseButtonPressed={() => {
                                    setIsDeleteModalOpen(false);
                                }}
                                buttons={[
                                    {
                                        text: t('general.cancel'),
                                        aspect: 'secondary',
                                        'data-testid': 'cancelDelete',
                                        onClick() {
                                            setIsDeleteModalOpen(false);
                                        },
                                    },
                                    {
                                        text: t('general.confirm'),
                                        aspect: 'primary',
                                        'data-testid': 'confirm',
                                        onClick() {
                                            if (hasRightToDeleteUsers && user) {
                                                void dispatch(
                                                    deleteUser({ id: user.id, organizationId }),
                                                );
                                                navigate(state.goBackTo ?? '/settings/users');
                                            }
                                        },
                                    },
                                ]}
                                title={t('user.deletion')}
                                size="small"
                                iconName="Warning"
                                iconColor={colors.yellow.Y400}
                                centerTitle
                            >
                                <Text style={{ margin: '0 0 1rem 0' }}>
                                    {t('user.confirmDeletion')}
                                </Text>
                            </Modal>
                            <Modal
                                isOpen={isUserOrganizationChangeModalOpen}
                                onCloseButtonPressed={() => {
                                    setIsUserOrganizationChangeModalOpen(false);
                                }}
                                buttons={[
                                    {
                                        text: t('general.cancel'),
                                        aspect: 'secondary',
                                        'data-testid': 'cancelUserOrganizationChange',
                                        onClick() {
                                            setIsUserOrganizationChangeModalOpen(false);
                                        },
                                    },
                                    {
                                        text: t('general.confirm'),
                                        aspect: 'primary',
                                        'data-testid': 'confirmUserOrganizationChange',
                                        onClick() {
                                            if (hasRightToEditOrAddUsers && user) {
                                                handleSubmit(props.values);
                                            }
                                        },
                                    },
                                ]}
                                title={t('user.profile.confirmOrganizationChange')}
                                size="small"
                                iconName="Warning"
                                iconColor={colors.yellow.Y400}
                                centerTitle
                            >
                                <Text style={{ margin: '0 0 1rem 0' }}>
                                    {t('user.profile.confirmOrganizationChangeMessage', {
                                        userEmail: user?.email,
                                    })}
                                </Text>
                            </Modal>
                        </Container>
                    </MainLayout>
                );
            }}
        </Formik>
    );
};

export default UserProfile;
