import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import GridRow from './GridRow';
import GridCol from './GridCol';
import Icon from './Icon';
import PermissionAwareText from './PermissionAwareText';

// Data
import type { TvaCodeFormData } from '../slices/tvaCodeSlice';
import type { Permission } from '../slices/authSlice';

// Style
import { colors } from '../constants/colors';

const TvaCodeContainer = styled(GridCol)<{ last: string }>`
    flex: 1 1 auto;
    border-right: ${({ last }) => last === 'false' && `solid 0.1rem ${colors.neutral.N75}`};
`;

const shouldTvaCodeBeDisabled = (index: number, tvaCodes: TvaCodeFormData[]): boolean => {
    const currentTvaCode = tvaCodes[index];

    if (currentTvaCode.isUsedInDb) {
        return true;
    }

    const isFirstTvaCode = index === 0;
    if (isFirstTvaCode) {
        return false;
    }

    const previousTvaCode = tvaCodes[index - 1];
    const isPreviousTvaCodeFilled = previousTvaCode.value !== '' && previousTvaCode.value !== null;
    if (!isPreviousTvaCodeFilled) {
        return true;
    }

    return false;
};

type Props = {
    tvaCodes: TvaCodeFormData[];
    index: number;
    isEditMode: boolean;
    permissionsRequired: Permission[];
    editable: boolean;
};

const OperationTvaCode: React.FC<Props> = ({
    tvaCodes,
    index,
    isEditMode,
    permissionsRequired,
    editable,
}) => {
    const { t } = useTranslation();

    return (
        <TvaCodeContainer
            key={index}
            defaultScreen={4}
            smallScreen={4}
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands -- index is a number
            last={tvaCodes.length === index + 1 ? 'true' : 'false'}
        >
            <GridRow style={{ justifyContent: 'center', alignItems: 'center' }}>
                <GridCol defaultScreen={4} smallScreen={4} style={{ paddingRight: '0' }}>
                    <PermissionAwareText
                        marginTop="0"
                        type="text"
                        numberType="percentage"
                        name={`tvaCodes[${index}].value`}
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands -- index is a number
                        label={`${t('operation.tvaCodes.inputLabel')} ${index + 1} en %`}
                        defaultEmptyValue="-"
                        editable={editable}
                        permissionsRequired={permissionsRequired}
                        disabled={shouldTvaCodeBeDisabled(index, tvaCodes)}
                        data-testid={`tvaCode-${index}`}
                        errorTestId={`errorTvaCode-${index}`}
                        whiteBackground
                    />
                </GridCol>
                {isEditMode && tvaCodes[index].isUsedInDb && (
                    <GridCol defaultScreen={2} smallScreen={2} style={{ paddingLeft: '0' }}>
                        <Icon
                            tooltip={t('operation.tvaCodes.tvaCodeIsUsed')}
                            name="Info"
                            color={colors.neutral.N75}
                            style={{ marginLeft: '0.5rem' }}
                        />
                    </GridCol>
                )}
            </GridRow>
        </TvaCodeContainer>
    );
};

export default OperationTvaCode;
