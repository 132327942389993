import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Collapse } from '@blueprintjs/core';
import { useParams } from 'react-router-dom';

import { colors } from '../constants/colors';

import Icon from './Icon';
import Button from './Button';
import Modal from './Modal';
import Text from './Text';
import LabelPermissionAwareText from './LabelPermissionAwareText';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';

import type { Label, LabelValue } from '../slices/labelSlice';
import { createLabelValue, deleteLabel, updateLabel } from '../slices/labelSlice';

import type { Permission } from '../slices/authSlice';
import Toggle from './Toggle';
import PermissionAwareDisplay from './PermissionAwareDisplay';
import { useAppDispatch } from '../store';

type CollapseProps = {
    editable: boolean;
};

const List = styled.div<CollapseProps>`
    width: 100%;
    border: 0.0938rem solid ${colors.neutral.N75};
    border-radius: 0.3125rem;
    box-sizing: content-box;
    background-color: ${({ editable }) =>
        editable ? String(colors.other.white) : String(colors.neutral.N25)};
`;

type HeaderProps = {
    isOpen: boolean;
    editable: boolean;
};

const ListHeader = styled.div<HeaderProps>`
    display: flex;
    flex-direction: column;
    border-top: 0.3125rem solid
        ${({ editable }) => (editable ? String(colors.green.G400) : String(colors.blue.B400))};
    ${({ isOpen }) =>
        isOpen
            ? 'border-top-left-radius: 0.3125rem; border-top-right-radius: 0.3125rem;'
            : 'border-radius: 0.3125rem;'};
`;

const IconsPart = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: ${colors.other.white};
`;

const ButtonsPart = styled.div`
    display: flex;
    padding: 0 1rem;
    justify-content: center;
    flex-direction: row;
    > * {
        margin: 1rem 0.5rem 0rem 0.5rem;
    }
    box-sizing: border-box;
`;

const StyledCollapse = styled(Collapse)<CollapseProps>`
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;
    .bp4-collapse-body {
        width: 100%;
    }
`;

const ValuesHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    > p {
        margin-bottom: 0;
    }
`;

const StyledIcon = styled(Icon)`
    cursor: pointer;
`;

const IconWrapper = styled.div`
    cursor: pointer;
`;

const MandatoryPart = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    background-color: ${colors.neutral.N50};
    border-top: 0.0938rem solid ${colors.neutral.N75};
    border-bottom: 0.0938rem solid ${colors.neutral.N75};
    p,
    div {
        margin: 0;
        text-align: right;
    }
    section {
        padding: 0;
        div {
            margin: auto 0;
        }
    }
`;

type Props = {
    label?: Label;
    editPermissions: Permission[];
    deletePermissions: Permission[];
    linkableLabels: Label[];
    popoverOpen?: LabelValue['id'];
    handlePopover?: (id: LabelValue['id']) => void;
};

const LabelManager: React.FC<Props> = ({
    label,
    editPermissions,
    deletePermissions,
    linkableLabels,
    popoverOpen,
    handlePopover,
}) => {
    const { t } = useTranslation();
    const { organizationId, operationId }: { organizationId?: string; operationId?: string } =
        useParams();
    const [isOpen, setIsOpen] = useState(true);
    const [editable, setEditable] = useState(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);
    const [errorsList, setErrorsList] = useState<Array<{ id: string; error: boolean }>>([]);
    const dispatch = useAppDispatch();
    const isImmutable = Boolean(label?.isImmutable);

    const hasDeletePermission = usePermissionsCheck(deletePermissions);
    const hasEditPermission = usePermissionsCheck(editPermissions);

    useEffect(() => {
        if (!isOpen) {
            setEditable(false);
        }
    }, [isOpen]);

    const handleModalOpening = () => {
        setModalOpen(!modalOpen);
    };

    const handleError = ({ id, error }: { id: string; error: boolean }) => {
        const newList = [...errorsList];
        const index = errorsList.findIndex((errorObject) => errorObject.id === id);
        if (index >= 0 && newList[index].error !== error) {
            newList[index] = { id, error };
            setErrorsList(newList);
        } else if (index < 0) {
            newList.push({ id, error });
            setErrorsList(newList);
        }
    };

    return (
        <List editable={editable}>
            <ListHeader isOpen={isOpen} editable={editable}>
                <IconsPart>
                    <StyledIcon
                        name={isOpen ? 'Remove' : 'Add'}
                        data-testid={isOpen ? 'removeIcon' : 'addIcon'}
                        onClick={() => setIsOpen(!isOpen)}
                    />
                    {!isImmutable && hasDeletePermission && (
                        <IconWrapper
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                        >
                            <Icon
                                color={hovered ? colors.red.R500 : colors.neutral.N300}
                                name="DeleteOutline"
                                onClick={handleModalOpening}
                                data-testid="deleteLabelIcon"
                            />
                        </IconWrapper>
                    )}
                </IconsPart>
                <MandatoryPart>
                    <Text color={colors.neutral.N400}>{t('generalLabels.mandatory')}</Text>
                    {label &&
                        (hasEditPermission ? (
                            <Toggle
                                checked={label.isMandatory}
                                aspect="small"
                                onChange={() =>
                                    dispatch(
                                        updateLabel({
                                            label: { ...label, isMandatory: !label.isMandatory },
                                            organizationId,
                                            operationId: operationId
                                                ? Number(operationId)
                                                : undefined,
                                        }),
                                    )
                                }
                            />
                        ) : (
                            <PermissionAwareDisplay
                                value={label.isMandatory ? t('general.yes') : t('general.no')}
                            />
                        ))}
                </MandatoryPart>
                <LabelPermissionAwareText
                    editable={editable}
                    permissionsRequired={editPermissions}
                    label={label}
                    handleError={handleError}
                />
            </ListHeader>
            <StyledCollapse isOpen={isOpen} editable={editable}>
                {/* add data-testid on blueprintJS Collapse seems not working so I added a <div> */}
                <div data-testid="collapseOpen">
                    {label?.labelValues && label.labelValues.length > 0 && (
                        <ValuesHeader>
                            <Text size="11px" color={colors.neutral.N300} uppercased>
                                {t('generalLabels.label')}
                            </Text>
                            <Text size="11px" color={colors.neutral.N300} uppercased>
                                {!editable && t('generalLabels.filter')}
                            </Text>
                        </ValuesHeader>
                    )}
                    {label?.labelValues.map((labelValue: LabelValue) => (
                        <LabelPermissionAwareText
                            key={labelValue.id}
                            editable={editable}
                            permissionsRequired={editPermissions}
                            labelValue={labelValue}
                            linkableLabels={linkableLabels}
                            handleError={handleError}
                            popoverOpen={popoverOpen}
                            handlePopover={handlePopover}
                        />
                    ))}
                    {editable && label ? (
                        <ButtonsPart>
                            <Button
                                aspect="secondary"
                                text={t('general.add')}
                                size="small"
                                data-testid="addLabelValue"
                                onClick={() =>
                                    dispatch(
                                        createLabelValue({
                                            labelValue: {
                                                value: '',
                                                labelId: label.id,
                                            },
                                            organizationId,
                                            operationId: operationId
                                                ? Number(operationId)
                                                : undefined,
                                        }),
                                    )
                                }
                            />
                            <Button
                                aspect="primary"
                                text={t('general.save')}
                                size="small"
                                data-testid="saveLabel"
                                disabled={Boolean(
                                    errorsList.find((errorObject) => errorObject.error),
                                )}
                                onClick={() => setEditable(false)}
                            />
                        </ButtonsPart>
                    ) : (
                        <ButtonsPart>
                            {!isImmutable && hasEditPermission && (
                                <Button
                                    aspect="secondary"
                                    text={t('general.edit')}
                                    size="small"
                                    data-testid="editLabel"
                                    onClick={() => setEditable(true)}
                                />
                            )}
                        </ButtonsPart>
                    )}
                </div>
            </StyledCollapse>
            <Modal
                isOpen={modalOpen}
                onCloseButtonPressed={handleModalOpening}
                buttons={[
                    {
                        text: t('general.cancel'),
                        aspect: 'secondary',
                        'data-testid': 'cancelDelete',
                        onClick() {
                            handleModalOpening();
                        },
                    },
                    {
                        text: t('general.delete'),
                        aspect: 'primary',
                        'data-testid': 'confirmDelete',
                        onClick() {
                            if (label) {
                                void dispatch(
                                    deleteLabel({
                                        labelId: label.id,
                                        organizationId,
                                        operationId: operationId ? Number(operationId) : undefined,
                                    }),
                                );
                            }
                            setModalOpen(!modalOpen);
                        },
                    },
                ]}
                title={label?.name}
                size="small"
                iconName="Cancel"
                iconColor={colors.red.R500}
            >
                <Text style={{ margin: '0 0 1rem 0' }}>{t('generalLabels.deleteModalText')}</Text>
            </Modal>
        </List>
    );
};

export default LabelManager;
