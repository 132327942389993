import React from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from '../constants/colors';
import Badge from './Badge';
import type { IRole } from '../slices/roleSlice';

type Props = {
    role: IRole;
    roleIsAlreadyAffectedToUser: boolean;
    isEditMode: boolean;
    cannotBeRemoved: boolean;
    onClick: (roleId: IRole['id']) => void;
};

const UserRoleBadge: React.FunctionComponent<Props> = ({
    role,
    roleIsAlreadyAffectedToUser,
    isEditMode,
    cannotBeRemoved,
    onClick,
}) => {
    const { t } = useTranslation();
    const getBadgeText = (roleName: IRole['name']) => {
        if (isEditMode) {
            if (roleIsAlreadyAffectedToUser) {
                if (cannotBeRemoved) {
                    return String(roleName);
                }
                return `${roleName} x`;
            } else {
                return `+ ${t('operation.assignRole')}`;
            }
        } else {
            if (roleIsAlreadyAffectedToUser) {
                return String(roleName);
            } else {
                return ``;
            }
        }
    };

    const badgeIsEditable = !(roleIsAlreadyAffectedToUser && cannotBeRemoved);

    const getBadgeCursor = () => {
        if (!badgeIsEditable) {
            return 'initial';
        }
        return 'pointer';
    };

    const getBadgeBorder = () => {
        if (isEditMode) {
            if (!roleIsAlreadyAffectedToUser) {
                return `${colors.neutral.N75} solid 1px`;
            }
        }
        return undefined;
    };

    const handleBadgeClick = () => {
        if (badgeIsEditable) {
            onClick(role.id);
        }
    };

    return (
        <>
            <input
                data-testid="roleBadgeInput"
                role="checkbox"
                checked={roleIsAlreadyAffectedToUser}
                aria-checked={roleIsAlreadyAffectedToUser}
                disabled={!badgeIsEditable}
                hidden
                readOnly
            />
            <Badge
                data-testid="roleBadge"
                text={getBadgeText(role.label ?? role.name)}
                textColor={colors.blue.B500}
                background={roleIsAlreadyAffectedToUser ? colors.neutral.N75 : colors.other.white}
                onClick={handleBadgeClick}
                style={{
                    cursor: getBadgeCursor(),
                    border: getBadgeBorder(),
                }}
            />
        </>
    );
};
export default UserRoleBadge;
