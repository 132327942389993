import React from 'react';
import styled from 'styled-components';
import type { LinkProps } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectOrganizationName } from '../slices/organizationSlice';
import { colors } from '../constants/colors';
import Icon from './Icon';
import { selectSiteName } from '../slices/siteSlice';
import { selectOperationName, selectOperationInternalNumber } from '../slices/operationSlice';
import Tooltip from './Tooltip';
import { selectMarketName } from '../slices/marketSlice';

export type Breadcrumb = {
    to: string;
    text: string;
};

const Container = styled.div`
    @media print {
        padding: 0;
        margin: 0;
    }
    display: flex;
    flex: 1;
    padding: 1rem 2rem;
    background: ${colors.other.white};
    box-shadow: 0px 0.0625rem 0.25rem ${colors.neutral.N75};
`;

const BreadcrumbAndArrowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledBreadCrumbs = styled.div`
    display: flex;
    flex: 1;
`;

const IntermediateLink: React.FC<{ current: boolean } & LinkProps> = ({ current, ...rest }) => (
    <Link {...rest} />
);

const StyledBreadCrumb = styled(IntermediateLink)<{ current: boolean; to: string }>`
    &,
    &:visited,
    &:hover {
        font-weight: 500;
        color: ${({ current }) => (current ? colors.green.G400 : colors.neutral.N500)};
    }

    &:hover {
        color: ${colors.green.G200};
    }
`;

const StyledBreadCrumbsEllipsis = styled(IntermediateLink)<{ current: boolean }>`
    &,
    &:visited,
    &:hover {
        font-weight: 500;
        color: ${({ current }) => (current ? colors.green.G400 : colors.neutral.N500)};
    }

    &:hover {
        color: ${colors.green.G200};
    }
    text-overflow: ellipsis;
    width: 25rem;
    white-space: nowrap;
    overflow: hidden;
`;

type Props = {
    trailingComponent?: React.ReactNode;
    paths: Breadcrumb[];
};

export type BreadcrumbsProps = Props;

const AEOBreadcrumbs: React.FC<Props> = ({ trailingComponent, paths }) => {
    const params = useParams() as Record<string, string>;
    const {
        organizationId,
        siteId,
        operationId,
        marketId,
    }: {
        organizationId?: string;
        siteId?: string;
        operationId?: string;
        marketId?: string;
    } = useParams();
    const paramsKeys = Object.keys(params);
    const organizationName = useSelector(selectOrganizationName(organizationId));
    const siteName = useSelector(selectSiteName(Number(siteId)));
    const operationName = useSelector(selectOperationName(Number(operationId)));
    const operationInternalNumber = useSelector(selectOperationInternalNumber(Number(operationId)));
    const marketName = useSelector(selectMarketName(Number(marketId)));

    const { t } = useTranslation();

    const operationLabel = operationName ?? operationInternalNumber;

    return (
        <Container data-testid="breadcrumbs">
            <StyledBreadCrumbs>
                {paths.map(({ text, to }: Breadcrumb, index: number) => {
                    let computedTo = to;
                    let computedText = text;

                    if (text.includes(':organizationName') && params.organizationId) {
                        computedText = computedText.replace(
                            ':organizationName',
                            organizationName ?? t('organization.selectedOrganization'),
                        );
                    }

                    if (text.includes(':siteName') && siteId) {
                        computedText = computedText.replace(
                            ':siteName',
                            siteName ?? t('site.selectedOrganization'),
                        );
                    }

                    if (text.includes(':operationName') && operationId) {
                        computedText = computedText.replace(
                            ':operationName',
                            operationLabel ?? t('operation.newOperation'),
                        );
                    }

                    if (text.includes(':marketName') && marketId) {
                        computedText = computedText.replace(
                            ':marketName',
                            marketName ?? t('market.newMarket'),
                        );
                    }

                    paramsKeys.forEach((key: string) => {
                        if (computedTo.includes(`:${key}`) && params[key]) {
                            computedTo = computedTo.replace(`:${key}`, params[key]);
                        }
                    });

                    return (
                        <BreadcrumbAndArrowContainer key={text}>
                            {computedText.length > 50 ? (
                                <Tooltip content={computedText} isOneLine={false}>
                                    <StyledBreadCrumbsEllipsis
                                        data-testid="breadcrumbEllipsisInToolTip"
                                        to={computedTo}
                                        current={index === paths.length - 1}
                                    >
                                        {computedText}
                                    </StyledBreadCrumbsEllipsis>
                                </Tooltip>
                            ) : (
                                <StyledBreadCrumb
                                    to={computedTo}
                                    current={index === paths.length - 1}
                                >
                                    {computedText}
                                </StyledBreadCrumb>
                            )}
                            {index !== paths.length - 1 && (
                                <Icon
                                    width="0.625rem"
                                    color={colors.neutral.N300}
                                    name="ArrowForwardIos"
                                    style={{ marginLeft: '0.6875rem', marginRight: '0.6875rem' }}
                                />
                            )}
                        </BreadcrumbAndArrowContainer>
                    );
                })}
            </StyledBreadCrumbs>
            {trailingComponent}
        </Container>
    );
};

export default AEOBreadcrumbs;
