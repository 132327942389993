import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import { useParams } from 'react-router-dom';

import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import MainLayout from '../../components/MainLayout';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import LabelManager from '../../components/LabelManager';
import type { ButtonProps } from '../../components/Button';
import Button from '../../components/Button';
import EmptyState from '../../components/EmptyState';
import { showFlag } from '../../components/Flag';

import type { LabelType, Label, LabelValue } from '../../slices/labelSlice';
import {
    getLabels,
    getPrevizLabels,
    selectLabelsByTypeAndOrganizationIdAndOperationId,
    createLabel,
    selectError,
} from '../../slices/labelSlice';

import type { Permission } from '../../slices/authSlice';
import { useAppDispatch } from '../../store';

export type LabelsProps = {
    headerProps: HeaderProps;
    labelType: LabelType;
    isPreviz: boolean;
};

const LabelList = styled.div`
    width: 100%;
    height: 100%;
`;

const Labels: React.FunctionComponent<LabelsProps> = ({ headerProps, labelType, isPreviz }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { organizationId, operationId }: { organizationId?: string; operationId?: string } =
        useParams();

    const labels =
        useSelector(
            selectLabelsByTypeAndOrganizationIdAndOperationId(
                labelType,
                organizationId,
                Number(operationId),
            ),
        ) ?? [];
    const serverError = useSelector(selectError);
    const labelPrevizAddPermission = usePermissionsCheck([{ code: 'LABELS_PREVIZ_ADD' }]);
    const labelPrevizReadPermission = usePermissionsCheck([{ code: 'LABELS_PREVIZ_READ' }]);
    const labelClientAddPermission = usePermissionsCheck([{ code: 'LABELS_ADD', organizationId }]);
    const labelClientReadPermission = usePermissionsCheck([
        { code: 'LABELS_READ', organizationId },
    ]);
    const labelOperationAddPermission = usePermissionsCheck([{ code: 'LABELS_ADD', operationId }]);
    const labelOperationReadPermission = usePermissionsCheck([
        { code: 'LABELS_READ', operationId },
    ]);
    const [editPermissions, setEditPermissions] = useState<Permission[]>([]);
    const [deletePermissions, setDeleletePermissions] = useState<Permission[]>([]);

    const lastLabelRef = useRef<HTMLDivElement>(null);
    const labelsRef = useRef(labels);
    // Scroll
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error, @typescript-eslint/ban-ts-comment -- necessary
    // @ts-ignore
    const isIE11 = Boolean(window.msCrypto);

    const [popoverOpen, setPopoverOpen] = useState<LabelValue['id']>(0);

    useEffect(() => {
        if (
            (labelClientReadPermission || labelOperationReadPermission) &&
            (organizationId || operationId)
        ) {
            void dispatch(
                getLabels({
                    organizationId,
                    operationId: operationId ? Number(operationId) : undefined,
                }),
            );
        } else if (labelPrevizReadPermission) {
            void dispatch(getPrevizLabels);
        }
    }, [
        dispatch,
        labelPrevizReadPermission,
        labelClientReadPermission,
        labelOperationReadPermission,
        organizationId,
        operationId,
    ]);

    useEffect(() => {
        if (organizationId) {
            setEditPermissions([{ code: 'LABELS_EDIT', organizationId }]);
            setDeleletePermissions([{ code: 'LABELS_DELETE', organizationId }]);
        } else if (operationId) {
            setEditPermissions([{ code: 'LABELS_EDIT', operationId }]);
            setDeleletePermissions([{ code: 'LABELS_DELETE', operationId }]);
        } else {
            setEditPermissions([{ code: 'LABELS_PREVIZ_EDIT' }]);
            setDeleletePermissions([{ code: 'LABELS_PREVIZ_DELETE' }]);
        }
    }, [organizationId, operationId]);

    useEffect(() => {
        if (labelsRef.current.length > 0) {
            let scrollableParent = document.querySelector('.scrollable-content');

            if (isIE11) {
                scrollableParent = scrollableParent?.childNodes[0] as Element;
            }

            if (lastLabelRef.current && scrollableParent) {
                scrollableParent.scrollTop = lastLabelRef.current.offsetTop;
            }
        }
    }, [isIE11, labelsRef]);

    useEffect(() => {
        if (serverError) {
            showFlag('error', t('errors.error'), t(serverError));
        }
    }, [serverError, t]);

    const addNewLabel = () => {
        const label = {
            name: '',
            isPrevizLabel: isPreviz,
            isMarketLabel: labelType === 'market',
            isOperationLabel: labelType === 'operation',
            isEngagementLabel: labelType === 'engagement',
        };
        void dispatch(
            createLabel({
                label,
                organizationId,
                operationId: operationId ? Number(operationId) : undefined,
            }),
        );
    };

    let headerButtons: ButtonProps[] = [];
    if (
        (labelPrevizAddPermission || labelClientAddPermission || labelOperationAddPermission) &&
        labels.length > 0
    ) {
        headerButtons = [
            {
                'data-testid': 'addLabelButton',
                text: t('generalLabels.addList'),
                aspect: 'primary',
                onClick: addNewLabel,
            },
        ];
    }

    return (
        <MainLayout
            header={<Header {...headerProps} buttons={headerButtons} />}
            smallContentSidePadding
        >
            {labels.length > 0 &&
            (labelPrevizReadPermission ||
                labelClientReadPermission ||
                labelOperationReadPermission) ? (
                <LabelList>
                    <Masonry
                        breakpointCols={{
                            default: 3,
                            1430: 2,
                        }}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {labels.map((label: Label, index: number) => (
                            <React.Fragment key={label.id}>
                                <LabelManager
                                    label={label}
                                    editPermissions={editPermissions}
                                    deletePermissions={deletePermissions}
                                    linkableLabels={labels.filter(
                                        ({ id }: Label) => id !== label.id,
                                    )}
                                    popoverOpen={popoverOpen}
                                    handlePopover={(id) => setPopoverOpen(id)}
                                />
                                {index === labels.length - 1 && <div ref={lastLabelRef} />}
                            </React.Fragment>
                        ))}
                    </Masonry>
                </LabelList>
            ) : (
                <EmptyState
                    imageName="Lists"
                    titleTranslationKey="operation.noList"
                    button={
                        (labelPrevizAddPermission ||
                            labelClientAddPermission ||
                            labelOperationAddPermission) && (
                            <Button
                                onClick={addNewLabel}
                                text={t('generalLabels.addList')}
                                aspect="primary"
                                size="medium"
                                data-testid="addLabelButtonEmptyState"
                            />
                        )
                    }
                />
            )}
        </MainLayout>
    );
};

export default Labels;
