import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';

import Text from './Text';
import { selectOperationsToConfirm } from '../slices/marketSlice';
import type { OperationFromImport } from '../slices/common';
import type { Operation } from '../slices/operationSlice';
import { selectOperation } from '../slices/operationSlice';
import Modal from './Modal';
import { formatNumberWithLeadingZeros } from '../utils/formatters';

type Props = {
    isOpen: boolean;
    operationId: Operation['id'];
    onCloseImportConfirmationModal: () => void;
    onImportConfirmation: () => void;
};

const ImportMarketsConfirmationModal: React.FC<Props> = ({
    isOpen,
    operationId,
    onCloseImportConfirmationModal,
    onImportConfirmation,
}) => {
    const { t } = useTranslation();

    const operation = useSelector(selectOperation(operationId));
    const ediflexOperations = useSelector(selectOperationsToConfirm);

    const getOperationsLabels = (operations: OperationFromImport[]): string =>
        operations.reduce((result, operationItem, index) => {
            let newResult = `${result} ${operationItem.clientCode} ${formatNumberWithLeadingZeros(
                operationItem.operationCode,
                4,
            )} ${operationItem.longLabel}`;
            if (operations.length > 1 && index < operations.length - 1) {
                newResult += `, `;
            }
            return newResult;
        }, '');

    const renderUserConfirmation = (ediflexOperationsToConfirm: OperationFromImport[] | null) => {
        const operationsLabels = ediflexOperationsToConfirm
            ? getOperationsLabels(ediflexOperationsToConfirm)
            : '';
        const previzOperationLabel = operation?.longLabel;
        const ediflexOperationsLabels = operationsLabels;

        return (
            <Text>
                <Trans i18nKey="markets.import.operationsConfirmation">
                    Est-ce bien sur l’opération <strong>{previzOperationLabel}</strong> sur laquelle
                    vous souhaitez importer les marchés et engagements provenant des opérations{' '}
                    <strong>{ediflexOperationsLabels}</strong> sur l&apos;application tierce ?
                </Trans>
            </Text>
        );
    };

    return isOpen ? (
        <Modal
            isOpen={isOpen}
            onCloseButtonPressed={onCloseImportConfirmationModal}
            buttons={[
                {
                    text: t('general.cancel'),
                    aspect: 'secondary',
                    'data-testid': 'cancelDelete',
                    onClick() {
                        onCloseImportConfirmationModal();
                    },
                },
                {
                    text: t('general.confirm'),
                    aspect: 'primary',
                    'data-testid': 'confirm',
                    onClick() {
                        onImportConfirmation();
                    },
                },
            ]}
            title={t('markets.import.confirm')}
            size="small"
            centerTitle
        >
            <Text type="H300">{renderUserConfirmation(ediflexOperations)}</Text>
        </Modal>
    ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment -- Necessary fragment
        <></>
    );
};

export default ImportMarketsConfirmationModal;
