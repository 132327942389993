import type { CSSProperties } from 'react';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import type { IDialogProps } from '@blueprintjs/core';
import { Dialog, Classes } from '@blueprintjs/core';

import { colors } from '../constants/colors';
import type { IconName } from './Icon';
import Icon from './Icon';
import type { ButtonProps } from './Button';
import Button from './Button';

export const GlobalStyle = createGlobalStyle<Props>`
    h4.bp4-heading {
        padding: ${(props) => (props.iconName ? '0 0 0 0.4rem' : '0')};
        color: ${colors.blue.B400};
        font-family: Gilroy;
        font-weight: 800;
        font-size: ${(props) => (props.titleFontSize ? props.titleFontSize : '0.875rem')};
        line-height: 1rem;
        display: ${(props) => (props.centerTitle ? 'flex' : '')};
        justify-content: ${(props) => (props.centerTitle ? 'center' : 'flex-start')};
    }

    .bp4-dialog-body {
        background-color: ${(props) =>
            props.backgroundColor ? props.backgroundColor : String(colors.other.white)};
        padding: 0 1.5rem;
        margin: 1rem 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: ${(props) => (props.centerBody ? 'center' : 'flex-start')};

        &.scrollable-body {
            display: block;
            overflow-y: auto;
        }
    }

    .bp4-dialog {
        background-color: ${(props) =>
            props.backgroundColor ? props.backgroundColor : String(colors.other.white)};
        box-shadow: 0 1.25rem 1.25rem ${colors.blue.B400}33;
        border-radius: 0.625rem;
        ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}

        &.scrollable-body {
            position: static;
            margin: 0;
            max-height: calc(100vh - 60px);
            padding-bottom: 0;
            overflow: clip;
        }
    }

    .bp4-dialog-header {
        background-color: ${(props) =>
            props.backgroundColor ? props.backgroundColor : String(colors.other.white)};
        box-shadow: none;
        border-radius: 0.625rem;
        padding: 1.5rem 1rem 0 1.5rem;
        > .bp4-button {
            :hover {
                background-color: ${(props) =>
                    props.backgroundColor ? props.backgroundColor : String(colors.other.white)};
            }

            > .bp4-icon {
                color: ${colors.blue.B400};
            }
        }
    }

    .bp4-overlay-backdrop {
        background-color: ${colors.blue.B400}4D;
    }

    .bp4-dialog-footer {
        margin: 0 1.5rem;
        background-color: ${(props) =>
            props.backgroundColor ? props.backgroundColor : String(colors.other.white)};

        &.scrollable-body {
            position: sticky;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: ${colors.other.white};
            box-shadow: 0rem -0.0625rem 0.25rem ${colors.neutral.N75};;
            width: 100%;
            margin: 0;
            padding: 1rem 3rem
        }

    }

    .bp4-dialog-close-button {
        margin-top: -1rem;
        padding: 0.3125rem 0rem 0.3125rem 0.875rem;
    }
`;

type Props = {
    style?: CSSProperties;
    iconName?: IconName;
    buttons?: ButtonProps[] | null;
    onCloseButtonPressed?: () => void;
    size?: 'small' | 'medium' | 'large' | 'full';
    specificWidth?: string;
    specificHeight?: string;
    specificPadding?: string;
    iconColor?: string;
    'data-testid'?: string;
    maxWidth?: string;
    titleFontSize?: string;
    shouldRenderGlobalStyle?: boolean;
    centerTitle?: boolean;
    centerBody?: boolean;
    backgroundColor?: string;
    scrollableBody?: boolean;
};

const Modal: React.FC<Props & IDialogProps> = ({
    style,
    iconName,
    children,
    buttons,
    size,
    title = '',
    centerTitle = false,
    centerBody = true,
    iconColor,
    onCloseButtonPressed,
    maxWidth,
    titleFontSize,
    specificWidth,
    specificHeight,
    specificPadding,
    shouldRenderGlobalStyle = true,
    backgroundColor,
    scrollableBody = false,
    ...rest
}) => {
    const icon = iconName && (
        <Icon width="1.125rem" color={iconColor} name={iconName} data-testid="modalTitleIcon" />
    );
    let ModalSize: string;
    switch (size) {
        case 'full':
            ModalSize = '90%';
            break;
        case 'large':
            ModalSize = '63%';
            break;
        case 'small':
            ModalSize = '33%';
            break;
        default:
            ModalSize = '50%';
            break;
    }
    if (specificWidth) {
        ModalSize = specificWidth;
    }

    return (
        <div data-testid="modalWindow">
            {shouldRenderGlobalStyle && (
                <GlobalStyle
                    iconName={iconName}
                    style={style}
                    size={size}
                    maxWidth={maxWidth}
                    titleFontSize={titleFontSize}
                    centerTitle={centerTitle}
                    centerBody={centerBody}
                    backgroundColor={backgroundColor}
                    scrollableBody={scrollableBody}
                />
            )}
            <Dialog
                {...rest}
                icon={icon}
                onClose={onCloseButtonPressed}
                style={
                    specificHeight
                        ? {
                              width: ModalSize,
                              height: specificHeight,
                              padding: specificPadding ?? '0 0 1.25rem',
                          }
                        : { width: ModalSize, padding: specificPadding ?? '0 0 1.25rem' }
                }
                title={title}
                usePortal={false}
                enforceFocus={false}
                className={`${Classes.DIALOG} ${scrollableBody ? 'scrollable-body' : ''}`}
            >
                <div
                    className={`${Classes.DIALOG_BODY} ${scrollableBody ? 'scrollable-body' : ''}`}
                    data-testid="modalBody"
                >
                    {children}
                </div>
                {buttons && (
                    <div
                        className={`${Classes.DIALOG_FOOTER} ${
                            scrollableBody ? 'scrollable-body' : ''
                        }`}
                    >
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            {buttons.map((button, index) => (
                                <Button
                                    // eslint-disable-next-line react/no-array-index-key -- no other choice than index
                                    key={index}
                                    {...button}
                                    size="small"
                                    style={{ marginLeft: '1.3125rem' }}
                                    data-testid={button['data-testid'] ?? 'modalActionButton'}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </Dialog>
        </div>
    );
};
export default Modal;
