import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useDebouncedCallback } from 'use-debounce';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';

import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import TextField from './TextField';
import Text from './Text';
import Icon from './Icon';
import Popover from './Popover';
import CheckboxField from './CheckboxField';
import LabelDropdown from './dropdown/LabelDropdown';
import type { Permission } from '../slices/authSlice';
import type { LabelValue, Label } from '../slices/labelSlice';
import {
    updateLabel,
    updateLabelValue,
    deleteLabelValue,
    createLabelLink,
    deleteLabelLink,
    selectLabel,
} from '../slices/labelSlice';
import { useAppDispatch } from '../store';

const StyledValue = styled(Text)`
    padding: 0.625rem;
`;

const StyledValuePart = styled.div`
    background-color: ${colors.other.white};
    border-radius: ${styles.borderRadiusSmall};
    border: 0.0938rem solid ${colors.other.white}; // added to make visualization and text field same height
    margin: 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    > *:first-child {
        flex: 1;
        margin-bottom: 0;
    }
`;

type TextFieldProps = {
    islabelvalue: string; // not CamelCase to avoid warning in console
};

const StyledTextField = styled(TextField)<TextFieldProps>`
    margin: 0 ${({ islabelvalue }) => (islabelvalue === 'true' ? '0.75rem' : '1.5rem')} 0 1.5rem;
    display: flex;
`;

const StyledTextFieldPart = styled.div`
    display: flex;
    align-items: center;
    > *:first-child {
        flex: 1 0 0;
    }
    .bp4-form-content {
        p {
            margin-left: 1.5rem;
        }
    }
`;

const PopoverContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const PopoverHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const PopoverGlobalStyle = createGlobalStyle`
    .bp4-popover {
        display: contents;
        .bp4-popover-content {
            border-radius: ${styles.borderRadiusMedium};
            padding: 1rem;
            box-shadow: 0 0 1.25rem ${colors.neutral.N100};
        }
    }
`;

type IconWrapperProps = {
    isValuePart?: boolean;
    disabled?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    children: React.ReactNode;
};

const IntermediateDiv: React.FC<IconWrapperProps> = ({ isValuePart, disabled, ...rest }) => (
    <div {...rest} />
);
const IconWrapper = styled(IntermediateDiv)<IconWrapperProps>`
    margin-right: ${({ isValuePart }) => (isValuePart ? '0.625rem' : '1.5rem')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const getLinkedLabelValue = (labelValue: LabelValue, labelValueToLink: LabelValue) =>
    labelValue.linkedLabelValues?.find(
        ({ labelValueId }: { labelValueId: LabelValue['id']; linkId: number }) =>
            labelValueId === labelValueToLink.id,
    );

type Props = {
    permissionsRequired: Permission[];
    placeholder?: string;
    'data-testid'?: string;
    editable?: boolean;
    labelValue?: LabelValue;
    label?: Label;
    style?: React.CSSProperties;
    linkableLabels?: Label[];
    handleError: ({ id, error }: { id: string; error: boolean }) => void;
    popoverOpen?: LabelValue['id'];
    handlePopover?: (id: LabelValue['id']) => void;
};

const LabelPermissionAwareText: React.FC<Props> = ({
    permissionsRequired,
    labelValue,
    label,
    editable,
    linkableLabels,
    handleError,
    popoverOpen,
    handlePopover,
    ...props
}) => {
    const { t } = useTranslation();
    const { organizationId, operationId }: { organizationId?: string; operationId?: string } =
        useParams();
    const hasRightToEdit = usePermissionsCheck(permissionsRequired);
    const dispatch = useAppDispatch();
    const [hovered, setHovered] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [selectedLabel, setSelectedLabel] = useState<Label | null>(
        linkableLabels ? linkableLabels[0] : null,
    );
    const isLabelValue = Boolean(labelValue);
    const labelOfLabelValue = useSelector(selectLabel(labelValue?.labelId));
    const debouncedCallback = useDebouncedCallback((value) => {
        setError(value);
    }, 500);
    const [textFieldValue, setTextFieldValue] = useState<string>();

    let component = null;

    useEffect(() => {
        if (labelValue) {
            handleError({ id: `labelValue-${labelValue.id}`, error });
        } else if (label) {
            handleError({ id: `label-${label.id}`, error });
        }
    }, [handleError, error, labelValue, label]);

    useEffect(() => {
        if (textFieldValue && textFieldValue.length > 50) {
            debouncedCallback(true);
        } else {
            debouncedCallback(false);
        }
    }, [textFieldValue, debouncedCallback]);

    useEffect(() => {
        if (textFieldValue === undefined && (labelValue || label)) {
            setTextFieldValue(labelValue?.value ?? label?.name);
        }
    }, [textFieldValue, label, labelValue]);

    const handleBlur = (value: string) => {
        if (!error) {
            if (label) {
                void dispatch(
                    updateLabel({
                        label: { ...label, name: value },
                        organizationId,
                        operationId: operationId ? Number(operationId) : undefined,
                    }),
                );
            } else if (labelValue) {
                void dispatch(
                    updateLabelValue({
                        labelValue: { ...labelValue, value },
                        organizationId,
                        operationId: operationId ? Number(operationId) : undefined,
                    }),
                );
            }
        }
    };

    const handleDeleteLabelValue = () => {
        if (labelValue) {
            void dispatch(
                deleteLabelValue({
                    labelValueId: labelValue.id,
                    labelId: labelValue.labelId,
                    organizationId,
                    operationId: operationId ? Number(operationId) : undefined,
                }),
            );
        }
    };

    const handleChangeLink = (link: {
        linkId?: number;
        sourceLabelValueId: LabelValue['id'];
        targetLabelValueId: LabelValue['id'];
    }) => {
        if (link.linkId) {
            void dispatch(
                deleteLabelLink({
                    linkId: link.linkId,
                    organizationId,
                    operationId: operationId ? Number(operationId) : undefined,
                }),
            );
        } else {
            delete link.linkId;
            void dispatch(
                createLabelLink({
                    link,
                    organizationId,
                    operationId: operationId ? Number(operationId) : undefined,
                }),
            );
        }
    };

    let placeholder = '';

    if (label && label.name === '') {
        placeholder = t('generalLabels.newLabel');
    } else if (labelValue && labelValue.value === '') {
        placeholder = t('generalLabels.newLabelValue');
    }

    if (hasRightToEdit && editable) {
        component = (
            <StyledTextFieldPart>
                <StyledTextField
                    {...props}
                    islabelvalue={isLabelValue.toString()}
                    value={textFieldValue}
                    type="text"
                    marginTop="0.5rem"
                    marginBottom="0.5rem"
                    onChange={(e) => {
                        setTextFieldValue(e.target.value);
                    }}
                    errorMessage={error ? t('errors.tooLong', { number: 50 }) : undefined}
                    onBlur={(e) => handleBlur(e.target.value)}
                    placeholder={placeholder}
                    whiteBackground
                    autoFocusable
                />
                {labelValue && (
                    <IconWrapper
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                    >
                        <Icon
                            name="RemoveCircleOutline"
                            color={hovered ? colors.red.R500 : colors.neutral.N300}
                            onClick={handleDeleteLabelValue}
                            data-testid="deleteIcon"
                        />
                    </IconWrapper>
                )}
            </StyledTextFieldPart>
        );
    } else {
        const computeLinkColor = () => {
            let color = colors.neutral.N300;
            if (labelValue?.linkedLabelValues && labelValue.linkedLabelValues.length > 0) {
                color = colors.green.G400;
            }
            if (popoverOpen === labelValue?.id) {
                color = colors.green.G400;
            }
            return color;
        };
        component = (
            <StyledValuePart>
                <PopoverGlobalStyle />
                <StyledValue>{labelValue?.value ?? label?.name}</StyledValue>
                {labelValue && linkableLabels && (
                    <IconWrapper disabled={linkableLabels.length === 0} isValuePart>
                        <Popover isOpen={popoverOpen === labelValue.id}>
                            <Icon
                                name={
                                    labelValue.linkedLabelValues &&
                                    labelValue.linkedLabelValues.length > 0
                                        ? 'Link'
                                        : 'LinkOff'
                                }
                                color={computeLinkColor()}
                                onClick={() =>
                                    linkableLabels.length > 0 && handlePopover?.(labelValue.id)
                                }
                                data-testid={
                                    labelValue.linkedLabelValues &&
                                    labelValue.linkedLabelValues.length > 0
                                        ? 'linkIcon'
                                        : 'linkOffIcon'
                                }
                            />
                            <PopoverContent>
                                <PopoverHeader>
                                    <Text
                                        color={colors.blue.B400}
                                        font="Gilroy"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {t('generalLabels.addLink')}
                                    </Text>
                                    <Icon
                                        name="Close"
                                        color={colors.blue.B400}
                                        onClick={() => handlePopover?.(0)}
                                        data-testid="closePopoverIcon"
                                        style={{ cursor: 'pointer' }}
                                    />
                                </PopoverHeader>
                                <LabelDropdown
                                    items={linkableLabels}
                                    popoverOpenedLabelValueId={labelValue.id}
                                    onItemSelect={(labelObject: Label) =>
                                        setSelectedLabel(labelObject)
                                    }
                                    name="label"
                                />
                                {selectedLabel?.labelValues.map((labelValueToLink: LabelValue) => (
                                    <CheckboxField
                                        key={labelValueToLink.id}
                                        name="labelValueCheckbox"
                                        disabled={labelOfLabelValue?.isImmutable ?? !hasRightToEdit}
                                        checked={Boolean(
                                            getLinkedLabelValue(labelValue, labelValueToLink),
                                        )}
                                        type="checkbox"
                                        onChange={() =>
                                            handleChangeLink({
                                                linkId: getLinkedLabelValue(
                                                    labelValue,
                                                    labelValueToLink,
                                                )?.linkId,
                                                sourceLabelValueId: labelValue.id,
                                                targetLabelValueId: labelValueToLink.id,
                                            })
                                        }
                                        label={labelValueToLink.value}
                                        data-testid={`checkbox-${labelValueToLink.value}`}
                                    />
                                ))}
                            </PopoverContent>
                        </Popover>
                    </IconWrapper>
                )}
            </StyledValuePart>
        );
    }

    return component;
};

export default LabelPermissionAwareText;
