import React from 'react';
import styled from 'styled-components';
import { useParams, useLocation, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { colors } from '../constants/colors';
import { selectOrganization, getOrganizationName } from '../slices/organizationSlice';
import { selectOperation, getOperationName } from '../slices/operationSlice';
import Icon from './Icon';
import type { ChildItem } from './SidebarItem';
import PermissionAwareHider from './PermissionAwareHider';

const SubLinksContainer = styled.div`
    background-color: ${colors.other.white};
    width: 100%;
    padding: 1.5rem 0;

    &::before {
        content: '';
        width: 0.765rem;
        height: 0.765rem;
        transform: rotate(45deg);
        position: absolute;
        margin-top: -1.875rem;
        left: 2rem;
        background-color: ${colors.other.white};
    }
`;

const Container = styled.div`
    border-bottom: 0.09375rem solid ${colors.neutral.N75};
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
`;

const ItemName = styled.p`
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${colors.blue.B400};
    margin-bottom: 0;
`;

const ItemLabel = styled(ItemName)`
    line-height: 1.5rem;
    color: ${colors.neutral.N300};
    display: flex;
    align-items: baseline;

    span {
        margin-left: 0.5rem;
    }
`;

const Identifier = styled(ItemName)`
    font-weight: bold;
    margin-top: 0.625rem;
`;

const StyledNavLink = styled(NavLink)`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 0 2rem;
    margin: 1.875rem 0;
    color: ${colors.neutral.N300};
    letter-spacing: 0.03em;
    font-family: 'Gilroy';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    outline: none;

    :visited {
        text-decoration: none;
        color: ${colors.neutral.N300};
        margin-left: 1.5rem;
        padding-left: 1rem;
        letter-spacing: 0.03em;
        cursor: pointer;
    }

    :hover {
        font-family: 'Gilroy';
        color: ${colors.blue.B400};
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.1875rem;

        ::before {
            content: '';
            width: 0.1875rem;
            height: 1.1875rem;
            background: ${colors.green.G400};
            position: absolute;
            z-index: 1;
            left: 0;
            border-radius: 0.3125rem;
        }
    }

    &.activated {
        ::before {
            content: '';
            width: 0.1875rem;
            height: 1.1875rem;
            background: ${colors.green.G400};
            position: absolute;
            z-index: 1;
            left: 0;
            border-radius: 0.3125rem;
        }
        color: ${colors.blue.B400};
    }

    /* List Item Icon */
    svg {
        margin: 0;
    }

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
`;

type Props = {
    childrenItems: ChildItem[];
    startsWith: string;
};

const SidebarSubLinks: React.FC<Props> = ({ childrenItems, startsWith }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { operationId, organizationId }: { operationId?: string; organizationId?: string } =
        useParams();

    const operation = useSelector(selectOperation(Number(operationId)));
    const organization = useSelector(selectOrganization(organizationId));

    const isLinkActive = (item: ChildItem, finalTo: string) => {
        // Clean first '/' if there is one
        const pathEnd = location.pathname.replace(finalTo, '').replace('/', '');
        const isMatchingExactly = pathEnd.lastIndexOf('/') === -1 && pathEnd === '';

        if (item.isActiveIfExact) {
            return isMatchingExactly;
        }

        const restOfUrlAfterLastSlash = pathEnd.substring(0, pathEnd.lastIndexOf('/'));
        const isActive = isMatchingExactly || Number.isInteger(Number(restOfUrlAfterLastSlash));

        if (isActive && item.activeMatchExclusions) {
            const replaceEntityInExclusions = item.activeMatchExclusions.map((exclusion) =>
                info?.replace ? exclusion.replace(info.replace, String(info.entityId)) : exclusion,
            );

            if (replaceEntityInExclusions.includes(location.pathname)) {
                return false;
            }
        }

        return isActive;
    };

    if (!organizationId && operationId === 'new') {
        return null;
    }

    const getInfo = () => {
        if (
            location.pathname.includes(startsWith) &&
            operation &&
            operationId &&
            operationId !== 'new'
        ) {
            return {
                entityId: operation.id,
                replace: ':operationId',
                entityIsClient: true,
                selectedItemLabel: t('sidebar.selectedOperation'),
                identifier: operation.internalNumber,
                name: getOperationName(operation),
            };
        } else if (location.pathname.includes(startsWith) && organization) {
            return {
                entityId: organization.id,
                replace: ':organizationId',
                selectedItemLabel: t('sidebar.selectedOrganization'),
                entityIsClient: organization.estClient,
                identifier: organization.siret,
                name: getOrganizationName(organization),
            };
        }

        return null;
    };

    const info = getInfo();

    return (
        <SubLinksContainer>
            {info ? (
                <Container>
                    <ItemLabel>
                        <Icon name="Building" width="0.875rem" />
                        <span>{info.selectedItemLabel}</span>
                    </ItemLabel>
                    <Identifier>{info.identifier}</Identifier>
                    <ItemName>{info.name}</ItemName>
                </Container>
            ) : null}
            {childrenItems.map((item) => {
                const completeTo = `${startsWith}${item.to}`;
                const finalTo = info?.replace
                    ? completeTo.replace(info.replace, String(info.entityId))
                    : completeTo;

                const linkClassName = isLinkActive(item, finalTo) ? 'activated' : '';

                if (item.organizationNeedsToBeClient && !info?.entityIsClient) {
                    return null;
                }

                const link = (
                    <StyledNavLink key={item.translationKey} to={finalTo} className={linkClassName}>
                        {t(item.translationKey)}
                    </StyledNavLink>
                );

                return item.permissionsRequired ? (
                    <PermissionAwareHider
                        key={`permission-${item.translationKey}`}
                        permissionsRequired={item.permissionsRequired.map((permission) => {
                            // Replace :organizationId in permission
                            if (
                                permission.organizationId &&
                                permission.organizationId === ':organizationId' &&
                                organization
                            ) {
                                permission.organizationId = organization.id;
                            } else if (
                                permission.organizationId &&
                                permission.organizationId === ':organizationId' &&
                                operation
                            ) {
                                permission.organizationId = operation.clientId;
                            }
                            // Replace :operationId in permission
                            if (
                                permission.operationId &&
                                permission.operationId === ':operationId' &&
                                operation
                            ) {
                                permission.operationId = operation.id;
                            }

                            return permission;
                        })}
                    >
                        {link}
                    </PermissionAwareHider>
                ) : (
                    link
                );
            })}
        </SubLinksContainer>
    );
};

export default SidebarSubLinks;
