import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { getTime } from 'date-fns';

import type { RootState } from '../store';
import type { Operation } from './operationSlice';
import type { Market } from './marketSlice';
import type { Engagement } from './engagementSlice';

import type { ServerError, ImportResultType, ImportPayload } from './common';
import { errorHandler } from './common';

export type EngagementInvoice = {
    id: number;
    marketInvoiceId: MarketInvoice['id'];
    engagementId: Engagement['id'];
    previousCumulatedAvancementsAmountHt: number;
    currentCumulatedAvancementsAmountHt: number;
    avancementsAmountHt: number;
    avancementsAmountTtc: number;
    taxesOnAvancements: number;
    restToInvoiceHt: number;
    previousCumulatedRevisionsAmountHt: number;
    currentCumulatedRevisionsAmountHt: number;
    revisionsAmountHt: number;
    revisionsAmountTtc: number;
    tvaCodeId: number;
    tvaRate: number;
    isImported: boolean;
    groupCode?: string | null;
    engagementLabel: string;
    engagementOrder?: number | null;
};

export type EngagementInvoiceFormData = Omit<
    EngagementInvoice,
    'currentCumulatedAvancementsAmountHt' | 'currentCumulatedRevisionsAmountHt'
> & {
    currentCumulatedAvancementsAmountHt: number | string;
    currentCumulatedRevisionsAmountHt: number | string;
};

export type MarketInvoice = {
    id: number;
    marketId: Market['id'];
    invoiceNumber: number;
    month: string;
    totalAmountTtc: number;
    initialMarketAvancementsHt: number;
    otherWorksAvancementsHt: number;
    avancementsTotalHt: number;
    revisionsAmountHt: number;
    avancementsAndRevisionsTotalHt: number;
    avancementsAndRevisionsTaxes: number;
    avancementsAndRevisionsTotalTtc: number;
    holdback: number;
    penalties: number;
    deductions: number;
    bonusesTtc: number;
    initialAdvancePaymentTtc: number;
    advancePaymentTakebackTtc: number;
    presentedAt: string;
    approvalProjectOwnerAt: string;
    isImported: boolean;
    engagementInvoices?: EngagementInvoice[];
    dueDate: string;
    currentCumulatedInitialMarketAvancementsHt: number;
    previousCumulatedInitialMarketAvancementsHt: number;
    currentCumulatedOtherWorksAvancementsHt: number;
    previousCumulatedOtherWorksAvancementsHt: number;
    currentCumulatedAvancementsTotalHt: number;
    previousCumulatedAvancementsTotalHt: number;
    currentCumulatedRevisionsAmountHt: number;
    previousCumulatedRevisionsAmountHt: number;
    currentCumulatedAvancementsAndRevisionsTotalHt: number;
    previousCumulatedAvancementsAndRevisionsTotalHt: number;
    currentCumulatedHoldback: number;
    previousCumulatedHoldback: number;
    currentCumulatedInitialAdvancePaymentTtc: number;
    previousCumulatedInitialAdvancePaymentTtc: number;
    currentCumulatedAdvancePaymentTakebackTtc: number;
    previousCumulatedAdvancePaymentTakebackTtc: number;
    currentCumulatedBonusesTtc: number;
    previousCumulatedBonusesTtc: number;
    currentCumulatedDeductions: number;
    previousCumulatedDeductions: number;
    currentCumulatedPenalties: number;
    previousCumulatedPenalties: number;
    taxesAdjustment: number;
    groupCode?: string | null;
};

export type MarketInvoiceFormData = {
    month: string;
    presentedAt: string;
    approvalProjectOwnerAt: string;
    taxesAdjustment: number | string;
    currentCumulatedHoldback: number | string;
    currentCumulatedInitialAdvancePaymentTtc: number | string;
    currentCumulatedAdvancePaymentTakebackTtc: number | string;
    currentCumulatedBonusesTtc: number | string;
    currentCumulatedDeductions: number | string;
    currentCumulatedPenalties: number | string;
    engagementInvoices: EngagementInvoiceFormData[] | [];
    initialMarketEngagementInvoices: EngagementInvoiceFormData[];
    otherEngagementInvoices: EngagementInvoiceFormData[];
};

export type EngagementInvoiceFormDataKey = keyof EngagementInvoiceFormData;

export type MarketInvoiceGroupCodeForm = {
    groupCode: string | null;
};

export type MarketInvoiceCreationFormData = {
    marketId: number;
    groupCode: string | null;
};

export type MarketInvoiceCalculatedData = Omit<
    MarketInvoice,
    'id' | 'engagementInvoices' | 'invoiceNumber'
> & {
    id?: number | null;
    previousCumulatedInitialMarketAvancementsHt: number;
    previousCumulatedOtherWorksAvancementsHt: number;
    previousCumulatedAvancementsTotalHt: number;
    previousCumulatedRevisionsAmountHt: number;
    previousCumulatedAvancementsAndRevisionsTotalHt: number;
    previousCumulatedHoldback: number;
    previousCumulatedInitialAdvancePaymentTtc: number;
    previousCumulatedAdvancePaymentTakebackTtc: number;
    previousCumulatedDeductions: number;
    previousCumulatedBonusesTtc: number;
    previousCumulatedPenalties: number;
    currentCumulatedInitialMarketAvancementsTtc: number;
    currentCumulatedRevisionsAmountTtc: number;
    avancementsAndRevisionsTaxes: number;
    initialMarketAvancementsTtc: number;
    otherWorksAvancementsTtc: number;
    avancementsTva: number;
    taxesAdjustment: number;
    avancementsTotalTtc: number;
    revisionsAmountTtc: number;
    revisionTva: number;
    engagementInvoices: EngagementInvoiceFormData[];
    initialMarketEngagementInvoices: EngagementInvoiceFormData[];
    otherEngagementInvoices: EngagementInvoiceFormData[];
};

interface IInvoicesState {
    importResult: ImportResultType | null;
    loading: boolean;
    error: string | null;
    isDeleteFulfilled: boolean;
    marketInvoicesById: Record<number, MarketInvoice>;
    totalMarketInvoices: number;
    lastMarketInvoicesId: Array<{ id: MarketInvoice['id'] }>;
    marketInvoiceIdAdded: MarketInvoice['id'] | undefined;
    getMarketInvoicesLoading: boolean;
    previousMarketInvoice?: MarketInvoice;
}

export const initialState: IInvoicesState = {
    importResult: null,
    loading: false,
    error: null,
    isDeleteFulfilled: false,
    marketInvoicesById: {},
    totalMarketInvoices: 0,
    lastMarketInvoicesId: [],
    marketInvoiceIdAdded: undefined,
    getMarketInvoicesLoading: true,
    previousMarketInvoice: undefined,
};

export const getInvoices = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: MarketInvoice[]; operationId: Operation['id']; marketId: Market['id'] },
    // First argument to the payload creator
    {
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('ENGAGEMENT/GETALL', async ({ operationId, marketId }, { rejectWithValue, getState }) => {
    try {
        const response = await axios.get(`/operations/${operationId}/markets/${marketId}/invoices`);

        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return { data: response.data.data, operationId, marketId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        console.error(err);
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const importInvoices = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    ImportResultType,
    // First argument to the payload creator
    ImportPayload,
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('INVOICES/IMPORT', async ({ fileData, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.post<ImportResultType | undefined>(
            `/operations/${operationId}/markets/import-invoices`,
            fileData,
        );

        if (!response.data) {
            return rejectWithValue({
                message: 'Problem when importing invoices',
                translationKey: 'errors.importInvoices',
            });
        }
        return {
            filename: response.data.filename,
            status: response.data.status,
            message: response.data.message,
            operations: response.data.operations,
            markets: response.data.markets,
            engagements: response.data.engagements,
        };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getMarketInvoices = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: MarketInvoice[]; operationId: Operation['id']; marketId: Market['id'] },
    // First argument to the payload creator
    {
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('INVOICES/GETALL', async ({ operationId, marketId }, { rejectWithValue, getState }) => {
    try {
        const response = await axios.get(`/operations/${operationId}/markets/${marketId}/invoices`);

        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return { data: response.data.data, operationId, marketId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        console.error(err);
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getMarketInvoice = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    MarketInvoice,
    // First argument to the payload creator
    { id: MarketInvoice['id']; marketId: Market['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('INVOICES/GET', async ({ id, marketId, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: MarketInvoice }>(
            `/operations/${operationId}/markets/${marketId}/invoices/${id}`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }

        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getMarketInvoiceForEdit = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    MarketInvoice,
    // First argument to the payload creator
    { id: MarketInvoice['id']; marketId: Market['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('INVOICES/GET_FOR_EDIT', async ({ id, marketId, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: MarketInvoice }>(
            `/operations/${operationId}/markets/${marketId}/invoices/${id}/create-if-missing-before-edit`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }

        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getLastMarketInvoices = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Array<{ id: MarketInvoice['id'] }>,
    // First argument to the payload creator
    { marketId: Market['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('INVOICES/GET_LAST', async ({ marketId, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `/operations/${operationId}/markets/${marketId}/invoices/last`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getPreviousMarketInvoice = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    MarketInvoice | undefined,
    // First argument to the payload creator
    { id: MarketInvoice['id']; marketId: Market['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('INVOICES/GET_PREVIOUS', async ({ id, marketId, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: MarketInvoice }>(
            `/operations/${operationId}/markets/${marketId}/invoices/${id}/previous`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (error: unknown) {
        const axiosError = error as AxiosError<ServerError>;

        if (axiosError.response?.status === 404) {
            return undefined;
        }

        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const createMarketInvoice = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    MarketInvoice,
    // First argument to the payload creator
    {
        marketInvoice: MarketInvoiceGroupCodeForm;
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
    }
>('INVOICES/POST', async ({ marketInvoice, operationId, marketId }, { rejectWithValue }) => {
    try {
        const response = await axios.post<{ data?: MarketInvoice; token: string }>(
            `/operations/${operationId}/markets/${marketId}/invoices`,
            marketInvoice,
        );
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        if (response.data.token) {
            const newToken = response.data.token;
            // eslint-disable-next-line require-atomic-updates -- necessary
            axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
            localStorage.setItem('access_token', newToken);
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const updateMarketInvoice = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    MarketInvoice,
    // First argument to the payload creator
    {
        marketInvoice: Partial<MarketInvoiceCalculatedData>;
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
    }
>('INVOICES/PUT', async ({ marketInvoice, operationId, marketId }, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: MarketInvoice }>(
            `/operations/${operationId}/markets/${marketId}/invoices/${marketInvoice.id}`,
            marketInvoice,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'Problem when updating invoice',
                translationKey: 'errors.updateInvoice',
            });
        }
        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const deleteMarketInvoice = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { id: MarketInvoice['id']; operationId: Operation['id']; marketId: Market['id'] },
    // First argument to the payload creator
    { id: MarketInvoice['id']; operationId: Operation['id']; marketId: Market['id'] },
    {
        rejectValue: ServerError;
    }
>('INVOICES/DELETE', async ({ id, operationId, marketId }, { rejectWithValue }) => {
    try {
        await axios.delete<{ data?: MarketInvoice['id'] }>(
            `/operations/${operationId}/markets/${marketId}/invoices/${id}`,
        );
        return { id, operationId, marketId };
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const slice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        resetImportState(state) {
            state.importResult = null;
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers(builder) {
        // Get all imported invoices
        builder.addCase(importInvoices.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.importResult = null;
        });
        builder.addCase(importInvoices.fulfilled, (state, { payload }) => {
            state.importResult = payload;
            state.error = null;
            state.loading = false;
        });
        builder.addCase(importInvoices.rejected, errorHandler());
        // Get all invoices
        builder.addCase(getMarketInvoices.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.importResult = null;
            state.marketInvoicesById = [];
            state.getMarketInvoicesLoading = true;
        });
        builder.addCase(getMarketInvoices.fulfilled, (state, { payload }) => {
            payload.data.forEach((item: MarketInvoice) => {
                state.marketInvoicesById[item.id] = item;
            });
            state.totalMarketInvoices = payload.data.length;
            state.error = null;
            state.loading = false;
            state.getMarketInvoicesLoading = false;
        });
        builder.addCase(getMarketInvoices.rejected, errorHandler());
        // Get invoice
        builder.addCase(getMarketInvoice.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.importResult = null;
        });
        builder.addCase(getMarketInvoice.fulfilled, (state, { payload }) => {
            state.marketInvoicesById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getMarketInvoice.rejected, errorHandler());
        // Get invoice for edit
        builder.addCase(getMarketInvoiceForEdit.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.importResult = null;
        });
        builder.addCase(getMarketInvoiceForEdit.fulfilled, (state, { payload }) => {
            state.marketInvoicesById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getMarketInvoiceForEdit.rejected, errorHandler());
        // Get last invoice
        builder.addCase(getLastMarketInvoices.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.importResult = null;
            state.lastMarketInvoicesId = [];
        });
        builder.addCase(getLastMarketInvoices.fulfilled, (state, { payload }) => {
            state.lastMarketInvoicesId = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getLastMarketInvoices.rejected, errorHandler());
        // Get previous market invoice
        builder.addCase(getPreviousMarketInvoice.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.previousMarketInvoice = undefined;
        });
        builder.addCase(getPreviousMarketInvoice.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.previousMarketInvoice = payload;
        });
        builder.addCase(getPreviousMarketInvoice.rejected, errorHandler());
        // Create
        builder.addCase(createMarketInvoice.pending, (state) => {
            state.loading = true;
            state.marketInvoiceIdAdded = undefined;
            state.error = null;
        });
        builder.addCase(createMarketInvoice.fulfilled, (state, { payload }) => {
            state.marketInvoiceIdAdded = payload.id;
            state.error = null;
            state.marketInvoicesById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(createMarketInvoice.rejected, errorHandler());
        // Update
        builder.addCase(updateMarketInvoice.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateMarketInvoice.fulfilled, (state, { payload }) => {
            state.marketInvoicesById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateMarketInvoice.rejected, errorHandler());
        // Delete
        builder.addCase(deleteMarketInvoice.pending, (state) => {
            state.loading = true;
            state.isDeleteFulfilled = false;
        });
        builder.addCase(deleteMarketInvoice.fulfilled, (state, { payload }) => {
            const invoicesToUpdate = { ...state.marketInvoicesById };
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete invoicesToUpdate[payload.id];
            state.marketInvoicesById = invoicesToUpdate;
            state.error = null;
            state.loading = false;
            state.isDeleteFulfilled = true;
        });
        builder.addCase(deleteMarketInvoice.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.invoice.error;

export const selectGetMarketInvoicesLoading = (state: RootState) =>
    state.invoice.getMarketInvoicesLoading;

export const selectIsDeleteFulfilled = (state: RootState) => state.invoice.isDeleteFulfilled;

export const selectImportResult = (state: RootState) => state.invoice.importResult;

export const selectImportStatus = (state: RootState) => state.invoice.importResult?.status;

export const selectImportMessage = (state: RootState) => state.invoice.importResult?.message;

export const selectExcelFilename = (state: RootState): string | null =>
    state.invoice.importResult?.filename ?? null;

export const selectImportMarkets = (state: RootState) => state.invoice.importResult?.markets;

export const selectImportEngagements = (state: RootState) =>
    state.invoice.importResult?.engagements;

export const selectMarketInvoice =
    (id: MarketInvoice['id']) =>
    (state: RootState): MarketInvoice | undefined =>
        state.invoice.marketInvoicesById[id];

export const selectMarketInvoices = (state: RootState) => {
    const marketInvoices = Object.values(state.invoice.marketInvoicesById).sort(
        (invoice1: MarketInvoice, invoice2: MarketInvoice) => {
            const invoice1GroupCode = invoice1.groupCode ?? '';
            const invoice2GroupCode = invoice2.groupCode ?? '';
            return (
                getTime(new Date(`01/${invoice2.month}`)) -
                    getTime(new Date(`01/${invoice1.month}`)) ||
                invoice2.invoiceNumber - invoice1.invoiceNumber ||
                invoice1GroupCode.localeCompare(invoice2GroupCode, undefined, { numeric: true })
            );
        },
    );
    return marketInvoices;
};

export const selectTotalMarketInvoices = (state: RootState) => state.invoice.totalMarketInvoices;

export const selectMarketInvoiceIdAdded = (state: RootState) => state.invoice.marketInvoiceIdAdded;

export const selectLastMarketInvoices = (state: RootState) =>
    state.invoice.lastMarketInvoicesId.map((element) => element.id);

export const selectPreviousMarketInvoice = (state: RootState) =>
    state.invoice.previousMarketInvoice;

// Actions added into the `reducers` part
// export const actions = slice.actions;
export const { resetImportState } = slice.actions;

export default slice.reducer;
