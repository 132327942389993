import React from 'react';
import styled from 'styled-components/macro';

import RollingLoader from '../assets/images/loaders/ring-loader.gif';
import DualRingLoader from '../assets/images/loaders/dual-ring-loader.gif';
import EllipsisLoader from '../assets/images/loaders/ellipsis-loader.gif';
import DoubleRingLoader from '../assets/images/loaders/double-ring-loader.gif';

import { colors } from '../constants/colors';

const Container = styled.div`
    ${({ overlay }: Props) =>
        overlay
            ? `
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${colors.other.transparentWhite};
        z-index: 9999;
        `
            : ''}
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 6.25rem;
    min-height: 6.25rem;
`;

const RingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 6.25rem;
    min-height: 6.25rem;
`;

const Spinner = styled.img`
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;
`;

const Text = styled.div`
    text-align: center;
`;

type Props = {
    text?: string;
    overlay?: boolean;
    type?: 'RollingLoader' | 'DualRingLoader' | 'EllipsisLoader' | 'DoubleRingLoader';
};

const Loader: React.FC<Props> = ({ type = 'RollingLoader', text, overlay }) => {
    const textElement = text ? <Text>{text}</Text> : null;

    let SpinnerType;
    switch (type) {
        case 'RollingLoader':
            SpinnerType = RollingLoader;
            break;
        case 'DualRingLoader':
            SpinnerType = DualRingLoader;
            break;
        case 'EllipsisLoader':
            SpinnerType = EllipsisLoader;
            break;
        case 'DoubleRingLoader':
            SpinnerType = DoubleRingLoader;
            break;
        default:
            console.error(`No spinner of this type: ${type}.`);
            return null;
    }
    return (
        <Container overlay={overlay} data-testid="loader">
            <RingContainer>
                <Spinner src={SpinnerType} alt="loading..." />
            </RingContainer>
            {textElement}
        </Container>
    );
};

export default Loader;
