import type { Label, LabelValue } from '../slices/labelSlice';

export const getMandatoryLabelIds = (labels: Label[]): number[] =>
    labels.reduce((result: Array<Label['id']>, label: Label) => {
        if (label.isMandatory) {
            result.push(label.id);
        }
        return result;
    }, []);

export const validateMandatoryLabels = (labelValues: LabelValue[], labels: Label[]): boolean => {
    const mandatoryLabelIds = getMandatoryLabelIds(labels);
    const labelIdsWithValueSelected: number[] = labelValues.map(
        (labelValue: LabelValue) => labelValue.labelId,
    );
    const intersection = mandatoryLabelIds.filter(
        (id) => labelIdsWithValueSelected.indexOf(id) > -1,
    );
    const mandatoryLabelsAreValid =
        JSON.stringify(mandatoryLabelIds.sort()) === JSON.stringify(intersection.sort());
    return mandatoryLabelsAreValid;
};
