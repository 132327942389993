import type { Action } from '@reduxjs/toolkit';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';

import { useDispatch } from 'react-redux';

import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import roleReducer from './slices/roleSlice';
import siteReducer from './slices/siteSlice';
import permissionReducer from './slices/permissionSlice';
import organizationReducer from './slices/organizationSlice';
import labelReducer from './slices/labelSlice';
import operationReducer from './slices/operationSlice';
import budgetReducer from './slices/budgetSlice';
import planningReducer from './slices/planningSlice';
import marketReducer from './slices/marketSlice';
import budgetLineReducer from './slices/budgetLineSlice';
import budgetPriceHypothesisReducer from './slices/budgetPriceHypothesisSlice';
import marketsPriceHypothesisReducer from './slices/marketsPriceHypothesisSlice';
import tvaCodeReducer from './slices/tvaCodeSlice';
import priceIndexReducer from './slices/priceIndexSlice';
import spendingSchemaReducer from './slices/spendingSchemaSlice';
import engagementReducer from './slices/engagementSlice';
import invoiceReducer from './slices/invoiceSlice';
import groupCodeSequenceReducer from './slices/groupCodeSequenceSlice';
import noteReducer from './slices/noteSlice';
import noteValueMarketReducer from './slices/noteValueMarketSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    budget: budgetReducer,
    budgetLine: budgetLineReducer,
    engagement: engagementReducer,
    invoice: invoiceReducer,
    label: labelReducer,
    market: marketReducer,
    organization: organizationReducer,
    operation: operationReducer,
    permission: permissionReducer,
    planning: planningReducer,
    budgetPriceHypothesis: budgetPriceHypothesisReducer,
    marketsPriceHypothesis: marketsPriceHypothesisReducer,
    priceIndex: priceIndexReducer,
    role: roleReducer,
    site: siteReducer,
    spendingSchema: spendingSchemaReducer,
    tvaCode: tvaCodeReducer,
    user: userReducer,
    groupCodeSequence: groupCodeSequenceReducer,
    note: noteReducer,
    noteValueMarket: noteValueMarketReducer,
});

const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
