import type { ReactElement } from 'react';
import type React from 'react';

import type { Permission } from '../slices/authSlice';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';

type Props = {
    permissionsRequired: Permission[];
    children: ReactElement | null;
};

const PermissionAwareHider: React.FC<Props> = ({ permissionsRequired, children }) => {
    const hasRightToRead = usePermissionsCheck(permissionsRequired);

    if (!hasRightToRead) {
        return null;
    }

    return children ?? null;
};

export default PermissionAwareHider;
