import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { isAfter } from 'date-fns';

import { useSelector } from 'react-redux';

import Loader from '../Loader';
import type { Engagement } from '../../slices/engagementSlice';
import EcheancierEngagementModal from './EcheancierEngagementModal';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import EcheancierEngagementSimulationModal from './EcheancierEngagementSimulationModal';
import { selectMarket } from '../../slices/marketSlice';

type Props = {
    engagement: Engagement;
    onClose: (event?: React.SyntheticEvent<HTMLElement> | undefined) => void;
};

const EcheancierEngagement: React.FC<Props> = ({ engagement, onClose }) => {
    const { operationId, marketId }: { operationId?: string; marketId?: string } = useParams();

    const permissionNeededToRead = {
        code: 'ECHEANCIERS_ENGAGEMENT_READ',
        operationId,
    };
    const permissionNeededToEdit = { code: 'ECHEANCIERS_ENGAGEMENT_EDIT', operationId };
    const hasRightToRead = usePermissionsCheck([permissionNeededToRead]);
    const market = useSelector(selectMarket(Number(marketId)));
    const engagementAndMarketLabels = `${market?.label} / ${engagement.label}`;
    const [isSimulationDone, setIsSimulationDone] = useState(false);

    const checkIfEcheancierIsOutdated = () => {
        if (!engagement.echeancierLastCalculationAt) {
            return true;
        } else if (
            isAfter(
                new Date(engagement.lastUpdatedAtForElk),
                new Date(engagement.echeancierLastCalculationAt),
            )
        ) {
            return true;
        }
        return false;
    };
    const echeancierIsOutdated = checkIfEcheancierIsOutdated();

    if (hasRightToRead) {
        return echeancierIsOutdated && !isSimulationDone ? (
            <EcheancierEngagementSimulationModal
                operationId={Number(operationId)}
                marketId={Number(marketId)}
                engagementId={engagement.id}
                onFinishSimulating={() => setIsSimulationDone(true)}
            />
        ) : (
            <EcheancierEngagementModal
                operationId={Number(operationId)}
                marketId={Number(marketId)}
                permissionNeededToEdit={permissionNeededToEdit}
                engagementAndMarketLabels={engagementAndMarketLabels}
                engagement={engagement}
                onClose={onClose}
            />
        );
    } else {
        return <Loader overlay />;
    }
};

export default EcheancierEngagement;
