import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from './Button';
import { requestResetPasswordEmail } from '../slices/authSlice';
import { showFlag } from './Flag';
import type { User } from '../slices/userSlice';
import { useAppDispatch } from '../store';

type Props = { userEmail: User['email'] };

const ResetPasswordButton: React.FC<Props> = ({ userEmail }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [requestPasswordDone, setRequestPasswordDone] = useState(false);

    return (
        <Button
            onClick={() => {
                void dispatch(
                    requestResetPasswordEmail({
                        email: userEmail,
                    }),
                );
                setRequestPasswordDone(true);
                setTimeout(() => {
                    showFlag(
                        'success',
                        t('general.success'),
                        t('user.profile.resetPasswordEmailSent'),
                    );
                }, 1000);
            }}
            disabled={requestPasswordDone}
            data-testid="resetPasswordButton"
            aspect="secondary"
            text={t('user.resetPassword')}
            size="medium"
        />
    );
};

export default ResetPasswordButton;
