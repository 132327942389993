import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import * as Yup from 'yup';
import type { TFunction } from 'i18next';
import { Classes } from '@blueprintjs/core';

import type { IRoleFormData } from '../../slices/roleSlice';
import {
    getRoles,
    selectRolesHavingAnOperationScopeAndSortedByDefaultFirstThenByOrder,
    selectError,
    updateManyRoles,
    selectIsLoading as selectIsRoleLoading,
    selectIsUpdateManyRolesFulfilled,
    selectNbOfCustomRoles,
    deleteRole,
    selectIsDeleteRoleFulfilled,
    resetError,
} from '../../slices/roleSlice';
import type { IPermission } from '../../slices/permissionSlice';
import {
    getPermissions,
    selectPermissionsThatCanBeUsedOnCustomRole,
    selectIsLoading as selectIsPermissionLoading,
    selectPermissions,
} from '../../slices/permissionSlice';
import Table from '../../components/Table';
import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import CheckboxField from '../../components/CheckboxField';
import Text from '../../components/Text';
import Tooltip from '../../components/Tooltip';
import Icon from '../../components/Icon';
import { colors } from '../../constants/colors';
import type { ButtonProps } from '../../components/Button';
import Loader from '../../components/Loader';
import { showFlag } from '../../components/Flag';
import EditRoleModal from '../../components/EditRoleModal';
import Popover, { PopoverContent, PopoverItem } from '../../components/Popover';
import Elevation from '../../components/Elevation';
import Modal from '../../components/Modal';
import { useAppDispatch } from '../../store';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const CheckboxWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const RoleTitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const RoleTitle = styled(Text)`
    margin: 0;
    margin-right: 6px;
`;

const ContextualMenuIcon = styled(Icon)`
    margin-right: 0;
    cursor: pointer;
`;

const TitleAndDescriptionHeaderContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const PopoverMenuContainer = styled.div`
    display: flex;
`;

const PERMISSION_COL_WIDTH = 20;
const NB_COLUMNS_MIN = 5;
const ROLE_COL_WIDTH = (100 - PERMISSION_COL_WIDTH) / NB_COLUMNS_MIN;

export const MANDATORY_PERMISSIONS_BY_CODE = ['OPERATIONS_READ'];

export const getRoleFormSchema = (t: TFunction) =>
    Yup.object().shape({
        name: Yup.string().nullable(),
        description: Yup.string()
            .nullable()
            .max(255, t('errors.tooLong', { number: 255 })),
        label: Yup.string()
            .required(t('errors.required'))
            .min(5, t('errors.tooShort', { number: 5 }))
            .max(20, t('errors.tooLong', { number: 20 })),
        scope: Yup.string().oneOf(['operation']),
        isTechnical: Yup.boolean(),
        isCustom: Yup.boolean(),
        order: Yup.number().required(t('errors.required')).typeError(t('errors.required')),
    });

type Props = {
    headerProps: Partial<HeaderProps>;
};

const RolePermissionMatrix: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { organizationId }: { organizationId?: string } = useParams();
    const roles = useSelector(selectRolesHavingAnOperationScopeAndSortedByDefaultFirstThenByOrder);
    const nbOfCustomRoles = useSelector(selectNbOfCustomRoles);
    const allPermissions = useSelector(selectPermissions);
    const permissionsThatCanBeUsedOnCustomRole = useSelector(
        selectPermissionsThatCanBeUsedOnCustomRole,
    );
    const serverError = useSelector(selectError);
    const isRoleLoading = useSelector(selectIsRoleLoading);
    const isPermissionLoading = useSelector(selectIsPermissionLoading);
    const isUpdateManyRolesFulfilled = useSelector(selectIsUpdateManyRolesFulfilled);
    const isDeleteRoleFulfilled = useSelector(selectIsDeleteRoleFulfilled);

    const permissionNeededToRead = { code: 'ROLES_READ', organizationId };
    const permissionNeededToEdit = { code: 'ROLES_EDIT', organizationId };
    const permissionNeededToAdd = { code: 'ROLES_ADD', organizationId };
    const permissionNeededToDelete = { code: 'ROLES_DELETE' };
    const hasRightToReadRoles = usePermissionsCheck([permissionNeededToRead]);
    const hasRightToEditRoles = usePermissionsCheck([permissionNeededToEdit]);
    const hasRightToAddRoles = usePermissionsCheck([permissionNeededToAdd]);
    const hasRightToDeleteRoles = usePermissionsCheck([permissionNeededToDelete]);

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
    const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
    const [isDeleteRoleConfirmationModalOpen, setIsDeleteRoleConfirmationModalOpen] =
        useState(false);
    const [roleIdToEdit, setRoleIdToEdit] = useState<IRoleFormData['id']>(null);
    const [roleToEdit, setRoleToEdit] = useState<IRoleFormData | null>(null);
    const [roleIdToDelete, setRoleIdToDelete] = useState<IRoleFormData['id']>(null);
    const [isUpdateManyRolesPending, setIsUpdateManyRolesPending] = useState(false);
    const [isDeleteRolePending, setIsDeleteRolePending] = useState(false);

    const errorMessage = t('errors.error');
    let serverErrorMessage = '';
    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    const permissionsToDisplay = organizationId
        ? permissionsThatCanBeUsedOnCustomRole
        : allPermissions;

    const rolesRef = useRef(roles);

    useEffect(() => {
        void dispatch(getRoles({ organizationId }));
        void dispatch(getPermissions());
    }, [dispatch, organizationId]);

    useEffect(() => {
        if (isUpdateManyRolesPending) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
                dispatch(resetError());
            } else if (isUpdateManyRolesFulfilled) {
                showFlag('success', t('general.success'), t('roles.successfullyUpdatedRoles'));
                void dispatch(getRoles({ organizationId }));
                setIsUpdateManyRolesPending(false);
            }
        }
    }, [
        dispatch,
        errorMessage,
        isUpdateManyRolesFulfilled,
        isUpdateManyRolesPending,
        organizationId,
        serverError,
        serverErrorMessage,
        t,
    ]);

    useEffect(() => {
        if (isDeleteRolePending) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
                dispatch(resetError());
            } else if (isDeleteRoleFulfilled) {
                showFlag('success', t('general.success'), t('roles.successfullyDeletedRole'));
                void dispatch(getRoles({ organizationId }));
                setIsDeleteRolePending(false);
            }
        }
    }, [
        dispatch,
        errorMessage,
        isDeleteRoleFulfilled,
        isDeleteRolePending,
        isUpdateManyRolesFulfilled,
        isUpdateManyRolesPending,
        organizationId,
        serverError,
        serverErrorMessage,
        t,
    ]);

    useEffect(() => {
        if (roleIdToEdit) {
            setRoleToEdit(rolesRef.current.find((role) => role.id === roleIdToEdit) ?? null);
        }
    }, [roleIdToEdit, rolesRef]);

    useEffect(() => {
        if (roleToEdit && roleIdToEdit) {
            setIsEditRoleModalOpen(true);
            setRoleIdToEdit(null);
        }
    }, [roleIdToEdit, roleToEdit]);

    useEffect(() => {
        if (roleIdToDelete) {
            setIsDeleteRoleConfirmationModalOpen(true);
        }
    }, [roleIdToDelete]);

    const nbExtraBlankColumnsToAdd = Math.max(0, NB_COLUMNS_MIN - roles.length);

    const getHeaders = (formikProps: FormikProps<FormProps>) => {
        const roleLabels: Record<string, JSX.Element> = formikProps.values.roles.reduce(
            (object: Record<string, JSX.Element>, role: IRoleFormData, currentIndex) => {
                const roleLabel = role.label ?? '';
                const roleKey = `role-${currentIndex}`;

                return {
                    ...object,
                    [roleKey]: (
                        <RoleTitleWrapper>
                            <TitleAndDescriptionHeaderContainer>
                                <RoleTitle type="H100">{roleLabel}</RoleTitle>
                                {role.description && (
                                    <Tooltip content={role.description}>
                                        <Icon
                                            name="Info"
                                            color={colors.neutral.N400}
                                            width="0.875rem"
                                        />
                                    </Tooltip>
                                )}
                            </TitleAndDescriptionHeaderContainer>
                            {!isEditMode &&
                                (checkIfRoleIsEditable(role) || checkIfRoleIsDeletable(role)) && (
                                    <PopoverMenuContainer>
                                        {getPopoverMenu(role)}
                                    </PopoverMenuContainer>
                                )}
                        </RoleTitleWrapper>
                    ),
                };
            },
            {},
        );

        const extraRoleColumnHeaders: Record<string, string> = {};
        for (let i = 0; i < nbExtraBlankColumnsToAdd; i++) {
            extraRoleColumnHeaders[`extra-${i}`] = '';
        }

        const headers = {
            name: t('roles.permissionsByRoles'),
            ...roleLabels,
            ...extraRoleColumnHeaders,
        };
        return headers;
    };

    const getPopoverMenu = (role: IRoleFormData) => (
        <Popover iconWrapperLineHeight="1rem">
            <ContextualMenuIcon
                name="MoreHoriz"
                data-testid="popOverOptions"
                width="1rem"
                color={colors.neutral.N400}
            />
            <Elevation elevation="default">
                <PopoverContent>
                    {checkIfRoleIsEditable(role) && (
                        <PopoverItem
                            className={Classes.POPOVER_DISMISS}
                            onClick={() => {
                                setRoleIdToEdit(role.id);
                            }}
                            data-testid="editRole"
                            first
                        >
                            {t('role.editInformation')}
                        </PopoverItem>
                    )}
                    {checkIfRoleIsDeletable(role) && (
                        <PopoverItem
                            className={Classes.POPOVER_DISMISS}
                            onClick={() => {
                                setRoleIdToDelete(role.id);
                            }}
                            data-testid="deleteRole"
                        >
                            {t('role.deleteRole')}
                        </PopoverItem>
                    )}
                </PopoverContent>
            </Elevation>
        </Popover>
    );

    const checkIfRoleIsEditable = useCallback(
        (role: IRoleFormData) => hasRightToEditRoles && role.isCustom,
        [hasRightToEditRoles],
    );

    const checkIfRoleIsDeletable = (role: IRoleFormData) => hasRightToDeleteRoles && role.isCustom;

    const checkIfPermissionIsEditable = (permission: IPermission) =>
        !MANDATORY_PERMISSIONS_BY_CODE.includes(permission.code);

    const RoleCheckBox = useCallback(
        (formikProps: FormikProps<FormProps>, role: IRoleFormData, permission: IPermission) => {
            const roleHasPermission = role.permissions?.some(
                (permissionAdded: IPermission) => permissionAdded.id === permission.id,
            );

            return (
                <CheckboxWrapper>
                    <CheckboxField
                        key={permission.code}
                        id={permission.code}
                        data-testid={`permission${permission.code}`}
                        name="permissions"
                        type="checkbox"
                        disabled={
                            !isEditMode ||
                            !checkIfRoleIsEditable(role) ||
                            !checkIfPermissionIsEditable(permission)
                        }
                        checked={roleHasPermission}
                        onChange={(e) => {
                            const roleIndex = formikProps.values.roles.findIndex(
                                (formikRole) => formikRole.id === role.id,
                            );
                            if (e.target.checked)
                                formikProps.setFieldValue(`roles[${roleIndex}].permissions`, [
                                    ...(formikProps.values.roles[roleIndex].permissions ?? []),
                                    permission,
                                ]);
                            else {
                                formikProps.setFieldValue(
                                    `roles[${roleIndex}].permissions`,
                                    formikProps.values.roles[roleIndex].permissions?.filter(
                                        (formikPermission) => formikPermission.id !== permission.id,
                                    ),
                                );
                            }
                        }}
                        style={{ justifyContent: 'center' }}
                    />
                </CheckboxWrapper>
            );
        },
        [checkIfRoleIsEditable, isEditMode],
    );

    const getRenderCellMap = (formikProps: FormikProps<FormProps>) => {
        const roleRenderMap: Record<
            string,
            (permission: IPermission | Partial<IPermission>) => JSX.Element
        > = formikProps.values.roles.reduce(
            (
                object: Record<
                    string,
                    (permission: IPermission | Partial<IPermission>) => JSX.Element
                >,
                role: IRoleFormData,
                currentIndex,
            ) => {
                const roleKey = `role-${currentIndex}`;
                return {
                    ...object,
                    [roleKey]: (permission: IPermission | Partial<IPermission>) =>
                        RoleCheckBox(formikProps, role, permission as IPermission),
                };
            },
            {},
        );

        const extraRoleColumnHeaders: Record<string, () => JSX.Element> = {};
        for (let i = 0; i < nbExtraBlankColumnsToAdd; i++) {
            // eslint-disable-next-line react/jsx-no-useless-fragment -- needed
            extraRoleColumnHeaders[`extra-${i}`] = () => <></>;
        }

        const renderCellMap = {
            name: (permission: IPermission | Partial<IPermission>) => permission.code ?? '',
            ...roleRenderMap,
            ...extraRoleColumnHeaders,
        };
        return renderCellMap;
    };

    const computeButtonsOutOfProps = (formikProps: FormikProps<FormProps>) => {
        const buttons: ButtonProps[] = [];
        if (hasRightToEditRoles && organizationId) {
            if (isEditMode) {
                buttons.push({
                    'data-testid': 'cancel',
                    text: t('general.cancel'),
                    aspect: 'primary',
                    onClick() {
                        formikProps.resetForm();
                        setIsEditMode(false);
                    },
                });
                buttons.push({
                    'data-testid': 'saveModifications',
                    text: t('general.saveModifications'),
                    aspect: 'primary',
                    onClick() {
                        formikProps.handleSubmit();
                        setIsEditMode(false);
                    },
                });
            } else {
                buttons.push({
                    'data-testid': 'editUsersRoles',
                    text: t('roles.edit'),
                    aspect: 'primary',
                    bluePrintJsIconName: <Icon name="Edit" width="1.125rem" />,
                    onClick() {
                        formikProps.resetForm();
                        setIsEditMode(true);
                    },
                });
            }
        }
        if (hasRightToAddRoles && !isEditMode && organizationId) {
            buttons.push({
                'data-testid': 'addARole',
                text: t('roles.createACustomRole'),
                aspect: 'primary',
                bluePrintJsIconName: <Icon name="AddBox" width="1.125rem" />,
                onClick: () => setIsAddRoleModalOpen(true),
            });
        }
        return buttons;
    };

    const roleFormSchema = getRoleFormSchema(t);
    const rolesFormSchema = Yup.array(roleFormSchema);

    type FormProps = { roles: IRoleFormData[] };
    const initialValues = { roles: roles as IRoleFormData[] };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always falsy
    if ((isRoleLoading && roles.length === 0) || (isPermissionLoading && !permissionsToDisplay)) {
        return <Loader overlay />;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={rolesFormSchema}
            onSubmit={(values, actions) => {
                const rolesToUpdate = values.roles.filter((role) => role.isCustom);
                if (organizationId) {
                    setIsUpdateManyRolesPending(true);
                    void dispatch(updateManyRoles({ roles: rolesToUpdate, organizationId }));
                }
                actions.setSubmitting(true);
            }}
            enableReinitialize
        >
            {(formikProps: FormikProps<FormProps>) => (
                <MainLayout
                    header={
                        <Header
                            {...headerProps}
                            title={`${t('roles.title')} (${roles.length})`}
                            buttons={computeButtonsOutOfProps(formikProps)}
                        />
                    }
                    smallContentSidePadding
                >
                    <Helmet>
                        <title>{t('roles.title')}</title>
                    </Helmet>
                    <Container>
                        {hasRightToReadRoles && roles.length > 0 && (
                            <Table
                                headers={getHeaders(formikProps)}
                                rows={permissionsToDisplay}
                                renderCellMap={getRenderCellMap(formikProps)}
                                data-testid="rolePermissionMatrix"
                                fixedStartColumnsNumber={1}
                                tableHeight="80vh"
                                columnSizes={`${PERMISSION_COL_WIDTH}% ${`${ROLE_COL_WIDTH}% `.repeat(
                                    formikProps.values.roles.length + nbExtraBlankColumnsToAdd,
                                )}`}
                                isFixedHeader
                                fullBorder
                            />
                        )}
                    </Container>
                    {isAddRoleModalOpen && (
                        <EditRoleModal
                            isOpen={isAddRoleModalOpen}
                            setIsOpen={setIsAddRoleModalOpen}
                            permissionNeeded={permissionNeededToAdd}
                            orderMax={nbOfCustomRoles + 1}
                            isCreationMode
                        />
                    )}
                    {isEditRoleModalOpen && roleToEdit && (
                        <EditRoleModal
                            role={roleToEdit}
                            isOpen={isEditRoleModalOpen}
                            setIsOpen={setIsEditRoleModalOpen}
                            permissionNeeded={permissionNeededToEdit}
                            orderMax={nbOfCustomRoles}
                        />
                    )}
                    {isDeleteRoleConfirmationModalOpen && (
                        <Modal
                            isOpen={isDeleteRoleConfirmationModalOpen}
                            onCloseButtonPressed={() => {
                                setIsDeleteRoleConfirmationModalOpen(false);
                            }}
                            buttons={[
                                {
                                    text: t('general.cancel'),
                                    aspect: 'secondary',
                                    'data-testid': 'cancelDelete',
                                    onClick() {
                                        setIsDeleteRoleConfirmationModalOpen(false);
                                    },
                                },
                                {
                                    text: t('general.confirm'),
                                    aspect: 'primary',
                                    'data-testid': 'confirm',
                                    onClick() {
                                        if (roleIdToDelete && organizationId) {
                                            setIsDeleteRoleConfirmationModalOpen(false);
                                            setIsDeleteRolePending(true);
                                            void dispatch(
                                                deleteRole({ id: roleIdToDelete, organizationId }),
                                            );
                                        }
                                    },
                                },
                            ]}
                            title={t('role.deletion')}
                            size="small"
                            iconName="Warning"
                            iconColor={colors.yellow.Y400}
                            centerTitle
                        >
                            <Text style={{ margin: '0 0 1rem 0' }}>
                                {t('role.confirmDeletion')}
                            </Text>
                        </Modal>
                    )}
                </MainLayout>
            )}
        </Formik>
    );
};

export default RolePermissionMatrix;
