import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import type { Site as SiteType } from '../../slices/siteSlice';
import {
    getSites,
    selectSitesByOrganizationIdAndPage,
    selectTotalSites,
} from '../../slices/siteSlice';
import type { QueryParams, ListViewType } from '../../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../../slices/common';
import SiteList from '../../components/SiteList';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import MainLayout from '../../components/MainLayout';
import GridCol from '../../components/GridCol';
import GridRow from '../../components/GridRow';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import { usePageNumber } from '../../hooks/usePageNumber';
import { useAppDispatch } from '../../store';

const ViewToggle = styled.div`
    display: flex;
    div:first-child {
        margin: 0 1rem 0 0;
    }
    justify-content: flex-end;
`;

type Props = {
    headerProps: HeaderProps;
};

const Sites: React.FunctionComponent<Props> = ({ headerProps }) => {
    const { organizationId } = useParams<{ organizationId: string }>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    const pageNumber = usePageNumber();
    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: pageNumber,
        organizationId,
    });
    // Handle view toggler
    const [viewType, setViewType] = useState<ListViewType>(
        localStorage.getItem('sites_list_type') === 'table' ? 'table' : 'card',
    );
    const createViewClickHandler = (type: 'card' | 'table') => () => {
        setViewType(type);
        localStorage.setItem('sites_list_type', type);
    };
    const hasRightToEditOrganizations = usePermissionsCheck([
        { code: 'ORGANIZATIONS_EDIT', organizationId },
    ]);

    // Hooks
    const dispatch = useAppDispatch();
    useEffect(() => {
        void dispatch(getSites(queryParams));
    }, [dispatch, queryParams]);

    const totalSites = useSelector(selectTotalSites);
    const sites = useSelector(
        selectSitesByOrganizationIdAndPage(organizationId, queryParams.page),
    ) as SiteType[];
    const addSite = () => {
        navigate(`/organizations/${organizationId}/sites/new`, {
            state: {
                organizationId,
                breadcrumbs: headerProps.breadcrumbs,
                goBackTo: `/organizations/${organizationId}/sites`,
            },
        });
    };

    return (
        <MainLayout
            header={
                <Header
                    {...headerProps}
                    buttons={
                        hasRightToEditOrganizations
                            ? [
                                  {
                                      'data-testid': 'addSiteButton',
                                      text: t('site.addSite'),
                                      aspect: 'primary',
                                      onClick: addSite,
                                  },
                              ]
                            : []
                    }
                    showBackButton
                />
            }
            footer={
                totalSites > 0 ? (
                    <Pagination
                        data-testid="pagination"
                        initialPage={queryParams.page}
                        currentPage={queryParams.page}
                        pageNeighbours={2}
                        nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                        nbItemsTotal={totalSites}
                        onPageNumberClicked={(page: number) =>
                            setQueryParams({ ...queryParams, page })
                        }
                        pageUrl="sites"
                    />
                ) : null
            }
            smallContentSidePadding
        >
            <GridRow style={{ marginBottom: '1rem' }}>
                <GridCol>
                    <ViewToggle>
                        <Button
                            onClick={createViewClickHandler('table')}
                            iconName="ViewList"
                            isPressed={viewType === 'table'}
                            data-testid="tableViewButton"
                            aspect="onlyIcon"
                            toggle
                        />
                        <Button
                            onClick={createViewClickHandler('card')}
                            iconName="ViewModule"
                            isPressed={viewType === 'card'}
                            data-testid="cardViewButton"
                            aspect="onlyIcon"
                            toggle
                        />
                    </ViewToggle>
                </GridCol>
            </GridRow>
            <GridRow>
                <GridCol>
                    <SiteList
                        viewType={viewType}
                        sites={sites}
                        stateForNavigation={
                            location.pathname && location.search
                                ? {
                                      goBackTo: `${location.pathname}${location.search}`,
                                  }
                                : undefined
                        }
                    />
                </GridCol>
            </GridRow>
        </MainLayout>
    );
};

export default Sites;
