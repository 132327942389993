import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FormikProps } from 'formik';
import { Form } from 'formik';
import type { IDialogProps } from '@blueprintjs/core';
import { Tab, Tabs } from '@blueprintjs/core';

import { useParams } from 'react-router-dom';

import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import Modal from './Modal';
import type { ButtonProps } from './Button';
import Icon from './Icon';
import type { Permission } from '../slices/authSlice';
import type { PriceVariationSettings } from '../slices/common';

type TabFunction<T> = <B>(props: ComponentProps<T>) => JSX.Element;

type ModalTab<T> = {
    tabId: string;
    Component: TabFunction<T>;
    validateAndGoToNextTab?: () => Promise<void> | Promise<boolean>;
    validateSaveAndGoToEcheancier: () => void;
    validateAndGoToPreviousTab?: () => void;
    validateSaveAndCloseModal: () => void;
    title: React.ReactNode;
    entityId?: number;
    priceVariationSettingsOfFirstLine?: PriceVariationSettings;
};

type ComponentProps<T> = {
    entityId?: number;
    hasRightToEdit: boolean;
    permissionsNeeded: Permission[];
    formikProps: FormikProps<T>;
    isEditMode?: boolean;
    priceVariationSettingsOfFirstLine?: PriceVariationSettings;
};

export type Props<T> = {
    onClose: (event?: React.SyntheticEvent<HTMLElement> | undefined) => void;
    iconColor?: string;
    'data-testid'?: string;
    tabs: Array<ModalTab<T>>;
    formikProps: FormikProps<T>;
    selectedTab: string | number;
    isOpen: boolean;
    hasRightToEdit: boolean;
    permissionsNeeded: Permission[];
    onCancelClick: () => void;
    isPending?: boolean;
    scrollableBody?: boolean;
    backgroundColor?: string;
};

const LineModal = <B,>({
    title = '',
    onClose,
    tabs,
    formikProps,
    style,
    selectedTab,
    isOpen,
    hasRightToEdit,
    permissionsNeeded,
    onCancelClick,
    isPending,
    scrollableBody,
    ...rest
}: Props<B> & IDialogProps) => {
    const { t } = useTranslation();
    const { operationId } = useParams<{ operationId: string }>();

    const hasRightToReadEcheanciersEngagement = usePermissionsCheck([
        { code: 'ECHEANCIERS_ENGAGEMENT_READ', operationId },
    ]);

    const [isSaveOnlyButtonClicked, setIsSaveOnlyButtonClicked] = useState<boolean>(false);

    const currentSelectedTab = tabs.find(({ tabId }) => tabId === selectedTab);

    let isFirstTabSelected = false;
    let isLastTabSelected = false;

    if (currentSelectedTab) {
        isFirstTabSelected = tabs.indexOf(currentSelectedTab) === 0;
        isLastTabSelected = tabs.indexOf(currentSelectedTab) === tabs.length - 1;
    }

    const computeButtons = () => {
        const buttons: ButtonProps[] = [];

        buttons.push({
            text: t('general.cancel'),
            aspect: 'secondary',
            'data-testid': 'cancel',
            onClick() {
                onCancelClick();
                onClose();
            },
            size: 'small',
        });
        buttons.push({
            text: t('general.previous'),
            aspect: 'secondary',
            'data-testid': 'previous',
            onClick() {
                if (currentSelectedTab?.validateAndGoToPreviousTab) {
                    currentSelectedTab.validateAndGoToPreviousTab();
                }
            },
            disabled: isFirstTabSelected,
            size: 'small',
            bluePrintJsIconName: <Icon width="1.3rem" name="ArrowBack" />,
        });
        buttons.push({
            text: t('general.next'),
            aspect: 'primary',
            'data-testid': 'next',
            async onClick() {
                if (currentSelectedTab?.validateAndGoToNextTab) {
                    await currentSelectedTab.validateAndGoToNextTab();
                }
            },
            disabled: isLastTabSelected,
            size: 'small',
            bluePrintJsRightIconName: <Icon width="1.3rem" name="ArrowForward" />,
        });
        if (hasRightToReadEcheanciersEngagement) {
            buttons.push({
                text: t('engagement.saveAndGoToEcheancier'),
                aspect: 'primary',
                'data-testid': 'saveAndGoToEcheancier',
                onClick() {
                    currentSelectedTab?.validateSaveAndGoToEcheancier();
                    setIsSaveOnlyButtonClicked(false);
                },
                isLoading: isPending && !isSaveOnlyButtonClicked,
                disabled: isPending && isSaveOnlyButtonClicked,
                size: 'small',
            });
        }
        buttons.push({
            text: t('general.record'),
            aspect: 'primary',
            'data-testid': 'record',
            onClick() {
                currentSelectedTab?.validateSaveAndCloseModal();
                setIsSaveOnlyButtonClicked(true);
            },
            isLoading: isPending && isSaveOnlyButtonClicked,
            disabled: isPending && !isSaveOnlyButtonClicked,
            size: 'small',
        });
        return buttons;
    };

    return (
        <Modal
            isOpen={isOpen}
            onCloseButtonPressed={() => {
                onClose();
                onCancelClick();
            }}
            title={title}
            enforceFocus={false}
            usePortal={false}
            canOutsideClickClose={false}
            size="full"
            buttons={computeButtons()}
            scrollableBody={scrollableBody}
            specificPadding="0"
            {...rest}
        >
            <Form style={{ minWidth: '100%' }}>
                <Tabs id="modalTabs" selectedTabId={selectedTab}>
                    {tabs.map(
                        ({
                            tabId,
                            Component,
                            title: tabTitle,
                            entityId,
                            priceVariationSettingsOfFirstLine,
                        }) => (
                            <Tab
                                key={tabId}
                                id={tabId}
                                title={tabTitle}
                                panel={
                                    <Component<B>
                                        entityId={entityId}
                                        hasRightToEdit={hasRightToEdit}
                                        permissionsNeeded={permissionsNeeded}
                                        formikProps={formikProps}
                                        priceVariationSettingsOfFirstLine={
                                            priceVariationSettingsOfFirstLine
                                        }
                                    />
                                }
                            />
                        ),
                    )}
                </Tabs>
            </Form>
        </Modal>
    );
};
export default LineModal;
