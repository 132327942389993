import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError } from './common';
import { errorHandler } from './common';

export type Note = {
    id: number;
    name: string;
    isMarketNote: boolean;
};

interface NoteState {
    notesById: Record<number, Note>;
    loading: boolean;
    error: string | null;
}

export const initialState: NoteState = {
    notesById: {},
    loading: false,
    error: null,
};

export const getNotes = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Note[],
    // First argument to the payload creator
    void,
    {
        rejectValue: ServerError;
    }
>('NOTE/GETALL', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data: Note[] }>('/notes');
        if (Array.isArray(response.data.data)) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const slice = createSlice({
    name: 'note',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getNotes.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getNotes.fulfilled, (state, { payload }) => {
            payload.forEach((item: Note) => {
                state.notesById[item.id] = item;
            });
            state.loading = false;
        });
        builder.addCase(getNotes.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.note.error;
export const selectNotes = (state: RootState) => Object.values(state.note.notesById);
export const selectNotesById = (state: RootState) => state.note.notesById;

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
