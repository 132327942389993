import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import axios from 'axios';
import { Classes } from '@blueprintjs/core';

import { readFileAsync } from '../utils/readFile';

import Button from './Button';
import Popover, { PopoverContent, PopoverItem } from './Popover';
import Elevation from './Elevation';
import Tooltip from './Tooltip';
import Text from './Text';

import { colors } from '../constants/colors';

const Container = styled.section`
    display: flex;
`;

const StyledInput = styled.input`
    display: none;
`;

const ButtonsPart = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 0.25rem;
`;

type Props = {
    handleFileUploaded: (id: number) => void;
    fileId?: number;
    isEditMode?: boolean;
    handleWarrantyLineRemoved: (id: number) => void;
    index: number;
};

type FileFromBack = {
    base64?: string;
    filename?: string;
    mimetype?: string;
    src?: string;
    buffer?: {
        data?: string;
    };
    id?: number;
};

const FileUpload: React.FC<Props> = ({
    handleFileUploaded,
    fileId,
    isEditMode,
    handleWarrantyLineRemoved,
    index,
}) => {
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileSelected, setFileSelected] = useState<FileFromBack>();
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const getFile = async (id: number) => {
            const response = await axios.get<{ data?: FileFromBack }>(`/files/${id}`);
            const file = response.data.data;
            const src = file?.buffer?.data
                ? `data:${file.mimetype};base64,${Buffer.from(file.buffer.data).toString('base64')}`
                : '';
            setFileSelected({
                id,
                filename: file?.filename,
                src,
                mimetype: file?.mimetype,
            });
        };
        if (fileId) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- necessary
            getFile(fileId);
        }
    }, [fileId]);

    const handleIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setError(false);
        if (e.target.files && e.target.files.length > 0) {
            setLoading(true);
            const file = e.target.files[0];
            if (fileSelected?.id) {
                await handleFileDelete();
            }
            try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- necessary
                const arrayBuffer: any = await readFileAsync(file);
                const base64 = arrayBuffer.split(',')[1];
                const data = {
                    base64,
                    filename: file.name,
                    mimetype: file.type,
                    src: arrayBuffer,
                };
                const response = await axios.post<{ data?: { id: number } }>('/files', data);
                setLoading(false);
                if (response.data.data) {
                    setFileSelected({ ...file, ...data });
                    handleFileUploaded(response.data.data.id);
                } else {
                    throw new Error('No data returned');
                }
            } catch (err: unknown) {
                setFileSelected(undefined);
                setLoading(false);
                setError(true);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        }
    };

    const handleFileDelete = async () => {
        setLoading(true);
        if (fileSelected?.id) {
            try {
                const response = await axios.delete<{ data?: { id: number } }>(
                    `/files/${fileSelected.id}`,
                );
                setLoading(false);
                if (response.status === 204) {
                    setFileSelected(undefined);
                    handleFileUploaded(0);
                } else {
                    throw new Error('Error deleting file');
                }
            } catch (err: unknown) {
                setFileSelected(undefined);
                setLoading(false);
                setError(true);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        }
    };

    const downloadFile = (file: FileFromBack) => {
        if (file.src && file.filename) {
            const link = document.createElement('a');
            link.href = file.src;
            link.download = file.filename;
            link.click();
        }
    };

    const popoverTarget = (
        <Container>
            <Button
                error={error}
                aspect="onlyIcon"
                size="small"
                tooltip={fileSelected?.filename}
                isPressed={Boolean(fileSelected)}
                iconName={fileSelected ? 'DescriptionOutline' : 'AttachFile'}
                onClick={!fileSelected ? handleIconClick : undefined}
                isLoading={loading}
                iconSize="1.25rem"
                hoverColor={colors.green.G400}
                toggle
            />
            <StyledInput ref={fileInputRef} type="file" onChange={handleFileInputChange} />
        </Container>
    );

    if (!isEditMode) {
        if (fileSelected?.filename) {
            return (
                <Text
                    color={colors.green.G400}
                    onClick={() => downloadFile(fileSelected)}
                    style={{ cursor: 'pointer' }}
                >
                    <Tooltip content={fileSelected.filename}>
                        {fileSelected.filename.substring(0, 10)}
                    </Tooltip>
                </Text>
            );
        }
        return null;
    } else {
        return (
            <ButtonsPart>
                <Popover>
                    {popoverTarget}
                    <Elevation elevation="default">
                        {fileSelected && (
                            <PopoverContent>
                                {fileSelected.filename ? (
                                    <PopoverItem
                                        type="default"
                                        className={Classes.POPOVER_DISMISS}
                                        onClick={() => downloadFile(fileSelected)}
                                        style={{
                                            padding: '1rem 1.125rem 0.5rem 1.125rem',
                                        }}
                                        first
                                    >
                                        {fileSelected.filename}
                                    </PopoverItem>
                                ) : null}
                                <PopoverItem
                                    onClick={handleIconClick}
                                    type="default"
                                    className={Classes.POPOVER_DISMISS}
                                    style={{
                                        padding: '1rem 1.125rem 0.5rem 1.125rem',
                                    }}
                                >
                                    {t('general.replace')}
                                </PopoverItem>
                                <PopoverItem
                                    onClick={handleFileDelete}
                                    type="default"
                                    className={Classes.POPOVER_DISMISS}
                                    style={{
                                        padding: '0.5rem 1.125rem 0.5rem 1.125rem',
                                    }}
                                    last
                                >
                                    {t('general.delete')}
                                </PopoverItem>
                            </PopoverContent>
                        )}
                    </Elevation>
                </Popover>
                <Button
                    iconName="DeleteOutline"
                    aspect="onlyIcon"
                    size="small"
                    iconSize="1.25rem"
                    onClick={async () => {
                        await handleFileDelete();
                        handleWarrantyLineRemoved(index);
                    }}
                    hoverColor={colors.red.R400}
                />
            </ButtonsPart>
        );
    }
};

export default FileUpload;
