import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { showFlag } from './Flag';
import type { Budget } from '../slices/budgetSlice';
import {
    getBudget,
    getBudgetSimulation,
    selectBudgetSimulation,
    resetBudgetStatus,
} from '../slices/budgetSlice';
import { getBudgetLines } from '../slices/budgetLineSlice';
import type { Operation } from '../slices/operationSlice';
import {
    getOperation,
    getOperationSimulation,
    selectOperationSimulation,
    resetOperationStatus,
    selectOperationHasEngagement,
} from '../slices/operationSlice';
import type { Market } from '../slices/marketSlice';
import { getEngagements } from '../slices/engagementSlice';
import type { MarketsPriceHypothesis } from '../slices/marketsPriceHypothesisSlice';
import Button from './Button';
import type { IconName } from './Icon';
import Icon from './Icon';
import { useAppDispatch } from '../store';
import Modal from './Modal';
import { colors } from '../constants/colors';
import Text from './Text';

type Props = {
    type: 'budget' | 'operation';
    isEditMode?: boolean;
    hasRightToEdit?: boolean;
    budget?: Budget | null;
    operationId?: Operation['id'] | null;
    marketId?: Market['id'] | null;
    disabled?: boolean;
    text?: string;
    iconName?: IconName;
    marketsPriceHypothesis?: MarketsPriceHypothesis[] | null;
    openPriceVariationModal?: () => void;
};

let interval: number | NodeJS.Timer;

const SimulationButton: React.FunctionComponent<Props> = ({
    type,
    isEditMode,
    budget,
    operationId,
    hasRightToEdit,
    marketId,
    disabled,
    text,
    iconName,
    marketsPriceHypothesis,
    openPriceVariationModal,
}: Props) => {
    const { t } = useTranslation();
    const statusSelector =
        type === 'budget'
            ? selectBudgetSimulation(budget?.id)
            : selectOperationSimulation(operationId);
    const simulationStatus = useSelector(statusSelector);
    const operationHasEngagements = useSelector(selectOperationHasEngagement(Number(operationId)));
    const [showButton, setShowButton] = useState<boolean>(false);
    const [isInvalidPriceHypotesesModalOpen, setIsInvalidPriceHypotesesModalOpen] = useState(false);
    const dispatch = useAppDispatch();

    const successMessage = t('general.success');
    const errorMessage = t('errors.error');
    const simulationError = t('simulation.errorSimulation');
    const simulationSuccess = t('simulation.simulationDone');

    useEffect(() => {
        let showButtonTemp = true;
        if (type === 'budget') {
            showButtonTemp = !(
                isEditMode ??
                (!budget || budget.totalAmountTtcWithoutVariations === 0 || !budget.updatedAt)
            );
        } else {
            showButtonTemp = Boolean(hasRightToEdit && operationId && operationHasEngagements);
        }
        setShowButton(showButtonTemp);
    }, [budget, hasRightToEdit, isEditMode, operationHasEngagements, operationId, type]);

    // componentWillUnmount
    useEffect(() => () => clearInterval(interval), []);

    useEffect(() => {
        if (simulationStatus === 'done') {
            showFlag('success', successMessage, simulationSuccess);
            clearInterval(interval);
            if (type === 'budget' && budget) {
                void dispatch(getBudget({ id: budget.id, operationId: budget.operationId }));
                void dispatch(
                    getBudgetLines({
                        operationId: budget.operationId,
                        budgetId: budget.id,
                        all: true,
                    }),
                );
                dispatch(resetBudgetStatus({ budgetId: budget.id }));
            } else {
                void dispatch(getOperation(Number(operationId)));
                dispatch(resetOperationStatus({ operationId }));
                if (marketId) {
                    void dispatch(
                        getEngagements({
                            operationId: Number(operationId),
                            marketId: Number(marketId),
                        }),
                    );
                }
            }
        } else if (simulationStatus === 'errored') {
            showFlag('error', errorMessage, simulationError);
            clearInterval(interval);
            if (type === 'budget' && budget) {
                void dispatch(getBudget({ id: budget.id, operationId: budget.operationId }));
                dispatch(resetBudgetStatus({ budgetId: budget.id }));
            } else {
                void dispatch(getOperation(Number(operationId)));
                dispatch(resetOperationStatus({ operationId }));
                if (marketId) {
                    void dispatch(
                        getEngagements({
                            operationId: Number(operationId),
                            marketId: Number(marketId),
                        }),
                    );
                }
            }
        } else if (simulationStatus === null && interval) {
            clearInterval(interval);
        } else if (
            (simulationStatus === 'in_progress' || simulationStatus === 'not_started') &&
            !interval
        ) {
            interval = setInterval(() => {
                if (type === 'budget' && budget) {
                    void dispatch(
                        getBudgetSimulation({ id: budget.id, operationId: budget.operationId }),
                    );
                } else if (operationId) {
                    void dispatch(getOperationSimulation({ operationId }));
                }
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [simulationStatus]);

    let buttonText = text ?? t('simulation.startSimulation');

    if (simulationStatus === 'in_progress' || simulationStatus === 'not_started') {
        buttonText = t('simulation.runningSimulation');
    }
    const handleSimulationButtonClick = async () => {
        if (marketsPriceHypothesis) {
            const validPriceHypothesis = marketsPriceHypothesis.filter(
                (hypothesis) => hypothesis.increasingFactor,
            );
            if (validPriceHypothesis.length) {
                await startSimulation();
            } else {
                setIsInvalidPriceHypotesesModalOpen(true);
            }
        } else {
            await startSimulation();
        }
    };

    const startSimulation = async () => {
        if (
            simulationStatus === 'done' ||
            simulationStatus === 'errored' ||
            simulationStatus === null
        ) {
            // Create simulation
            try {
                let path = `/operations/${operationId}/simulation`;
                if (type === 'budget' && budget) {
                    path = `/operations/${budget.operationId}/budgets/${budget.id}/simulation`;
                }
                await axios.post(path);
                if (type === 'budget' && budget) {
                    void dispatch(
                        getBudgetSimulation({ id: budget.id, operationId: budget.operationId }),
                    );
                } else if (operationId) {
                    void dispatch(getOperationSimulation({ operationId }));
                }
                interval = setInterval(() => {
                    if (type === 'budget' && budget) {
                        void dispatch(
                            getBudgetSimulation({
                                id: budget.id,
                                operationId: budget.operationId,
                            }),
                        );
                    } else if (operationId) {
                        void dispatch(getOperationSimulation({ operationId }));
                    }
                }, 2000);
            } catch (error: unknown) {
                showFlag('error', errorMessage, simulationError);
            }
        } else {
            console.error('Cannot create simulation, status is', simulationStatus);
        }
    };

    const isSimulationLoading =
        simulationStatus === 'in_progress' || simulationStatus === 'not_started';

    const icon =
        !isSimulationLoading && iconName
            ? {
                  bluePrintJsIconName: <Icon name={iconName} />,
              }
            : {};

    return showButton ? (
        <>
            <Button
                id="simulationButton"
                onClick={handleSimulationButtonClick}
                style={{ marginLeft: '1.5rem' }}
                text={buttonText}
                data-testid="budgetSimulation"
                aspect="secondary"
                size={type === 'budget' ? 'small' : 'medium'}
                disabled={disabled ?? isSimulationLoading}
                isLoading={isSimulationLoading}
                {...icon}
            />
            {isInvalidPriceHypotesesModalOpen ? (
                <Modal
                    isOpen={isInvalidPriceHypotesesModalOpen}
                    onCloseButtonPressed={() => setIsInvalidPriceHypotesesModalOpen(false)}
                    buttons={[
                        {
                            text: t('simulation.startSimulation'),
                            aspect: 'secondary',
                            'data-testid': 'cancelDelete',
                            async onClick() {
                                setIsInvalidPriceHypotesesModalOpen(false);
                                await startSimulation();
                            },
                        },
                        {
                            text: t('markets.configurePriceVariations'),
                            aspect: 'primary',
                            'data-testid': 'confirm',
                            onClick() {
                                setIsInvalidPriceHypotesesModalOpen(false);
                                if (openPriceVariationModal) {
                                    openPriceVariationModal();
                                }
                            },
                        },
                    ]}
                    title={t('market.noPriceHypothesisTitle')}
                    size="medium"
                    iconColor={colors.green.G400}
                >
                    <Text style={{ margin: '0 0 1rem 0' }}>
                        {t('market.noPriceHypothesisText')}
                    </Text>
                </Modal>
            ) : null}
        </>
    ) : null;
};

export default SimulationButton;
