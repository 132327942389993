import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSelector } from 'react-redux';

import type { FormikProps } from 'formik';
import { Formik } from 'formik';

import { useParams } from 'react-router-dom';

import Modal from './Modal';
import GridRow from './GridRow';
import GridCol from './GridCol';
import type { Permission } from '../slices/authSlice';
import PermissionAwareText from './PermissionAwareText';
import { colors } from '../constants/colors';
import PermissionAwareTextarea from './PermissionAwareTextarea';
import FormikOptionDropdown from './dropdown/FormikOptionDropdown';
import type { IRoleFormData } from '../slices/roleSlice';
import {
    createRole,
    selectIsCreateRoleFulfilled,
    updateRole,
    selectIsUpdateRoleFulfilled,
    selectError,
    resetError,
} from '../slices/roleSlice';
import {
    getRoleFormSchema,
    MANDATORY_PERMISSIONS_BY_CODE,
} from '../pages/rolePermissionMatrix/RolePermissionMatrix';
import type { Option } from './SelectField';
import { showFlag } from './Flag';
import { getPermissions, selectAllPermissionsByCodes } from '../slices/permissionSlice';
import { useAppDispatch } from '../store';

export const formatRoleName = (roleLabel: string) => roleLabel.replace(' ', '_').toUpperCase();

type Props = {
    isCreationMode?: boolean;
    role?: IRoleFormData;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    permissionNeeded: Permission;
    orderMax: number;
};

const Container = styled.div`
    width: 100%;
`;

const EditRoleModal: React.FC<Props> = ({
    isCreationMode = false,
    role,
    isOpen,
    setIsOpen,
    permissionNeeded,
    orderMax,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { organizationId }: { organizationId?: string } = useParams();

    const isCreateRoleFulfilled = useSelector(selectIsCreateRoleFulfilled);
    const isUpdateRoleFulfilled = useSelector(selectIsUpdateRoleFulfilled);
    const serverError = useSelector(selectError);

    const mandatoryPermissions = useSelector(
        selectAllPermissionsByCodes(MANDATORY_PERMISSIONS_BY_CODE),
    );

    const [isCreatingOrUpdatingRole, setIsCreatingOrUpdatingRole] = useState(false);

    const errorMessage = t('errors.error');
    let serverErrorMessage = '';
    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    useEffect(() => {
        void dispatch(getPermissions());
    }, [dispatch]);

    useEffect(() => {
        if (isCreatingOrUpdatingRole) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
                dispatch(resetError());
            } else if (isUpdateRoleFulfilled || isCreateRoleFulfilled) {
                setIsOpen(false);
            }
        }
    }, [
        dispatch,
        errorMessage,
        isCreateRoleFulfilled,
        isCreatingOrUpdatingRole,
        isUpdateRoleFulfilled,
        serverError,
        serverErrorMessage,
        setIsOpen,
    ]);

    const orderOptions: Array<Option<number>> = [];
    for (let i = 1; i <= orderMax; i++) {
        orderOptions.push({
            label: String(i),
            value: i,
        });
    }

    const initialValues = role ?? {
        name: '',
        description: '',
        label: '',
        scope: 'operation',
        isTechnical: false,
        isCustom: true,
        order: null,
        organizationId,
        permissions: [],
    };

    const roleFormSchema = getRoleFormSchema(t);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={roleFormSchema}
            onSubmit={(values, actions) => {
                if (values.label && values.organizationId) {
                    const roleToUpsert = {
                        ...values,
                        organizationId: values.organizationId,
                        name: formatRoleName(values.label),
                        permissions: isCreationMode ? mandatoryPermissions : role?.permissions,
                    };

                    setIsCreatingOrUpdatingRole(true);
                    if (isCreationMode) {
                        void dispatch(createRole(roleToUpsert));
                    } else {
                        void dispatch(updateRole(roleToUpsert));
                    }
                }

                actions.setSubmitting(true);
            }}
        >
            {(formikProps: FormikProps<IRoleFormData>) => (
                <Modal
                    isOpen={isOpen}
                    onCloseButtonPressed={() => {
                        setIsOpen(false);
                    }}
                    buttons={[
                        {
                            text: t('general.cancel'),
                            aspect: 'secondary',
                            'data-testid': 'cancelDelete',
                            onClick() {
                                setIsOpen(false);
                            },
                        },
                        {
                            text: t('general.submit'),
                            aspect: 'primary',
                            'data-testid': 'submit',
                            async onClick() {
                                await formikProps.validateForm();
                                formikProps.handleSubmit();
                            },
                        },
                    ]}
                    title={
                        isCreationMode ? t('roles.createACustomRole') : t('roles.editACustomRole')
                    }
                    titleFontSize="1.5rem"
                    backgroundColor={colors.neutral.N50}
                    specificPadding="0"
                    centerTitle
                    scrollableBody
                >
                    <Container>
                        <GridRow style={{ marginBottom: '1rem' }}>
                            <GridCol smallScreen={10} defaultScreen={4}>
                                <PermissionAwareText
                                    marginTop="0"
                                    permissionsRequired={[permissionNeeded]}
                                    label={t('role.name')}
                                    name="label"
                                    type="text"
                                    placeholder={t('role.namePlaceholder')}
                                    data-testid="name"
                                    errorTestId="errorName"
                                    noMarginTop
                                    whiteBackground
                                    editable
                                />
                            </GridCol>
                            <GridCol smallScreen="auto" defaultScreen="auto">
                                <FormikOptionDropdown
                                    items={orderOptions}
                                    name="order"
                                    label={t('role.order')}
                                    initialNameDiplay={
                                        (role &&
                                            orderOptions.find(
                                                (orderOption) => orderOption.value === role.order,
                                            )?.label) ??
                                        t('role.order')
                                    }
                                    data-testid="order"
                                    errorTestId="errorOrder"
                                />
                            </GridCol>
                        </GridRow>
                        <GridRow style={{ marginBottom: '1rem' }}>
                            <GridCol smallScreen={12} defaultScreen={12}>
                                <PermissionAwareTextarea
                                    permissionsRequired={[permissionNeeded]}
                                    label={t('role.description')}
                                    name="description"
                                    placeholder={t('role.descriptionPlaceholder')}
                                    data-testid="description"
                                    errorTestId="errorDescription"
                                    noMarginTop
                                    whiteBackground
                                    editable
                                />
                            </GridCol>
                        </GridRow>
                    </Container>
                </Modal>
            )}
        </Formik>
    );
};

export default EditRoleModal;
