import axios from 'axios';

import type { Operation } from '../slices/operationSlice';
import type { Budget } from '../slices/budgetSlice';
import type { BudgetLineForDropdown, IBudgetLineFormData } from '../slices/budgetLineSlice';

const getBudgetLinesForCalculationDropdown = async (
    operationId: Operation['id'],
    budgetId: Budget['id'],
    initialModalContentValues: IBudgetLineFormData,
): Promise<BudgetLineForDropdown[]> => {
    const payload = {
        lineId: initialModalContentValues.id,
        parentId: initialModalContentValues.parentId,
    };
    try {
        const result = await axios.post(
            `/operations/${operationId}/budgets/${budgetId}/lines/calculation-dropdown`,
            payload,
        );
        const budgetLines: BudgetLineForDropdown[] = result.data.data;
        return budgetLines;
    } catch {
        throw new Error('errors.notFoundBudgetLinesForCalculationDropdown');
    }
};

export default getBudgetLinesForCalculationDropdown;
