import React from 'react';
import styled from 'styled-components';

import Icon from './Icon';
import PermissionAwareText from './PermissionAwareText';
import WarrantyFileUpload from './WarrantyFileUpload';
import GridCol from './GridCol';
import PermissionAwareDateInputField from './PermissionAwareDateInputField';

import { styles } from '../constants/styles';
import { colors } from '../constants/colors';

import type { Permission } from '../slices/authSlice';
import type { Warranty } from '../slices/marketSlice';

const AddLineSmall = styled.div`
    width: 1.125rem;
    height: 1.125rem;
    background-color: ${colors.green.G400};
    position: relative;
    margin: 1rem 0rem 0 0rem !important;
    border-radius: 0 ${styles.borderRadiusXSmall} ${styles.borderRadiusXSmall} 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type StyledWarrantyLineProps = {
    index: number;
};

const StyledWarrantyLine = styled.div<StyledWarrantyLineProps>`
    display: flex;
    ${({ index }) => index === 0 && 'margin: 1rem 0 0 0;'}
`;

type Props = {
    isEditMode?: boolean;
    permissionNeeded: Permission[];
    index: number;
    warranty: Warranty;
    isLastItem: boolean;
    handleWarrantyLineAdded: () => void;
    handleWarrantyLineRemoved: (index: number) => void;
    handleFileUploaded: (fileId: number) => void;
};

const WarrantyLine: React.FC<Props> = ({
    isEditMode,
    permissionNeeded,
    index,
    warranty,
    isLastItem,
    handleWarrantyLineAdded,
    handleWarrantyLineRemoved,
    handleFileUploaded,
}) => (
    <StyledWarrantyLine index={index}>
        <GridCol defaultScreen={2} gutterWidth="1rem">
            <PermissionAwareText
                marginTop="0"
                editable={isEditMode}
                permissionsRequired={permissionNeeded}
                name={`warranties[${index}].number`}
                type="text"
                data-testid="warrantyNumber"
                errorTestId="errorWarrantyNumber"
                noMarginTop
                whiteBackground
            />
        </GridCol>
        <GridCol defaultScreen={2} gutterWidth="1rem">
            <PermissionAwareText
                marginTop="0"
                editable={isEditMode}
                permissionsRequired={permissionNeeded}
                name={`warranties[${index}].reference`}
                type="text"
                data-testid="warrantyReference"
                errorTestId="errorWarrantyReference"
                noMarginTop
                whiteBackground
            />
        </GridCol>
        <GridCol defaultScreen={3} gutterWidth="1rem">
            <PermissionAwareText
                marginTop="0"
                editable={isEditMode}
                permissionsRequired={permissionNeeded}
                name={`warranties[${index}].issuer`}
                type="text"
                data-testid="warrantyIssuer"
                errorTestId="errorWarrantyIssuer"
                noMarginTop
                whiteBackground
            />
        </GridCol>
        <GridCol defaultScreen={2} gutterWidth="1rem">
            <PermissionAwareDateInputField
                editable={isEditMode}
                permissionsRequired={permissionNeeded}
                name={`warranties[${index}].date`}
                data-testid="warrantyDate"
                errorTestId="errorWarrantyDate"
                noMarginTop
                whiteBackground
            />
        </GridCol>
        <GridCol defaultScreen={2} gutterWidth="1rem">
            <PermissionAwareText
                marginTop="0"
                editable={isEditMode}
                permissionsRequired={permissionNeeded}
                numberType="amount"
                name={`warranties[${index}].amountTtc`}
                type="text"
                data-testid="warrantyAmountTtc"
                errorTestId="errorWarrantyAmountTtc"
                noMarginTop
                whiteBackground
            />
        </GridCol>
        <GridCol defaultScreen={1} gutterWidth="1rem">
            <WarrantyFileUpload
                handleFileUploaded={handleFileUploaded}
                handleWarrantyLineRemoved={handleWarrantyLineRemoved}
                fileId={warranty.fileId}
                isEditMode={isEditMode}
                index={index}
            />
        </GridCol>
        {isLastItem && isEditMode && (
            <AddLineSmall>
                <Icon name="Add" color={colors.other.white} onClick={handleWarrantyLineAdded} />
            </AddLineSmall>
        )}
    </StyledWarrantyLine>
);

export default WarrantyLine;
