import React, { useState, useRef, useEffect } from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import styled from 'styled-components';
import type { FieldInputProps } from 'formik';

import Icon from './Icon';
import FieldErrorMessage from './FieldErrorMessage';
import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import { joinThousands, separateThousands } from '../utils/formatters';

type ContainerProps = {
    label?: string;
    whiteBackground?: boolean;
    leftComponent?: JSX.Element;
    marginTop: string;
    marginBottom: string;
    type: string;
    leftComponentPadding?: string;
    inputHeight?: string;
    inputWidth?: string;
};

const Container = styled.div<ContainerProps>`
    .bp4-form-group {
        font-family: Rubik;
        font-size: 0.875rem;
        margin-top: ${({ marginTop }) => marginTop};
        margin-bottom: ${({ marginBottom }) => marginBottom};
        .bp4-label {
            text-align: left;
            color: ${colors.neutral.N300};
            line-height: 1.5rem;
        }
        .bp4-form-content {
            width: ${({ label }) => (label ? 'auto' : '100%')};
            .bp4-input-group {
                height: ${({ inputHeight }) => inputHeight ?? '2.5rem'};
                ${({ inputWidth }) => inputWidth ?? `width: ${inputWidth}`};
                .bp4-input {
                    background: ${colors.other.white};

                    height: 100%;
                    border: ${({ whiteBackground }) =>
                        whiteBackground ? `0.0938rem solid ${colors.neutral.N100}` : 'none'};
                    border-radius: ${styles.borderRadiusSmall};
                    box-shadow: none;
                    line-height: normal;

                    padding: ${({ leftComponent, type, leftComponentPadding }) =>
                        leftComponent
                            ? `0.625rem 1rem 0.625rem ${leftComponentPadding ?? '3rem'} !important`
                            : `0 ${type === 'number' ? 0 : 1} 0 1rem`};
                    color: ${colors.blue.B400};
                    ::placeholder {
                        color: ${colors.neutral.N200};
                        font-family: Rubik;
                    }
                    :focus {
                        outline: none;
                        border: 0.0938rem solid ${colors.green.G400};
                    }
                    :valid {
                        outline: none;
                    }
                    :-webkit-autofill {
                        -webkit-box-shadow: 0 0 0 3.125rem ${colors.other.white} inset;
                        -webkit-text-fill-color: ${colors.blue.B400};
                    }
                    &[disabled] {
                        background-color: ${colors.neutral.N100};
                    }
                }
                .bp4-input-action,
                .bp4-input-left-container {
                    margin: 0.5rem 1rem;
                }

                .bp4-input-action {
                    cursor: pointer;
                }
            }
        }
    }
`;

type Props = {
    autoFocus?: boolean;
    'data-testid'?: string;
    leftComponent?: JSX.Element;
    rightComponent?: JSX.Element;
    disabled?: boolean;
    label?: string;
    name?: string;
    placeholder?: string;
    type: string;
    eyeIcon?: boolean;
    whiteBackground?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    inputMode?:
        | 'text'
        | 'none'
        | 'tel'
        | 'url'
        | 'email'
        | 'numeric'
        | 'decimal'
        | 'search'
        | undefined;
    numberType?: 'amount' | 'percentage' | 'factor' | 'shift' | 'decimal';
    inputHeight?: string;
    inputWidth?: string;
    pattern?: HTMLInputElement['pattern'];
    formikField?: FieldInputProps<unknown>;
    errorTestId?: string;
    errorMessage?: string;
    value?: string;
    marginTop?: string;
    marginBottom?: string;
    textAlign?: 'left' | 'center' | 'right';
    autoFocusable?: boolean;
    leftComponentPadding?: string;
};
export type TextFieldProps = Props;

const TextField: React.FC<Props> = ({
    name,
    whiteBackground,
    label,
    type,
    rightComponent,
    leftComponent,
    eyeIcon,
    onChange,
    onBlur,
    onFocus,
    pattern,
    formikField,
    errorTestId,
    errorMessage,
    marginTop = '1.875rem',
    marginBottom = '1rem',
    textAlign,
    value,
    autoFocusable,
    numberType,
    leftComponentPadding,
    inputMode,
    inputHeight,
    inputWidth,
    ...rest
}) => {
    const [formType, setFormType] = useState<string>(type);
    let rightComponentTemp = rightComponent;
    const textField = useRef<HTMLInputElement>(null);
    const [hasFocus, setFocus] = useState<boolean>(false);

    const applySeparateThousands = (val: string) => {
        if (!hasFocus && (numberType === 'amount' || numberType === 'decimal')) {
            return separateThousands(val);
        } else {
            return val;
        }
    };

    let valueTemp = '';
    const formikFieldValue: string | undefined = formikField?.value as string;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary condition
    if (formikFieldValue !== undefined) {
        valueTemp = applySeparateThousands(formikFieldValue);
    }
    if (value !== undefined) {
        valueTemp = applySeparateThousands(value);
    }

    const isNumeral = Boolean(numberType);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let copyValue = event.target.value;
        // Remove spaces
        if (numberType === 'amount') {
            copyValue = String(copyValue).replace(/\s/g, '');
        }
        // Remove symbole
        if (copyValue.includes('€')) {
            copyValue = copyValue.replace('€', '');
        }
        // Replace all commas
        if (pattern?.includes(',') && copyValue.includes(',')) {
            const numberArray = copyValue.split(',');
            if (numberArray.length === 2) {
                copyValue = numberArray.join('.'); // Ex: If 9,00 becomes 9.00
            } else {
                copyValue = numberArray.join(''); // Ex: If 9,000,000.00 becomes 9000000000.00
            }
        }
        // Finished cleaning up the value before matching it with regex

        // Match with regex
        if (event.target.value && pattern) {
            const reg = new RegExp(pattern);

            const exec = copyValue.match(reg);
            // eslint-disable-next-line @typescript-eslint/prefer-optional-chain -- necessary
            if (exec && exec[0] !== null) {
                const number = exec[0];
                event.target.value = number;
            } else {
                event.target.value = valueTemp;
            }
        }

        if (onChange) {
            onChange(event);
        }
    };

    const errorExtraCSSProperties = {
        border: `0.0938rem solid ${colors.red.R300}`,
        boxShadow: `0px 0px 0px 0.219rem ${colors.red.R75}`,
    };

    if (!rightComponent && eyeIcon) {
        if (formType === 'password') {
            rightComponentTemp = (
                <Icon
                    onClick={() => setFormType('text')}
                    name="Visibility"
                    color={colors.neutral.N200}
                    data-testid="visibilityIcon"
                />
            );
        } else {
            rightComponentTemp = (
                <Icon
                    onClick={() => setFormType('password')}
                    name="VisibilityOff"
                    color={colors.neutral.N200}
                    data-testid="visibilityOffIcon"
                />
            );
        }
    }

    useEffect(() => {
        if (autoFocusable && textField.current) {
            textField.current.focus();
        }
    }, [textField, autoFocusable]);
    return (
        <Container
            label={label}
            whiteBackground={Boolean(whiteBackground)}
            leftComponent={leftComponent}
            marginTop={marginTop}
            marginBottom={marginBottom}
            type={type}
            leftComponentPadding={leftComponentPadding}
            inputHeight={inputHeight}
            inputWidth={inputWidth}
        >
            <FormGroup inline={false} label={label} labelFor={name}>
                <InputGroup
                    {...rest}
                    {...formikField}
                    inputRef={textField}
                    value={valueTemp}
                    id={rest['data-testid'] ?? name}
                    type={formType}
                    name={formikField ? formikField.name : name}
                    rightElement={rightComponentTemp}
                    leftElement={leftComponent}
                    onChange={handleChange}
                    style={{
                        ...(errorMessage ? errorExtraCSSProperties : {}),
                        ...(isNumeral ? { fontFamily: styles.numeralsFont } : {}),
                        ...(inputHeight ? { height: inputHeight } : {}),
                        ...(inputWidth ? { width: inputWidth } : {}),
                        ...(textAlign ? { textAlign } : {}),
                    }}
                    onBlur={(e) => {
                        setFocus(false);
                        if (numberType === 'amount' && valueTemp) {
                            e.target.value = separateThousands(valueTemp);
                        }
                        if (onBlur) onBlur(e);
                    }}
                    onFocus={(e) => {
                        setFocus(true);
                        if (numberType === 'amount' && valueTemp) {
                            e.target.value = joinThousands(valueTemp);
                        }
                        if (onFocus) onFocus(e);
                    }}
                    onKeyDown={(e) => onBlur && e.keyCode === 13 && textField.current?.blur()}
                />
                {errorMessage && (
                    <FieldErrorMessage data-testid={errorTestId}>{errorMessage}</FieldErrorMessage>
                )}
            </FormGroup>
        </Container>
    );
};

export default TextField;
