import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import MainLayout from '../../components/MainLayout';
import UserList from '../../components/UserList';
import GridCol from '../../components/GridCol';
import GridRow from '../../components/GridRow';
import SearchField from '../../components/SearchField';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';

import {
    getUsers,
    selectUsersByOrganizationIdAndPage,
    selectIsLoading,
    selectTotalUsers,
} from '../../slices/userSlice';
import type { QueryParams } from '../../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../../slices/common';
import { usePageNumber } from '../../hooks/usePageNumber';
import { useAppDispatch } from '../../store';

type Props = {
    headerProps: HeaderProps;
};

const UsersByOrganization: React.FunctionComponent<Props> = ({ headerProps }) => {
    const { organizationId } = useParams<{ organizationId: string }>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const pageNumber = usePageNumber();

    const defaultQueryParams = {
        organizationId,
        page: pageNumber,
        orderBy: '[profile.firstName, profile.lastName]',
        order: '[asc, asc]',
    };
    const [queryParams, setQueryParams] = useState<QueryParams>(defaultQueryParams);
    const hasRightToAddUsers = usePermissionsCheck([{ code: 'USERS_ADD', organizationId }]);

    const isLoading = useSelector(selectIsLoading);
    const users = useSelector(selectUsersByOrganizationIdAndPage(organizationId, queryParams.page));
    const totalUsers = useSelector(selectTotalUsers);

    const dispatch = useAppDispatch();

    useEffect(() => {
        void dispatch(getUsers(queryParams));
    }, [dispatch, queryParams]);

    const addUser = () => {
        navigate(`/organizations/${organizationId}/users/new`, {
            state: {
                organizationId,
                breadcrumbs: headerProps.breadcrumbs,
                goBackTo: `/organizations/${organizationId}/users`,
            },
        });
    };

    const handleSearch = (value: string) => {
        if (value === '') {
            setQueryParams({ ...defaultQueryParams, page: 1 });
        } else {
            setQueryParams({
                ...defaultQueryParams,
                page: 1,
                query: value,
                match: 'any',
            });
        }
    };

    return (
        <MainLayout
            header={
                <Header
                    {...headerProps}
                    buttons={
                        hasRightToAddUsers
                            ? [
                                  {
                                      'data-testid': 'addUserButton',
                                      text: t('user.addUser'),
                                      aspect: 'primary',
                                      onClick: addUser,
                                  },
                              ]
                            : []
                    }
                    showBackButton
                />
            }
            footer={
                totalUsers > 0 ? (
                    <Pagination
                        data-testid="pagination"
                        initialPage={queryParams.page}
                        currentPage={queryParams.page}
                        pageNeighbours={2}
                        nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                        nbItemsTotal={totalUsers}
                        onPageNumberClicked={(page: number) =>
                            setQueryParams({ ...queryParams, page })
                        }
                        pageUrl="users"
                    />
                ) : null
            }
            smallContentSidePadding
        >
            <GridRow>
                <GridCol smallScreen={10} defaultScreen={4}>
                    <SearchField
                        name="organizationResearch"
                        data-testid="usersSearch"
                        onSearch={handleSearch}
                    />
                </GridCol>
            </GridRow>
            <GridRow>
                <GridCol>
                    {isLoading ? <Loader /> : null}
                    {!isLoading && users.length > 0 && (
                        <UserList
                            users={users}
                            stateForNavigation={{
                                breadcrumbs: headerProps.breadcrumbs,
                                goBackTo: `${location.pathname}${location.search}`,
                            }}
                            userRoutePrefix={`/organizations/${organizationId}`}
                        />
                    )}
                    {!isLoading && users.length === 0 && (
                        <EmptyState
                            titleTranslationKey="site.noUsers"
                            imageName="User"
                            button={
                                hasRightToAddUsers ? (
                                    <Button
                                        onClick={addUser}
                                        text={t('user.addUser')}
                                        aspect="secondary"
                                        size="medium"
                                    />
                                ) : null
                            }
                        />
                    )}
                </GridCol>
            </GridRow>
        </MainLayout>
    );
};

export default UsersByOrganization;
