import type {
    EngagementInvoiceFormData,
    EngagementInvoiceFormDataKey,
} from '../slices/invoiceSlice';
import type { TvaCode } from '../slices/tvaCodeSlice';

import { roundAmount } from './calculationMethods';

export const getTotal = (arrayValues: number[]): number =>
    arrayValues.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);

export const getTvaRate = (tvaCodesById: Record<number, TvaCode>, tvaCodeId: number): number => {
    const tvaCode = tvaCodeId ? tvaCodesById[tvaCodeId] : null;
    const tvaRate = tvaCode ? tvaCode.value : 0;
    return tvaRate;
};

export const calculateAmountTtc = (amountHt: number, tvaRate: number) => {
    const amountTtc = Number(amountHt) * (1 + tvaRate);
    const roundedAmountTtc = roundAmount(amountTtc, 2);
    return roundedAmountTtc;
};

export const getTotalOfProperty = (
    arrayValues: EngagementInvoiceFormData[],
    property: EngagementInvoiceFormDataKey,
): number =>
    arrayValues.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue[property]),
        0,
    );

export const getTotalTtcOfProperty = (
    arrayValues: EngagementInvoiceFormData[],
    propertyHt: EngagementInvoiceFormDataKey,
    tvaCodesById: Record<number, TvaCode>,
): number =>
    arrayValues.reduce((accumulator, currentValue) => {
        const tvaCode = currentValue.tvaCodeId ? tvaCodesById[currentValue.tvaCodeId] : null;
        const tvaRate = tvaCode ? tvaCode.value : 0;
        return accumulator + calculateAmountTtc(Number(currentValue[propertyHt]), tvaRate);
    }, 0);
