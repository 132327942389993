import { useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';

import { MenuItem } from '@blueprintjs/core';
import type { ISelectProps as Props, IItemRendererProps } from '@blueprintjs/select';
import { Select as BPSelect } from '@blueprintjs/select';

import Button from '../Button';
import Badge from '../Badge';
import Text from '../Text';
import { SelectStyle } from './dropdownStyle';
import type { Option } from '../SelectField';
import { StyledLabel } from '../PermissionAwareDisplay';
import type { Label, LabelValue } from '../../slices/labelSlice';

import { colors } from '../../constants/colors';

export type StatusOption = Option<string>;

export type ISelectProps = {
    ['data-testid']?: string;
    items: Props<Label>['items'];
    itemRenderer?: Props<Label>['itemRenderer'];
    noResults?: Props<Label>['noResults'];
    onItemSelect: Props<Label>['onItemSelect'];
    handleChange?: (label: Label) => void;
    label?: string;
    name: string;
    popoverOpenedLabelValueId?: LabelValue['id'];
    isOptional?: boolean;
};

const StyledTextDisplay = styled.div`
    display: flex;
    flex-direction: row;
    > *:first-child {
        margin-bottom: 0;
        margin-right: 1rem;
    }
`;

// define type of data going through the select
const Select = BPSelect.ofType<Label>();

const LabelDropdown: React.FC<PropsWithChildren<ISelectProps>> = ({
    children,
    items,
    label,
    name,
    popoverOpenedLabelValueId,
    isOptional,
    ...props
}) => {
    const { t } = useTranslation();
    const [selectedLabel, setSelectedLabel] = useState<Label>(items[0]);
    const fieldLabel = isOptional ? `${label} ${t('general.optional')}` : label;

    const computeLabelDropdownNameDisplay = (dropdownLabel: Label) => {
        let labelName: React.ReactNode = (
            <StyledTextDisplay>
                <Text>{dropdownLabel.name}</Text>
            </StyledTextDisplay>
        );
        const links = dropdownLabel.labelValues.reduce((result: number, labelValue: LabelValue) => {
            labelValue.linkedLabelValues?.forEach((linkedValue) => {
                if (linkedValue.labelValueId === popoverOpenedLabelValueId) {
                    result += 1;
                }
            });
            return result;
        }, 0);
        if (links > 0) {
            labelName = (
                <StyledTextDisplay>
                    <Text>{dropdownLabel.name}</Text>
                    <Badge
                        text={links}
                        textColor={colors.blue.B400}
                        background={colors.neutral.N75}
                        style={{ margin: 'auto 0' }}
                    />
                </StyledTextDisplay>
            );
        }
        return labelName;
    };

    // Render items inside select
    const renderData = (dataLabel: Label, { handleClick }: IItemRendererProps) => {
        let text: React.ReactNode = (
            <StyledTextDisplay>
                <Text>{dataLabel.name}</Text>
            </StyledTextDisplay>
        );
        if (popoverOpenedLabelValueId) {
            text = computeLabelDropdownNameDisplay(dataLabel);
        }
        return (
            <MenuItem
                key={dataLabel.id}
                text={text}
                onClick={handleClick}
                tabIndex={0}
                data-testid="dropdown-item"
            />
        );
    };
    const testId = props['data-testid'] ?? 'test';

    return (
        <div className="dropdown" data-testid={`${testId}-dropdown`}>
            {label && (
                <StyledLabel color={colors.neutral.N300} style={{ marginBottom: '0' }}>
                    {fieldLabel}
                </StyledLabel>
            )}
            <SelectStyle />
            <Select
                items={items}
                itemRenderer={props.itemRenderer ?? renderData}
                onItemSelect={(itemLabel: Label) => {
                    setSelectedLabel(itemLabel);
                    props.onItemSelect(itemLabel);
                }}
                filterable={false}
                data-testid={`${testId}-select`}
            >
                <Button
                    aspect="dropdown"
                    iconName="ArrowDropDown"
                    iconSize="1.25rem"
                    text={computeLabelDropdownNameDisplay(selectedLabel)}
                    data-testid={`${testId}-dropdown-button`}
                />
            </Select>
        </div>
    );
};

export default LabelDropdown;
