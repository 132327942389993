import React, { Suspense, useEffect } from 'react';
import styled from 'styled-components';

import { BrowserRouter } from 'react-router-dom';

import { whoAmI } from './slices/authSlice';
import Routes from './Routes';
import Loader from './components/Loader';
import { ConnectAsBanner } from './components/ConnectAsBanner';
import { useAppDispatch } from './store';

const AppContainer = styled.div`
    height: 100%;
    display: grid;
    grid-template:
        'connect-as-banner' auto
        '.' 1fr;
`;

const App = () => {
    const dispatch = useAppDispatch();
    const refreshToken: string | null = localStorage.getItem('refresh_token');
    useEffect(() => {
        if (refreshToken) {
            void dispatch(whoAmI());
        }
    }, [dispatch, refreshToken]);

    return (
        <Suspense fallback={<Loader />}>
            <AppContainer>
                <ConnectAsBanner />
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </AppContainer>
        </Suspense>
    );
};

export default App;
