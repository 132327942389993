import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

// eslint-disable-next-line @typescript-eslint/no-floating-promises -- necessary for config
i18n
    // load translation using http -> see /public/locales
    .use(ChainedBackend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        lng: 'fr',
        debug: true,
        returnNull: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        backend: {
            backends: [LocalStorageBackend, HttpBackend],
            backendOptions: [
                {
                    expirationTime: 0,
                },
                {
                    loadPath: `/locales/{{lng}}/{{ns}}.json?${Date.now()}`,
                },
            ],
        },

        // if you don't want to use Suspense
        // react: {
        //     wait: true,
        //     useSuspense: false,
        // },
    });

export default i18n;
