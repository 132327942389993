import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// Data
import {
    getBudget,
    getBudgetEngagedAmounts,
    selectBudget,
    getBudgetSimulation,
    selectBudgetSimulation,
    selectError,
} from '../../slices/budgetSlice';
import { getOperation } from '../../slices/operationSlice';

import { getAllBudgetPriceHypothesis } from '../../slices/budgetPriceHypothesisSlice';

import { getTvaCodes } from '../../slices/tvaCodeSlice';

// Component
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import MainLayout from '../../components/MainLayout';
import BudgetGeneralInfo from '../../components/BudgetGeneralInfo';
import Loader from '../../components/Loader';
import BudgetLines from '../../components/BudgetLines';

// Hook
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import { getBudgetVersion } from '../../utils/getBudgetVersion';
import { useAppDispatch } from '../../store';

type Props = {
    headerProps?: Partial<HeaderProps>;
};

const Budget: React.FC<Props> = ({ headerProps, ...props }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { operationId, budgetId }: { operationId?: string; budgetId?: string } = useParams();
    const permissionNeeded = { code: 'BUDGETS_EDIT', operationId };
    const [isDeletePending, setIsDeletePending] = useState<boolean>(false);
    const navigate = useNavigate();
    const budgetLinesRef = useRef<HTMLDivElement>(null);

    // Selectors
    const hasRightToEdit = usePermissionsCheck([permissionNeeded]);
    const budget = useSelector(selectBudget(Number(budgetId)));
    const simulationStatus = useSelector(selectBudgetSimulation(Number(budgetId)));
    const simulationToStartOrInProgress =
        simulationStatus === 'not_started' || simulationStatus === 'in_progress';
    const serverError = useSelector(selectError);

    const location = useLocation();

    // State
    const [isEditMode, setIsEditMode] = useState<boolean>(Boolean(location.search === '?edit'));

    useEffect(() => {
        // initial fetch
        void dispatch(getOperation(Number(operationId)));
        void dispatch(getTvaCodes({ operationId: Number(operationId) }));

        if (budgetId) {
            void dispatch(getBudget({ id: Number(budgetId), operationId: Number(operationId) }));
            void dispatch(
                getAllBudgetPriceHypothesis({
                    budgetId: Number(budgetId),
                    operationId: Number(operationId),
                }),
            );
            void dispatch(
                getBudgetSimulation({
                    id: Number(budgetId),
                    operationId: Number(operationId),
                }),
            );
        }
    }, [dispatch, budgetId, operationId]);

    useEffect(() => {
        if (serverError === 'errors.noBudgetForThisOperation') {
            navigate(`/operations/${operationId}/budgets`);
        }
    }, [serverError, navigate, operationId]);

    useEffect(() => {
        if (budget && budget.status === 'approved') {
            void dispatch(
                getBudgetEngagedAmounts({
                    id: Number(budgetId),
                    operationId: Number(operationId),
                }),
            );
        }
    }, [budget, budgetId, dispatch, operationId]);

    // Refetch totals after simulation
    useEffect(() => {
        if (simulationStatus && simulationStatus === 'done') {
            void dispatch(
                getBudgetEngagedAmounts({
                    id: Number(budgetId),
                    operationId: Number(operationId),
                }),
            );
        }
    }, [dispatch, budgetId, operationId, simulationStatus]);

    useEffect(() => {
        if (isDeletePending && !budget) {
            navigate(`/operations/${operationId}/budgets`);
        }
    }, [isDeletePending, budget, operationId, navigate]);

    // Scroll
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error, @typescript-eslint/ban-ts-comment -- necessary
    // @ts-ignore
    const isIE11 = Boolean(window.msCrypto);
    const scrollToBudgetLines = useCallback(() => {
        let scrollableParent = document.querySelector('.scrollable-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (budgetLinesRef.current && scrollableParent) {
            // 100 is the header height
            scrollableParent.scrollTop = scrollableParent.scrollHeight;
        }
    }, [isIE11]);

    const scrollToTop = useCallback(() => {
        let scrollableParent = document.querySelector('.scrollable-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (scrollableParent) {
            scrollableParent.scrollTop = 0;
        }
    }, [isIE11]);

    useEffect(() => {
        if (isEditMode) {
            scrollToBudgetLines();
        } else {
            scrollToTop();
        }
    }, [isEditMode, scrollToTop, scrollToBudgetLines]);

    if (!budget) {
        return <Loader overlay />;
    } else {
        return (
            <MainLayout
                header={
                    <Header
                        {...headerProps}
                        title={budget.label === '' ? t('budget.defaultLabel') : budget.label}
                        showNavigation={!isEditMode}
                        badges={getBudgetVersion(budget)}
                        showBackButton
                    />
                }
                smallContentSidePadding
            >
                {simulationToStartOrInProgress && (
                    <Loader text={t('budget.simulationInProgress')} overlay />
                )}
                <BudgetGeneralInfo
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    permissionNeeded={permissionNeeded}
                    hasRightToEdit={hasRightToEdit}
                    budget={budget}
                    setIsDeletePending={setIsDeletePending}
                />
                <div ref={budgetLinesRef}>
                    <BudgetLines isEditMode={isEditMode} />
                </div>
            </MainLayout>
        );
    }
};

export default Budget;
