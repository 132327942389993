import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Modal from '../Modal';
import type { Engagement } from '../../slices/engagementSlice';
import {
    createEngagementSimulation,
    getEngagement,
    getEngagementSimulation,
    resetCreateEngagementSimulationFulfilled,
    resetEngagementSimulationStatus,
    selectCreateEngagementSimulationFulfilled,
    selectEngagementSimulationStatus,
} from '../../slices/engagementSlice';
import type { Operation } from '../../slices/operationSlice';
import { getOperation } from '../../slices/operationSlice';
import type { Market } from '../../slices/marketSlice';
import Loader from '../Loader';
import Text from '../Text';
import type { ButtonProps } from '../Button';
import { useAppDispatch } from '../../store';

let interval: number | NodeJS.Timer | null;

type Props = {
    operationId: Operation['id'];
    marketId: Market['id'];
    engagementId: Engagement['id'];
    onFinishSimulating: () => void;
    disableSimulationCreationDelay?: boolean;
};

const EcheancierEngagementSimulationModal: React.FC<Props> = ({
    operationId,
    marketId,
    engagementId,
    onFinishSimulating,
    disableSimulationCreationDelay = false,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const simulationStatus = useSelector(selectEngagementSimulationStatus(engagementId));
    const createEngagementSimulationFulfilled = useSelector(
        selectCreateEngagementSimulationFulfilled,
    );

    const DEFAULT_DELAY_BEFORE_SIMULATION_CREATION = 1000;
    const DEFAULT_INTERVAL_BETWEEN_SIMULATION_GET = 2000;

    const cancelInterval = () => {
        if (interval) {
            clearInterval(interval);
            interval = null;
        }
    };

    // Delaying simulation creation to set modal minimum display time
    // Set to 0 to avoid user waiting the default delay
    const delayBeforeSimulationCreation: number = disableSimulationCreationDelay
        ? 0
        : DEFAULT_DELAY_BEFORE_SIMULATION_CREATION;
    useEffect(() => {
        if (delayBeforeSimulationCreation > 0) {
            setTimeout(() => {
                void dispatch(
                    createEngagementSimulation({
                        operationId,
                        marketId,
                        id: engagementId,
                        task: { type: 'amounts' },
                    }),
                );
            }, delayBeforeSimulationCreation);
        } else {
            void dispatch(
                createEngagementSimulation({
                    operationId,
                    marketId,
                    id: engagementId,
                    task: { type: 'amounts' },
                }),
            );
        }
    }, [dispatch, engagementId, marketId, operationId, delayBeforeSimulationCreation]);

    useEffect(() => {
        if (createEngagementSimulationFulfilled) {
            dispatch(resetCreateEngagementSimulationFulfilled());
            // Checking for engagement simulation status
            void dispatch(
                getEngagementSimulation({
                    operationId: Number(operationId),
                    marketId: Number(marketId),
                    id: engagementId,
                }),
            );
        }
    }, [createEngagementSimulationFulfilled, dispatch, engagementId, marketId, operationId]);

    useEffect(() => {
        if (simulationStatus === 'done') {
            dispatch(resetEngagementSimulationStatus({ engagementId }));
            cancelInterval();
            // Fetching engagement because the simulation has updated engagement.echeancierLastCalculationAt
            void dispatch(
                getEngagement({
                    operationId: Number(operationId),
                    marketId: Number(marketId),
                    id: engagementId,
                }),
            );
            // Fetching operation because the simulation has updated operation.lastEngagementsUpdatedAt
            void dispatch(getOperation(Number(operationId)));
            onFinishSimulating();
        } else if (simulationStatus === 'errored') {
            cancelInterval();
        } else if (simulationStatus === null && interval) {
            cancelInterval();
        } else if (
            (simulationStatus === 'in_progress' || simulationStatus === 'not_started') &&
            !interval
        ) {
            // We create the interval only once
            interval = setInterval(() => {
                void dispatch(
                    getEngagementSimulation({
                        operationId: Number(operationId),
                        marketId: Number(marketId),
                        id: engagementId,
                    }),
                );
            }, DEFAULT_INTERVAL_BETWEEN_SIMULATION_GET);
        }
    }, [dispatch, engagementId, marketId, onFinishSimulating, operationId, simulationStatus]);

    // componentWillUnmount
    useEffect(
        () => () => {
            cancelInterval();
        },
        [],
    );

    const computeButtons = () => {
        const buttons: ButtonProps[] = [];
        if (simulationStatus === 'errored') {
            buttons.push({
                text: t('echeancierEngagement.updating.retry'),
                aspect: 'primary',
                'data-testid': 'retryUpdate',
                onClick() {
                    void dispatch(
                        createEngagementSimulation({
                            operationId,
                            marketId,
                            id: engagementId,
                            task: { type: 'amounts' },
                        }),
                    );
                },
            });
        }
        return buttons;
    };

    return (
        <Modal
            title={t('echeancierEngagement.updating.title')}
            iconColor="orange"
            size="small"
            buttons={computeButtons()}
            isCloseButtonShown={false}
            isOpen
        >
            <div data-testid="echeancierEngagementSimulationModal">
                {simulationStatus === 'errored' ? (
                    <Text data-testid="simulationErrorText">
                        {t('echeancierEngagement.updating.error')}
                    </Text>
                ) : (
                    <Loader />
                )}
            </div>
        </Modal>
    );
};

export default EcheancierEngagementSimulationModal;
