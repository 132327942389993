import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import Gantt from '../../components/Gantt';
import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import type { ButtonProps } from '../../components/Button';

// Hook
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';

// Data
import {
    selectBudget,
    getBudget,
    selectError as selectBudgetError,
    getBudgetSimulation,
    selectBudgetSimulation,
} from '../../slices/budgetSlice';
import { selectPlanning, getPlanning } from '../../slices/planningSlice';
import { selectOperationName, selectOperationInternalNumber } from '../../slices/operationSlice';
import { getBudgetVersion } from '../../utils/getBudgetVersion';
import { useAppDispatch } from '../../store';

const PlanningMainLayout = styled(MainLayout)`
    position: relative;
`;

type Props = {
    headerProps: Partial<HeaderProps>;
    type: 'budget' | 'markets';
};

const Planning: React.FC<Props> = ({ headerProps, type }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { operationId, budgetId }: { operationId?: string; budgetId?: string } = useParams();
    const budget = useSelector(selectBudget(Number(budgetId)));
    const operationName = useSelector(selectOperationName(Number(operationId)));
    const operationInternalNumber = useSelector(selectOperationInternalNumber(Number(operationId)));
    const planning = useSelector(
        selectPlanning,
        (before, after) => JSON.stringify(before) === JSON.stringify(after),
    );
    const [zoom, setZoom] = useState<'month' | 'week' | 'year'>('month');
    const budgetServerError = useSelector(selectBudgetError);
    const simulationStatus = useSelector(selectBudgetSimulation(Number(budgetId)));
    const simulationToStartOrInProgress =
        simulationStatus === 'not_started' || simulationStatus === 'in_progress';

    let permissionNeeded = [
        {
            code: 'BUDGETS_READ',
            operationId,
        },
    ];
    if (type === 'markets') {
        permissionNeeded = [
            {
                code: 'MARKETS_PLANNING_READ',
                operationId,
            },
        ];
    }
    const hasRightToReadBudgetsOrMarkets = usePermissionsCheck(permissionNeeded);
    useEffect(() => {
        if (operationId && !budgetId) {
            void dispatch(getPlanning({ operationId: Number(operationId) }));
        } else if (operationId && budgetId) {
            void dispatch(getBudget({ id: Number(budgetId), operationId: Number(operationId) }));
            void dispatch(
                getPlanning({ budgetId: Number(budgetId), operationId: Number(operationId) }),
            );
            void dispatch(
                getBudgetSimulation({
                    id: Number(budgetId),
                    operationId: Number(operationId),
                }),
            );
        }
    }, [dispatch, budgetId, operationId]);

    useEffect(() => {
        if (budgetServerError === 'errors.noBudgetForThisOperation') {
            navigate(`/operations/${operationId}/budgets`);
        }
    }, [budgetServerError, navigate, operationId]);

    if (budgetId && !budget) {
        return <Loader overlay />;
    }

    let title = '';
    let goBackTo;
    let titleTranslationKey = '';
    let titleTranslationKeyNoRight = '';

    switch (type) {
        case 'budget':
            if (budget) {
                title = budget.label === '' ? t('budget.defaultLabel') : budget.label;
            }
            goBackTo = operationId ? `/operations/${operationId}/budgets` : '/budgets';
            titleTranslationKey = 'budget.noLine';
            titleTranslationKeyNoRight = 'errors.nonIdenticalOperationId';
            break;
        case 'markets':
            title = `${t('sidebar.planning')} `;
            title += operationName ?? operationInternalNumber ?? '';
            goBackTo = '/operations';
            titleTranslationKey = 'errors.noMarketsTitle';
            titleTranslationKeyNoRight = 'errors.unauthorizedMarketsPlanning';
            break;
        default:
            break;
    }

    const computeButtonsOutOfProps = (): ButtonProps[] => [
        {
            text: t('planning.week'),
            'data-testid': 'cancelModifyMarket',
            aspect: 'secondary',
            toggle: true,
            isPressed: zoom === 'week',
            onClick() {
                setZoom('week');
            },
        },
        {
            text: t('planning.month'),
            'data-testid': 'submitMarket',
            aspect: 'secondary',
            toggle: true,
            isPressed: zoom === 'month',
            onClick() {
                setZoom('month');
            },
        },
        {
            text: t('planning.year'),
            'data-testid': 'submitMarket',
            aspect: 'secondary',
            toggle: true,
            isPressed: zoom === 'year',
            onClick() {
                setZoom('year');
            },
        },
    ];

    return (
        <PlanningMainLayout
            header={
                <Header
                    {...headerProps}
                    title={title}
                    goBackTo={goBackTo}
                    showBackButton={Boolean(goBackTo)}
                    buttons={computeButtonsOutOfProps()}
                    badges={getBudgetVersion(budget)}
                />
            }
            smallContentSidePadding
        >
            {simulationToStartOrInProgress && (
                <Loader text={t('budget.simulationInProgress')} overlay />
            )}
            {!hasRightToReadBudgetsOrMarkets ? (
                <EmptyState
                    data-testid="errorUnathorizedPlanning"
                    imageName="Budget"
                    titleTranslationKey={titleTranslationKeyNoRight}
                />
            ) : null}
            {hasRightToReadBudgetsOrMarkets && planning.data.length > 0 ? (
                <Gantt
                    data={planning}
                    planningType={type}
                    zoom={zoom}
                    isReadOnly={Boolean(type === 'budget' && budget?.status === 'approved')}
                />
            ) : (
                <EmptyState
                    imageName="Lines"
                    titleTranslationKey={titleTranslationKey}
                    data-testid="errorNoLinePlanning"
                />
            )}
        </PlanningMainLayout>
    );
};

export default Planning;
