import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError } from './common';
import { errorHandler } from './common';

export type GroupCodeSequence = {
    id: number;
    label: string;
    value: string;
};

export type GroupCodeSequenceForDropDown = Pick<GroupCodeSequence, 'id' | 'label'>;

interface IGroupCodeSequenceState {
    groupCodeSequenceById: Record<number, GroupCodeSequence>;
    groupCodeSequencesForDropdown: GroupCodeSequenceForDropDown[];
    loading: boolean;
    error: string | null;
}

export const initialState: IGroupCodeSequenceState = {
    groupCodeSequenceById: {},
    groupCodeSequencesForDropdown: [],
    loading: false,
    error: null,
};

export const getGroupCodeSequences = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    GroupCodeSequence[],
    // First argument to the payload creator
    void,
    {
        rejectValue: ServerError;
    }
>('GROUP_CODE_SEQUENCE/GETALL', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: GroupCodeSequence[] }>('/group-code-sequences');
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getGroupCodeSequence = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    GroupCodeSequence,
    // First argument to the payload creator
    { id: GroupCodeSequence['id'] },
    {
        rejectValue: ServerError;
    }
>('GROUP_CODE_SEQUENCE/GET', async ({ id }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: GroupCodeSequence }>(
            `/group-code-sequences/${id}`,
        );
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const slice = createSlice({
    name: 'groupCodeSequence',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getGroupCodeSequences.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getGroupCodeSequences.fulfilled, (state, { payload }) => {
            payload.forEach((item: GroupCodeSequence) => {
                state.groupCodeSequenceById[item.id] = item;
            });
            state.groupCodeSequencesForDropdown = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getGroupCodeSequences.rejected, errorHandler());
        // Get one
        builder.addCase(getGroupCodeSequence.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getGroupCodeSequence.fulfilled, (state, { payload }) => {
            state.groupCodeSequenceById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getGroupCodeSequence.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.groupCodeSequence.error;
export const selectGroupCodeSequences = (state: RootState) =>
    Object.values(state.groupCodeSequence.groupCodeSequenceById);
export const selectGroupCodeSequencesForDropdown = (
    state: RootState,
): GroupCodeSequenceForDropDown[] | [] =>
    Object.values(state.groupCodeSequence.groupCodeSequencesForDropdown);
export const selectGroupCodeSequence =
    (id: GroupCodeSequence['id'] = 1) =>
    (state: RootState): GroupCodeSequence | undefined =>
        state.groupCodeSequence.groupCodeSequenceById[id];

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
