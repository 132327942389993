import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import GridRow from './GridRow';
import Text from './Text';
import Icon from './Icon';
import {
    selectImportStatus,
    selectImportedMarkets,
    selectError,
    selectImportedEngagements,
} from '../slices/marketSlice';
import type { EngagementFromImport, MarketFromImport } from '../slices/common';

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 80vh;
`;

const MarketRow = styled(GridRow)<{ isWarning?: boolean }>`
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    width: 100%;
`;

const EngagementRow = styled(GridRow)<{ isWarning?: boolean }>`
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1rem;
    margin-top: rem;
    width: 100%;
`;

const SuccessMessage = styled(Text)`
    white-space: pre-line;
`;

const ImportMarketsResultModal: React.FC = () => {
    const { t } = useTranslation();

    const importStatus = useSelector(selectImportStatus);
    const importServerError = useSelector(selectError);
    const markets = useSelector(selectImportedMarkets);
    const engagements = useSelector(selectImportedEngagements);

    const getMarketLabel = (market: MarketFromImport) =>
        `${market.externalNumber} ${market.sliceCode ?? ''} ${market.label} `;

    const getEngagementLabel = (engagement: EngagementFromImport) =>
        `${engagement.marketExternalNumber ?? ''} ${engagement.marketSliceCode ?? ''} ${
            engagement.marketLabel ?? ''
        } ${engagement.groupCode ?? ''} ${engagement.externalNumber ?? ''} ${
            engagement.label ?? ''
        }`;

    const checkIfMarketHasEngagementsWithError = (market: MarketFromImport) =>
        engagements?.filter(
            (engagement) =>
                (engagement.marketId === market.id ||
                    engagement.marketExternalNumber === market.externalNumber) &&
                engagement.error,
        );

    const checkIfEngagementHasBeenCreated = (engagement: EngagementFromImport) =>
        engagement.createdAt === engagement.lastUpdatedAtForElk;

    const renderEngagementImportErrors = (marketExternalNumber: string | null) =>
        engagements?.map((engagement: EngagementFromImport) => {
            if (engagement.error) {
                if (marketExternalNumber === null && engagement.marketExternalNumber === null) {
                    return (
                        <EngagementRow>
                            <Text
                                type="H200"
                                style={{ margin: '0 0 0 0.5rem', lineHeight: '1.8rem' }}
                            >
                                {getEngagementLabel(engagement)}
                            </Text>
                        </EngagementRow>
                    );
                }
                if (marketExternalNumber === engagement.marketExternalNumber) {
                    return (
                        <EngagementRow>
                            <Text
                                type="H200"
                                style={{ margin: '0 0 0 0.5rem', lineHeight: '1.8rem' }}
                            >
                                {getEngagementLabel(engagement)}
                            </Text>
                            <Text
                                type="small"
                                style={{ paddingLeft: '1rem', lineHeight: '1.8rem' }}
                            >
                                {t(engagement.error)}
                            </Text>
                        </EngagementRow>
                    );
                }
            }
            return undefined;
        });

    const renderErrors = (serverError?: string | null) => (
        <>
            <Text type="H500" style={{ margin: '0 0 2rem 0' }}>
                {t('markets.import.error')}
            </Text>
            {serverError && (
                <Text>
                    <Trans i18nKey={serverError} />
                </Text>
            )}
            {markets?.map(
                (market: MarketFromImport) =>
                    (market.error || checkIfMarketHasEngagementsWithError(market)) && (
                        <GridRow
                            style={{ marginBottom: '0.5rem', marginLeft: '0rem', width: '100%' }}
                        >
                            <Icon name="Error" color={colors.red.R500} width="1.5rem" />
                            <Text
                                type="H400"
                                style={{ margin: '0 0 0 0.5rem', lineHeight: '1.6rem' }}
                            >
                                {t('invoices.marketLabel', {
                                    label: getMarketLabel(market),
                                })}
                                <span style={{ fontWeight: 400 }}>{t(market.error ?? '')}</span>
                            </Text>
                            {renderEngagementImportErrors(market.externalNumber)}
                        </GridRow>
                    ),
            )}
            {engagements &&
                engagements.filter(
                    (engagement) => engagement.error === 'errors.engagementMarchén°HasNoValue',
                ).length > 0 && (
                    <GridRow style={{ marginBottom: '0.5rem', marginLeft: '0rem', width: '100%' }}>
                        <Icon name="Error" color={colors.red.R500} width="1.5rem" />
                        <Text type="H400" style={{ margin: '0 0 0 0.5rem', lineHeight: '1.6rem' }}>
                            {t('errors.engagementsWithoutMarketNumber')}
                        </Text>

                        {renderEngagementImportErrors(null)}
                    </GridRow>
                )}
        </>
    );

    const renderImportedEngagementsWithWarningByMarket = (marketId: number | null) =>
        engagements?.map((engagement: EngagementFromImport) => {
            if (marketId === engagement.marketId) {
                if (engagement.warning) {
                    return (
                        <EngagementRow>
                            <Icon name="Warning" color={colors.yellow.Y500} width="1.5rem" />
                            <Text
                                type="H200"
                                style={{ margin: '0 0 0 0.5rem', lineHeight: '1.8rem' }}
                            >
                                {getEngagementLabel(engagement)}
                            </Text>
                            <Text
                                type="small"
                                style={{ paddingLeft: '1rem', lineHeight: '1.8rem' }}
                            >
                                {t(engagement.warning)}
                            </Text>
                        </EngagementRow>
                    );
                } else {
                    return (
                        <EngagementRow>
                            <Icon name="CheckCircle" color={colors.green.G400} width="1.5rem" />
                            <Text
                                type="H200"
                                style={{ margin: '0 0 0 0.5rem', lineHeight: '1.8rem' }}
                            >
                                {getEngagementLabel(engagement)}
                            </Text>
                            <Text
                                type="small"
                                style={{ paddingLeft: '1rem', lineHeight: '1.8rem' }}
                            >
                                {checkIfEngagementHasBeenCreated(engagement)
                                    ? t('engagement.import.created')
                                    : t('engagement.import.updated')}
                            </Text>
                        </EngagementRow>
                    );
                }
            }
            return undefined;
        });

    const renderImportedMarkets = () =>
        markets?.map((market: MarketFromImport) => (
            <React.Fragment key={market.id}>
                <MarketRow style={{ flexWrap: 'nowrap' }}>
                    {market.warning ? (
                        <Icon name="Warning" color={colors.yellow.Y500} width="1.5rem" />
                    ) : (
                        <Icon name="CheckCircle" color={colors.green.G400} width="1.5rem" />
                    )}
                    <Text type="H500" style={{ margin: '0 0 0 0.5rem', lineHeight: '1.8rem' }}>
                        {t('invoices.marketLabel', {
                            label: getMarketLabel(market),
                        })}
                        <span style={{ fontWeight: 400 }}>{t(market.warning ?? '')}</span>
                    </Text>
                </MarketRow>
                {renderImportedEngagementsWithWarningByMarket(market.id)}
            </React.Fragment>
        ));

    return (
        <ModalContentWrapper>
            {importStatus === 'success' && (
                <>
                    <SuccessMessage>
                        {t('markets.importMarkets.confirmationMessage')}
                    </SuccessMessage>
                    {renderImportedMarkets()}
                </>
            )}
            {importStatus === 'error' && renderErrors()}
            {importServerError && renderErrors(importServerError)}
        </ModalContentWrapper>
    );
};

export default ImportMarketsResultModal;
