import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import DashboardComponent from '../../components/Dashboard';
import EmptyState from '../../components/EmptyState';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import MainLayout from '../../components/MainLayout';
import { selectUserPermissions } from '../../slices/authSlice';

const Container = styled.div`
    padding-top: 24px;
    height: 100%;
`;

const EmptyStateContainer = styled.div`
    display: flex;
    height: 100%;
`;

type Props = {
    headerProps: HeaderProps;
};

const MultiOperationsDashboard: React.FC<Props> = ({ headerProps }) => {
    const userPermissions = useSelector(selectUserPermissions);
    const userHasAtLeatOneDashboardReadPermission =
        userPermissions &&
        userPermissions.length > 0 &&
        userPermissions.some((permission) => permission.code === 'DASHBOARD_READ');

    return (
        <MainLayout header={<Header {...headerProps} showTitle={false} />} smallContentSidePadding>
            <Container>
                {userHasAtLeatOneDashboardReadPermission ? (
                    <DashboardComponent />
                ) : (
                    <EmptyStateContainer>
                        <EmptyState titleTranslationKey="dashboard.cannotAccessMultiOperationsDashboard" />
                    </EmptyStateContainer>
                )}
            </Container>
        </MainLayout>
    );
};

export default MultiOperationsDashboard;
