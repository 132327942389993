import { useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import React, { useState, useEffect } from 'react';
import { useField } from 'formik';

import { MenuItem } from '@blueprintjs/core';
import type { ISelectProps as Props, IItemRendererProps } from '@blueprintjs/select';
import { Select as BPSelect } from '@blueprintjs/select';

import Button from '../Button';
import { SelectStyle } from './dropdownStyle';
import type { OrganizationForDropDown } from '../../slices/organizationSlice';
import { getOrganizationName } from '../../slices/organizationSlice';
import Icon from '../Icon';
import { StyledLabel } from '../PermissionAwareDisplay';
import FieldErrorMessage from '../FieldErrorMessage';

import { colors } from '../../constants/colors';

export type ISelectProps = {
    buttonTextTranslationKey?: string;
    'data-testid'?: string;
    errorTestId?: string;
    filterable?: Props<OrganizationForDropDown>['filterable'];
    handleChange?: (organization: OrganizationForDropDown) => void;
    initialOrganizationId?: OrganizationForDropDown['id'] | null;
    itemPredicate?: Props<OrganizationForDropDown>['itemPredicate'];
    itemRenderer?: Props<OrganizationForDropDown>['itemRenderer'];
    items: Props<OrganizationForDropDown>['items'];
    label?: string;
    name: string;
    noResults?: Props<OrganizationForDropDown>['noResults'];
    onItemSelect?: Props<OrganizationForDropDown>['onItemSelect'];
    shouldBeResettable?: boolean;
    isOptional?: boolean;
};

// define type of data going through the select
const Select = BPSelect.ofType<OrganizationForDropDown>();

const predicateOrganizationData = (
    query: string,
    item: OrganizationForDropDown,
    _index: number | undefined,
    exactMatch: boolean | undefined,
) => {
    const normalizedTitle = getOrganizationName(item)?.toString().toLowerCase() ?? '';
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
};

const OrganizationDropdown: React.FC<PropsWithChildren<ISelectProps>> = ({
    children,
    label,
    initialOrganizationId,
    name,
    errorTestId,
    shouldBeResettable,
    isOptional,
    ...props
}) => {
    const { t } = useTranslation();
    const formatOrganizationDisplayName = (organization: OrganizationForDropDown) =>
        organization.id
            ? `${organization.id} - ${getOrganizationName(organization)}`
            : getOrganizationName(organization);

    const initialOrganization = props.items.find(({ id }) => id === initialOrganizationId);
    let initialDisplayName: string | null = null;
    if (initialOrganization) {
        initialDisplayName = formatOrganizationDisplayName(initialOrganization);
    }
    const testId = props['data-testid'] ? props['data-testid'] : 'test';

    // Define state for handling field text change
    const placeholder = t('organization.select');
    const [nameDisplay, setNameDisplay] = useState<string>(initialDisplayName ?? placeholder);
    const [field, meta] = useField(name);
    const fieldLabel = isOptional ? `${label} ${t('general.optional')}` : label;

    // Handle change of nameDisplay to modify select button inside text
    useEffect(() => {
        setNameDisplay(initialDisplayName ?? placeholder);
    }, [initialDisplayName, placeholder]);

    // On select item click set nameDisplay to clicked item
    const handleValueChange = (organization: OrganizationForDropDown) => {
        setNameDisplay(formatOrganizationDisplayName(organization) ?? 'error');
        field.onChange(name)(organization.id);
        if (props.handleChange) {
            props.handleChange(organization);
        }
    };

    // Render items inside select
    const renderOrganizationData = (
        organization: OrganizationForDropDown,
        { handleClick }: IItemRendererProps,
    ) => (
        <MenuItem
            key={organization.id}
            text={formatOrganizationDisplayName(organization)}
            onClick={handleClick}
            tabIndex={0}
            data-testid={`${testId}-dropdown-item`}
        />
    );

    let items = [...props.items];

    if (shouldBeResettable) {
        items = [
            {
                id: '',
                estClient: null,
                denomination: t('general.noValue'),
                denominationUsuelle: '',
                denominationUsuelle1: '',
            },
            ...items,
        ];
    }

    return (
        <div
            className={`dropdown${meta.error && meta.touched ? '-error' : ''}`}
            data-testid={`${testId}-dropdown`}
        >
            {label && (
                <StyledLabel color={colors.neutral.N300} style={{ marginBottom: '0.3125rem' }}>
                    {fieldLabel}
                </StyledLabel>
            )}
            <SelectStyle />
            <Select
                items={items}
                itemRenderer={props.itemRenderer ?? renderOrganizationData}
                noResults={props.noResults ?? <MenuItem text={t('general.noResults')} disabled />}
                onItemSelect={props.onItemSelect ?? handleValueChange}
                itemPredicate={props.itemPredicate ?? predicateOrganizationData}
                filterable={props.filterable ?? false}
                data-testid={`${testId}-select`}
                inputProps={{
                    leftIcon: null,
                    leftElement: <Icon name="Search" style={{ width: '1.5rem' }} />,
                    placeholder: t('general.search'),
                }}
            >
                <Button
                    aspect="dropdown"
                    iconName="ArrowDropDown"
                    iconSize="1.25rem"
                    text={nameDisplay}
                    style={
                        meta.error && meta.touched
                            ? {}
                            : {
                                  marginBottom: '0.6rem',
                              }
                    }
                    data-testid={`${testId}-dropdown-button`}
                />
            </Select>
            {meta.error && meta.touched && (
                <FieldErrorMessage data-testid={errorTestId}>{meta.error}</FieldErrorMessage>
            )}
        </div>
    );
};

export default OrganizationDropdown;
