import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { IOrganization } from './organizationSlice';
import type { Operation } from './operationSlice';

import type { ServerError } from './common';
import { errorHandler } from './common';

export interface LabelValue {
    id: number;
    value: string;
    labelId: Label['id'];
    linkedLabelValues?: Array<{
        labelValueId: LabelValue['id'];
        linkId: number;
    }>;
}

export type LabelType = 'market' | 'operation' | 'engagement';

export interface Label {
    id: number;
    name: string;
    isPrevizLabel: boolean;
    isMarketLabel: boolean;
    isOperationLabel: boolean;
    isEngagementLabel: boolean;
    isMandatory: boolean;
    isImmutable: boolean;
    labelValues: LabelValue[];
}

interface ILabelFormData {
    id?: number;
    name: string;
    isPrevizLabel?: boolean;
    isMarketLabel?: boolean;
    isOperationLabel?: boolean;
    isEngagementLabel?: boolean;
    isMandatory?: boolean;
    isImmutable?: boolean;
}

interface ILabelValueFormData {
    id?: number;
    value: string;
    labelId: Label['id'];
}

interface ILabelState {
    labelsById: Record<number, Label>;
    previzLabels: Array<Label['id']>;
    labelsByOrganizationId: Record<string, Array<Label['id']> | null>;
    labelsByOperationId: Record<number, Array<Label['id']> | null>;
    loading: boolean;
    error: string | null;
}

export const initialState: ILabelState = {
    labelsById: {},
    previzLabels: [],
    labelsByOrganizationId: {},
    labelsByOperationId: {},
    loading: false,
    error: null,
};

export const getLabels = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: Label[]; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    // First argument to the payload creator
    { organizationId?: IOrganization['id'] | undefined; operationId?: Operation['id'] | undefined },
    {
        rejectValue: ServerError;
    }
>('LABEL/GETALL', async ({ organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = '/labels';
        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }
        const response = await axios.get(path);

        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }
        return { data: response.data.data, organizationId, operationId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getPrevizLabels = getLabels({});

export const getSpecificLabels = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    {
        data: {
            previzLabels: Label[];
            clientLabels: Label[];
            clientId?: IOrganization['id'];
            operationLabels: Label[];
        };
        operationId?: Operation['id'];
    },
    // First argument to the payload creator
    {
        type: LabelType;
        clientId?: IOrganization['id'] | undefined;
        operationId?: Operation['id'] | undefined;
    },
    {
        rejectValue: ServerError;
    }
>('SPECIFICLABEL/GETALL', async ({ type, clientId, operationId }, { rejectWithValue }) => {
    try {
        // path = /operation/labels?operationId=operationId&clientId=clientId&type=market
        let queryString = `type=${type}`;
        if (clientId) {
            queryString += `&clientId=${clientId}`;
        }
        if (operationId) {
            queryString += `&operationId=${operationId}`;
        }
        const path = `/operations/labels?${queryString}`;
        const response = await axios.get(path);

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }
        return { data: response.data.data, operationId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getLabel = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: Label; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    // First argument to the payload creator
    { labelId: Label['id']; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('LABEL/GET', async ({ labelId, organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = `/labels/${labelId}`;
        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }
        const response = await axios.get(path);
        if (response.data.data) {
            return { data: response.data.data, organizationId, operationId };
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});
export const createLabel = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: Label; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    // First argument to the payload creator
    { label: ILabelFormData; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('LABEL/POST', async ({ label, organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = `/labels`;
        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }
        const response = await axios.post(path, label);
        if (response.data.data) {
            return { data: response.data.data, organizationId, operationId };
        }
        return rejectWithValue({
            message: 'Problem when create new label',
            translationKey: 'errors.createLabel',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});
export const createLabelValue = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    LabelValue,
    // First argument to the payload creator
    {
        labelValue: ILabelValueFormData;
        organizationId?: IOrganization['id'];
        operationId?: Operation['id'];
    },
    {
        rejectValue: ServerError;
    }
>('LABELVALUE/POST', async ({ labelValue, organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = `/labels/${labelValue.labelId}/values`;
        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }

        const response = await axios.post(path, labelValue);
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when create new label value',
            translationKey: 'errors.createLabelValue',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const createLabelLink = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: Label[]; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    // First argument to the payload creator
    {
        link: { sourceLabelValueId: LabelValue['id']; targetLabelValueId: LabelValue['id'] };
        organizationId?: IOrganization['id'];
        operationId?: Operation['id'];
    },
    {
        rejectValue: ServerError;
    }
>('LABELLINK/POST', async ({ link, organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = `/labels/links`;
        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }

        const response = await axios.post(path, link);
        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }
        return { data: response.data.data, organizationId, operationId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const deleteLabelLink = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: Label[]; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    // First argument to the payload creator
    { linkId: number; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('LABELLINK/DELETE', async ({ linkId, organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = `/labels/links/${linkId}`;
        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }
        const response = await axios.delete(path);
        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }
        return { data: response.data.data, organizationId, operationId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const updateLabel = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Label,
    // First argument to the payload creator
    { label: ILabelFormData; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('LABEL/PUT', async ({ label, organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = `/labels/${label.id}`;
        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }
        const response = await axios.put(path, label);
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when update label',
            translationKey: 'errors.updateLabel',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});
export const updateLabelValue = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    LabelValue,
    // First argument to the payload creator
    {
        labelValue: ILabelValueFormData;
        organizationId?: IOrganization['id'];
        operationId?: Operation['id'];
    },
    {
        rejectValue: ServerError;
    }
>('LABELVALUE/PUT', async ({ labelValue, organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = `/labels/${labelValue.labelId}/values/${labelValue.id}`;
        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }
        const response = await axios.put(path, labelValue);
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when update label value',
            translationKey: 'errors.updateLabelValue',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});
export const deleteLabel = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { labelId: Label['id']; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    // First argument to the payload creator
    { labelId: Label['id']; organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('LABEL/DELETE', async ({ labelId, organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = `/labels/${labelId}`;
        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }
        const response = await axios.delete(path);
        if (response.status === 204) {
            return { labelId, organizationId, operationId };
        }
        return rejectWithValue({
            message: 'Problem when delete label',
            translationKey: 'errors.deleteLabel',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});
export const deleteLabelValue = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { labelValueId: LabelValue['id']; labelId: Label['id'] },
    // First argument to the payload creator
    {
        labelValueId: LabelValue['id'];
        labelId: Label['id'];
        organizationId?: IOrganization['id'];
        operationId?: Operation['id'];
    },
    {
        rejectValue: ServerError;
    }
>(
    'LABELVALUE/DELETE',
    async ({ labelValueId, labelId, organizationId, operationId }, { rejectWithValue }) => {
        try {
            let path = `/labels/${labelId}/values/${labelValueId}`;
            if (organizationId) {
                path = `/organizations/${organizationId}${path}`;
            } else if (operationId) {
                path = `/operations/${operationId}${path}`;
            }
            const response = await axios.delete(path);
            if (response.status === 204) {
                return { labelValueId, labelId };
            }
            return rejectWithValue({
                message: 'Problem when delete label value',
                translationKey: 'errors.deleteLabelValue',
            });
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    },
);

export const slice = createSlice({
    name: 'label',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all Labels
        builder.addCase(getLabels.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getLabels.fulfilled, (state, { payload }) => {
            payload.data.forEach((item: Label) => {
                state.labelsById[item.id] = item;
            });
            if (payload.organizationId) {
                state.labelsByOrganizationId[payload.organizationId] = payload.data.map(
                    (item: Label) => item.id,
                );
            } else if (payload.operationId) {
                state.labelsByOperationId[payload.operationId] = payload.data.map(
                    (item: Label) => item.id,
                );
            } else {
                state.previzLabels = payload.data.map((item: Label) => item.id);
            }
            state.loading = false;
        });
        builder.addCase(getLabels.rejected, errorHandler());
        // Get specific Labels
        builder.addCase(getSpecificLabels.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getSpecificLabels.fulfilled, (state, { payload }) => {
            payload.data.previzLabels.forEach((item: Label) => {
                state.labelsById[item.id] = item;
            });
            payload.data.clientLabels.forEach((item: Label) => {
                state.labelsById[item.id] = item;
            });
            payload.data.operationLabels.forEach((item: Label) => {
                state.labelsById[item.id] = item;
            });
            if (payload.data.clientId) {
                state.labelsByOrganizationId[payload.data.clientId] = payload.data.clientLabels.map(
                    (item: Label) => item.id,
                );
            }
            if (payload.operationId) {
                state.labelsByOperationId[payload.operationId] = payload.data.operationLabels.map(
                    (item: Label) => item.id,
                );
            }
            state.previzLabels = payload.data.previzLabels.map((item: Label) => item.id);
            state.loading = false;
        });
        builder.addCase(getSpecificLabels.rejected, errorHandler());
        // Get one Label
        builder.addCase(getLabel.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getLabel.fulfilled, (state, { payload }) => {
            state.labelsById[payload.data.id] = payload.data;
            if (payload.organizationId) {
                const labelList = state.labelsByOrganizationId[payload.organizationId] ?? [];
                state.labelsByOrganizationId[payload.organizationId] = [
                    ...labelList,
                    payload.data.id,
                ];
            } else if (payload.operationId) {
                const labelList = state.labelsByOperationId[payload.operationId] ?? [];
                state.labelsByOperationId[payload.operationId] = [...labelList, payload.data.id];
            } else {
                const labelList = state.previzLabels;
                state.previzLabels = [...labelList, payload.data.id];
            }
            state.loading = false;
        });
        builder.addCase(getLabel.rejected, errorHandler());
        // Create Label
        builder.addCase(createLabel.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createLabel.fulfilled, (state, { payload }) => {
            state.labelsById[payload.data.id] = { ...payload.data, labelValues: [] };
            if (payload.organizationId) {
                const labelList = state.labelsByOrganizationId[payload.organizationId] ?? [];
                state.labelsByOrganizationId[payload.organizationId] = [
                    ...labelList,
                    payload.data.id,
                ];
            } else if (payload.operationId) {
                const labelList = state.labelsByOperationId[payload.operationId] ?? [];
                state.labelsByOperationId[payload.operationId] = [...labelList, payload.data.id];
            } else {
                const labelList = state.previzLabels;
                state.previzLabels = [...labelList, payload.data.id];
            }
            state.loading = false;
            state.error = null;
        });
        builder.addCase(createLabel.rejected, errorHandler());
        // Create LabelValue
        builder.addCase(createLabelValue.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createLabelValue.fulfilled, (state, { payload }) => {
            state.labelsById[payload.labelId].labelValues = [
                ...state.labelsById[payload.labelId].labelValues,
                payload,
            ];
            state.loading = false;
            state.error = null;
        });
        builder.addCase(createLabelValue.rejected, errorHandler());
        // Create Label link
        builder.addCase(createLabelLink.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createLabelLink.fulfilled, (state, { payload }) => {
            payload.data.forEach((item: Label) => {
                state.labelsById[item.id] = item;
            });
            if (payload.organizationId) {
                state.labelsByOrganizationId[payload.organizationId] = payload.data.map(
                    (item: Label) => item.id,
                );
            } else if (payload.operationId) {
                state.labelsByOperationId[payload.operationId] = payload.data.map(
                    (item: Label) => item.id,
                );
            } else {
                state.previzLabels = payload.data.map((item: Label) => item.id);
            }
            state.loading = false;
        });
        builder.addCase(createLabelLink.rejected, errorHandler());
        // Delete Label link
        builder.addCase(deleteLabelLink.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteLabelLink.fulfilled, (state, { payload }) => {
            payload.data.forEach((item: Label) => {
                state.labelsById[item.id] = item;
            });
            if (payload.organizationId) {
                state.labelsByOrganizationId[payload.organizationId] = payload.data.map(
                    (item: Label) => item.id,
                );
            } else if (payload.operationId) {
                state.labelsByOperationId[payload.operationId] = payload.data.map(
                    (item: Label) => item.id,
                );
            } else {
                state.previzLabels = payload.data.map((item: Label) => item.id);
            }
            state.loading = false;
        });
        builder.addCase(deleteLabelLink.rejected, errorHandler());
        // Update Label
        builder.addCase(updateLabel.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateLabel.fulfilled, (state, { payload }) => {
            state.labelsById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(updateLabel.rejected, errorHandler());
        // Update Label Value
        builder.addCase(updateLabelValue.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateLabelValue.fulfilled, (state, { payload }) => {
            const labelValues: LabelValue[] = state.labelsById[payload.labelId].labelValues.map(
                (labelValue: LabelValue) => {
                    if (labelValue.id === payload.id) {
                        return payload;
                    }
                    return labelValue;
                },
            );
            state.labelsById[payload.labelId].labelValues = labelValues;
            state.loading = false;
        });
        builder.addCase(updateLabelValue.rejected, errorHandler());
        // Delete Label
        builder.addCase(deleteLabel.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteLabel.fulfilled, (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.labelsById[payload.labelId];
            if (payload.organizationId) {
                const labelList = state.labelsByOrganizationId[payload.organizationId] ?? [];
                state.labelsByOrganizationId[payload.organizationId] = labelList.filter(
                    (id: Label['id']) => id !== payload.labelId,
                );
            } else if (payload.operationId) {
                const labelList = state.labelsByOperationId[payload.operationId] ?? [];
                state.labelsByOperationId[payload.operationId] = labelList.filter(
                    (id: Label['id']) => id !== payload.labelId,
                );
            } else {
                const labelList = state.previzLabels;
                state.previzLabels = labelList.filter((id: Label['id']) => id !== payload.labelId);
            }
            state.loading = false;
        });
        builder.addCase(deleteLabel.rejected, errorHandler());
        // Delete Label Value
        builder.addCase(deleteLabelValue.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteLabelValue.fulfilled, (state, { payload }) => {
            const labelValues: LabelValue[] = state.labelsById[payload.labelId].labelValues.filter(
                (labelValue: LabelValue) => labelValue.id !== payload.labelValueId,
            );
            state.labelsById[payload.labelId].labelValues = labelValues;
            state.loading = false;
        });
        builder.addCase(deleteLabelValue.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.label.error;
export const selectLabels = (state: RootState) => Object.values(state.label.labelsById);
export const selectLabelsByType = (labelType: LabelType) => (state: RootState) =>
    Object.values(state.label.labelsById).filter((label: Label) => {
        if (labelType === 'market') {
            return label.isMarketLabel;
        } else if (labelType === 'operation') {
            return label.isOperationLabel;
        } else {
            return label.isEngagementLabel;
        }
    });

export const selectLabelsByTypeAndOrganizationIdAndOperationId =
    (
        labelType: LabelType,
        organizationId: IOrganization['id'] | undefined,
        operationId: Operation['id'] | undefined,
    ) =>
    (state: RootState) => {
        if (organizationId) {
            return state.label.labelsByOrganizationId[organizationId]?.reduce(
                (result: Label[], id: Label['id']) => {
                    if (labelType === 'market' && state.label.labelsById[id].isMarketLabel) {
                        result = [...result, state.label.labelsById[id]];
                    } else if (
                        labelType === 'operation' &&
                        state.label.labelsById[id].isOperationLabel
                    ) {
                        result = [...result, state.label.labelsById[id]];
                    } else if (
                        labelType === 'engagement' &&
                        state.label.labelsById[id].isEngagementLabel
                    ) {
                        result = [...result, state.label.labelsById[id]];
                    }
                    return result;
                },
                [],
            );
        } else if (operationId) {
            return state.label.labelsByOperationId[operationId]?.reduce(
                (result: Label[], id: Label['id']) => {
                    if (labelType === 'market' && state.label.labelsById[id].isMarketLabel) {
                        result = [...result, state.label.labelsById[id]];
                    } else if (
                        labelType === 'operation' &&
                        state.label.labelsById[id].isOperationLabel
                    ) {
                        result = [...result, state.label.labelsById[id]];
                    } else if (
                        labelType === 'engagement' &&
                        state.label.labelsById[id].isEngagementLabel
                    ) {
                        result = [...result, state.label.labelsById[id]];
                    }
                    return result;
                },
                [],
            );
        } else {
            return state.label.previzLabels.reduce((result: Label[], id: Label['id']) => {
                if (labelType === 'market' && state.label.labelsById[id].isMarketLabel) {
                    result = [...result, state.label.labelsById[id]];
                } else if (
                    labelType === 'operation' &&
                    state.label.labelsById[id].isOperationLabel
                ) {
                    result = [...result, state.label.labelsById[id]];
                } else if (
                    labelType === 'engagement' &&
                    state.label.labelsById[id].isEngagementLabel
                ) {
                    result = [...result, state.label.labelsById[id]];
                }
                return result;
            }, []);
        }
    };

export const selectAllLabelsBy =
    (
        labelType: LabelType,
        organizationId: IOrganization['id'] | undefined,
        operationId: Operation['id'] | undefined,
    ) =>
    (state: RootState) => {
        let clientLabels: Label[] = [];
        if (organizationId) {
            clientLabels =
                state.label.labelsByOrganizationId[organizationId]?.reduce(
                    (result: Label[], id: Label['id']) => {
                        if (labelType === 'market' && state.label.labelsById[id].isMarketLabel) {
                            result = [...result, state.label.labelsById[id]];
                        } else if (
                            labelType === 'operation' &&
                            state.label.labelsById[id].isOperationLabel
                        ) {
                            result = [...result, state.label.labelsById[id]];
                        } else if (
                            labelType === 'engagement' &&
                            state.label.labelsById[id].isEngagementLabel
                        ) {
                            result = [...result, state.label.labelsById[id]];
                        }
                        return result;
                    },
                    [],
                ) ?? [];
        }
        let operationLabels: Label[] = [];
        if (operationId) {
            operationLabels =
                state.label.labelsByOperationId[operationId]?.reduce(
                    (result: Label[], id: Label['id']) => {
                        if (labelType === 'market' && state.label.labelsById[id].isMarketLabel) {
                            result = [...result, state.label.labelsById[id]];
                        } else if (
                            labelType === 'operation' &&
                            state.label.labelsById[id].isOperationLabel
                        ) {
                            result = [...result, state.label.labelsById[id]];
                        } else if (
                            labelType === 'engagement' &&
                            state.label.labelsById[id].isEngagementLabel
                        ) {
                            result = [...result, state.label.labelsById[id]];
                        }
                        return result;
                    },
                    [],
                ) ?? [];
        }
        const previzLabels = state.label.previzLabels.reduce((result: Label[], id: Label['id']) => {
            if (labelType === 'market' && state.label.labelsById[id].isMarketLabel) {
                result = [...result, state.label.labelsById[id]];
            } else if (labelType === 'operation' && state.label.labelsById[id].isOperationLabel) {
                result = [...result, state.label.labelsById[id]];
            } else if (labelType === 'engagement' && state.label.labelsById[id].isEngagementLabel) {
                result = [...result, state.label.labelsById[id]];
            }
            return result;
        }, []);
        return [...previzLabels, ...clientLabels, ...operationLabels];
    };

export const selectLabel = (id: Label['id'] | undefined) => (state: RootState) =>
    id ? state.label.labelsById[id] : undefined;

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
