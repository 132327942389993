import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import Modal from './Modal';
import type { ButtonProps } from './Button';
import Button from './Button';
import type {
    IOrganization,
    OperationOrganizationPayload,
    AffectationTypeData,
} from '../slices/organizationSlice';
import {
    affectOrganizationToOperation,
    selectError,
    getOrganizationsForAffectation,
    resetOrganizationsByIdForAffectation,
    selectOrganization,
    selectOrganizationsForAffectation,
    selectGetOrganizationsForAffectationLoading,
    AFFECTATION_TYPES,
    selectAffectOrganizationToOperationFulfilled,
    updateOrganizationAffectationTypeToOperation,
    selectUpdateOperationOrganizationFulfulled,
    getAffectationTypeOptions,
} from '../slices/organizationSlice';
import type { Operation } from '../slices/operationSlice';
import { showFlag } from './Flag';
import OrganizationsList from './OrganizationsList';
import { colors } from '../constants/colors';
import type { QueryParams } from '../slices/common';
import { usePageNumber } from '../hooks/usePageNumber';
import GridRow from './GridRow';
import GridCol from './GridCol';
import OrganizationsSearch from './OrganizationsSearch';
import { getRenderCellMap as getOrganizationsRenderCellMap } from '../pages/organizations/Organizations';
import FormikOptionDropdown from './dropdown/FormikOptionDropdown';
import type { EmptyStateProps } from './EmptyState';
import type { TableHeaders } from './Table';
import type { RenderCellMapType } from './TableRow';
import Badge from './Badge';
import { useAppDispatch } from '../store';

type Props = {
    operationId: Operation['id'];
    onModalClosed: () => void;
    operationOrganization?: OperationOrganizationPayload;
};

const Container = styled.div`
    width: 100%;
`;

const checkIfOrganizationHasAffectedOperation = (
    operationsAffected: IOrganization['operationsAffected'],
    operationId: Operation['id'],
): boolean => {
    if (operationsAffected) {
        return operationsAffected.some((operationAffected) => operationAffected.id === operationId);
    }
    return false;
};

const AffectOrganizationToOperationModal: React.FunctionComponent<Props> = ({
    operationId,
    onModalClosed,
    operationOrganization,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const pageNumber = usePageNumber();

    const isCreation = useMemo(() => Boolean(!operationOrganization), [operationOrganization]);

    const [info, setInfo] = useState<string | null>(
        isCreation ? t('errors.youMustSearchOrganizationBySiret') : null,
    );
    const [searchError, setSearchError] = useState<string | null>(null);
    const [queryParams, setQueryParams] = useState<QueryParams>({ page: pageNumber });
    const [emptyState, setEmptyState] = useState<EmptyStateProps | null>(null);
    const [isAffectOrganizationToOperationPending, setIsAffectOrganizationToOperationPending] =
        useState<boolean>(false);
    const [isSearchReset, setIsSearchReset] = useState<boolean>(false);
    const [isQueryCorrespondingToValidSiret, setIsQueryCorrespondingToValidSiret] =
        useState<boolean>(false);

    const [tableHeaders, setTableHeaders] = useState<TableHeaders>({
        denomination: t('organization.list.headers.denomination'),
        siret: t('organization.list.headers.siret'),
        codePostal: t('organization.list.headers.codePostal'),
        affectationType: '',
    });

    const organizationsRenderCellMap = getOrganizationsRenderCellMap(t);

    type RenderCellMapOrganizations = RenderCellMapType<IOrganization | Partial<IOrganization>>;

    const [renderCellMap, setRenderCellMap] = useState<RenderCellMapOrganizations>({
        denomination: organizationsRenderCellMap.denomination,
        siret: organizationsRenderCellMap.siret,
        codePostal: organizationsRenderCellMap.codePostal,
        affectationType: useCallback(
            (organization: IOrganization | Partial<IOrganization>) => {
                if (
                    organization.operationsAffected &&
                    checkIfOrganizationHasAffectedOperation(
                        organization.operationsAffected,
                        operationId,
                    )
                ) {
                    const badgeText = t('operationOrganization.organizationAlreadyAffected');
                    return (
                        <Badge
                            text={badgeText}
                            textColor={colors.blue.B400}
                            background={colors.neutral.N100}
                            data-testid="alreadyAffected"
                        />
                    );
                }
                return '';
            },
            [operationId, t],
        ),
    });

    const serverError = useSelector(selectError);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<
        IOrganization['id'] | null
    >(operationOrganization?.organizationId ?? null);
    const getOrganizationsForAffectationLoading = useSelector(
        selectGetOrganizationsForAffectationLoading,
    );
    const organizations = useSelector(selectOrganizationsForAffectation, shallowEqual);
    const selectedOrganization = useSelector(selectOrganization(selectedOrganizationId));
    const affectOrganizationToOperationFulfilled = useSelector(
        selectAffectOrganizationToOperationFulfilled,
    );
    const updateOperationOrganizationFulfulled = useSelector(
        selectUpdateOperationOrganizationFulfulled,
    );

    let serverErrorMessage = '';
    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    const emptyStateImage: { imageName: EmptyStateProps['imageName'] } = useMemo(
        () => ({
            imageName: 'SearchDocument',
        }),
        [],
    );

    useEffect(() => {
        if (operationOrganization) {
            setRenderCellMap({
                denomination: organizationsRenderCellMap.denomination,
                siret: organizationsRenderCellMap.siret,
                affectationType() {
                    return (
                        <FormikOptionDropdown
                            items={affectationTypeOptions}
                            name="affectationType"
                            initialNameDiplay={
                                affectationTypeOptions.filter(
                                    (type) =>
                                        type.value.toLocaleLowerCase() ===
                                        operationOrganization.affectationType,
                                )[0].label
                            }
                            data-testid="affectationType"
                            errorTestId="errorCalculatedFrom"
                            style={{ margin: '0', width: '100%' }}
                        />
                    );
                },
                // eslint-disable-next-line react/jsx-no-useless-fragment -- needed
                none: () => <></>,
            });
            setTableHeaders({
                denomination: t('organization.list.headers.denomination'),
                siret: t('organization.list.headers.siret'),
                affectationType: t('operationOrganization.affectation'),
                none: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on each changes
    }, [operationOrganization, t]);

    useEffect(() => {
        if (isCreation) {
            setSelectedOrganizationId(null);
            if (isQueryCorrespondingToValidSiret) {
                void dispatch(getOrganizationsForAffectation(queryParams));
            } else {
                setInfo(t('errors.youMustSearchOrganizationBySiret'));
                setEmptyState(null);
                dispatch(resetOrganizationsByIdForAffectation());
            }
        }
    }, [dispatch, isQueryCorrespondingToValidSiret, queryParams, t, isCreation]);

    useEffect(() => {
        if (serverError) {
            showFlag('error', t('errors.error'), serverErrorMessage);
            setIsAffectOrganizationToOperationPending(false);
        } else {
            if (
                isAffectOrganizationToOperationPending &&
                (affectOrganizationToOperationFulfilled || updateOperationOrganizationFulfulled)
            ) {
                setIsAffectOrganizationToOperationPending(false);
                onModalClosed();
                const successMessage = isCreation
                    ? t('operation.organizationAffectedWithSuccess')
                    : t('operation.affectationUpdatedWithSuccess');
                showFlag('success', successMessage, '');
            }
        }
    }, [
        affectOrganizationToOperationFulfilled,
        isAffectOrganizationToOperationPending,
        onModalClosed,
        serverError,
        serverErrorMessage,
        t,
        isCreation,
        updateOperationOrganizationFulfulled,
    ]);

    useEffect(() => {
        if (getOrganizationsForAffectationLoading) {
            setEmptyState(null);
            setInfo(null);
        } else if (
            isQueryCorrespondingToValidSiret &&
            !searchError &&
            !isSearchReset &&
            !emptyState &&
            organizations.length === 0
        ) {
            setEmptyState({
                ...emptyStateImage,
                'data-testid': 'errorNoOrganizations',
                titleTranslationKey: 'errors.noOrganizationsTitle',
                button: (
                    <Button
                        onClick={() => {
                            navigate(`/organizations`, {
                                state: {
                                    organizationSiretToAdd: queryParams.query,
                                },
                            });
                        }}
                        text={t('organization.addOrganization')}
                        aspect="primary"
                        size="medium"
                        data-testid="addOrganization"
                    />
                ),
            });
        }
    }, [
        emptyState,
        emptyStateImage,
        getOrganizationsForAffectationLoading,
        navigate,
        isQueryCorrespondingToValidSiret,
        isSearchReset,
        organizations.length,
        queryParams.query,
        searchError,
        t,
    ]);

    useEffect(() => {
        if (selectedOrganizationId && isCreation) {
            setTableHeaders({
                ...tableHeaders,
                affectationType: t('operationOrganization.affectation'),
            });
            setRenderCellMap({
                ...renderCellMap,
                affectationType() {
                    return (
                        <FormikOptionDropdown
                            items={affectationTypeOptions}
                            name="affectationType"
                            initialNameDiplay={t('operationOrganization.affectationType')}
                            data-testid="affectationType"
                            errorTestId="errorCalculatedFrom"
                            style={{ margin: '0', width: '100%' }}
                        />
                    );
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [selectedOrganizationId]);

    const computeButtonsOutOfProps = (
        formikProps: FormikProps<AffectationTypeData>,
    ): ButtonProps[] => [
        {
            text: t('general.cancel'),
            'data-testid': 'cancel',
            aspect: 'secondary',
            onClick() {
                onModalClosed();
            },
        },
        {
            text: t('general.submit'),
            'data-testid': 'submit',
            aspect: 'primary',
            disabled:
                selectedOrganizationId === null ||
                formikProps.values.affectationType === null ||
                operationOrganization?.affectationType === formikProps.values.affectationType ||
                isAffectOrganizationToOperationPending,
            isLoading: isAffectOrganizationToOperationPending,
            async onClick() {
                await formikProps.validateForm();
                formikProps.handleSubmit();
            },
        },
    ];

    const onOrganizationClick = (organization: IOrganization | Partial<IOrganization>) => {
        if (organization.id) {
            setSelectedOrganizationId(organization.id);
        }
    };

    const organizationOperationSchema = Yup.object().shape({
        affectationType: Yup.string()
            .required(t('errors.required'))
            .oneOf([...AFFECTATION_TYPES]),
    });

    const affectationTypeOptions = getAffectationTypeOptions(t);

    const handleSubmit = (organizationId: IOrganization['id'], values: AffectationTypeData) => {
        if (isCreation && values.affectationType) {
            void dispatch(
                affectOrganizationToOperation({
                    operationId,
                    organizationId,
                    cantBeDeleted: false,
                    affectationType: values.affectationType,
                }),
            );
        } else if (operationOrganization && values.affectationType) {
            void dispatch(
                updateOrganizationAffectationTypeToOperation({
                    id: operationOrganization.id,
                    operationId,
                    organizationId,
                    cantBeDeleted: operationOrganization.cantBeDeleted,
                    affectationType: values.affectationType,
                }),
            );
        }
    };

    return (
        <Formik
            initialValues={{
                affectationType: operationOrganization?.affectationType ?? null,
            }}
            validationSchema={organizationOperationSchema}
            onSubmit={(values: AffectationTypeData, actions) => {
                if (selectedOrganizationId && values.affectationType) {
                    handleSubmit(selectedOrganizationId, values);
                    setIsAffectOrganizationToOperationPending(true);
                    actions.setSubmitting(true);
                }
            }}
            enableReinitialize
        >
            {(formikProps: FormikProps<AffectationTypeData>) => (
                <Modal
                    buttons={computeButtonsOutOfProps(formikProps)}
                    onCloseButtonPressed={onModalClosed}
                    title={
                        isCreation
                            ? t('operationOrganization.affectAnOrganization')
                            : t('operationOrganization.updateAffectation')
                    }
                    size="full"
                    titleFontSize="1.5rem"
                    backgroundColor={colors.neutral.N50}
                    specificHeight="90vh"
                    specificPadding="0"
                    isOpen
                    centerTitle
                    scrollableBody
                >
                    <Container>
                        <GridRow style={{ marginBottom: isCreation ? '1rem' : '6rem' }}>
                            {isCreation && (
                                <GridCol smallScreen={10} defaultScreen={4}>
                                    <OrganizationsSearch
                                        setQueryParams={setQueryParams}
                                        setIsSearchReset={setIsSearchReset}
                                        setSearchError={setSearchError}
                                        setIsQueryCorrespondingToValidSiret={
                                            setIsQueryCorrespondingToValidSiret
                                        }
                                    />
                                </GridCol>
                            )}
                        </GridRow>

                        <OrganizationsList
                            organizations={
                                selectedOrganization ? [selectedOrganization] : organizations
                            }
                            shouldShowList={!isCreation || Boolean(organizations.length > 0)}
                            onOrganizationClick={onOrganizationClick}
                            columnSizes={isCreation ? '5fr 2fr 2fr 3fr' : '5fr 3fr 3fr 2fr'}
                            tableRowHeight="3.125rem"
                            tableHeaders={tableHeaders}
                            renderCellMap={renderCellMap}
                            checkIfDisabled={(organization) =>
                                checkIfOrganizationHasAffectedOperation(
                                    organization.operationsAffected,
                                    operationId,
                                )
                            }
                            checkIfSelected={
                                isCreation
                                    ? (organization) => organization.id === selectedOrganizationId
                                    : undefined
                            }
                            viewType="table"
                            emptyState={emptyState}
                            info={info}
                            searchError={searchError}
                            isLoading={getOrganizationsForAffectationLoading}
                            searchingOrganizationsForAffectation
                        />
                    </Container>
                </Modal>
            )}
        </Formik>
    );
};

export default AffectOrganizationToOperationModal;
