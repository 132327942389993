import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';

const Container = styled.div<Props>`
    @media print {
        transition: none;
        box-shadow: none;
        page-break-inside: avoid;
    }
    background: ${colors.other.transparent};
    ${({ disabledBorderRadius }) =>
        !disabledBorderRadius && `border-radius: ${styles.borderRadiusMedium};`};
    transition: box-shadow 0.3s;
    border: none;
    ${(props) => {
        let boxShadow = 'box-shadow: none;';
        if (props.elevation === 'default') {
            boxShadow = `box-shadow: 0 0.0625rem 0.25rem ${colors.neutral.N75};
            :hover {
                box-shadow: 0 1.25rem 1.25rem ${colors.neutral.N75};
            }`;
        } else if (props.elevation === 'withoutHover') {
            boxShadow = `box-shadow: 0 0.0625rem 0.25rem ${colors.neutral.N75};`;
        }
        return boxShadow;
    }};
`;

type Props = {
    elevation: 'none' | 'default' | 'withoutHover';
    style?: React.CSSProperties;
    isFixedColumns?: boolean;
    disabledBorderRadius?: boolean;
    tableWidth?: number;
    backgroundColor?: string;
};

const CustomElevation: React.FC<PropsWithChildren<Props>> = ({
    elevation,
    children,
    isFixedColumns,
    tableWidth,
    disabledBorderRadius = false,
    backgroundColor,
    ...rest
}) => (
    <Container
        {...rest}
        disabledBorderRadius={disabledBorderRadius}
        elevation={elevation}
        backgroundColor={backgroundColor}
    >
        {children}
    </Container>
);

export default CustomElevation;
