import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import type { FormikProps, FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { format } from 'date-fns';
import * as Yup from 'yup';
import type { IDialogProps, TabId } from '@blueprintjs/core';

// Style
import { styles } from '../constants/styles';
import { colors } from '../constants/colors';

// Components
import EngagementGeneralTab from './tabs/EngagementGeneralTab';
import SpendingRythmTab, {
    initialValuesSpendingRythm,
    getSpendingRythmValidationSchema,
    validateSpendingSchemaTab,
} from './tabs/SpendingRythmTab';
import PriceVariationTab, {
    duplicatePriceVariationSettingsFromAPreviousEngagement,
    setInitialPriceVariationSettings,
    getYupSchema,
    validatePriceVariationsTab,
} from './tabs/PriceVariationTab';
import LineModal from './LineModal';
import type { IEngagementFormData, Engagement } from '../slices/engagementSlice';
import {
    updateEngagement,
    createEngagement,
    selectLastEngagementOrder,
    resetUpdateEngagementFulfilled,
    selectLastEngagementNotificationDate,
    selectValidatedBudgetLines,
    selectFirstEngagement,
} from '../slices/engagementSlice';
import { selectMarket } from '../slices/marketSlice';
import Badge from './Badge';
import Text from './Text';
import { showFlag } from './Flag';

import { validateFormFields } from '../utils/formValidation';
import { validateMandatoryLabels } from '../utils/mandatoryLabelsValidation';

import { convertDaysToCompleteWeeks, convertWeeksToCompleteDays } from '../utils/date';

import { selectAllLabelsBy } from '../slices/labelSlice';
import { selectOperation } from '../slices/operationSlice';
import { selectGroupCodeSequence } from '../slices/groupCodeSequenceSlice';
import type { BudgetLineForDropdown } from '../slices/budgetLineSlice';
import type { Permission } from '../slices/authSlice';
import type { PriceVariationSettings } from '../slices/common';
import { useAppDispatch } from '../store';

type Props = {
    engagement?: Engagement;
    permissionsNeeded: Permission[];
    hasRightToEdit: boolean;
    isOpen: boolean;
    onClose: (event?: React.SyntheticEvent<HTMLElement> | undefined) => void;
    setIsPending: (isPending: boolean) => void;
    setIsEngagementModalOpen: (isOpen: boolean) => void;
    setOpenEcheancierModalAfterEngagementSaving: (
        openEcheancierModalAfterEngagementSaving: boolean,
    ) => void;
    isPending?: boolean;
    setIsEcheancierEngagementOpen?: (isEcheancierOpen: boolean) => void;
    setOpenEcheancierModalOnLastCreatedEngagement?: (
        openEcheancierModalOnLastCreatedEngagement: boolean,
    ) => void;
    isEngagementCreationModal: boolean;
};

const TabItem = styled.div`
    display: flex;
    align-items: center;
`;

const ModalStyle = styled.div`
    .bp4-tab-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bp4-heading {
        display: flex;
        justify-content: center;
        font-size: 1.5rem !important;
        line-height: inherit !important;
    }
    .bp4-tab-indicator {
        display: none;
    }
    .bp4-tab {
        display: flex;
        align-items: center;
    }
    .bp4-radio {
        margin-right: 1.2rem;
    }
    .bp4-control input:checked ~ .bp4-control-indicator {
        background-color: ${colors.green.G400};
    }
    .bp4-control {
        margin-bottom: 1rem;
    }
    .bp4-popover {
        display: contents;
        border: 0.0625rem solid ${colors.neutral.N75};
        .bp4-popover-content {
            border-radius: ${styles.borderRadiusMedium};
        }
    }
    h4.bp4-heading {
        padding: 0;
        color: ${colors.blue.B400};
        font-family: Gilroy;
        font-weight: 800;
        font-size: 0.875rem;
        line-height: 1rem;
    }
    .bp4-dialog-body {
        padding: 0 1.5rem;
        margin: 1rem 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 80%;
    }
    .bp4-dialog {
        box-shadow: 0 1.25rem 1.25rem ${colors.blue.B400}33;
        border-radius: 0.625rem;
        min-width: 90%;
    }
    .bp4-dialog-header {
        box-shadow: none;
        border-radius: 0.625rem;
        padding: 1.5rem 1rem 0 1.5rem;
        > .bp4-button {
            :hover {
                background-color: ${colors.other.white};
            }

            > .bp4-icon {
                color: ${colors.blue.B400};
            }
        }
    }
    .bp4-overlay-backdrop {
        background-color: ${colors.blue.B400}4D;
    }
    .bp4-dialog-footer {
        margin: 0 1.5rem;
    }
    .bp4-dialog-close-button {
        margin-top: -1rem;
        padding: 0.3125rem 0rem 0.3125rem 0.875rem;
    }
    .bp4-tab-indicator-wrapper {
        display: none;
    }
    .bp4-tab-panel {
        width: 100%;
    }
`;

const EngagementModal: React.FC<Props & IDialogProps> = ({
    engagement,
    hasRightToEdit,
    permissionsNeeded,
    setIsPending,
    setIsEngagementModalOpen,
    setIsEcheancierEngagementOpen,
    setOpenEcheancierModalAfterEngagementSaving,
    isPending,
    setOpenEcheancierModalOnLastCreatedEngagement,
    isEngagementCreationModal,
    ...rest
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { operationId, marketId }: { operationId?: string; marketId?: string } = useParams();
    const operation = useSelector(selectOperation(Number(operationId)));
    const firstEngagement = useSelector(selectFirstEngagement);
    const lastEngagementOrder = useSelector(selectLastEngagementOrder);
    const market = useSelector(selectMarket(Number(marketId)));
    const groupCodeSequence = useSelector(selectGroupCodeSequence(operation?.groupCodeSequenceId));
    const [selectedTab, setSelectedTab] = useState<TabId>('general');
    const labels = useSelector(
        selectAllLabelsBy('engagement', operation?.clientId, Number(operationId)),
    );
    const lastEngagementNotificationDate = useSelector(selectLastEngagementNotificationDate);
    const defaultNotificationDate = lastEngagementNotificationDate ?? '';
    const budgetLinesForDropdown: BudgetLineForDropdown[] = useSelector(selectValidatedBudgetLines);

    const [priceVariationSettingsOfFirstEngagement, setPriceVariationSettingsOfFirstEngagement] =
        useState<PriceVariationSettings>(null);

    useEffect(() => {
        void dispatch(resetUpdateEngagementFulfilled());
    }, [dispatch]);

    useEffect(() => {
        setPriceVariationSettingsOfFirstEngagement(
            duplicatePriceVariationSettingsFromAPreviousEngagement(firstEngagement),
        );
    }, [firstEngagement]);

    let groupCodeSchema;
    if (market?.isJointGroupMarket) {
        // If engagement is in a joined group market then group code is mandatory
        // We can't use Yup conditions (.when) because the condition is on a value that is outside of Formik
        groupCodeSchema = Yup.object({
            groupCode: Yup.mixed()
                .oneOf(
                    groupCodeSequence !== undefined ? groupCodeSequence.value.split(',') : [],
                    t('errors.required'),
                )
                .required(t('errors.required')),
        });
    } else {
        groupCodeSchema = Yup.object({
            groupCode: Yup.string().nullable(),
        });
    }

    const validBudgetLineIds = budgetLinesForDropdown.map(({ id }) => id);

    const otherFieldsSchema = Yup.object({
        label: Yup.string().required(t('errors.required')).min(1),
        engagementType: Yup.mixed()
            .oneOf(['market', 'amendment', 'service_order', 'pursuit_descision', 'other'])
            .required(t('errors.required')),
        order: Yup.number().required(t('errors.required')),
        externalNumber: Yup.string()
            .nullable()
            .max(50, t('errors.tooLong', { number: 50 })),
        notificationDate: Yup.string().required(t('errors.required')),
        durationInWeeks: Yup.number()
            .nullable()
            .required(t('errors.required'))
            .moreThan(0, t('errors.required')),
        amountHt: Yup.number().nullable().required(t('errors.required')),
        budgetLineId: Yup.number()
            .required(t('errors.required'))
            .oneOf(validBudgetLineIds, t('errors.required')),
        holdbackPercentage: Yup.number()
            .nullable()
            .when('isWarranty', {
                is: true,
                then: Yup.number().required(t('errors.required')),
            }),
        advancePaymentPercentage: Yup.number()
            .nullable()
            .when('isAdvancePayment', {
                is: true,
                then: Yup.number().required(t('errors.required')),
            }),
        advancePaymentAmountTtc: Yup.number()
            .nullable()
            .when('isAdvancePayment', {
                is: true,
                then: Yup.number().required(t('errors.required')),
            }),
        inferiorRecoveryPercentage: Yup.number()
            .nullable()
            .when('isAdvancePayment', {
                is: true,
                then: Yup.number()
                    .lessThan(
                        Yup.ref('superiorRecoveryPercentage'),
                        t('errors.inferiorShouldBeInferiorToSuperior'),
                    )
                    .required(t('errors.required')),
            }),
        superiorRecoveryPercentage: Yup.number()
            .nullable()
            .when('isAdvancePayment', {
                is: true,
                then: Yup.number()
                    .moreThan(
                        Yup.ref('inferiorRecoveryPercentage'),
                        t('errors.superiorShouldBeSuperiorToInferior'),
                    )
                    .required(t('errors.required')),
            }),
        downPaymentPercentage: Yup.number()
            .nullable()
            .when('isDownPayment', {
                is: true,
                then: Yup.number().required(t('errors.required')),
            }),
        downPaymentAmountTtc: Yup.number()
            .nullable()
            .when('isDownPayment', {
                is: true,
                then: Yup.number().required(t('errors.required')),
            }),
        priceVariationSettings: getYupSchema(t),
        spendingRythm: getSpendingRythmValidationSchema(t),
    });

    const validationSchema = otherFieldsSchema.concat(groupCodeSchema);

    const defaultAmountTtc = 0;
    const defaultDownPaymentPercentage = 0;
    const defaultDownPaymentAmountTtc = 0;
    const defaultAdvancePaymentPercentage = 5;
    const defaultAdvancePaymentTtc = 0;
    const defaultiInferiorRecoveryPercentage = 65;
    const defaultSuperiorRecoveryPercentage = 80;
    const defaultHoldbackPercentage = 5;
    const defaultFixedSpendingFactor = 100;
    const defaultVariableSpendingFactor = 0;

    const initialValues = {
        id: engagement?.id,
        label: engagement?.label ?? '',
        marketId: Number(marketId),
        spendingRythmId: engagement?.spendingRythmId ? engagement.spendingRythmId : null,
        budgetLineId: engagement?.budgetLineId ?? 0,
        comment: engagement?.comment,
        engagementType: engagement?.engagementType ?? 'market',
        amountHt: engagement?.amountHt
            ? Number(engagement.amountHt).toFixed(2)
            : Number(0).toFixed(2),
        taxes: engagement?.taxes ?? 0,
        amountTtc: engagement?.amountTtc ?? defaultAmountTtc.toFixed(2),
        notificationDate: engagement?.notificationDate ?? defaultNotificationDate,
        durationInWeeks: engagement?.durationInDays
            ? convertDaysToCompleteWeeks(engagement.durationInDays)
            : 1,
        depreciationPeriod: engagement?.depreciationPeriod ? engagement.depreciationPeriod : null,
        createdAt: engagement?.createdAt,
        order: engagement?.order ?? Number(lastEngagementOrder) + 1,
        tvaCodeId: engagement?.tvaCodeId ?? 1,
        downPaymentPercentage: engagement
            ? Number(engagement.downPaymentPercentage * 100).toFixed(2)
            : defaultDownPaymentPercentage.toFixed(2),
        downPaymentAmountTtc: engagement?.downPaymentAmountTtc
            ? Number(engagement.downPaymentAmountTtc).toFixed(2)
            : defaultDownPaymentAmountTtc.toFixed(2),
        advancePaymentPercentage: engagement
            ? Number(engagement.advancePaymentPercentage * 100).toFixed(2)
            : defaultAdvancePaymentPercentage.toFixed(2),
        advancePaymentAmountTtc: engagement?.advancePaymentAmountTtc
            ? Number(engagement.advancePaymentAmountTtc).toFixed(2)
            : defaultAdvancePaymentTtc.toFixed(2),
        inferiorRecoveryPercentage:
            // eslint-disable-next-line @typescript-eslint/prefer-optional-chain -- optional chain not working here
            engagement && engagement.inferiorRecoveryPercentage !== null
                ? (Number(engagement.inferiorRecoveryPercentage) * 100).toFixed(2)
                : defaultiInferiorRecoveryPercentage.toFixed(2),
        superiorRecoveryPercentage:
            engagement && engagement.superiorRecoveryPercentage > 0
                ? (Number(engagement.superiorRecoveryPercentage) * 100).toFixed(2)
                : defaultSuperiorRecoveryPercentage.toFixed(2),
        holdbackPercentage: market?.holdbackDefaultPercentage
            ? (Number(market.holdbackDefaultPercentage) * 100).toFixed(2)
            : defaultHoldbackPercentage.toFixed(2),
        warrantiesTotalTtc: engagement?.warrantiesTotalTtc,
        labelValues: engagement?.labelValues ?? [],

        isWarranty: engagement ? engagement.warrantiesTotalTtc !== null : market?.hasHoldback,
        isAdvancePayment: engagement
            ? engagement.advancePaymentAmountTtc !== null
            : market?.hasAdvancePayment,
        isDownPayment: engagement
            ? engagement.downPaymentAmountTtc !== null
            : market?.hasDownPayment,
        priceVariationSettings: engagement?.id
            ? setInitialPriceVariationSettings(engagement)
            : priceVariationSettingsOfFirstEngagement,
        spendingRythm: engagement?.spendingRythm
            ? {
                  ...engagement.spendingRythm,
                  fixedSpendingFactor:
                      engagement.spendingRythm.fixedSpendingFactor !== null
                          ? (Number(engagement.spendingRythm.fixedSpendingFactor) * 100).toFixed(2)
                          : defaultFixedSpendingFactor.toFixed(2),
                  variableSpendingFactor:
                      engagement.spendingRythm.variableSpendingFactor !== null
                          ? (Number(engagement.spendingRythm.variableSpendingFactor) * 100).toFixed(
                                2,
                            )
                          : defaultVariableSpendingFactor.toFixed(2),
              }
            : initialValuesSpendingRythm,
        externalNumber: engagement?.externalNumber,
        groupCode: engagement?.groupCode ?? null,
        echeancierMode: engagement?.echeancierMode,
    };

    const renderBadge = (indicator: string, isActive: boolean) => (
        <Badge
            text={indicator}
            textSize="1rem"
            textColor={colors.other.white}
            background={isActive ? colors.green.G400 : colors.neutral.N400}
            style={{
                marginRight: '0.5rem',
                width: '2rem',
                height: '2rem',
                borderRadius: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        />
    );

    const validateWarranty = async (props: FormikProps<IEngagementFormData>) => {
        const formFieldToValidate = [];
        if (props.values.isWarranty) {
            formFieldToValidate.push('holdbackPercentage');
        }
        if (props.values.isAdvancePayment) {
            formFieldToValidate.push(
                'advancePaymentPercentage',
                'advancePaymentAmountTtc',
                'inferiorRecoveryPercentage',
                'superiorRecoveryPercentage',
            );
        }
        if (props.values.isDownPayment) {
            formFieldToValidate.push('downPaymentPercentage', 'downPaymentAmountTtc');
        }
        const isWarrantyTabValid = await validateFormFields(formFieldToValidate, props);
        return isWarrantyTabValid;
    };

    // Logic to valid field from tab
    const validateGeneralTab = async (props: FormikProps<IEngagementFormData>) => {
        const formFieldsAreValid: boolean = await validateFormFields(
            [
                'label',
                'engagementType',
                'order',
                'notificationDate',
                'durationInWeeks',
                'amountHt',
                'budgetLineId',
                'labelValues',
                'externalNumber',
                'groupCode',
            ],
            props,
        );
        const mandatoryLabelsAreValid: boolean = validateMandatoryLabels(
            props.values.labelValues,
            labels,
        );
        const isGeneralTabValid: boolean = formFieldsAreValid && mandatoryLabelsAreValid;

        const isWarrantyValuesValid: boolean = await validateWarranty(props);

        return isGeneralTabValid && isWarrantyValuesValid;
    };

    const validateAndSave = async (
        props: FormikProps<IEngagementFormData>,
        goToEcheancier?: boolean,
    ) => {
        let allTabsAreValid = true;
        if (props.dirty) {
            const isGeneralTabValid = await validateGeneralTab(props);
            const isSpendingRythmTabValid = await validateSpendingSchemaTab(props);
            const isPriceVariationTabValid = await validatePriceVariationsTab<IEngagementFormData>(
                props,
            );
            allTabsAreValid =
                isGeneralTabValid && isSpendingRythmTabValid && isPriceVariationTabValid;
            if (allTabsAreValid) {
                props.handleSubmit();
            }
        } else {
            props.resetForm();
            showFlag('warning', t('general.warning'), t('general.nothingToUpdate'));
            setIsEngagementModalOpen(false);
            setSelectedTab('general');
            if (goToEcheancier && setIsEcheancierEngagementOpen) {
                setIsEcheancierEngagementOpen(true);
            }
        }
        return allTabsAreValid;
    };

    const handleSubmit = (
        values: IEngagementFormData,
        actions: FormikHelpers<IEngagementFormData>,
    ) => {
        setIsPending(true);
        if (!values.isWarranty) {
            delete values.holdbackPercentage;
            delete values.warrantiesTotalTtc;
        }
        if (!values.isAdvancePayment) {
            delete values.advancePaymentAmountTtc;
            delete values.advancePaymentPercentage;
            delete values.superiorRecoveryPercentage;
            delete values.inferiorRecoveryPercentage;
        }
        if (!values.isDownPayment) {
            delete values.downPaymentAmountTtc;
            delete values.downPaymentPercentage;
        }
        const notificationDate = format(new Date(values.notificationDate), 'yyyy-MM-dd');
        const payload = {
            ...values,
            notificationDate,
            amountHt: Number(values.amountHt),
            budgetLineId: Number(values.budgetLineId),
            order: Number(values.order),
            durationInDays: convertWeeksToCompleteDays(Number(values.durationInWeeks)),
            holdbackPercentage:
                values.holdbackPercentage !== null ? Number(values.holdbackPercentage) / 100 : null,
            warrantiesTotalTtc: Number(values.warrantiesTotalTtc),
            advancePaymentPercentage:
                values.advancePaymentPercentage !== null
                    ? Number(values.advancePaymentPercentage) / 100
                    : null,
            advancePaymentAmountTtc:
                values.advancePaymentAmountTtc !== null
                    ? Number(values.advancePaymentAmountTtc)
                    : null,
            downPaymentPercentage:
                values.downPaymentPercentage !== null
                    ? Number(values.downPaymentPercentage) / 100
                    : null,
            downPaymentAmountTtc:
                values.downPaymentAmountTtc !== null ? Number(values.downPaymentAmountTtc) : null,
            superiorRecoveryPercentage:
                values.superiorRecoveryPercentage !== null
                    ? Number(values.superiorRecoveryPercentage) / 100
                    : null,
            inferiorRecoveryPercentage:
                values.inferiorRecoveryPercentage !== null
                    ? Number(values.inferiorRecoveryPercentage) / 100
                    : null,
            spendingRythm: {
                ...values.spendingRythm,
                fixedSpendingEndShift: Number(values.spendingRythm?.fixedSpendingEndShift),
                fixedSpendingFactor: Number(values.spendingRythm?.fixedSpendingFactor) / 100,
                fixedSpendingStartShift: Number(values.spendingRythm?.fixedSpendingStartShift),
                variableSpendingEndShift: Number(values.spendingRythm?.variableSpendingEndShift),
                variableSpendingFactor: Number(values.spendingRythm?.variableSpendingFactor) / 100,
                variableSpendingStartShift: Number(
                    values.spendingRythm?.variableSpendingStartShift,
                ),
                variableSpendingSchemaId:
                    Number(values.spendingRythm?.variableSpendingFactor) === 0 ||
                    values.spendingRythm?.variableSpendingSchemaId === 0
                        ? null
                        : values.spendingRythm?.variableSpendingSchemaId,
            },
            matched: true,
        };
        delete payload.isWarranty;
        delete payload.isAdvancePayment;
        delete payload.isDownPayment;
        delete payload.durationInWeeks;

        if (payload.priceVariationSettings?.isSimulated) {
            delete payload.priceVariationSettings.isSimulated;
            delete payload.priceVariationSettings.actualisationsAmountHt;
            delete payload.priceVariationSettings.revisionsAmountHt;
            payload.isSimulated = true;
            if (
                payload.priceVariationSettings.hasActualisation &&
                !payload.priceVariationSettings.hasRevision
            ) {
                payload.priceVariationSettings = {
                    ...payload.priceVariationSettings,
                    a: null,
                    b: null,
                };
            } else if (
                !payload.priceVariationSettings.hasActualisation &&
                payload.priceVariationSettings.hasRevision
            ) {
                payload.priceVariationSettings = {
                    ...payload.priceVariationSettings,
                    actualisationMonth: null,
                };
            } else if (
                !payload.priceVariationSettings.hasActualisation &&
                !payload.priceVariationSettings.hasRevision
            ) {
                payload.priceVariationSettings = null;
            }

            if (payload.priceVariationSettings?.indexSettings) {
                const formattedIndexSettings = payload.priceVariationSettings.indexSettings.map(
                    (index) => ({ ...index, factor: Number(index.factor) }),
                );
                payload.priceVariationSettings = {
                    ...payload.priceVariationSettings,
                    indexSettings: formattedIndexSettings,
                };
            }
        } else if (payload.priceVariationSettings) {
            payload.isSimulated = false;
            payload.actualisationsAmountHt = Number(
                payload.priceVariationSettings.actualisationsAmountHt,
            );
            payload.revisionsAmountHt = Number(payload.priceVariationSettings.revisionsAmountHt);
            payload.priceVariationSettings = null;
        }

        void dispatch(
            !engagement
                ? createEngagement({
                      engagement: payload,
                      operationId: Number(operationId),
                      marketId: Number(marketId),
                  })
                : updateEngagement({
                      engagement: payload,
                      operationId: Number(operationId),
                      marketId: Number(marketId),
                  }),
        );
        actions.setSubmitting(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {(props: FormikProps<IEngagementFormData>) => (
                <ModalStyle data-testid="engagementModal">
                    <LineModal<IEngagementFormData>
                        {...rest}
                        title={
                            props.values.id
                                ? t('engagement.modifyEngagement')
                                : t('engagement.addEngagement')
                        }
                        backgroundColor={colors.neutral.N50}
                        tabs={[
                            {
                                tabId: 'general',
                                Component: EngagementGeneralTab,
                                async validateSaveAndGoToEcheancier() {
                                    await validateAndSave(props, true);
                                    setOpenEcheancierModalAfterEngagementSaving(true);
                                    if (
                                        isEngagementCreationModal &&
                                        setOpenEcheancierModalOnLastCreatedEngagement
                                    ) {
                                        setOpenEcheancierModalOnLastCreatedEngagement(true);
                                    }
                                },
                                async validateSaveAndCloseModal() {
                                    await validateAndSave(props);
                                },
                                async validateAndGoToNextTab() {
                                    const isGeneralTabValid = await validateGeneralTab(props);
                                    if (isGeneralTabValid) {
                                        setSelectedTab('spendingRythm');
                                    }
                                },
                                title: (
                                    <TabItem
                                        data-testid="generalTabTitle"
                                        onClick={() => setSelectedTab('general')}
                                    >
                                        {renderBadge('1', selectedTab === 'general')}
                                        <Text style={{ margin: '0' }}>
                                            {t('engagement.general')}
                                        </Text>
                                    </TabItem>
                                ),
                                entityId: engagement?.id,
                            },
                            {
                                tabId: 'spendingRythm',
                                Component: SpendingRythmTab,
                                async validateSaveAndGoToEcheancier() {
                                    await validateAndSave(props, true);
                                    setOpenEcheancierModalAfterEngagementSaving(true);
                                    if (
                                        isEngagementCreationModal &&
                                        setOpenEcheancierModalOnLastCreatedEngagement
                                    ) {
                                        setOpenEcheancierModalOnLastCreatedEngagement(true);
                                    }
                                },
                                async validateSaveAndCloseModal() {
                                    await validateAndSave(props);
                                },
                                async validateAndGoToPreviousTab() {
                                    const isPriceVariationsTabValid =
                                        await validatePriceVariationsTab(props);
                                    if (isPriceVariationsTabValid) {
                                        setSelectedTab('general');
                                    }
                                },
                                async validateAndGoToNextTab() {
                                    const isGeneralTabValid = await validateGeneralTab(props);
                                    const isSpendingRythmTabValid = await validateSpendingSchemaTab(
                                        props,
                                    );

                                    if (isGeneralTabValid && isSpendingRythmTabValid) {
                                        setSelectedTab('priceVariation');
                                    }
                                },
                                title: (
                                    <TabItem
                                        data-testid="spendingRythmTab"
                                        onClick={async () => {
                                            const isGeneralTabValid = await validateGeneralTab(
                                                props,
                                            );
                                            const isPriceVariationTabValid =
                                                await validatePriceVariationsTab(props);

                                            if (isGeneralTabValid && isPriceVariationTabValid) {
                                                setSelectedTab('spendingRythm');
                                            }
                                        }}
                                    >
                                        {renderBadge('2', selectedTab === 'spendingRythm')}
                                        <Text style={{ margin: '0' }}>
                                            {t('engagement.spendingRythm')}
                                        </Text>
                                    </TabItem>
                                ),
                                entityId: engagement?.id,
                            },
                            {
                                tabId: 'priceVariation',
                                Component: PriceVariationTab,
                                async validateSaveAndGoToEcheancier() {
                                    await validateAndSave(props, true);
                                    setOpenEcheancierModalAfterEngagementSaving(true);
                                    if (
                                        isEngagementCreationModal &&
                                        setOpenEcheancierModalOnLastCreatedEngagement
                                    ) {
                                        setOpenEcheancierModalOnLastCreatedEngagement(true);
                                    }
                                },
                                async validateSaveAndCloseModal() {
                                    await validateAndSave(props);
                                    setOpenEcheancierModalAfterEngagementSaving(false);
                                },
                                async validateAndGoToPreviousTab() {
                                    const isGeneralTabValid = await validateGeneralTab(props);
                                    if (isGeneralTabValid) {
                                        setSelectedTab('spendingRythm');
                                    }
                                },
                                title: (
                                    <TabItem
                                        data-testid="priceVariationTab"
                                        onClick={async () => {
                                            const isGeneralTabValid = await validateGeneralTab(
                                                props,
                                            );
                                            if (isGeneralTabValid) {
                                                setSelectedTab('priceVariation');
                                            }
                                        }}
                                    >
                                        {renderBadge('3', selectedTab === 'priceVariation')}
                                        <Text style={{ margin: '0' }}>
                                            {t('engagement.priceVariation')}
                                        </Text>
                                    </TabItem>
                                ),
                                entityId: engagement?.id,
                                priceVariationSettingsOfFirstLine:
                                    priceVariationSettingsOfFirstEngagement,
                            },
                        ]}
                        formikProps={props}
                        selectedTab={selectedTab}
                        hasRightToEdit={hasRightToEdit}
                        permissionsNeeded={permissionsNeeded}
                        onCancelClick={() => {
                            props.resetForm();
                            setSelectedTab('general');
                        }}
                        isPending={isPending}
                        scrollableBody
                    />
                </ModalStyle>
            )}
        </Formik>
    );
};
export default EngagementModal;
