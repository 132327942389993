import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import type { FormikProps, FieldArrayRenderProps } from 'formik';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Style
import { colors } from '../../constants/colors';
import SuccessOperation from '../../assets/images/successOperation.svg';

// Utils
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import { createEmptyTvaCodesForInputs, createTvaCodesForInputs } from '../../utils/tvaCodes';

// Components
import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import type { ButtonProps } from '../../components/Button';
import Button from '../../components/Button';
import PermissionAwareText from '../../components/PermissionAwareText';
import PermissionAwareDisplay from '../../components/PermissionAwareDisplay';
import InformationCard from '../../components/InformationCard';
import GridRow from '../../components/GridRow';
import GridCol from '../../components/GridCol';
import Loader from '../../components/Loader';
import Text from '../../components/Text';
import PermissionAwareAddress from '../../components/PermissionAwareAddress';
import { showFlag } from '../../components/Flag';
import LabelValueDropdown from '../../components/dropdown/LabelValueDropdown';
import OrganizationDropdown from '../../components/dropdown/OrganizationDropdown';
import type { StatusOption } from '../../components/dropdown/FormikOptionDropdown';
import FormikOptionDropdown from '../../components/dropdown/FormikOptionDropdown';
import PermissionAwareTextarea from '../../components/PermissionAwareTextarea';
import Modal from '../../components/Modal';
import PermissionAwareDateInputField from '../../components/PermissionAwareDateInputField';
import GroupCodeSequenceDropdown from '../../components/dropdown/GroupCodeSequenceDropdown';
import OperationTvaCode from '../../components/OperationTvaCode';

// Data
import type { OperationFormData } from '../../slices/operationSlice';
import {
    createOperation,
    deleteOperation,
    getOperation,
    selectError,
    selectOperation,
    selectOperationIdAdded,
    updateOperation,
} from '../../slices/operationSlice';
import {
    selectOrganizationsForDropdown,
    selectClientOrganizationsForDropdown,
    getOrganizationsForDropdown,
    getOrganizationName,
} from '../../slices/organizationSlice';
import type { Label, LabelValue } from '../../slices/labelSlice';
import { getSpecificLabels, selectAllLabelsBy } from '../../slices/labelSlice';
import type { Permission } from '../../slices/authSlice';
import { selectUser } from '../../slices/authSlice';
import {
    selectGroupCodeSequencesForDropdown,
    getGroupCodeSequences,
} from '../../slices/groupCodeSequenceSlice';
import type { TvaCodeFormData } from '../../slices/tvaCodeSlice';
import {
    validateMandatoryLabels,
    getMandatoryLabelIds,
} from '../../utils/mandatoryLabelsValidation';
import { getExactDecimalValue } from '../../utils/calculationMethods';
import type { IconName } from '../../components/Icon';
import { useAppDispatch } from '../../store';

type Props = {
    headerProps: Partial<HeaderProps>;
};

const StyledInternalNumber = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${colors.neutral.N25};
    padding: 0 0.40625rem;
    height: fit-content;
    border-radius: 0.25rem;
    > * {
        margin: 0.40625rem;
    }
`;

const StyledLabel = styled(Text)`
    margin: 0.5rem 0 0 0;
`;

const ContainerGroupCode = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ImageContainer = styled.img`
    align-self: center;
    margin: -1.5rem 0 0.5rem 0;
    width: 14.375rem;
`;

const TvaCodesContainer = styled(GridRow)`
    justify-content: space-around;
`;

const Operation: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const serverError = useSelector(selectError);
    const {
        operationId,
        organizationId,
    }: {
        operationId?: string;
        organizationId?: string;
    } = useParams();
    let isCreation = false;
    if (operationId === 'new') {
        isCreation = true;
    }
    let permissionNeededToAddOrEdit: Permission = {
        code: 'OPERATIONS_EDIT',
        operationId,
    };
    if (isCreation) {
        permissionNeededToAddOrEdit = {
            code: 'OPERATIONS_ADD',
            organizationId,
        };
    }
    const permissionNeededToDelete = { code: 'OPERATIONS_DELETE', operationId };
    const hasGlobalOperationAddPermission = usePermissionsCheck([{ code: 'OPERATIONS_ADD' }]);
    const user = useSelector(selectUser);
    const hasRightToEditOrAddOperations = usePermissionsCheck([permissionNeededToAddOrEdit]);
    const hasRightToDeleteOperations = usePermissionsCheck([permissionNeededToDelete]);
    const operation = useSelector(selectOperation(Number(operationId)));
    const operationIdAdded = useSelector(selectOperationIdAdded);
    const organizations = useSelector(selectOrganizationsForDropdown);
    const clientOrganizations = useSelector(selectClientOrganizationsForDropdown);
    const groupCodeSequences = useSelector(selectGroupCodeSequencesForDropdown);

    const [clientOrganizationId, setClientOrganizationId] = useState<string | undefined>(
        organizationId ?? operation?.clientId ?? user?.organizationId,
    );
    const clientOrganizationName = getOrganizationName(
        organizations.find(({ id }) => id === clientOrganizationId),
    );
    const projectOwnerOrganizationName = getOrganizationName(
        organizations.find(({ id }) => id === operation?.projectOwnerId),
    );
    const delegateProjectOwnerOrganizationName = getOrganizationName(
        organizations.find(({ id }) => id === operation?.delegateProjectOwnerId),
    );
    const labels = useSelector(
        selectAllLabelsBy('operation', clientOrganizationId, Number(operationId)),
    );

    type LocationParams =
        | {
              organizationId?: string;
              goBackTo?: string;
              breadcrumbs?: Array<{
                  to: string;
                  text: string;
              }>;
          }
        | undefined;

    const location = useLocation();
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState<boolean>(
        Boolean(location.search === '?edit' || isCreation),
    );

    const [isPending, setIsPending] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalOptions, setModalOptions] = useState<{
        title: string;
        text: string;
        confirmationButton: string;
        iconName: IconName;
        iconColor: string;
        callback: () => void;
    } | null>(null);
    const [isDeletePending, setIsDeletePending] = useState<boolean>(false);

    const computeInitialSelectedLabelValues = (operationLabelValues: LabelValue[]) =>
        operationLabelValues.map((labelValue) => {
            const labelValueWithLinks = labels
                .find(({ id }) => labelValue.labelId === id)
                ?.labelValues.find(({ id }) => labelValue.id === id);
            const links = labelValueWithLinks?.linkedLabelValues ?? [];
            return {
                ...labelValue,
                hasLinks: links.length > 0,
            };
        });
    const [selectedLabelValues, setSelectedLabelValues] = useState<
        Array<{
            labelId: Label['id'];
            id: LabelValue['id'];
            hasLinks: boolean;
            value: string;
        }>
    >(operation?.labelValues ? computeInitialSelectedLabelValues(operation.labelValues) : []);

    const [initialTvaCodes, setInitialTvaCodes] = useState<TvaCodeFormData[]>([]);

    const state = (location.state as LocationParams) ?? {};

    useEffect(() => {
        if (operation) {
            setClientOrganizationId(operation.clientId);
            setSelectedLabelValues(computeInitialSelectedLabelValues(operation.labelValues));
            createTvaCodesForInputs(operation.tvaCodes)
                .then((computedTvaCodes) => {
                    setInitialTvaCodes(computedTvaCodes);
                })
                .catch((error) => {
                    showFlag('error', t('errors.error'), t('errors.unexpectedError'));
                });
        } else {
            if (!organizationId && user?.organizationId) {
                setClientOrganizationId(user.organizationId);
            }

            setInitialTvaCodes(createEmptyTvaCodesForInputs());
        } // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on every dependencies change
    }, [operation, user, organizationId]);

    useEffect(() => {
        if (operationId && operation?.clientId === clientOrganizationId) {
            void dispatch(
                getSpecificLabels({
                    type: 'operation',
                    operationId: Number(operationId),
                }),
            );
        } else if (clientOrganizationId) {
            void dispatch(
                getSpecificLabels({
                    type: 'operation',
                    clientId: clientOrganizationId,
                }),
            );
        } else {
            void dispatch(
                getSpecificLabels({
                    type: 'operation',
                }),
            );
        }
    }, [dispatch, clientOrganizationId, operationId, operation]);

    useEffect(() => {
        void dispatch(getOrganizationsForDropdown());
        void dispatch(getGroupCodeSequences());
    }, [dispatch]);

    useEffect(() => {
        if (operationId && operationId !== 'new' && !operation && !isDeletePending) {
            void dispatch(getOperation(Number(operationId)));
        }
    }, [dispatch, isDeletePending, operation, operationId]);

    useEffect(() => {
        if (isPending) {
            if (serverError) {
                showFlag('error', t('errors.error'), t(serverError));
                setIsPending(false);
                setIsEditMode(true);
            } else if (operationIdAdded) {
                if (isCreation) {
                    setModalOpen(true);
                    navigate(`${location.pathname.split('/new')[0]}/${operationIdAdded}/settings`, {
                        state,
                        replace: true,
                    });
                } else {
                    showFlag('success', t('general.success'), t('operation.successfullyUpdated'));
                }
                setIsPending(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on every dependencies change
    }, [serverError, t, isCreation, operationIdAdded, setModalOpen]);

    useEffect(() => {
        if (isDeletePending) {
            if (!operation) {
                navigate(`/operations`);
                showFlag('success', t('general.success'), t('operation.successfullyDeleted'));
            }
        } else {
            if (serverError) {
                showFlag('error', t('errors.error'), t(serverError));
                setIsDeletePending(false);
                setModalOptions(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on every dependencies change
    }, [operation, navigate, serverError, t]);

    const addressInformationCardRef = useRef<HTMLDivElement>(null);
    const labelsInformationCardRef = useRef<HTMLDivElement>(null);
    const tvaCodesInformationCardRef = useRef<HTMLDivElement>(null);

    if (operationId && operationId !== 'new' && !operation) {
        return <Loader overlay />;
    }

    const operationSchema = Yup.object().shape({
        status: Yup.mixed()
            .oneOf([
                'preliminary_studies',
                'in_conception',
                'in_progress',
                'completed',
                'archived',
                'canceled',
            ])
            .required(t('errors.required')),
        clientId: Yup.string().required(t('errors.required')),
        label: Yup.string()
            .required(t('errors.required'))
            .max(255, t('errors.tooLong', { number: 255 })),
        longLabel: Yup.string()
            .required(t('errors.required'))
            .max(255, t('errors.tooLong', { number: 255 })),
        projectOwnerId: Yup.string().required(t('errors.required')),
        description: Yup.string().max(512, t('errors.tooLong', { number: 512 })),
        constructionSiteName: Yup.string()
            .required(t('errors.required'))
            .max(255, t('errors.tooLong', { number: 255 })),
        postalCode: Yup.string()
            .max(
                10,
                String(
                    t('errors.tooLong', {
                        number: '10',
                    }),
                ),
            )
            .required(t('errors.required')),
        address1: Yup.string()
            .max(
                512,
                String(
                    t('errors.tooLong', {
                        number: '512',
                    }),
                ),
            )
            .required(t('errors.required')),
        city: Yup.string()
            .max(
                50,
                String(
                    t('errors.tooLong', {
                        number: '50',
                    }),
                ),
            )
            .required(t('errors.required')),
        address2: Yup.string().max(
            512,
            String(
                t('errors.tooLong', {
                    number: '512',
                }),
            ),
        ),
        country: Yup.string()
            .max(
                50,
                String(
                    t('errors.tooLong', {
                        number: '50',
                    }),
                ),
            )
            .required(t('errors.required')),
        plannedStartingDate: Yup.string().required(t('errors.required')),
        groupCodeSequenceId: Yup.number().required(t('errors.required')),
        tvaCodes: Yup.array().of(
            Yup.object().shape({
                id: Yup.number().nullable(),
                value: Yup.number()
                    .nullable()
                    .test(
                        'value',
                        t('operation.tvaCodes.errors.tvaValueMustBeBetween0And100'),
                        (value) => {
                            const isTvaCodeEmpty = value === undefined || value === null;
                            if (!isTvaCodeEmpty) {
                                return value >= 0 && value <= 100;
                            } else {
                                return true;
                            }
                        },
                    )
                    .typeError(t('operation.tvaCodes.errors.tvaValueMustBeBetween0And100')),
                operationId: Yup.number(),
            }),
        ),
    });

    const computeButtonsOutOfProps = (props: FormikProps<OperationFormData>): ButtonProps[] => {
        if (hasRightToEditOrAddOperations) {
            if (isEditMode) {
                const buttons: ButtonProps[] = [];
                if (hasRightToDeleteOperations && operationId !== 'new') {
                    buttons.push({
                        'data-testid': 'deleteOperation',
                        aspect: 'onlyIcon',
                        iconName: 'DeleteOutline',
                        onClick: handleDeleteClick,
                    });
                }
                buttons.push({
                    text: t('general.cancel'),
                    'data-testid': 'cancelModifyOperation',
                    aspect: 'secondary',
                    onClick() {
                        if (isCreation) {
                            navigate(-1);
                        } else {
                            navigate(location.pathname.replace('?edit', ''), {
                                state,
                                replace: true,
                            });
                            setIsEditMode(false);
                            props.handleReset();
                        }
                    },
                });
                buttons.push({
                    text: isCreation ? t('general.create') : t('general.submit'),
                    'data-testid': 'submitOperation',
                    aspect: 'primary',
                    async onClick() {
                        await props.validateForm();
                        props.handleSubmit();
                        // We use errorKeys here to leverage yup/Formik's validation
                        const errorKeys = Object.keys(props.errors);
                        if (
                            errorKeys.length > 0 &&
                            (errorKeys.includes('clientId') ||
                                errorKeys.includes('label') ||
                                errorKeys.includes('longLabel') ||
                                errorKeys.includes('projectOwnerId'))
                        ) {
                            scrollToTop();
                        } else if (
                            errorKeys.length > 0 &&
                            (errorKeys.includes('constructionSiteName') ||
                                errorKeys.includes('postalCode') ||
                                errorKeys.includes('address1') ||
                                errorKeys.includes('city') ||
                                errorKeys.includes('country'))
                        ) {
                            scrollToAddressInfoCard();
                        }
                    },
                });
                return buttons;
            } else {
                return [
                    {
                        text: t('operation.modifyOperation'),
                        'data-testid': 'modifyOperation',
                        aspect: 'primary',
                        onClick() {
                            navigate(`${location.pathname}?edit`, { state, replace: true });
                            setIsEditMode(true);
                        },
                    },
                ];
            }
        }
        return [];
    };

    const statusOptions: StatusOption[] = [
        {
            value: 'preliminary_studies',
            label: t('operation.statuses.preliminaryStudies'),
        },
        {
            value: 'in_conception',
            label: t('operation.statuses.inConception'),
        },
        {
            value: 'in_progress',
            label: t('operation.statuses.inProgress'),
        },
        {
            value: 'completed',
            label: t('operation.statuses.completed'),
        },
        {
            value: 'archived',
            label: t('operation.statuses.archived'),
        },
        {
            value: 'canceled',
            label: t('operation.statuses.canceled'),
        },
    ];
    const emptyField = '';
    const descriptionLabel = isEditMode
        ? `${t('operation.information.description')} ${t('general.optional')}`
        : t('operation.information.description');

    const computeLabelValueDisplay = (operationLabelValues: LabelValue[], label: Label) => {
        let nameDisplay = isEditMode ? t('operation.labels.selectValue') : '-';
        const labelValue = operationLabelValues.find(
            (value: LabelValue) => value.labelId === label.id,
        )?.value;
        if (labelValue) {
            nameDisplay = labelValue;
        }
        return nameDisplay;
    };

    const computeLabelError = (labelId: Label['id']) => {
        const mandatoryLabelIds = getMandatoryLabelIds(labels);
        const labelValueId = selectedLabelValues.find(
            (labelValue) => labelValue.labelId === labelId,
        )?.id;
        return mandatoryLabelIds.includes(labelId) && !labelValueId;
    };

    const handleLabelValueChange = (
        arrayHelpers: FieldArrayRenderProps,
        operationLabelValues: LabelValue[],
        labelValueSelected: LabelValue,
    ) => {
        // formik management
        const index = operationLabelValues.findIndex(
            (labelValue: LabelValue) => labelValue.labelId === labelValueSelected.labelId,
        );

        if (index >= 0) {
            if (labelValueSelected.id !== 0) {
                arrayHelpers.replace(index, labelValueSelected);
            } else {
                arrayHelpers.remove(index);
            }
        } else {
            if (labelValueSelected.id !== 0) {
                arrayHelpers.push(labelValueSelected);
            }
        }

        // linkedLabelValues management
        const { linkedLabelValues, ...selectedValue } = labelValueSelected;
        const newSelectedValue = {
            ...selectedValue,
            hasLinks: Boolean(
                labelValueSelected.linkedLabelValues &&
                    labelValueSelected.linkedLabelValues.length > 0,
            ),
        };
        const newList = [...selectedLabelValues];
        const selectedValuesListIndex = selectedLabelValues.findIndex(
            (labelValue) => labelValue.labelId === labelValueSelected.labelId,
        );
        if (
            selectedValuesListIndex >= 0 &&
            newList[selectedValuesListIndex].id !== labelValueSelected.id
        ) {
            newList[selectedValuesListIndex] = newSelectedValue;
            setSelectedLabelValues(newList);
        } else if (selectedValuesListIndex < 0) {
            newList.push(newSelectedValue);
            setSelectedLabelValues(newList);
        }
    };

    const handleDeleteClick = () => {
        setModalOptions({
            title: 'operation.deletion',
            text: 'operation.confirmDeletion',
            confirmationButton: 'general.confirm',
            iconName: 'Warning',
            iconColor: colors.yellow.Y400,
            callback() {
                void dispatch(
                    deleteOperation({
                        id: Number(operationId),
                    }),
                );
                setIsDeletePending(true);
            },
        });
    };

    // Scroll
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error, @typescript-eslint/ban-ts-comment -- necessary
    // @ts-ignore
    const isIE11 = Boolean(window.msCrypto);
    const scrollToAddressInfoCard = () => {
        let scrollableParent = document.querySelector('.scrollable-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (addressInformationCardRef.current && scrollableParent) {
            // 100 is the header height
            scrollableParent.scrollTop = addressInformationCardRef.current.offsetTop - 100;
        }
    };
    const scrollToTop = () => {
        let scrollableParent = document.querySelector('.scrollable-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (scrollableParent) {
            scrollableParent.scrollTop = 0;
        }
    };
    const scrollToLabels = () => {
        let scrollableParent = document.querySelector('.scrollable-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (labelsInformationCardRef.current && scrollableParent) {
            // 100 is the header height
            scrollableParent.scrollTop = labelsInformationCardRef.current.offsetTop - 100;
        }
    };

    const getNonNullDecimalTvaCodesValues = (tvaCodes: TvaCodeFormData[]) =>
        tvaCodes.reduce((result: TvaCodeFormData[], currentTvaCode: TvaCodeFormData) => {
            delete currentTvaCode.isUsedInDb;
            const tvaCodeHasValidValue =
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- want to keep 0 value
                currentTvaCode.value !== undefined &&
                currentTvaCode.value !== null &&
                currentTvaCode.value !== '';

            if (tvaCodeHasValidValue) {
                const tvaCodeToSaveInDb: TvaCodeFormData = {
                    ...currentTvaCode,
                    value: getExactDecimalValue(Number(currentTvaCode.value) / 100, 4),
                    operationId: Number(operation?.id),
                };
                result.push(tvaCodeToSaveInDb);
            }
            return result;
        }, []);

    const canEditClient =
        isEditMode &&
        hasRightToEditOrAddOperations &&
        !operation?.clientId &&
        !organizationId &&
        hasGlobalOperationAddPermission;

    let initialFormikClientId;

    if (canEditClient) {
        initialFormikClientId = operation?.clientId ?? user?.organizationId;
    } else {
        initialFormikClientId = clientOrganizationId;
    }

    if (!initialFormikClientId) {
        initialFormikClientId = emptyField;
    }

    let goBackTo = '';
    if (state.goBackTo) {
        goBackTo = state.goBackTo;
    } else if (headerProps.goBackTo) {
        goBackTo = headerProps.goBackTo;
    } else {
        goBackTo = '/operations';
    }

    const initialValues = {
        id: operation?.id,
        clientId: initialFormikClientId,
        status: operation?.status ?? 'preliminary_studies',
        label: operation?.label ?? emptyField,
        longLabel: operation?.longLabel ?? emptyField,
        projectOwnerId: operation?.projectOwnerId ?? emptyField,
        delegateProjectOwnerId: operation?.delegateProjectOwnerId ?? null,
        description: operation?.description ?? emptyField,
        constructionSiteName: operation?.constructionSiteName ?? emptyField,
        postalCode: operation?.postalCode ?? emptyField,
        address1: operation?.address1 ?? emptyField,
        city: operation?.city ?? emptyField,
        address2: operation?.address2 ?? emptyField,
        country: operation?.country ?? 'France',
        labelValues: operation?.labelValues ?? [],
        plannedStartingDate: operation?.plannedStartingDate ?? '',
        groupCodeSequenceId: operation?.groupCodeSequenceId ?? 1,
        tvaCodes: initialTvaCodes,
    } as OperationFormData;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={operationSchema}
            onSubmit={(values: OperationFormData, actions) => {
                values.groupCodeSequenceId = Number(values.groupCodeSequenceId);

                const mandatoryLabelsAreValid = validateMandatoryLabels(values.labelValues, labels);
                const tvaCodesWithValidValues = getNonNullDecimalTvaCodesValues(values.tvaCodes);
                if (mandatoryLabelsAreValid) {
                    if (values.delegateProjectOwnerId === '') {
                        values.delegateProjectOwnerId = null;
                    }

                    void dispatch(
                        isCreation
                            ? createOperation({ ...values, tvaCodes: tvaCodesWithValidValues })
                            : updateOperation({ ...values, tvaCodes: tvaCodesWithValidValues }),
                    );
                    actions.setSubmitting(false);
                    setIsEditMode(false);
                    navigate(location.pathname.replace('?edit', ''), { state, replace: true });
                    setIsPending(true);
                } else {
                    scrollToLabels();
                }
            }}
            enableReinitialize
        >
            {(props: FormikProps<OperationFormData>) => (
                <MainLayout
                    header={
                        <Header
                            {...headerProps}
                            title={
                                isCreation || !operation
                                    ? t('operation.addOperation')
                                    : String(operation.label)
                            }
                            goBackTo={goBackTo}
                            showNavigation={!isEditMode}
                            isDirty={Object.keys(props.touched).length > 0}
                            buttons={computeButtonsOutOfProps(props)}
                        />
                    }
                >
                    <Form>
                        <InformationCard
                            title={t('operation.information.title')}
                            headerTrailingComponent={
                                <StyledInternalNumber>
                                    <Text color={colors.neutral.N300}>
                                        {t('operation.information.internalNumber')}
                                    </Text>
                                    <Text color={colors.neutral.N500} data-testid="internalNumber">
                                        {operation?.internalNumber}
                                    </Text>
                                </StyledInternalNumber>
                            }
                        >
                            <GridCol
                                defaultScreen={6}
                                smallScreen={6}
                                style={
                                    isEditMode
                                        ? {
                                              marginBottom: '1.875rem',
                                          }
                                        : {
                                              marginTop: '0',
                                          }
                                }
                            >
                                {canEditClient ? (
                                    <OrganizationDropdown
                                        items={clientOrganizations}
                                        label={t('operation.information.clientName')}
                                        initialOrganizationId={clientOrganizationId}
                                        handleChange={({ id }) => setClientOrganizationId(id)}
                                        name="clientId"
                                        data-testid="clientId"
                                        errorTestId="errorClientId"
                                        filterable
                                    />
                                ) : (
                                    <PermissionAwareDisplay
                                        label={t('operation.information.clientName')}
                                        value={clientOrganizationName ?? emptyField}
                                        style={{
                                            marginTop: '0',
                                        }}
                                    />
                                )}
                            </GridCol>
                            <GridCol
                                defaultScreen={3}
                                smallScreen={3}
                                style={
                                    isEditMode
                                        ? {
                                              marginBottom: '1.875rem',
                                              marginTop: '0',
                                          }
                                        : {
                                              marginTop: '0',
                                          }
                                }
                            >
                                <PermissionAwareDateInputField
                                    editable={isEditMode}
                                    permissionsRequired={[permissionNeededToAddOrEdit]}
                                    name="plannedStartingDate"
                                    label={t('operation.information.plannedStartingDate')}
                                    value={props.values.plannedStartingDate}
                                    data-testid="plannedStartingDate"
                                    errorTestId="errorPlannedStartingDate"
                                    noMarginTop
                                    whiteBackground
                                />
                            </GridCol>
                            <GridCol
                                defaultScreen={3}
                                smallScreen={3}
                                style={
                                    isEditMode
                                        ? {
                                              marginBottom: '1.875rem',
                                              marginTop: '0',
                                          }
                                        : {
                                              marginTop: '0',
                                          }
                                }
                            >
                                {isEditMode && hasRightToEditOrAddOperations ? (
                                    <FormikOptionDropdown
                                        items={statusOptions}
                                        label={t('operation.information.status')}
                                        name="status"
                                        initialNameDiplay={
                                            statusOptions.find(
                                                (status) => status.value === operation?.status,
                                            )?.label ?? t('operation.statuses.preliminaryStudies')
                                        }
                                        data-testid="status"
                                        errorTestId="errorClientId"
                                    />
                                ) : (
                                    <PermissionAwareDisplay
                                        label={t('operation.information.status')}
                                        value={
                                            statusOptions.find(
                                                (status) => status.value === operation?.status,
                                            )?.label ?? t('operation.statuses.preliminaryStudies')
                                        }
                                        style={{
                                            marginTop: '0',
                                        }}
                                    />
                                )}
                            </GridCol>
                            <GridCol
                                defaultScreen={6}
                                smallScreen={6}
                                style={
                                    isEditMode
                                        ? {
                                              marginBottom: '1.875rem',
                                          }
                                        : {}
                                }
                            >
                                <PermissionAwareText
                                    marginTop="0"
                                    editable={isEditMode}
                                    permissionsRequired={[permissionNeededToAddOrEdit]}
                                    label={t('operation.information.label')}
                                    name="label"
                                    type="text"
                                    placeholder={t('operation.information.label')}
                                    data-testid="operationLabel"
                                    errorTestId="errorOperationLabel"
                                    whiteBackground
                                />
                            </GridCol>
                            <GridCol
                                defaultScreen={6}
                                smallScreen={6}
                                style={
                                    isEditMode
                                        ? {
                                              marginBottom: '1.875rem',
                                          }
                                        : {}
                                }
                            >
                                <PermissionAwareText
                                    marginTop="0"
                                    editable={isEditMode}
                                    permissionsRequired={[permissionNeededToAddOrEdit]}
                                    label={t('operation.information.longLabel')}
                                    name="longLabel"
                                    type="text"
                                    placeholder={t('operation.information.longLabel')}
                                    data-testid="longLabel"
                                    errorTestId="errorLongLabel"
                                    hasTooltip
                                    whiteBackground
                                />
                            </GridCol>
                            <GridCol
                                defaultScreen={6}
                                smallScreen={6}
                                style={
                                    isEditMode
                                        ? {
                                              marginBottom: '1.875rem',
                                          }
                                        : {}
                                }
                            >
                                {isEditMode && hasRightToEditOrAddOperations ? (
                                    <OrganizationDropdown
                                        items={organizations}
                                        label={t('operation.information.projectOwner')}
                                        initialOrganizationId={operation?.projectOwnerId}
                                        name="projectOwnerId"
                                        data-testid="projectOwnerId"
                                        errorTestId="errorProjectOwnerId"
                                        filterable
                                    />
                                ) : (
                                    <PermissionAwareDisplay
                                        label={t('operation.information.projectOwner')}
                                        value={projectOwnerOrganizationName ?? emptyField}
                                    />
                                )}
                            </GridCol>
                            <GridCol
                                defaultScreen={6}
                                smallScreen={6}
                                style={
                                    isEditMode
                                        ? {
                                              marginBottom: '1.875rem',
                                          }
                                        : {}
                                }
                            >
                                {isEditMode && hasRightToEditOrAddOperations ? (
                                    <OrganizationDropdown
                                        items={organizations}
                                        label={t('operation.information.delegateProjectOwner')}
                                        initialOrganizationId={operation?.delegateProjectOwnerId}
                                        name="delegateProjectOwnerId"
                                        data-testid="delegateProjectOwnerId"
                                        errorTestId="errorDelegateProjectOwnerId"
                                        filterable
                                        shouldBeResettable
                                        isOptional
                                    />
                                ) : (
                                    <PermissionAwareDisplay
                                        label={t('operation.information.delegateProjectOwner')}
                                        value={delegateProjectOwnerOrganizationName ?? emptyField}
                                    />
                                )}
                            </GridCol>
                            <GridCol
                                defaultScreen={12}
                                smallScreen={12}
                                style={
                                    isEditMode
                                        ? {
                                              marginBottom: '1.875rem',
                                          }
                                        : {}
                                }
                            >
                                <PermissionAwareTextarea
                                    editable={isEditMode}
                                    permissionsRequired={[permissionNeededToAddOrEdit]}
                                    label={descriptionLabel}
                                    name="description"
                                    value={operation?.description ?? emptyField}
                                    data-testid="description"
                                    errorTestId="errorDescription"
                                    displayStyle={{
                                        whiteSpace: 'unset',
                                    }}
                                    whiteBackground
                                />
                            </GridCol>
                        </InformationCard>
                        <div ref={addressInformationCardRef} />
                        <InformationCard
                            title={t('operation.address.title')}
                            bigHeaderSpace={isEditMode}
                        >
                            <PermissionAwareAddress
                                editable={isEditMode}
                                permissionsRequired={[permissionNeededToAddOrEdit]}
                                data={operation}
                                colSizes={[5, 2, 3, 2]}
                                fieldsToUse={[
                                    {
                                        name: 'constructionSiteName',
                                        isOptional: false,
                                    },
                                    {
                                        name: 'address1',
                                        isOptional: false,
                                    },
                                    {
                                        name: 'address2',
                                        isOptional: true,
                                    },
                                    {
                                        name: 'postalCode',
                                        isOptional: false,
                                    },
                                    {
                                        name: 'city',
                                        isOptional: false,
                                    },
                                    {
                                        name: 'country',
                                        isOptional: false,
                                    },
                                ]}
                                hasTooltip
                            />
                        </InformationCard>
                        <InformationCard title={t('operation.marketManager')}>
                            <GridCol defaultScreen={12} smallScreen={12} style={{ marginTop: '0' }}>
                                {isEditMode && hasRightToEditOrAddOperations ? (
                                    <ContainerGroupCode>
                                        <StyledLabel
                                            color={colors.neutral.N300}
                                            style={{ marginBottom: '0' }}
                                        >
                                            {t('operation.groupCodeSequence')}
                                        </StyledLabel>
                                        <GroupCodeSequenceDropdown
                                            items={groupCodeSequences}
                                            initialGroupCodeSequenceId={
                                                props.values.groupCodeSequenceId
                                            }
                                            name="groupCodeSequenceId"
                                            data-testid="groupCodeSequenceId"
                                            errorTestId="errorGroupCodeSequenceId"
                                        />
                                    </ContainerGroupCode>
                                ) : (
                                    <ContainerGroupCode>
                                        <StyledLabel
                                            color={colors.neutral.N300}
                                            style={{ marginBottom: '0.5rem' }}
                                        >
                                            {t('operation.groupCodeSequence')}
                                        </StyledLabel>
                                        <PermissionAwareDisplay
                                            value={
                                                groupCodeSequences.find(
                                                    (sequence) =>
                                                        sequence.id ===
                                                        operation?.groupCodeSequenceId,
                                                )?.label ?? ''
                                            }
                                            style={{
                                                margin: '0.5rem 0 0 1rem',
                                            }}
                                        />
                                    </ContainerGroupCode>
                                )}
                            </GridCol>
                        </InformationCard>
                        <InformationCard title={t('operation.priceVariation.title')}>
                            {/* priceVariation will be done later */}
                            {/* <GridCol defaultScreen={4} style={{ padding: '2rem 0rem' }}>
                                {t('operation.priceVariation.coefficientVariation')}
                            </GridCol>
                            <GridCol defaultScreen={4}>
                                <FormikTextField
                                    name={'decimalPlace'}
                                    label={t('operation.priceVariation.decimalNumber')}
                                    type="text"
                                    placeholder="3"
                                    maxWidth="6rem"
                                    whiteBackground
                                    marginTop={'0'}
                                />
                            </GridCol>
                            <GridCol defaultScreen={4}>
                                <FormikOptionDropdown
                                    items={[
                                        {
                                            value: 'superior',
                                            label: t(
                                                'operation.priceVariation.coefficientValues.superior',
                                            ),
                                        },
                                    ]}
                                    label={'\n'}
                                    name="coefficientValue"
                                    initialNameDiplay={t(
                                        'operation.priceVariation.coefficientValues.superior',
                                    )}
                                    emptyLabelSpace
                                />
                            </GridCol> */}
                        </InformationCard>
                        <FieldArray
                            name="labelValues"
                            render={(arrayHelpers: FieldArrayRenderProps) => (
                                <InformationCard title={t('operation.labels.title')}>
                                    <div ref={labelsInformationCardRef} />
                                    {labels.map((label: Label) => (
                                        <GridCol key={label.id} defaultScreen={4} smallScreen={4}>
                                            {isEditMode && hasRightToEditOrAddOperations ? (
                                                <LabelValueDropdown
                                                    items={label.labelValues}
                                                    label={label.name}
                                                    labelValueDisplay={computeLabelValueDisplay(
                                                        props.values.labelValues,
                                                        label,
                                                    )}
                                                    onItemSelect={(
                                                        labelValueSelected: LabelValue,
                                                    ) =>
                                                        handleLabelValueChange(
                                                            arrayHelpers,
                                                            props.values.labelValues,
                                                            labelValueSelected,
                                                        )
                                                    }
                                                    currentLabelId={label.id}
                                                    selectedLabelValues={selectedLabelValues}
                                                    name="labelValue"
                                                    data-testid={`labelDropdown-${label.id}`}
                                                    error={
                                                        props.touched.labelValues &&
                                                        computeLabelError(label.id)
                                                    }
                                                    isMandatory={label.isMandatory}
                                                />
                                            ) : (
                                                <PermissionAwareDisplay
                                                    label={label.name}
                                                    data-testid={`labelDropdownDisplay-${label.id}`}
                                                    value={computeLabelValueDisplay(
                                                        operation?.labelValues ?? [],
                                                        label,
                                                    )}
                                                    style={{
                                                        marginTop: '0',
                                                    }}
                                                />
                                            )}
                                        </GridCol>
                                    ))}
                                </InformationCard>
                            )}
                        />
                        <InformationCard title={t('operation.tvaCodes.title')}>
                            <div ref={tvaCodesInformationCardRef} />
                            <GridCol defaultScreen={12} smallScreen={12}>
                                <TvaCodesContainer>
                                    {props.values.tvaCodes.map(
                                        (tvaCode: TvaCodeFormData, index: number) => (
                                            <OperationTvaCode
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={`${tvaCode.id}-${index}`}
                                                tvaCodes={props.values.tvaCodes}
                                                index={index}
                                                isEditMode={isEditMode}
                                                permissionsRequired={[permissionNeededToAddOrEdit]}
                                                editable={
                                                    isEditMode && hasRightToEditOrAddOperations
                                                }
                                            />
                                        ),
                                    )}
                                </TvaCodesContainer>
                            </GridCol>
                        </InformationCard>
                    </Form>
                    <Modal
                        isOpen={modalOpen}
                        onCloseButtonPressed={() => {
                            setModalOpen(!modalOpen);
                        }}
                        maxWidth="37.5rem"
                    >
                        <ImageContainer src={SuccessOperation} />
                        <Text
                            color={colors.blue.B400}
                            style={{
                                fontWeight: 800,
                                lineHeight: '2rem',
                            }}
                            size="0.875rem"
                            font="Gilroy"
                        >
                            {t('operation.successfullyCreatedMessageTitle')}
                        </Text>
                        <Text
                            style={{
                                textAlign: 'center',
                                maxWidth: '29rem',
                            }}
                        >
                            {t('operation.successfullyCreatedMessage')}
                        </Text>
                        <Button
                            text={t('operation.enterBudget')}
                            aspect="primary"
                            size="small"
                            style={{
                                margin: '0.5rem 0rem',
                            }}
                            onClick={() => {
                                setModalOpen(!modalOpen);
                                navigate(`/operations/${operation?.id}/budgets`);
                            }}
                        />
                    </Modal>
                    {modalOptions && (
                        <Modal
                            isOpen={modalOptions.text !== ''}
                            onCloseButtonPressed={() => setModalOptions(null)}
                            buttons={[
                                {
                                    text: t('general.cancel'),
                                    aspect: 'secondary',
                                    'data-testid': 'cancelDelete',
                                    onClick() {
                                        setModalOptions(null);
                                    },
                                },
                                {
                                    text: t(modalOptions.confirmationButton),
                                    aspect: 'primary',
                                    'data-testid': 'confirmDelete',
                                    onClick: modalOptions.callback,
                                },
                            ]}
                            title={t(modalOptions.title)}
                            iconName={modalOptions.iconName}
                            iconColor={modalOptions.iconColor}
                            size="small"
                            shouldRenderGlobalStyle
                            centerTitle
                        >
                            <Text style={{ margin: '0 0 1rem 0' }}>{t(modalOptions.text)}</Text>
                        </Modal>
                    )}
                </MainLayout>
            )}
        </Formik>
    );
};

export default Operation;
