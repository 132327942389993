import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import type { Engagement } from '../slices/engagementSlice';
import { selectEngagement, selectLastEngagementOrder } from '../slices/engagementSlice';
import { formatNumberWithLeadingZeros } from '../utils/formatters';
import type { Market } from '../slices/marketSlice';
import { selectMarket } from '../slices/marketSlice';

const useEngagementInternalNumber = (marketId: Market['id'], engagementId?: Engagement['id']) => {
    const [internalNumber, setInternalNumber] = useState('');

    const engagement = useSelector(selectEngagement(Number(engagementId)));
    const market = useSelector(selectMarket(marketId));
    const lastEngagementOrder = useSelector(selectLastEngagementOrder);

    useEffect(() => {
        if (market) {
            if (!engagement) {
                setInternalNumber(
                    `${market.internalNumber}E${formatNumberWithLeadingZeros(
                        Number(lastEngagementOrder) + 1,
                        3,
                    )}`,
                );
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- engagement.order can be equal to 0. We want to pass inside the condition even if order === 0, which s nullish
                if (engagement.order !== null && engagement.order !== undefined) {
                    setInternalNumber(
                        `${market.internalNumber}E${formatNumberWithLeadingZeros(
                            engagement.order,
                            3,
                        )}`,
                    );
                }
            }
        }
    }, [engagement, engagementId, lastEngagementOrder, market, marketId]);

    return internalNumber;
};

export default useEngagementInternalNumber;
