import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import type { FieldArrayRenderProps } from 'formik';
import { useField } from 'formik';
import { useSelector } from 'react-redux';

import PermissionAwareText from './PermissionAwareText';
import type { ColProps } from './GridCol';
import GridCol from './GridCol';
import GridRow from './GridRow';
import Text from './Text';
import Button from './Button';
import FormikOptionDropdown from './dropdown/FormikOptionDropdown';
import PermissionAwareDisplay from './PermissionAwareDisplay';
import Icon from './Icon';

import { styles } from '../constants/styles';
import { colors } from '../constants/colors';

import type { Permission } from '../slices/authSlice';
import type { IndexSetting } from '../slices/common';
import { selectPriceIndexes } from '../slices/priceIndexSlice';

type PriceVariationIndexLineProps = {
    first: boolean;
    last: boolean;
};
const PriceVariationIndexLine = styled.div<PriceVariationIndexLineProps>`
    display: flex;
    ${({ first, last }) => {
        if (first && last) {
            return 'margin: 1rem 0 1rem 0;';
        } else if (first) {
            return 'margin: 1rem 0 0 0;';
        } else if (last) {
            return 'margin: 0 0 1rem 0;';
        }
    }}
`;

const PriceVariationIndexesTable = styled.div`
    background: ${colors.neutral.N25};
    border-radius: ${styles.borderRadiusSmall};
    border: 0.09375rem solid ${colors.neutral.N75};
    margin: 1rem 0;
`;

const PriceVariationIndexesTableHeader = styled(GridRow)`
    div > p {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    border-bottom: 0.09375rem solid ${colors.neutral.N75};
`;

type ColPropsForPriceVariationSettings = ColProps & { $isEditMode?: boolean };

const StyledPriceVariationSettings = styled(GridCol)<ColPropsForPriceVariationSettings>`
    display: flex;
    flex-direction: row;
    align-items: center;
    > p {
        width: 2rem;
        ${({ $isEditMode }) => !$isEditMode && 'margin: 0'}
    }
    .bp4-form-group {
        margin-bottom: 0.6rem !important;
    }
    .dropdown-error,
    .dropdown {
        margin: 0;
        p {
            margin: 0 !important;
        }
    }
`;

const StyledButton = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const IconWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-self: center;
    margin-bottom: 0.6rem !important;
`;

type Props = {
    isEditMode?: boolean;
    hasRightToEdit: boolean;
    permissionsNeeded: Permission[];
    arrayHelpers: FieldArrayRenderProps;
    factorError?: string;
};

const PriceVariationIndexSettings: React.FC<Props> = ({
    isEditMode,
    permissionsNeeded,
    arrayHelpers,
    hasRightToEdit,
    factorError,
}) => {
    const { t } = useTranslation();
    const [hovered, setHovered] = useState<number | null>(null);
    const priceIndexes = useSelector(selectPriceIndexes).map(({ name, id }) => ({
        value: id,
        label: name,
    }));
    const [field] = useField('priceVariationSettings.indexSettings');

    // useEffect needed to allow add first index / value only on mount
    useEffect(() => {
        if (field.value.length === 0) {
            arrayHelpers.push({
                priceIndexId: null,
                factor: null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to create a line on mount
    }, [field.value.length]);

    return (
        <PriceVariationIndexesTable>
            <GridCol defaultScreen={12}>
                <PriceVariationIndexesTableHeader>
                    <GridCol defaultScreen={4}>
                        <Text color={colors.neutral.N400} type="small" uppercased>
                            {t('budget.priceVariations.index')}
                        </Text>
                    </GridCol>
                    <GridCol defaultScreen={3}>
                        <Text color={colors.neutral.N400} type="small" uppercased>
                            {t('budget.priceVariations.coefficient')}
                        </Text>
                    </GridCol>
                </PriceVariationIndexesTableHeader>
            </GridCol>
            {field.value.length > 0
                ? field.value.map((indexSetting: IndexSetting, index: number) => (
                      <PriceVariationIndexLine
                          key={indexSetting.id ?? index}
                          first={index === 0}
                          last={index === field.value.length - 1}
                      >
                          <StyledPriceVariationSettings defaultScreen={4}>
                              <>
                                  <Text color={colors.neutral.N300}>{`I${index + 1}`}</Text>
                                  {isEditMode && hasRightToEdit && priceIndexes.length > 0 ? (
                                      <FormikOptionDropdown
                                          items={priceIndexes.filter(
                                              ({ value }) => value !== indexSetting.priceIndexId,
                                          )}
                                          name={`priceVariationSettings.indexSettings[${index}].priceIndexId`}
                                          initialNameDiplay={
                                              priceIndexes.find(
                                                  ({ value }) =>
                                                      value === indexSetting.priceIndexId,
                                              )?.label ?? t('budget.priceVariations.index')
                                          }
                                          data-testid={`priceVariationSettings.indexSettings[${index}].priceIndexId`}
                                          errorTestId={`errorPriceVariationSettings.indexSettings[${index}].priceIndexId`}
                                          filterable
                                      />
                                  ) : (
                                      <PermissionAwareDisplay
                                          data-testid={`priceVariationSettings.indexSettings[${index}].priceIndexId`}
                                          value={
                                              priceIndexes.find(
                                                  ({ value }) =>
                                                      value === indexSetting.priceIndexId,
                                              )?.label ?? '-'
                                          }
                                          style={{ marginTop: '0' }}
                                      />
                                  )}
                              </>
                          </StyledPriceVariationSettings>
                          <StyledPriceVariationSettings defaultScreen={3} $isEditMode={isEditMode}>
                              <>
                                  <Text color={colors.neutral.N300}>{`a${index + 1}`}</Text>
                                  <PermissionAwareText
                                      marginTop="0"
                                      editable={isEditMode}
                                      permissionsRequired={permissionsNeeded}
                                      name={`priceVariationSettings.indexSettings[${index}].factor`}
                                      type="text"
                                      data-testid={`priceVariationSettings.indexSettings[${index}].factor`}
                                      errorTestId={`errorPriceVariationSettings.indexSettings[${index}].factor`}
                                      numberType="factor"
                                      overrideErrorMessage={factorError}
                                      noMarginTop
                                      whiteBackground
                                  />
                              </>
                          </StyledPriceVariationSettings>
                          {index !== 0 && isEditMode ? (
                              <StyledPriceVariationSettings defaultScreen={1}>
                                  <IconWrapper
                                      onMouseEnter={() => setHovered(index)}
                                      onMouseLeave={() => setHovered(null)}
                                  >
                                      <Icon
                                          color={
                                              hovered === index
                                                  ? colors.red.R500
                                                  : colors.neutral.N300
                                          }
                                          name="DeleteOutline"
                                          onClick={() => {
                                              arrayHelpers.remove(index);
                                              setHovered(null);
                                          }}
                                          data-testid="deleteLabelIcon"
                                      />
                                  </IconWrapper>
                              </StyledPriceVariationSettings>
                          ) : null}
                          {index === field.value.length - 1 &&
                          field.value.length < 8 &&
                          isEditMode ? (
                              <GridCol defaultScreen={4}>
                                  <StyledButton>
                                      <Button
                                          iconName="Add"
                                          aspect="onlyIcon"
                                          onClick={() =>
                                              arrayHelpers.push({
                                                  priceIndexId: null,
                                                  factor: null,
                                              })
                                          }
                                      />
                                  </StyledButton>
                              </GridCol>
                          ) : null}
                      </PriceVariationIndexLine>
                  ))
                : null}
        </PriceVariationIndexesTable>
    );
};

export default PriceVariationIndexSettings;
