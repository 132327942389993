import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';

const Container = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const NotFound: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const urlNotFound = window.location.href.split('?')[1];
    let urlToRedirect = '/';
    if (urlNotFound) {
        urlToRedirect = urlNotFound.substr(0, urlNotFound.lastIndexOf('/'));
    }

    return (
        <>
            <Helmet>
                <title>{t('errors.notFoundTitle')}</title>
            </Helmet>
            <Container>
                <p>{t('errors.notFoundMessage')}</p>
                <Button text={t('general.goBack')} onClick={() => navigate(urlToRedirect)} />
            </Container>
        </>
    );
};

export default NotFound;
