import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';

export const Container = styled.p`
    font-family: Rubik;
    color: ${colors.red.R300};
    font-size: 0.6875rem;
    text-align: left;
    margin: 0.5rem 0 0 0;
`;

type Props = {
    'data-testid'?: string;
};

const FieldErrorMessage: React.FC<PropsWithChildren<Props>> = (props) => <Container {...props} />;

export default FieldErrorMessage;
