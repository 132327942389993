import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { useParams } from 'react-router-dom';
import throttle from 'lodash.throttle';

import BudgetLine from './BudgetLine';
import Text from './Text';
import EmptyState from './EmptyState';
import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import Modal, { GlobalStyle as ModalGlobalStyle } from './Modal';
import { GlobalStyle as PopoverGlobalStyle } from './Popover';
import { showFlag } from './Flag';

// Data
import type { BudgetLine as BudgetLineEntity } from '../slices/budgetLineSlice';
import {
    selectError,
    selectBudgetLineIdDeleted,
    resetBudgetLineDeleted,
    resetBudgetLineConvertedToGroup,
    getBudgetLines,
    selectBudgetLineConvertedToGroup,
    selectTopBudgetLines,
    getBudgetLinesForRefDropdown,
    selectIsLoading,
    updateBudgetLineFromMove,
    selectBudgetLine,
} from '../slices/budgetLineSlice';
import { getBudget, selectBudget } from '../slices/budgetSlice';
import type { RootState } from '../store';
import { useAppDispatch } from '../store';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
`;

const BudgetLinesHeader = styled.div<{ isEditMode?: boolean }>`
    @media print {
        margin-bottom: 0;
    }
    display: flex;
    width: 100%;
    background-color: ${colors.neutral.N75};
    border-radius: ${styles.borderRadiusSmall};
    opacity: 0.7;
    p {
        font-weight: 500;
        margin: 0.5rem 0;
    }
    margin-bottom: ${({ isEditMode }) => (isEditMode ? '0.25rem' : '0.5rem')};
    padding: ${({ isEditMode }) => (isEditMode ? '0 2.5rem 0 1rem' : '0 1rem')};
`;

const StyledCol = styled.div<{ width?: string }>`
    @media print {
        width: ${({ width }) => (width === '6vw' ? `6vw !important;` : `20vw !important;`)};
    }
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    ${({ width }) => width && `width: ${width};`}
`;

const GlobalStyleForTabsInModal = createGlobalStyle`
    .bp4-tab-list {
        display: flex;
        align-items: center;
        justify-content: center;
    };
    .bp4-heading {
        display: flex;
        justify-content: center;
    };
    .bp4-tab-indicator {
        display: none;
    };
    .bp4-tab {
        display: flex;
        align-items: center;
    };
    .bp4-radio {
        margin-right: 1.2rem;
    };
    .bp4-control input:checked ~ .bp4-control-indicator {
        background-color: ${colors.blue.B500};
    }
    .bp4-popover {
        display: contents;
        border: 0.0625rem solid ${colors.neutral.N75};
        .bp4-popover-content {
            border-radius: ${styles.borderRadiusMedium};
        }
    }
`;

type Props = {
    isEditMode: boolean;
};

const BudgetLines: React.FC<Props> = ({ isEditMode }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const store = useStore<RootState>();

    const { operationId, budgetId }: { operationId?: string; budgetId?: string } = useParams();

    const [isPending, setIsPending] = useState<boolean>(false);
    const [expandedLineIds, setExpandedLineIds] = useState<number[]>([]);
    const [
        alertMovingLineIsChangingGroupModalOptions,
        setAlertMovingLineIsChangingGroupModalOptions,
    ] = useState<{ text: string; callback: () => void } | null>(null);

    // Messages
    const serverError = useSelector(selectError);
    const budgetLineIdDeleted = useSelector(selectBudgetLineIdDeleted);
    const budgetLineConvertedToGroup = useSelector(selectBudgetLineConvertedToGroup);
    const isLoading = useSelector(selectIsLoading);
    const budgetLinesData: BudgetLineEntity[] = useSelector(selectTopBudgetLines);
    const budget = useSelector(selectBudget(Number(budgetId)));

    const successMessage = t('general.success');
    const errorMessage = t('errors.error');
    const savedMessage = t('budget.lineSaved');
    const convertedMessage = t('budget.convertedToGroupLineSuccess');
    const deletedMessage = t('budget.lineDeleted');
    let serverErrorMessage = '';

    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    useEffect(() => {
        if (isPending && !isLoading) {
            if (serverError) {
                showFlag('error', t('errors.error'), serverErrorMessage);
                setIsPending(false);
            } else {
                setIsPending(false);
                void dispatch(
                    getBudgetLines({
                        operationId: Number(operationId),
                        budgetId: Number(budgetId),
                        all: true,
                    }),
                );
            }
        }
    }, [isPending, isLoading, serverError, t, dispatch, operationId, budgetId, serverErrorMessage]);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react-hooks/exhaustive-deps
    const throttledGetLinesForDropdown = useCallback(
        throttle(
            () => {
                void dispatch(
                    getBudgetLinesForRefDropdown({
                        operationId: Number(operationId),
                        budgetId: Number(budgetId),
                    }),
                );
            },
            5000,
            { trailing: true },
        ),
        [],
    );

    // Initial load
    useEffect(() => {
        void dispatch(
            getBudgetLines({
                operationId: Number(operationId),
                budgetId: Number(budgetId),
            }),
        );
        throttledGetLinesForDropdown();
    }, [dispatch, operationId, budgetId, throttledGetLinesForDropdown]);

    useEffect(() => {
        // Should handle delete and converted to group
        if (budgetLineIdDeleted || budgetLineConvertedToGroup) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
            } else {
                let message = savedMessage;
                if (budgetLineConvertedToGroup) {
                    message = convertedMessage;
                }
                if (budgetLineIdDeleted) {
                    message = deletedMessage;
                }
                showFlag('success', successMessage, message);
                void dispatch(
                    getBudget({ id: Number(budgetId), operationId: Number(operationId) }),
                );
                void dispatch(
                    getBudgetLines({
                        operationId: Number(operationId),
                        budgetId: Number(budgetId),
                        all: true,
                    }),
                );

                throttledGetLinesForDropdown();
            }
            if (budgetLineConvertedToGroup) {
                dispatch(resetBudgetLineConvertedToGroup());
            }
            if (budgetLineIdDeleted) {
                dispatch(resetBudgetLineDeleted());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [serverError, budgetLineIdDeleted, budgetLineConvertedToGroup]);

    const getOldParentIdOfMovingLine = (
        lineId: BudgetLineEntity['id'],
    ): BudgetLineEntity['parentId'] => {
        const line = selectBudgetLine(lineId)(store.getState()) as BudgetLineEntity;
        const oldParentId = line.parentId;
        return oldParentId;
    };

    const getNewParentIdOfMovingLine = (result: DropResult): BudgetLineEntity['parentId'] => {
        let newParentId: BudgetLineEntity['parentId'] = null;
        if (result.combine) {
            newParentId = Number(result.combine.draggableId);
        } else if (result.destination) {
            if (result.destination.droppableId !== 'root') {
                newParentId = Number(result.destination.droppableId);
            }
        }
        return newParentId;
    };

    const checkIfBudgetLineChangingGroup = (
        oldParentId: BudgetLineEntity['parentId'],
        newParentId: BudgetLineEntity['parentId'],
    ) => oldParentId !== newParentId;

    const getNewIndexOfMovingLine = (result: DropResult): BudgetLineEntity['index'] | undefined => {
        let newLineIndex;
        if (result.destination) {
            const newNumeralIndex = result.destination.index;
            if (result.destination.droppableId === 'root') {
                newLineIndex = String(newNumeralIndex + 1);
            } else {
                const targetLineId = Number(result.destination.droppableId);
                const targetParentLine = selectBudgetLine(targetLineId)(
                    store.getState(),
                ) as BudgetLineEntity;
                newLineIndex = `${targetParentLine.index}.${newNumeralIndex + 1}`;
            }
        }
        return newLineIndex;
    };

    const updateBudgetLineInDb = (
        lineId: BudgetLineEntity['id'],
        newParentId: BudgetLineEntity['parentId'],
        newLineIndex: BudgetLineEntity['index'],
    ) => {
        void dispatch(
            updateBudgetLineFromMove({
                budgetLine: { id: lineId, parentId: newParentId, index: newLineIndex },
                operationId: Number(operationId),
                budgetId: Number(budgetId),
            }),
        );
        setIsPending(true);
    };

    const applyChangesDueToLineMove = (
        result: DropResult,
        lineId: BudgetLineEntity['id'],
        newParentId: BudgetLineEntity['parentId'],
    ) => {
        const newLineIndex = getNewIndexOfMovingLine(result);
        if (newLineIndex) {
            updateBudgetLineInDb(lineId, newParentId, newLineIndex);
        }
    };

    const onDragEnd = (result: DropResult) => {
        const lineId = Number(result.draggableId);
        const oldParentId = getOldParentIdOfMovingLine(lineId);
        const newParentId = getNewParentIdOfMovingLine(result);

        if (checkIfBudgetLineChangingGroup(oldParentId, newParentId)) {
            setAlertMovingLineIsChangingGroupModalOptions({
                text: 'errors.movingLineIsChangingGroup',
                callback() {
                    setAlertMovingLineIsChangingGroupModalOptions(null);
                    applyChangesDueToLineMove(result, lineId, newParentId);
                },
            });
        } else {
            applyChangesDueToLineMove(result, lineId, newParentId);
        }
    };

    return (
        <Container>
            <ModalGlobalStyle />
            <GlobalStyleForTabsInModal />
            <PopoverGlobalStyle />
            {budgetLinesData.length > 0 ? (
                <>
                    <BudgetLinesHeader isEditMode={isEditMode}>
                        <StyledCol style={{ flex: '1' }}>
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {t('budget.headerGroupAndBudgetLine')}
                            </Text>
                        </StyledCol>
                        <StyledCol width="7vw" />
                        <StyledCol
                            style={{ justifyContent: 'start', paddingLeft: '3vw' }}
                            width="10vw"
                        >
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {t('budget.headerReference')}
                            </Text>
                        </StyledCol>
                        <StyledCol style={{ justifyContent: 'flex-end' }} width="13vw">
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {t('budget.headerAmountHt')}
                            </Text>
                        </StyledCol>
                        <StyledCol style={{ justifyContent: 'center' }} width="6vw">
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {t('budget.headerTva')}
                            </Text>
                        </StyledCol>
                        <StyledCol style={{ justifyContent: 'flex-end' }} width="13vw">
                            <Text type="small" color={colors.neutral.N400} uppercased>
                                {t('budget.headerAmountTtc')}
                            </Text>
                        </StyledCol>
                        {budget?.status === 'approved' ? (
                            <StyledCol style={{ justifyContent: 'flex-end' }} width="15vw">
                                <Text type="small" color={colors.neutral.N400} uppercased>
                                    {t('budget.headerEngagementAmountTTC')}
                                </Text>
                            </StyledCol>
                        ) : null}
                    </BudgetLinesHeader>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="root" isCombineEnabled>
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {budgetLinesData.map((item, index) => (
                                        <BudgetLine
                                            key={`line-${item.id}`}
                                            id={item.id}
                                            index={index}
                                            onDragEnd={onDragEnd}
                                            isEditMode={isEditMode}
                                            isDraggingOver={snapshot.isDraggingOver}
                                            throttledGetLinesForDropdown={
                                                throttledGetLinesForDropdown
                                            }
                                            expandedLineIds={expandedLineIds}
                                            setExpandedLineIds={(
                                                id: number,
                                                type: 'add' | 'remove',
                                            ) => {
                                                if (type === 'add') {
                                                    setExpandedLineIds([...expandedLineIds, id]);
                                                } else {
                                                    setExpandedLineIds(
                                                        expandedLineIds.filter(
                                                            (listId) => listId !== id,
                                                        ),
                                                    );
                                                }
                                            }}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {isEditMode ? <BudgetLine id={null} index={-1} isEditMode /> : null}
                </>
            ) : (
                <EmptyState
                    imageName="Lines"
                    titleTranslationKey="budget.noLine"
                    button={
                        isEditMode ? (
                            <BudgetLine id={null} index={-1} isFirstBudgetLine isEditMode />
                        ) : null
                    }
                />
            )}
            {alertMovingLineIsChangingGroupModalOptions && (
                <Modal
                    isOpen={alertMovingLineIsChangingGroupModalOptions.text !== ''}
                    onCloseButtonPressed={() => setAlertMovingLineIsChangingGroupModalOptions(null)}
                    buttons={[
                        {
                            text: t('general.cancel'),
                            aspect: 'secondary',
                            'data-testid': 'cancelDelete',
                            onClick() {
                                setAlertMovingLineIsChangingGroupModalOptions(null);
                            },
                        },
                        {
                            text: t('general.confirm'),
                            aspect: 'primary',
                            'data-testid': 'confirm',
                            onClick: alertMovingLineIsChangingGroupModalOptions.callback,
                        },
                    ]}
                    title={t('budget.movingLine')}
                    size="small"
                    iconName="CheckCircle"
                    iconColor={colors.green.G400}
                    shouldRenderGlobalStyle={false}
                >
                    <Text style={{ margin: '0 0 1rem 0' }}>
                        {t(alertMovingLineIsChangingGroupModalOptions.text)}
                    </Text>
                </Modal>
            )}
        </Container>
    );
};

export default BudgetLines;
