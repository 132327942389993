import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { OperationAffectedToUser, User } from '../slices/userSlice';
import Table from './Table';
import UserRolesBadges from './UserRolesBadges';

type Props = { operations: OperationAffectedToUser[]; userRoles?: User['roles'] };

const OperationsAffectedToUser: React.FC<Props> = ({ operations, userRoles }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const headers = {
        operationNumber: t('user.profile.operationsAffected.operation.number'),
        operationLabel: t('user.profile.operationsAffected.operation.label'),
        projectOwnerOrganizationDenomination: t('user.profile.operationsAffected.projectOwner'),
        city: t('user.profile.operationsAffected.operation.city'),
        roles: t('user.profile.operationsAffected.roles'),
    };

    const renderCellMap = {
        operationNumber: (
            operationAffectedToUser: OperationAffectedToUser | Partial<OperationAffectedToUser>,
        ) => operationAffectedToUser.operation?.internalNumber ?? '',
        operationLabel: (
            operationAffectedToUser: OperationAffectedToUser | Partial<OperationAffectedToUser>,
        ) => operationAffectedToUser.operation?.label ?? '',
        projectOwnerOrganizationDenomination: (
            operationAffectedToUser: OperationAffectedToUser | Partial<OperationAffectedToUser>,
        ) => operationAffectedToUser.projectOwnerOrganization?.denomination ?? '',
        city: (
            operationAffectedToUser: OperationAffectedToUser | Partial<OperationAffectedToUser>,
        ) =>
            operationAffectedToUser.operation?.postalCode && operationAffectedToUser.operation.city
                ? `${operationAffectedToUser.operation.postalCode} ${operationAffectedToUser.operation.city}`
                : '',
        roles(operationAffectedToUser: OperationAffectedToUser | Partial<OperationAffectedToUser>) {
            if (operationAffectedToUser.operation?.id && userRoles && userRoles.length > 0) {
                const operationId = operationAffectedToUser.operation.id;
                const authUserRolesOnOperation = userRoles.filter(
                    (userRole) => userRole.userRoleOperationId === operationId,
                );
                return <UserRolesBadges userRoles={authUserRolesOnOperation} />;
            } else {
                return null;
            }
        },
    };

    const handleOnOperationClick = (
        operationAffectedToUser: OperationAffectedToUser | Partial<OperationAffectedToUser>,
    ) => navigate(`/operations/${operationAffectedToUser.operation?.id}`);

    return (
        <Table
            headers={headers}
            rows={operations}
            columnSizes="1fr 2fr 2fr 1.5fr 2fr"
            renderCellMap={renderCellMap}
            onRowClick={handleOnOperationClick}
            data-testid="operationsAffectedTableList"
            fixedEndColumnsNumber={1}
            isFixedHeader
        />
    );
};

export default OperationsAffectedToUser;
