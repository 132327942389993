import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import GridRow from '../../components/GridRow';
import GridCol from '../../components/GridCol';
import OperationList from '../../components/OperationList';

import type { Operation } from '../../slices/operationSlice';
import {
    selectOperationsByPage,
    selectTotalOperations,
    getOperations,
    selectGetOperationsLoading,
} from '../../slices/operationSlice';

import type { QueryParams, ListViewType } from '../../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../../slices/common';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import Pagination from '../../components/Pagination';
import SearchField from '../../components/SearchField';
import Button from '../../components/Button';
import EmptyState from '../../components/EmptyState';
import { usePageNumber } from '../../hooks/usePageNumber';
import Loader from '../../components/Loader';
import { useAppDispatch } from '../../store';

const ViewToggle = styled.div`
    display: flex;
    div:first-child {
        margin: 0 1rem 0 0;
    }
    justify-content: flex-end;
`;

type Props = {
    headerProps: HeaderProps;
    showAddButton?: boolean;
    showRolesOfAuthUser?: boolean;
    showAffectationType?: boolean;
};

const Operations: React.FC<Props> = ({
    headerProps,
    showAddButton = true,
    showRolesOfAuthUser = false,
    showAffectationType = false,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const {
        organizationId,
    }: {
        organizationId?: string;
    } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const pageNumber = usePageNumber();
    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: pageNumber,
        organizationId,
    });

    const state = {
        goBackTo: `${location.pathname}${location.search}`,
        organizationId: organizationId ?? undefined,
    };
    const [viewType, setViewType] = useState<ListViewType>(
        localStorage.getItem('operations_list_type') === 'table' ? 'table' : 'card',
    );
    const hasRightToAddOperations = usePermissionsCheck([
        {
            code: 'OPERATIONS_ADD',
        },
    ]);
    const totalOperations = useSelector(selectTotalOperations);
    const createViewClickHandler = (type: 'card' | 'table') => () => {
        setViewType(type);
        localStorage.setItem('operations_list_type', type);
    };

    useEffect(() => {
        void dispatch(getOperations(queryParams));
    }, [dispatch, queryParams]);

    const operations = useSelector(selectOperationsByPage(queryParams.page)) as Operation[] | null;
    const isLoading = useSelector(selectGetOperationsLoading);

    const handleSearch = (value: string) => {
        if (value === '') {
            setQueryParams({ page: 1, organizationId });
        } else {
            setQueryParams({
                page: 1,
                query: value,
                match: 'any',
                fields: [
                    'internal_number',
                    'label',
                    'long_label',
                    'postal_code',
                    'city',
                    'clientOrganization.denomination',
                    'clientOrganization.denomination_usuelle',
                    'clientOrganization.denomination_usuelle1',
                    'projectOwnerOrganization.denomination',
                    'projectOwnerOrganization.denomination_usuelle',
                    'projectOwnerOrganization.denomination_usuelle1',
                    'delegateProjectOwnerOrganization.denomination',
                    'delegateProjectOwnerOrganization.denomination_usuelle',
                    'delegateProjectOwnerOrganization.denomination_usuelle1',
                ],
                orderBy: 'label',
                order: 'asc',
                pageSize: DEFAULT_ITEMS_PER_PAGE,
                organizationId,
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('sidebar.operations')}</title>
            </Helmet>
            <MainLayout
                header={
                    <Header
                        {...headerProps}
                        buttons={
                            hasRightToAddOperations && showAddButton
                                ? [
                                      {
                                          'data-testid': 'addOperationButton',
                                          text: t('operation.addOperation'),
                                          aspect: 'primary',
                                          onClick: () =>
                                              navigate(`${location.pathname}/new/settings`, {
                                                  state,
                                              }),
                                      },
                                  ]
                                : []
                        }
                        style={{
                            marginBottom: '1rem',
                        }}
                        showNavigation
                    />
                }
                footer={
                    totalOperations > 0 ? (
                        <Pagination
                            data-testid="pagination"
                            initialPage={queryParams.page}
                            currentPage={queryParams.page}
                            pageNeighbours={2}
                            nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                            nbItemsTotal={totalOperations}
                            onPageNumberClicked={(page: number) =>
                                setQueryParams({
                                    ...queryParams,
                                    page,
                                })
                            }
                            pageUrl="operations"
                        />
                    ) : null
                }
                smallContentSidePadding
            >
                <GridRow
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    <GridCol smallScreen={10} defaultScreen={4}>
                        <SearchField
                            name="operationResearch"
                            data-testid="operationsSearch"
                            onSearch={handleSearch}
                        />
                    </GridCol>
                    <GridCol smallScreen={2} defaultScreen={8}>
                        <ViewToggle>
                            <Button
                                onClick={createViewClickHandler('table')}
                                iconName="ViewList"
                                isPressed={viewType === 'table'}
                                data-testid="tableViewButton"
                                aspect="onlyIcon"
                                toggle
                            />
                            <Button
                                onClick={createViewClickHandler('card')}
                                iconName="ViewModule"
                                isPressed={viewType === 'card'}
                                data-testid="cardViewButton"
                                aspect="onlyIcon"
                                toggle
                            />
                        </ViewToggle>
                    </GridCol>
                </GridRow>
                {!isLoading ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment -- necessary fragment
                    <>
                        {operations && operations.length > 0 ? (
                            <OperationList
                                viewType={viewType}
                                operations={operations}
                                showRolesOfAuthUser={showRolesOfAuthUser}
                                showAffectationType={showAffectationType}
                                locationState={state}
                            />
                        ) : (
                            <EmptyState
                                data-testid="errorNoOperations"
                                imageName="Operation"
                                titleTranslationKey="errors.noOperationYouHaveAccessTo"
                            />
                        )}
                    </>
                ) : (
                    <Loader />
                )}
            </MainLayout>
        </>
    );
};

export default Operations;
