import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';
import Footer from '../../components/Footer';
import Logo from '../../components/Logo';
import PublicPageLayout from '../../components/PublicPageLayout';
import Text from '../../components/Text';

const CheckContainer = styled.img`
    align-self: center;
    margin-bottom: 2.5em;
`;

const Center = styled.div`
    -ms-grid-row: 1;
    grid-area: content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ResetPasswordSuccess: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('resetedPassword.confirmation')}</title>
            </Helmet>
            <PublicPageLayout>
                <Center>
                    <Logo />
                    <Text type="H700" style={{ marginTop: '1.875rem' }}>
                        {t('resetedPassword.confirmation')}
                    </Text>
                    <CheckContainer
                        src="assets/green_check_logo.svg"
                        alt={t('resetedPassword.successLogoAlt')}
                    />
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Button
                            size="large"
                            type="submit"
                            aspect="primary"
                            data-testid="button"
                            text={t('resetedPassword.goBack')}
                        />
                    </Link>
                </Center>
                <Footer />
            </PublicPageLayout>
        </>
    );
};

export default ResetPasswordSuccess;
