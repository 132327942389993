import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import type { FieldMetaProps } from 'formik';

import { MenuItem } from '@blueprintjs/core';
import type { ISelectProps as Props, IItemRendererProps } from '@blueprintjs/select';
import { Select as BPSelect } from '@blueprintjs/select';

import Button from '../Button';
import { SelectStyle } from './dropdownStyle';
import type { Option } from '../SelectField';
import { StyledLabel } from '../PermissionAwareDisplay';
import FieldErrorMessage from '../FieldErrorMessage';
import Icon from '../Icon';

import { colors } from '../../constants/colors';

export type StatusOption = Option<string | number>;

export type ISelectProps<T> = {
    'data-testid'?: string;
    emptyLabelSpace?: boolean;
    errorTestId?: string;
    filterable?: Props<T>['filterable'];
    handleChange?: (option: T) => void;
    initialNameDiplay: string | React.ReactNode;
    itemPredicate?: Props<T>['itemPredicate'];
    itemRenderer?: Props<T>['itemRenderer'];
    items: Props<T>['items'];
    label?: string;
    name: string;
    noResults?: Props<T>['noResults'];
    onItemSelect?: Props<T>['onItemSelect'];
    style?: React.CSSProperties;
    marginBottom?: boolean;
    buttonPadding?: string;
    buttonBorder?: string;
    isOptional?: boolean;
    disabled?: boolean;
    children?: React.ReactChildren;
    onValueChange?: (value: StatusOption['value']) => void;
    formikMeta?: FieldMetaProps<unknown>;
};

const OptionDropdown = <T extends StatusOption>({
    children,
    items,
    initialNameDiplay,
    label,
    name,
    emptyLabelSpace = false,
    errorTestId,
    style,
    marginBottom = false,
    buttonPadding,
    buttonBorder,
    isOptional,
    disabled,
    onValueChange,
    formikMeta,
    ...props
}: ISelectProps<T>) => {
    const { t } = useTranslation();
    // Define state for handling field text change
    const [nameDisplay, setNameDisplay] = useState<string | React.ReactNode>(initialNameDiplay);

    const testId = props['data-testid'] ?? 'test';
    const fieldLabel = isOptional ? `${label} ${t('general.optional')}` : label;

    // Handle change of nameDisplay to modify select button inside text
    useEffect(() => {
        setNameDisplay(initialNameDiplay);
    }, [initialNameDiplay]);

    // On select item click set nameDisplay to clicked item
    const handleValueChange = (statusOption: T) => {
        setNameDisplay(statusOption.label);
        onValueChange?.(statusOption.value);
        if (props.handleChange) {
            props.handleChange(statusOption);
        }
    };

    // Render items inside select
    const renderData = (statusOption: T, { handleClick }: IItemRendererProps) => (
        <MenuItem
            key={statusOption.value}
            text={statusOption.label}
            onClick={handleClick}
            tabIndex={0}
            data-testid={`${testId}-dropdown-item`}
            className="toto"
        />
    );

    const predicateOptionData = (
        query: string,
        item: T,
        _index: number | undefined,
        exactMatch: boolean | undefined,
    ) => {
        const normalizedTitle = item.label?.toString().toLowerCase() ?? '';
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return normalizedTitle.indexOf(normalizedQuery) >= 0;
        }
    };

    const computeButtonMargins = () => {
        let buttonMargins: { marginTop?: string; marginBottom?: string } = {};
        if (emptyLabelSpace) {
            buttonMargins = { marginTop: '1.4rem', marginBottom: '0.6rem' };
        } else {
            buttonMargins = { marginBottom: '0.6rem' };
        }
        if (!marginBottom) {
            buttonMargins.marginBottom = '0';
        }
        return buttonMargins;
    };

    return (
        <div
            className={`dropdown${formikMeta?.error && formikMeta.touched ? '-error' : ''}`}
            data-testid={`${testId}-dropdown`}
            style={style}
        >
            {label && (
                <StyledLabel color={colors.neutral.N300} style={{ marginBottom: '0.3125rem' }}>
                    {fieldLabel}
                </StyledLabel>
            )}
            <SelectStyle maxHeight="15rem" />
            <BPSelect<T>
                items={items}
                itemRenderer={props.itemRenderer ?? renderData}
                noResults={props.noResults ?? <MenuItem text={t('general.noResults')} disabled />}
                onItemSelect={props.onItemSelect ?? handleValueChange}
                data-testid={`${testId}-select`}
                itemPredicate={props.itemPredicate ?? predicateOptionData}
                filterable={props.filterable ?? false}
                inputProps={{
                    leftIcon: null,
                    leftElement: <Icon name="Search" style={{ width: '1.5rem' }} />,
                    placeholder: t('general.search'),
                }}
                disabled={disabled}
            >
                <Button
                    aspect="dropdown"
                    iconName="ArrowDropDown"
                    iconSize="1.25rem"
                    text={nameDisplay}
                    data-testid={`${testId}-dropdown-button`}
                    style={computeButtonMargins()}
                    disabled={disabled}
                    padding={buttonPadding}
                    border={buttonBorder}
                />
            </BPSelect>
            {formikMeta?.error && formikMeta.touched && (
                <FieldErrorMessage data-testid={errorTestId}>{formikMeta.error}</FieldErrorMessage>
            )}
        </div>
    );
};

export default OptionDropdown;
