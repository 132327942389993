import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';

// Components
import GridCol from './GridCol';
import GridRow from './GridRow';
import Text from './Text';
import type { StatusProps } from './Status';
import Status from './Status';
import Badge from './Badge';
import SearchField from './SearchField';

const Container = styled(GridRow)`
    background-color: ${colors.other.white};
    border-radius: ${styles.borderRadiusSmall};
    padding: 1.5rem 1rem;
    margin-bottom: 2rem;
`;

const StatusColumn = styled(GridCol)`
    margin-bottom: 1.875rem;
    display: flex;
    justify-content: flex-end;
`;

type Props = {
    headerTrailingComponent?: React.ReactNode;
    bigHeaderSpace?: boolean;
    status?: StatusProps['status'];
    showStatus?: boolean;
    subtitle?: string | null;
    title: string;
    showBadge?: boolean;
    badgeText?: string | number;
    badgeTextColor?: string;
    badgeBackground?: string;
    showSearchBar?: boolean;
    handleSearch?: (value: string) => void;
    style?: React.CSSProperties;
};

const InformationCard: React.FC<PropsWithChildren<Props>> = ({
    children,
    headerTrailingComponent,
    bigHeaderSpace = true,
    status = 'inactive',
    showStatus = false,
    subtitle = null,
    showBadge = false,
    badgeText = '',
    badgeTextColor = '',
    badgeBackground = '',
    showSearchBar = false,
    handleSearch,
    title,
    style,
}) => {
    const headerSpaceSize = bigHeaderSpace ? '1.875rem' : '0.5rem';

    if (headerTrailingComponent && showStatus) {
        console.warn('headerTrailingComponent and showStatus are incompatible right now');
    }

    return (
        <Container style={style}>
            <GridCol defaultScreen={12}>
                <GridRow>
                    {title && (
                        <GridCol
                            defaultScreen="auto"
                            smallScreen="auto"
                            style={{
                                marginBottom: String(headerSpaceSize),
                                display: 'flex',
                                alignItems: 'baseline',
                            }}
                        >
                            <Text type="H500">{title}</Text>
                            &nbsp; &nbsp;
                            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- condition is useful */}
                            {subtitle && <Text type="H100">{subtitle}</Text>}
                            {showBadge && (
                                <Badge
                                    textColor={badgeTextColor}
                                    background={badgeBackground}
                                    text={badgeText}
                                    style={{
                                        display: 'flex',
                                        height: '1rem',
                                        margin: '0.875rem 0 0 0.675rem',
                                    }}
                                />
                            )}
                        </GridCol>
                    )}
                    {showSearchBar && handleSearch && (
                        <GridCol defaultScreen={3}>
                            <SearchField
                                name="operationResearch"
                                data-testid="operationsSearch"
                                onSearch={handleSearch}
                                whiteBackground
                            />
                        </GridCol>
                    )}
                    {headerTrailingComponent || showStatus ? (
                        <StatusColumn
                            defaultScreen="auto"
                            smallScreen="auto"
                            style={{ marginLeft: 'auto' }}
                        >
                            {!headerTrailingComponent ? (
                                <Status status={status} squareStyle={{ margin: '0rem 0.5rem' }} />
                            ) : (
                                headerTrailingComponent
                            )}
                        </StatusColumn>
                    ) : null}
                </GridRow>
            </GridCol>
            {children}
        </Container>
    );
};

export default InformationCard;
