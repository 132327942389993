import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type { FormikProps } from 'formik';
import { Formik, Form } from 'formik';
import { Collapse } from '@blueprintjs/core';
import * as Yup from 'yup';
import findLast from 'lodash.findlast';
import { isAfter, format } from 'date-fns';

import { validateFormFields } from '../utils/formValidation';
import { CurrencyFormatter } from '../utils/formatters';
import { calculateAmountRatio } from '../utils/calculateAmountRatio';
import type {
    Budget,
    IBudgetFormData,
    BudgetStatus,
    BudgetLabelAndCommentFormData,
} from '../slices/budgetSlice';
import {
    createBudget,
    selectError,
    selectBudgetAdded,
    updateBudget,
    deleteBudget,
    updateBudgetLabelAndComment,
} from '../slices/budgetSlice';
import type { BudgetPriceHypothesis as BudgetPriceHypothesisEntity } from '../slices/budgetPriceHypothesisSlice';
import {
    selectIsCreating as selectIsBudgetPriceHypothesisCreating,
    selectError as selectBudgetPriceHypothesisError,
    createBudgetPriceHypothesis,
    selectAllBudgetPriceHypothesis,
} from '../slices/budgetPriceHypothesisSlice';
import type { Operation } from '../slices/operationSlice';
import {
    getOperationHasEngagement,
    selectOperation,
    selectOperationHasEngagement,
} from '../slices/operationSlice';
import type { ButtonProps } from './Button';
import Button from './Button';
import SimulationButton from './SimulationButton';
import Icon from './Icon';
import GridCol from './GridCol';
import Text from './Text';
import type { StatusOption } from './dropdown/FormikOptionDropdown';
import FormikOptionDropdown from './dropdown/FormikOptionDropdown';
import BudgetInformationCard from './BudgetInformationCard';
import Status from './Status';
import { showFlag } from './Flag';
import Modal from './Modal';
import BudgetPriceHypothesis from './BudgetPriceHypothesis';
import type { EngagementMatching } from './BudgetLinesMatchingEngagementsModal';
import Tooltip, { GlobalStyle as TooltipGlobalStyle } from './Tooltip';
import PermissionAwareText from './PermissionAwareText';
import PermissionAwareTextarea from './PermissionAwareTextarea';
import GridRow from './GridRow';
import BudgetCloneChooseOperationTargetModal from './BudgetCloneChooseOperationTargetModal';
import BudgetTitleAndCommentModal from './BudgetTitleAndCommentModal';
import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import BudgetLinesMatchingEngagementsModalBeforeApprovingBudget from './BudgetLinesMatchingEngagementsModalBeforeApprovingBudget';
import PercentageBadge from './PercentageBadge';
import { useAppDispatch } from '../store';
import type { Permission } from '../slices/authSlice';

const OtherInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 0;
    width: 100%;
`;

type CollapseProps = {
    isOpen?: boolean;
};

const GridColValue = styled(GridCol)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: flex-end;
    p {
        padding: 0;
    }
`;

const InformationHeader = styled.div<CollapseProps>`
    @media print {
        border-top: 0.05px black solid;
        margin: 0;
        padding: 0.25rem;
        display: ${(formikProps) => (formikProps.isOpen ? `flex` : `none`)};
        border-radius: 0;
    }
    display: flex;
    align-items: center;
    background-color: ${colors.other.white};
    margin: 0.5rem 0 0 0;
    padding: 0.625rem 3rem 0.625rem 1rem;
    border-radius: ${(formikProps) =>
        formikProps.isOpen
            ? `${styles.borderRadiusSmall} ${styles.borderRadiusSmall} 0 0`
            : String(styles.borderRadiusSmall)};
`;

const InformationCollapseContainer = styled(Collapse)<CollapseProps>`
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
    .bp4-collapse-body {
        width: 100%;
    }
`;

const InformationCollapseBody = styled.div`
    @media print {
        margin: 0;
        padding: 0;
    }
    background-color: ${colors.other.white};
    margin: 0.15rem 0 0 0;
    padding: 1rem 3rem 1rem 3rem;
`;

const InformationCollapseFooter = styled.div`
    @media print {
        margin: 0;
        padding: 0.5rem 0;
        border-bottom: 0.05px solid black;
        border-radius: 0;
    }
    background-color: ${colors.other.white};
    margin: 0.15rem 0 0 0;
    padding: 1rem 3rem 1.25rem 3rem;
    border-radius: 0 0 ${styles.borderRadiusSmall} ${styles.borderRadiusSmall};
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem 0 0 0;
`;

const Flex = styled.div`
    display: flex;
`;

const TotalValueText = styled(Text)`
    margin: 0;
    font-weight: 700;
    font-size: 0.875rem;
`;

const SubtotalValueText = styled(Text)`
    margin: 0;
    font-weight: 400;
    font-size: 0.875rem;
`;

const TextEngagedAmountValue = styled(Text)`
    margin: 0;
    font-weight: bold;
    font-size: 0.875rem;
    color: ${colors.neutral.N300};
`;

type Props = {
    isEditMode: boolean;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    permissionNeeded: Permission;
    hasRightToEdit: boolean;
    budget: Budget;
    setIsDeletePending: (isPending: boolean) => void;
};

const BudgetGeneralInfo: React.FunctionComponent<Props> = ({
    budget,
    isEditMode,
    setIsEditMode,
    hasRightToEdit,
    permissionNeeded,
    setIsDeletePending,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const serverError = useSelector(selectError);
    const isCreatingBudgetPriceHypothesis = useSelector(selectIsBudgetPriceHypothesisCreating);
    const budgetPriceHypothesisError = useSelector(selectBudgetPriceHypothesisError);
    const budgetAdded = useSelector(selectBudgetAdded);
    const operationHasEngagements = useSelector(
        selectOperationHasEngagement(Number(budget.operationId)),
    );
    const operation = useSelector(selectOperation(Number(budget.operationId)));
    const location = useLocation();
    const hasRightToAddBudgets = usePermissionsCheck([
        {
            code: 'BUDGETS_ADD',
            operationId: budget.operationId,
        },
    ]);
    const hasRightToDeleteBudgets = usePermissionsCheck([
        {
            code: 'BUDGETS_DELETE',
            operationId: budget.operationId,
        },
    ]);

    const budgetIsEditable = budget.status !== 'approved' && budget.status !== 'replaced';

    const [isPending, setIsPending] = useState<boolean>(false);
    const [isCloningPending, setIsCloningPending] = useState<boolean>(false);
    const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);
    const [isMatchingModalOpen, setIsMatchingModalOpen] = useState<boolean>(false);
    const [engagementMatching, setEngagementMatching] = useState<EngagementMatching[] | undefined>(
        undefined,
    );
    const [modalOptions, setModalOptions] = useState<{ text: string; callback: () => void } | null>(
        null,
    );
    const [isTitleAndCommentModalOpen, setIsTitleAndCommentModalOpen] = useState<boolean>(false);
    const [isOperationListModalOpen, setIsOperationListModalOpen] = useState<boolean>(false);
    const [isCloningToTheSameOperation, setIsCloningToTheSameOperation] = useState<boolean>(false);
    const [showTtcValues, setShowTtcValues] = useState<boolean>(true);

    const state = location.state ?? {};

    useEffect(() => {
        setModalOptions(null);
        if (budget.operationId) {
            void dispatch(getOperationHasEngagement({ operationId: Number(budget.operationId) }));
        }
    }, [budget.operationId, isOperationListModalOpen, dispatch]);

    const budgetPriceHypotheses: BudgetPriceHypothesisEntity[] | undefined = useSelector(
        selectAllBudgetPriceHypothesis,
    );
    budgetPriceHypotheses.sort((a, b) => a.year - b.year);

    const defaultBudget = useMemo(
        () => ({
            operationId: Number(budget.operationId),
            label: '',
            comment: '',
            totalAmountTtc: 0,
            status: 'draft',
        }),
        [budget.operationId],
    );

    const successMessage = t('general.success');
    const errorMessage = t('errors.error');
    const savedMessage = t('budget.saved');
    let serverErrorMessage = '';
    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    useEffect(() => {
        if (
            isEditMode &&
            defaultBudget.operationId === budget.operationId &&
            defaultBudget.label === budget.label &&
            defaultBudget.comment === budget.comment &&
            defaultBudget.totalAmountTtc === budget.totalAmountTtc &&
            defaultBudget.status === budget.status
        ) {
            setIsCollapseOpen(true);
        }
    }, [isEditMode, defaultBudget, budget]);

    useEffect(() => {
        if (isPending) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
                setIsPending(false);
            } else if (budgetAdded && budget) {
                showFlag('success', successMessage, savedMessage);
                setIsPending(false);
                setIsCloningPending(false);
                setIsEditMode(false);
                navigate(`/operations/${budgetAdded.operationId}/budgets/${budgetAdded.id}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Don't want to rerender on every dependencies changes
    }, [serverError, budgetAdded]);

    useEffect(() => {
        if (isCreatingBudgetPriceHypothesis && budgetPriceHypothesisError) {
            showFlag('error', errorMessage, t(budgetPriceHypothesisError));
        }
    }, [isCreatingBudgetPriceHypothesis, budgetPriceHypothesisError, errorMessage, t]);

    const budgetSchema = Yup.object().shape({
        status: Yup.mixed()
            .oneOf(['draft', 'replaced', 'cancelled', 'approved'])
            .required(t('errors.required')),
        label: Yup.string()
            .required(t('errors.required'))
            .max(60, t('errors.tooLong', { number: 60 })),
        comment: Yup.string()
            .max(512, t('errors.tooLong', { number: 512 }))
            .nullable(),
        targetTotalAmountTtc: Yup.number(),
    });

    const cloneBudget = () => {
        const clonedBudget = {
            ...budget,
            cloneSourceId: budget.id,
            newBudgetLabel: t('budget.cloneBudgetDefaultTitle'),
        };
        void dispatch(createBudget({ budget: clonedBudget, operationId: budget.operationId }));
        setIsPending(true);
        setIsCloningToTheSameOperation(true);
        setIsCloningPending(true);
        setEngagementMatching(undefined);
    };

    const cloneBudgetToAnotherOperation = (destinationOperationId: Operation['id']) => {
        const clonedBudget = {
            ...budget,
            cloneSourceId: budget.id,
            newBudgetLabel: t('budget.cloneBudgetTitle', {
                operationNumber: operation?.internalNumber,
                budgetVersion: `${budget.majorVersionNumber}${
                    budget.minorVersionNumber ? `-${budget.minorVersionNumber}` : ''
                }`,
            }),
            newBudgetComment: t('budget.cloneBudgetComment', {
                operationNumber: operation?.internalNumber,
                operationLabel: operation?.label,
                budgetVersion: `${budget.majorVersionNumber}${
                    budget.minorVersionNumber ? `-${budget.minorVersionNumber}` : ''
                }`,
            }),
        };
        void dispatch(createBudget({ budget: clonedBudget, operationId: destinationOperationId }));
        setIsCloningToTheSameOperation(false);
        setIsPending(true);
        setIsCloningPending(true);
        setEngagementMatching(undefined);
    };

    const computeButtonsOutOfProps = (formikProps: FormikProps<IBudgetFormData>): ButtonProps[] => {
        const buttons: ButtonProps[] = [];
        if (hasRightToEdit) {
            if (isEditMode) {
                if (hasRightToDeleteBudgets) {
                    buttons.push({
                        'data-testid': 'deleteBudget',
                        aspect: 'onlyIcon',
                        iconName: 'DeleteOutline',
                        onClick() {
                            modalManager(budget.status, 'deleted', () => {
                                void dispatch(
                                    deleteBudget({
                                        id: budget.id,
                                        operationId: budget.operationId,
                                    }),
                                );
                                setIsDeletePending(true);
                            });
                        },
                    });
                }
                buttons.push(
                    {
                        text: t('general.cancel'),
                        'data-testid': 'cancelModifyBudget',
                        aspect: 'secondary',
                        onClick() {
                            navigate(location.pathname.replace('?edit', ''), {
                                state,
                                replace: true,
                            });
                            setIsEditMode(false);
                            formikProps.handleReset();
                        },
                    },
                    {
                        text: t('general.record'),
                        'data-testid': 'submitBudget',
                        aspect: 'primary',
                        async onClick() {
                            const isValid: boolean = await validateFormFields(
                                ['label'],
                                formikProps,
                            );
                            if (isValid) {
                                formikProps.handleSubmit();
                            } else {
                                setIsCollapseOpen(true);
                            }
                        },
                    },
                );
            } else {
                if (budgetIsEditable) {
                    buttons.push({
                        text: t('budget.modifyBudget'),
                        'data-testid': 'modifyBudget',
                        aspect: 'primary',
                        onClick() {
                            navigate(`${location.pathname}?edit`, { state, replace: true });
                            setIsEditMode(true);
                        },
                    });
                } else {
                    // can only edit title and comment on validated budget
                    buttons.push({
                        text: t('budget.editLabelAndComment'),
                        'data-testid': 'editLabelAndComment',
                        aspect: 'primary',
                        onClick: () => setIsTitleAndCommentModalOpen(true),
                    });
                }
            }
        }
        if (hasRightToAddBudgets && !isEditMode) {
            buttons.push(
                {
                    text: t('budget.cloneBudgetToAnotherOperation'),
                    'data-testid': 'cloneBudgetToAnotherOperation',
                    aspect: 'primary',
                    onClick: () => setIsOperationListModalOpen(true),
                    isLoading: isCloningPending && !isCloningToTheSameOperation,
                    disabled: isCloningPending,
                },
                {
                    text: t('budget.cloneBudget'),
                    'data-testid': 'cloneBudget',
                    aspect: 'primary',
                    onClick: cloneBudget,
                    isLoading: isCloningPending && isCloningToTheSameOperation,
                    disabled: isCloningPending,
                },
            );
        }
        return buttons;
    };

    const formatDate = (date: string) => format(new Date(date), 'dd/MM/yyyy');
    const formatDateForTooltip = (date: string) => format(new Date(date), 'dd/MM/yyyy HH:mm:ss');

    const getOptionalValueToDisplay = (value?: number) => value ?? 0;

    const statusOptions: StatusOption[] = [
        {
            value: 'draft',
            label: <Status status="draft" />,
        },
        {
            value: 'approved',
            label: <Status status="approved" />,
        },
        {
            value: 'cancelled',
            label: <Status status="cancelled" />,
        },
    ];

    const modalManager = (source: string, target: string, callback: () => void) => {
        let modalText = '';

        if (source === 'draft') {
            if (target === 'cancelled') {
                modalText = 'budget.draftToCancelled';
            } else if (target === 'deleted') {
                modalText = 'budget.draftToDeleted';
            } else if (target === 'approved') {
                modalText = 'budget.draftToApproved';
            }
        } else if (source === 'cancelled' && target === 'deleted') {
            modalText = 'budget.cancelledToDeleted';
        }

        if (modalText === '') {
            callback();
        } else {
            setModalOptions({ text: modalText, callback });
        }
    };

    const getFooterData = () => {
        const footerCols: Record<string, React.ReactNode> = {};

        // CreatedAt
        if (budget.createdByUser) {
            footerCols[t('budget.list.headers.createdByAt')] = (
                <Tooltip
                    content={formatDateForTooltip(budget.createdAt)}
                    shouldRenderGlobalStyle={false}
                >
                    <Text style={{ marginBottom: '0' }}>{`${
                        budget.createdByUser.profile.firstName
                    } ${budget.createdByUser.profile.lastName} ${t(
                        'budget.atByIntersection',
                    )} ${formatDate(budget.createdAt)}`}</Text>
                </Tooltip>
            );
        }

        // UpdatedAt
        if (budget.updatedByUser && budget.updatedAt) {
            footerCols[t('budget.list.headers.approvedByAt')] = (
                <Tooltip
                    content={formatDateForTooltip(budget.updatedAt)}
                    shouldRenderGlobalStyle={false}
                >
                    <Text style={{ marginBottom: '0' }}>{`${
                        budget.updatedByUser.profile.firstName
                    } ${budget.updatedByUser.profile.lastName} ${t(
                        'budget.atByIntersection',
                    )} ${formatDate(budget.updatedAt)}`}</Text>
                </Tooltip>
            );
        } else {
            footerCols[t('budget.list.headers.approvedByAt')] = '-';
        }

        // Approved_at (if applicable)
        if (budget.approvedByUser && budget.approvedAt) {
            footerCols[t('budget.list.headers.approvedByAt')] = (
                <Tooltip
                    content={formatDateForTooltip(budget.approvedAt)}
                    shouldRenderGlobalStyle={false}
                >
                    <Text style={{ marginBottom: '0' }}>{`${
                        budget.approvedByUser.profile.firstName
                    } ${budget.approvedByUser.profile.lastName} ${t(
                        'budget.atByIntersection',
                    )} ${formatDate(budget.approvedAt)}`}</Text>
                </Tooltip>
            );
        }

        // LastSimulationAt
        if (budget.lastSimulationAt) {
            footerCols[String(t('budget.list.headers.lastSimulationAt'))] = (
                <Flex>
                    <Tooltip content={formatDateForTooltip(budget.lastSimulationAt)}>
                        <Text>
                            {t('budget.atByIntersection')} {formatDate(budget.lastSimulationAt)}
                        </Text>
                    </Tooltip>
                    {budget.updatedAt &&
                        isAfter(new Date(budget.updatedAt), new Date(budget.lastSimulationAt)) &&
                        budget.status !== 'approved' &&
                        budget.status !== 'replaced' && (
                            <Icon
                                name="Error"
                                color={colors.yellow.Y400}
                                data-testid="ErrorIcon"
                                style={{ marginLeft: '0.5rem' }}
                            />
                        )}
                </Flex>
            );
        } else {
            footerCols[String(t('budget.list.headers.lastSimulationAt'))] = `-`;
        }

        return footerCols;
    };

    return (
        <>
            <TooltipGlobalStyle />
            <Formik
                initialValues={{
                    id: budget.id,
                    label: budget.label,
                    status: budget.status,
                    comment: budget.comment,
                    operationId: budget.operationId,
                    targetTotalAmountTtc: budget.targetTotalAmountTtc || 0,
                }}
                validationSchema={budgetSchema}
                onSubmit={(values: IBudgetFormData, actions) => {
                    const budgetValues = {
                        ...values,
                        targetTotalAmountTtc: values.targetTotalAmountTtc
                            ? Number(values.targetTotalAmountTtc)
                            : 0,
                    };
                    delete budgetValues.showTtcValues;
                    let callback = () => {
                        void dispatch(
                            updateBudget({
                                budget: {
                                    ...budgetValues,
                                    engagementMatching,
                                },
                                operationId: budget.operationId,
                            }),
                        );
                        setModalOptions(null);
                        actions.setSubmitting(false);
                        setIsPending(true);
                        setIsEditMode(false);
                        navigate(location.pathname.replace('?edit', ''), { state, replace: true });
                    };
                    if (
                        operationHasEngagements &&
                        budget.status === 'draft' &&
                        values.status === 'approved' &&
                        !engagementMatching
                    ) {
                        callback = () => {
                            setModalOptions(null);
                            setIsMatchingModalOpen(true);
                        };
                    } else if (engagementMatching) {
                        callback();
                        return;
                    }
                    modalManager(budget.status, values.status, callback);
                }}
                enableReinitialize
            >
                {(formikProps: FormikProps<IBudgetFormData>) => (
                    <Form autoComplete="off">
                        {document.getElementById('buttonsContainer') &&
                            ReactDOM.createPortal(
                                computeButtonsOutOfProps(formikProps).map((buttonProps) => (
                                    <Button key={buttonProps['data-testid']} {...buttonProps} />
                                )),
                                document.getElementById('buttonsContainer') as Element,
                            )}
                        <BudgetInformationCard
                            budget={budget}
                            totalData={{
                                [`${t('budget.totalBudgetOperationValue')} ${
                                    showTtcValues ? `(1)` : ``
                                }`]: (
                                    <>
                                        <GridColValue defaultScreen={4} smallScreen={4}>
                                            <TotalValueText className="print-medium-font" isNumeral>
                                                {CurrencyFormatter.format(
                                                    showTtcValues
                                                        ? budget.totalAmountTtc
                                                        : budget.totalAmountHt,
                                                )}
                                            </TotalValueText>
                                        </GridColValue>
                                        {budget.status === 'approved' && (
                                            <>
                                                <GridColValue defaultScreen={3} smallScreen={3}>
                                                    <PercentageBadge
                                                        value={calculateAmountRatio(
                                                            budget.totalEngagedTtc,
                                                            budget.totalAmountTtc,
                                                        )}
                                                    />
                                                </GridColValue>
                                                <GridColValue defaultScreen={4} smallScreen={4}>
                                                    <TextEngagedAmountValue
                                                        className="print-medium-font"
                                                        isNumeral
                                                    >
                                                        {CurrencyFormatter.format(
                                                            showTtcValues
                                                                ? getOptionalValueToDisplay(
                                                                      budget.totalEngagedTtc,
                                                                  )
                                                                : getOptionalValueToDisplay(
                                                                      budget.totalEngagedHt,
                                                                  ),
                                                        )}
                                                    </TextEngagedAmountValue>
                                                </GridColValue>
                                            </>
                                        )}
                                    </>
                                ),
                            }}
                            subtotalsData={{
                                [t('budget.totalInitialValue')]: (
                                    <>
                                        <GridColValue defaultScreen={4} smallScreen={4}>
                                            <SubtotalValueText
                                                className="print-medium-font"
                                                isNumeral
                                            >
                                                {CurrencyFormatter.format(
                                                    showTtcValues
                                                        ? budget.totalAmountTtcWithoutVariations
                                                        : budget.totalAmountHtWithoutVariations,
                                                )}
                                            </SubtotalValueText>
                                        </GridColValue>
                                        {budget.status === 'approved' && (
                                            <>
                                                <GridColValue defaultScreen={3} smallScreen={3}>
                                                    <PercentageBadge
                                                        value={
                                                            showTtcValues
                                                                ? calculateAmountRatio(
                                                                      budget.initialEngagedTtc,
                                                                      budget.totalAmountTtcWithoutVariations,
                                                                  )
                                                                : calculateAmountRatio(
                                                                      budget.initialEngagedHt,
                                                                      budget.totalAmountHtWithoutVariations,
                                                                  )
                                                        }
                                                    />
                                                </GridColValue>
                                                <GridColValue defaultScreen={4} smallScreen={4}>
                                                    <TextEngagedAmountValue
                                                        className="print-medium-font"
                                                        isNumeral
                                                    >
                                                        {CurrencyFormatter.format(
                                                            showTtcValues
                                                                ? getOptionalValueToDisplay(
                                                                      budget.initialEngagedTtc,
                                                                  )
                                                                : getOptionalValueToDisplay(
                                                                      budget.initialEngagedHt,
                                                                  ),
                                                        )}
                                                    </TextEngagedAmountValue>
                                                </GridColValue>
                                            </>
                                        )}
                                    </>
                                ),
                                [t('budget.totalPriceVariations')]: (
                                    <>
                                        <GridColValue defaultScreen={4} smallScreen={4}>
                                            <SubtotalValueText
                                                className="print-medium-font"
                                                isNumeral
                                            >
                                                {CurrencyFormatter.format(
                                                    showTtcValues
                                                        ? budget.totalAmountTtcVariations
                                                        : budget.totalAmountHtVariations,
                                                )}
                                            </SubtotalValueText>
                                        </GridColValue>
                                        {budget.status === 'approved' && (
                                            <>
                                                <GridColValue defaultScreen={3} smallScreen={3}>
                                                    <PercentageBadge
                                                        value={
                                                            showTtcValues
                                                                ? calculateAmountRatio(
                                                                      budget.priceVariationEngagedTtc,
                                                                      budget.totalAmountTtcVariations,
                                                                  )
                                                                : calculateAmountRatio(
                                                                      budget.priceVariationEngagedHt,
                                                                      budget.totalAmountHtVariations,
                                                                  )
                                                        }
                                                    />
                                                </GridColValue>
                                                <GridColValue defaultScreen={4} smallScreen={4}>
                                                    <TextEngagedAmountValue
                                                        className="print-medium-font"
                                                        isNumeral
                                                    >
                                                        {CurrencyFormatter.format(
                                                            showTtcValues
                                                                ? getOptionalValueToDisplay(
                                                                      budget.priceVariationEngagedTtc,
                                                                  )
                                                                : getOptionalValueToDisplay(
                                                                      budget.priceVariationEngagedHt,
                                                                  ),
                                                        )}
                                                    </TextEngagedAmountValue>
                                                </GridColValue>
                                            </>
                                        )}
                                    </>
                                ),
                            }}
                            targetData={
                                showTtcValues
                                    ? {
                                          [`${t('budget.targetAmount')} (2)`]: (
                                              <GridColValue defaultScreen={4} smallScreen={4}>
                                                  <PermissionAwareText
                                                      marginTop="0"
                                                      displayStyle={{
                                                          fontWeight: 700,
                                                          margin: 0,
                                                          fontSize: '0.875rem',
                                                      }}
                                                      textAlign="right"
                                                      editable={isEditMode}
                                                      permissionsRequired={[permissionNeeded]}
                                                      name="targetTotalAmountTtc"
                                                      type="text"
                                                      numberType="amount"
                                                      data-testid="targetTotalAmountTtc"
                                                      errorTestId="errorTargetTotalAmountTtc"
                                                      noMarginTop
                                                      whiteBackground
                                                  />
                                              </GridColValue>
                                          ),
                                          [`${t('budget.difference')} (3)=(2)-(1)`]: (
                                              <GridColValue defaultScreen={4} smallScreen={4}>
                                                  <TotalValueText
                                                      className="print-medium-font"
                                                      isNumeral
                                                  >
                                                      {formikProps.values.targetTotalAmountTtc
                                                          ? CurrencyFormatter.format(
                                                                formikProps.values
                                                                    .targetTotalAmountTtc -
                                                                    budget.totalAmountTtc,
                                                            )
                                                          : CurrencyFormatter.format(
                                                                budget.targetTotalAmountTtc -
                                                                    budget.totalAmountTtc,
                                                            )}
                                                  </TotalValueText>
                                              </GridColValue>
                                          ),
                                      }
                                    : {}
                            }
                            footerData={getFooterData()}
                            footerTrailingComponent={
                                <GridCol
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <div>
                                        {isEditMode ? (
                                            <FormikOptionDropdown
                                                items={statusOptions}
                                                name="status"
                                                initialNameDiplay={
                                                    <Status
                                                        status={
                                                            formikProps.values
                                                                .status as BudgetStatus
                                                        }
                                                    />
                                                }
                                                label={t('budget.list.headers.status')}
                                                data-testid="status"
                                                errorTestId="errorStatus"
                                                style={{ margin: '0' }}
                                            />
                                        ) : (
                                            <>
                                                <Text
                                                    style={{ margin: '0' }}
                                                    color={colors.neutral.N300}
                                                >
                                                    {t('budget.list.headers.status')}
                                                </Text>
                                                <Status status={budget.status} />
                                            </>
                                        )}
                                    </div>
                                </GridCol>
                            }
                            bodyNode={
                                <SimulationButton
                                    isEditMode={isEditMode}
                                    budget={budget}
                                    type="budget"
                                    disabled={
                                        budget.status === 'approved' ||
                                        budget.status === 'replaced' ||
                                        !hasRightToEdit
                                    }
                                    text={t('budget.calculate')}
                                    iconName="Calculate"
                                />
                            }
                            showTtcValues={showTtcValues}
                            setShowTtcValues={setShowTtcValues}
                        />
                        <OtherInformationContainer>
                            <InformationHeader isOpen={isCollapseOpen}>
                                <Icon
                                    name={isCollapseOpen ? 'Remove' : 'Add'}
                                    onClick={() => setIsCollapseOpen(!isCollapseOpen)}
                                    data-testid="collapseInformationContainerButton"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                                <Text
                                    type="H500"
                                    color={colors.neutral.N500}
                                    style={{
                                        margin: '0 0 0 0.5rem',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setIsCollapseOpen(!isCollapseOpen)}
                                >
                                    {t('budget.otherInformation')}
                                </Text>
                            </InformationHeader>
                            <InformationCollapseContainer isOpen={isCollapseOpen}>
                                <InformationCollapseBody>
                                    <PermissionAwareText
                                        marginTop="0"
                                        editable={isEditMode}
                                        permissionsRequired={[permissionNeeded]}
                                        label={t('budget.label')}
                                        name="label"
                                        type="text"
                                        value={budget.label}
                                        placeholder={t('budget.label')}
                                        data-testid="label"
                                        errorTestId="errorLabel"
                                        noMarginTop
                                        whiteBackground
                                    />
                                    <PermissionAwareTextarea
                                        editable={isEditMode}
                                        permissionsRequired={[permissionNeeded]}
                                        label={`${t('budget.comment')} ${t('general.optional')}`}
                                        name="comment"
                                        placeholder={t('budget.comment')}
                                        value={budget.comment ?? '-'}
                                        data-testid="comment"
                                        errorTestId="errorComment"
                                        displayStyle={{
                                            whiteSpace: 'unset',
                                            marginTop: '0.75rem',
                                        }}
                                        whiteBackground
                                    />
                                </InformationCollapseBody>
                                <InformationCollapseFooter>
                                    <Text
                                        type="H500"
                                        color={colors.neutral.N500}
                                        style={{
                                            margin: '0',
                                        }}
                                    >
                                        {t('budget.priceVariationHypothesis')}
                                    </Text>
                                    <GridRow
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        {budgetPriceHypotheses.map((item, index) => (
                                            <GridCol
                                                key={item.id}
                                                defaultScreen={3}
                                                smallScreen={3}
                                            >
                                                <BudgetPriceHypothesis
                                                    isEditMode={isEditMode}
                                                    permissionNeeded={[permissionNeeded]}
                                                    budgetPriceHypothesis={item}
                                                    operationId={budget.operationId}
                                                    hasRightToEdit={hasRightToEdit}
                                                    separator={(index + 1) % 4 !== 0}
                                                    increasingFactorPlaceHolder={
                                                        findLast(
                                                            budgetPriceHypotheses,
                                                            (
                                                                {
                                                                    increasingFactor,
                                                                }: BudgetPriceHypothesisEntity,
                                                                indexValue: number,
                                                            ) =>
                                                                increasingFactor !== null &&
                                                                indexValue < index,
                                                        )?.increasingFactor
                                                    }
                                                />
                                            </GridCol>
                                        ))}
                                    </GridRow>
                                    {isEditMode &&
                                        hasRightToEdit &&
                                        budgetPriceHypotheses.length < 20 && (
                                            <Container>
                                                <Button
                                                    text={t('budget.addHypotheses')}
                                                    aspect="secondary"
                                                    size="small"
                                                    onClick={() => {
                                                        void dispatch(
                                                            createBudgetPriceHypothesis({
                                                                operationId: budget.operationId,
                                                                budgetId: budget.id,
                                                            }),
                                                        );
                                                    }}
                                                    isLoading={isCreatingBudgetPriceHypothesis}
                                                    throttled
                                                />
                                            </Container>
                                        )}
                                </InformationCollapseFooter>
                            </InformationCollapseContainer>
                        </OtherInformationContainer>
                        {isMatchingModalOpen && hasRightToEdit ? (
                            <BudgetLinesMatchingEngagementsModalBeforeApprovingBudget
                                onSubmit={(values: EngagementMatching[]) => {
                                    setIsMatchingModalOpen(false);
                                    setEngagementMatching(values);
                                    formikProps.handleSubmit();
                                }}
                                onCancelClick={() => {
                                    setIsMatchingModalOpen(false);
                                    showFlag(
                                        'warning',
                                        t('general.warning'),
                                        t('general.unconfirmedChanges'),
                                    );
                                }}
                                operationId={Number(budget.operationId)}
                                budgetId={Number(budget.id)}
                                isOpen
                            />
                        ) : null}
                    </Form>
                )}
            </Formik>
            {modalOptions && (
                <Modal
                    isOpen={modalOptions.text !== ''}
                    onCloseButtonPressed={() => setModalOptions(null)}
                    buttons={[
                        {
                            text: t('general.cancel'),
                            aspect: 'secondary',
                            'data-testid': 'cancelDelete',
                            onClick() {
                                setModalOptions(null);
                            },
                        },
                        {
                            text: t('general.confirm'),
                            aspect: 'primary',
                            'data-testid': 'confirm',
                            onClick: modalOptions.callback,
                        },
                    ]}
                    title={t('budget.statusChange')}
                    size="small"
                    iconName="CheckCircle"
                    iconColor={colors.green.G400}
                    shouldRenderGlobalStyle={false}
                >
                    <Text style={{ margin: '0 0 1rem 0' }}>{t(modalOptions.text)}</Text>
                </Modal>
            )}
            {isOperationListModalOpen && (
                <BudgetCloneChooseOperationTargetModal
                    isOperationListModalOpen={isOperationListModalOpen}
                    setIsOperationListModalOpen={setIsOperationListModalOpen}
                    cloneBudgetToAnotherOperation={cloneBudgetToAnotherOperation}
                />
            )}
            {isTitleAndCommentModalOpen && (
                <BudgetTitleAndCommentModal
                    isOpen={isTitleAndCommentModalOpen}
                    setIsOpen={setIsTitleAndCommentModalOpen}
                    permissionNeeded={[permissionNeeded]}
                    budget={budget}
                    callback={(values: BudgetLabelAndCommentFormData) => {
                        void dispatch(
                            updateBudgetLabelAndComment({
                                budget,
                                budgetLabelAndComment: values,
                            }),
                        );
                        setIsTitleAndCommentModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
export default BudgetGeneralInfo;
