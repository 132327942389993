import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { IOrganization } from './organizationSlice';
import type { QueryParams, PaginatedPayload, ServerError } from './common';
import { errorHandler } from './common';
import type { User } from './userSlice';

export type Site = {
    id: number;
    organizationId: IOrganization['id'];
    label: string;
    address1: string;
    address2: string;
    postalCode: string;
    city: string;
    country: string;
    isDefault: boolean;
    users: User[];
};

export interface ISiteFormData {
    id?: number;
    organizationId: IOrganization['id'];
    label: string;
    address1: string;
    address2: string;
    postalCode: string;
    city: string;
    country: string;
    isDefault: boolean;
}

type SitesPayload = PaginatedPayload<Site>;

interface ISiteState {
    totalSites: number;
    sitesById: Record<number, Site>;
    loading: boolean;
    error: string | null;
    siteIdAdded: number | null;
    sitesByOrganizationIdAndPage: Record<string, Record<number, Array<Site['id']>>>;
    queryString: string;
}

export const initialState: ISiteState = {
    totalSites: 0,
    sitesById: {},
    sitesByOrganizationIdAndPage: {},
    loading: false,
    error: null,
    siteIdAdded: null,
    queryString: '',
};

export const getSites = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    SitesPayload & { page: number; queryString: string; organizationId: string | undefined },
    // First argument to the payload creator
    QueryParams,
    {
        rejectValue: ServerError;
    }
>(
    'SITE/GETALL',
    async (
        { page = 1, query, match = 'any', fields, orderBy, pageSize = 12, organizationId },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `&match=${match}`;
            if (query) {
                queryString += `&query=${query}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            const path = `/organizations/${organizationId}/sites?page=${
                page - 1
            }&pageSize=${pageSize}${queryString}`;

            const response = await axios.get<SitesPayload>(path);

            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { ...response.data, page, queryString, organizationId };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;

            if (error.response?.data.translationKey) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    },
);

export const getSite = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Site,
    // First argument to the payload creator
    { id: Site['id']; organizationId: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('SITE/GET', async ({ id, organizationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: Site }>(
            `/organizations/${organizationId}/sites/${id}`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});
export const createSite = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Site,
    // First argument to the payload creator
    ISiteFormData & { organizationId: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('SITE/POST', async ({ organizationId, ...siteValues }, { rejectWithValue }) => {
    try {
        const response = await axios.post<{ data?: Site }>(
            `/organizations/${organizationId}/sites`,
            siteValues,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const updateSite = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Site,
    // First argument to the payload creator
    ISiteFormData & { organizationId: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('SITE/PUT', async ({ organizationId, ...siteValues }, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: Site }>(
            `/organizations/${organizationId}/sites/${siteValues.id}`,
            siteValues,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});
export const deleteSite = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Site['id'],
    // First argument to the payload creator
    { id: Site['id']; organizationId: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('SITE/DELETE', async ({ id, organizationId }, { rejectWithValue }) => {
    try {
        await axios.delete<{ data?: Site['id'] }>(`/organizations/${organizationId}/sites/${id}`);

        // We don't really care about the data returned here, as long as it is a 200
        return id;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const slice = createSlice({
    name: 'site',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getSites.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getSites.fulfilled, (state, { payload }) => {
            if (payload.queryString !== state.queryString) {
                state.queryString = payload.queryString;
            }
            if (payload.data && payload.data.length > 0 && payload.page) {
                const byPage: Array<Site['id']> = [];
                payload.data.forEach((item: Site) => {
                    state.sitesById[item.id] = item;
                    byPage.push(item.id);
                });
                if (payload.organizationId) {
                    state.sitesByOrganizationIdAndPage[payload.organizationId] = {};
                    state.sitesByOrganizationIdAndPage[payload.organizationId][payload.page] =
                        byPage;
                }
            }
            state.totalSites = payload.total;

            state.loading = false;
        });
        builder.addCase(getSites.rejected, errorHandler());
        // Get one
        builder.addCase(getSite.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSite.fulfilled, (state, { payload }) => {
            state.sitesById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(getSite.rejected, errorHandler());
        // Create
        builder.addCase(createSite.pending, (state) => {
            state.loading = true;
            state.siteIdAdded = null;
            state.error = null;
        });
        builder.addCase(createSite.fulfilled, (state, { payload }) => {
            state.error = null;
            state.sitesById[payload.id] = payload;
            state.siteIdAdded = payload.id;
            state.loading = false;
        });
        builder.addCase(
            createSite.rejected,
            errorHandler((state, action) => {
                state.siteIdAdded = null;
            }),
        );
        // Update
        builder.addCase(updateSite.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateSite.fulfilled, (state, { payload }) => {
            state.sitesById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(updateSite.rejected, errorHandler());
        // Delete
        builder.addCase(deleteSite.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteSite.fulfilled, (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.sitesById[payload];
            state.loading = false;
        });
        builder.addCase(deleteSite.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.site.error;
export const selectSites = (state: RootState) => Object.values(state.site.sitesById);
export const selectSite =
    (id: Site['id']) =>
    (state: RootState): Site | undefined =>
        state.site.sitesById[id];
export const selectSiteIdAdded = (state: RootState) => state.site.siteIdAdded;
export const selectTotalSites = (state: RootState) => state.site.totalSites;
export const selectSitesByOrganizationIdAndPage =
    (organizationId: string | undefined, page: number) => (state: RootState) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
        if (organizationId && state.site.sitesByOrganizationIdAndPage[organizationId]) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
            return state.site.sitesByOrganizationIdAndPage[organizationId][page]?.map(
                (id: number) => state.site.sitesById[id],
            );
        }
        return [];
    };
export const selectAllSitesByOrganizationId =
    (organizationId: string | undefined) => (state: RootState) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
        if (organizationId && state.site.sitesByOrganizationIdAndPage[organizationId]) {
            return Object.values(state.site.sitesByOrganizationIdAndPage[organizationId]).reduce(
                (result: Site[], arrayOfIds: Array<Site['id']>) => {
                    const sitesToAdd = arrayOfIds.map((id: number) => state.site.sitesById[id]);
                    return result.concat(sitesToAdd);
                },
                [],
            );
        }
        return [];
    };
export const selectIsLoading = (state: RootState) => state.site.loading;
export const selectSiteName =
    (id: Site['id'] | undefined) =>
    (state: RootState): string | null => {
        let name = null;
        if (id) {
            const site = selectSite(id)(state);
            if (site?.label) {
                name = site.label;
            }
        }
        return name;
    };

export default slice.reducer;
