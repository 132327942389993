import type { ChangeEvent } from 'react';
import React from 'react';
import { useField } from 'formik';

import type { ToggleProps } from './Toggle';
import Toggle from './Toggle';

type Props = ToggleProps & {
    name: string;
};

const ToggleWithFormik: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
    style,
    ...props
}) => {
    const { name, checked, onChange, ...rest } = props;

    const formikFields = useField(name); // [field, meta, helpers]
    const field = formikFields[0];
    const setValue = formikFields[2].setValue;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(!field.value);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <Toggle
            style={style}
            name={name}
            onChange={handleChange}
            checked={checked ?? field.value}
            {...rest}
        />
    );
};

export default ToggleWithFormik;
