import React from 'react';
import { useSelector } from 'react-redux';

import type { Operation } from '../slices/operationSlice';
import {
    importInvoices,
    selectImportResult,
    resetImportState,
    selectExcelFilename,
    selectImportStatus,
    selectError,
    selectImportMessage,
} from '../slices/invoiceSlice';
import ImportEdiflex from './ImportEdiflex';
import ImportInvoicesResultModal from './ImportInvoicesResultModal';
import type { ImportPayload } from '../slices/common';
import { useAppDispatch } from '../store';

type Props = {
    operationId: Operation['id'];
    fileInputRef: React.RefObject<HTMLInputElement>;
};

const ImportInvoices: React.FC<Props> = ({ operationId, fileInputRef }) => {
    const dispatch = useAppDispatch();

    const importResult = useSelector(selectImportResult);
    const importFilename = useSelector(selectExcelFilename);
    const importStatus = useSelector(selectImportStatus);
    const importMessage = useSelector(selectImportMessage);
    const importServerError = useSelector(selectError);

    const handleCloseImportResultsModal = () => {
        dispatch(resetImportState());
    };

    const importItems = (dataToImport: ImportPayload) => {
        const { fileData, operationId: id } = dataToImport;
        void dispatch(
            importInvoices({
                fileData,
                operationId: Number(id),
            }),
        );
    };

    return (
        <ImportEdiflex
            operationId={operationId}
            fileInputRef={fileInputRef}
            importResult={importResult}
            importFilename={importFilename}
            importStatus={importStatus}
            importServerError={importServerError}
            importItems={importItems}
            importResultModalContent={
                <ImportInvoicesResultModal
                    importMessage={importMessage}
                    importServerError={importServerError}
                />
            }
            onCloseImportResultsModal={handleCloseImportResultsModal}
        />
    );
};

export default ImportInvoices;
