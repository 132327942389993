import type { HTMLAttributes } from 'react';
import React from 'react';
import { Col } from '@bootstrap-styled/v4';

import { styles } from '../constants/styles';

// if others types are needed, see https://github.com/bootstrap-styled/v4/pull/136/files
export type ColumnSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ColumnProps =
    | ColumnSize
    | {
          size?: ColumnSize;
          offset?: ColumnSize;
      };

export type ColProps = {
    smallScreen?: ColumnProps;
    defaultScreen?: ColumnProps;
    gutterWidth?: string;
    gridBreakpoint?: string;
    topOffset?: string;
} & HTMLAttributes<HTMLElement>;

const GridCol: React.FC<ColProps> = ({
    children,
    gutterWidth = styles.gutterWidth,
    gridBreakpoint = styles.gridBreakpoint,
    smallScreen,
    defaultScreen,
    topOffset,
    ...rest
}) => (
    <Col
        xs={smallScreen}
        md={defaultScreen}
        sm={smallScreen}
        theme={{
            '$grid-gutter-width': gutterWidth,
            '$grid-breakpoints': { sm: '0', md: gridBreakpoint, lg: '1250rem' },
        }}
        {...rest}
    >
        {children}
    </Col>
);

export default GridCol;
