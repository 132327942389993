import React from 'react';
import styled from 'styled-components';

import type { ColumnSize } from './GridCol';
import GridCol from './GridCol';
import PermissionAwareDisplay from './PermissionAwareDisplay';

type Props = {
    data: Record<string, string | undefined>;
    colSizes?: ColumnSize[];
    hasTooltip?: boolean;
};

const PermissionAwareDisplayWithoutMarginTop = styled(PermissionAwareDisplay)<{
    hasTooltip: boolean;
}>`
    margin-top: 0;
`;

const Address: React.FC<Props> = ({ data, colSizes, hasTooltip = false }) => {
    const handleAddressData = ([entry, value]: [string, string | undefined], index: number) => {
        const shouldShowTooltip = Boolean(value && value.length > 10);
        return (
            <GridCol
                key={entry}
                data-testid={`${entry}HeaderCol`}
                defaultScreen={colSizes ? colSizes[index] : 3}
                smallScreen={colSizes ? colSizes[index] : 3}
            >
                <PermissionAwareDisplayWithoutMarginTop
                    label={entry}
                    value={value ?? ''}
                    hasTooltip={hasTooltip && shouldShowTooltip}
                />
            </GridCol>
        );
    };
    return <>{Object.entries(data).map(handleAddressData)}</>;
};

export default Address;
