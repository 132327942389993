import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import * as Yup from 'yup';
import type { FormikProps } from 'formik';
import { useField } from 'formik';
import { Radio, RadioGroup } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VictoryAxis, VictoryChart, VictoryLine, VictoryTheme } from 'victory';
import type { TFunction } from 'i18next';
import { assign } from 'lodash';

import { colors } from '../../constants/colors';
import type { Option } from '../SelectField';

import GridRow from '../GridRow';
import GridCol from '../GridCol';
import FormikOptionDropdown from '../dropdown/FormikOptionDropdown';
import Text from '../Text';
import PermissionAwareText from '../PermissionAwareText';

import {
    getSpendingSchemas,
    selectSpendingSchemasForDropdown,
} from '../../slices/spendingSchemaSlice';
import PermissionAwareDisplay from '../PermissionAwareDisplay';
import { handleRadioStringChange } from '../../utils/handleRadioButtonGroup';
import type { ExpenseType, SpendingRythm } from '../../slices/common';
import { ONE_TIME, STAGGERED } from '../../slices/common';
import type { EcheancierMode } from '../../slices/engagementSlice';
import { MANUAL_ENTRY } from '../../slices/engagementSlice';
import { validateFormFields } from '../../utils/formValidation';
import ErrorMessage from '../InfoMessage';
import type { Permission } from '../../slices/authSlice';
import { useAppDispatch } from '../../store';

const GlobalStyle = createGlobalStyle`
    .bp4-radio {
        margin-right: 0.5rem;
        font-weight: 800;
        color: ${colors.blue.B400};
        font-size: 1rem;
        text-align: left;
    };
    .bp4-control input:checked ~ .bp4-control-indicator {
        background-color: ${colors.blue.B500};
    }
`;

const StyledContainer = styled.div`
    background-color: ${colors.other.white};
    width: 95%;
    margin: auto;
    margin-bottom: 1rem;
    padding: 2rem 2rem 0.5rem 2rem;
    border-radius: 10px;
`;

const DashedSeparator = styled.div`
    border-bottom: 0.2rem dotted ${colors.neutral.N75};
    width: 100%;
    margin-bottom: 1rem;
`;

const StyledOptionDropdown = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
`;

const StyledContainerHalfWidth = styled(StyledContainer)`
    width: 49%;
    margin: 0;
    text-align: left;
    padding-top: 0.625rem;
`;

const StyledContainerRowDisplay = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledLabelText = styled(GridRow)`
    margin-bottom: 1.25rem;
`;

const StyledGridRow = styled(GridRow)`
    display: block !important;
    padding-left: 0.75rem;
`;

const VICTORY_CHARTS_HEIGHT = 153;
const VICTORY_CHARTS_WIDTH = 360;
const VICTORY_AXIS_FONT = 'Rubik';
const VICTORY_AXIS_FONT_SIZE = 9;
const VICTORY_AXIS_COLOR = colors.blue.B500;

export const initialValuesSpendingRythm = {
    fixedSpendingEndShift: 0,
    fixedSpendingFactor: Number(100).toFixed(2),
    fixedSpendingStartShift: 0,
    variableSpendingEndShift: 0,
    variableSpendingFactor: Number(0).toFixed(2),
    variableSpendingStartShift: 0,
    variableSpendingSchemaId: null,
    paymentPeriod: undefined,
    expenseType: STAGGERED,
};

export async function validateSpendingSchemaTab<T extends { spendingRythm?: SpendingRythm }>(
    props: FormikProps<T>,
) {
    const formFieldToValidate = [];
    if (props.values.spendingRythm?.expenseType === ONE_TIME) {
        formFieldToValidate.push('spendingRythm', 'spendingRythm.paymentPeriod');
    }
    if (props.values.spendingRythm?.expenseType === STAGGERED) {
        formFieldToValidate.push(
            'spendingRythm',
            'spendingRythm.fixedSpendingFactor',
            'spendingRythm.fixedSpendingStartShift',
            'spendingRythm.fixedSpendingEndShift',
            'spendingRythm.variableSpendingFactor',
            'spendingRythm.variableSpendingStartShift',
            'spendingRythm.variableSpendingEndShift',
            'spendingRythm.variableSpendingSchemaId',
        );
    }
    const isValid = await validateFormFields(formFieldToValidate, props);
    return isValid;
}

const checkThatSpendingFactorValueIsValid = (spendingFactorValue: number) =>
    spendingFactorValue >= 0 && spendingFactorValue <= 100;

export const checkSpendingRythmFormula = (
    fixedSpendingFactor: number,
    variableSpendingFactor: number,
) => {
    const isFixedSpendingFactorValueValid =
        checkThatSpendingFactorValueIsValid(fixedSpendingFactor);
    const isVariableSpendingFactorValueValid =
        checkThatSpendingFactorValueIsValid(variableSpendingFactor);
    const factorsSum = (fixedSpendingFactor * 1000 + variableSpendingFactor * 1000) / 1000;
    return (
        isFixedSpendingFactorValueValid && isVariableSpendingFactorValueValid && factorsSum === 100
    );
};

export const calculateOtherSpendingFactorValueToKeepSumEqualTo100 = (
    newSpendingFactorValue: number,
) => {
    let otherSpendingFactorValue;
    if (checkThatSpendingFactorValueIsValid(newSpendingFactorValue)) {
        otherSpendingFactorValue = 100 - newSpendingFactorValue;
    } else {
        otherSpendingFactorValue = 0;
    }
    return otherSpendingFactorValue;
};

export const getSpendingRythmValidationSchema = (t: TFunction) =>
    Yup.object().shape({
        expenseType: Yup.mixed().oneOf([ONE_TIME, STAGGERED]).required(t('errors.required')),
        paymentPeriod: Yup.mixed()
            .nullable()
            .when('expenseType', {
                is: (value: ExpenseType) => value === 'one_time',
                then: Yup.mixed().oneOf(['at_start', 'at_end']).required(t('errors.required')),
            }),
        fixedSpendingFactor: Yup.number()
            .nullable()
            .required(t('errors.required'))
            .test(
                'formulaSpendingRythm',
                t('errors.distributionSumNotEqualToOne'),
                function (fixedSpendingFactor: number | null | undefined) {
                    const { variableSpendingFactor, expenseType } = this.parent;
                    return expenseType === STAGGERED
                        ? checkSpendingRythmFormula(
                              Number(fixedSpendingFactor),
                              Number(variableSpendingFactor),
                          )
                        : true;
                },
            ),
        fixedSpendingStartShift: Yup.number()
            .nullable()
            .when('expenseType', {
                is: (value: ExpenseType) => value === 'staggered',
                then: Yup.number()
                    .required(t('errors.required'))
                    .min(0, t('spendingRythm.shiftMustBeAnIntegerPositiveOrNull'))
                    .integer(t('spendingRythm.shiftMustBeAnIntegerPositiveOrNull')),
            }),
        fixedSpendingEndShift: Yup.number()
            .nullable()
            .when('expenseType', {
                is: (value: ExpenseType) => value === 'staggered',
                then: Yup.number()
                    .required(t('errors.required'))
                    .min(0, t('spendingRythm.shiftMustBeAnIntegerPositiveOrNull'))
                    .integer(t('spendingRythm.shiftMustBeAnIntegerPositiveOrNull')),
            }),
        variableSpendingFactor: Yup.number()
            .nullable()
            .required(t('errors.required'))
            .test(
                'formulaSpendingRythm',
                t('errors.distributionSumNotEqualToOne'),
                function (variableSpendingFactor: number | null | undefined) {
                    const { fixedSpendingFactor, expenseType } = this.parent;

                    return expenseType === STAGGERED
                        ? checkSpendingRythmFormula(
                              Number(fixedSpendingFactor),
                              Number(variableSpendingFactor),
                          )
                        : true;
                },
            ),

        variableSpendingEndShift: Yup.number()
            .nullable()
            .when('expenseType', {
                is: (value: ExpenseType) => value === 'staggered',
                then: Yup.number().required(t('errors.required')),
            }),
        variableSpendingSchemaId: Yup.number()
            .nullable()
            .test(
                'spendingSchemaIdValidation',
                t('errors.chooseAvalueForSpendingSchema'),
                function (variableSpendingSchemaId: number | null | undefined) {
                    const { expenseType, variableSpendingFactor } = this.parent;
                    if (expenseType === STAGGERED && Number(variableSpendingFactor) !== 0) {
                        return (
                            Number(variableSpendingSchemaId) !== 0 ||
                            variableSpendingSchemaId !== null
                        );
                    }
                    return true;
                },
            ),
        variableSpendingStartShift: Yup.number()
            .nullable()
            .when('expenseType', {
                is: (value: ExpenseType) => value === 'staggered',
                then: Yup.number().required(t('errors.required')),
            }),
    });

type Props<T> = {
    hasRightToEdit: boolean;
    permissionsNeeded: Permission[];
    formikProps: FormikProps<T>;
    isEditMode?: boolean;
};

const SpendingRythmTab = <
    B extends { spendingRythm?: SpendingRythm; echeancierMode?: EcheancierMode },
>({
    hasRightToEdit,
    formikProps,
    permissionsNeeded,
    isEditMode = true,
}: Props<B>) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    let spendingSchemasForDropdown = useSelector(selectSpendingSchemasForDropdown);
    spendingSchemasForDropdown = [
        {
            value: 0,
            label: t('general.noValue'),
            curvePoints: [],
        },
        ...spendingSchemasForDropdown,
    ];

    const [paymentField] = useField('spendingRythm.paymentPeriod');
    const oneTimeExpenseField = useField('spendingRythm.expenseType');

    const paymentPeriodOptions: Array<Option<string | number>> = [
        {
            value: 'at_start',
            label: t('spendingRythm.beginning'),
        },
        {
            value: 'at_end',
            label: t('spendingRythm.atTheEnd'),
        },
    ];

    useEffect(() => {
        void dispatch(getSpendingSchemas());
    }, [dispatch]);

    const handleValueChange = handleRadioStringChange((value) => {
        if (value === ONE_TIME) {
            formikProps.setValues({
                ...formikProps.values,
                spendingRythm: {
                    ...formikProps.values.spendingRythm,
                    fixedSpendingEndShift: 0,
                    fixedSpendingFactor: 0,
                    fixedSpendingStartShift: 0,
                    variableSpendingEndShift: 0,
                    variableSpendingFactor: 0,
                    variableSpendingStartShift: 0,
                    variableSpendingSchemaId: null,
                },
            });
        } else {
            formikProps.setFieldValue('spendingRythm.paymentPeriod', 'at_start');
            formikProps.setFieldValue('spendingRythm.variableSpendingSchemaId', null);
        }

        oneTimeExpenseField[2].setValue(value);
    });

    const getInitialPaymentPeriod = () => {
        const paymentPeriod = paymentPeriodOptions.find(
            ({ value }) => value === paymentField.value,
        );
        return paymentPeriod ? paymentPeriod.label : t('spendingRythm.choosePayment');
    };

    const getInitialSpendingSchemaDropdownName = () => {
        let initialSpendingSchema = isEditMode
            ? t('general.noValue')
            : t('spendingRythm.selectAcurve');
        if (formikProps.values.spendingRythm?.variableSpendingSchemaId) {
            initialSpendingSchema =
                spendingSchemasForDropdown.find(
                    (spendingSchemasOption) =>
                        spendingSchemasOption.value ===
                        formikProps.values.spendingRythm?.variableSpendingSchemaId,
                )?.label ?? t('spendingRythm.selectAcurve');
        }

        return initialSpendingSchema;
    };

    const isManualEntryEcheancier = formikProps.values.echeancierMode === MANUAL_ENTRY;
    const startLabel = t('spendingRythm.start');
    const endLabel = t('spendingRythm.end');

    const victoryManualEntryTheme = {
        axis: assign({
            style: {
                axis: { stroke: colors.neutral.N300 },
                tickLabels: {
                    fill: colors.neutral.N300,
                    padding: 10,
                },
                grid: {
                    fill: 'none',
                    stroke: 'none',
                },
            },
        }),
    };

    return (
        <StyledContainer>
            <GlobalStyle />
            {isManualEntryEcheancier && (
                <ErrorMessage text={t('spendingRythm.disabledModifications')} />
            )}
            <RadioGroup
                name="spendingRythm.oneTimeExpense"
                onChange={handleValueChange}
                selectedValue={formikProps.values.spendingRythm?.expenseType}
                disabled={Boolean(!(hasRightToEdit && isEditMode)) || isManualEntryEcheancier}
            >
                <Radio
                    value={ONE_TIME}
                    style={{ marginRight: '0.5rem' }}
                    label={t('spendingRythm.oneTimeExpense')}
                    checked={formikProps.values.spendingRythm?.expenseType === ONE_TIME}
                />
                {formikProps.values.spendingRythm?.expenseType === ONE_TIME && (
                    <GridRow>
                        <GridCol defaultScreen={4} smallScreen={4}>
                            {hasRightToEdit && isEditMode ? (
                                <FormikOptionDropdown
                                    items={paymentPeriodOptions.filter(
                                        ({ value }) => value !== paymentField.value,
                                    )}
                                    name="spendingRythm.paymentPeriod"
                                    initialNameDiplay={getInitialPaymentPeriod()}
                                    data-testid="spendingRythm.paymentPeriod"
                                    errorTestId="errorSpendingRythmPaymentPeriod"
                                    disabled={isManualEntryEcheancier}
                                />
                            ) : (
                                <PermissionAwareDisplay
                                    data-testid="spendingRythm.paymentPeriod"
                                    value={getInitialPaymentPeriod()}
                                    style={{ marginTop: '0' }}
                                />
                            )}
                        </GridCol>
                    </GridRow>
                )}
                <DashedSeparator />
                <Radio
                    value={STAGGERED}
                    style={{ marginRight: '0.5rem' }}
                    label={t('spendingRythm.staggeredExpense')}
                    checked={formikProps.values.spendingRythm?.expenseType === STAGGERED}
                />
            </RadioGroup>

            {formikProps.values.spendingRythm?.expenseType === STAGGERED && (
                <StyledContainerRowDisplay>
                    <StyledContainerHalfWidth>
                        <StyledLabelText>
                            <GridCol defaultScreen={12} smallScreen={12}>
                                <Text
                                    type="H500"
                                    style={{ margin: 0 }}
                                    color={
                                        isManualEntryEcheancier
                                            ? colors.neutral.N300
                                            : colors.blue.B400
                                    }
                                >
                                    {t('spendingRythm.uniform')}
                                </Text>
                            </GridCol>
                        </StyledLabelText>
                        <GridRow>
                            <GridCol defaultScreen={4} smallScreen={4}>
                                <PermissionAwareText
                                    marginTop="0"
                                    editable={hasRightToEdit && isEditMode}
                                    permissionsRequired={permissionsNeeded}
                                    label={t('spendingRythm.distributionPercentage')}
                                    name="spendingRythm.fixedSpendingFactor"
                                    type="text"
                                    numberType="percentage"
                                    disabled={
                                        formikProps.values.spendingRythm.expenseType !==
                                            STAGGERED || isManualEntryEcheancier
                                    }
                                    onChange={(e) => {
                                        const newValue = Number(e.target.value);
                                        formikProps.setFieldValue(
                                            'spendingRythm.variableSpendingFactor',
                                            calculateOtherSpendingFactorValueToKeepSumEqualTo100(
                                                newValue,
                                            ),
                                        );
                                    }}
                                    noMarginTop
                                    whiteBackground
                                />
                            </GridCol>
                        </GridRow>
                        <StyledLabelText>
                            <GridCol defaultScreen={12} smallScreen={12}>
                                <Text
                                    type="H500"
                                    style={{ margin: 0 }}
                                    color={
                                        isManualEntryEcheancier
                                            ? colors.neutral.N300
                                            : colors.blue.B400
                                    }
                                >
                                    {t('spendingRythm.variable')}
                                </Text>
                            </GridCol>
                        </StyledLabelText>
                        <GridRow>
                            <GridCol defaultScreen={4} smallScreen={4}>
                                <PermissionAwareText
                                    marginTop="0"
                                    editable={hasRightToEdit && isEditMode}
                                    permissionsRequired={permissionsNeeded}
                                    label={t('spendingRythm.distributionPercentage')}
                                    name="spendingRythm.variableSpendingFactor"
                                    type="text"
                                    numberType="percentage"
                                    disabled={
                                        formikProps.values.spendingRythm.expenseType !==
                                            STAGGERED || isManualEntryEcheancier
                                    }
                                    onChange={(e) => {
                                        const newValue = Number(e.target.value);
                                        if (newValue === 0) {
                                            formikProps.setFieldValue(
                                                'spendingRythm.variableSpendingSchemaId',
                                                0,
                                            );
                                        }
                                        formikProps.setFieldValue(
                                            'spendingRythm.fixedSpendingFactor',
                                            calculateOtherSpendingFactorValueToKeepSumEqualTo100(
                                                newValue,
                                            ),
                                        );
                                    }}
                                    noMarginTop
                                    whiteBackground
                                />
                            </GridCol>
                        </GridRow>
                    </StyledContainerHalfWidth>
                    {formikProps.values.spendingRythm.variableSpendingFactor !== null &&
                        formikProps.values.spendingRythm.variableSpendingFactor > 0 && (
                            <StyledContainerHalfWidth>
                                <StyledGridRow>
                                    <StyledLabelText>
                                        <GridCol defaultScreen={12} smallScreen={12}>
                                            <Text
                                                type="H500"
                                                style={{ margin: 0 }}
                                                color={
                                                    isManualEntryEcheancier
                                                        ? colors.neutral.N300
                                                        : colors.blue.B400
                                                }
                                            >
                                                {t('spendingRythm.curve')}
                                            </Text>
                                        </GridCol>
                                    </StyledLabelText>
                                    <GridRow>
                                        {spendingSchemasForDropdown.length > 1 &&
                                        hasRightToEdit &&
                                        isEditMode ? (
                                            <GridCol defaultScreen={6} smallScreen={6}>
                                                <FormikOptionDropdown
                                                    items={spendingSchemasForDropdown}
                                                    name="spendingRythm.variableSpendingSchemaId"
                                                    initialNameDiplay={getInitialSpendingSchemaDropdownName()}
                                                    label={t('spendingRythm.selectAcurve')}
                                                    style={{ margin: '0' }}
                                                    disabled={isManualEntryEcheancier}
                                                />
                                            </GridCol>
                                        ) : (
                                            <StyledOptionDropdown>
                                                <Text
                                                    style={{ margin: '0' }}
                                                    color={colors.neutral.N300}
                                                    data-testid="spendingRythmSelectAcurve"
                                                >
                                                    {t('spendingRythm.selectAcurve')}
                                                </Text>
                                                <Text>
                                                    {getInitialSpendingSchemaDropdownName()}
                                                </Text>
                                            </StyledOptionDropdown>
                                        )}
                                    </GridRow>
                                </StyledGridRow>

                                {spendingSchemasForDropdown.length > 0 &&
                                formikProps.values.spendingRythm.variableSpendingSchemaId ? (
                                    <VictoryChart
                                        height={VICTORY_CHARTS_HEIGHT}
                                        width={VICTORY_CHARTS_WIDTH}
                                        maxDomain={{ y: 325 }}
                                        style={{
                                            background: {
                                                fill: colors.other.white,
                                            },
                                        }}
                                        theme={
                                            isManualEntryEcheancier
                                                ? victoryManualEntryTheme
                                                : VictoryTheme.grayscale
                                        }
                                    >
                                        <VictoryAxis
                                            height={VICTORY_CHARTS_HEIGHT}
                                            width={VICTORY_CHARTS_WIDTH}
                                            theme={
                                                isManualEntryEcheancier
                                                    ? victoryManualEntryTheme
                                                    : VictoryTheme.grayscale
                                            }
                                            standalone={false}
                                            tickValues={[325]}
                                            tickFormat={() => '€'}
                                            style={{
                                                tickLabels: {
                                                    fontSize: VICTORY_AXIS_FONT_SIZE,
                                                    fill: VICTORY_AXIS_COLOR,
                                                    fontFamily: VICTORY_AXIS_FONT,
                                                },
                                            }}
                                            dependentAxis
                                            crossAxis
                                        />
                                        <VictoryAxis
                                            height={VICTORY_CHARTS_HEIGHT}
                                            width={VICTORY_CHARTS_WIDTH}
                                            theme={
                                                isManualEntryEcheancier
                                                    ? victoryManualEntryTheme
                                                    : VictoryTheme.grayscale
                                            }
                                            standalone={false}
                                            tickValues={[0, 540]}
                                            tickFormat={(tick) =>
                                                tick === 0 ? startLabel : endLabel
                                            }
                                            style={{
                                                tickLabels: {
                                                    fontSize: VICTORY_AXIS_FONT_SIZE,
                                                    fill: VICTORY_AXIS_COLOR,
                                                    fontFamily: VICTORY_AXIS_FONT,
                                                },
                                            }}
                                            crossAxis
                                        />
                                        <VictoryLine
                                            interpolation="monotoneX"
                                            data={
                                                spendingSchemasForDropdown.find(
                                                    (spendingSchemasOption) =>
                                                        spendingSchemasOption.value ===
                                                        formikProps.values.spendingRythm
                                                            ?.variableSpendingSchemaId,
                                                )?.curvePoints
                                            }
                                            style={{
                                                data: {
                                                    fill: colors.green.G75,
                                                    stroke: isManualEntryEcheancier
                                                        ? colors.neutral.N300
                                                        : colors.blue.B400,
                                                },
                                            }}
                                        />
                                    </VictoryChart>
                                ) : null}
                            </StyledContainerHalfWidth>
                        )}
                </StyledContainerRowDisplay>
            )}
        </StyledContainer>
    );
};
export default SpendingRythmTab;
