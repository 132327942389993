import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import type { Permission } from '../slices/authSlice';
import { selectUserPermissions } from '../slices/authSlice';
import type { Operation } from '../slices/operationSlice';
import { selectOperation } from '../slices/operationSlice';

export const checkPermissions = (
    requiredPermissions: Permission[],
    userPermissions: Permission[],
    operation?: Operation,
): boolean => {
    const intersection = userPermissions.filter((permission: Permission) =>
        requiredPermissions.find((requiredPermission) => {
            if (requiredPermission.code !== permission.code) {
                return false;
            }
            if (requiredPermission.organizationId) {
                if (permission.organizationId) {
                    return permission.organizationId === requiredPermission.organizationId;
                } else if (permission.operationId) {
                    return false;
                } else {
                    return true;
                }
            } else if (requiredPermission.operationId) {
                if (!permission.operationId && permission.organizationId && operation) {
                    if (requiredPermission.code === 'OPERATIONS_READ') {
                        return (
                            permission.organizationId === operation.clientId ||
                            permission.organizationId === operation.projectOwnerId ||
                            permission.organizationId === operation.delegateProjectOwnerId
                        );
                    } else {
                        return (
                            permission.organizationId === operation.clientId ||
                            permission.organizationId === operation.projectOwnerId
                        );
                    }
                } else if (permission.operationId) {
                    return permission.operationId === Number(requiredPermission.operationId);
                } else if (!permission.operationId && !permission.organizationId) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return requiredPermission.code === permission.code;
            }
        }),
    );
    return intersection.length >= requiredPermissions.length;
};

export const usePermissionsCheck = (requiredPermissions: Permission[]) => {
    const userPermissions = useSelector(selectUserPermissions);
    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    const { operationId }: { operationId?: string } = useParams();
    const requiredPermissionsRef = useRef(requiredPermissions);

    const operation = useSelector(selectOperation(Number(operationId)));
    useEffect(() => {
        if (
            userPermissions &&
            userPermissions.length > 0 &&
            requiredPermissionsRef.current.length > 0
        ) {
            setIsAllowed(
                checkPermissions(requiredPermissionsRef.current, userPermissions, operation),
            );
        }
    }, [requiredPermissionsRef, userPermissions, operation]);

    return isAllowed;
};
