import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import type { ToggleProps } from './Toggle';
import PermissionAwareDisplay from './PermissionAwareDisplay';
import Status from './Status';
import type { Permission } from '../slices/authSlice';
import ToggleWithFormik from './ToggleWithFormik';

const Container = styled.section`
    padding: 0.625rem 1rem;
    display: flex;
    align-items: center;
`;

type Props = {
    checked?: boolean;
    editable?: boolean;
    permissionsRequired: Permission[];
    name: string;
    label?: string;
    'data-testid'?: string;
    errorTestId?: string;
    whiteBackground?: boolean;
    isOptional?: boolean;
    statusDisplay?: boolean;
    style?: React.CSSProperties;
} & ToggleProps;

const PermissionAwareToggle: React.FC<Props> = ({
    checked,
    editable,
    name,
    label,
    permissionsRequired,
    aspect = 'large',
    isOptional,
    statusDisplay,
    style,
    ...props
}) => {
    const hasRightToEdit = usePermissionsCheck(permissionsRequired);
    const { t } = useTranslation();
    const fieldLabel = isOptional ? `${label} ${t('general.optional')}` : label;
    const [field] = useField(name);

    let content = (
        <PermissionAwareDisplay
            label={fieldLabel}
            value={checked || field.value ? t('general.yes') : t('general.no')}
        />
    );
    if (hasRightToEdit && editable) {
        content = (
            <Container style={style}>
                <ToggleWithFormik
                    {...props}
                    name={name}
                    label={fieldLabel}
                    aspect={aspect}
                    checked={checked}
                />
            </Container>
        );
    } else if (statusDisplay) {
        content = (
            <Container>
                <Status status={checked || field.value ? 'active' : 'inactive'} />
            </Container>
        );
    }

    return content;
};
export default PermissionAwareToggle;
