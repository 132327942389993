import React from 'react';
import styled from 'styled-components';

import Text from './Text';

import { colors } from '../constants/colors';

const sizes = {
    small: {
        circle: '1.5rem',
        font: '0.75rem',
        lineHeight: '1rem',
        fontWeight: '500',
    },
    medium: {
        circle: '2rem',
        font: '0.875rem',
        lineHeight: '1rem',
        fontWeight: '700',
    },
    large: {
        circle: '3rem',
        font: '1.125rem',
        lineHeight: '1.5rem',
        fontWeight: '800',
    },
};

type CommonProps = {
    size?: 'small' | 'medium' | 'large';
    isActive?: boolean;
};

type Props = CommonProps & {
    text: string;
};

type StyledTextProps = CommonProps & {
    numberOfLetters: number;
};

const Container = styled.div<CommonProps>`
    height: ${(props) => (props.size ? sizes[props.size].circle : sizes.large.circle)};
    width: ${(props) => (props.size ? sizes[props.size].circle : sizes.large.circle)};
    border-radius: 50%;
    background-color: ${(props) => (props.isActive ? colors.blue.B50 : colors.neutral.N75)};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledText = styled(Text)<StyledTextProps>`
    font-size: ${(props) => {
        let fontSize = sizes.small.font;
        if (props.size) {
            fontSize = sizes[props.size].font;
        }

        if (props.numberOfLetters > 2) {
            fontSize = `${(Number(fontSize.split('rem')[0]) / props.numberOfLetters) * 2}rem`;
        }

        return fontSize;
    }};
    font-weight: ${(props) => (props.size ? sizes[props.size].fontWeight : sizes.small.fontWeight)};
    line-height: ${(props) => (props.size ? sizes[props.size].lineHeight : sizes.small.lineHeight)};
    color: ${(props) => (props.isActive ? colors.green.G200 : colors.neutral.N300)};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
`;

const Avatar: React.FC<Props> = ({ size, isActive, text }) => {
    const getInitials = (fullText: string) =>
        fullText
            .split(' ')
            .reduce(
                (previousValue: string, currentValue: string) =>
                    previousValue + currentValue.charAt(0).toUpperCase(),
                '',
            );

    const initials = getInitials(text);

    return (
        <Container size={size} isActive={isActive}>
            <StyledText
                font="Gilroy"
                size={size}
                isActive={isActive}
                numberOfLetters={initials.length}
            >
                {initials}
            </StyledText>
        </Container>
    );
};

export default Avatar;
