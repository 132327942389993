/* eslint-disable @typescript-eslint/no-explicit-any -- TODO: properly type errorHandler params */
import type { Budget } from './budgetSlice';
import type { Operation } from './operationSlice';
import type { Engagement } from './engagementSlice';
import type { Permission } from './authSlice';
import type { IOrganization } from './organizationSlice';
import type { MarketInvoice } from './invoiceSlice';

export type IndexSetting = {
    id?: number;
    priceIndexId: number | null;
    factor: number | null;
};

export type PriceVariationSettingsType = {
    id?: number;
    a: number | null;
    b: number | null;
    shift: number | null;
    m0: string | null;
    actualisationMonth: string | null;
    hasActualisation: boolean;
    hasRevision: boolean;
    indexSettings: IndexSetting[];
    isSimulated?: boolean;
    revisionsAmountHt?: number;
    actualisationsAmountHt?: number;
};

export type PriceVariationSettings = PriceVariationSettingsType | null;

export type SimulationStatus = 'done' | 'in_progress' | 'not_started' | 'errored' | null;

export type SimulationTask = {
    id?: number;
    type: 'amounts' | 'price_variations' | 'amounts_and_price_variations';
    budgetId?: Budget['id'];
    operationId?: Operation['id'];
    engagementId?: Engagement['id'];
    status?: SimulationStatus;
    error?: string;
};

export type QueryParams = {
    page: number;
    query?: string | null;
    match?: 'start' | 'end' | 'exact' | 'any' | null;
    fields?: string[] | null;
    pageSize?: number;
    orderBy?: string;
    order?: 'asc' | 'desc' | string;
    organizationId?: IOrganization['id'];
    operationId?: Operation['id'];
    permissionNeeded?: Permission['code'];
};

export type PaginatedPayload<SpecificType> = {
    data?: SpecificType[];
    page?: number;
    total: number;
};

export type ServerError = {
    message?: string;
    status?: number;
    translationKey: string;
};

export type FileData = {
    base64: string;
    filename: string;
    mimetype: string;
};

export type ImportStatus = 'success' | 'error' | 'warning' | 'confirmation_needed';

export type OperationFromImport = {
    clientCode: string;
    operationCode: number;
    longLabel: string;
};

export type MarketInvoiceFromImport = {
    id: MarketInvoice['id'];
    error: string | null;
};

export type EngagementInvoiceFromImport = {
    displayNumber: string;
    engagementLabel: string;
    nature: string;
    amountHt: number;
    error: string | null;
};

export type MarketFromImport = {
    id: number | null;
    label: string;
    operationId: number;
    externalNumber: string;
    sliceCode: string | null;
    warning: string | null;
    error: string | null;
    marketInvoices?: MarketInvoiceFromImport[];
    engagementInvoices?: EngagementInvoiceFromImport[];
};

export type EngagementFromImport = {
    id: number | null;
    marketId: number | null;
    marketInternalNumber: string;
    marketExternalNumber?: string | null;
    marketSliceCode: string | null;
    marketLabel: string | null;
    externalNumber: string | null;
    label: string | null;
    engagementType: string | null;
    amountHt: number;
    groupCode: string | null;
    warning: string | null;
    error: string | null;
    createdAt: string;
    lastUpdatedAtForElk: string;
};

export type ImportPayload = {
    fileData: { base64: string; filename: string; mimetype: string };
    importConfirmed?: boolean;
    operationId?: Operation['id'];
};

export type ImportResultType = {
    filename: string;
    status: ImportStatus;
    message?: string;
    operations: OperationFromImport[];
    markets?: MarketFromImport[];
    engagements?: EngagementFromImport[];
};

export type SpendingRythm = {
    id?: number;
    fixedSpendingEndShift: number;
    fixedSpendingFactor: number | string | null;
    fixedSpendingStartShift: number;
    variableSpendingEndShift: number;
    variableSpendingFactor: number | string | null;
    variableSpendingSchemaId?: number | null;
    variableSpendingStartShift: number;
    expenseType?: ExpenseType;
    paymentPeriod?: 'at_start' | 'at_end';
};

export const ONE_TIME: ExpenseType = 'one_time';
export const STAGGERED: ExpenseType = 'staggered';

export type ExpenseType = 'one_time' | 'staggered';

export type ListViewType = 'table' | 'card';

export const DEFAULT_ITEMS_PER_PAGE = 12;

export interface ErrorState {
    error: string | null;
    loading: boolean;
    blockedAt?: number | null;
    loginAttempts?: number;
}

export interface ErrorAction {
    payload?: {
        translationKey?: string;
    };
    loading: boolean;
}

export const errorHandler =
    (additionalHandler?: (state: any, action: any) => void | undefined) =>
    (state: any, action: any) => {
        const isAbortError =
            action.payload?.translationKey && action.payload.translationKey === 'errors.status499';

        if (action.payload?.translationKey && !isAbortError) {
            state.error = action.payload.translationKey;
        } else if (!isAbortError) {
            console.error('Error payload with no translation key???', action.payload);
            state.error = 'errors.unexpectedError';
        }

        state.loading = false;
        if (additionalHandler) {
            additionalHandler(state, action);
        }
    };
