import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';

import type { Site } from '../slices/siteSlice';
import CardList from './CardList';
import Table from './Table';
import type { BreadcrumbsProps } from './Breadcrumbs';
import type { ListViewType } from '../slices/common';

type Props = {
    viewType: ListViewType;
    sites: Site[];
    stateForNavigation?: {
        breadcrumbs?: BreadcrumbsProps['paths'];
        // see how it works in the header back arrow button section of the README file
        goBackTo?: string;
    };
};

const SiteList: React.FunctionComponent<Props> = ({ viewType, sites, stateForNavigation }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organizationId } = useParams();
    const headers = {
        label: t('site.list.headers.label'),
        address: t('site.list.headers.address'),
        postalCode: t('site.list.headers.postalCode'),
        city: t('site.list.headers.city'),
        users: t('site.list.headers.userNumber'),
    };

    const transformAddressData = (siteData: Site | Partial<Site>) => {
        let address = `-`;
        if (siteData.address1 && siteData.address2 && siteData.country) {
            address = `${siteData.address1}, ${siteData.address2}, ${siteData.country}`;
        } else if (siteData.address1 && siteData.country) {
            address = `${siteData.address1}, ${siteData.country}`;
        } else if (siteData.address2 && siteData.country) {
            address = `${siteData.address2}, ${siteData.country}`;
        } else if (siteData.address2) {
            address = `${siteData.address1}, ${siteData.address2}`;
        } else if (siteData.country) {
            address = String(siteData.country);
        }
        return address;
    };

    const renderCellMap = {
        label: (siteData: Site | Partial<Site>) => String(siteData.label),
        postalCode: (siteData: Site | Partial<Site>) => String(siteData.postalCode) || `-`,
        address: (siteData: Site | Partial<Site>) => transformAddressData(siteData),
        city: (siteData: Site | Partial<Site>) => String(siteData.city) || `-`,
        users: (siteData: Site | Partial<Site>) => String(siteData.users?.length),
    };

    const mapDataToCardProps = (siteData: Site | Partial<Site>) => {
        let city = '-';
        if (siteData.postalCode && siteData.city) {
            city = `${siteData.postalCode}, ${siteData.city}`;
        } else if (siteData.postalCode || siteData.city) {
            city = siteData.postalCode ?? siteData.city ?? '-';
        }
        return {
            headerTitle: siteData.label ?? '',
            isTooltipOneLine: true,
            bodyData: {
                [String(headers.address)]: transformAddressData(siteData),
                [`${headers.postalCode}, ${headers.city}`]: city,
                [String(headers.users)]: String(siteData.users?.length),
            },
        };
    };

    const handleClick = (row: Site | Partial<Site>) =>
        navigate(`/organizations/${organizationId}/sites/${row.id}`, { state: stateForNavigation });

    return (
        <>
            {viewType === 'card' && (
                <CardList
                    data={sites}
                    mapDataToCardProps={mapDataToCardProps}
                    onCardClick={handleClick}
                    data-testid="siteCardList"
                />
            )}
            {viewType === 'table' && (
                <Table
                    headers={headers}
                    rows={sites}
                    columnSizes="4fr 3fr 2fr 2fr 2fr"
                    renderCellMap={renderCellMap}
                    onRowClick={handleClick}
                    data-testid="siteTableList"
                />
            )}
        </>
    );
};

export default SiteList;
