import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebouncedCallback } from 'use-debounce';

import TextField from './TextField';
import Icon from './Icon';
import { colors } from '../constants/colors';

type Props = {
    name: string;
    'data-testid'?: string;
    onSearch: (value: string) => void;
    defaultValue?: string;
    marginTop?: string;
    autoFocus?: boolean;
    whiteBackground?: boolean;
};

const SearchField: React.FC<Props> = ({
    onSearch,
    defaultValue = '',
    marginTop = '0',
    autoFocus = false,
    whiteBackground = false,
    ...props
}) => {
    const { t } = useTranslation();

    const debouncedCallback = useDebouncedCallback((value: string) => {
        onSearch(value);
    }, 500);
    const [searchedValue, setSearchedValue] = useState(defaultValue);
    const [hasFocus, setHasFocus] = useState(false);

    useEffect(() => {
        if (hasFocus) {
            debouncedCallback(searchedValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies changes
    }, [searchedValue, debouncedCallback]);

    return (
        <TextField
            type="text"
            value={searchedValue}
            placeholder={t('general.search')}
            leftComponent={<Icon name="Search" color={colors.neutral.N200} />}
            onChange={(e) => setSearchedValue(e.target.value)}
            onFocus={() => {
                setHasFocus(true);
            }}
            onBlur={() => {
                setHasFocus(false);
            }}
            marginTop={marginTop}
            autoFocus={autoFocus}
            whiteBackground={whiteBackground}
            {...props}
        />
    );
};

export default SearchField;
