import type { FormikProps } from 'formik';

export async function validateFormFields<T>(fields: string[], formikProps: FormikProps<T>) {
    fields.forEach((field) => {
        formikProps.setFieldTouched(field);
    });

    const errors = await formikProps.validateForm();

    const errorsOnThisTab = Object.keys(errors).filter((key) => fields.includes(key));

    return errorsOnThisTab.length === 0;
}
