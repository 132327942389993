import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import type { CSSProperties } from 'react';
import React from 'react';

import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import type { TextFieldWithFormikProps } from './TextFieldWithFormik';
import TextFieldWithFormik from './TextFieldWithFormik';
import PermissionAwareDisplay from './PermissionAwareDisplay';
import type { Permission } from '../slices/authSlice';
import { NumberFormatter, CurrencyFormatter } from '../utils/formatters';
import { colors } from '../constants/colors';

export type PermissionAwareTextProps = TextFieldWithFormikProps & {
    displayStyle?: CSSProperties;
    displayRightComponent?: React.ReactNode;
    permissionsRequired: Permission[];
    defaultEmptyValue?: string;
    editable?: boolean;
    noMarginTop?: boolean;
    inputHeight?: string;
    inputWidth?: string;
    hasTooltip?: boolean;
    shouldRenderGlobalStyleForTooltip?: boolean;
    numberType?: 'amount' | 'percentage' | 'factor' | 'shift' | 'decimal';
    displayPlaceHolderIfNull?: boolean;
    isOptional?: boolean;
    type?: string;
};

const PermissionAwareText: React.FC<PermissionAwareTextProps> = ({
    displayStyle,
    displayRightComponent,
    name,
    label,
    defaultEmptyValue,
    permissionsRequired,
    value,
    editable,
    marginTop,
    noMarginTop,
    inputHeight,
    inputWidth,
    textAlign,
    hasTooltip = false,
    numberType,
    displayPlaceHolderIfNull,
    shouldRenderGlobalStyleForTooltip = true,
    isOptional,
    type,
    ...props
}) => {
    const { t } = useTranslation();
    const hasRightToEdit = usePermissionsCheck(permissionsRequired);
    const [field] = useField(name);
    let component = null;
    let displayedValueStyle = {};
    const fieldLabel = isOptional ? `${label} ${t('general.optional')}` : label;

    let displayedValue = value ?? field.value;

    if (displayedValue !== undefined && numberType === 'decimal') {
        displayedValue = NumberFormatter.format(displayedValue);
    }

    if (displayedValue !== undefined && numberType === 'amount') {
        displayedValue = CurrencyFormatter.format(displayedValue);
    }

    if (!displayedValue && displayPlaceHolderIfNull) {
        displayedValue = props.placeholder;
        displayedValueStyle = { color: String(colors.neutral.N300) };
    }

    const displayedEmptyValue = defaultEmptyValue ?? t('errors.notFilled');

    if (hasRightToEdit && editable) {
        let pattern = '';
        switch (numberType) {
            case 'decimal':
            case 'amount':
                pattern = '-?[0-9 ]*[.,]?[0-9]{0,2}';
                break;
            case 'percentage':
                pattern = '-?[0-9 ]{0,3}[.,]?[0-9]{0,2}';
                break;
            case 'factor':
                pattern = '(-?[0][.,]?[0-9]{0,3}|[1])';
                break;
            case 'shift':
                pattern = '-?[0-9]{0,2}';
                break;
            default:
                break;
        }

        component = (
            <TextFieldWithFormik
                type={type}
                {...props}
                {...(numberType ? { pattern, inputMode: 'numeric', numberType } : {})}
                name={name}
                label={fieldLabel}
                marginTop={marginTop}
                inputHeight={inputHeight}
                inputWidth={inputWidth}
                textAlign={textAlign}
            />
        );
    } else {
        component = (
            <PermissionAwareDisplay
                label={fieldLabel}
                value={displayedValue ?? displayedEmptyValue}
                data-testid={`${props['data-testid']}-show`}
                style={{
                    ...(noMarginTop ? { marginTop: 0 } : {}),
                    ...(displayStyle ?? {}),
                    ...(textAlign ? { textAlign } : {}),
                }}
                valueStyle={{ ...displayedValueStyle }}
                rightComponent={displayRightComponent}
                hasTooltip={hasTooltip}
                shouldRenderGlobalStyleForTooltip={shouldRenderGlobalStyleForTooltip}
                isNumeral={Boolean(numberType)}
            />
        );
    }

    return component;
};

export default PermissionAwareText;
