import type { CSSProperties } from 'react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import type { Breadcrumb } from './Breadcrumbs';
import Breadcrumbs from './Breadcrumbs';
import type { ButtonProps } from './Button';
import Button, { checkIfButtonTextIsAReactNode } from './Button';
import Icon from './Icon';
import Text from './Text';
import type { TabsProps } from './Tabs';
import Tabs from './Tabs';
import { colors } from '../constants/colors';
import { selectOrganization, getOrganization } from '../slices/organizationSlice';
import type { BadgeProps } from './Badge';
import Badge from './Badge';
import { checkPermissions } from '../hooks/usePermissionsCheck';
import { selectUserPermissions } from '../slices/authSlice';
import { selectOperation } from '../slices/operationSlice';
import { useAppDispatch } from '../store';

const Container = styled.header`
    @media print {
        padding: 0;
        margin: 0;
    }
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: header;
`;

const TitleContainer = styled.div<{ noTitlePadding?: boolean }>`
    @media print {
        padding: 0;
    }
    display: flex;
    flex: 1;
    ${({ noTitlePadding }) => !noTitlePadding && `padding: 1.5rem 2rem;`}
    align-items: center;
    height: 4.25rem;
`;

const ButtonsContainer = styled.div`
    @media print {
        display: none;
    }
    display: flex;

    & > div {
        margin-left: 1rem;
    }
`;

const BackButtonContainer = styled.div`
    @media print {
        display: none;
    }
    display: flex;
    cursor: pointer;

    &:active {
        outline: none;
    }
`;

const Separator = styled.div`
    width: 0.0625rem;
    background-color: ${colors.neutral.N100};
    margin-right: 1.5em;
`;

const Title = styled.div<{ marginTop?: string }>`
    flex: 1;
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
    > *:first-child {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

type Props = {
    breadcrumbs?: Breadcrumb[];
    buttons?: ButtonProps[] | null;
    goBackTo?: string;
    isDirty?: boolean;
    title: string;
    showTitle?: boolean;
    showBackButton?: boolean;
    showNavigation?: boolean;
    tabs?: TabsProps;
    style?: CSSProperties;
    subTitle?: React.ReactNode;
    badges?: BadgeProps | BadgeProps[];
    // overload gobackTo behaviour if defined
    backArrowCallBack?: () => void;
    shouldSetHelmet?: boolean;
    noTitlePadding?: boolean;
};

export type HeaderProps = Props;

const Header: React.FC<Props> = ({
    buttons,
    breadcrumbs,
    goBackTo,
    isDirty,
    title,
    showTitle = true,
    showNavigation = true,
    showBackButton,
    tabs,
    style,
    subTitle,
    badges,
    backArrowCallBack,
    shouldSetHelmet = true,
    noTitlePadding = false,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { organizationId, operationId }: { organizationId?: string; operationId?: string } =
        useParams();

    const organization = useSelector(selectOrganization(organizationId));
    const operation = useSelector(selectOperation(Number(operationId)));
    const userPermissions = useSelector(selectUserPermissions);

    useEffect(() => {
        if (!organization && organizationId) {
            void dispatch(getOrganization(organizationId));
        }
    }, [dispatch, organization, organizationId]);

    // see how it works in the header back arrow button section of the README file
    const goBack = () => {
        if (goBackTo) {
            navigate(goBackTo);
        } else if (location.state?.goBackTo) {
            navigate(location.state.goBackTo);
        } else {
            navigate(-1);
        }
    };

    const renderBadges = () => {
        if (badges instanceof Array) {
            return badges.map((badge: BadgeProps) => <Badge key={badge.text} {...badge} />);
        } else {
            return <Badge {...(badges as BadgeProps)} />;
        }
    };

    const displayTabsAccordingToUserPermissions = () => {
        if (tabs) {
            const tabsToDisplay = tabs.filter((tab) => {
                if (tab.requiredPermissions) {
                    const requiredPermissions = tab.requiredPermissions.map((permission) => {
                        if (permission.operationId && operationId) {
                            return {
                                ...permission,
                                operationId,
                            };
                        }
                        if (permission.organizationId && organizationId) {
                            return {
                                ...permission,
                                organizationId,
                            };
                        }
                        return permission;
                    });
                    const hasRightToDisplayTab = checkPermissions(
                        requiredPermissions,
                        userPermissions ?? [],
                        operation,
                    );
                    return hasRightToDisplayTab;
                }
                return true;
            });
            return tabsToDisplay;
        }
        return [];
    };

    return (
        <Container style={style}>
            {shouldSetHelmet && (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            )}
            {showNavigation && breadcrumbs && <Breadcrumbs paths={breadcrumbs} />}
            {showTitle && (
                <TitleContainer data-testid="headerTitle" noTitlePadding={noTitlePadding}>
                    {showBackButton && (
                        <BackButtonContainer
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                if (isDirty) {
                                    // eslint-disable-next-line no-alert -- necessary
                                    if (window.confirm(t('errors.confirmQuit'))) {
                                        goBack();
                                    }
                                } else if (backArrowCallBack) {
                                    backArrowCallBack();
                                } else {
                                    goBack();
                                }
                            }}
                            data-testid="backarrow"
                        >
                            <Icon
                                name="ArrowBackIos"
                                color={colors.blue.B400}
                                style={{ marginRight: '1.1875rem' }}
                            />
                            <Separator />
                        </BackButtonContainer>
                    )}
                    {badges && renderBadges()}
                    <Title marginTop={subTitle ? '2rem' : undefined}>
                        <Text type="H700">{title}</Text>
                        {subTitle ?? null}
                    </Title>

                    {buttons?.map((button, index) => (
                        // eslint-disable-next-line react/no-array-index-key -- no other choice than using index
                        <React.Fragment key={index}>
                            {checkIfButtonTextIsAReactNode(button.text) ? (
                                button.text
                            ) : (
                                <Button
                                    {...button}
                                    size="medium"
                                    style={{ marginLeft: '1.3125rem' }}
                                />
                            )}
                        </React.Fragment>
                    ))}
                    <ButtonsContainer id="buttonsContainer" />
                </TitleContainer>
            )}
            {showNavigation && tabs && <Tabs tabs={displayTabsAccordingToUserPermissions()} />}
        </Container>
    );
};

export default Header;
