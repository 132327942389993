import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const getPageNumber = (locationSearch: string) => {
    if (locationSearch && locationSearch.length > 0) {
        const pageNumber = Number(locationSearch.replace(/^\D+/g, ''));
        if (pageNumber > 0) {
            return Number(pageNumber);
        }
    }
    return 1;
};

export const usePageNumber = () => {
    const location = useLocation();
    return useMemo(() => getPageNumber(location.search), [location.search]);
};
