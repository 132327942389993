import React from 'react';

import type { MaybeElement } from '@blueprintjs/core';
import { Position } from '@blueprintjs/core';
import { createGlobalStyle } from 'styled-components';

import { createToaster } from '@hcfy/create-toaster';

import Icon from './Icon';
import Text from './Text';
import { colors } from '../constants/colors';

/** Singleton toaster instance. Create separate instances for different options. */
const AppToasterPromise = createToaster({
    position: Position.TOP,
});

const successIcon: MaybeElement = (
    <Icon name="CheckCircle" color={colors.green.G400} width="1.5rem" />
);
const errorIcon: MaybeElement = <Icon name="Cancel" color={colors.red.R500} width="1.5rem" />;
const warningIcon: MaybeElement = <Icon name="Warning" color={colors.yellow.Y500} width="1.5rem" />;

const GlobalStyle = createGlobalStyle`
    .bp4-toast {
        background-color: ${colors.other.white};
        border: 0.0625rem solid ${colors.neutral.N75};
        box-sizing: border-box;
        box-shadow: 0 1.25rem 1.25rem ${colors.neutral.N75};
        border-radius: 0.625rem;
        > .bp4-minimal .bp4-button {
            padding: 0;
            margin: 0.625rem 0.3125rem;
            :hover {
                background: none;
            }
            > .bp4-icon {
                color: ${colors.blue.B400};
                svg {
                    margin: 0;
                }
            }
        }
        svg {
            margin: 1.25rem 1rem;
        }
        .bp4-toast-message {
            padding: 1rem 0;
        }
    }
`;

export const showFlag = (type: 'success' | 'error' | 'warning', title: string, text: string) => {
    let icon: MaybeElement;

    switch (type) {
        case 'success':
            icon = successIcon;
            break;
        case 'error':
            icon = errorIcon;
            break;
        case 'warning':
            icon = warningIcon;
            break;
        default:
            console.error(`No icon for type: ${type}.`);
            return null;
    }

    void AppToasterPromise.then((toaster) => {
        toaster.show({
            icon,
            message: (
                <>
                    <GlobalStyle />
                    <Text type="H800" size="0.875rem" style={{ lineHeight: '1rem' }}>
                        {title}
                    </Text>
                    <Text type="small" color={colors.neutral.N400}>
                        {text}
                    </Text>
                </>
            ),
        });
    });
};
