import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { isAfter } from 'date-fns';

// Style
import { styles } from '../../constants/styles';

// Components
import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import EngagementInformationCard from '../../components/EngagementInformationCard';
import Button from '../../components/Button';
import { CurrencyFormatter } from '../../utils/formatters';
import Text from '../../components/Text';
import EmptyState from '../../components/EmptyState';
import EngagementLine from '../../components/EngagementLine';
import { colors } from '../../constants/colors';
import { showFlag } from '../../components/Flag';
import SimulationButton from '../../components/SimulationButton';
import Tooltip from '../../components/Tooltip';
import Icon from '../../components/Icon';

// Data
import { getSpecificLabels } from '../../slices/labelSlice';
import {
    getMarket,
    selectMarket,
    selectMarketName,
    selectError as selectMarketError,
} from '../../slices/marketSlice';
import type { Engagement } from '../../slices/engagementSlice';
import {
    getEngagements,
    selectEngagements,
    getBudgetLinesForEngagements,
    selectValidatedBudgetLines,
    selectError,
    selectEngagementIdDeleted,
    resetEngagementDeleted,
    selectGetEngagementsLoading,
} from '../../slices/engagementSlice';
import {
    getOperation,
    selectOperation,
    getOperationHasEngagement,
    selectOperationHasEngagement,
} from '../../slices/operationSlice';
import { getGroupCodeSequence } from '../../slices/groupCodeSequenceSlice';
import { getTvaCodes } from '../../slices/tvaCodeSlice';

// Utils
import {
    calculateTotalAmountHtByMarketType,
    calculateVariationsAmountHtTotal,
} from '../../utils/engagementCalculation';
import { formatDateFromDb } from '../../utils/date';
import Loader from '../../components/Loader';
import { useAppDispatch } from '../../store';

type Props = {
    headerProps?: Partial<HeaderProps>;
};

const StyledEmptyState = styled(EmptyState)`
    margin-top: 4.125rem;
`;

const StyledGridCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledHeaderText = styled(Text)`
    margin-bottom: 0;
    font-weight: 500;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
`;

// Aligned column headers and content by letting an extra padding-right equivalent to the ContextualMenuIcon width
const EngagementLinesHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0 0.625rem;
    background-color: ${colors.neutral.N75};
    color: ${colors.neutral.N400};
    padding: 1rem calc(1rem + 1.5rem) 1rem 1rem;
    border-radius: ${styles.borderRadiusSmall};
`;

const Flex = styled.div`
    display: flex;
`;

const Engagements: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        operationId,
        marketId,
    }: {
        operationId?: string;
        marketId?: string;
    } = useParams();

    const market = useSelector(selectMarket(Number(marketId)));
    const marketName = useSelector(selectMarketName(Number(marketId)));
    const engagements: Engagement[] = useSelector(selectEngagements);
    const isLoading = useSelector(selectGetEngagementsLoading);
    const engagementIdDeleted = useSelector(selectEngagementIdDeleted);
    const marketServerError = useSelector(selectMarketError);

    const permissionNeededToAdd = {
        code: 'ENGAGEMENTS_ADD',
        operationId,
    };
    const permissionNeededToRead = {
        code: 'ENGAGEMENTS_READ',
        operationId,
    };
    const hasRightToAddEngagements = usePermissionsCheck([permissionNeededToAdd]);
    const hasRightToReadEngagements = usePermissionsCheck([permissionNeededToRead]);
    const hasRightToEditEngagements = usePermissionsCheck([
        {
            code: 'ENGAGEMENTS_EDIT',
            operationId,
        },
    ]);
    const hasRightToReadBudgets = usePermissionsCheck([
        {
            code: 'BUDGETS_READ',
            operationId,
        },
    ]);

    const dispatch = useAppDispatch();
    const budgetLinesForDropdown = useSelector(selectValidatedBudgetLines);
    const operation = useSelector(selectOperation(Number(operationId)));
    const operationHasEngagements = useSelector(selectOperationHasEngagement(Number(operationId)));

    const [
        openEcheancierModalOnLastCreatedEngagement,
        setOpenEcheancierModalOnLastCreatedEngagement,
    ] = useState<boolean>(false);

    useEffect(() => {
        if (operationId && marketId) {
            void dispatch(
                getMarket({
                    id: Number(marketId),
                    operationId: Number(operationId),
                }),
            );
            void dispatch(
                getSpecificLabels({
                    type: 'engagement',
                    operationId: Number(operationId),
                }),
            );
            void dispatch(
                getEngagements({
                    marketId: Number(marketId),
                    operationId: Number(operationId),
                }),
            );
        } else {
            void dispatch(
                getSpecificLabels({
                    type: 'engagement',
                }),
            );
        }
    }, [dispatch, operationId, marketId]);

    useEffect(() => {
        if (marketServerError === 'errors.marketMustExist') {
            navigate(`/operations/${operationId}/markets`);
        }
    }, [marketServerError, navigate, operationId]);

    useEffect(() => {
        void dispatch(getOperation(Number(operationId)));
        void dispatch(getOperationHasEngagement({ operationId: Number(operationId) }));
        void dispatch(
            getBudgetLinesForEngagements({
                operationId: Number(operationId),
            }),
        );
        void dispatch(getTvaCodes({ operationId: Number(operationId) }));
    }, [dispatch, operationId]);

    useEffect(() => {
        if (operation) {
            void dispatch(getGroupCodeSequence({ id: operation.groupCodeSequenceId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Don't want to rerender on every dependencies change
    }, [operation?.groupCodeSequenceId]);

    // Messages
    const serverError = useSelector(selectError);
    const successMessage = t('general.success');
    const errorMessage = t('errors.error');
    const deletedMessage = t('engagement.lineDeleted');

    let serverErrorMessage = '';

    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    useEffect(() => {
        // Should handle delete engagement
        if (engagementIdDeleted) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
            } else {
                showFlag('success', successMessage, deletedMessage);
                void dispatch(
                    getEngagements({
                        operationId: Number(operationId),
                        marketId: Number(marketId),
                    }),
                );
            }
            if (engagementIdDeleted) {
                dispatch(resetEngagementDeleted());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Don't want to rerender on every dependencies change
    }, [serverError, engagementIdDeleted]);

    const marketInitialValue = calculateTotalAmountHtByMarketType(engagements, 'market');

    const marketOSValue = calculateTotalAmountHtByMarketType(engagements, 'service_order');

    const marketAmendmentValue = calculateTotalAmountHtByMarketType(engagements, 'amendment');

    const marketPursuitDescisionValue = calculateTotalAmountHtByMarketType(
        engagements,
        'pursuit_descision',
    );

    const marketOtherValue = calculateTotalAmountHtByMarketType(engagements, 'other');

    const totalValidEngagement =
        marketInitialValue +
        marketOSValue +
        marketAmendmentValue +
        marketPursuitDescisionValue +
        marketOtherValue;

    const priceVariationTotal = calculateVariationsAmountHtTotal(engagements);

    const globalTotal = totalValidEngagement + priceVariationTotal;

    const checkIfEngagementHasOutdatedEcheancier = (engagement: Engagement) =>
        !engagement.echeancierLastCalculationAt ||
        isAfter(
            new Date(engagement.lastUpdatedAtForElk),
            new Date(engagement.echeancierLastCalculationAt),
        );
    return (
        <MainLayout
            header={
                <Header
                    {...headerProps}
                    title={!marketName ? t('market.defaultLabel') : marketName}
                    buttons={[
                        {
                            text: (
                                <SimulationButton
                                    hasRightToEdit={
                                        hasRightToEditEngagements || hasRightToAddEngagements
                                    }
                                    operationId={Number(operationId)}
                                    type="operation"
                                    marketId={Number(marketId)}
                                />
                            ),
                        },
                    ]}
                    subTitle={
                        operation && operationHasEngagements ? (
                            <Flex>
                                <Tooltip
                                    content={formatDateFromDb(
                                        operation.lastSimulationAt,
                                        'dd/MM/yyyy HH:mm:ss',
                                    )}
                                >
                                    <Text color={colors.blue.B500}>
                                        {t('markets.lastSimulationOn')}{' '}
                                        {formatDateFromDb(operation.lastSimulationAt, 'dd/MM/yyyy')}
                                    </Text>
                                </Tooltip>
                                {operation.lastEngagementsUpdatedAt &&
                                    isAfter(
                                        new Date(operation.lastEngagementsUpdatedAt),
                                        new Date(operation.lastSimulationAt),
                                    ) && (
                                        <Icon
                                            name="Error"
                                            color={colors.yellow.Y400}
                                            data-testid="ErrorIcon"
                                            style={{ marginLeft: '0.5rem' }}
                                        />
                                    )}
                            </Flex>
                        ) : null
                    }
                    showBackButton
                    showNavigation
                />
            }
            smallContentSidePadding
        >
            {market && hasRightToReadEngagements && (
                <EngagementInformationCard
                    totals={[
                        {
                            label: t('engagement.globalTotal'),
                            value: CurrencyFormatter.format(globalTotal),
                        },
                        {
                            label: t('engagement.totalValidValueHt'),
                            value: CurrencyFormatter.format(totalValidEngagement),
                            subtotals: [
                                {
                                    label: t('engagement.initialMarketValue'),
                                    value: CurrencyFormatter.format(marketInitialValue),
                                },
                                {
                                    label: t('engagement.OSValue'),
                                    value: CurrencyFormatter.format(marketOSValue),
                                },

                                {
                                    label: t('engagement.amendment'),
                                    value: CurrencyFormatter.format(marketAmendmentValue),
                                },

                                {
                                    label: t('engagement.pursuitValue'),
                                    value: CurrencyFormatter.format(marketPursuitDescisionValue),
                                },
                                {
                                    label: t('engagement.otherValue'),
                                    value: CurrencyFormatter.format(marketOtherValue),
                                },
                            ],
                            isCollapsable: true,
                        },
                        {
                            label: t('engagement.calculatedVariations'),
                            value: CurrencyFormatter.format(priceVariationTotal),
                            tooltipContent: t('engagement.calculatedVariationsTooltip'),
                        },
                    ]}
                />
            )}
            {engagements.length > 0 &&
                hasRightToReadEngagements &&
                budgetLinesForDropdown.length > 0 && (
                    <>
                        <EngagementLinesHeader>
                            <StyledGridCol
                                style={{
                                    width: '15%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.number')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            {market?.isJointGroupMarket && (
                                <StyledGridCol
                                    style={{
                                        width: '5%',
                                    }}
                                >
                                    <StyledHeaderText type="small">
                                        {t('engagement.list.groupCode')}
                                    </StyledHeaderText>
                                </StyledGridCol>
                            )}
                            <StyledGridCol
                                style={{
                                    width: '10%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.label')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            <StyledGridCol
                                style={{
                                    width: '7.5%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.notification')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            <StyledGridCol
                                style={{
                                    width: '7.5%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.type')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            <StyledGridCol
                                style={{
                                    width: '5%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.labels')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            <StyledGridCol
                                style={{
                                    width: '15%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.amountHt')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            <StyledGridCol
                                style={{
                                    width: '6%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.taxes')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            <StyledGridCol
                                style={{
                                    width: '6%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.rg')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            <StyledGridCol
                                style={{
                                    width: '6%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.advance')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            <StyledGridCol
                                style={{
                                    width: '6%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.variation')}
                                </StyledHeaderText>
                            </StyledGridCol>
                            <StyledGridCol
                                style={{
                                    width: '7%',
                                }}
                            >
                                <StyledHeaderText type="small">
                                    {t('engagement.list.echeancier')}
                                </StyledHeaderText>
                            </StyledGridCol>
                        </EngagementLinesHeader>
                        {engagements.map((engagement) => (
                            <EngagementLine
                                key={`line-${engagement.id}`}
                                id={engagement.id}
                                marketInternalNumber={market?.internalNumber}
                                engagementHasOutdatedEcheancier={checkIfEngagementHasOutdatedEcheancier(
                                    engagement,
                                )}
                                openEcheancierModalOnLastCreatedEngagement={
                                    openEcheancierModalOnLastCreatedEngagement
                                }
                                setOpenEcheancierModalOnLastCreatedEngagement={
                                    setOpenEcheancierModalOnLastCreatedEngagement
                                }
                            />
                        ))}
                        {hasRightToAddEngagements &&
                        market?.marketEntryMode === 'manual_entry' &&
                        engagements.length > 0 ? (
                            <EngagementLine
                                isFirstEngagement={false}
                                id={null}
                                marketInternalNumber={market.internalNumber}
                                openEcheancierModalOnLastCreatedEngagement={
                                    openEcheancierModalOnLastCreatedEngagement
                                }
                                setOpenEcheancierModalOnLastCreatedEngagement={
                                    setOpenEcheancierModalOnLastCreatedEngagement
                                }
                            />
                        ) : null}
                    </>
                )}
            {!isLoading && engagements.length === 0 && (
                <>
                    {budgetLinesForDropdown.length > 0 && (
                        <div style={{ marginTop: '2rem' }}>
                            {hasRightToAddEngagements &&
                                market?.marketEntryMode === 'manual_entry' && (
                                    <EngagementLine
                                        id={null}
                                        openEcheancierModalOnLastCreatedEngagement={
                                            openEcheancierModalOnLastCreatedEngagement
                                        }
                                        setOpenEcheancierModalOnLastCreatedEngagement={
                                            setOpenEcheancierModalOnLastCreatedEngagement
                                        }
                                        isFirstEngagement
                                    />
                                )}
                        </div>
                    )}
                    {budgetLinesForDropdown.length === 0 && (
                        <StyledEmptyState
                            data-testid="errorNoBudgetLines"
                            imageName="Lines"
                            titleTranslationKey="budget.noBudgetFallback"
                            button={
                                hasRightToReadBudgets && (
                                    <Button
                                        onClick={() => {
                                            navigate(`/operations/${operationId}/budgets`);
                                        }}
                                        text={t('budget.addBudgetFallback')}
                                        aspect="primary"
                                        size="medium"
                                        data-testid="addBudgetLine"
                                    />
                                )
                            }
                        />
                    )}
                </>
            )}
            {isLoading && engagements.length === 0 && <Loader />}
        </MainLayout>
    );
};

export default Engagements;
