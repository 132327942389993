import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    getBudgetLinesForEngagements,
    selectValidatedBudgetLines,
} from '../slices/engagementSlice';
import type { Operation } from '../slices/operationSlice';
import {
    getOperationEngagementsMatch,
    selectOperationEngagementMatching,
} from '../slices/operationSlice';
import { useAppDispatch } from '../store';
import type { EngagementMatching } from './BudgetLinesMatchingEngagementsModal';
import BudgetLinesMatchingEngagementsModal from './BudgetLinesMatchingEngagementsModal';

type Props = {
    onSubmit: (values: EngagementMatching[]) => void;
    onCancelClick: () => void;
    'data-testid'?: string;
    isOpen: boolean;
    operationId: Operation['id'];
};

const BudgetLinesMatchingEngagementsModalAfterEngagementsImport = ({
    operationId,
    ...props
}: Props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const budgetLinesApprovedBudget = useSelector(selectValidatedBudgetLines);
    const engagementMatching = useSelector(selectOperationEngagementMatching(Number(operationId)));

    useEffect(() => {
        void dispatch(
            getOperationEngagementsMatch({
                id: Number(operationId),
            }),
        );
        void dispatch(
            getBudgetLinesForEngagements({
                operationId: Number(operationId),
            }),
        );
    }, [dispatch, operationId]);

    return (
        <BudgetLinesMatchingEngagementsModal
            {...props}
            currentBudgetLines={budgetLinesApprovedBudget}
            newBudgetLines={budgetLinesApprovedBudget}
            engagementMatching={engagementMatching}
            oldBudgetLinesHeaderTitle={t('budget.budgetLine')}
            newBudgetLinesHeaderTitle={t('budget.newBudgetLine')}
        />
    );
};
export default BudgetLinesMatchingEngagementsModalAfterEngagementsImport;
