import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError } from './common';
import { errorHandler } from './common';

import type { Operation } from './operationSlice';

export type TvaCode = {
    id: number;
    code: string | null;
    value: number;
    label: string | null;
    startExecutionDate: string;
    endExecutionDate: string | null;
    operationId: Operation['id'] | null;
};

export type TvaCodeFormData = {
    id?: number | undefined;
    value: number | string | null;
    operationId?: Operation['id'] | null | undefined;
    isUsedInDb?: boolean | undefined;
};

interface ITvaCodeState {
    tvaCodesById: Record<number, TvaCode>;
    loading: boolean;
    error: string | null;
}

export const initialState: ITvaCodeState = {
    tvaCodesById: {},
    loading: false,
    error: null,
};

export const getTvaCodes = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    TvaCode[],
    // First argument to the payload creator
    {
        operationId?: Operation['id'] | undefined;
    },
    {
        rejectValue: ServerError;
    }
>('TVA_CODE/GETALL', async ({ operationId }, { rejectWithValue }) => {
    try {
        let path = '/tva-codes';
        if (operationId) {
            path = `/operations/${operationId}${path}`;
        }
        const response = await axios.get<{ data: TvaCode[] }>(path);
        if (Array.isArray(response.data.data)) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const slice = createSlice({
    name: 'tvaCode',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getTvaCodes.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTvaCodes.fulfilled, (state, { payload }) => {
            state.tvaCodesById = {};
            payload.forEach((item: TvaCode) => {
                state.tvaCodesById[item.id] = item;
            });
            state.loading = false;
        });
        builder.addCase(getTvaCodes.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.tvaCode.error;
export const selectTvaCodes = (state: RootState) => Object.values(state.tvaCode.tvaCodesById);
export const selectTvaCodesById = (state: RootState) => state.tvaCode.tvaCodesById;
export const selectTvaCodeById =
    (id: TvaCode['id']) =>
    (state: RootState): TvaCode | undefined =>
        state.tvaCode.tvaCodesById[id];

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
