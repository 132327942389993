import { useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { MenuItem } from '@blueprintjs/core';
import type { ISelectProps as Props, IItemRendererProps } from '@blueprintjs/select';
import { Select as BPSelect } from '@blueprintjs/select';

import Button from '../Button';
import { SelectStyle } from './dropdownStyle';
import type { Option } from '../SelectField';
import { StyledLabel } from '../PermissionAwareDisplay';
import type { LabelValue, Label } from '../../slices/labelSlice';
import FieldErrorMessage from '../FieldErrorMessage';

import { colors } from '../../constants/colors';

export type StatusOption = Option<string>;

export type ISelectProps = {
    ['data-testid']?: string;
    items: Props<LabelValue>['items'];
    itemRenderer?: Props<LabelValue>['itemRenderer'];
    noResults?: Props<LabelValue>['noResults'];
    onItemSelect: Props<LabelValue>['onItemSelect'];
    handleChange?: (labelValue: LabelValue) => void;
    labelValueDisplay: string;
    label?: string;
    name: string;
    selectedLabelValues: Array<{
        labelId: Label['id'];
        id: LabelValue['id'];
        hasLinks: boolean;
        value: string;
    }>;
    currentLabelId: Label['id'];
    error?: boolean;
    isMandatory?: boolean;
};

// define type of data going through the select
const Select = BPSelect.ofType<LabelValue>();

const LabelValueDropdown: React.FC<PropsWithChildren<ISelectProps>> = ({
    children,
    items,
    labelValueDisplay,
    label,
    name,
    selectedLabelValues,
    currentLabelId,
    error,
    isMandatory,
    ...props
}) => {
    const { t } = useTranslation();

    // Render items inside select
    const renderData = (labelValue: LabelValue, { handleClick }: IItemRendererProps) => (
        <MenuItem
            key={labelValue.id}
            text={labelValue.value}
            onClick={handleClick}
            tabIndex={0}
            data-testid={`${testId}-dropdown-item`}
        />
    );
    const testId = props['data-testid'] ?? 'test';

    // selectedValuesToBeEvaluated = selectedValues not in label list diplayed to display all values for this dropdown
    // and values for which there is links (we don't have to search links if value hasn't links)
    const selectedValuesToBeEvaluated = selectedLabelValues.filter(
        ({ labelId, hasLinks }) => labelId !== currentLabelId && hasLinks,
    );

    const computeLabelValues = (labelValuesList: LabelValue[]) => {
        let filteredList = [...labelValuesList];
        if (selectedValuesToBeEvaluated.length > 0) {
            filteredList = labelValuesList.filter((labelValueToFilter: LabelValue) => {
                const hasLinks = Boolean(
                    labelValueToFilter.linkedLabelValues &&
                        labelValueToFilter.linkedLabelValues.length > 0,
                );
                if (!hasLinks) {
                    return true;
                }
                // for each labelValue we search in its linkedLabelValues if there is a value selected in others labelValues dropdowns
                const foundLink = labelValueToFilter.linkedLabelValues?.find(({ labelValueId }) =>
                    Boolean(
                        selectedValuesToBeEvaluated.find(
                            (selectedLabelValue) => selectedLabelValue.id === labelValueId,
                        ),
                    ),
                );
                return Boolean(foundLink);
            });
        }
        return [
            {
                id: 0,
                value: t('general.noValue'),
                labelId: currentLabelId,
                linkedLabelValues: [],
            },
            ...filteredList,
        ];
    };

    return (
        <div className={`dropdown${error ? '-error' : ''}`} data-testid={`${testId}-dropdown`}>
            {label && (
                <StyledLabel color={colors.neutral.N300} style={{ marginBottom: '0' }}>
                    {isMandatory ? label : `${label} ${t('general.optional')}`}
                </StyledLabel>
            )}
            <SelectStyle />
            <Select
                items={computeLabelValues(items)}
                itemRenderer={props.itemRenderer ?? renderData}
                onItemSelect={props.onItemSelect}
                filterable={false}
                data-testid={`${testId}-select`}
            >
                <Button
                    aspect="dropdown"
                    iconName="ArrowDropDown"
                    iconSize="1.25rem"
                    text={labelValueDisplay}
                    data-testid={`${testId}-dropdown-button`}
                />
            </Select>
            {error && (
                <FieldErrorMessage data-testid={`errorLabelValueDropdown-${currentLabelId}`}>
                    {t('errors.required')}
                </FieldErrorMessage>
            )}
        </div>
    );
};

export default LabelValueDropdown;
