import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';

import type { User } from '../slices/userSlice';
import Table from './Table';
import Avatar from './Avatar';
import Icon from './Icon';
import { colors } from '../constants/colors';
import Badge from './Badge';
import type { IRole } from '../slices/roleSlice';
import type { Operation } from '../slices/operationSlice';
import UserRoleBadge from './UserRoleBadge';
import { checkHowManyRolesUserHasOnOperation } from '../utils/userRoles';
import Popover, { PopoverContent, PopoverItem } from './Popover';
import Elevation from './Elevation';
import type { RenderCellMapType } from './TableRow';

const StyledText = styled.p`
    margin: auto auto auto 0.5rem;
`;

const ContextualMenuIcon = styled(Icon)`
    margin-right: 1rem;
    cursor: pointer;
`;

const defaultCellMap = {
    organization: (user: User | Partial<User>) => user.organization?.denomination,
    name: (user: User | Partial<User>) => (
        <>
            <Avatar text={`${user.profile?.firstName} ${user.profile?.lastName}`} size="small" />
            <StyledText>
                {user.profile?.firstName} {user.profile?.lastName}
            </StyledText>
        </>
    ),
    occupation: (user: User | Partial<User>) => user.profile?.occupation ?? '-',
    email: (user: User | Partial<User>) => user.email ?? '-',
    mobile: (user: User | Partial<User>) => user.profile?.mobilePhoneNumber ?? '-',
    phone: (user: User | Partial<User>) => user.profile?.phoneNumber ?? '-',
};

type Props = {
    operationId: Operation['id'];
    users: User[];
    hasAddRow?: boolean;
    addRowText?: string;
    onAddRowClick?: () => void;
    onRowClick?: (user: User | Partial<User>) => void;
    isRoleManagementMode?: boolean;
    isAffectationMode?: boolean;
    isEditMode?: boolean;
    showActionMenu?: boolean;
    operationRoles?: IRole[];
    onUserRoleClick?: (userId: User['id'], roleId: IRole['id']) => void;
    checkIfRoleIsAlreadyAffectedToUserOnOperation?: (userId: User['id'], roleId: number) => boolean;
    headers?: Record<string, string>;
    cellMap?: Record<string, unknown>;
    columnSizes?: string;
    onDisaffectUserClick?: (userId: User['id']) => void;
};

const OperationUsersList: React.FunctionComponent<Props> = ({
    operationId,
    users,
    hasAddRow,
    addRowText = '',
    onRowClick,
    isRoleManagementMode,
    isAffectationMode,
    isEditMode = false,
    showActionMenu = false,
    onAddRowClick,
    operationRoles,
    onUserRoleClick,
    checkIfRoleIsAlreadyAffectedToUserOnOperation,
    headers,
    cellMap,
    columnSizes = '14% 18% 17% 17% 17% 17%',
    onDisaffectUserClick,
}) => {
    const { t } = useTranslation();

    const defaultHeaders = {
        organization: t('user.organization'),
        name: t('user.list.name'),
        occupation: t('user.list.occupation'),
        email: t('user.list.email'),
        mobile: t('user.profile.mobilePhone'),
        phone: t('user.profile.phone'),
    };

    const handleUserRoleBadgeClick = (userId: User['id'], roleId: IRole['id']) => {
        onUserRoleClick?.(userId, roleId);
    };

    const getNbRolesThatUserHasOnOperation = (user: User | Partial<User>) => {
        let nbRolesThatUserOnOperation = 0;
        if (user.roles) {
            nbRolesThatUserOnOperation = checkHowManyRolesUserHasOnOperation(
                user.roles,
                operationId,
            );
        }
        return nbRolesThatUserOnOperation;
    };

    const renderOperationRolesHeaders = () => {
        let affectationHeaders: {
            organization: string;
            name: string;
            nbRoles: string;
            actions?: string;
        } = {
            organization: t('user.organization'),
            name: t('user.list.name'),
            nbRoles: t('user.profile.roles'),
        };

        operationRoles?.forEach((role) => {
            affectationHeaders = {
                ...affectationHeaders,
                [role.name]: role.label ?? role.name,
            };
        });

        if (showActionMenu) {
            affectationHeaders.actions = t('usersByOperation.action');
        }

        return affectationHeaders;
    };

    const renderOperationRoles = () => {
        let renderAffectationCell: {
            organization: (user: User | Partial<User>) => string | null | undefined;
            name: (user: User | Partial<User>) => JSX.Element;
            nbRoles: (user: User | Partial<User>) => JSX.Element;
            actions?: (user: User | Partial<User>) => JSX.Element;
        } = {
            organization: (user: User | Partial<User>) => user.organization?.denomination,
            name: (user: User | Partial<User>) => (
                <>
                    <Avatar
                        text={`${user.profile?.firstName} ${user.profile?.lastName}`}
                        size="small"
                    />
                    <StyledText>
                        {user.profile?.firstName} {user.profile?.lastName}
                    </StyledText>
                </>
            ),
            nbRoles: (user: User | Partial<User>) => (
                <Badge
                    text={getNbRolesThatUserHasOnOperation(user)}
                    textColor={colors.blue.B400}
                    background={colors.neutral.N75}
                />
            ),
        };

        operationRoles?.forEach((role) => {
            renderAffectationCell = {
                ...renderAffectationCell,
                [role.name](user: User | Partial<User>) {
                    const nbRolesUserHasOnOperation = user.roles
                        ? checkHowManyRolesUserHasOnOperation(user.roles, operationId)
                        : 0;
                    return (
                        <UserRoleBadge
                            role={role}
                            roleIsAlreadyAffectedToUser={
                                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
                                checkIfRoleIsAlreadyAffectedToUserOnOperation && user?.id
                                    ? checkIfRoleIsAlreadyAffectedToUserOnOperation(
                                          user.id,
                                          role.id,
                                      )
                                    : false
                            }
                            isEditMode={isEditMode}
                            cannotBeRemoved={!isAffectationMode && nbRolesUserHasOnOperation <= 1}
                            onClick={() => {
                                if (user.id) {
                                    handleUserRoleBadgeClick(user.id, role.id);
                                }
                            }}
                        />
                    );
                },
            };
        });

        if (showActionMenu) {
            renderAffectationCell.actions = (user: User | Partial<User>) => (
                <Popover renderGlobalStyle={false}>
                    <ContextualMenuIcon name="MoreHoriz" data-testid="popOverOptions" />
                    <Elevation elevation="default">
                        <PopoverContent>
                            <PopoverItem
                                className={Classes.POPOVER_DISMISS}
                                onClick={() => {
                                    if (user.id) {
                                        onDisaffectUserClick?.(user.id);
                                    }
                                }}
                                data-testid="disaffectUser"
                            >
                                {t('usersByOperation.disaffectUser')}
                            </PopoverItem>
                        </PopoverContent>
                    </Elevation>
                </Popover>
            );
        }

        return renderAffectationCell;
    };

    const getRenderHeaders = () => {
        if (isRoleManagementMode) {
            return renderOperationRolesHeaders();
        } else if (headers) {
            return headers;
        }
        return defaultHeaders;
    };

    const getRenderCellMap = () => {
        if (isRoleManagementMode) {
            return renderOperationRoles();
        } else if (cellMap) {
            return cellMap;
        }
        return defaultCellMap;
    };

    const renderAddRow = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- necessary
        add: (text: any) => (
            <>
                <Icon
                    width="0.625rem"
                    color={colors.neutral.N300}
                    name="Add"
                    style={{ marginLeft: '0.6875rem', marginRight: '0.6875rem' }}
                />
                <StyledText>{text.title}</StyledText>
            </>
        ),
    };

    return (
        <Table
            headers={getRenderHeaders()}
            rows={users as User[]}
            columnSizes={columnSizes}
            fixedStartColumnsNumber={isRoleManagementMode ? 3 : 0}
            fixedEndColumnsNumber={isRoleManagementMode && !isEditMode ? 1 : 0}
            renderCellMap={getRenderCellMap() as RenderCellMapType<User | Partial<User>>}
            onRowClick={
                !isRoleManagementMode && onRowClick
                    ? (user: User | Partial<User>) => onRowClick(user)
                    : undefined
            }
            hasAddRow={hasAddRow}
            renderAddRow={renderAddRow}
            addRowText={addRowText}
            onAddRowClick={onAddRowClick}
        />
    );
};

export default OperationUsersList;
