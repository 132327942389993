import type { ChangeEvent } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Switch } from '@blueprintjs/core';

import { colors } from '../constants/colors';

// aspect of toggle button
const aspects = {
    small: {
        width: '2rem',
        height: '1rem',
        circle: '0.75rem',
    },
    large: {
        width: '2.5rem',
        height: '1.25rem',
        circle: '1rem',
    },
    withText: {
        width: '4.875rem',
        height: '1.75rem',
        circle: '1.37rem',
    },
};

// icons' svg path
const icons = {
    cross: 'M65.569 19.949L50 35.518 34.431 19.949c-3.993-3.993-10.49-3.993-14.483 0s-3.993 10.49 0 14.483l15.569 15.569L19.948 65.57c-3.993 3.993-3.993 10.49 0 14.483 3.993 3.993 10.49 3.993 14.483 0L50 64.484l15.569 15.569c3.993 3.993 10.49 3.993 14.483 0s3.993-10.49 0-14.483L64.483 50.001l15.569-15.569c3.993-3.993 3.993-10.49 0-14.483-3.993-3.993-10.49-3.993-14.483 0z',
    tick: 'M1.71929 2.30529C1.62704 2.20978 1.5167 2.1336 1.39469 2.08119C1.27269 2.02878 1.14147 2.00119 1.00869 2.00004C0.87591 1.99888 0.744231 2.02419 0.621334 2.07447C0.498438 2.12475 0.386786 2.199 0.292893 2.29289C0.199001 2.38679 0.124748 2.49844 0.0744668 2.62133C0.0241859 2.74423 -0.00111606 2.87591 3.77564e-05 3.00869C0.00119157 3.14147 0.0287774 3.27269 0.0811864 3.39469C0.133595 3.5167 0.209778 3.62704 0.305288 3.71929L2.30529 5.71929C2.49282 5.90676 2.74712 6.01207 3.01229 6.01207C3.27745 6.01207 3.53176 5.90676 3.71929 5.71929L7.71929 1.71929C7.8148 1.62704 7.89098 1.5167 7.94339 1.39469C7.9958 1.27269 8.02339 1.14147 8.02454 1.00869C8.02569 0.87591 8.00039 0.744231 7.95011 0.621335C7.89983 0.498438 7.82558 0.386787 7.73168 0.292894C7.63779 0.199001 7.52614 0.124748 7.40324 0.0744673C7.28035 0.0241864 7.14867 -0.00111606 7.01589 3.77557e-05C6.88311 0.00119157 6.75189 0.0287779 6.62988 0.0811869C6.50788 0.133596 6.39754 0.209779 6.30529 0.305289L3.01229 3.59829L1.71929 2.30529Z',
};

type Props = {
    id?: string;
    name?: string;
    label?: string;
    aspect?: 'small' | 'large' | 'withText';
    disabled?: boolean;
    checked?: boolean;
    placeholder?: string;
    innerLabelChecked?: string;
    innerLabel?: string;
    icon?: boolean;
    noMargin?: boolean;
    'data-testid'?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export type ToggleProps = Props;

const Container = styled.div<Props>`
    .bp4-control-indicator {
        ${(props) => props.noMargin && `margin: 0`};
    }

    .bp4-control.bp4-switch:hover .bp4-control-indicator {
        background-color: ${(props) =>
            props.aspect === 'withText' ? colors.neutral.N75 : colors.neutral.N300};
        background-image: ${(props) =>
            (props.icon && props.aspect === 'small') || (props.icon && props.aspect === 'large')
                ? `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='${
                      aspects[props.aspect].height
                  }' height='${
                      aspects[props.aspect].height
                  }' viewBox='-35 -41 180 180'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='${
                      icons.cross
                  }' fill='white'/%3e%3c/svg%3e");`
                : ''};
    }
    .bp4-control.bp4-switch {
        margin: auto;
        .bp4-control-indicator,
        .bp4-control-indicator:hover {
            position: relative;
            background-color: ${(props) =>
                props.aspect === 'withText' ? colors.neutral.N75 : colors.neutral.N300};
            width: ${(props) => (props.aspect ? aspects[props.aspect].width : aspects.small.width)};
            padding-top: ${(props) => (props.aspect === 'withText' ? '0.05rem' : '0')};
            height: ${(props) =>
                props.aspect ? aspects[props.aspect].height : aspects.small.height};
            background-image: ${(props) =>
                (props.icon && props.aspect === 'small') || (props.icon && props.aspect === 'large')
                    ? `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='${
                          aspects[props.aspect].height
                      }' height='${
                          aspects[props.aspect].height
                      }' viewBox='-35 -41 180 180'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='${
                          icons.cross
                      }' fill='white'/%3e%3c/svg%3e");`
                    : ''};
            ::before {
                box-shadow: none;
                width: ${(props) =>
                    props.aspect ? aspects[props.aspect].circle : aspects.small.circle};
                height: ${(props) =>
                    props.aspect ? aspects[props.aspect].circle : aspects.small.circle};
            }
        }
        input:checked ~,
        input:active:checked ~ {
            .bp4-control-indicator {
                background-color: ${colors.green.G300};
                background-image: ${(props) =>
                    (props.icon && props.aspect === 'small') ||
                    (props.icon && props.aspect === 'large')
                        ? `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='${
                              aspects[props.aspect].height
                          }' height='${
                              aspects[props.aspect].height
                          }' viewBox='-3 -5.25 12 16.5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='${
                              icons.tick
                          }' fill='white'/%3e%3c/svg%3e");`
                        : ''};
            }
            ::before {
                left: calc(
                    100% - ${(props) => (props.aspect ? aspects[props.aspect].circle : '1rem')} -
                        0.375rem
                );
            }
        }
        input:active ~ {
            .bp4-control-indicator {
                background-color: ${(props) =>
                    props.aspect === 'withText' ? colors.neutral.N75 : colors.neutral.N300};
                background-image: ${(props) =>
                    (props.icon && props.aspect === 'small') ||
                    (props.icon && props.aspect === 'large')
                        ? `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='${
                              aspects[props.aspect].height
                          }' height='${
                              aspects[props.aspect].height
                          }' viewBox='-35 -41 180 180'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='${
                              icons.cross
                          }' fill='white'/%3e%3c/svg%3e");`
                        : ''};
            }
        }
        input:active:checked ~ {
            .bp4-control-indicator {
                background-color: ${colors.green.G300};
            }
        }
        input:disabled ~ {
            .bp4-control-indicator {
                background-color: ${colors.neutral.N75};
                :hover {
                    background-color: ${colors.neutral.N75};
                }
                ::before {
                    background-color: ${colors.neutral.N300};
                }
            }
        }
        input:disabled ~,
        input:disabled:active ~ {
            .bp4-control-indicator {
                background-image: ${(props) =>
                    (props.icon && props.aspect === 'small') ||
                    (props.icon && props.aspect === 'large')
                        ? `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='${
                              aspects[props.aspect].height
                          }' height='${
                              aspects[props.aspect].height
                          }' viewBox='-35 -41 180 180'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='${
                              icons.cross
                          }' fill='%239EA9BD'/%3e%3c/svg%3e");`
                        : ''};
            }
        }
        input:disabled:checked ~,
        input:disabled:checked:active ~ {
            .bp4-control-indicator {
                background-image: ${(props) =>
                    (props.icon && props.aspect === 'small') ||
                    (props.icon && props.aspect === 'large')
                        ? `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='${
                              aspects[props.aspect].height
                          }' height='${
                              aspects[props.aspect].height
                          }' viewBox='-3 -5.25 12 16.5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='${
                              icons.tick
                          }' fill='%239EA9BD'/%3e%3c/svg%3e");`
                        : ''};
            }
            :hover {
                background-color: ${colors.neutral.N75};
            }
        }
        .bp4-switch-inner-text {
            color: ${colors.neutral.N400};
            font: Gilroy;
            font-weight: 500;
            font-size: ${(props) =>
                props.icon && props.aspect === 'withText' ? '0.875rem' : '0.5625rem'};
        }
        input:checked + .bp4-control-indicator .bp4-switch-inner-text {
            color: ${colors.other.white};
        }
        .bp4-control-indicator > .bp4-control-indicator-child:first-of-type {
            right: ${(props) => (props.aspect === 'withText' ? '2rem' : '1.2em')};
            margin-left: 0;
            margin-right: 0;
        }
        .bp4-control-indicator > .bp4-control-indicator-child:nth-of-type(2) {
            left: ${(props) => (props.aspect === 'withText' ? '2rem' : '1.2em')};
            margin-left: 0;
            margin-right: 0;
        }
        .bp4-control-indicator > .bp4-control-indicator-child {
            position: absolute;
            top: 0.35em;
        }
    }
`;

const Toggle: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
    style,
    ...props
}) => {
    const { name, aspect, icon, noMargin, ...rest } = props;

    const propsContainer = {
        name,
        aspect,
        icon,
        noMargin,
    };

    return (
        <Container style={style} {...propsContainer}>
            <Switch type="checkbox" {...rest} />
        </Container>
    );
};

export default Toggle;
