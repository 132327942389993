import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import type { FormikProps } from 'formik';

import type { IEngagementFormData } from '../slices/engagementSlice';

import PermissionAwareText from './PermissionAwareText';
import Text from './Text';
import GridCol from './GridCol';
import GridRow from './GridRow';
import PermissionAwareToggle from './PermissionAwareToggle';

import { convertWeeksToMonths } from '../utils/date';
import {
    calculateAdvancePaymentAmountTtc,
    calculateAdvancePaymentPercentage,
    calculateDownPaymentAmountTtc,
    calculateDownPaymentPercentage,
    calculateWarrantyTtcAmount,
} from '../utils/engagementCalculation';

import { colors } from '../constants/colors';
import type { Permission } from '../slices/authSlice';

const StyledContainer = styled.div`
    display: flex;
    width: 100%;
    background-color: ${colors.other.white};
    margin-bottom: 0.5rem;
    border-radius: 10px;
    flex-direction: column;
`;

const StyledGridRow = styled(GridRow)`
    display: flex;
    width: 100%;
    margin: 1.25rem 0 0 0 !important;
    padding: 0 3% 1% 3%;
    align-items: flex-end;
`;

const StyledGridRowNoPaddingLeft = styled(StyledGridRow)`
    padding-left: 0;
`;

const StyledGridRowTop = styled(GridRow)`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 1rem 0 0 0 !important;
    padding: 0 3% 1% 3%;
`;

const InfoText = styled(Text)`
    margin: 0;
    display: flex;
    align-items: center;
    padding-left: 3%;
`;

type Props<T> = {
    hasRightToEdit: boolean;
    formikProps: FormikProps<T>;
    permissionsNeeded: Permission[];
};

const WarrantyAdvance = <B extends Omit<IEngagementFormData, 'priceVariationSettings'>>({
    hasRightToEdit,
    formikProps,
    permissionsNeeded,
}: Props<B>) => {
    const { t } = useTranslation();

    const handleAdvancePercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const durationInMonth = convertWeeksToMonths(formikProps.values.durationInWeeks ?? 0);
        const amountTtc = formikProps.values.amountTtc ? Number(formikProps.values.amountTtc) : 0;
        const advancePercentage = event.target.value ? Number(event.target.value) : 0;
        const advancePaymentAmountTtc = calculateAdvancePaymentAmountTtc(
            amountTtc,
            advancePercentage,
            durationInMonth,
        );

        formikProps.setValues({
            ...formikProps.values,
            advancePaymentAmountTtc,
        });
    };

    const handleAdvanceAmountTtcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const durationInMonth = convertWeeksToMonths(formikProps.values.durationInWeeks ?? 0);
        const amountTtc = formikProps.values.amountTtc ? Number(formikProps.values.amountTtc) : 0;
        const advanceTtc = event.target.value ? Number(event.target.value) : 0;
        const advancePaymentPercentage = calculateAdvancePaymentPercentage(
            advanceTtc,
            amountTtc,
            durationInMonth,
        );

        formikProps.setValues({
            ...formikProps.values,
            advancePaymentPercentage,
        });
    };

    const handleDownPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const amountTtc = formikProps.values.amountTtc ? Number(formikProps.values.amountTtc) : 0;
        const downPercentage = event.target.value ? Number(event.target.value) : 0;
        const downPaymentAmountTtc = calculateDownPaymentAmountTtc(amountTtc, downPercentage);

        formikProps.setValues({
            ...formikProps.values,
            downPaymentAmountTtc,
        });
    };

    const handleDownTtcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const amountTtc = formikProps.values.amountTtc ? Number(formikProps.values.amountTtc) : 0;
        const downTtc = event.target.value ? Number(event.target.value) : 0;
        const downPaymentPercentage = calculateDownPaymentPercentage(amountTtc, downTtc);

        formikProps.setValues({ ...formikProps.values, downPaymentPercentage });
    };

    const handleWarrantyPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const amountTtc = formikProps.values.amountTtc ? Number(formikProps.values.amountTtc) : 0;
        const warrantyPercentage = event.target.value ? Number(event.target.value) : 0;
        const warrantiesTotalTtc = calculateWarrantyTtcAmount(amountTtc, warrantyPercentage);

        formikProps.setValues({ ...formikProps.values, warrantiesTotalTtc });
    };

    return (
        <>
            <StyledContainer>
                <StyledGridRowTop>
                    <GridCol defaultScreen={6} smallScreen={6} style={{ padding: '0 1rem 0 0' }}>
                        <Text type="H500">{t('engagement.warrantyHoldback')}</Text>
                    </GridCol>
                    <GridCol
                        defaultScreen={6}
                        smallScreen={6}
                        style={{ display: 'flex', justifyContent: 'flex-end', padding: '0' }}
                    >
                        <PermissionAwareToggle
                            aspect="withText"
                            permissionsRequired={permissionsNeeded}
                            name="isWarranty"
                            editable={hasRightToEdit}
                            innerLabelChecked={t('general.active')}
                            innerLabel={t('general.inactive')}
                            style={{ paddingRight: '0', marginRight: '-0.5rem' }}
                        />
                    </GridCol>
                </StyledGridRowTop>
                {formikProps.values.isWarranty && (
                    <>
                        <InfoText>{t('engagement.warrantyHoldbackInfo')}</InfoText>
                        <StyledGridRow>
                            <GridCol
                                defaultScreen={3}
                                smallScreen={3}
                                style={{ padding: '0 1rem 0 0' }}
                            >
                                <PermissionAwareText
                                    marginTop="0"
                                    editable={hasRightToEdit}
                                    permissionsRequired={permissionsNeeded}
                                    name="holdbackPercentage"
                                    type="text"
                                    numberType="percentage"
                                    placeholder={t('engagement.warrantyHoldback')}
                                    data-testid="holdbackPercentage"
                                    errorTestId="errorHoldbackPercentage"
                                    onChange={handleWarrantyPercentageChange}
                                    noMarginTop
                                    whiteBackground
                                />
                            </GridCol>
                            <InfoText>{t('engagement.warrantyInfo')}</InfoText>
                        </StyledGridRow>
                    </>
                )}
            </StyledContainer>
            <StyledContainer>
                <StyledGridRow>
                    <GridCol defaultScreen={6} smallScreen={6} style={{ padding: '0 1rem 0 0' }}>
                        <Text type="H500">{t('engagement.advance')}</Text>
                    </GridCol>
                    <GridCol
                        defaultScreen={6}
                        smallScreen={6}
                        style={{ display: 'flex', justifyContent: 'flex-end', padding: '0' }}
                    >
                        <PermissionAwareToggle
                            aspect="withText"
                            permissionsRequired={permissionsNeeded}
                            name="isAdvancePayment"
                            editable={hasRightToEdit}
                            innerLabelChecked={t('general.active')}
                            innerLabel={t('general.inactive')}
                            style={{ paddingRight: '0', marginRight: '-0.5rem' }}
                        />
                    </GridCol>
                </StyledGridRow>
                {formikProps.values.isAdvancePayment && (
                    <>
                        <StyledGridRow>
                            <GridCol
                                defaultScreen={3}
                                smallScreen={3}
                                style={{ padding: '0 1rem 0 0' }}
                            >
                                <PermissionAwareText
                                    marginTop="0"
                                    editable={hasRightToEdit}
                                    permissionsRequired={permissionsNeeded}
                                    name="advancePaymentPercentage"
                                    label={t('engagement.advancePercentage')}
                                    type="text"
                                    numberType="percentage"
                                    placeholder={t('engagement.advancePercentage')}
                                    data-testid="advancePercentage"
                                    errorTestId="errorAdvancePercentage"
                                    onChange={handleAdvancePercentageChange}
                                    noMarginTop
                                    whiteBackground
                                />
                            </GridCol>
                            <GridCol defaultScreen={5} smallScreen={5}>
                                <PermissionAwareText
                                    marginTop="0"
                                    editable={hasRightToEdit}
                                    permissionsRequired={permissionsNeeded}
                                    name="advancePaymentAmountTtc"
                                    label={t('engagement.advanceAmountTtc')}
                                    type="text"
                                    numberType="amount"
                                    placeholder={t('engagement.advanceAmountTtc')}
                                    data-testid="advanceAmountTtc"
                                    errorTestId="errorAdvanceAmountTtc"
                                    onChange={handleAdvanceAmountTtcChange}
                                    noMarginTop
                                    whiteBackground
                                />
                            </GridCol>
                        </StyledGridRow>
                        <StyledGridRowNoPaddingLeft>
                            <InfoText>{t('engagement.retrieveBetween')}</InfoText>
                            <GridCol defaultScreen={2} smallScreen={2}>
                                <PermissionAwareText
                                    marginTop="0"
                                    marginBottom="0"
                                    editable={hasRightToEdit}
                                    permissionsRequired={permissionsNeeded}
                                    name="inferiorRecoveryPercentage"
                                    type="text"
                                    numberType="percentage"
                                    placeholder={t('engagement.inferiorRecoveryPercentage')}
                                    data-testid="inferiorRecoveryPercentage"
                                    errorTestId="errorInferiorRecoveryPercentage"
                                    noMarginTop
                                    whiteBackground
                                />
                            </GridCol>
                            <InfoText>{t('engagement.percentageAnd')}</InfoText>
                            <GridCol defaultScreen={2} smallScreen={2}>
                                <PermissionAwareText
                                    marginTop="0"
                                    marginBottom="0"
                                    editable={hasRightToEdit}
                                    permissionsRequired={permissionsNeeded}
                                    name="superiorRecoveryPercentage"
                                    type="text"
                                    numberType="percentage"
                                    placeholder={t('engagement.superiorRecoveryPercentage')}
                                    data-testid="superiorRecoveryPercentage"
                                    errorTestId="errorSuperiorRecoveryPercentage"
                                    noMarginTop
                                    whiteBackground
                                />
                            </GridCol>
                            <InfoText>{t('engagement.percentageOfInitialMarket')}</InfoText>
                        </StyledGridRowNoPaddingLeft>
                    </>
                )}
            </StyledContainer>
            <StyledContainer>
                <StyledGridRow>
                    <GridCol defaultScreen={6} smallScreen={6} style={{ padding: '0 1rem 0 0' }}>
                        <Text type="H500">{t('engagement.downPayment')}</Text>
                    </GridCol>
                    <GridCol
                        defaultScreen={6}
                        smallScreen={6}
                        style={
                            formikProps.values.isDownPayment
                                ? { display: 'flex', justifyContent: 'flex-end', padding: '0' }
                                : {
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      padding: '0',
                                      marginBottom: '1rem',
                                  }
                        }
                    >
                        <PermissionAwareToggle
                            aspect="withText"
                            permissionsRequired={permissionsNeeded}
                            name="isDownPayment"
                            editable={hasRightToEdit}
                            innerLabelChecked={t('general.active')}
                            innerLabel={t('general.inactive')}
                            style={{ paddingRight: '0', marginRight: '-0.5rem' }}
                        />
                    </GridCol>
                </StyledGridRow>
                {formikProps.values.isDownPayment && (
                    <StyledGridRow>
                        <GridCol
                            defaultScreen={3}
                            smallScreen={3}
                            style={{ padding: '0 1rem 0 0' }}
                        >
                            <PermissionAwareText
                                marginTop="0"
                                editable={hasRightToEdit}
                                permissionsRequired={permissionsNeeded}
                                name="downPaymentPercentage"
                                type="text"
                                numberType="percentage"
                                label={t('engagement.downPaymentPercentage')}
                                placeholder={t('engagement.downPaymentPercentage')}
                                data-testid="downPaymentPercentage"
                                errorTestId="errorDownPaymentPercentage"
                                onChange={handleDownPercentageChange}
                                noMarginTop
                                whiteBackground
                            />
                        </GridCol>
                        <GridCol defaultScreen={5} smallScreen={5}>
                            <PermissionAwareText
                                marginTop="0"
                                editable={hasRightToEdit}
                                permissionsRequired={permissionsNeeded}
                                name="downPaymentAmountTtc"
                                type="text"
                                numberType="amount"
                                label={t('engagement.downPaymentAmountTtc')}
                                placeholder={t('engagement.downPaymentAmountTtc')}
                                data-testid="downPaymentAmountTtc"
                                errorTestId="errorDownPaymentAmountTtc"
                                onChange={handleDownTtcChange}
                                noMarginTop
                                whiteBackground
                            />
                        </GridCol>
                    </StyledGridRow>
                )}
            </StyledContainer>
        </>
    );
};
export default WarrantyAdvance;
