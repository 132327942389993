import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';

import GridRow from './GridRow';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const TabWrapper = styled(GridRow)<{
    isActive: boolean;
}>`
    flex-wrap: wrap;
    flex-direction: row;
    cursor: pointer;

    &:hover {
        ${({ isActive }) =>
            !isActive &&
            `
            > p {
                color: ${colors.blue.B400};
            }

            > div {
                border-color: ${colors.blue.B400};
            }
        `}
`;

const Tab = styled.div<{
    isActive: boolean;
}>`
    ${({ isActive }) =>
        isActive
            ? `
        border: 0.1rem solid ${colors.blue.B300};
        background-color: #246EFF;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        margin-right: 1rem;
        margin-left: 0.09rem;
    `
            : `
        border: 0.06rem solid ${colors.neutral.N200};
        margin-left: 0.1rem;
        margin-right: 1rem;
    `};
`;

const TabText = styled.p<{
    isActive: boolean;
}>`
    line-height: 2.5rem;
    margin-bottom: 0;
    font-family: 'Gilroy';
    font-weight: 800;
    font-size: 0.875rem;
    color: ${({ isActive }) => (isActive ? colors.blue.B300 : colors.neutral.N300)};
`;

type Tab = {
    text: string;
    onClick: () => void;
    isActive: boolean;
};

type TabsProps = {
    tabs: Tab[];
    style?: CSSProperties;
};

const VerticalTabs: React.FC<TabsProps> = ({ tabs, style }) => (
    <Wrapper style={style}>
        {tabs.map((tab: Tab) => (
            <TabWrapper key={tab.text} isActive={tab.isActive} onClick={tab.onClick}>
                <Tab isActive={tab.isActive} />
                <TabText isActive={tab.isActive}>{tab.text}</TabText>
            </TabWrapper>
        ))}
    </Wrapper>
);

export default VerticalTabs;
