import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError } from './common';
import { errorHandler } from './common';
import type { Market } from './marketSlice';
import type { Note } from './noteSlice';
import type { Operation } from './operationSlice';
import type { User } from './userSlice';

export type NoteValueMarket = {
    id: number;
    noteId: Note['id'];
    marketId: Market['id'];
    content: string;
    createdAt: string;
    createdByUser: User;
    updatedAt: string;
    updatedByUser: User | null;
    note: Note;
};

export type NoteValueMarketFormData = {
    id?: NoteValueMarket['id'];
    noteId: NoteValueMarket['noteId'];
    marketId: NoteValueMarket['marketId'];
    content: NoteValueMarket['content'];
    note: Note;
};

export type LastUpdateInfo = {
    firstName: string;
    lastName: string;
    date: string;
};

interface NoteState {
    notesValuesByMarketId: Record<string, Record<number, NoteValueMarket>>;
    lastUpdateInfoByMarketId: Record<string, LastUpdateInfo | null>;
    loading: boolean;
    error: string | null;
}

export const initialState: NoteState = {
    notesValuesByMarketId: {},
    lastUpdateInfoByMarketId: {},
    loading: false,
    error: null,
};

export const getMarketNotesValues = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    NoteValueMarket[],
    // First argument to the payload creator
    { operationId: Operation['id']; marketId: Market['id'] },
    {
        rejectValue: ServerError;
    }
>('NOTE_VALUE_MARKET/GETALL', async ({ operationId, marketId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data: NoteValueMarket[] }>(
            `/operations/${operationId}/markets/${marketId}/notes-values`,
        );
        if (Array.isArray(response.data.data)) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getMarketLastUpdateInfo = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    LastUpdateInfo,
    // First argument to the payload creator
    { operationId: Operation['id']; marketId: Market['id'] },
    {
        rejectValue: ServerError;
    }
>(
    'NOTE_VALUE_MARKET/GET_LAST_UPDATE_INFO',
    async ({ operationId, marketId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<{ data: LastUpdateInfo }>(
                `/operations/${operationId}/markets/${marketId}/notes-values/last-update-info`,
            );
            return response.data.data;
        } catch (err: unknown) {
            return rejectWithValue({
                message: 'Something went wrong',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const updateManyNoteValueMarket = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    NoteValueMarket[],
    // First argument to the payload creator
    {
        operationId: Operation['id'];
        marketId: Market['id'];
        notesValues: NoteValueMarketFormData[];
    },
    {
        rejectValue: ServerError;
    }
>(
    'NOTE_VALUE_MARKET/COLLECTION/PUT',
    async ({ operationId, marketId, notesValues }, { rejectWithValue }) => {
        try {
            const response = await axios.put<{ data: NoteValueMarket[] }>(
                `/operations/${operationId}/markets/${marketId}/notes-values/collection`,
                notesValues,
            );
            if (Array.isArray(response.data.data)) {
                return response.data.data;
            }
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        } catch (err: unknown) {
            return rejectWithValue({
                message: 'Something went wrong',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const slice = createSlice({
    name: 'noteValueMarket',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getMarketNotesValues.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getMarketNotesValues.fulfilled, (state, { payload, meta }) => {
            state.loading = false;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
            if (meta.arg?.marketId) {
                const { marketId } = meta.arg;
                state.notesValuesByMarketId[marketId] = {};
                payload.forEach((item: NoteValueMarket) => {
                    state.notesValuesByMarketId[marketId][item.id] = item;
                });
            }
        });
        builder.addCase(getMarketNotesValues.rejected, errorHandler());
        // Get last update info
        builder.addCase(getMarketLastUpdateInfo.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getMarketLastUpdateInfo.fulfilled, (state, { payload, meta }) => {
            state.loading = false;
            const { marketId } = meta.arg;
            state.lastUpdateInfoByMarketId[marketId] = payload;
        });
        builder.addCase(getMarketLastUpdateInfo.rejected, errorHandler());
        // Update many
        builder.addCase(updateManyNoteValueMarket.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateManyNoteValueMarket.fulfilled, (state, { payload, meta }) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
            if (meta.arg?.marketId) {
                const { marketId } = meta.arg;
                state.notesValuesByMarketId[marketId] = {};
                payload.forEach((item: NoteValueMarket) => {
                    state.notesValuesByMarketId[marketId][item.id] = item;
                });
                state.loading = false;
            }
            state.loading = false;
        });
        builder.addCase(updateManyNoteValueMarket.rejected, errorHandler());
    },
});

export const selectIsLoading = (state: RootState) => state.noteValueMarket.loading;
export const selectError = (state: RootState) => state.noteValueMarket.error;
export const selectNotesValuesByMarketId = (marketId: Market['id']) => (state: RootState) =>
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- want to goo through condition even if null
    state.noteValueMarket.notesValuesByMarketId[marketId] !== undefined
        ? Object.values(state.noteValueMarket.notesValuesByMarketId[marketId])
        : [];
export const selectLastUpdateInfoByMarketId = (marketId: Market['id']) =>
    function (state: RootState): LastUpdateInfo | null {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- want to goo through condition even if null
        return state.noteValueMarket.lastUpdateInfoByMarketId[marketId] !== undefined
            ? state.noteValueMarket.lastUpdateInfoByMarketId[marketId]
            : null;
    };

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
