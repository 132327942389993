import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';

import Text from './Text';
import Tooltip from './Tooltip';

import { colors } from '../constants/colors';
import { replaceTextValueIfNaNOrEmpty } from '../utils/formatters';

type InfoProps = {
    largeMarginTop?: boolean;
};

const Info = styled.div<InfoProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const StyledLabel = styled(Text)`
    margin: 0 0 0.5rem 0;
`;

const StyledValue = styled(Text)<{
    whiteSpace: string | undefined;
    fontWeight: number | string | undefined;
    isEmptyValue: boolean | undefined;
}>`
    margin: 0;
    white-space: ${({ whiteSpace }) => whiteSpace ?? 'nowrap'};
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ isEmptyValue }) => isEmptyValue && `font-style: italic; color: ${colors.neutral.N400}`};
`;

type Props = {
    label?: string;
    value: string | React.ReactNode;
    style?: CSSProperties;
    valueStyle?: CSSProperties;
    hasTooltip?: boolean;
    rightComponent?: React.ReactNode;
    subLabel?: string;
    shouldRenderGlobalStyleForTooltip?: boolean;
    isNumeral?: boolean;
    defaultEmptyValue?: string;
};

const PermissionAwareDisplay: React.FC<Props> = ({
    label,
    value,
    hasTooltip = false,
    rightComponent = null,
    valueStyle,
    subLabel,
    shouldRenderGlobalStyleForTooltip = true,
    isNumeral = false,
    defaultEmptyValue = '-',
    ...rest
}: Props) => {
    const displayedValue = replaceTextValueIfNaNOrEmpty(value, isNumeral, defaultEmptyValue);
    const textValue = typeof displayedValue === 'string' ? displayedValue : '';
    const isEmptyStringValue = value === '';

    const content = (
        <StyledValue
            whiteSpace={rest.style?.whiteSpace}
            fontWeight={rest.style?.fontWeight}
            style={valueStyle}
            isNumeral={isNumeral}
            isEmptyValue={isEmptyStringValue}
        >
            {displayedValue}
            {rightComponent}
        </StyledValue>
    );

    return (
        <Info {...rest}>
            {label && <StyledLabel color={colors.neutral.N300}>{label}</StyledLabel>}
            {hasTooltip ? (
                <Tooltip
                    content={textValue}
                    isOneLine={false}
                    shouldRenderGlobalStyle={shouldRenderGlobalStyleForTooltip}
                >
                    {content}
                </Tooltip>
            ) : (
                content
            )}
            {subLabel && (
                <Text
                    color={colors.neutral.N300}
                    type="small"
                    style={{ margin: '0.5rem 0' }}
                    isNumeral={isNumeral}
                >
                    {subLabel}
                </Text>
            )}
        </Info>
    );
};

export default PermissionAwareDisplay;
