import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';

const Container = styled.p`
    display: flex;
    border-radius: 0.3125rem;
    background-color: ${colors.red.R75};
    color: ${colors.red.R300};
    font-family: Rubik;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    justify-content: center;
    text-align: center;
    padding: 0.3125rem 1rem;
    margin-top: 1.875rem;
    margin-bottom: 0;
    white-space: pre-line;
    max-width: 100%;
`;

type Props = {
    'data-testid'?: string;
    text?: string;
    translationKey?: string;
};

const ErrorMessage: React.FC<Props> = ({ text, translationKey, ...props }) => {
    const { t } = useTranslation();

    let message = text;
    if (translationKey) {
        message = t(translationKey);
    }

    return <Container {...props}>{message}</Container>;
};

export default ErrorMessage;
