import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import GridRow from '../../components/GridRow';
import GridCol from '../../components/GridCol';

import type { Budget, IBudgetFormData } from '../../slices/budgetSlice';
import {
    selectError,
    createBudget,
    selectBudgetsByOperationIdAndPage,
    getBudgets,
    selectTotalBudgets,
    selectBudgetAdded,
    selectGetBudgetsLoading,
} from '../../slices/budgetSlice';

import {
    getOperationHasEngagement,
    selectOperationHasEngagement,
} from '../../slices/operationSlice';
import type { QueryParams, ListViewType } from '../../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../../slices/common';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import EmptyState from '../../components/EmptyState';
import { showFlag } from '../../components/Flag';
import BudgetList from '../../components/BudgetList';
import { usePageNumber } from '../../hooks/usePageNumber';
import Loader from '../../components/Loader';
import { useAppDispatch } from '../../store';

const ViewToggle = styled.div`
    display: flex;
    div:first-child {
        margin: 0 1rem 0 0;
    }
    justify-content: flex-end;
`;

type Props = {
    headerProps: HeaderProps;
};

const Budgets: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { operationId } = useParams<{ operationId: string }>();
    const navigate = useNavigate();
    const location = useLocation();

    const pageNumber = usePageNumber();
    const [queryParams, setQueryParams] = useState<QueryParams>({
        operationId: Number(operationId),
        page: pageNumber,
    });
    const [viewType, setViewType] = useState<ListViewType>(
        localStorage.getItem('budgets_list_type') === 'table' ? 'table' : 'card',
    );
    const [isPending, setIsPending] = useState<boolean>(false);
    const permissionNeeded = { code: 'BUDGETS_ADD', operationId };
    const hasRightToAddBudgets = usePermissionsCheck([permissionNeeded]);
    const totalBudgets = useSelector(selectTotalBudgets);
    const budgetAdded = useSelector(selectBudgetAdded);
    const operationHasEngagements = useSelector(selectOperationHasEngagement(Number(operationId)));
    const createViewClickHandler = (type: 'card' | 'table') => () => {
        setViewType(type);
        localStorage.setItem('budgets_list_type', type);
    };
    const serverError = useSelector(selectError);
    const isLoading = useSelector(selectGetBudgetsLoading);

    useEffect(() => {
        void dispatch(getBudgets(queryParams));
        void dispatch(getOperationHasEngagement({ operationId: Number(operationId) }));
    }, [dispatch, queryParams, operationId]);

    const budgets = useSelector(
        selectBudgetsByOperationIdAndPage(operationId, queryParams.page),
        shallowEqual, // TODO: Used to fix infinite loop, check if it happen in other components
    ) as Budget[];

    const addNewDefaultBudget = () => {
        const budget: IBudgetFormData = {
            operationId: Number(operationId),
            label: '',
            comment: '',
            totalAmountTtc: 0,
            status: 'draft',
            targetTotalAmountTtc: 0,
        };
        void dispatch(createBudget({ budget, operationId: Number(operationId) }));
        setIsPending(true);
    };

    const successMessage = t('general.success');
    const errorMessage = t('errors.error');
    const savedMessage = t('budget.saved');

    useEffect(() => {
        if (isPending) {
            if (serverError) {
                showFlag('error', errorMessage, serverError);
                setIsPending(false);
            } else if (budgetAdded) {
                showFlag('success', successMessage, savedMessage);
                setIsPending(false);
                navigate(`/operations/${operationId}/budgets/${budgetAdded.id}?edit`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  -- Don't want to rerender on every dependencies change
    }, [serverError, budgetAdded, isPending]);

    return (
        <>
            <Helmet>
                <title>{t('sidebar.operations')}</title>
            </Helmet>
            <MainLayout
                header={
                    <Header
                        {...headerProps}
                        buttons={
                            hasRightToAddBudgets
                                ? [
                                      {
                                          'data-testid': 'addBudgetButton',
                                          text: t('budget.addBudget'),
                                          aspect: 'primary',
                                          throttled: true,
                                          onClick: addNewDefaultBudget,
                                      },
                                  ]
                                : []
                        }
                        showNavigation
                    />
                }
                footer={
                    totalBudgets > 0 ? (
                        <Pagination
                            data-testid="pagination"
                            initialPage={queryParams.page}
                            currentPage={queryParams.page}
                            pageNeighbours={2}
                            nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                            nbItemsTotal={totalBudgets}
                            onPageNumberClicked={(page: number) =>
                                setQueryParams({
                                    ...queryParams,
                                    page,
                                })
                            }
                            pageUrl="budgets"
                        />
                    ) : null
                }
                smallContentSidePadding
            >
                <GridRow
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    {totalBudgets > 0 && (
                        <GridCol>
                            <ViewToggle>
                                <Button
                                    onClick={createViewClickHandler('table')}
                                    iconName="ViewList"
                                    isPressed={viewType === 'table'}
                                    data-testid="tableViewButton"
                                    aspect="onlyIcon"
                                    toggle
                                />
                                <Button
                                    onClick={createViewClickHandler('card')}
                                    iconName="ViewModule"
                                    isPressed={viewType === 'card'}
                                    data-testid="cardViewButton"
                                    aspect="onlyIcon"
                                    toggle
                                />
                            </ViewToggle>
                        </GridCol>
                    )}
                </GridRow>
                {!isLoading ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment -- necessary fragment
                    <>
                        {totalBudgets > 0 ? (
                            <BudgetList
                                viewType={viewType}
                                budgets={budgets}
                                stateForNavigation={{
                                    goBackTo: `${location.pathname}${location.search}`,
                                }}
                            />
                        ) : (
                            <EmptyState
                                data-testid="errorNoBudgets"
                                imageName="Budget"
                                titleTranslationKey="budget.noBudgetTitle"
                            />
                        )}
                    </>
                ) : (
                    <Loader />
                )}
            </MainLayout>
        </>
    );
};

export default Budgets;
