import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import {
    selectUser as selectAuthentifiedUser,
    selectUserImpersonating,
    logout,
} from '../slices/authSlice';
import { getUser, selectUser } from '../slices/userSlice';
import { useAppDispatch } from '../store';
import Icon from './Icon';
import Text from './Text';

const Banner = styled.div`
    grid-area: connect-as-banner;
    padding: 12px 35px 12px 18px;
    background-color: ${colors.yellow.Y100};
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const EmptyBanner = styled.div`
    grid-area: connect-as-banner;
`;

const LeftContent = styled.div`
    display: flex;
`;

const LeftContentText = styled(Text)`
    margin-left: 1.125rem;
    margin-bottom: 0;
`;

const RightContentText = styled(Text)`
    margin-bottom: 0;
`;

const RightContent = styled.div`
    display: flex;
`;

const LogoutLink = styled(Text)`
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
`;

export const ConnectAsBanner = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const userAuthentified = useSelector(selectAuthentifiedUser);
    const userImpersonated = useSelector(selectUser(userAuthentified ? userAuthentified.id : null));
    const userImpersonating = useSelector(selectUserImpersonating);

    useEffect(() => {
        if (userAuthentified) {
            void dispatch(getUser({ id: userAuthentified.id }));
        }
    }, [dispatch, userAuthentified]);

    return userImpersonating && userImpersonated ? (
        <Banner>
            <LeftContent>
                <Icon name="ErrorWarning" />
                <LeftContentText>
                    {t('connectAsBanner.dislayingAccountOf')} {userImpersonated.email} (
                    {userImpersonated.profile.lastName} {userImpersonated.profile.firstName})
                </LeftContentText>
            </LeftContent>
            <RightContent>
                <RightContentText>{t('connectAsBanner.toComeBackToAdmin')}</RightContentText>
                <span>&nbsp;</span>
                <LogoutLink
                    onClick={() => {
                        void dispatch(logout());
                    }}
                >
                    {t('connectAsBanner.logout')}
                </LogoutLink>
            </RightContent>
        </Banner>
    ) : (
        <EmptyBanner />
    );
};
