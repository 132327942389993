import { createGlobalStyle } from 'styled-components';

import { colors } from './colors';

// Import fonts
import GilroyLightWoff from '../assets/fonts/Gilroy/Gilroy-Light.woff';
import GilroyLightWoff2 from '../assets/fonts/Gilroy/Gilroy-Light.woff2';
import GilroyRegularWoff from '../assets/fonts/Gilroy/Gilroy-Regular.woff';
import GilroyRegularWoff2 from '../assets/fonts/Gilroy/Gilroy-Regular.woff2';
import GilroyMediumWoff from '../assets/fonts/Gilroy/Gilroy-Medium.woff';
import GilroyMediumWoff2 from '../assets/fonts/Gilroy/Gilroy-Medium.woff2';
import GilroyBoldWoff from '../assets/fonts/Gilroy/Gilroy-Bold.woff';
import GilroyBoldWoff2 from '../assets/fonts/Gilroy/Gilroy-Bold.woff2';
import GilroyExtraBoldWoff from '../assets/fonts/Gilroy/Gilroy-ExtraBold.woff';
import GilroyExtraBoldWoff2 from '../assets/fonts/Gilroy/Gilroy-ExtraBold.woff2';
import RobotoLightWoff from '../assets/fonts/Roboto/Roboto-Light.woff';
import RobotoLightWoff2 from '../assets/fonts/Roboto/Roboto-Light.woff2';
import RobotoRegularWoff from '../assets/fonts/Roboto/Roboto-Regular.woff';
import RobotoRegularWoff2 from '../assets/fonts/Roboto/Roboto-Regular.woff2';
import RobotoMediumWoff from '../assets/fonts/Roboto/Roboto-Medium.woff';
import RobotoMediumWoff2 from '../assets/fonts/Roboto/Roboto-Medium.woff2';
import RobotoBoldWoff from '../assets/fonts/Roboto/Roboto-Bold.woff';
import RobotoBoldWoff2 from '../assets/fonts/Roboto/Roboto-Bold.woff2';
import RubikLightWoff from '../assets/fonts/Rubik/Rubik-Light.woff';
import RubikLightWoff2 from '../assets/fonts/Rubik/Rubik-Light.woff2';
import RubikRegularWoff from '../assets/fonts/Rubik/Rubik-Regular.woff';
import RubikRegularWoff2 from '../assets/fonts/Rubik/Rubik-Regular.woff2';
import RubikMediumWoff from '../assets/fonts/Rubik/Rubik-Medium.woff';
import RubikMediumWoff2 from '../assets/fonts/Rubik/Rubik-Medium.woff2';
import RubikBoldWoff from '../assets/fonts/Rubik/Rubik-Bold.woff';
import RubikBoldWoff2 from '../assets/fonts/Rubik/Rubik-Bold.woff2';
// Courier Prime downloaded on http://google-webfonts-helper.herokuapp.com/fonts/courier-prime?subsets=latin
import CourierPrimeRegularWoff from '../assets/fonts/Courier-Prime/Courier-Prime-400.woff';
import CourierPrimeRegularWoff2 from '../assets/fonts/Courier-Prime/Courier-Prime-400.woff2';
import CourierPrimeBoldWoff from '../assets/fonts/Courier-Prime/Courier-Prime-700.woff';
import CourierPrimeBoldWoff2 from '../assets/fonts/Courier-Prime/Courier-Prime-700.woff2';

export const GlobalStyle = createGlobalStyle`
    html, #root {
        height: 100%;
        background-color: 'white';
        scroll-behavior: smooth;
    }

    @font-face {
        font-family: 'Gilroy';
        font-weight: 300;
        src: local('Gilroy'), url(${GilroyLightWoff}) format('woff'), url(${GilroyLightWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Gilroy';
        font-weight: 400;
        src: local('Gilroy'), url(${GilroyRegularWoff}) format('woff'), url(${GilroyRegularWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Gilroy';
        font-weight: 500;
        src: local('Gilroy'), url(${GilroyMediumWoff}) format('woff'), url(${GilroyMediumWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Gilroy';
        font-weight: 700;
        src: local('Gilroy'), url(${GilroyBoldWoff}) format('woff'), url(${GilroyBoldWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Gilroy';
        font-weight: 800;
        src: local('Gilroy'), url(${GilroyExtraBoldWoff}) format('woff'), url(${GilroyExtraBoldWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Roboto';
        font-weight: 300;
        src: local('Roboto'), url(${RobotoLightWoff}) format('woff'), url(${RobotoLightWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Roboto';
        font-weight: 400;
        src: local('Roboto'), url(${RobotoRegularWoff}) format('woff'), url(${RobotoRegularWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Roboto';
        font-weight: 500;
        src: local('Roboto'), url(${RobotoMediumWoff}) format('woff'), url(${RobotoMediumWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Roboto';
        font-weight: 700;
        src: local('Roboto'), url(${RobotoBoldWoff}) format('woff'), url(${RobotoBoldWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Rubik';
        font-weight: 300;
        src: local('Rubik'), url(${RubikLightWoff}) format('woff'), url(${RubikLightWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Rubik';
        font-weight: 400;
        src: local('Rubik'), url(${RubikRegularWoff}) format('woff'), url(${RubikRegularWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Rubik';
        font-weight: 500;
        src: local('Rubik'), url(${RubikMediumWoff}) format('woff'), url(${RubikMediumWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Rubik';
        font-weight: 700;
        src: local('Rubik'), url(${RubikBoldWoff}) format('woff'), url(${RubikBoldWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Courier Prime';
        font-weight: 400;
        src: local('Courier Prime'), url(${CourierPrimeRegularWoff}) format('woff'), url(${CourierPrimeRegularWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Courier Prime';
        font-weight: bold;
        src: local('Courier Prime'), url(${CourierPrimeBoldWoff}) format('woff'), url(${CourierPrimeBoldWoff2}) format('woff2');
    }

    body {
        height: 100%;
        margin: 0;
        font-size: 0.875rem;
        font-family: 'Roboto', 'Gilroy', 'Rubik', sans-serif;
        color: ${colors.neutral.N400};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a, a:visited {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${colors.green.G400};
        text-decoration: none;
        cursor: pointer;
    }

    a:hover {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${colors.blue.B200};
        text-decoration: none;
        cursor: pointer;
    }

    .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -2rem; /* gutter size offset */
        width: auto;
    }

    .my-masonry-grid_column {
        padding-left: 2rem; /* gutter size */
        background-clip: padding-box;
    }

    /* Style your items */
    .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
        // background: grey;
        margin-bottom: 2rem;
    }

    .gantt_cal_light .gantt_section_time{
        select[aria-label=Mois] {
            width: 5rem;
        }
        select[aria-label=Jours] {
            width: 2rem;
        }
        select[aria-label=Années] {
            width: 3rem;
        }
    }

    @media print{
        #simulationButton{
            display: none;
        }
        .scrollable-content{
            display: block !important;
            position: relative !important;
            width: auto !important;
            height: auto !important;
            overflow-y: visible !important;
            margin-left: 0 !important;
        }
        html, body {
            height: initial !important;
            overflow: initial !important;
            zoom: 100%;
        }
        .print-medium-font{
            font-size: medium;
        }
    }
`;
