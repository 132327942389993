import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';

const Container = styled.footer`
    -ms-grid-row: 2;
    grid-area: footer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;

    color: ${colors.neutral.N300};
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    text-align: center;
    width: 100%;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0.3125rem 0;
`;

const Elem = styled.p`
    font-family: Roboto;
    font-weight: 500;
    display: flex;
    padding: 0 0.5rem;
    margin: 0;
    white-space: nowrap;
`;

const Footer: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Row>
                <Elem>{t('footer.support')}</Elem>
                <Elem>.</Elem>
                <Elem>{t('footer.service')}</Elem>
            </Row>
            <Row>
                <Elem>{t('footer.copyright')}</Elem>
                <Elem>.</Elem>
                <Elem>{t('footer.about')}</Elem>
                <Elem>.</Elem>
                <Elem>{t('footer.confidentiality')}</Elem>
                <Elem>.</Elem>
                <Elem>{t('footer.conditions')}</Elem>
            </Row>
        </Container>
    );
};
export default Footer;
