import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError } from './common';
import { errorHandler } from './common';
import type { Operation } from './operationSlice';

export type MarketsPriceHypothesis = {
    id: number;
    year: number;
    increasingFactor: number | null;
    operationId: number;
};

export type MarketsPriceHypothesisFormData = {
    id: number;
    year: number;
    increasingFactor: number | string | null;
    operationId: number;
};

interface IMarketsPriceHypothesisState {
    marketsPriceHypothesisById: Record<number, MarketsPriceHypothesis>;
    loading: boolean;
    isCreating: boolean;
    error: string | null;
    selectedOperationId: number | null;
}

export const initialState: IMarketsPriceHypothesisState = {
    marketsPriceHypothesisById: {},
    loading: false,
    isCreating: false,
    error: null,
    selectedOperationId: null,
};

export const getAllMarketsPriceHypothesis = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: MarketsPriceHypothesis[]; operationId: Operation['id'] },
    // First argument to the payload creator
    {
        operationId: Operation['id'];
    },
    {
        rejectValue: ServerError;
    }
>('MARKETS_PRICE_HYPOTHESIS/GETALL', async ({ operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: MarketsPriceHypothesis[] }>(
            `/operations/${operationId}/markets/hypotheses`,
        );

        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return { data: response.data.data, operationId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.status === 400 || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const createMarketsPriceHypothesis = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    MarketsPriceHypothesis[],
    // First argument to the payload creator
    {
        operationId: Operation['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('MARKETS_PRICE_HYPOTHESIS/POST', async ({ operationId }, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post(`/operations/${operationId}/markets/hypotheses`);

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.status === 400) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updateMarketsPriceHypothesis = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    MarketsPriceHypothesis,
    // First argument to the payload creator
    {
        marketsPriceHypothesis: MarketsPriceHypothesisFormData;
        operationId: Operation['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>(
    'MARKETS_PRICE_HYPOTHESIS/PUT',
    async ({ marketsPriceHypothesis, operationId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put<{ data?: MarketsPriceHypothesis }>(
                `/operations/${operationId}/markets/hypotheses/${marketsPriceHypothesis.id}`,
                marketsPriceHypothesis,
            );

            if (!response.data.data) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return response.data.data;
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.status === 400) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const slice = createSlice({
    name: 'marketsPriceHypothesis',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getAllMarketsPriceHypothesis.pending, (state, { meta }) => {
            if (meta.arg.operationId !== state.selectedOperationId) {
                state.marketsPriceHypothesisById = {};
            }
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllMarketsPriceHypothesis.fulfilled, (state, { payload }) => {
            payload.data.forEach((item: MarketsPriceHypothesis) => {
                state.marketsPriceHypothesisById[item.id] = item;
            });
            state.selectedOperationId = payload.operationId;
            state.loading = false;
        });
        builder.addCase(getAllMarketsPriceHypothesis.rejected, errorHandler());
        // Create
        builder.addCase(createMarketsPriceHypothesis.pending, (state) => {
            state.loading = true;
            state.isCreating = true;
            state.error = null;
        });
        builder.addCase(createMarketsPriceHypothesis.fulfilled, (state, { payload }) => {
            payload.forEach((item: MarketsPriceHypothesis) => {
                state.marketsPriceHypothesisById[item.id] = item;
            });
            state.loading = false;
            state.isCreating = false;
        });
        builder.addCase(
            createMarketsPriceHypothesis.rejected,
            errorHandler((state) => {
                state.isCreating = false;
            }),
        );
        // Update
        builder.addCase(updateMarketsPriceHypothesis.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateMarketsPriceHypothesis.fulfilled, (state, { payload }) => {
            state.marketsPriceHypothesisById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(updateMarketsPriceHypothesis.rejected, errorHandler());
    },
});

export const selectIsCreating = (state: RootState) => state.marketsPriceHypothesis.isCreating;
export const selectError = (state: RootState) => state.marketsPriceHypothesis.error;
export const selectAllMarketsPriceHypothesis = (state: RootState): MarketsPriceHypothesis[] =>
    Object.values(state.marketsPriceHypothesis.marketsPriceHypothesisById);

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
