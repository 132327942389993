import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { colors } from '../constants/colors';
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import SidebarImage from '../assets/images/sidebar-image.svg';
import LogoImage from '../assets/images/logos/logo_image_fonce.svg';
import UserMenu from './UserMenu';

// Data
import { getOperation, selectOperationIdDeleted, selectOperation } from '../slices/operationSlice';
import { getOrganization, selectOrganization } from '../slices/organizationSlice';
import { selectIsAdmin } from '../slices/authSlice';
import { useAppDispatch } from '../store';

const Container = styled.nav`
    @media print {
        display: none;
    }
    -ms-grid-column: 1;
    grid-area: sidebar;
    background-color: ${colors.blue.B400};
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
`;

type ContainerProps = {
    iscollapsed: string;
};

const SidebarList = styled.div<ContainerProps>`
    height: auto;
    width: auto;
    padding: 1.625rem 0;
    ${(props) => props.iscollapsed === 'true' && 'text-align: center;'}
    z-index: 10;
    img {
        margin-bottom: 0.625rem;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const Image = styled.img`
    position: absolute;
    bottom: 0;
    left: -3rem;
    width: inherit;
    height: auto;
`;

type Props = {
    isCollapsed: boolean;
};

const Sidebar: React.FC<Props> = ({ isCollapsed }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { operationId, organizationId }: { operationId?: string; organizationId?: string } =
        useParams();
    const operation = useSelector(selectOperation(Number(operationId)));
    const organization = useSelector(selectOrganization(organizationId));
    const isAuthenticatedUserAdmin = useSelector(selectIsAdmin);
    const operationIdDeleted = useSelector(selectOperationIdDeleted);

    // we get organization and/or operation if we have operationId and/or organizationId to make active SidebarSubLinks working
    useEffect(() => {
        if (
            !operation &&
            operationId &&
            operationId !== 'new' &&
            Number(operationId) !== operationIdDeleted
        ) {
            void dispatch(getOperation(Number(operationId)));
        }
    }, [dispatch, operationId, operation, operationIdDeleted]);

    useEffect(() => {
        if (!organization && organizationId) {
            void dispatch(getOrganization(organizationId));
        }
    }, [dispatch, organizationId, organization]);

    const operationsChildrenItems = [
        {
            to: '/:operationId',
            translationKey: 'sidebar.general',
            isActiveIfExact: true,
            permissionsRequired: [{ code: 'OPERATIONS_READ', operationId: ':operationId' }],
        },
        {
            to: '/:operationId/budgets',
            translationKey: 'sidebar.budget',
            permissionsRequired: [{ code: 'BUDGETS_READ', operationId: ':operationId' }],
        },
        {
            to: '/:operationId/markets',
            translationKey: 'sidebar.markets',
            permissionsRequired: [{ code: 'MARKETS_READ', operationId: ':operationId' }],
            activeMatchExclusions: ['/operations/:operationId/markets/planning'],
        },
        {
            to: '/:operationId/markets/planning',
            translationKey: 'sidebar.marketsPlanning',
            permissionsRequired: [
                {
                    code: 'MARKETS_PLANNING_READ',
                    operationId: ':operationId',
                },
            ],
        },
        {
            to: '/:operationId/settings',
            translationKey: 'sidebar.operationSettings',
            permissionsRequired: [{ code: 'OPERATIONS_READ', operationId: ':operationId' }],
        },
        {
            to: '/:operationId/labels',
            translationKey: 'sidebar.operationLabels',
            permissionsRequired: [{ code: 'LABELS_READ', operationId: ':operationId' }],
        },
        {
            to: '/:operationId/organizations',
            translationKey: 'sidebar.operationOrganizationsAndUsers',
            permissionsRequired: [
                { code: 'OPERATIONS_ORGANIZATIONS_READ', operationId: ':operationId' },
            ],
        },
    ];

    const organizationsChildrenItems = [
        {
            to: '/:organizationId',
            translationKey: 'sidebar.general',
            isActiveIfExact: true,
        },
        {
            to: '/:organizationId/users',
            translationKey: 'sidebar.users',
            permissionsRequired: [{ code: 'USERS_READ', organizationId: ':organizationId' }],
        },
        {
            to: '/:organizationId/roles',
            translationKey: 'sidebar.roles',
            permissionsRequired: [{ code: 'ROLES_READ', organizationId: ':organizationId' }],
            organizationNeedsToBeClient: true,
        },
        {
            to: '/:organizationId/sites',
            translationKey: 'sidebar.sites',
        },
        {
            to: '/:organizationId/operations',
            translationKey: 'sidebar.operations',
            permissionsRequired: [{ code: 'OPERATIONS_READ', organizationId: ':organizationId' }],
        },
        {
            to: '/:organizationId/labels/operation',
            translationKey: 'sidebar.clientLabels',
            permissionsRequired: [{ code: 'LABELS_READ', organizationId: ':organizationId' }],
            organizationNeedsToBeClient: true,
        },
    ];

    const administrationChildrenItems = [
        {
            to: '/users',
            translationKey: 'sidebar.users',
            permissionsRequired: [{ code: 'USERS_READ' }],
        },
        {
            to: '/price-indexes',
            translationKey: 'sidebar.priceIndexes',
            permissionsRequired: [{ code: 'PRICE_INDEXES_READ' }],
        },
        {
            to: '/spending-schema',
            translationKey: 'sidebar.spendingSchema',
            permissionsRequired: [{ code: 'SPENDING_SCHEMAS_READ' }],
        },
        {
            to: '/labels',
            translationKey: 'sidebar.generalLabels',
            permissionsRequired: [{ code: 'LABELS_PREVIZ_READ' }],
        },
        {
            to: '/roles',
            translationKey: 'sidebar.rolesByDefault',
            permissionsRequired: [{ code: 'ROLES_READ' }],
        },
    ];

    return (
        <Container>
            <Image src={SidebarImage} alt={t('altMessage.sidebarImage')} />
            <SidebarList iscollapsed={isCollapsed.toString()}>
                {isCollapsed ? (
                    <img src={LogoImage} width="40" height="40" alt={t('altMessage.logoImage')} />
                ) : (
                    <LogoContainer>
                        <Logo size="medium" background="dark" />
                    </LogoContainer>
                )}
                <SidebarItem
                    to="/dashboard"
                    translationKey="sidebar.dashboard"
                    activeIcon="Dashboard"
                    defaultIcon="DashboardOutlined"
                    activePaths={[]}
                    isCollapsed={isCollapsed}
                />
                <SidebarItem
                    to="/operations"
                    translationKey="sidebar.myOperations"
                    activeIcon="Work"
                    defaultIcon="WorkOutline"
                    activePaths={operationsChildrenItems.map(({ to }) => to)}
                    childrenItems={operationsChildrenItems}
                    isCollapsed={isCollapsed}
                />
                <SidebarItem
                    to="/organizations"
                    translationKey="sidebar.organizations"
                    activeIcon="LibraryBooks"
                    defaultIcon="LibraryBooksOutlined"
                    activePaths={organizationsChildrenItems.map(({ to }) => to)}
                    childrenItems={organizationsChildrenItems}
                    isCollapsed={isCollapsed}
                />
                {isAuthenticatedUserAdmin && (
                    <SidebarItem
                        to="/settings"
                        translationKey="sidebar.administration"
                        activeIcon="Topic"
                        defaultIcon="TopicOutline"
                        activePaths={administrationChildrenItems.map(({ to }) => to)}
                        childrenItems={administrationChildrenItems}
                        isCollapsed={isCollapsed}
                    />
                )}
            </SidebarList>
            <UserMenu />
        </Container>
    );
};

export default Sidebar;
