import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError } from './common';
import { errorHandler } from './common';
import type { IOrganization } from './organizationSlice';
import soleoSiret from '../constants/soleoSiret';
import type { Operation } from './operationSlice';
import type { IPermission } from './permissionSlice';

export interface IRole {
    id: number;
    name: string;
    description: string | null;
    label: string | null;
    scope: 'organization' | 'soleo_organization' | 'client_organization' | 'operation';
    isTechnical: boolean;
    isCustom: boolean;
    order: number | null;
    organizationId: IOrganization['id'] | null;
    permissions?: IPermission[];
    level: number;
}
export interface IRoleFormData {
    id?: IRole['id'] | null;
    name?: IRole['name'];
    description?: IRole['description'];
    label?: IRole['label'];
    scope?: IRole['scope'];
    isCustom?: IRole['isCustom'];
    order?: IRole['order'];
    organizationId?: IRole['organizationId'];
    permissions?: IRole['permissions'];
    isTechnical?: IRole['isTechnical'];
    level?: IRole['level'];
}

interface IRoleState {
    rolesById: Record<number, IRole>;
    loading: boolean;
    isCreateRoleFulfilled: boolean;
    isUpdateRoleFulfilled: boolean;
    isUpdateManyRolesFulfilled: boolean;
    isDeleleteRoleFulfilled: boolean;
    error: string | null;
}

export const initialState: IRoleState = {
    rolesById: {},
    loading: false,
    isCreateRoleFulfilled: false,
    isUpdateRoleFulfilled: false,
    isUpdateManyRolesFulfilled: false,
    isDeleleteRoleFulfilled: false,
    error: null,
};

export const getRoles = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IRole[],
    // First argument to the payload creator
    { organizationId?: IOrganization['id']; operationId?: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('ROLE/GETALL', async ({ organizationId, operationId }, { rejectWithValue }) => {
    try {
        let path = '/roles';

        if (organizationId) {
            path = `/organizations/${organizationId}${path}`;
        } else if (operationId) {
            path = `/operations/${operationId}${path}`;
        }

        const response = await axios.get<{ data: IRole[] }>(path);
        if (Array.isArray(response.data.data)) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getRole = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IRole,
    // First argument to the payload creator
    number,
    {
        rejectValue: ServerError;
    }
>('ROLE/GET', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: IRole }>(`/roles/${id}`);
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const createRole = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IRole,
    // First argument to the payload creator
    IRoleFormData & { organizationId: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('ROLE/POST', async (roleValues, { rejectWithValue }) => {
    try {
        const response = await axios.post<{ data?: IRole }>(
            `/organizations/${roleValues.organizationId}/roles`,
            roleValues,
        );
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when set new role',
            translationKey: 'errors.setNewRole',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.status === 400 || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updateRole = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IRole,
    // First argument to the payload creator
    IRoleFormData & { organizationId: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('ROLE/PUT', async (roleValues, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: IRole }>(
            `/organizations/${roleValues.organizationId}/roles/${roleValues.id}`,
            roleValues,
        );
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when update role',
            translationKey: 'errors.updateRole',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.status === 400 || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updateManyRoles = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IRole[],
    // First argument to the payload creator
    { roles: IRoleFormData[]; organizationId: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('USER/COLLECTION/PUT', async ({ roles, organizationId }, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: IRole[] }>(
            `/organizations/${organizationId}/roles/collection`,
            roles,
        );
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when update user',
            translationKey: 'errors.somethingWentWrong',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.status === 400 || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const deleteRole = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IRole['id'],
    // First argument to the payload creator
    { id: IRole['id']; organizationId: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('ROLE/DELETE', async ({ id, organizationId }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/organizations/${organizationId}/roles/${id}`);
        if (response.status === 204 || response.status === 200) {
            return id;
        }
        return rejectWithValue({
            message: 'Problem when delete role',
            translationKey: 'errors.deleteRole',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response.data);
    }
});

export const slice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        resetError(state) {
            state.error = null;
        },
    },
    extraReducers(builder) {
        // Get all
        builder.addCase(getRoles.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.rolesById = {};
        });
        builder.addCase(getRoles.fulfilled, (state, { payload }) => {
            payload.forEach((item: IRole) => {
                state.rolesById[item.id] = item;
            });
            state.loading = false;
        });
        builder.addCase(getRoles.rejected, errorHandler());
        // Get one
        builder.addCase(getRole.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getRole.fulfilled, (state, { payload }) => {
            state.rolesById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(getRole.rejected, errorHandler());
        // Create
        builder.addCase(createRole.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.isCreateRoleFulfilled = false;
        });
        builder.addCase(createRole.fulfilled, (state, { payload }) => {
            state.rolesById[payload.id] = payload;
            state.loading = false;
            state.isCreateRoleFulfilled = true;
        });
        builder.addCase(createRole.rejected, errorHandler());
        // Update
        builder.addCase(updateRole.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.isUpdateRoleFulfilled = false;
        });
        builder.addCase(updateRole.fulfilled, (state, { payload }) => {
            state.rolesById[payload.id] = payload;
            state.loading = false;
            state.isUpdateRoleFulfilled = true;
        });
        builder.addCase(updateRole.rejected, errorHandler());
        // Update many
        builder.addCase(updateManyRoles.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.isUpdateManyRolesFulfilled = false;
        });
        builder.addCase(updateManyRoles.fulfilled, (state, { payload }) => {
            payload.forEach((item: IRole) => {
                state.rolesById[item.id] = item;
            });
            state.loading = false;
            state.isUpdateManyRolesFulfilled = true;
        });
        builder.addCase(updateManyRoles.rejected, errorHandler());
        // Delete
        builder.addCase(deleteRole.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.isDeleleteRoleFulfilled = false;
        });
        builder.addCase(deleteRole.fulfilled, (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.rolesById[payload];
            state.loading = false;
            state.isDeleleteRoleFulfilled = true;
        });
        builder.addCase(deleteRole.rejected, errorHandler());
    },
});

export const checkIfRoleHasAnOrganizationScope = (role: IRole) =>
    role.scope === 'organization' ||
    role.scope === 'client_organization' ||
    role.scope === 'soleo_organization';

export const checkIfRoleHasAnOperationScope = (role: IRole) => role.scope === 'operation';

export const selectError = (state: RootState) => state.role.error;

export const selectRoles = (state: RootState): IRole[] => Object.values(state.role.rolesById);

export const selectNonTechnicalRoles = (state: RootState) =>
    selectRoles(state).filter((role) => !role.isTechnical);

export const selectRolesHavingAnOperationScope = (state: RootState) =>
    selectNonTechnicalRoles(state).filter((role) => checkIfRoleHasAnOperationScope(role));

const selectRolesHavingAnOrganizationScope = (state: RootState) =>
    selectNonTechnicalRoles(state).filter((role) => checkIfRoleHasAnOrganizationScope(role));

export const selectRolesThatCanBeSetOnAnOrganizationByAuthUser =
    (organization: IOrganization) => (state: RootState) => {
        const rolesHavingAnOrganizationScope = selectRolesHavingAnOrganizationScope(state);
        let rolesThatCanBeSetOnAnOrganization = [...rolesHavingAnOrganizationScope];

        if (!organization.estClient) {
            rolesThatCanBeSetOnAnOrganization = rolesThatCanBeSetOnAnOrganization.filter(
                (roleThatCanBeSetOnAnOrganization) =>
                    roleThatCanBeSetOnAnOrganization.scope !== 'client_organization',
            );
        }

        if (organization.siret !== soleoSiret) {
            rolesThatCanBeSetOnAnOrganization = rolesThatCanBeSetOnAnOrganization.filter(
                (roleThatCanBeSetOnAnOrganization) =>
                    roleThatCanBeSetOnAnOrganization.scope !== 'soleo_organization',
            );
        }

        const sortedRolesThatCanBeSetOnAnOrganization = rolesThatCanBeSetOnAnOrganization.sort(
            (roleA, roleB) => roleA.level - roleB.level,
        );

        return sortedRolesThatCanBeSetOnAnOrganization;
    };
export const selectRolesHavingAnOperationScopeAndSortedByDefaultFirstThenByOrder = (
    state: RootState,
): IRole[] => {
    const rolesHavingAnOperationScope = selectRolesHavingAnOperationScope(state);
    return rolesHavingAnOperationScope.sort((roleA, roleB) => {
        // Default roles will go at the top of the list
        if (!roleA.isCustom) return -1;
        if (!roleB.isCustom) return 1;
        // Role with a null order will go at the end of list
        // https://stackoverflow.com/questions/50933086/sort-an-array-of-objects-in-ascending-order-but-put-all-zeros-at-the-end/50933148
        if (roleA.order === null) return 1;
        if (roleB.order === null) return -1;
        return roleA.order - roleB.order;
    });
};
export const selectNbOfCustomRoles = (state: RootState): number => {
    const customRoles = Object.values(state.role.rolesById).filter((role) => role.isCustom);
    return customRoles.length;
};
export const selectRole = (id: IRole['id']) => (state: RootState) => state.role.rolesById[id];
export const selectIsLoading = (state: RootState) => state.role.loading;
export const selectIsCreateRoleFulfilled = (state: RootState) => state.role.isCreateRoleFulfilled;
export const selectIsUpdateRoleFulfilled = (state: RootState) => state.role.isUpdateRoleFulfilled;
export const selectIsUpdateManyRolesFulfilled = (state: RootState) =>
    state.role.isUpdateManyRolesFulfilled;
export const selectIsDeleteRoleFulfilled = (state: RootState) => state.role.isDeleleteRoleFulfilled;

// Actions added into the `reducers` part
export const { resetError } = slice.actions;

export default slice.reducer;
