import { useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { format, addDays } from 'date-fns';
import styled, { createGlobalStyle } from 'styled-components';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Classes } from '@blueprintjs/core';

// Slices
import { selectMarket } from '../slices/marketSlice';
import type { MarketInvoice } from '../slices/invoiceSlice';
import { selectLastMarketInvoices } from '../slices/invoiceSlice';

// Hooks
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';

// Utils
import { CurrencyFormatter, formatNumberWithLeadingZeros } from '../utils/formatters';

// Style
import { colors } from '../constants/colors';
import { styles } from '../constants/styles';

// Components
import Text from './Text';
import Elevation from './Elevation';
import Popover from './Popover';
import Icon from './Icon';

const Line = styled.div`
    display: flex;
    width: 100%;
    padding: 0 1rem;
    background-color: ${colors.other.white};
    border-radius: ${styles.borderRadiusSmall};
    margin: 0 0 0.5rem 0;
    cursor: pointer;
`;

const StyledGridCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LineText = styled(Text)`
    margin: 0;
`;

const PopoverContent = styled.div`
    display: flex;

    flex-direction: column;
`;

const PopoverItem = styled(Text)<{ className: string }>`
    cursor: pointer;
    width: auto;
    :hover {
        background-color: ${colors.blue.B50};
        color: ${colors.green.G400};
    }
`;

const ContextualMenuIcon = styled(Icon)`
    cursor: pointer;
`;

const ClickableZone = styled.div`
    display: flex;
    width: 100%;
`;

const ContextualMenuIconZone = styled.div`
    width: 1.5rem;
    height: 1.5rem;
`;

const GlobalStyle = createGlobalStyle`
    .bp4-popover {
        display: contents;
        border: 0.0625rem solid ${colors.neutral.N75};
        .bp4-popover-content {
            border-radius: ${styles.borderRadiusMedium}
        }
    }
`;

type Props = {
    id: number;
    columns: Array<{
        header: string;
        style: React.CSSProperties;
    }>;
    marketInvoice: Partial<MarketInvoice>;
    handleDeleteClick: (id: number) => void;
};

const MarketInvoiceLine: React.FC<Props> = ({ id, columns, marketInvoice, handleDeleteClick }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { operationId, marketId }: { operationId?: string; marketId?: string } = useParams();
    const market = useSelector(selectMarket(Number(marketId)));
    const lastMarketInvoicesId = useSelector(selectLastMarketInvoices);

    const permissionNeededToEdit = { code: 'INVOICES_EDIT', operationId };
    const permissionNeededToDelete = { code: 'INVOICES_DELETE', operationId };

    let hasRightToEditInvoices = usePermissionsCheck([permissionNeededToEdit]);
    let hasRightToDeleteInvoices = usePermissionsCheck([permissionNeededToDelete]);

    // Editing & deleting are only possible if invoice is the last one of it's group code
    if (!lastMarketInvoicesId.includes(Number(marketInvoice.id))) {
        hasRightToEditInvoices = false;
        hasRightToDeleteInvoices = false;
    }

    let deadlineValue = '';
    if (marketInvoice.dueDate) {
        deadlineValue = format(new Date(marketInvoice.dueDate), 'dd/MM/yyyy');
    } else {
        deadlineValue =
            marketInvoice.presentedAt && market?.paymentDelay
                ? format(
                      addDays(new Date(marketInvoice.presentedAt), market.paymentDelay),
                      'dd/MM/yyyy',
                  )
                : '-';
    }

    const handleEditClick = () => {
        navigate(
            `/operations/${operationId}/markets/${marketId}/invoices/${marketInvoice.id}?edit`,
            {
                state: {
                    isCreation: true,
                },
            },
        );
    };

    return (
        <>
            <GlobalStyle />
            <Elevation elevation="default">
                <Line>
                    <ClickableZone
                        onClick={() => navigate(`${location.pathname}/${marketInvoice.id}`)}
                    >
                        <StyledGridCol style={{ width: '10%', justifyContent: 'flex-start' }}>
                            <LineText>{marketInvoice.month ?? '-'}</LineText>
                        </StyledGridCol>
                        <StyledGridCol style={{ width: '17%', justifyContent: 'flex-start' }}>
                            <LineText>
                                {market?.isJointGroupMarket && `${marketInvoice.groupCode} `}
                                {marketInvoice.invoiceNumber &&
                                    String(
                                        formatNumberWithLeadingZeros(
                                            marketInvoice.invoiceNumber,
                                            3,
                                        ),
                                    )}
                            </LineText>
                        </StyledGridCol>
                        <StyledGridCol style={{ width: '17%', justifyContent: 'flex-start' }}>
                            <LineText>
                                {marketInvoice.presentedAt
                                    ? format(new Date(marketInvoice.presentedAt), 'dd/MM/yyyy')
                                    : '-'}
                            </LineText>
                        </StyledGridCol>
                        <StyledGridCol style={{ width: '17%', justifyContent: 'flex-start' }}>
                            <LineText>
                                {marketInvoice.approvalProjectOwnerAt
                                    ? format(
                                          new Date(marketInvoice.approvalProjectOwnerAt),
                                          'dd/MM/yyyy',
                                      )
                                    : '-'}
                            </LineText>
                        </StyledGridCol>
                        <StyledGridCol style={{ width: '17%', justifyContent: 'flex-start' }}>
                            <LineText>{deadlineValue}</LineText>
                        </StyledGridCol>
                        <StyledGridCol style={{ width: '17%', justifyContent: 'flex-end' }}>
                            <LineText isNumeral>
                                {marketInvoice.totalAmountTtc !== undefined
                                    ? CurrencyFormatter.format(marketInvoice.totalAmountTtc)
                                    : '-'}
                            </LineText>
                        </StyledGridCol>
                    </ClickableZone>

                    <StyledGridCol>
                        {hasRightToEditInvoices || hasRightToDeleteInvoices ? (
                            <Popover>
                                <ContextualMenuIcon name="MoreHoriz" data-testid="popOverOptions" />
                                <Elevation elevation="default">
                                    <PopoverContent>
                                        {hasRightToEditInvoices && (
                                            <PopoverItem
                                                className={Classes.POPOVER_DISMISS}
                                                onClick={handleEditClick}
                                                style={{
                                                    margin: '0',
                                                    padding: '0.5rem 1.125rem',
                                                }}
                                                data-testid="modifyInvoice"
                                            >
                                                {t('general.edit')}
                                            </PopoverItem>
                                        )}
                                        {hasRightToDeleteInvoices && (
                                            <PopoverItem
                                                className={Classes.POPOVER_DISMISS}
                                                onClick={() => {
                                                    if (marketInvoice.id) {
                                                        handleDeleteClick(marketInvoice.id);
                                                    }
                                                }}
                                                style={{
                                                    margin: '0',
                                                    padding: '0.5rem 1.125rem',
                                                }}
                                                data-testid="deleteInvoice"
                                            >
                                                {t('general.delete')}
                                            </PopoverItem>
                                        )}
                                    </PopoverContent>
                                </Elevation>
                            </Popover>
                        ) : (
                            <ContextualMenuIconZone />
                        )}
                    </StyledGridCol>
                </Line>
            </Elevation>
        </>
    );
};

export default MarketInvoiceLine;
