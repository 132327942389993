import { useTranslation } from 'react-i18next';
import type { CSSProperties } from 'react';
import React from 'react';

import type { Permission } from '../slices/authSlice';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import type { TextareaProps } from './TextareaField';
import TextareaField from './TextareaField';
import PermissionAwareDisplay from './PermissionAwareDisplay';

type Props = TextareaProps & {
    editable?: boolean;
    permissionsRequired: Permission[];
    name: string;
    label: string;
    'data-testid'?: string;
    errorTestId?: string;
    placeholder?: string;
    value?: string | undefined;
    whiteBackground?: boolean;
    noMarginTop?: boolean;
    displayStyle?: CSSProperties;
    isOptional?: boolean;
    defaultEmptyValue?: string;
};

const PermissionAwareTextarea: React.FC<Props> = ({
    editable,
    name,
    label,
    value,
    permissionsRequired,
    displayStyle,
    isOptional,
    defaultEmptyValue,
    ...props
}) => {
    const { t } = useTranslation();
    const hasRightToEdit = usePermissionsCheck(permissionsRequired);
    const fieldLabel = isOptional ? `${label} ${t('general.optional')}` : label;

    return hasRightToEdit && editable ? (
        <TextareaField {...props} name={name} label={fieldLabel} value={value} />
    ) : (
        <PermissionAwareDisplay
            label={fieldLabel}
            value={value ?? ''}
            style={displayStyle}
            defaultEmptyValue={defaultEmptyValue}
        />
    );
};

export default PermissionAwareTextarea;
