import type { Engagement } from '../slices/engagementSlice';
import { roundAmount } from './calculationMethods';

export const getFactor = (durationInMonth: number | null) =>
    durationInMonth && durationInMonth > 12 ? 12 / durationInMonth : 1;

export const calculateAdvancePaymentAmountTtc = (
    amountTtc: number,
    advancePaymentPercentage: number,
    durationInMonth: number | null,
) => {
    const factor = getFactor(durationInMonth);
    return roundAmount(((amountTtc * advancePaymentPercentage) / 100) * factor, 2);
};

export const calculateAdvancePaymentPercentage = (
    advanceTtc: number,
    amountTtc: number,
    durationInMonth: number | null,
) => {
    const factor = getFactor(durationInMonth);
    return roundAmount((advanceTtc / amountTtc / factor) * 100, 2);
};

export const calculateDownPaymentAmountTtc = (amountTtc: number, downPercentage: number) =>
    roundAmount(amountTtc * (downPercentage / 100), 2);

export const calculateDownPaymentPercentage = (amountTtc: number, downTtc: number) =>
    roundAmount((downTtc / amountTtc) * 100, 2);

export const calculateWarrantyTtcAmount = (amountTtc: number, percentage: number) =>
    roundAmount((amountTtc * percentage) / 100, 2);

export const calculateTotalAmountHtByMarketType = (engagements: Engagement[], type: string) => {
    let value = 0;
    engagements.forEach((engagement) => {
        if (engagement.engagementType === type) {
            value += engagement.amountHt;
        }
    });
    return value;
};

export const calculateVariationsAmountHtTotal = (engagements: Engagement[]) =>
    engagements.reduce((sum, engagement) => sum + engagement.variationsAmountHt, 0);
