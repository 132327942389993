import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import type { AppDispatch, RootState } from '../store';
import { useAppDispatch } from '../store';
import { isRefreshTokenExpired } from '../utils/tokens';
import type { User } from './userSlice';
import type { ServerError } from './common';
import { errorHandler } from './common';
import type { IOrganization } from './organizationSlice';
import { gotUserOrganization } from './organizationSlice';

export interface IJWTToken {
    jti: number;
    iat: number;
    exp: number;
    sub: number;
    userImpersonating?: User['id'];
}

export interface IPermissionToken extends IJWTToken {
    permissions: Permission[];
}

export interface IIsAdminToken extends IJWTToken {
    isAdmin: boolean;
}

export type Permission = {
    code: string;
    organizationId?: string | null;
    operationId?: string | number | null;
};

interface IAuthFormData {
    email: User['email'];
    password: string;
}

interface IAuthAsUserFormData {
    email: User['email'];
    userImpersonating: User['id'];
}
interface ICompleteSetup {
    token: string;
    password: string;
    isAccountSetup?: boolean;
}

interface IPasswordReset {
    email: string;
}

interface ICompleteSetup {
    token: string;
    password: string;
}

export type MyUser = User & {
    operationDashboardUrl?: string;
    overviewDashboardUrl?: string;
};

interface IAuthState {
    blockedAt: number | null;
    error: string | null;
    loading: boolean;
    loginAttempts: number;
    permissions?: Permission[] | null;
    isAdmin?: boolean;
    userImpersonating?: User['id'];
    user: MyUser | null;
    updateSucceeded: boolean;
    inactivityLogout: boolean;
    loggedOut: boolean;
}

const blockedAt: number | null = Number(localStorage.getItem('blockedAt'));

function manageBlockedAt(blockedAtTimestamp: number | null): number | null {
    if (blockedAtTimestamp === null) {
        return null;
    }

    const delayInMinutes: number = process.env.REACT_APP_BLOCK_DELAY_IN_MINUTES
        ? Number(process.env.REACT_APP_BLOCK_DELAY_IN_MINUTES)
        : 10;
    const delayInMs: number = delayInMinutes * 60 * 1000;
    const blockExpirationTimestamp = blockedAtTimestamp + delayInMs;

    if (Date.now() > blockExpirationTimestamp) {
        localStorage.removeItem('blockedAt');
        return null;
    }

    return blockedAtTimestamp;
}

export const initialState: IAuthState = {
    blockedAt: manageBlockedAt(blockedAt),
    error: manageBlockedAt(blockedAt) !== null ? 'errors.tooManyLoginAttempts' : null,
    loading: false,
    loginAttempts: 0,
    permissions: null,
    user: null,
    updateSucceeded: false,
    inactivityLogout: false,
    loggedOut: false,
};

export const whoAmI = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    User,
    // First argument to the payload creator
    void,
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('auth/whoAmI', async (_, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.get<{ data?: User & { organization: IOrganization } }>(
            '/auth/whoami',
        );
        if (response.status === 200) {
            if (response.data.data) {
                const { organization, ...user } = response.data.data;

                dispatch(gotUserOrganization(organization));

                const checkRefreshTokenInterval = setInterval(() => {
                    if (isRefreshTokenExpired()) {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('permissions_token');
                        localStorage.removeItem('is_admin_token');
                        dispatch(setInactivityLogout());
                        clearInterval(checkRefreshTokenInterval);
                    }
                }, 60000);

                return user;
            }

            return rejectWithValue({
                message: 'No user in data',
                translationKey: 'errors.noUserInData',
            });
        } else {
            localStorage.removeItem('access_token');
            // Clear store
            return rejectWithValue({
                message: 'Bad response status',
                translationKey: 'errors.badResponseStatus',
            });
        }
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('permissions_token');
        localStorage.removeItem('is_admin_token');

        console.error(err);

        if (error.response) {
            return rejectWithValue(error.response.data);
        } else {
            throw err;
        }
    }
});

export const login = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Permission[],
    // First argument to the payload creator
    IAuthFormData,
    {
        rejectValue: ServerError;
    }
>('auth/login', async (payload: IAuthFormData, { rejectWithValue, dispatch }) => {
    const appDispatch = dispatch as AppDispatch;
    try {
        const response = await axios.post<{
            data?: {
                token: string;
                refreshToken: string;
                permissionToken: string;
                isAdminToken: string;
            };
        }>('/auth/login', payload);
        if (response.data.data?.token) {
            // eslint-disable-next-line require-atomic-updates -- needed
            axios.defaults.headers.common.Authorization = `Bearer ${response.data.data.token}`;
            localStorage.setItem('access_token', response.data.data.token);
            localStorage.setItem('refresh_token', response.data.data.refreshToken);
            localStorage.setItem('permission_token', response.data.data.permissionToken);
            localStorage.setItem('is_admin_token', response.data.data.isAdminToken);

            const refreshTokenDecoded: IJWTToken = jwtDecode(response.data.data.refreshToken);
            const isAdminTokenDecoded: IIsAdminToken = jwtDecode(response.data.data.isAdminToken);
            const permissionTokenDecoded: IPermissionToken = jwtDecode(
                response.data.data.permissionToken,
            );
            dispatch(setIsAdmin(isAdminTokenDecoded.isAdmin));
            dispatch(setUserImpersonating(refreshTokenDecoded.userImpersonating));
            void appDispatch(whoAmI());

            return permissionTokenDecoded.permissions;
        } else {
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('permissions_token');
        localStorage.removeItem('is_admin_token');

        if (error.response) {
            return rejectWithValue(error.response.data);
        } else {
            throw err;
        }
    }
});

export const loginAsUser = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Permission[],
    // First argument to the payload creator
    IAuthAsUserFormData,
    {
        rejectValue: ServerError;
    }
>('auth/login-as-user', async (payload: IAuthAsUserFormData, { rejectWithValue, dispatch }) => {
    const appDispatch = dispatch as AppDispatch;

    try {
        const response = await axios.post<{
            data?: {
                token: string;
                refreshToken: string;
                permissionToken: string;
                isAdminToken: string;
            };
        }>('/auth/login-as-user', payload);
        if (response.data.data?.token) {
            // eslint-disable-next-line require-atomic-updates -- needed
            axios.defaults.headers.common.Authorization = `Bearer ${response.data.data.token}`;
            localStorage.setItem('access_token', response.data.data.token);
            localStorage.setItem('refresh_token', response.data.data.refreshToken);
            localStorage.setItem('permission_token', response.data.data.permissionToken);
            localStorage.setItem('is_admin_token', response.data.data.isAdminToken);

            const refreshTokenDecoded: IJWTToken = jwtDecode(response.data.data.refreshToken);
            const isAdminTokenDecoded: IIsAdminToken = jwtDecode(response.data.data.isAdminToken);
            const permissionTokenDecoded: IPermissionToken = jwtDecode(
                response.data.data.permissionToken,
            );
            dispatch(setIsAdmin(isAdminTokenDecoded.isAdmin));
            dispatch(setUserImpersonating(refreshTokenDecoded.userImpersonating));
            void appDispatch(whoAmI());

            return permissionTokenDecoded.permissions;
        } else {
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response) {
            return rejectWithValue(error.response.data);
        } else {
            throw err;
        }
    }
});

export const resetPassword = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    void,
    // First argument to the payload creator
    ICompleteSetup,
    {
        rejectValue: ServerError;
    }
>('auth/reset-password', async ({ token, password, isAccountSetup }, { rejectWithValue }) => {
    try {
        if (isAccountSetup) {
            await axios
                .post(`/users/finish-account-setup`, { token, password })
                .catch((e) => console.log(e));
        } else {
            await axios
                .post(`/users/reset-password`, { token, password })
                .catch((e) => console.log(e));
        }
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response) {
            return rejectWithValue(error.response.data);
        } else {
            throw err;
        }
    }
});

export const requestResetPasswordEmail = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    void,
    // First argument to the payload creator
    IPasswordReset
>('auth/reset-password', async ({ email }) => {
    try {
        await axios.post(`/users/send-password-reset`, { email });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>; // cast the error for access
        if (!error.response) {
            throw err;
        }
    }
});

export const requestActivationEmail = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    void,
    // First argument to the payload creator
    IPasswordReset
>('auth/send-activation', async ({ email }) => {
    try {
        await axios.post(`/users/send-activation`, { email });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>; // cast the error for access
        if (!error.response) {
            throw err;
        }
    }
});

const clearTokens = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('permissions_token');
    localStorage.removeItem('is_admin_token');
    axios.defaults.headers.common.Authorization = '';
};

export const logout = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    void,
    // First argument to the payload creator
    boolean | undefined,
    {
        rejectValue: ServerError;
    }
>('auth/logout', async (skipServer = false, { rejectWithValue }) => {
    try {
        if (!skipServer) {
            const response = await axios.post(`/auth/logout`);
            clearTokens();
            if (response.status !== 204) {
                return rejectWithValue({
                    message: 'Logout failed',
                    translationKey: 'errors.logoutFailed',
                });
            }
        } else {
            clearTokens();
        }
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        clearTokens();

        if (error.response) {
            return rejectWithValue(error.response.data);
        } else {
            throw err;
        }
    }
});

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setPermissions(state, action: PayloadAction<Permission[]>) {
            state.permissions = action.payload;
        },
        setIsAdmin(state, action: PayloadAction<boolean>) {
            state.isAdmin = action.payload;
        },
        setUserImpersonating(state, action: PayloadAction<User['id'] | undefined>) {
            state.userImpersonating = action.payload;
        },
        setInactivityLogout(state) {
            state.inactivityLogout = true;
            state.user = null;
        },
        resetInactivityLogout(state) {
            state.inactivityLogout = false;
        },
    },
    extraReducers(builder) {
        // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.loggedOut = false;
            state.error = null;
        });
        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.permissions = payload;
            state.loading = false;
            state.loginAttempts = 0;
            state.blockedAt = null;
            localStorage.removeItem('blockedAt');
            state.error = null;
        });
        builder.addCase(
            login.rejected,
            errorHandler((state, action) => {
                // If it was blocked
                if (state.blockedAt) {
                    // Checking the expiration
                    state.blockedAt = manageBlockedAt(state.blockedAt);
                    // If it was expired
                    if (!state.blockedAt) {
                        state.loginAttempts = 0;
                    } else {
                        state.error = 'errors.tooManyLoginAttempts';
                    }
                }

                // Only increment on 400 error
                if (action.payload?.translationKey === 'errors.matchingCredentials') {
                    state.loginAttempts += 1;
                }

                if (
                    state.loginAttempts > 3 ||
                    action.payload?.translationKey === 'errors.tooManyRequests'
                ) {
                    state.blockedAt = Date.now();
                    localStorage.setItem('blockedAt', state.blockedAt.toString());
                    state.error = 'errors.tooManyLoginAttempts';
                }
            }),
        );
        builder.addCase(loginAsUser.pending, (state) => {
            state.loading = true;
            state.loggedOut = false;
            state.error = null;
        });
        builder.addCase(loginAsUser.fulfilled, (state, { payload }) => {
            state.permissions = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(loginAsUser.rejected, errorHandler());
        builder.addCase(whoAmI.pending, (state) => {
            state.loading = true;
            state.loggedOut = false;
            state.error = null;
        });
        builder.addCase(whoAmI.fulfilled, (state, { payload }) => {
            state.user = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(whoAmI.rejected, errorHandler());
        builder.addCase(resetPassword.pending, (state) => {
            state.loading = true;
            state.updateSucceeded = false;
        });
        builder.addCase(resetPassword.fulfilled, (state) => {
            state.loading = false;
            state.updateSucceeded = true;
            state.error = null;
        });
        builder.addCase(
            resetPassword.rejected,
            errorHandler((state, action) => {
                state.updateSucceeded = false;
            }),
        );
        builder.addCase(logout.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.user = null;
            state.loading = false;
            state.loggedOut = true;
            state.error = null;
        });
        builder.addCase(
            logout.rejected,
            errorHandler((state, action) => {
                state.user = null;
                state.loggedOut = true;
                state.error = null;
            }),
        );
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.auth.value)`
export const selectUser = (state: RootState) => state.auth.user;
export const selectServerError = (state: RootState) => state.auth.error;
export const selectBlockedAt = (state: RootState) => manageBlockedAt(state.auth.blockedAt);
export const selectUserPermissions = (state: RootState) => state.auth.permissions;
export const selectSpecificUserPermissions = (permissionCode: string) => (state: RootState) =>
    state.auth.permissions?.filter(({ code }) => code === permissionCode) ?? [];
export const selectIsAdmin = (state: RootState) => state.auth.isAdmin;
export const selectUserImpersonating = (state: RootState) => state.auth.userImpersonating;
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectUpdateSucceeded = (state: RootState) => state.auth.updateSucceeded;
export const selectInactivityLogout = (state: RootState) => state.auth.inactivityLogout;
export const selectLoggedOut = (state: RootState) => state.auth.loggedOut;

export const {
    setPermissions,
    setIsAdmin,
    setUserImpersonating,
    setInactivityLogout,
    resetInactivityLogout,
} = slice.actions;

export default slice.reducer;
