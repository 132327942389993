import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import Icon from './Icon';
import Text from './Text';

const Line = styled.div<{ borderRadius?: string }>`
    background-color: ${colors.blue.B50};
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 1rem;
    border-radius: ${({ borderRadius }) => borderRadius ?? styles.borderRadiusSmall};
`;

const LineText = styled(Text)`
    margin: 0;
`;

type Props = {
    borderRadius?: string;
    text: string;
    onClick: () => void;
};

const AddItemLine: React.FC<Props> = ({ borderRadius, text, onClick }) => (
    <Line borderRadius={borderRadius} onClick={onClick}>
        <Icon
            name="AddBox"
            color={colors.blue.B400}
            style={{ marginRight: '1rem' }}
            width="1.125rem"
        />
        <LineText type="H500" size="0.875rem" color={colors.neutral.N400}>
            {text}
        </LineText>
    </Line>
);

export default AddItemLine;
