import React from 'react';

import type { Props as PaginationProps } from './Pagination';
import Pagination from './Pagination';
import type { QueryParams } from '../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../slices/common';

type Props = {
    initialPage: PaginationProps['initialPage'];
    currentPage: PaginationProps['currentPage'];
    nbItemsTotal: PaginationProps['nbItemsTotal'];
    queryParams: QueryParams;
    setQueryParams: (value: React.SetStateAction<QueryParams>) => void;
    setIsSearchReset: (value: React.SetStateAction<boolean>) => void;
};

const OrganizationsSearch: React.FunctionComponent<Props> = ({
    initialPage,
    currentPage,
    nbItemsTotal,
    queryParams,
    setQueryParams,
    setIsSearchReset,
}) => (
    <Pagination
        data-testid="pagination"
        initialPage={initialPage}
        nbPagesMax={DEFAULT_ITEMS_PER_PAGE}
        currentPage={currentPage}
        pageNeighbours={2}
        nbItemsPerPage={12}
        nbItemsTotal={nbItemsTotal}
        onPageNumberClicked={(page: number) => {
            setIsSearchReset(false);
            setQueryParams({ ...queryParams, page });
        }}
        pageUrl="organizations"
    />
);

export default OrganizationsSearch;
