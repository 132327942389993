export const CurrencyFormatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
});

/**
 *    Not only works as toFixed(2) but also takes care of thousands and locale
 *    TODO: We need apply actual localization. Currently set to fr-FR
 *
 *    Ex:
 *        number: 1000000.938457
 *        .toFixed(2): 1000000.94
 *        Intl.NumberFormat(‘fr-FR’): 1 000 000,938
 *        Intl.NumberFormat(‘en-US’): 1,000,000.938
 *
 */

export const NumberFormatter = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 2,
});

export const PercentageFormatter = new Intl.NumberFormat('fr-FR', {
    style: 'percent',
    minimumFractionDigits: 2,
});

export const separateThousands = (value: string, separator = ' ') =>
    String(value).replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const joinThousands = (value: string, separator = ' ') => value.split(separator).join('');

export const formatNumberWithLeadingZeros = (number: number, nbMinOfDigits: number): string =>
    String(number).padStart(nbMinOfDigits, '0');

export const replaceTextValueIfNaNOrEmpty = (
    value: string | React.ReactNode,
    isNumeral?: boolean,
    defaultEmptyString?: string,
) => {
    if (value) {
        if (isNumeral && typeof value === 'string' && value.includes('NaN')) {
            let replacedValue = '0,00';

            const valueIncludesEuroSign = value.includes('€');
            if (valueIncludesEuroSign) {
                replacedValue += ' €';
            }
            return replacedValue;
        }
        return value;
    } else {
        if (defaultEmptyString) {
            return defaultEmptyString;
        }
        return '';
    }
};
