import React from 'react';
import { useField } from 'formik';
import type { ISelectProps as Props } from '@blueprintjs/select';

import type { Option } from '../SelectField';
import OptionDropdown from './OptionDropdown';

export type StatusOption = Option<string | number>;

export type ISelectProps<T> = {
    'data-testid'?: string;
    emptyLabelSpace?: boolean;
    errorTestId?: string;
    filterable?: Props<T>['filterable'];
    handleChange?: (option: T) => void;
    initialNameDiplay: string | React.ReactNode;
    itemPredicate?: Props<T>['itemPredicate'];
    itemRenderer?: Props<T>['itemRenderer'];
    items: Props<T>['items'];
    label?: string;
    name: string;
    noResults?: Props<T>['noResults'];
    onItemSelect?: Props<T>['onItemSelect'];
    style?: React.CSSProperties;
    marginBottom?: boolean;
    buttonPadding?: string;
    buttonBorder?: string;
    isOptional?: boolean;
    disabled?: boolean;
    children?: React.ReactChildren;
};

const FormikOptionDropdown = <T extends StatusOption>(props: ISelectProps<T>) => {
    const formikFields = useField(props.name); // [field, meta, helpers]
    const meta = formikFields[1];
    const setValue = formikFields[2].setValue;

    const handleValueChange = (value: StatusOption['value']) => setValue(value);

    return <OptionDropdown {...props} onValueChange={handleValueChange} formikMeta={meta} />;
};

export default FormikOptionDropdown;
