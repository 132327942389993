import React from 'react';

import type { Props as CardProps } from './Card';
import Card from './Card';
import Elevation from './Elevation';
import GridCol from './GridCol';
import GridRow from './GridRow';

type BaseT = {
    id: string | number;
};

type Props<T> = {
    data: T[] | null;
    mapDataToCardProps: (dataItem: T | Partial<T>) => CardProps;
    cardPopoverMenu?: (dataItem: T | Partial<T>) => React.ReactNode;
    footerComponent?: React.ReactNode;
};

type CardGridProps<T> = {
    onCardClick?: (card: T) => void;
};

const CardList = <B extends BaseT>({
    data,
    mapDataToCardProps,
    onCardClick,
    cardPopoverMenu,
    ...props
}: Props<B> & CardGridProps<B>) => (
    <GridRow>
        {data?.map((dataItem: B) => (
            <GridCol
                key={`card${dataItem.id}`}
                smallScreen={6}
                defaultScreen={6}
                data-testid="card"
            >
                <Elevation elevation={onCardClick ? 'default' : 'none'}>
                    <Card
                        {...props}
                        {...mapDataToCardProps(dataItem)}
                        popoverMenu={cardPopoverMenu ? cardPopoverMenu(dataItem) : undefined}
                        onClick={onCardClick ? () => onCardClick(dataItem) : undefined}
                    />
                </Elevation>
            </GridCol>
        ))}
    </GridRow>
);

export default CardList;
