import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import type { TextFieldProps } from './TextField';
import TextField from './TextField';

type ContainerProps = {
    maxWidth?: string;
};
const Container = styled.div<ContainerProps>`
    width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
`;

type Props = TextFieldProps & {
    errorTestId?: string;
    name: string;
    hideError?: boolean;
    maxWidth?: string;
    marginBottom?: string;
    overrideErrorMessage?: string;
};

export type TextFieldWithFormikProps = Props;

const TextFieldWithFormik: React.FC<Props> = ({
    name,
    hideError,
    type,
    maxWidth,
    marginBottom,
    overrideErrorMessage,
    ...props
}) => {
    const [field, meta] = useField(name);
    let errorMessage;
    if (!hideError && meta.error && meta.touched) {
        errorMessage = meta.error;
    }

    return (
        <Container maxWidth={maxWidth}>
            <TextField
                type={type}
                {...props}
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(e);
                    }

                    field.onChange(e);
                }}
                name={name}
                formikField={field}
                errorMessage={overrideErrorMessage ?? errorMessage}
                marginBottom={marginBottom}
            />
        </Container>
    );
};

export default TextFieldWithFormik;
