import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError } from './common';
import { errorHandler } from './common';
import type { Option } from '../components/SelectField';

export type ISpendingSchema = {
    id: number;
    label: string;
    spendingSchemaCurvePart: SpendingSchemaCurvePart[];
};

export type SpendingSchemaCurvePart = {
    id: number;
    spendingSchemaId: number;
    order: number;
    xStart: number;
    yStart: number;
    dx1: number;
    dy1: number;
    dx2: number;
    dy2: number;
    xEnd: number;
    yEnd: number;
};

export type SpendingSchemaOption = Option<number | string>;

interface ISpendingSchemaState {
    spendingSchemasById: Record<string, ISpendingSchema>;
    loading: boolean;
    error: string | null;
}

export const initialState: ISpendingSchemaState = {
    spendingSchemasById: {},
    loading: false,
    error: null,
};

export const getSpendingSchemas = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    ISpendingSchema[],
    // First argument to the payload creator
    void,
    {
        rejectValue: ServerError;
    }
>('SPENDING_SCHEMAS/GETALL', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data: ISpendingSchema[] }>('/spending-schemas');
        if (Array.isArray(response.data.data)) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getSpendingSchema = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    ISpendingSchema,
    // First argument to the payload creator
    ISpendingSchema['id'],
    {
        rejectValue: ServerError;
    }
>('SPENDING_SCHEMA/GET', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: ISpendingSchema }>(`/spending-schemas/${id}`);

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const slice = createSlice({
    name: 'spendingSchema',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getSpendingSchemas.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSpendingSchemas.fulfilled, (state, { payload }) => {
            payload.forEach((item: ISpendingSchema) => {
                state.spendingSchemasById[item.id] = item;
            });
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getSpendingSchemas.rejected, errorHandler());
        // Get one
        builder.addCase(getSpendingSchema.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getSpendingSchema.fulfilled, (state, { payload }) => {
            state.spendingSchemasById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getSpendingSchema.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.spendingSchema.error;

export const selectSpendingSchema = (id: ISpendingSchema['id'] | undefined) =>
    function (state: RootState): ISpendingSchema | null {
        return id ? state.spendingSchema.spendingSchemasById[id] : null;
    };

export const selectSpendingSchemas = (state: RootState) =>
    Object.values(state.spendingSchema.spendingSchemasById);

export const selectSpendingSchemasForDropdown = (state: RootState) => {
    const spendingSchemasArray = Object.values(state.spendingSchema.spendingSchemasById);

    return spendingSchemasArray.map(({ id, label, spendingSchemaCurvePart }: ISpendingSchema) => {
        const curvePoints: Array<{ x: number; y: number }> = [];
        spendingSchemaCurvePart.forEach((curvePart: SpendingSchemaCurvePart) => {
            if (curvePart.order === 1) {
                curvePoints.push({ x: curvePart.xStart, y: curvePart.yStart });
                curvePoints.push({ x: curvePart.xEnd, y: curvePart.yEnd });
            } else if (curvePart.order === 3) {
                curvePoints.push({ x: curvePart.xStart, y: curvePart.yStart });
                curvePoints.push({ x: curvePart.xEnd, y: curvePart.yEnd });
            }
        });
        return { value: id, label, curvePoints };
    });
};

export const selectIsLoading = (state: RootState) => state.spendingSchema.loading;

export default slice.reducer;
