import type { HTMLAttributes } from 'react';
import React from 'react';
import { Row } from '@bootstrap-styled/v4';

import { styles } from '../constants/styles';

// if others types are needed, see https://github.com/bootstrap-styled/v4/pull/136/files
type RowProps = {
    noGutters?: boolean;
    gutterWidth?: string;
    isActive?: boolean;
} & HTMLAttributes<HTMLElement>;

const GridRow: React.FC<RowProps> = ({
    children,
    gutterWidth = styles.gutterWidth,
    isActive,
    ...rest
}) => (
    <Row
        theme={{
            '$grid-gutter-width': gutterWidth,
        }}
        {...rest}
    >
        {children}
    </Row>
);

export default GridRow;
