import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import type {
    EcheanceEngagement,
    IEcheanceEngagementFormData,
    IEcheanceEngagementInvoiceFormData,
} from '../../slices/engagementSlice';
import { colors } from '../../constants/colors';
import { styles } from '../../constants/styles';
import { NumberFormatter } from '../../utils/formatters';
import type { EcheancierViewMode } from './EcheancierEngagementModal';
import {
    COL_WIDTHS,
    ECHEANCIER_MONTH_VIEW,
    ECHEANCIER_YEAR_VIEW,
    TableCol,
} from './EcheancierEngagementModal';
import Text from '../Text';
import PermissionAwareText from '../PermissionAwareText';
import type { Permission } from '../../slices/authSlice';

const computeBackgroundColor = (
    isEcheancierViewModeYear?: boolean,
    isOutOfEcheancier?: boolean,
) => {
    if (isEcheancierViewModeYear) {
        return colors.blue.B50;
    } else if (isOutOfEcheancier) {
        return colors.red.R50;
    } else {
        return colors.other.white;
    }
};

const Line = styled.div<{
    isDisabled?: boolean;
    isEcheancierViewModeYear?: boolean;
    isOutOfEcheancier?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: ${styles.borderRadiusSmall};
    padding: 0.25rem;
    margin-bottom: 0.25rem;
    background-color: ${({ isOutOfEcheancier, isEcheancierViewModeYear }) =>
        computeBackgroundColor(isEcheancierViewModeYear, isOutOfEcheancier)};

    p {
        ${({ isDisabled }) => isDisabled && `color: ${colors.neutral.N400}; font-style: italic;`};
    }
`;

const LineText = styled(Text)`
    margin: 0;
`;

const EditedPrevisionHt = styled.div`
    width: 50%;
`;

type Props = {
    index: number;
    number: IEcheanceEngagementFormData['number'];
    month: IEcheanceEngagementFormData['month'];
    initialAmountHt: EcheanceEngagement['amountHt'];
    initialPercentageHt: number;
    marketInvoiceNumber: IEcheanceEngagementInvoiceFormData['marketInvoiceNumber'];
    engagementAvancementHt: IEcheanceEngagementFormData['engagementAvancementHt'];
    suggestionHt: IEcheanceEngagementFormData['suggestionHt'];
    editMode: boolean;
    echeanceEditable: boolean;
    echeanceOutOfEngagementSchedule: boolean;
    permissionNeededToEdit: Permission;
    echeancierViewMode: EcheancierViewMode;
};

const EcheancierEngagementLine: React.FC<Props> = ({
    index,
    number,
    month,
    initialAmountHt,
    initialPercentageHt,
    marketInvoiceNumber,
    engagementAvancementHt,
    suggestionHt,
    editMode,
    echeanceEditable,
    echeanceOutOfEngagementSchedule,
    permissionNeededToEdit,
    echeancierViewMode,
}) => {
    const renderMonthOrYear = () => {
        if (echeancierViewMode === ECHEANCIER_MONTH_VIEW) {
            return format(new Date(month), 'MM/yyyy');
        } else {
            return format(new Date(month), 'yyyy');
        }
    };
    return (
        <Line
            isDisabled={!echeanceEditable}
            isEcheancierViewModeYear={echeancierViewMode === ECHEANCIER_YEAR_VIEW}
            isOutOfEcheancier={
                echeanceOutOfEngagementSchedule && echeancierViewMode === ECHEANCIER_MONTH_VIEW
            }
            data-testid="echeancierEngagementLine"
        >
            <TableCol width={COL_WIDTHS[0]} />
            <TableCol width={COL_WIDTHS[1]} justifyContent="flex-start">
                <LineText>{number}</LineText>
            </TableCol>
            <TableCol width={COL_WIDTHS[2]}>
                <LineText>{renderMonthOrYear()}</LineText>
            </TableCol>
            <TableCol width={COL_WIDTHS[3]} justifyContent="flex-end">
                <LineText isNumeral>{NumberFormatter.format(initialAmountHt)}</LineText>
            </TableCol>
            <TableCol width={COL_WIDTHS[4]} justifyContent="flex-end">
                <LineText isNumeral>{NumberFormatter.format(initialPercentageHt)}</LineText>
            </TableCol>
            <TableCol width={COL_WIDTHS[5]} justifyContent="flex-end">
                <LineText>{marketInvoiceNumber}</LineText>
            </TableCol>
            <TableCol width={COL_WIDTHS[6]} justifyContent="flex-end">
                <LineText data-testid="echeanceAvancementHt" isNumeral>
                    {NumberFormatter.format(engagementAvancementHt)}
                </LineText>
            </TableCol>
            <TableCol width={COL_WIDTHS[7]} justifyContent="flex-end">
                {editMode && (
                    <LineText data-testid="suggestionHt" isNumeral>
                        {NumberFormatter.format(suggestionHt)}
                    </LineText>
                )}
            </TableCol>
            <TableCol width={COL_WIDTHS[8]} justifyContent="flex-end">
                {editMode && (
                    <EditedPrevisionHt>
                        <PermissionAwareText
                            marginTop="0"
                            marginBottom="0"
                            inputHeight="1.5rem"
                            textAlign="right"
                            editable={echeanceEditable}
                            permissionsRequired={[permissionNeededToEdit]}
                            name={`echeances.${index}.amountHt`}
                            type="text"
                            numberType="decimal"
                            data-testid="echeanceAmountHt"
                            errorTestId="errorEcheanceAmountHt"
                            whiteBackground
                        />
                    </EditedPrevisionHt>
                )}
            </TableCol>
            <TableCol width={COL_WIDTHS[9]} />
        </Line>
    );
};

export default EcheancierEngagementLine;
