import type { PropsWithChildren } from 'react';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Tooltip, Position } from '@blueprintjs/core';

import { colors } from '../constants/colors';
import type { TextProps } from './Text';
import Text from './Text';

type GlobalStyleProps = {
    isOneLine?: boolean;
    width?: string;
    centeredContent?: boolean;
    fullWidth?: boolean;
};

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    .tooltip {
        span.bp4-popover-target {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 18.75rem;
        }
        .bp4-popover {
            box-shadow: 0;
        }
        .bp4-tooltip {
            background: ${colors.neutral.N600};
            color: ${colors.other.white};
            overflow: hidden;
        }
        .bp4-popover-arrow {
            display: none;
        }
        .bp4-popover-content {
            box-sizing: content-box;
            padding: 0;
            margin: 0;
            position: absolute;
            font-family: 'Rubik';
            font-weight: 400;
            font-size: 0.6875rem;
            line-height: 0.875rem;
            white-space: ${(props) => (props.isOneLine ? 'nowrap' : 'normal')};
            text-overflow: ellipsis;
            overflow: hidden;
        }
        ${({ centeredContent }) =>
            centeredContent
                ? ` display: flex;
                    align-items: center;
                    justify-content: center;
                    `
                : ''}
        ${({ fullWidth }) => (fullWidth ? `flex: 1` : '')}

    }
    .bp4-transition-container {
        white-space: pre-line;
        width: ${({ width }) => width ?? 'auto'};
        max-width: 50%;
    }
`;

type Props = {
    content: string;
    textStyle?: TextProps | null;
    shouldRenderGlobalStyle?: boolean;
};

const AEOTooltip: React.FC<PropsWithChildren<Props & GlobalStyleProps & TextProps>> = ({
    content,
    isOneLine,
    width,
    textStyle,
    children,
    shouldRenderGlobalStyle = true,
    centeredContent,
    fullWidth = false,
}) => (
    <>
        {shouldRenderGlobalStyle && (
            <GlobalStyle
                isOneLine={isOneLine}
                width={width}
                centeredContent={centeredContent}
                fullWidth={fullWidth}
            />
        )}
        <Tooltip
            content={content}
            position={Position.TOP}
            hoverOpenDelay={1000}
            hoverCloseDelay={200}
            className="tooltip"
        >
            {textStyle ? <Text {...textStyle}>{children}</Text> : children}
        </Tooltip>
    </>
);

export default AEOTooltip;
