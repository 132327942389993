import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import type { QueryParams } from '../../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../../slices/common';
import {
    getUsersByOperation,
    selectTotalUsersAffectedToCurrentOperation,
    selectUsersAffectedToCurrentOperationByPage,
    selectIsLoading,
} from '../../slices/userSlice';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import MainLayout from '../../components/MainLayout';
import GridRow from '../../components/GridRow';
import GridCol from '../../components/GridCol';
import SearchField from '../../components/SearchField';
import Loader from '../../components/Loader';
import OperationUsersList from '../../components/OperationUsersList';
import EmptyState from '../../components/EmptyState';
import Button from '../../components/Button';
import AffectUserToOperationModal from '../../components/AffectUserToOperationModal';
import Pagination from '../../components/Pagination';
import { usePageNumber } from '../../hooks/usePageNumber';
import AffectUserIcon from '../../assets/images/affect_user_icon.svg';
import {
    getOrganizationsByOperation,
    selectTotalOrganizations,
} from '../../slices/organizationSlice';
import { useAppDispatch } from '../../store';

const AffectUserIconStyle = styled.img`
    width: 1.125rem;
`;

type Props = {
    headerProps: HeaderProps;
};

const UsersByOperation: React.FunctionComponent<Props> = ({ headerProps }) => {
    const { operationId } = useParams<{ operationId: string }>();
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const pageNumber = usePageNumber();
    const hasRightToAffectUsers = usePermissionsCheck([{ code: 'USERS_EDIT', operationId }]);

    const defaultQueryParams = {
        operationId: Number(operationId),
        page: pageNumber,
        orderBy: '[organization.denomination, profile.firstName, profile.lastName]',
        order: '[asc, asc, asc]',
    };
    const [queryParams, setQueryParams] = useState<QueryParams>(defaultQueryParams);
    const [isAffectUserToOperationModalOpen, setIsAffectUserToOperationModalOpen] = useState(false);

    const isLoading = useSelector(selectIsLoading);
    const users = useSelector(selectUsersAffectedToCurrentOperationByPage(pageNumber));
    const totalUsers = useSelector(selectTotalUsersAffectedToCurrentOperation);
    const totalOrganizations = useSelector(selectTotalOrganizations);

    useEffect(() => {
        void dispatch(getUsersByOperation(queryParams));
    }, [dispatch, queryParams]);

    useEffect(() => {
        void dispatch(
            getOrganizationsByOperation({
                page: pageNumber,
                operationId: Number(operationId),
            }),
        );
    }, [dispatch, operationId, pageNumber]);

    const handleSearch = (value: string) => {
        if (value === '') {
            setQueryParams(defaultQueryParams);
        } else {
            setQueryParams({
                ...defaultQueryParams,
                query: value,
                match: 'any',
            });
        }
    };

    return (
        <MainLayout
            header={
                <Header
                    {...headerProps}
                    tabs={headerProps.tabs?.map((tab, index) => {
                        if (index === 0) {
                            return { ...tab, text: `${tab.text} (${totalOrganizations})` };
                        } else if (index === 1) {
                            return { ...tab, text: `${tab.text} (${totalUsers})` };
                        }
                        return tab;
                    })}
                    buttons={
                        hasRightToAffectUsers
                            ? [
                                  {
                                      'data-testid': 'affectUserButton',
                                      text: t('operation.affectUser'),
                                      aspect: 'primary',
                                      bluePrintJsIconName: (
                                          <AffectUserIconStyle
                                              src={AffectUserIcon}
                                              alt={t('usersByOperation.affectUserAlt')}
                                          />
                                      ),
                                      onClick: () => setIsAffectUserToOperationModalOpen(true),
                                  },
                              ]
                            : []
                    }
                    showBackButton
                />
            }
            footer={
                users.length > 0 ? (
                    <Pagination
                        data-testid="pagination"
                        initialPage={queryParams.page}
                        currentPage={queryParams.page}
                        pageNeighbours={2}
                        nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                        nbItemsTotal={totalUsers}
                        onPageNumberClicked={(page: number) =>
                            setQueryParams({
                                ...queryParams,
                                page,
                            })
                        }
                        pageUrl="users"
                    />
                ) : null
            }
            smallContentSidePadding
        >
            <GridRow style={{ marginBottom: '1rem' }}>
                <GridCol smallScreen={10} defaultScreen={4}>
                    <SearchField
                        name="organizationResearch"
                        data-testid="usersSearch"
                        onSearch={handleSearch}
                    />
                </GridCol>
            </GridRow>
            <GridRow>
                <GridCol>
                    {isLoading ? <Loader /> : null}
                    {!isLoading && users.length > 0 && (
                        <OperationUsersList operationId={Number(operationId)} users={users} />
                    )}
                    {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy */}
                    {!isLoading && users && users.length === 0 && (
                        <EmptyState
                            titleTranslationKey="operation.noUsers"
                            imageName="User"
                            button={
                                hasRightToAffectUsers ? (
                                    <Button
                                        onClick={() => setIsAffectUserToOperationModalOpen(true)}
                                        text={t('operation.affectUser')}
                                        aspect="secondary"
                                        size="medium"
                                    />
                                ) : null
                            }
                        />
                    )}
                </GridCol>
            </GridRow>
            {isAffectUserToOperationModalOpen && (
                <AffectUserToOperationModal
                    operationId={Number(operationId)}
                    onModalClosed={() => setIsAffectUserToOperationModalOpen(false)}
                    stateForNavigation={{
                        breadcrumbs: headerProps.breadcrumbs,
                        goBackTo: `${location.pathname}${location.search}`,
                    }}
                    userRoutePrefix={`/operations/${operationId}`}
                />
            )}
        </MainLayout>
    );
};

export default UsersByOperation;
