import { createGlobalStyle } from 'styled-components';

import { colors } from '../../constants/colors';
import { styles } from '../../constants/styles';

type StyleProps = {
    maxHeight?: string;
};

export const SelectStyle = createGlobalStyle<StyleProps>`
    .bp4-popover.bp4-select-popover {
        max-width: 100%;

        .bp4-popover-content {
            padding: 0.5rem;
            box-shadow: 0 0.0625rem 0.25rem ${colors.neutral.N75};
            border: 0.04375rem solid ${colors.neutral.N100};
            border-radius: 0.25rem;
            .bp4-input-group > .bp4-input-left-container {
                margin: 0.2rem 0.1rem;
                > svg {
                    color: ${colors.neutral.N200};
                }
            }
        }
        .bp4-popover-arrow {
            display: none;
        };
        .bp4-popover {
            box-shadow: 0 0.0625rem 0.25rem ${colors.neutral.N75};
            border: 0.04375rem solid ${colors.neutral.N100};
            background-color: ${colors.other.white};

            .bp4-input-group > input {
                // Padding for 'Research' placeholder in search bar in dropdown, doesn't work without important flag
                padding-left: 30px !important;
                margin-bottom: 1rem;
            }
        };
        .bp4-popover-target {
            width: 100%;
        };

        .bp4-menu-item {
            padding: 0.25rem 1rem 0.25rem 1rem;
            border-radius: 0;
            :hover {
                background-color: ${colors.blue.B50};
                color: ${colors.blue.B400};
                line-height: 1.25rem;
            };
        };
        .bp4-menu {
            padding: 0;
        }
    }

    .bp4-portal {
        .bp4-menu {
            ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}; overflow: auto;`}
        };
    }

    .dropdown, .dropdown-error {
        margin-bottom: 1rem;
        .bp4-minimal:focus {
            box-shadow:none;
            border: 0.0938rem solid ${colors.green.G400};
            outline: none;
        };
        .bp4-input-group {
            padding-right: 2rem;
            margin-bottom: 1rem;
        };
        .bp4-input-left-container {
            padding-right: 5rem;
            :first-child {
                margin: 1.5rem 0 0 1.25rem;
                color: ${colors.neutral.N200};
            }
        };
        .bp4-input-action {
            margin: 1rem 1rem 0 0;
            .bp4-button .bp4-icon {
                color: ${colors.neutral.N200};
            };
        };
        .bp4-button {
            width: 100%;
            border-radius: ${styles.borderRadiusSmall};
            border: 0.0938rem solid ${colors.neutral.N100};
            display: flex;
            justify-content: space-between;
            padding: 0.05rem 0.5rem 0.05rem 1.25rem;
        }
        .bp4-button-text {
            padding: 0.3rem 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > svg {
                margin: 0.2rem 0 0.2rem 1rem;
            };
        };
        .bp4-button {
            width: 100%;
            border-radius: ${styles.borderRadiusSmall};
            display: flex;
            justify-content: space-between;
        }
    }
    .dropdown {
        .bp4-button {
            border: 0.0938rem solid ${colors.neutral.N100};
        }
    }
    .dropdown-error {
        .bp4-button {
            border: 0.0938rem solid ${colors.red.R300};
            box-shadow: 0px 0px 0px 0.219rem ${colors.red.R75};
        }
    }
    span.bp4-popover-target {
        display: block;
    };
`;
