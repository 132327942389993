// Data that is returned from the API and then saved is all in uppercase. For design purposes we need to capitalize it.
export const capitalize = (string: string | unknown) => {
    if (typeof string === 'string') {
        return string
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join(' ')
            .split('-')
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join('-');
    } else {
        return string as string;
    }
};
