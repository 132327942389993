import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';

type ContainerProps = {
    textColor?: string;
    textSize?: string;
    background?: string;
    fontWeight?: number | string;
};

const Container = styled.div<ContainerProps>`
    @media print {
        margin-right: 0.25rem !important;
        border: 1px solid black;
        padding: 0 0.15rem;
        margin-left: 2rem !important;
        white-space: nowrap;
    }
    color: ${(props) => props.textColor ?? colors.other.white};
    background: ${(props) => props.background ?? colors.green.G400};
    font-family: Rubik;
    font-weight: ${(props) => props.fontWeight ?? 500};
    display: inline-block;
    font-size: ${(props) => (props.textSize ? props.textSize : '0.75rem')};
    line-height: 1rem;
    padding: 0 0.375rem;
    border-radius: 0.625rem;
    > * {
        margin: auto;
        padding-top: 0.0625rem;
    }
`;

type Props = {
    text: string | number;
    textSize?: string;
    textColor: string;
    background: string;
    fontWeight?: number | string;
    style?: CSSProperties;
    isNumeral?: boolean;
    'data-testid'?: string;
    onClick?: () => void;
};

export type BadgeProps = Props;

const Badge: React.FunctionComponent<Props> = ({
    text,
    textColor,
    background,
    textSize,
    fontWeight,
    style,
    isNumeral,
    onClick,
    ...props
}) => (
    <Container
        textColor={textColor}
        background={background}
        style={style}
        textSize={textSize}
        fontWeight={fontWeight}
        data-testid={props['data-testid']}
        onClick={onClick ?? undefined}
    >
        <p style={isNumeral ? { fontFamily: styles.numeralsFont } : {}} data-testid="badgeText">
            {text}
        </p>
    </Container>
);
export default Badge;
