import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { IRole, IRoleFormData } from './roleSlice';
import type { QueryParams, PaginatedPayload, ServerError } from './common';
import { errorHandler } from './common';

import type { Site } from './siteSlice';
import type { IOrganization } from './organizationSlice';
import type { Operation } from './operationSlice';

export interface IUserProfile {
    id?: number;
    firstName: string;
    lastName: string;
    title: string;
    mobilePhoneNumber?: string;
    phoneNumber?: string;
    occupation?: string;
}

export type UserRole = IRole & {
    userRoleId: number;
    userRoleOrganizationId?: IOrganization['id'] | null;
    userRoleOperationId?: Operation['id'] | null;
};

export type IUserRoleFormData = IRoleFormData & {
    userRoleId?: UserRole['userRoleId'];
    userRoleOrganizationId?: UserRole['userRoleOrganizationId'];
    userRoleOperationId?: UserRole['userRoleOperationId'];
};

export type User = {
    id: number;
    email: string;
    profile: IUserProfile;
    isActive: boolean;
    lastConnectedAt?: string;
    lastActivationEmailSentAt?: string;
    deletedAt: string | null;
    roles?: UserRole[];
    siteId?: Site['id'];
    site?: Site;
    createdAt: string;
    organizationId?: IOrganization['id'];
    cantBeDeleted: boolean;
    organization?: IOrganization;
};

export interface IUserFormData {
    id?: number;
    email: string;
    profile: IUserProfile;
    roles: IUserRoleFormData[];
    siteId?: Site['id'];
    site?: Site;
    organizationId?: IOrganization['id'];
    operationId?: number;
    isEnabled?: boolean;
}

type UsersPayload = PaginatedPayload<User>;

export type OperationAffectedToUser = {
    id: Operation['id'];
    operation: {
        id: Operation['id'];
        internalNumber: Operation['internalNumber'];
        label: Operation['label'];
        postalCode: Operation['postalCode'];
        city: Operation['city'];
    };
    projectOwnerOrganization: { denomination: IOrganization['denomination'] };
    roles: Array<{ name: IRole['name']; label: IRole['label'] }>;
};
interface IUserState {
    totalUsers: number;
    usersById: Record<number, User>;
    usersByPage: Record<number, Array<User['id']> | null>;
    usersByOrganizationId: Record<string, Record<number, Array<User['id']> | null>>;
    affectUserToOperationFulfilled: boolean;
    usersAffectedToCurrentOperationById: Record<number, User>;
    usersAffectedToCurrentOperationByPage: Record<number, Array<User['id']> | null>;
    totalUsersAffectedToCurrentOperation: number;
    usersFromOrganizationsAffectedToCurrentOperationById: Record<number, User>;
    usersFromOrganizationsAffectedToCurrentOperationByPage: Record<
        number,
        Array<User['id']> | null
    >;
    totalUsersFromOrganizationsAffectedToCurrentOperation: number;
    operationsAffectedByUserId: Record<number, OperationAffectedToUser[] | null>;
    usersBySites: Record<number, Array<User['id']>>;
    usersByBudgets: Record<number, Array<User['id']>>;
    userIdAdded: User['id'] | null;
    loading: boolean;
    isUpdateManyUsersFulfilled: boolean;
    isUpdateUserFulfilled: boolean;
    queryString: string;
    queryOrganizationId?: IOrganization['id'];
    error: string | null;
}

export const initialState: IUserState = {
    totalUsers: 0,
    usersById: {},
    usersByPage: {},
    usersByOrganizationId: {},
    affectUserToOperationFulfilled: false,
    usersAffectedToCurrentOperationById: {},
    usersAffectedToCurrentOperationByPage: [],
    totalUsersAffectedToCurrentOperation: 0,
    usersFromOrganizationsAffectedToCurrentOperationById: {},
    usersFromOrganizationsAffectedToCurrentOperationByPage: [],
    totalUsersFromOrganizationsAffectedToCurrentOperation: 0,
    operationsAffectedByUserId: {},
    usersBySites: {},
    usersByBudgets: {},
    loading: false,
    isUpdateManyUsersFulfilled: false,
    isUpdateUserFulfilled: false,
    queryString: '',
    queryOrganizationId: undefined,
    userIdAdded: null,
    error: null,
};

export const getUsers = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    UsersPayload & { page: number; queryString: string; organizationId: string | undefined },
    // First argument to the payload creator
    QueryParams,
    {
        rejectValue: ServerError;
    }
>(
    'USER/GETALL',
    async (
        { page = 1, query, match = 'any', fields, orderBy, order, pageSize = 12, organizationId },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `&match=${match}`;
            if (query) {
                queryString += `&query=${encodeURIComponent(query)}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            if (order) {
                queryString += `&order=${order}`;
            }
            const path = organizationId
                ? `/organizations/${organizationId}/users?page=${
                      page - 1
                  }&pageSize=${pageSize}${queryString}`
                : `/users?page=${page - 1}&pageSize=${pageSize}${queryString}`;
            const response = await axios.get<UsersPayload>(path);

            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { ...response.data, page, queryString, organizationId };
        } catch (err: unknown) {
            return rejectWithValue({
                message: 'Something went wrong',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const getUser = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    User,
    // First argument to the payload creator
    { id: User['id']; organizationId?: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('USER/GET', async ({ id, organizationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: User }>(
            `${organizationId ? `/organizations/${organizationId}` : ''}/users/${id}`,
        );
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const createUser = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    User,
    // First argument to the payload creator
    IUserFormData,
    {
        rejectValue: ServerError;
    }
>('USER/POST', async (userValues, { rejectWithValue }) => {
    try {
        const response = await axios.post<{ data?: User }>(
            `${
                userValues.organizationId ? `/organizations/${userValues.organizationId}` : ''
            }/users`,
            userValues,
        );
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when set new user',
            translationKey: 'errors.somethingWentWrong',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }

        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updateUser = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    User,
    // First argument to the payload creator
    {
        user: IUserFormData;
        operationId?: Operation['id'];
    },
    {
        rejectValue: ServerError;
    }
>('USER/PUT', async ({ user, operationId }, { rejectWithValue }) => {
    try {
        let path = `/users/${user.id}`;
        if (operationId) {
            path = `/operations/${operationId}${path}`;
        } else if (user.organizationId) {
            path = `/organizations/${user.organizationId}${path}`;
        }
        const response = await axios.put<{ data?: User }>(path, user);
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when update user',
            translationKey: 'errors.somethingWentWrong',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }

        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updateManyUsers = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    User[],
    // First argument to the payload creator
    { users: IUserFormData[]; operationId?: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('USER/COLLECTION/PUT', async ({ users, operationId }, { rejectWithValue }) => {
    try {
        let path = '/users/collection';
        if (operationId) {
            path = `/operations/${operationId}${path}`;
        }
        const response = await axios.put<{ data?: User[] }>(path, users);
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when update user',
            translationKey: 'errors.somethingWentWrong',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }

        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const deleteUser = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    number,
    // First argument to the payload creator
    { id: number; organizationId?: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('USER/DELETE', async ({ id, organizationId }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(
            `${organizationId ? `/organizations/${organizationId}` : ''}/users/${id}`,
        );
        if (response.status === 204) {
            return id;
        }
        return rejectWithValue({
            message: 'Problem when delete user',
            translationKey: 'errors.somethingWentWrong',
        });
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getOperationsOnWhichUserIsAffected = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    OperationAffectedToUser[],
    // First argument to the payload creator
    { id: User['id']; organizationId?: IOrganization['id']; queryParams: QueryParams },
    {
        rejectValue: ServerError;
    }
>(
    'USER/GET_OPERATIONS_AFFECTED',
    async (
        {
            id,
            organizationId,
            queryParams: {
                operationId,
                match = 'any',
                query,
                fields,
                orderBy = 'internal_number',
                order = 'desc',
            },
        },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `?match=${match}`;

            if (operationId) {
                queryString += `&ids=${JSON.stringify([operationId])}`;
            }
            if (query) {
                queryString += `&query=${query}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            if (order) {
                queryString += `&order=${order}`;
            }

            const response = await axios.get<{ data?: OperationAffectedToUser[] }>(
                `${
                    organizationId ? `/organizations/${organizationId}` : ''
                }/users/${id}/operations-affected${queryString}`,
            );
            if (response.data.data) {
                return response.data.data;
            }
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey || error.response?.status === 499) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: 'Something went wrong',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const getUsersByOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    UsersPayload & { page: number; queryString: string },
    // First argument to the payload creator
    QueryParams,
    {
        rejectValue: ServerError;
    }
>(
    'USER/GET_USERS_AFFECTED_TO_OPERATION',
    async (
        { page = 1, query, match = 'any', fields, orderBy, order, pageSize = 12, operationId },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `&match=${match}`;
            if (query) {
                queryString += `&query=${encodeURIComponent(query)}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            if (order) {
                queryString += `&order=${order}`;
            }

            const response = await axios.get<UsersPayload>(
                `/operations/${operationId}/users?page=${
                    page - 1
                }&pageSize=${pageSize}${queryString}`,
            );
            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { ...response.data, page, queryString };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey || error.response?.status === 499) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: 'Something went wrong',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const getAllowedUsersNotAlreadyAffectedToOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    UsersPayload & { page: number; queryString: string },
    // First argument to the payload creator
    QueryParams,
    {
        rejectValue: ServerError;
    }
>(
    'USER/GET_ALLOWED_USERS_NOT_ALREADY_AFFECTED_TO_OPERATION',
    async (
        { page = 1, query, match = 'any', fields, orderBy, order, operationId, pageSize = 12 },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `&match=${match}`;
            if (query) {
                queryString += `&query=${encodeURIComponent(query)}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            if (order) {
                queryString += `&order=${order}`;
            }

            const response = await axios.get<UsersPayload>(
                `/operations/${operationId}/users?getDisaffectedUsersFromOrganizations=true&page=${
                    page - 1
                }&pageSize=${pageSize}${queryString}`,
            );
            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }
            return { ...response.data, page, queryString };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey || error.response?.status === 499) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: 'Something went wrong',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const updateUserOperationRoles = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    User,
    // First argument to the payload creator
    { user: IUserFormData; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('USER/UPDATE_OPERATION_ROLES', async ({ user, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: User }>(
            `/operations/${operationId}/users/${user.id}`,
            user,
        );
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'No data returned',
            translationKey: 'errors.noDataResponse',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const checkEmailValidity = async (email: string) => {
    const response = await axios.get(`/users/email/${email}`);
    return response;
};

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getUsers.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUsers.fulfilled, (state, { payload }) => {
            if (
                payload.queryString !== state.queryString &&
                payload.organizationId !== state.queryOrganizationId
            ) {
                state.queryString = payload.queryString;
                state.queryOrganizationId = payload.organizationId;
                state.usersById = {};
            }
            if (payload.data && payload.page) {
                const byPage: Array<User['id']> = [];
                payload.data.forEach((item: User) => {
                    state.usersById[item.id] = item;
                    byPage.push(item.id);
                });
                if (payload.organizationId) {
                    state.usersByOrganizationId[payload.organizationId] = {};
                    state.usersByOrganizationId[payload.organizationId][payload.page] = byPage;
                } else {
                    state.usersByPage[payload.page] = byPage;
                }
            }
            state.totalUsers = payload.total;
            state.loading = false;
        });
        builder.addCase(getUsers.rejected, errorHandler());
        // Get one
        builder.addCase(getUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUser.fulfilled, (state, { payload }) => {
            state.usersById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getUser.rejected, errorHandler());
        // Create
        builder.addCase(createUser.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.userIdAdded = null;
        });
        builder.addCase(createUser.fulfilled, (state, { payload }) => {
            state.usersById[payload.id] = payload;
            state.userIdAdded = payload.id;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(createUser.rejected, errorHandler());
        // Update one user
        builder.addCase(updateUser.pending, (state) => {
            state.loading = true;
            state.isUpdateUserFulfilled = false;
            state.error = null;
            state.userIdAdded = null;
        });
        builder.addCase(updateUser.fulfilled, (state, { payload }) => {
            state.usersById[payload.id] = payload;
            state.loading = false;
            state.isUpdateUserFulfilled = true;
            state.error = null;
        });
        builder.addCase(updateUser.rejected, errorHandler());
        // Update many user
        builder.addCase(updateManyUsers.pending, (state) => {
            state.loading = true;
            state.isUpdateManyUsersFulfilled = false;
            state.error = null;
        });
        builder.addCase(updateManyUsers.fulfilled, (state, { payload }) => {
            payload.forEach((user: User) => {
                state.usersById[user.id] = user;
            });
            state.loading = false;
            state.isUpdateManyUsersFulfilled = true;
            state.error = null;
        });
        builder.addCase(updateManyUsers.rejected, errorHandler());
        // Delete
        builder.addCase(deleteUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
            Object.entries(state.usersByPage).forEach(([pageId, ids]) => {
                if (ids?.includes(payload)) {
                    state.usersByPage[Number(pageId)]?.splice(ids.indexOf(payload), 1);
                }
            });
            Object.entries(state.usersByOrganizationId).forEach(([organizationId, byPage]) => {
                Object.entries(byPage).forEach(([pageId, ids]) => {
                    if (
                        ids?.includes(payload) &&
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
                        state.usersByOrganizationId[organizationId] &&
                        state.usersByOrganizationId[organizationId][Number(pageId)]
                    ) {
                        state.usersByOrganizationId[organizationId][Number(pageId)]?.splice(
                            ids.indexOf(payload),
                            1,
                        );
                    }
                });
            });
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.usersById[payload];
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteUser.rejected, errorHandler());
        // Get operations on which user is affected
        builder.addCase(getOperationsOnWhichUserIsAffected.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(
            getOperationsOnWhichUserIsAffected.fulfilled,
            (state, { meta, payload }) => {
                state.operationsAffectedByUserId[meta.arg.id] = payload;
                state.loading = false;
                state.error = null;
            },
        );
        builder.addCase(getOperationsOnWhichUserIsAffected.rejected, errorHandler());
        builder.addCase(getUsersByOperation.pending, (state) => {
            state.affectUserToOperationFulfilled = false;
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUsersByOperation.fulfilled, (state, { payload }) => {
            if (payload.queryString !== state.queryString) {
                state.queryString = payload.queryString;
                state.usersAffectedToCurrentOperationById = {};
            }
            if (payload.data && payload.page) {
                const byPage: Array<User['id']> = [];
                payload.data.forEach((item: User) => {
                    state.usersAffectedToCurrentOperationById[item.id] = item;
                    byPage.push(item.id);
                });

                state.usersAffectedToCurrentOperationByPage[payload.page] = byPage;
            }
            state.totalUsersAffectedToCurrentOperation = payload.total;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getUsersByOperation.rejected, errorHandler());
        builder.addCase(getAllowedUsersNotAlreadyAffectedToOperation.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(
            getAllowedUsersNotAlreadyAffectedToOperation.fulfilled,
            (state, { payload }) => {
                if (payload.queryString !== state.queryString) {
                    state.queryString = payload.queryString;
                    state.usersFromOrganizationsAffectedToCurrentOperationById = {};
                }
                if (payload.data && payload.page) {
                    const byPage: Array<User['id']> = [];
                    payload.data.forEach((item: User) => {
                        state.usersFromOrganizationsAffectedToCurrentOperationById[item.id] = item;
                        byPage.push(item.id);
                    });

                    state.usersFromOrganizationsAffectedToCurrentOperationByPage[payload.page] =
                        byPage;
                }
                state.totalUsersFromOrganizationsAffectedToCurrentOperation = payload.total;
                state.loading = false;
                state.error = null;
            },
        );
        builder.addCase(getAllowedUsersNotAlreadyAffectedToOperation.rejected, errorHandler());
        builder.addCase(updateUserOperationRoles.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateUserOperationRoles.fulfilled, (state) => {
            state.affectUserToOperationFulfilled = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateUserOperationRoles.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.user.error;
export const selectUsers = (state: RootState) => Object.values(state.user.usersById);
export const selectUser = (id: User['id'] | null) =>
    function (state: RootState) {
        return id ? state.user.usersById[id] : null;
    };
export const selectUsersByPage = (page: number) => (state: RootState) => {
    if (state.user.usersByPage[page]) {
        return state.user.usersByPage[page]?.map((id: number) => state.user.usersById[id]) ?? [];
    }
    return [];
};
export const selectUserIdAdded = (state: RootState) => state.user.userIdAdded;
export const selectUsersByOrganizationIdAndPage =
    (organizationId: string | undefined, page: number) => (state: RootState) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
        if (organizationId && state.user.usersByOrganizationId[organizationId]) {
            return (
                state.user.usersByOrganizationId[organizationId][page]?.map(
                    (id: number) => state.user.usersById[id],
                ) ?? []
            );
        }
        return [];
    };
export const selectUsersAffectedToCurrentOperationByPage = (page: number) => (state: RootState) => {
    if (state.user.usersAffectedToCurrentOperationByPage[page]) {
        return (
            state.user.usersAffectedToCurrentOperationByPage[page]?.map(
                (id: number) => state.user.usersAffectedToCurrentOperationById[id],
            ) ?? []
        );
    }
    return [];
};

export const selectTotalUsersAffectedToCurrentOperation = (state: RootState) =>
    state.user.totalUsersAffectedToCurrentOperation;
export const selectUsersFromOrganizationsAffectedToCurrentOperationById = (state: RootState) =>
    state.user.usersFromOrganizationsAffectedToCurrentOperationById;
export const selectUsersFromOrganizationsAffectedToCurrentOperationByPage =
    (page: number) => (state: RootState) => {
        if (state.user.usersFromOrganizationsAffectedToCurrentOperationByPage[page]) {
            return (
                state.user.usersFromOrganizationsAffectedToCurrentOperationByPage[page]?.map(
                    (id: number) =>
                        state.user.usersFromOrganizationsAffectedToCurrentOperationById[id],
                ) ?? []
            );
        }
        return [];
    };
export const selectTotalUsersFromOrganizationsAffectedToCurrentOperation = (state: RootState) =>
    state.user.totalUsersFromOrganizationsAffectedToCurrentOperation;
export const selectAffectUserToOperationFulfilled = (state: RootState) =>
    state.user.affectUserToOperationFulfilled;
export const selectIsLoading = (state: RootState) => state.user.loading;
export const selectTotalUsers = (state: RootState) => state.user.totalUsers;
export const selectUsersBySiteId = (siteId: number | undefined) => (state: RootState) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
    if (siteId && state.user.usersBySites[siteId]) {
        return state.user.usersBySites[siteId].map((id: number) => state.user.usersById[id]);
    }
    return [];
};
export const selectUserFullName =
    (id: User['id'] | undefined) =>
    (state: RootState): string | null => {
        let name = null;
        if (id) {
            const user = selectUser(id)(state);
            if (user) {
                name = `${user.profile.firstName} ${user.profile.lastName}`;
            }
        }
        return name;
    };
export const selectOperationsAffectedByUserId = (id: User['id'] | null) =>
    function (state: RootState) {
        return id ? state.user.operationsAffectedByUserId[id] : null;
    };
export const selectIsUpdateManyUsersFulfilled = (state: RootState) =>
    state.user.isUpdateManyUsersFulfilled;
export const selectIsUpdateUserFulfilled = (state: RootState) => state.user.isUpdateUserFulfilled;

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
