import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import type { Operation } from '../slices/operationSlice';
import { readFileAsync } from '../utils/readFile';
import Modal from './Modal';
import RollingLoader from '../assets/images/loaders/ring-loader.gif';
import Text from './Text';
import type { ImportPayload, ImportResultType } from '../slices/common';
import ImportMarketsConfirmationModal from './ImportMarketsConfirmationModal';
import { showFlag } from './Flag';

const FileInput = styled.input`
    display: none;
`;

const Spinner = styled.img`
    display: inline-block;
    position: relative;
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.5rem;
`;

type Props = {
    operationId: Operation['id'];
    fileInputRef: React.RefObject<HTMLInputElement>;
    importFilename: ImportResultType['filename'] | null;
    importStatus: ImportResultType['status'] | undefined;
    importResult: ImportResultType | null;
    importServerError: string | null;
    onImportConfirmation?: () => void;
    onCloseImportConfirmationModal?: () => void;
    importItems: (dataToImport: ImportPayload) => void;
    importResultModalContent: JSX.Element;
    onCloseImportResultsModal: () => void;
};

const ImportEdiflex: React.FC<Props> = ({
    operationId,
    fileInputRef,
    importFilename,
    importStatus,
    importResult,
    importServerError,
    onImportConfirmation,
    onCloseImportConfirmationModal,
    importItems,
    importResultModalContent,
    onCloseImportResultsModal,
}) => {
    const { t } = useTranslation();

    const [isImporting, setIsImporting] = useState<boolean>(false);
    const [isImportResultModalOpen, setIsImportResultModalOpen] = useState<boolean>(false);

    const importFileMaxSize = process.env.REACT_APP_IMPORT_FILE_MAX_SIZE
        ? Number(process.env.REACT_APP_IMPORT_FILE_MAX_SIZE)
        : 10485760;

    useEffect(() => {
        if (isImporting) {
            if (
                importServerError ||
                (importResult && importResult.status !== 'confirmation_needed')
            ) {
                setIsImportResultModalOpen(true);
                setIsImporting(false);
                localStorage.removeItem('fileData');
            }
        }
    }, [importResult, importServerError, isImporting]);

    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const getFileExtension = (filename: string) => filename.split('.').pop();

    const checkIfFileIsAnExcelFile = (filename: string) => {
        const fileExtension = getFileExtension(filename);
        const isFileAnExcelFile = Boolean(fileExtension === 'xls' || fileExtension === 'xlsx');
        return isFileAnExcelFile;
    };

    const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const importedFileIsAnExcelFile = checkIfFileIsAnExcelFile(file.name);
            if (!importedFileIsAnExcelFile) {
                showFlag('error', t('importEdiflex.fileMustBeExcel'), '');
            } else if (file.size > importFileMaxSize) {
                showFlag('error', t('importEdiflex.fileTooBig'), '');
            } else {
                try {
                    const arrayBuffer = await readFileAsync(file);
                    if (arrayBuffer) {
                        const base64 = (arrayBuffer as string).split(',')[1];
                        const fileData = {
                            base64,
                            filename: file.name,
                            mimetype: file.type,
                        };
                        setIsImporting(true);
                        importItems({
                            fileData,
                            operationId: Number(operationId),
                        });
                        resetFileInput();
                    }
                } catch (error: unknown) {
                    resetFileInput();
                }
            }
        }
    };

    return (
        <>
            <FileInput
                ref={fileInputRef}
                data-testid="importEdiflexFile"
                type="file"
                onChange={handleFileInputChange}
            />
            <Modal isOpen={isImporting} size="small">
                <Spinner src={RollingLoader} alt="loading..." />
                <Text type="H300">{t('market.importingExcel')}</Text>
            </Modal>
            {onCloseImportConfirmationModal && onImportConfirmation && (
                <ImportMarketsConfirmationModal
                    isOpen={importStatus === 'confirmation_needed'}
                    operationId={operationId}
                    onCloseImportConfirmationModal={() => {
                        onCloseImportConfirmationModal();
                        setIsImporting(false);
                        setIsImportResultModalOpen(false);
                    }}
                    onImportConfirmation={onImportConfirmation}
                />
            )}
            <Modal
                title={importFilename ? `${t('ediflex.import.title')} ${importFilename}` : ''}
                titleFontSize="1.5rem"
                isOpen={isImportResultModalOpen}
                size={importStatus !== 'warning' ? 'large' : 'full'}
                onCloseButtonPressed={() => {
                    setIsImportResultModalOpen(false);
                    setIsImporting(false);
                    onCloseImportResultsModal();
                }}
                maxWidth="90%"
            >
                {importResultModalContent}
            </Modal>
        </>
    );
};
export default ImportEdiflex;
