import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Avatar from './Avatar';

import type { User } from '../slices/userSlice';
import Table from './Table';
import type { BreadcrumbsProps } from './Breadcrumbs';

import type { StatusProps } from './Status';
import Status from './Status';
import { colors } from '../constants/colors';

const statusLabelStyle: React.CSSProperties = {
    color: colors.blue.B400,
    fontSize: '0.75rem',
    fontWeight: 500,
};

type Props = {
    users: User[];
    stateForNavigation?: {
        breadcrumbs?: BreadcrumbsProps['paths'];
        // see how it works in the header back arrow button section of the README file
        goBackTo?: string;
    };
    userRoutePrefix?: string;
};

const checkIfActive = (user: User | Partial<User>) => {
    let status: StatusProps['status'] = 'inactive';
    if (!user.isActive && user.deletedAt === null) {
        status = 'pending';
    } else if (user.isActive && user.deletedAt === null) {
        status = 'active';
    }
    return (
        <Status
            status={status}
            squareStyle={{ margin: '0.0625rem 0.5rem 0' }}
            labelStyle={statusLabelStyle}
        />
    );
};

const StyledText = styled.p`
    margin: auto auto auto 0.5rem;
`;

export const getNameAndAvatar = (user: User | Partial<User>) => (
    <>
        <Avatar text={`${user.profile?.firstName} ${user.profile?.lastName}`} size="small" />
        <StyledText>
            {user.profile?.firstName} {user.profile?.lastName}
        </StyledText>
    </>
);

const UserList: React.FunctionComponent<Props> = ({
    users,
    stateForNavigation,
    userRoutePrefix = '',
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Users Table(List)
    const headers = {
        name: t('user.list.name'),
        isActive: t('user.list.isActive'),
        email: t('user.list.email'),
        occupation: t('user.list.occupation'),
        site: t('user.list.site'),
    };

    const renderCellMap = {
        name: getNameAndAvatar,
        isActive: checkIfActive,
        email: (user: User | Partial<User>) => user.email ?? '-',
        occupation: (user: User | Partial<User>) => user.profile?.occupation,
        site: (user: Partial<User>) => user.site?.label ?? '-',
    };

    return (
        <Table
            headers={headers}
            rows={users as User[]}
            columnSizes="3fr 2fr 3fr 3fr 3fr"
            renderCellMap={renderCellMap}
            onRowClick={(user: User | Partial<User>) =>
                navigate(`${userRoutePrefix}/users/${user.id}`, { state: stateForNavigation })
            }
        />
    );
};

export default UserList;
