export const styles = {
    borderRadiusXSmall: '0.25rem',
    borderRadiusSmall: '0.3125rem',
    borderRadiusMedium: '0.625rem',
    borderRadiusBig: '1.6875rem',
    gridBreakpoint: '64.1rem ',
    gutterWidth: '2rem',

    ipadVerticalWidth: {
        px: 768,
    },

    numeralsFont: 'Courier Prime',
};
