import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import format from 'date-fns/format';
import styled from 'styled-components';

import type { Budget } from '../slices/budgetSlice';

import CardList from './CardList';
import Table from './Table';
import Text from './Text';
import BudgetVersionWrapper from './BudgetVersionWrapper';

import { colors } from '../constants/colors';

import { CurrencyFormatter } from '../utils/formatters';
import type { BreadcrumbsProps } from './Breadcrumbs';
import type { ListViewType } from '../slices/common';

const StyledText = styled(Text)`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

type Props = {
    viewType: ListViewType;
    budgets: Budget[];
    stateForNavigation?: {
        breadcrumbs?: BreadcrumbsProps['paths'];
        // see how it works in the header back arrow button section of the README file
        goBackTo?: string;
    };
};

const BudgetList: React.FunctionComponent<Props> = ({ viewType, budgets, stateForNavigation }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { operationId } = useParams();
    const tableHeaders = {
        version: t('budget.list.headers.version'),
        label: t('budget.list.headers.label'),
        totalAmountTtc: t('budget.list.headers.totalAmountTtc'),
        createdByAt: t('budget.list.headers.createdByAt'),
        updatedByAt: t('budget.list.headers.updatedByAt'),
    };

    const cardHeaders = {
        label: t('budget.list.headers.label'),
        totalAmountTtc: t('budget.list.headers.totalAmountTtc'),
        createdByAt: t('budget.list.headers.createdByAt'),
        updatedByAt: t('budget.list.headers.updatedByAt'),
        approvedByAt: t('budget.list.headers.approvedByAt'),
    };

    const formatDate = (date: string) => format(new Date(date), 'dd/MM/yyyy');

    const transformAtByValue = (budgetData: Budget | Partial<Budget>, source: string) => {
        let valueAtBy = '';
        switch (source) {
            case 'created':
                if (budgetData.createdAt && budgetData.createdByUser) {
                    valueAtBy = `${budgetData.createdByUser.profile.firstName} ${
                        budgetData.createdByUser.profile.lastName
                    }
                    ${t('budget.atByIntersection')} ${formatDate(budgetData.createdAt)}`;
                }
                break;
            case 'updated':
                if (budgetData.updatedAt && budgetData.updatedByUser) {
                    valueAtBy = `${budgetData.updatedByUser.profile.firstName} ${
                        budgetData.updatedByUser.profile.lastName
                    } ${t('budget.atByIntersection')} ${formatDate(budgetData.updatedAt)}`;
                }
                break;
            case 'approved':
                if (budgetData.approvedAt && budgetData.approvedByUser) {
                    valueAtBy = `${budgetData.approvedByUser.profile.firstName} ${
                        budgetData.approvedByUser.profile.lastName
                    } ${t('budget.atByIntersection')} ${formatDate(budgetData.approvedAt)}`;
                }
                break;
            default:
                console.warn("value for source argument doesn't exist");
        }

        return valueAtBy;
    };

    const statusLabelStyle: React.CSSProperties = {
        color: colors.blue.B400,
        fontSize: '0.75rem',
        fontWeight: 500,
    };

    const renderCellMap = {
        version(budgetData: Budget | Partial<Budget>) {
            return (
                <BudgetVersionWrapper
                    budget={budgetData}
                    labelStyle={statusLabelStyle}
                    isStatusLabelDisplayed={false}
                />
            );
        },
        label: (budgetData: Budget | Partial<Budget>) =>
            budgetData.label === '' ? String(t('budget.defaultLabel')) : budgetData.label,
        totalAmountTtc: (budgetData: Budget | Partial<Budget>) =>
            String(CurrencyFormatter.format(budgetData.totalAmountTtc ?? 0)),
        createdByAt: (budgetData: Budget | Partial<Budget>) =>
            transformAtByValue(budgetData, 'created'),
        updatedByAt: (budgetData: Budget | Partial<Budget>) =>
            transformAtByValue(budgetData, 'updated'),
    };

    const mapDataToCardProps = (budgetData: Budget | Partial<Budget>) => ({
        headerTitle: budgetData.label === '' ? String(t('budget.defaultLabel')) : budgetData.label,
        isTooltipOneLine: true,
        headerTrailingComponent: <BudgetVersionWrapper budget={budgetData} fontWeight="normal" />,
        bodyData:
            budgetData.status === 'approved'
                ? {
                      [String(cardHeaders.totalAmountTtc)]: (
                          <StyledText
                              data-testid={`bodyItem${cardHeaders.totalAmountTtc}-${budgetData.totalAmountTtc}`}
                              isNumeral
                          >
                              {CurrencyFormatter.format(budgetData.totalAmountTtc ?? 0)}
                          </StyledText>
                      ),
                      [String(cardHeaders.createdByAt)]: transformAtByValue(budgetData, 'created'),
                      [String(cardHeaders.approvedByAt)]: transformAtByValue(
                          budgetData,
                          'approved',
                      ),
                  }
                : {
                      [String(cardHeaders.totalAmountTtc)]: String(
                          CurrencyFormatter.format(budgetData.totalAmountTtc ?? 0),
                      ),
                      [String(cardHeaders.createdByAt)]: transformAtByValue(budgetData, 'created'),
                      [String(cardHeaders.updatedByAt)]: transformAtByValue(budgetData, 'updated'),
                  },
    });

    const handleClick = (row: Budget | Partial<Budget>) =>
        navigate(`/operations/${operationId}/budgets/${row.id}`, { state: stateForNavigation });

    return (
        <>
            {viewType === 'card' && (
                <CardList
                    data={budgets}
                    mapDataToCardProps={mapDataToCardProps}
                    onCardClick={handleClick}
                    data-testid="budgetCardList"
                />
            )}
            {viewType === 'table' && (
                <Table
                    headers={tableHeaders}
                    rows={budgets}
                    columnSizes="2fr 2fr 3fr 3fr 3fr"
                    renderCellMap={renderCellMap}
                    onRowClick={handleClick}
                    data-testid="budgetTableList"
                />
            )}
        </>
    );
};

export default BudgetList;
