import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import findLast from 'lodash.findlast';

import { colors } from '../constants/colors';

import GridRow from './GridRow';
import GridCol from './GridCol';
import Text from './Text';
import Button from './Button';
import { showFlag } from './Flag';
import MarketsPriceHypothesis from './MarketsPriceHypothesis';

import type { Permission } from '../slices/authSlice';
import type { MarketsPriceHypothesis as MarketsPriceHypothesisEntity } from '../slices/marketsPriceHypothesisSlice';
import {
    selectIsCreating,
    selectError,
    selectAllMarketsPriceHypothesis,
    createMarketsPriceHypothesis,
} from '../slices/marketsPriceHypothesisSlice';
import { useAppDispatch } from '../store';

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    padding: 2.3125rem 3.125rem;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem 0 0 0;
    width: 100%;
`;

type Props = {
    operationId: number;
    hasRightToEdit: boolean;
    permissionNeeded: Permission[];
};

const MarketsPriceVariationsParameters: React.FC<Props> = ({
    operationId,
    hasRightToEdit,
    permissionNeeded,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const isCreating = useSelector(selectIsCreating);
    const serverError = useSelector(selectError);
    const marketsPriceHypotheses = useSelector(selectAllMarketsPriceHypothesis);

    useEffect(() => {
        if (isCreating) {
            if (serverError) {
                showFlag('error', t('errors.error'), t(String(serverError)));
            }
        }
    }, [isCreating, serverError, t]);

    return (
        <ModalContentWrapper>
            <Text
                type="H500"
                color={colors.neutral.N500}
                style={{
                    margin: '0',
                }}
            >
                {t('markets.priceVariationHypothesis')}
            </Text>
            <GridRow
                style={{
                    display: 'flex',
                }}
            >
                {marketsPriceHypotheses.map((item, index) => (
                    <GridCol key={item.id} defaultScreen={3} smallScreen={3}>
                        <MarketsPriceHypothesis
                            permissionNeeded={permissionNeeded}
                            marketsPriceHypothesis={item}
                            operationId={operationId}
                            hasRightToEdit={hasRightToEdit}
                            separator={(index + 1) % 4 !== 0}
                            increasingFactorPlaceHolder={
                                findLast(
                                    marketsPriceHypotheses,
                                    (
                                        { increasingFactor }: MarketsPriceHypothesisEntity,
                                        indexValue: number,
                                    ) => increasingFactor !== null && indexValue < index,
                                )?.increasingFactor
                            }
                        />
                    </GridCol>
                ))}
            </GridRow>
            {hasRightToEdit && marketsPriceHypotheses.length < 20 && (
                <Container>
                    <Button
                        text={t('markets.addHypotheses')}
                        aspect="secondary"
                        size="small"
                        onClick={() => {
                            void dispatch(
                                createMarketsPriceHypothesis({
                                    operationId,
                                }),
                            );
                        }}
                        isLoading={isCreating}
                        throttled
                    />
                </Container>
            )}
        </ModalContentWrapper>
    );
};

export default MarketsPriceVariationsParameters;
