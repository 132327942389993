import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

import DatePicker, { registerLocale } from 'react-datepicker'; // https://reactdatepicker.com/

// eslint-disable-next-line import/no-duplicates -- necessary
import fr from 'date-fns/locale/fr';
// eslint-disable-next-line import/no-duplicates -- necessary
import format from 'date-fns/format';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import { StyledLabel } from './PermissionAwareDisplay';
import FieldErrorMessage from './FieldErrorMessage';
import Text from './Text';

import 'react-datepicker/dist/react-datepicker.css';

// utils
import { getPastOrFutureDateByYears, parseDateString } from '../utils/date';

// if later we want to change locale for datepicker
// import { setDefaultLocale } from  "react-datepicker";
// setDefaultLocale('en'); => sets a registered locale as the default for all datepicker instances

registerLocale('fr', fr);

const Container = styled.div<{ whiteBackground: boolean; error: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    .react-datepicker__input-container > input {
        width: 100%;
        background: ${colors.other.white};
        border: ${({ whiteBackground }) =>
            whiteBackground ? `0.0938rem solid ${colors.neutral.N100}` : 'none'};
        box-shadow: none;

        ${({ error }) =>
            error &&
            `border-color: ${colors.red.R300}; box-shadow: 0px 0px 0px 0.219rem ${colors.red.R75}`};
        border-radius: ${styles.borderRadiusSmall};
        line-height: normal;

        padding: 0.625rem;
        color: ${colors.blue.B400};
        ::placeholder {
            color: ${colors.neutral.N200};
        }
        :focus {
            outline: none;
            border: 0.0938rem solid ${colors.green.G400};
        }
        :valid {
            outline: none;
        }
        :-webkit-autofill {
            -webkit-box-shadow: 0 0 0 3.125rem ${colors.other.white} inset;
            -webkit-text-fill-color: ${colors.blue.B400};
        }
        &[disabled] {
            background-color: ${colors.neutral.N100};
        }
    }
    .react-datepicker__month-container {
        width: 15rem;
    }
`;

type Props = {
    name: string;
    placeholder?: string;
    label?: string;
    whiteBackground?: boolean;
    'data-testid'?: string;
    errorTestId?: string;
    value?: string | null | undefined;
    handleChange?: (date: string) => void;
    isMonthPicker?: boolean;
    subLabel?: string;
    minAllowedDate?: Date;
    disabled?: boolean;
};

const DateInputField: React.FC<Props> = ({
    name,
    label,
    whiteBackground = true,
    errorTestId,
    handleChange,
    value,
    isMonthPicker,
    placeholder,
    subLabel,
    minAllowedDate,
    disabled,
    ...props
}) => {
    const [field, meta] = useField(name);

    const handleValueChange = (date: Date) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
        const formattedDate = date !== null ? format(date, 'yyyy-MM-dd') : '';
        field.onChange(name)(formattedDate);

        if (handleChange) {
            handleChange(formattedDate);
        }
    };

    let valueForPicker = null;
    if (value) {
        valueForPicker = parseDateString(value);
    }
    if (field.value) {
        valueForPicker = parseDateString(field.value);
    }

    let placeholderText = isMonthPicker ? 'MM/AAAA' : 'JJ/MM/AAAA';
    if (placeholder) {
        placeholderText = placeholder;
    }

    return (
        <Container whiteBackground={whiteBackground} error={(meta.error && meta.touched) || false}>
            <StyledLabel color={colors.neutral.N300} style={{ marginBottom: '0.3125rem' }}>
                {label}
            </StyledLabel>
            <DatePicker
                selected={valueForPicker}
                onChange={handleValueChange}
                locale={fr}
                dateFormat={isMonthPicker ? 'MM/yyyy' : 'dd/MM/yyyy'}
                showMonthYearPicker={isMonthPicker}
                showMonthDropdown={!isMonthPicker}
                placeholderText={placeholderText}
                minDate={minAllowedDate ?? getPastOrFutureDateByYears(-10, true)}
                maxDate={getPastOrFutureDateByYears(20, true)}
                customInput={<input data-testid={props['data-testid']} type="text" />}
                disabled={disabled}
                showYearDropdown
            />
            {subLabel && (
                <Text color={colors.neutral.N300} type="small" style={{ margin: '0.5rem 0' }}>
                    {subLabel}
                </Text>
            )}
            {meta.error && meta.touched && (
                <FieldErrorMessage data-testid={errorTestId}>{meta.error}</FieldErrorMessage>
            )}
        </Container>
    );
};

export default DateInputField;
