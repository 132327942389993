import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { styles } from '../../constants/styles';
import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import Text from '../../components/Text';
import GridRow from '../../components/GridRow';
import EmptyState from '../../components/EmptyState';
import MarketInvoiceLine from '../../components/MarketInvoiceLine';
import { colors } from '../../constants/colors';
import type { ButtonProps } from '../../components/Button';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import FormikOptionDropdown from '../../components/dropdown/FormikOptionDropdown';
import type { Option } from '../../components/SelectField';
import { showFlag } from '../../components/Flag';
import Loader from '../../components/Loader';
import { selectOperation } from '../../slices/operationSlice';
import {
    selectMarket,
    selectMarketName,
    getMarket,
    selectError as selectMarketError,
    selectMarketEntryMode,
    MARKET_MANUAL_ENTRY,
    MARKET_EDIFLEX_IMPORT,
} from '../../slices/marketSlice';
import { getEngagements, selectUsedGroupCodes } from '../../slices/engagementSlice';
import type {
    MarketInvoice,
    MarketInvoiceCreationFormData,
    MarketInvoiceGroupCodeForm,
} from '../../slices/invoiceSlice';
import {
    getMarketInvoices,
    selectMarketInvoices,
    getLastMarketInvoices,
    selectTotalMarketInvoices,
    createMarketInvoice,
    selectError as selectMarketInvoiceError,
    selectIsDeleteFulfilled,
    selectGetMarketInvoicesLoading,
    selectMarketInvoiceIdAdded,
    deleteMarketInvoice,
} from '../../slices/invoiceSlice';
import {
    getGroupCodeSequences,
    selectGroupCodeSequence,
} from '../../slices/groupCodeSequenceSlice';
import ImportInvoices from '../../components/ImportInvoices';
import { useAppDispatch } from '../../store';

type Props = {
    headerProps?: Partial<HeaderProps>;
};

type GroupCodeOption = Option<string>;

const StyledGridCol = styled.div`
    display: flex;
    align-items: center;
`;

const StyledEmptyState = styled(EmptyState)`
    margin-top: 4.125rem;
`;

const StyledHeaderText = styled(Text)`
    margin-bottom: 0;
    font-weight: 500;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
`;

// Aligned column headers and content by letting an extra padding-right equivalent to the ContextualMenuIcon width
const MarketInvoiceLinesHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin: 1rem 0 0.5rem 0;
    background-color: ${colors.neutral.N75};
    color: ${colors.neutral.N400};
    padding: 1rem calc(1rem + 1.5rem) 1rem 1rem;
    border-radius: ${styles.borderRadiusSmall};
`;

const MarketInvoices: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { operationId, marketId }: { operationId?: string; marketId?: string } = useParams();

    const [isPending, setIsPending] = useState<boolean>(false);
    const [isGroupCodeModalOpen, setIsGroupCodeModalOpen] = useState<boolean>(false);
    const [marketInvoiceIdToBeDeleted, setMarketInvoiceIdToBeDeleted] = useState<
        number | undefined
    >(undefined);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const operation = useSelector(selectOperation(Number(operationId)));
    const market = useSelector(selectMarket(Number(marketId)));
    const marketName = useSelector(selectMarketName(Number(marketId)));
    const marketEntryMode = useSelector(selectMarketEntryMode(Number(marketId)));
    const marketInvoices: MarketInvoice[] = useSelector(selectMarketInvoices);
    const isLoading = useSelector(selectGetMarketInvoicesLoading);
    const totalMarketInvoices = useSelector(selectTotalMarketInvoices);
    const marketInvoiceIdAdded = useSelector(selectMarketInvoiceIdAdded);
    const usedGroupCodes = useSelector(selectUsedGroupCodes);
    const groupCodeSequence = useSelector(selectGroupCodeSequence(operation?.groupCodeSequenceId));
    const isDeleteFulfilled = useSelector(selectIsDeleteFulfilled);

    const permissionNeededToRead = { code: 'INVOICES_READ', operationId };
    const permissionNeededToAdd = { code: 'INVOICES_ADD', operationId };
    const hasRightToReadInvoices = usePermissionsCheck([permissionNeededToRead]);
    const hasRightToAddInvoices =
        usePermissionsCheck([permissionNeededToAdd]) && marketEntryMode === MARKET_MANUAL_ENTRY;
    const hasRightToImportInvoices =
        usePermissionsCheck([permissionNeededToAdd]) && marketEntryMode === MARKET_EDIFLEX_IMPORT;

    const [isDeleteTriggered, setIsDeleteTriggered] = useState<boolean>(false);
    const marketServerError = useSelector(selectMarketError);
    const marketInvoiceServerError = useSelector(selectMarketInvoiceError);

    useEffect(() => {
        if (marketServerError === 'errors.marketMustExist') {
            navigate(`/operations/${operationId}/markets`);
        }
    }, [marketServerError, navigate, operationId]);

    useEffect(() => {
        if (isDeleteTriggered && isDeleteFulfilled) {
            setIsDeleteTriggered(false);
            showFlag('success', t('general.success'), t('invoice.successfullyDeleted'));
            setIsDeleteModalOpen(false);
            setMarketInvoiceIdToBeDeleted(undefined);
            void dispatch(
                getLastMarketInvoices({
                    operationId: Number(operationId),
                    marketId: Number(marketId),
                }),
            );
        }
    }, [isDeleteTriggered, isDeleteFulfilled, dispatch, operationId, marketId, t]);

    useEffect(() => {
        if (isPending) {
            if (marketInvoiceServerError) {
                showFlag('error', t('errors.error'), t(marketInvoiceServerError));
                setIsPending(false);
                setIsDeleteTriggered(false);
            } else if (marketServerError) {
                showFlag('error', t('errors.error'), t(marketServerError));
                setIsPending(false);
            } else if (marketInvoiceIdAdded) {
                showFlag('success', t('general.success'), t('invoice.successfullyCreated'));
                setIsPending(false);
                navigate(
                    `/operations/${operationId}/markets/${marketId}/invoices/${marketInvoiceIdAdded}?edit`,
                    {
                        state: { isCreation: true },
                    },
                );
            }
        }
    }, [
        navigate,
        marketInvoiceIdAdded,
        isPending,
        marketId,
        marketInvoiceServerError,
        marketServerError,
        operationId,
        t,
    ]);

    useEffect(() => {
        void dispatch(getMarket({ id: Number(marketId), operationId: Number(operationId) }));
        void dispatch(
            getEngagements({ marketId: Number(marketId), operationId: Number(operationId) }),
        );
        void dispatch(
            getMarketInvoices({
                operationId: Number(operationId),
                marketId: Number(marketId),
            }),
        );
        void dispatch(getGroupCodeSequences());
        void dispatch(
            getLastMarketInvoices({
                operationId: Number(operationId),
                marketId: Number(marketId),
            }),
        );
    }, [dispatch, operationId, marketId]);

    const headerTitleAndStyle = [
        { header: 'month', style: { width: '10%' } },
        { header: 'situation', style: { width: '17%' } },
        { header: 'presentedAt', style: { width: '17%' } },
        { header: 'validatedAt', style: { width: '17%' } },
        { header: 'deadline', style: { width: '17%' } },
        { header: 'TTC', style: { width: '17%', justifyContent: 'flex-end' } },
    ];

    const handleHeaderDisplay = (
        item: {
            header: string;
            style: React.CSSProperties;
        },
        index: number,
    ) => (
        <StyledGridCol key={index} style={item.style}>
            <StyledHeaderText type="small">{t(`invoices.list.${item.header}`)}</StyledHeaderText>
        </StyledGridCol>
    );

    const createInvoice = (groupCode: MarketInvoiceGroupCodeForm['groupCode'] = null) => {
        const marketInvoice: MarketInvoiceCreationFormData = {
            marketId: Number(marketId),
            groupCode,
        };
        void dispatch(
            createMarketInvoice({
                marketInvoice,
                operationId: Number(operationId),
                marketId: Number(marketId),
            }),
        );
        setIsPending(true);
    };

    const handleAddClick = () => {
        if (market) {
            if (market.isJointGroupMarket) {
                setIsGroupCodeModalOpen(true);
            } else {
                createInvoice();
            }
        }
    };

    const handleDeleteClick = (id: number) => {
        setMarketInvoiceIdToBeDeleted(id);
        setIsDeleteModalOpen(true);
    };

    const groupCodeOptions: GroupCodeOption[] = usedGroupCodes.map((groupCode) => ({
        value: groupCode,
        label: groupCode,
    }));

    const groupCodeSchema = Yup.object().shape({
        groupCode: Yup.string()
            .oneOf(groupCodeSequence !== undefined ? groupCodeSequence.value.split(',') : [])
            .required(t('errors.required')),
    });

    const setButtonsAccordingToPermissions = () => {
        let buttons: ButtonProps[] = [];
        if (hasRightToImportInvoices) {
            buttons = [
                {
                    'data-testid': 'importInvoicesButton',
                    text: t('markets.importInvoices'),
                    aspect: 'secondary',
                    onClick() {
                        if (fileInputRef.current) {
                            fileInputRef.current.click();
                        }
                    },
                },
            ];
        }
        if (hasRightToAddInvoices) {
            buttons = [
                ...buttons,
                {
                    'data-testid': 'addInvoiceButton',
                    text: t('invoices.addInvoice'),
                    aspect: 'primary',
                    throttled: true,
                    onClick: handleAddClick,
                },
            ];
        }

        return buttons;
    };

    const initialValues = {
        groupCode: '',
    } as MarketInvoiceGroupCodeForm;

    return market ? (
        <>
            <MainLayout
                header={
                    <Header
                        {...headerProps}
                        title={!marketName ? t('market.defaultLabel') : marketName}
                        goBackTo={`/operations/${operationId}/markets`}
                        buttons={setButtonsAccordingToPermissions()}
                        showBackButton
                        showNavigation
                    />
                }
                smallContentSidePadding
            >
                <ImportInvoices operationId={Number(operationId)} fileInputRef={fileInputRef} />
                <GridRow
                    style={{
                        marginBottom: '1rem',
                    }}
                />
                {!isLoading ? (
                    <>
                        {totalMarketInvoices > 0 && hasRightToReadInvoices && (
                            <>
                                <MarketInvoiceLinesHeader>
                                    {headerTitleAndStyle.map(handleHeaderDisplay)}
                                </MarketInvoiceLinesHeader>
                                {marketInvoices.map((item) => (
                                    <MarketInvoiceLine
                                        key={`line-${item.id}`}
                                        id={item.id}
                                        columns={headerTitleAndStyle}
                                        marketInvoice={item}
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                ))}
                            </>
                        )}
                        {totalMarketInvoices === 0 && hasRightToReadInvoices && (
                            <StyledEmptyState
                                data-testid="noInvoices"
                                imageName="Lines"
                                titleTranslationKey="invoices.noInvoices"
                                button={
                                    hasRightToAddInvoices ? (
                                        <Button
                                            onClick={handleAddClick}
                                            text={t('invoices.addInvoice')}
                                            aspect="primary"
                                            size="medium"
                                            data-testid="addInvoice"
                                        />
                                    ) : null
                                }
                            />
                        )}
                    </>
                ) : (
                    <Loader />
                )}
            </MainLayout>
            <Modal
                isOpen={isDeleteModalOpen}
                onCloseButtonPressed={() => {
                    setIsDeleteModalOpen(false);
                }}
                buttons={[
                    {
                        text: t('general.cancel'),
                        aspect: 'secondary',
                        'data-testid': 'cancelDelete',
                        onClick() {
                            setIsDeleteModalOpen(false);
                        },
                    },
                    {
                        text: t('general.confirm'),
                        aspect: 'primary',
                        'data-testid': 'confirm',
                        onClick() {
                            if (marketInvoiceIdToBeDeleted) {
                                setIsDeleteTriggered(true);
                                void dispatch(
                                    deleteMarketInvoice({
                                        id: marketInvoiceIdToBeDeleted,
                                        marketId: Number(marketId),
                                        operationId: Number(operationId),
                                    }),
                                );
                            }
                        },
                    },
                ]}
                title={t('invoice.deletion')}
                size="small"
                iconName="Warning"
                iconColor={colors.yellow.Y400}
                centerTitle
            >
                <Text style={{ margin: '0 0 1rem 0' }}>{t('invoice.confirmDeletion')}</Text>
            </Modal>

            <Formik
                initialValues={initialValues}
                validationSchema={groupCodeSchema}
                onSubmit={(values: MarketInvoiceGroupCodeForm) => {
                    createInvoice(values.groupCode);
                    setIsPending(true);
                }}
            >
                {(props: FormikProps<MarketInvoiceGroupCodeForm>) => (
                    <Modal
                        isOpen={isGroupCodeModalOpen}
                        size="small"
                        onCloseButtonPressed={() => {
                            props.resetForm();
                            setIsGroupCodeModalOpen(false);
                        }}
                        buttons={[
                            {
                                text: t('general.cancel'),
                                aspect: 'secondary',
                                'data-testid': 'cancelDelete',
                                onClick() {
                                    props.resetForm();
                                    setIsGroupCodeModalOpen(false);
                                },
                            },
                            {
                                text: t('general.confirm'),
                                aspect: 'primary',
                                'data-testid': 'save',
                                onClick() {
                                    props.handleSubmit();
                                },
                            },
                        ]}
                    >
                        <Form>
                            <Text>{t('invoice.chooseGroupCodeForInvoice')}</Text>
                            <FormikOptionDropdown
                                items={groupCodeOptions}
                                name="groupCode"
                                initialNameDiplay={
                                    groupCodeOptions.find(
                                        (groupCode) => groupCode.value === props.values.groupCode,
                                    )?.label ?? t('engagement.chooseGroupCode')
                                }
                                label={t('engagement.groupCode')}
                                data-testid="marketType"
                                errorTestId="errorMarketType"
                                style={{ margin: '0' }}
                            />
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    ) : (
        <Loader />
    );
};

export default MarketInvoices;
