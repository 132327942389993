import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import type { FormikProps } from 'formik';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { format, addMonths, formatISO9075 } from 'date-fns';

import Modal from './Modal';
import GridRow from './GridRow';
import GridCol from './GridCol';
import type { Permission } from '../slices/authSlice';
import PermissionAwareText from './PermissionAwareText';
import { colors } from '../constants/colors';
import type { PriceIndexFormData, PriceIndexValueFormData } from '../slices/priceIndexSlice';
import {
    createPriceIndex,
    selectIsCreatePriceIndexFulfilled,
    updatePriceIndex,
    selectIsUpdatePriceIndexFulfilled,
    selectError,
    resetError,
} from '../slices/priceIndexSlice';
import { showFlag } from './Flag';
import { getCurrentStartOfMonthWithoutTime } from '../utils/date';
import AddItemLine from './AddItemLine';
import Text from './Text';
import PermissionAwareDateInputField from './PermissionAwareDateInputField';
import { Grid as TableGrid } from './Table';
import { CellDiv as TableCell } from './TableRow';
import { useAppDispatch } from '../store';

type Props = {
    isCreationMode?: boolean;
    priceIndex?: PriceIndexFormData;
    isOpen: boolean;
    onClose: () => void;
    permissionNeeded: Permission;
};

const Container = styled.div`
    width: 100%;
`;

const PriceIndexEditModal: React.FC<Props> = ({
    isCreationMode = false,
    priceIndex,
    isOpen,
    onClose,
    permissionNeeded,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const isCreatePriceIndexFulfilled = useSelector(selectIsCreatePriceIndexFulfilled);
    const isUpdatePriceIndexFulfilled = useSelector(selectIsUpdatePriceIndexFulfilled);
    const serverError = useSelector(selectError);

    const [isCreatingOrUpdatingPriceIndex, setIsCreatingOrUpdatingPriceIndex] = useState(false);

    const errorMessage = t('errors.error');
    let serverErrorMessage = '';
    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    useEffect(() => {
        if (isCreatingOrUpdatingPriceIndex) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
                void dispatch(resetError());
            } else if (isCreatePriceIndexFulfilled || isUpdatePriceIndexFulfilled) {
                onClose();
            }
        }
    }, [
        dispatch,
        errorMessage,
        isCreatePriceIndexFulfilled,
        isCreatingOrUpdatingPriceIndex,
        isUpdatePriceIndexFulfilled,
        serverError,
        serverErrorMessage,
        onClose,
    ]);

    const getPriceIndexPreviousMonthValue = (
        currentPriceIndexValues: PriceIndexValueFormData[],
    ): PriceIndexValueFormData => {
        const firstPriceIndexValueMonth =
            currentPriceIndexValues[currentPriceIndexValues.length - 1].month;
        const newPriceIndexMonth = format(
            addMonths(new Date(firstPriceIndexValueMonth), -1),
            'yyyy-MM-dd',
        );
        const newEcheanceDefaultValues = {
            month: newPriceIndexMonth,
            value: '',
        };
        return newEcheanceDefaultValues;
    };

    const getPriceIndexNextMonthValue = (
        currentPriceIndexValues: PriceIndexValueFormData[],
    ): PriceIndexValueFormData => {
        const lastPriceIndexValueMonth = currentPriceIndexValues[0].month;
        const newPriceIndexMonth = format(
            addMonths(new Date(lastPriceIndexValueMonth), 1),
            'yyyy-MM-dd',
        );
        const newEcheanceDefaultValues = {
            month: newPriceIndexMonth,
            value: '',
        };
        return newEcheanceDefaultValues;
    };

    const initialValues = priceIndex ?? {
        name: '',
        values: [
            {
                month: format(getCurrentStartOfMonthWithoutTime(), 'yyyy-MM-dd'),
                value: '',
            },
        ],
    };

    const priceIndexSchema = Yup.object().shape({
        name: Yup.string()
            .required(t('errors.required'))
            .max(10, t('errors.tooLong', { number: 10 })),
        values: Yup.array()
            .of(
                Yup.object().shape({
                    month: Yup.date().required(t('errors.required')),
                    value: Yup.number()
                        .required(t('errors.required'))
                        .typeError(t('errors.required')),
                }),
            )
            .min(1),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={priceIndexSchema}
            onSubmit={(formValues, actions) => {
                setIsCreatingOrUpdatingPriceIndex(true);
                const payload = {
                    id: formValues.id,
                    name: formValues.name,
                    values: formValues.values.map((priceIndexValue) => ({
                        ...priceIndexValue,
                        value: Number(priceIndexValue.value),
                        month: formatISO9075(new Date(priceIndexValue.month)),
                    })),
                };
                if (isCreationMode) {
                    void dispatch(createPriceIndex(payload));
                } else {
                    void dispatch(updatePriceIndex(payload));
                }
                actions.setSubmitting(true);
            }}
        >
            {(formikProps: FormikProps<PriceIndexFormData>) => (
                <Modal
                    isOpen={isOpen}
                    onCloseButtonPressed={() => {
                        onClose();
                    }}
                    buttons={[
                        {
                            text: t('general.cancel'),
                            aspect: 'secondary',
                            'data-testid': 'cancelDelete',
                            onClick() {
                                onClose();
                            },
                        },
                        {
                            text: t('general.submit'),
                            aspect: 'primary',
                            'data-testid': 'submit',
                            async onClick() {
                                await formikProps.validateForm();
                                formikProps.handleSubmit();
                            },
                        },
                    ]}
                    title={
                        isCreationMode ? t('priceIndex.addAPriceIndex') : t('priceIndex.editValues')
                    }
                    titleFontSize="1.5rem"
                    backgroundColor={colors.neutral.N50}
                    specificPadding="0"
                    specificHeight="90vh"
                    centerTitle
                    scrollableBody
                >
                    <Container>
                        <GridRow>
                            <GridCol defaultScreen={4}>
                                <PermissionAwareText
                                    marginTop="0"
                                    permissionsRequired={[permissionNeeded]}
                                    label={t('priceIndex.name')}
                                    name="name"
                                    type="text"
                                    placeholder={t('priceIndex.name')}
                                    data-testid="name"
                                    errorTestId="errorName"
                                    noMarginTop
                                    whiteBackground
                                    editable
                                />
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol defaultScreen={12}>
                                <FieldArray
                                    name="values"
                                    render={(arrayHelpers) => (
                                        <>
                                            <TableGrid
                                                numberOfColumns={2}
                                                rowGap="0"
                                                columnGap="0"
                                                isHeader
                                            >
                                                <TableCell
                                                    msGridColumn={1}
                                                    isLeftFixedColumn={false}
                                                    isHeader
                                                >
                                                    <Text type="H100" style={{ margin: 0 }}>
                                                        {t('priceIndex.month')}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    msGridColumn={2}
                                                    isLeftFixedColumn={false}
                                                    isHeader
                                                >
                                                    <Text type="H100" style={{ margin: 0 }}>
                                                        {t('priceIndex.value')}
                                                    </Text>
                                                </TableCell>
                                            </TableGrid>
                                            <AddItemLine
                                                borderRadius="0"
                                                text={t('priceIndex.addAValue')}
                                                onClick={() =>
                                                    arrayHelpers.unshift(
                                                        getPriceIndexNextMonthValue(
                                                            formikProps.values.values,
                                                        ),
                                                    )
                                                }
                                                data-testid="addEcheanceLine"
                                            />
                                            {formikProps.values.values.map(
                                                (priceIndexValue, index) => (
                                                    <TableGrid
                                                        key={priceIndexValue.id}
                                                        numberOfColumns={2}
                                                        rowGap="0"
                                                        columnGap="0"
                                                    >
                                                        <TableCell
                                                            msGridColumn={1}
                                                            isLeftFixedColumn={false}
                                                            isHeader
                                                        >
                                                            <PermissionAwareDateInputField
                                                                editable={
                                                                    formikProps.values.values
                                                                        .length < 2
                                                                }
                                                                permissionsRequired={[
                                                                    permissionNeeded,
                                                                ]}
                                                                name={`values.${index}.month`}
                                                                whiteBackground
                                                                isMonthPicker
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            msGridColumn={1}
                                                            isLeftFixedColumn={false}
                                                            isHeader
                                                        >
                                                            <PermissionAwareText
                                                                marginTop="0"
                                                                marginBottom="0"
                                                                inputWidth="6.8125rem"
                                                                inputHeight="1.5rem"
                                                                textAlign="right"
                                                                permissionsRequired={[
                                                                    permissionNeeded,
                                                                ]}
                                                                name={`values.${index}.value`}
                                                                type="text"
                                                                numberType="decimal"
                                                                data-testid="priceIndexValue"
                                                                errorTestId="errorPriceIndexValue"
                                                                whiteBackground
                                                                editable
                                                            />
                                                        </TableCell>
                                                    </TableGrid>
                                                ),
                                            )}
                                            <AddItemLine
                                                borderRadius="0"
                                                text={t('priceIndex.addAValue')}
                                                onClick={() =>
                                                    arrayHelpers.push(
                                                        getPriceIndexPreviousMonthValue(
                                                            formikProps.values.values,
                                                        ),
                                                    )
                                                }
                                                data-testid="addEcheanceLine"
                                            />
                                        </>
                                    )}
                                />
                            </GridCol>
                        </GridRow>
                    </Container>
                </Modal>
            )}
        </Formik>
    );
};

export default PriceIndexEditModal;
