import type { PropsWithChildren } from 'react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import { MenuItem } from '@blueprintjs/core';
import type { ISelectProps as Props, IItemRendererProps } from '@blueprintjs/select';
import { Select as BPSelect } from '@blueprintjs/select';

import Button from '../Button';
import { SelectStyle } from './dropdownStyle';
import Icon from '../Icon';
import { StyledLabel } from '../PermissionAwareDisplay';
import FieldErrorMessage from '../FieldErrorMessage';

import { colors } from '../../constants/colors';
import type { MarketForDropDown } from '../../slices/marketSlice';

export type ISelectProps = {
    buttonTextTranslationKey?: string;
    'data-testid'?: string;
    errorTestId?: string;
    filterable?: Props<MarketForDropDown>['filterable'];
    handleChange?: (market: MarketForDropDown) => void;
    initialMarketId?: MarketForDropDown['id'] | null;
    itemPredicate?: Props<MarketForDropDown>['itemPredicate'];
    itemRenderer?: Props<MarketForDropDown>['itemRenderer'];
    items: Props<MarketForDropDown>['items'];
    label?: string;
    name: string;
    noResults?: Props<MarketForDropDown>['noResults'];
    onItemSelect?: Props<MarketForDropDown>['onItemSelect'];
    shouldBeResettable?: boolean;
};

// define type of data going through the select
const Select = BPSelect.ofType<MarketForDropDown>();
const getMarketDisplayValue = (market: MarketForDropDown) =>
    `${market.internalNumber} ${market.label}`;

const predicateMarketData = (
    query: string,
    item: MarketForDropDown,
    _index: number | undefined,
    exactMatch: boolean | undefined,
) => {
    const normalizedTitle = getMarketDisplayValue(item).toString().toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
};

const MarketDropdown: React.FC<PropsWithChildren<ISelectProps>> = ({
    children,
    label,
    initialMarketId,
    name,
    errorTestId,
    shouldBeResettable,
    ...props
}) => {
    const { t } = useTranslation();
    const initialMarket = props.items.find(({ id }) => id === Number(initialMarketId));

    let initialDisplayName: string | null = null;
    if (initialMarket) {
        initialDisplayName = getMarketDisplayValue(initialMarket);
    }
    const testId = props['data-testid'] ? props['data-testid'] : 'test';

    // Define state for handling field text change
    const placeholder = t('market.chooseInitialMarket');
    const [nameDisplay, setNameDisplay] = useState<string>(initialDisplayName ?? placeholder);
    const [field, meta] = useField(name);

    // Handle change of nameDisplay to modify select button inside text
    useEffect(() => {
        setNameDisplay(initialDisplayName ?? placeholder);
    }, [initialDisplayName, placeholder]);

    // On select item click set nameDisplay to clicked item
    const handleValueChange = (market: MarketForDropDown) => {
        setNameDisplay(getMarketDisplayValue(market));
        field.onChange(name)(String(market.id));
        if (props.handleChange) {
            props.handleChange(market);
        }
    };

    // Render items inside select
    const renderMarketData = (market: MarketForDropDown, { handleClick }: IItemRendererProps) => (
        <MenuItem
            key={market.id}
            text={getMarketDisplayValue(market)}
            onClick={handleClick}
            tabIndex={0}
            data-testid={`${testId}-dropdown-item`}
        />
    );

    let items = [...props.items];

    if (shouldBeResettable) {
        items = [
            {
                id: -1,
                internalNumber: '',
                label: '',
            },
            ...items,
        ];
    }

    return (
        <div
            className={`dropdown${meta.error && meta.touched ? '-error' : ''}`}
            data-testid={`${testId}-dropdown`}
        >
            {label && (
                <StyledLabel color={colors.neutral.N300} style={{ marginBottom: '0' }}>
                    {label}
                </StyledLabel>
            )}
            <SelectStyle />
            <Select
                items={items}
                itemRenderer={props.itemRenderer ?? renderMarketData}
                noResults={props.noResults ?? <MenuItem text={t('general.noResults')} disabled />}
                onItemSelect={props.onItemSelect ?? handleValueChange}
                itemPredicate={props.itemPredicate ?? predicateMarketData}
                filterable={props.filterable ?? false}
                data-testid={`${testId}-select`}
                inputProps={{
                    leftIcon: null,
                    leftElement: <Icon name="Search" style={{ width: '1.5rem' }} />,
                    placeholder: t('general.search'),
                }}
            >
                <Button
                    aspect="dropdown"
                    iconName="ArrowDropDown"
                    iconSize="1.25rem"
                    text={nameDisplay}
                    style={meta.error && meta.touched ? {} : { marginBottom: '0.6rem' }}
                    data-testid={`${testId}-dropdown-button`}
                />
            </Select>
            {meta.error && meta.touched && (
                <FieldErrorMessage data-testid={errorTestId}>{meta.error}</FieldErrorMessage>
            )}
        </div>
    );
};

export default MarketDropdown;
