import { format, startOfMonth } from 'date-fns';

/**
 * Get a date n years before or after the current date
 *
 * @param years Number of years before or after the current date
 * @param isYearExtremity boolean used to return the beginning (if past date) or ending
 * (if current or future year) day of the year
 */
export const getPastOrFutureDateByYears = (years: number, isYearExtremity = false): Date => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const resultDateString = currentDate
        .toString()
        .replace(String(currentYear), String(currentYear + years));
    let resultDate = new Date(resultDateString);
    if (isYearExtremity) {
        resultDate =
            years < 0
                ? new Date(resultDate.getFullYear(), 0, 1)
                : new Date(resultDate.getFullYear(), 11, 31);
    }
    return resultDate;
};

// Parse date string of format 'yyyy-mm-dd' or 'yyyy-mm-dd hh:mm:ss' to Date
export const parseDateString = (dateString: string, separator = '-') => {
    dateString = format(new Date(dateString), 'yyyy-MM-dd');
    return new Date(
        Number(dateString.split(separator)[0]),
        Number(dateString.split(separator)[1]) - 1,
        Number(dateString.split(separator)[2]),
    );
};

export const formatDateFromDb = (date: string, dateFormat: string) =>
    date ? format(new Date(date), dateFormat) : '';

export const convertDaysToCompleteWeeks = (durationInDays: number) =>
    Math.floor(durationInDays / 7);

export const convertWeeksToCompleteDays = (durationInWeeks: number) =>
    Math.floor(durationInWeeks * 7);

export const convertWeeksToMonths = (durationInWeeks: number | null) =>
    durationInWeeks ? durationInWeeks / 4.34524285714286 : 0;

export const getCurrentStartOfMonthWithoutTime = (): Date => {
    const currentMonth = startOfMonth(new Date());
    const currentMonthString = format(currentMonth, 'yyyy-MM-dd');
    return new Date(currentMonthString);
};
