import type { PropsWithChildren } from 'react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import { MenuItem } from '@blueprintjs/core';
import type { ISelectProps as Props, IItemRendererProps } from '@blueprintjs/select';
import { Select as BPSelect } from '@blueprintjs/select';

import Button from '../Button';
import { SelectStyle } from './dropdownStyle';
import { StyledLabel } from '../PermissionAwareDisplay';
import FieldErrorMessage from '../FieldErrorMessage';

import { colors } from '../../constants/colors';
import type { GroupCodeSequenceForDropDown } from '../../slices/groupCodeSequenceSlice';

export type ISelectProps = {
    buttonTextTranslationKey?: string;
    'data-testid'?: string;
    errorTestId?: string;
    filterable?: Props<GroupCodeSequenceForDropDown>['filterable'];
    handleChange?: (groupCodeSequence: GroupCodeSequenceForDropDown) => void;
    initialGroupCodeSequenceId?: GroupCodeSequenceForDropDown['id'] | null;
    itemPredicate?: Props<GroupCodeSequenceForDropDown>['itemPredicate'];
    itemRenderer?: Props<GroupCodeSequenceForDropDown>['itemRenderer'];
    items: Props<GroupCodeSequenceForDropDown>['items'];
    label?: string;
    name: string;
    noResults?: Props<GroupCodeSequenceForDropDown>['noResults'];
    onItemSelect?: Props<GroupCodeSequenceForDropDown>['onItemSelect'];
    shouldBeResettable?: boolean;
};

// define type of data going through the select
const Select = BPSelect.ofType<GroupCodeSequenceForDropDown>();
const getGroupCodeSequenceDisplayValue = (groupCodeSequence: GroupCodeSequenceForDropDown) =>
    groupCodeSequence.label;

const GroupCodeSequenceDropdown: React.FC<PropsWithChildren<ISelectProps>> = ({
    children,
    label,
    initialGroupCodeSequenceId,
    name,
    errorTestId,
    shouldBeResettable,
    ...props
}) => {
    const { t } = useTranslation();
    const initialGroupCodeSequence = props.items.find(
        ({ id }) => id === Number(initialGroupCodeSequenceId),
    );

    let initialDisplayName: string | null = null;
    if (initialGroupCodeSequence) {
        initialDisplayName = getGroupCodeSequenceDisplayValue(initialGroupCodeSequence);
    }
    const testId = props['data-testid'] ? props['data-testid'] : 'test';

    // Define state for handling field text change
    const placeholder = t('operation.chooseGroupCodeSequence');
    const [nameDisplay, setNameDisplay] = useState<string>(initialDisplayName ?? placeholder);
    const [field, meta] = useField(name);

    // Handle change of nameDisplay to modify select button inside text
    useEffect(() => {
        setNameDisplay(initialDisplayName ?? placeholder);
    }, [initialDisplayName, placeholder]);

    // On select item click set nameDisplay to clicked item
    const handleValueChange = (groupCodeSequence: GroupCodeSequenceForDropDown) => {
        setNameDisplay(getGroupCodeSequenceDisplayValue(groupCodeSequence));
        field.onChange(name)(String(groupCodeSequence.id));
        if (props.handleChange) {
            props.handleChange(groupCodeSequence);
        }
    };

    // Render items inside select
    const renderGroupCodeSequenceData = (
        groupCodeSequence: GroupCodeSequenceForDropDown,
        { handleClick }: IItemRendererProps,
    ) => (
        <MenuItem
            key={groupCodeSequence.id}
            text={getGroupCodeSequenceDisplayValue(groupCodeSequence)}
            onClick={handleClick}
            tabIndex={0}
            data-testid={`${testId}-dropdown-item`}
        />
    );

    let items = [...props.items];

    if (shouldBeResettable) {
        items = [
            {
                id: -1,
                label: '',
            },
            ...items,
        ];
    }

    return (
        <div
            className={`dropdown${meta.error && meta.touched ? '-error' : ''}`}
            data-testid={`${testId}-dropdown`}
        >
            {label && (
                <StyledLabel color={colors.neutral.N300} style={{ marginBottom: '0' }}>
                    {label}
                </StyledLabel>
            )}
            <SelectStyle />
            <Select
                items={items}
                itemRenderer={props.itemRenderer ?? renderGroupCodeSequenceData}
                onItemSelect={props.onItemSelect ?? handleValueChange}
                filterable={false}
                data-testid={`${testId}-select`}
            >
                <Button
                    aspect="dropdown"
                    iconName="ArrowDropDown"
                    iconSize="1.25rem"
                    text={nameDisplay}
                    style={meta.error && meta.touched ? {} : { marginBottom: '0.6rem' }}
                    data-testid={`${testId}-dropdown-button`}
                />
            </Select>
            {meta.error && meta.touched && (
                <FieldErrorMessage data-testid={errorTestId}>{meta.error}</FieldErrorMessage>
            )}
        </div>
    );
};

export default GroupCodeSequenceDropdown;
