import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import type { Operation } from '../slices/operationSlice';
import {
    importMarkets,
    selectImportResult,
    resetImportState,
    selectExcelFilename,
    selectImportStatus,
    selectError,
    selectStringifiedFileDataToImport,
    setStringifiedFileDataToImport,
    resetStringifiedFileDataToImport,
} from '../slices/marketSlice';
import ImportEdiflex from './ImportEdiflex';
import ImportMarketsResultModal from './ImportMarketsResultModal';
import type { FileData, ImportPayload } from '../slices/common';
import { useAppDispatch } from '../store';

type Props = {
    operationId: Operation['id'];
    fileInputRef: React.RefObject<HTMLInputElement>;
    onCloseImportMarketsModal: () => void;
};

const ImportMarkets: React.FC<Props> = ({
    operationId,
    fileInputRef,
    onCloseImportMarketsModal,
}) => {
    const dispatch = useAppDispatch();

    const importResult = useSelector(selectImportResult);
    const importFilename = useSelector(selectExcelFilename);
    const importStatus = useSelector(selectImportStatus);
    const importServerError = useSelector(selectError);
    const stringifiedFileDataToImport = useSelector(selectStringifiedFileDataToImport);

    // componentWillUnmount
    useEffect(
        () => () => {
            dispatch(resetStringifiedFileDataToImport());
        },
        [dispatch],
    );

    const handleImportConfirmation = () => {
        if (stringifiedFileDataToImport) {
            const fileData: FileData | null = stringifiedFileDataToImport
                ? JSON.parse(stringifiedFileDataToImport)
                : null;

            if (fileData) {
                void dispatch(
                    importMarkets({
                        fileData,
                        importConfirmed: true,
                        operationId: Number(operationId),
                    }),
                );
            }
        }
    };

    const importItems = (dataToImport: ImportPayload) => {
        const { fileData, operationId: id } = dataToImport;

        const stringifiedFileData = JSON.stringify(fileData);
        dispatch(setStringifiedFileDataToImport(stringifiedFileData));

        void dispatch(
            importMarkets({
                fileData,
                importConfirmed: false,
                operationId: Number(id),
            }),
        );
    };

    const handleCloseImportResultsModal = () => {
        dispatch(resetImportState());
        onCloseImportMarketsModal();
        localStorage.removeItem('fileData');
    };

    return (
        <ImportEdiflex
            operationId={operationId}
            fileInputRef={fileInputRef}
            importResult={importResult}
            importFilename={importFilename}
            importStatus={importStatus}
            importItems={importItems}
            importServerError={importServerError}
            onCloseImportConfirmationModal={handleCloseImportResultsModal}
            onImportConfirmation={handleImportConfirmation}
            importResultModalContent={<ImportMarketsResultModal />}
            onCloseImportResultsModal={handleCloseImportResultsModal}
        />
    );
};

export default ImportMarkets;
