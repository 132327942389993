import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Classes } from '@blueprintjs/core';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import type {
    DropResult,
    DraggableProvidedDraggableProps,
    DraggableStateSnapshot,
} from '@hello-pangea/dnd';
import { Droppable, Draggable } from '@hello-pangea/dnd';

import type {
    BudgetLine as BudgetLineEntity,
    IBudgetLineFormData,
} from '../slices/budgetLineSlice';
import {
    selectError,
    selectBudgetLine,
    selectBudgetLineIdAdded,
    selectBudgetLineIdDeleted,
    deleteBudgetLine,
    selectChildrenIdByParentId,
    selectLastBudgetLine,
    resetBudgetLineAdded,
    getBudgetLines,
    selectBudgetLineConvertedToGroup,
    convertToGroupBudgetLine,
    updateBudgetLine,
    selectIsLoading,
    getBudgetLinesForRefDropdown,
} from '../slices/budgetLineSlice';
import { selectTvaCodes, selectTvaCodesById } from '../slices/tvaCodeSlice';
import { getBudget, selectBudget } from '../slices/budgetSlice';
import { colors } from '../constants/colors';
import handle from '../assets/images/handle.png';
import Icon from './Icon';
import Text from './Text';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import PermissionAwareText from './PermissionAwareText';
import type { Option } from './SelectField';
import Button from './Button';
import { styles } from '../constants/styles';
import Elevation from './Elevation';
import Popover, { PopoverContent, PopoverItem } from './Popover';
import Loader from './Loader';
import CollapsableLine from './CollapsableLine';
import { showFlag } from './Flag';
import BudgetLineModal from './BudgetLineModal';
import Modal from './Modal';
import Tooltip from './Tooltip';
import { CurrencyFormatter, PercentageFormatter } from '../utils/formatters';
import { calculateAmountRatio } from '../utils/calculateAmountRatio';
import PercentageBadge from './PercentageBadge';
import { useAppDispatch } from '../store';

const LineIndex = styled(Elevation)<{ indexLevel: string | undefined; isEditMode?: boolean }>`
    @media print {
        margin-bottom: 0 !important;
    }
    margin-left: ${({ indexLevel }) => (indexLevel ? `calc(1rem * ${indexLevel})` : '0rem')};
    ${({ isEditMode }) =>
        !isEditMode && `margin-bottom: 0.5rem; background-color: ${colors.other.white};`}
    min-height: 2rem;
    display: flex;
`;

const DraggableItem = styled.div<
    DraggableProvidedDraggableProps &
        DraggableStateSnapshot & { isDraggingOver: boolean; isEditMode: boolean }
>`
    background-color: ${({ isDragging, isDraggingOver, isEditMode }) => {
        if (isDragging) {
            return colors.neutral.N200;
        } else if (isDraggingOver) {
            return colors.neutral.N100;
        } else if (isEditMode) {
            return colors.neutral.N50;
        } else {
            return colors.other.white;
        }
    }};

    user-select: none;
    border-radius: ${styles.borderRadiusSmall};
    width: 100%;
    justify-content: center;
`;

const Item = styled.div<{ isEditMode?: boolean }>`
    @media print {
        padding: 0rem;
    }
    background-color: ${({ isEditMode }) => (isEditMode ? colors.neutral.N50 : colors.other.white)};
    user-select: none;
    border-radius: ${styles.borderRadiusSmall};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ isEditMode }) => !isEditMode && 'padding: 0.25rem 0;'}
`;

const TableCol = styled.div<{
    justifyContent?: string;
    flex?: string;
    width?: string;
}>`
    @media print {
        ${({ width }) => width === '15vw' && `justify-content: space-between;`}
        width:  ${({ width }) => (width === '6vw' ? `6vw !important;` : `20vw !important;`)};
        padding: 0rem;
        margin: 0rem !important;
    }
    display: flex;
    padding: 0 0.5rem;
    align-items: center;
    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
    ${({ flex }) => flex && `flex: ${flex};`}
    ${({ width }) => width && `width: ${width};`}
    p {
        padding: 0;
    }
`;

const StyledTableCol = styled(TableCol)`
    @media print {
        width: 15% !important;
    }
`;

const Line = styled.div<{ isEditMode?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    ${({ isEditMode }) => isEditMode && 'padding: 0.25rem 0;'}
`;

const DragHandle = styled.img<DraggableStateSnapshot>`
    transform: rotate(90deg);
    width: 1rem;
    height: 0.6875rem;
    margin: 0.3rem;
`;

const Content = styled.div<{ isEditMode: boolean | undefined }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    ${({ isEditMode }) => !isEditMode && 'padding: 0.25rem 1rem 0.25rem 0;'}
`;

const AddBudgetLine = styled.div`
    background-color: ${colors.blue.B50};
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 1rem;
    margin-top: 0.25rem;
    border-radius: ${styles.borderRadiusSmall};
`;

const TypeOfLine = styled(Text)<{ isEditMode: boolean | undefined }>`
    color: ${colors.neutral.N300};
    padding: ${({ isEditMode }) => (isEditMode ? '1rem 0' : '0')};
    margin-bottom: 0;
    margin-left: 1rem;
`;

const ContextualMenuIcon = styled(Icon)`
    margin-right: 1rem;
    cursor: pointer;
`;

const AddLineIcon = styled.div`
    width: 1.125rem;
    height: 1.125rem;
    background-color: ${colors.neutral.N300};
    position: relative;
    margin: 0 -1.1rem 0 0;
    border-radius: 0 ${styles.borderRadiusXSmall} ${styles.borderRadiusXSmall} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const ExpandGroupLineIcon = styled.div<{ isEditMode: boolean | undefined; indexLevel?: string }>`
    @media print {
        display: none;
    }
    width: 0.8125rem;
    height: ${({ isEditMode }) => (isEditMode ? '2.5rem' : '2rem')};
    background-color: ${colors.neutral.N400};
    position: relative;
    margin: 0;
    border-top-left-radius: ${styles.borderRadiusSmall};
    border-bottom-left-radius: ${styles.borderRadiusSmall};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const ExpandedContent = styled.div<{ isEditMode?: boolean; indexLevel?: string }>`
    @media print {
        padding: 0 0.5rem;
    }
    display: flex;
    width: 100%;
    padding: ${({ isEditMode }) => (isEditMode ? '0 0 0.5rem 3rem' : '0 0.5rem 0.5rem 0.5rem')};
    background-color: ${({ isEditMode }) => !isEditMode && colors.other.white};
    border-bottom-right-radius: ${styles.borderRadiusSmall};
`;

export type TaxOption = Option<number>;

export const CALCULATED_LINE = 'calculated';
export const SPENDING_LINE = 'spending';

export const GENERAL_BUDGET_LINE_TAB = 'generalBudgetLineTab';
export const SPENDING_SCHEMA_BUDGET_LINE_TAB = 'spendingSchemaBudgetLineTab';
export const VARIATIONS_BUDGET_LINE_TAB = 'variationsBudgetLineTab';

type Props = {
    onDragEnd?: (arg: DropResult) => void;
    id?: number | null;
    index: number;
    popoverOpen?: BudgetLineEntity['id'];
    handlePopover?: (id: BudgetLineEntity['id']) => void;
    isFirstBudgetLine?: boolean;
    isEditMode: boolean;
    showChildren?: boolean;
    throttledGetLinesForDropdown?: () => void;
    isDraggingOver?: boolean;
    expandedLineIds?: number[];
    setExpandedLineIds?: (id: number, type: 'add' | 'remove') => void;
};

const BudgetLine: React.FC<Props> = ({
    onDragEnd,
    index,
    id,
    isEditMode,
    isFirstBudgetLine,
    showChildren,
    throttledGetLinesForDropdown,
    isDraggingOver,
    expandedLineIds,
    setExpandedLineIds,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { operationId, budgetId }: { operationId?: string; budgetId?: string } = useParams();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalOptions, setModalOptions] = useState<{ text: string; callback: () => void } | null>(
        null,
    );
    const [isPending, setIsPending] = useState<boolean>(false);

    // Selectors
    const serverError = useSelector(selectError);
    const permissionNeeded = { code: 'BUDGETS_EDIT', operationId };
    const hasRightToEditBudgets = usePermissionsCheck([permissionNeeded]);
    const budgetLine = useSelector(selectBudgetLine(Number(id)));
    const childrenIds = useSelector(selectChildrenIdByParentId(id));
    const lastBudgetLine = useSelector(selectLastBudgetLine);
    const isLoading = useSelector(selectIsLoading);
    // select last child line of the group
    const lastChildGroupLine = useSelector(
        selectBudgetLine(Number(childrenIds[childrenIds.length - 1])),
    );
    const budgetLineIdAdded = useSelector(selectBudgetLineIdAdded);
    const budgetLineIdDeleted = useSelector(selectBudgetLineIdDeleted);
    const budgetLineConvertedToGroup = useSelector(selectBudgetLineConvertedToGroup);
    const tvaCodes = useSelector(selectTvaCodes);
    const tvaCodesById = useSelector(selectTvaCodesById);
    const budget = useSelector(selectBudget(Number(budgetId)));

    const budgetIsEditable = budget?.status !== 'approved' && budget?.status !== 'replaced';

    // Messages
    const successMessage = t('general.success');
    const errorMessage = t('errors.error');
    const savedMessage = t('budget.lineSaved');

    let serverErrorMessage = '';

    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    useEffect(() => {
        // Should only handle update and create flags
        if (isPending && !isLoading) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
                setIsPending(false);
            } else if (!budgetLineIdDeleted && !budgetLineConvertedToGroup && budgetId) {
                showFlag('success', successMessage, savedMessage);
                setIsPending(false);
                setIsModalOpen(false);
                void dispatch(
                    getBudget({ id: Number(budgetId), operationId: Number(operationId) }),
                );
                void dispatch(
                    getBudgetLines({
                        operationId: Number(operationId),
                        budgetId: Number(budgetId),
                        all: true,
                    }),
                );

                if (throttledGetLinesForDropdown) {
                    throttledGetLinesForDropdown();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on every dependencies change
    }, [
        isPending,
        isLoading,
        serverError,
        budgetLine,
        budgetLineIdDeleted,
        budgetLineConvertedToGroup,
    ]);

    useEffect(() => {
        if (budgetLineIdAdded) {
            void dispatch(resetBudgetLineAdded());
            void dispatch(
                getBudgetLinesForRefDropdown({
                    operationId: Number(operationId),
                    budgetId: Number(budgetId),
                }),
            );
        }
    }, [operationId, budgetId, dispatch, budgetLineIdAdded]);

    // TVA Code and taxes
    const taxOptions: TaxOption[] = tvaCodes.map((tvaCode, mapIndex) => ({
        value: tvaCode.id,
        label: `${mapIndex + 1} - ${PercentageFormatter.format(tvaCode.value)}`,
    }));

    const getTvaCodeDisplayLabel = (codeId?: number) => {
        const tvaCode = codeId ? tvaCodesById[codeId] : null;
        const tvaCodeDisplayOption = tvaCode
            ? taxOptions.find(({ value }) => value === tvaCode.id)
            : null;
        return tvaCodeDisplayOption ? tvaCodeDisplayOption.label : '';
    };

    const getTvaCodePercentage = (codeId?: number) => {
        const tvaCode = codeId ? tvaCodesById[codeId] : null;
        const tvaCodePercentage = tvaCode ? PercentageFormatter.format(tvaCode.value) : '';
        return tvaCodePercentage;
    };

    const getTvaCodeValue = (codeId?: number) => {
        const tvaCode = codeId ? tvaCodesById[codeId] : null;
        return tvaCode ? tvaCode.value : 0;
    };

    if (id && !budgetLine) {
        return <Loader overlay />;
    }

    // TODO: What do you think if we put all calcuations that require specific formula in helpers? (Polina)
    const calculateTtcAmount = (amountHt: number, tvaCodeId: number): number =>
        Number(amountHt) + calculateTvaAmount(amountHt, tvaCodeId);

    const calculateTvaAmount = (amountHt: number, tvaCodeId: number): number => {
        const taxRate = getTvaCodeValue(tvaCodeId);
        return amountHt * taxRate;
    };

    const getInputPadding = (isParent = false) => {
        const budgetIndex = budgetLine?.index ?? '-';

        // Let's add an element to the DOM and measure it's width!
        const test = document.createElement('span');
        test.innerText = isParent ? `${budgetIndex}-` : budgetIndex;
        document.getElementsByTagName('html')[0].appendChild(test);
        // 1.5 is for : 1rem left of the index, 0.5 rem right
        const width = `${1.5 + test.offsetWidth / 16}rem`;
        // Remove it from the dom
        test.remove();

        return width;
    };

    let symbols = '';
    if (budgetLine?.isParent) symbols = '∑';
    else {
        if (budgetLine?.isCalculated) {
            if (budgetLine.calculatedFactor) {
                symbols = String(PercentageFormatter.format(budgetLine.calculatedFactor));
            } else {
                symbols = '%';
            }
        } else {
            symbols = '€';
        }
    }

    let nextWBSIndex = '1';
    if (lastChildGroupLine) {
        const lastWBSIndex = lastChildGroupLine.index;
        const splitWBSIndex = lastWBSIndex.split('.');
        // Remove last digit from previous splitted WBS index and add 1 to it
        const incrementedValue = Number(splitWBSIndex.pop()) + 1;
        // Substring before-last-dot part of lastWBSIndex and concatenate with incremented value
        nextWBSIndex = `${lastWBSIndex.substr(
            0,
            lastWBSIndex.lastIndexOf('.') + 1,
        )}${incrementedValue}`;
    } else if (lastBudgetLine) {
        const lastWBSIndex = lastBudgetLine.index;
        const incrementedValue = Number(lastWBSIndex) + 1;
        nextWBSIndex = String(incrementedValue);
    }

    const modalManager = async (
        budgetLineEntity: BudgetLineEntity | null,
        callback: () => void,
    ) => {
        try {
            if (budgetLineEntity) {
                const request = await axios.get(
                    `/operations/${operationId}/budgets/${budgetId}/lines/${budgetLineEntity.id}/children-count`,
                );

                let modalText = '';
                const totalAmountTtc = budgetLineEntity.amountTtc;
                const totalAmountVariationTtc = budgetLineEntity.variationsAmountTtc;

                if (
                    request.data.data.directChildrenCount &&
                    request.data.data.directChildrenCount > 0
                ) {
                    modalText = t('budget.deleteBudgetLineWithChildren', {
                        directChildrenCount: request.data.data.directChildrenCount,
                        totalAmountTtc,
                        totalAmountVariationTtc,
                    });
                    if (request.data.data.containCalculationOrigin) {
                        modalText += t('budget.deleteCalculationOrigin');
                    }
                    setModalOptions({
                        text: modalText,
                        callback,
                    });
                } else {
                    callback();
                }
            }
        } catch (err: unknown) {
            showFlag('error', errorMessage, t('errors.unexpectedError'));
        }
    };

    if (isFirstBudgetLine) {
        return (
            <>
                <Button
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                    text={t('budget.addLine')}
                    aspect="primary"
                    size="medium"
                    data-testid="addBudgetLine"
                />
                {isModalOpen && (
                    <BudgetLineModal
                        isPending={isPending}
                        setIsPending={setIsPending}
                        operationId={Number(operationId)}
                        budgetId={Number(budgetId)}
                        getTvaCodeDisplayLabel={getTvaCodeDisplayLabel}
                        calculateTvaAmount={calculateTvaAmount}
                        calculateTtcAmount={calculateTtcAmount}
                        taxOptions={taxOptions}
                        nextWBSIndex={nextWBSIndex}
                        permissionNeeded={permissionNeeded}
                        hasRightToEdit={hasRightToEditBudgets}
                        setIsModalOpen={setIsModalOpen}
                        isEditMode={isEditMode}
                        indexPadding={getInputPadding()}
                    />
                )}
            </>
        );
    }

    if (!id) {
        // if id null, then modal will be a creation modal
        if (hasRightToEditBudgets) {
            return (
                <>
                    <AddBudgetLine
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >
                        <Icon
                            name="AddBox"
                            color={colors.green.G400}
                            style={{ marginRight: '1rem' }}
                        />
                        <Text
                            type="H500"
                            size="0.875rem"
                            color={colors.neutral.N400}
                            style={{ margin: '0' }}
                        >
                            {t('budget.addBudgetLine')}
                        </Text>
                    </AddBudgetLine>
                    {isModalOpen && (
                        <BudgetLineModal
                            isPending={isPending}
                            setIsPending={setIsPending}
                            operationId={Number(operationId)}
                            budgetId={Number(budgetId)}
                            getTvaCodeDisplayLabel={getTvaCodeDisplayLabel}
                            calculateTvaAmount={calculateTvaAmount}
                            calculateTtcAmount={calculateTtcAmount}
                            taxOptions={taxOptions}
                            nextWBSIndex={nextWBSIndex}
                            permissionNeeded={permissionNeeded}
                            hasRightToEdit={hasRightToEditBudgets}
                            setIsModalOpen={setIsModalOpen}
                            isEditMode={isEditMode}
                            indexPadding={getInputPadding()}
                        />
                    )}
                </>
            );
        }
        return null;
    }

    const handleAddNewLineToGroupClick = () => {
        // Make sure children ids are fetched
        if (childrenIds.length < 1) {
            void dispatch(
                getBudgetLines({
                    operationId: Number(operationId),
                    budgetId: Number(budgetId),
                    parentId: Number(budgetLine?.id),
                }),
            );
        }

        setIsModalOpen(true);
    };

    const handleExpandGroupLineClick = () => {
        if (expandedLineIds && !expandedLineIds.includes(id)) {
            setExpandedLineIds?.(id, 'add');
            void dispatch(
                getBudgetLines({
                    operationId: Number(operationId),
                    budgetId: Number(budgetId),
                    parentId: Number(budgetLine?.id),
                }),
            );
        } else {
            setExpandedLineIds?.(id, 'remove');
        }
    };

    const getIndexLevel = () =>
        budgetLine?.index && (budgetLine.index.split('.').length - 1).toString();

    const getExpandGroupLineIcon = () => (
        <ExpandGroupLineIcon
            isEditMode={isEditMode}
            onClick={handleExpandGroupLineClick}
            indexLevel={getIndexLevel()}
        >
            <Icon
                name={expandedLineIds?.includes(id) ? 'KeyboardArrowDown' : 'KeyboardArrowUp'}
                color={colors.other.white}
            />
        </ExpandGroupLineIcon>
    );

    const budgetLineHasSimulatedPriceVariations =
        !budgetLine?.isSimulated || budgetLine.priceVariationSettings;

    const budgetLineIsParentAndHasNonNullVariationsAmounts =
        budgetLine?.isParent && budgetLine.variationsAmountHt;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: type properly
    const getLine = (provided?: any, snapshot?: any) => (
        <>
            <Line
                isEditMode={isEditMode}
                onClick={() => !isEditMode && !budgetLine?.isParent && setIsModalOpen(true)}
                style={!budgetLine?.isParent ? { cursor: 'pointer' } : {}}
            >
                <Formik
                    initialValues={{
                        id: budgetLine?.id,
                        label: budgetLine?.label ?? '',
                        amountHt: budgetLine?.amountHt.toFixed(2) ?? 0,
                    }}
                    validationSchema={Yup.object().shape({
                        label: Yup.string().required(t('errors.required')).min(1),
                        amountHt: Yup.number()
                            .nullable()
                            .when('isCalculated', {
                                is: false,
                                then: Yup.number().required(t('errors.required')),
                            }),
                    })}
                    onSubmit={(values: Partial<IBudgetLineFormData>, actions) => {
                        void dispatch(
                            updateBudgetLine({
                                budgetLine: {
                                    id: budgetLine?.id,
                                    label: values.label,
                                    amountHt: values.amountHt ? Number(values.amountHt) : 0,
                                },
                                operationId: Number(operationId),
                                budgetId: Number(budgetId),
                            }),
                        );
                        actions.setSubmitting(false);
                        setIsPending(true);
                    }}
                    enableReinitialize
                >
                    {(props: FormikProps<Partial<IBudgetLineFormData>>) => (
                        <Form style={{ width: '100%' }}>
                            <Content isEditMode={isEditMode}>
                                <Tooltip
                                    content={
                                        budgetLine?.comment && !isEditMode ? budgetLine.comment : ''
                                    }
                                    shouldRenderGlobalStyle
                                    fullWidth
                                >
                                    <StyledTableCol flex="1">
                                        <>
                                            {hasRightToEditBudgets &&
                                                isEditMode &&
                                                provided &&
                                                snapshot && (
                                                    <DragHandle
                                                        {...provided.dragHandleProps}
                                                        {...snapshot}
                                                        src={handle}
                                                    />
                                                )}
                                            {!isEditMode && (
                                                <Text
                                                    type="H200"
                                                    style={{
                                                        margin: '0 0.5rem 0 0',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    color={colors.blue.B400}
                                                >
                                                    {budgetLine?.index}
                                                </Text>
                                            )}
                                            {isEditMode &&
                                                budgetLine?.isParent &&
                                                getExpandGroupLineIcon()}
                                            <PermissionAwareText
                                                marginTop="0"
                                                marginBottom="0"
                                                editable={hasRightToEditBudgets && isEditMode}
                                                permissionsRequired={[permissionNeeded]}
                                                name="label"
                                                type="text"
                                                placeholder={t('budget.label')}
                                                data-testid="label"
                                                errorTestId="errorLabel"
                                                onBlur={() => {
                                                    if (props.dirty) {
                                                        props.handleSubmit();
                                                    }
                                                }}
                                                shouldRenderGlobalStyleForTooltip={false}
                                                leftComponent={
                                                    <Text
                                                        style={{
                                                            margin: '0',
                                                            paddingTop: '0.3rem',
                                                            flex: '1',
                                                        }}
                                                        type="H400"
                                                        color={colors.blue.B400}
                                                        size="0.88rem"
                                                    >
                                                        {budgetLine?.index}
                                                    </Text>
                                                }
                                                leftComponentPadding={getInputPadding()}
                                                noMarginTop
                                            />
                                        </>
                                    </StyledTableCol>
                                </Tooltip>
                                <TableCol justifyContent="flex-end" width="7vw">
                                    <TypeOfLine isEditMode={isEditMode}>{symbols}</TypeOfLine>
                                </TableCol>
                                <TableCol
                                    justifyContent="flex-start"
                                    width="10vw"
                                    style={{ paddingLeft: '3vw' }}
                                >
                                    {budgetLine?.budgetLineCalculationReference?.index && (
                                        <Text
                                            size="0.875rem"
                                            color={colors.neutral.N300}
                                            style={{ margin: '0' }}
                                            data-testid="calculatedFrom"
                                        >
                                            {`${t('budget.line')} ${
                                                budgetLine.budgetLineCalculationReference.index
                                            }`}
                                        </Text>
                                    )}
                                </TableCol>
                                <TableCol justifyContent="flex-end" width="13vw">
                                    <PermissionAwareText
                                        marginTop="0"
                                        marginBottom="0"
                                        editable={
                                            hasRightToEditBudgets &&
                                            isEditMode &&
                                            !budgetLine?.isCalculated &&
                                            !budgetLine?.isParent
                                        }
                                        permissionsRequired={[permissionNeeded]}
                                        name="amountHt"
                                        type="text"
                                        placeholder={t('budget.amountHt')}
                                        data-testid="amountHt"
                                        errorTestId="errorAmountHt"
                                        onBlur={(e) => {
                                            if (props.dirty) {
                                                props.handleSubmit();
                                            }
                                        }}
                                        displayStyle={{ textAlign: 'right' }}
                                        numberType="amount"
                                        noMarginTop
                                    />
                                </TableCol>
                                <TableCol justifyContent="center" width="6vw">
                                    <Text style={{ margin: '0' }} data-testid="taxRate" isNumeral>
                                        {getTvaCodePercentage(budgetLine?.tvaCodeId)}
                                    </Text>
                                </TableCol>
                                <TableCol justifyContent="flex-end" width="13vw">
                                    <Text style={{ margin: '0' }} data-testid="amountTtc" isNumeral>
                                        {budgetLine?.isParent
                                            ? CurrencyFormatter.format(budgetLine.amountTtc)
                                            : CurrencyFormatter.format(
                                                  calculateTtcAmount(
                                                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
                                                      Number(props.values.amountHt) ?? 0,
                                                      budgetLine?.tvaCodeId ?? 1,
                                                  ),
                                              )}
                                    </Text>
                                </TableCol>
                                {budget?.status === 'approved' ? (
                                    <TableCol justifyContent="space-between" width="15vw">
                                        <PercentageBadge
                                            value={calculateAmountRatio(
                                                budgetLine?.engagedAmountTtc,
                                                budgetLine?.amountTtc,
                                            )}
                                            style={{
                                                margin: '0 1rem',
                                            }}
                                        />
                                        <Text
                                            style={{ margin: '0' }}
                                            color={colors.neutral.N300}
                                            data-testid="engagedTtc"
                                            isNumeral
                                        >
                                            {CurrencyFormatter.format(
                                                budgetLine?.engagedAmountTtc ?? 0,
                                            )}
                                        </Text>
                                    </TableCol>
                                ) : null}
                            </Content>
                        </Form>
                    )}
                </Formik>
                {modalOptions && (
                    <Modal
                        isOpen={modalOptions.text !== ''}
                        onCloseButtonPressed={() => setModalOptions(null)}
                        buttons={[
                            {
                                text: t('general.cancel'),
                                aspect: 'secondary',
                                'data-testid': 'cancelDelete',
                                onClick() {
                                    setModalOptions(null);
                                },
                            },
                            {
                                text: t('general.confirm'),
                                aspect: 'primary',
                                'data-testid': 'confirm',
                                onClick: modalOptions.callback,
                            },
                        ]}
                        title={t('budget.deleteMultipleLines')}
                        size="small"
                        iconName="Warning"
                        iconColor={colors.yellow.Y400}
                        shouldRenderGlobalStyle={false}
                    >
                        <Text style={{ margin: '0 0 1rem 0' }}>{t(modalOptions.text)}</Text>
                    </Modal>
                )}
                {hasRightToEditBudgets && isEditMode && (
                    <Popover renderGlobalStyle={false}>
                        <ContextualMenuIcon
                            name="MoreHoriz"
                            data-testid="budgetLinePopOverOptions"
                        />
                        <Elevation elevation="default">
                            <PopoverContent>
                                {!budgetLine?.isParent && (
                                    <PopoverItem
                                        className={Classes.POPOVER_DISMISS}
                                        onClick={() => {
                                            void dispatch(
                                                convertToGroupBudgetLine({
                                                    id,
                                                    budgetId: Number(budgetId),
                                                    operationId: Number(operationId),
                                                    previousParentId: Number(budgetLine?.parentId),
                                                }),
                                            );
                                        }}
                                        style={{
                                            margin: '0',
                                            padding: '0.5rem 1.125rem 0.5rem 1.125rem',
                                        }}
                                        first
                                    >
                                        {t('budget.convertToGroupLine')}
                                    </PopoverItem>
                                )}
                                {/* TODO: Disable modify for now since it opens a modal for adding a new line to the group */}
                                {!budgetLine?.isParent && (
                                    <PopoverItem
                                        className={Classes.POPOVER_DISMISS}
                                        onClick={() => {
                                            setIsModalOpen(true);
                                        }}
                                        style={{
                                            margin: '0',
                                            padding: '0.5rem 1.125rem 0.5rem 1.125rem',
                                        }}
                                        data-testid="modifyLine"
                                    >
                                        {t('budget.modifyLine')}
                                    </PopoverItem>
                                )}
                                <PopoverItem
                                    className={Classes.POPOVER_DISMISS}
                                    onClick={async () => {
                                        await modalManager(budgetLine, () => {
                                            void dispatch(
                                                deleteBudgetLine({
                                                    id,
                                                    budgetId: Number(budgetId),
                                                    operationId: Number(operationId),
                                                }),
                                            );
                                        });
                                    }}
                                    style={{
                                        margin: '0',
                                        padding: '0.5rem 1.125rem 0.5rem 1.125rem',
                                    }}
                                    last
                                >
                                    {t('budget.deleteLine')}
                                </PopoverItem>
                            </PopoverContent>
                        </Elevation>
                    </Popover>
                )}
                {hasRightToEditBudgets && isEditMode && budgetLine?.isParent && (
                    <AddLineIcon onClick={handleAddNewLineToGroupClick}>
                        <Icon
                            name="Add"
                            color={colors.other.white}
                            onClick={handleAddNewLineToGroupClick}
                        />
                    </AddLineIcon>
                )}
            </Line>
            {budgetLine &&
            (budgetLineHasSimulatedPriceVariations ||
                budgetLineIsParentAndHasNonNullVariationsAmounts) ? (
                <ExpandedContent isEditMode={isEditMode} indexLevel={getIndexLevel()}>
                    <CollapsableLine
                        isEditMode={isEditMode}
                        data={{
                            headerLine: {
                                label: t('engagement.variation'),
                                cells: [
                                    {
                                        openContentValue: CurrencyFormatter.format(
                                            budgetLine.variationsAmountHt,
                                        ),
                                        closedContentValue: CurrencyFormatter.format(
                                            budgetLine.variationsAmountHt,
                                        ),
                                        width: '10vw',
                                    },
                                    {
                                        width: '6vw',
                                    },
                                    {
                                        openContentValue: CurrencyFormatter.format(
                                            budgetLine.variationsAmountTtc,
                                        ),
                                        closedContentValue: CurrencyFormatter.format(
                                            budgetLine.variationsAmountTtc,
                                        ),
                                        width: '13vw',
                                    },
                                    budget?.status === 'approved'
                                        ? {
                                              openContentValue: CurrencyFormatter.format(
                                                  budgetLine.engagedVariationsAmountTtc,
                                              ),
                                              closedContentValue: CurrencyFormatter.format(
                                                  budgetLine.engagedVariationsAmountTtc,
                                              ),
                                              percentage: calculateAmountRatio(
                                                  budgetLine.engagedVariationsAmountTtc,
                                                  budgetLine.variationsAmountTtc,
                                              ),

                                              width: '15vw',
                                              textColor: colors.neutral.N300,
                                          }
                                        : null,
                                ],
                            },
                            subLines: [
                                {
                                    label: t('engagement.actualisation'),
                                    cells: [
                                        {
                                            value: CurrencyFormatter.format(
                                                budgetLine.actualisationsAmountHt,
                                            ),
                                            width: '10vw',
                                        },
                                        {
                                            value: '',
                                            width: '6vw',
                                        },
                                        {
                                            value: CurrencyFormatter.format(
                                                budgetLine.actualisationsAmountTtc,
                                            ),
                                            width: '13vw',
                                        },
                                        budget?.status === 'approved'
                                            ? {
                                                  value: CurrencyFormatter.format(
                                                      budgetLine.engagedActualisationsAmountTtc,
                                                  ),
                                                  percentage: calculateAmountRatio(
                                                      budgetLine.engagedActualisationsAmountTtc,
                                                      budgetLine.actualisationsAmountTtc,
                                                  ),

                                                  width: '15vw',
                                                  textColor: colors.neutral.N300,
                                              }
                                            : null,
                                    ],
                                },
                                {
                                    label: t('engagement.revision'),
                                    cells: [
                                        {
                                            value: CurrencyFormatter.format(
                                                budgetLine.revisionsAmountHt,
                                            ),
                                            width: '10vw',
                                        },
                                        {
                                            value: '',
                                            width: '6vw',
                                        },
                                        {
                                            value: CurrencyFormatter.format(
                                                budgetLine.revisionsAmountTtc,
                                            ),
                                            width: '13vw',
                                        },
                                        budget?.status === 'approved'
                                            ? {
                                                  value: CurrencyFormatter.format(
                                                      budgetLine.engagedRevisionsAmountTtc,
                                                  ),
                                                  percentage: calculateAmountRatio(
                                                      budgetLine.engagedRevisionsAmountTtc,
                                                      budgetLine.revisionsAmountTtc,
                                                  ),

                                                  width: '15vw',
                                                  textColor: colors.neutral.N300,
                                              }
                                            : null,
                                    ],
                                },
                            ],
                        }}
                    />
                </ExpandedContent>
            ) : null}
        </>
    );

    let line;
    if (onDragEnd) {
        line = (
            <Draggable
                draggableId={String(budgetLine?.id ?? 'none')}
                index={index}
                isDragDisabled={!hasRightToEditBudgets || !budgetIsEditable}
            >
                {(provided, snapshot) => (
                    <DraggableItem
                        ref={provided.innerRef}
                        isEditMode={isEditMode}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        {...snapshot}
                        data-testid="BudgetLine"
                        isDraggingOver={false} // TODO : identify how to toggle this
                    >
                        {getLine(provided, snapshot)}
                    </DraggableItem>
                )}
            </Draggable>
        );
    } else {
        line = <Item isEditMode={isEditMode}>{getLine()}</Item>;
    }
    let elevationType: 'none' | 'default' | 'withoutHover' = 'none';
    if (!isEditMode && budgetLine?.isParent) {
        elevationType = 'withoutHover';
    } else if (!isEditMode && !budgetLine?.isParent) {
        elevationType = 'default';
    }

    let lineContent: React.ReactNode = null;
    if (showChildren || !budgetLine?.parentId) {
        lineContent = (
            <LineIndex
                elevation={elevationType}
                isEditMode={isEditMode}
                indexLevel={getIndexLevel()}
                style={{ borderRadius: styles.borderRadiusSmall }}
            >
                {!isEditMode && budgetLine?.isParent && getExpandGroupLineIcon()}
                {line}
                {isModalOpen && (
                    <BudgetLineModal
                        isPending={isPending}
                        setIsPending={setIsPending}
                        operationId={Number(operationId)}
                        budgetId={Number(budgetId)}
                        getTvaCodeDisplayLabel={getTvaCodeDisplayLabel}
                        calculateTvaAmount={calculateTvaAmount}
                        calculateTtcAmount={calculateTtcAmount}
                        taxOptions={taxOptions}
                        nextWBSIndex={nextWBSIndex}
                        permissionNeeded={permissionNeeded}
                        hasRightToEdit={hasRightToEditBudgets}
                        budgetLine={budgetLine}
                        setIsModalOpen={setIsModalOpen}
                        indexPadding={getInputPadding(budgetLine?.isParent)}
                        isEditMode={isEditMode}
                    />
                )}
            </LineIndex>
        );

        return (
            <div>
                {lineContent}
                {childrenIds.length > 0 && (
                    <Droppable
                        droppableId={String(budgetLine?.id ?? 'none')}
                        isDropDisabled={!hasRightToEditBudgets}
                        isCombineEnabled
                    >
                        {(droppableProvided, droppableSnapshot) => (
                            <div
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                            >
                                {expandedLineIds?.includes(id) || budgetLineConvertedToGroup
                                    ? childrenIds.map((childId: number, mapIndex: number) => (
                                          <BudgetLine
                                              key={`line-${childId}`}
                                              id={childId}
                                              index={mapIndex}
                                              isEditMode={isEditMode}
                                              onDragEnd={onDragEnd}
                                              isDraggingOver={droppableSnapshot.isDraggingOver}
                                              expandedLineIds={expandedLineIds}
                                              setExpandedLineIds={setExpandedLineIds}
                                              showChildren
                                          />
                                      ))
                                    : null}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                )}
            </div>
        );
    }

    return null;
};

export default BudgetLine;
