import type { SyntheticEvent } from 'react';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { VictoryLine, VictoryScatter, VictoryArea } from 'victory';
import throttle from 'lodash.throttle';

// See https://github.com/FormidableLabs/victory/issues/279#issuecomment-245935323
import VictoryChart from '../../components/IEFriendlyVictoryChart';

const Container = styled.section`
    display: flex;
    flex-direction: column;
`;

const DisplayProportions = styled.p`
    padding: 3rem;
`;

let startY = 0;

type CurveFormulaObject = {
    type: 'curve';
    xStart: number;
    yStart: number;
    dx1: number;
    dy1: number;
    dx2: number;
    dy2: number;
    xEnd: number;
    yEnd: number;
    x: (t: number, xStart: number, dx1: number, dx2: number, xEnd: number) => number;
    y: (t: number, yStart: number, dy1: number, dy2: number, yEnd: number) => number;
};

function discretize(formula: CurveFormulaObject, start: number, end: number, step?: number) {
    const points = [];
    step = step ?? 0.01;
    for (let t = start; t < end; t += step) {
        points.push({
            x: formula.x(t + step / 2, formula.xStart, formula.dx1, formula.dx2, formula.xEnd),
            y: formula.y(t + step / 2, formula.yStart, formula.dy1, formula.dy2, formula.yEnd),
        });
    }
    return points;
}

type Point = {
    x: number;
    y: number;
};

function calculateArea(points: Point[]) {
    let area = 0;
    for (let i = 1; i < points.length; i++) {
        const currentPoint = points[i];
        const previousPoint = points[i - 1];
        // area of a triangle = 0.5 * base * height
        const height = Math.abs(currentPoint.y - previousPoint.y);
        const base = Math.abs(currentPoint.x - previousPoint.x);
        area += 0.5 * base * height;
        // total area of that unit of the curve is triangleArea (^) + rectangle area (v)
        area += Math.min(previousPoint.y, currentPoint.y) * base;
        //  /|    |\
        // | |    | |
        // | |    | |
        // |_| or |_|
    }
    return area;
}

const DEBUG_DISCRETIZATION = false;

const SpendingSchema: React.FunctionComponent = () => {
    const [dataPoint1Y, setDataPoint1Y] = useState(20);
    const [dataPoint2Y, setDataPoint2Y] = useState(20);
    const [isDragging, setIsDragging] = useState<number | null>(null);
    const [controlLine, setControlLine] = useState<Array<{ x: number; y: number }>>([]);
    const [areaPart1, setAreaPart1] = useState<number>(0);
    const [areaPart2, setAreaPart2] = useState<number>(0);
    const [areaPart3, setAreaPart3] = useState<number>(0);
    const [formulasForDebug, setFormulas] = useState<CurveFormulaObject[]>([]);

    const throttledSetDataPoint1Y = useRef(throttle(setDataPoint1Y, 25));
    const throttledSetDataPoint2Y = useRef(throttle(setDataPoint2Y, 25));
    const calculateAreas = () => {
        /* 🔥🔥🔥 Only way found to get the good curve is through it's position in the DOM 🔥🔥🔥 */
        const curve = document.querySelector('svg g:nth-child(3) path')?.getAttribute('d');
        const segments = curve?.trim().split('C');

        if (segments && segments.length > 0) {
            // M x1, y1
            const startCommand = segments.splice(0, 1)[0];
            let coords = startCommand.trim().split('M');

            if (coords[1].includes(',')) {
                coords = coords[1].trim().split(',');
            } else {
                coords = coords[1].trim().split(' ');
            }

            const startPoint = {
                x: Number(coords[0]),
                y: Number(coords[1]),
            };

            const normalizeX = (coord: string) => Number(coord) - startPoint.x;
            const normalizeY = (coord: string) =>
                // -1 to invert Y axis "direction" (svg draws y being bigger as you go down)
                -1 * (Number(coord) - startPoint.y);
            const curveFormulas = segments.reduce(
                (formulas: CurveFormulaObject[], item: string, index) => {
                    // item must be of shape (C) dx1, dy1 dx2, dy2 xEnd, yEnd
                    let split = item.split('Z');
                    if (split[0].includes(',')) {
                        split = split[0].trim().split(',');
                    } else {
                        split = split[0].trim().split(' ');
                    }

                    const formulaObject: CurveFormulaObject = {
                        type: 'curve',
                        xStart: formulas.length === 0 ? 0 : formulas[formulas.length - 1].xEnd,
                        yStart: formulas.length === 0 ? 0 : formulas[formulas.length - 1].yEnd,
                        dx1: normalizeX(split[0]),
                        dy1: normalizeY(split[1]),
                        dx2: normalizeX(split[2]),
                        dy2: normalizeY(split[3]),
                        xEnd: normalizeX(split[4]),
                        yEnd: normalizeY(split[5]),
                        x(t: number, xStart: number, dx1: number, dx2: number, xEnd: number) {
                            return (
                                (1 - t) ** 3 * xStart +
                                (1 - t) ** 2 * 3 * t * dx1 +
                                (1 - t) * 3 * t ** 2 * dx2 +
                                t ** 3 * xEnd
                            );
                        },
                        y(t: number, yStart: number, dy1: number, dy2: number, yEnd: number) {
                            return (
                                (1 - t) ** 3 * yStart +
                                (1 - t) ** 2 * 3 * t * dy1 +
                                (1 - t) * 3 * t ** 2 * dy2 +
                                t ** 3 * yEnd
                            );
                        },
                    };

                    formulas.push(formulaObject);

                    setFormulas(formulas);

                    return formulas;
                },
                [],
            );
            const pointsByCurve = curveFormulas.map((formula) => discretize(formula, 0, 1));
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
            if (DEBUG_DISCRETIZATION) {
                setControlLine(
                    pointsByCurve.reduce((result, pointsOfCurve) => result.concat(pointsOfCurve)),
                );
            }
            const areas = pointsByCurve.map((points) => calculateArea(points));
            const sumAreas = areas.reduce((total, area) => total + area);
            setAreaPart1((areas[0] / sumAreas) * 100);
            setAreaPart2((areas[1] / sumAreas) * 100);
            setAreaPart3((areas[2] / sumAreas) * 100);
        }
    };

    useEffect(calculateAreas, []);

    const data = [
        { x: 1, y: 10 },
        { x: 2, y: dataPoint1Y },
        { x: 3, y: dataPoint2Y },
        { x: 4, y: 10 },
    ];
    const fixedData = [
        { x: 0, y: 10 },
        { x: 1, y: 10 },
        { x: 2, y: 10 },
        { x: 3, y: 10 },
        { x: 4, y: 10 },
        { x: 5, y: 10 },
    ];

    return (
        <>
            <Helmet>
                <title>Schémas de dépenses</title>
            </Helmet>
            <Container
                onMouseUp={() => {
                    setIsDragging(null);
                    startY = 0;
                    calculateAreas();
                }}
                onMouseMove={(event) => {
                    if (isDragging) {
                        const diffY = (event.nativeEvent.y - startY) / startY;
                        if (isDragging === 1) {
                            throttledSetDataPoint1Y.current(
                                Math.max(Math.min(dataPoint1Y - diffY, 30), 10),
                            );
                            throttledSetDataPoint2Y.current(
                                Math.max(Math.min(dataPoint2Y + diffY, 30), 10),
                            );
                        } else if (isDragging === 2) {
                            throttledSetDataPoint1Y.current(
                                Math.max(Math.min(dataPoint1Y + diffY, 30), 10),
                            );
                            throttledSetDataPoint2Y.current(
                                Math.max(Math.min(dataPoint2Y - diffY, 30), 10),
                            );
                        }
                    }
                }}
            >
                <DisplayProportions>
                    1ère période : {areaPart1.toFixed(2)}% / 2ème période : {areaPart2.toFixed(2)}%
                    / 3ème période : {areaPart3.toFixed(2)}%
                </DisplayProportions>
                <DisplayProportions>
                    {formulasForDebug.map((formula) => (
                        <span key={`${formula.xStart}-${formula.yStart}`}>
                            {JSON.stringify(formula)}
                        </span>
                    ))}
                </DisplayProportions>
                <VictoryChart maxDomain={{ y: 30 }} height={500} width={1000}>
                    <VictoryArea
                        interpolation="monotoneX"
                        style={{ data: { fill: '#FF3333' } }}
                        data={data}
                    />
                    <VictoryArea style={{ data: { fill: '#3333FF' } }} data={fixedData} />
                    {/* 🔥🔥🔥 Be very careful if you change the position of the curve, it will impact the calculation 🔥🔥🔥 */}
                    <VictoryLine interpolation="monotoneX" data={data} />
                    {/* 🔥🔥🔥 Be very careful if you change the position of the curve, it will impact the calculation 🔥🔥🔥 */}
                    <VictoryScatter
                        style={{ data: { fill: 'black' } }}
                        size={4}
                        data={data}
                        events={[
                            {
                                target: 'data',
                                eventHandlers: {
                                    onMouseDown(event) {
                                        return [
                                            {
                                                target: 'data',
                                                mutation(prop) {
                                                    setIsDragging(prop.index);
                                                    const evt = event as SyntheticEvent<
                                                        MouseEvent,
                                                        MouseEvent
                                                    >;
                                                    startY = evt.nativeEvent.y;

                                                    return {};
                                                },
                                            },
                                        ];
                                    },
                                    onMouseUp(event) {
                                        return [
                                            {
                                                target: 'data',
                                                mutation() {
                                                    setIsDragging(null);
                                                    return {};
                                                },
                                            },
                                        ];
                                    },
                                },
                            },
                        ]}
                    />
                </VictoryChart>
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary */}
                {DEBUG_DISCRETIZATION && (
                    <VictoryChart height={500} width={1000}>
                        <VictoryLine data={controlLine} style={{ data: { fill: '#FF7777' } }} />
                    </VictoryChart>
                )}
            </Container>
        </>
    );
};

export default SpendingSchema;
