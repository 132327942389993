import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

type Props = {
    translationKey: string;
};

const BoldSpan = styled.span`
    font-weight: bold;
`;

const MarkAsBold: React.FC<Props> = ({ translationKey }) => {
    const { t } = useTranslation();
    const translatedString = t(translationKey);
    const regex = /\*(.*?)\*/g;

    const outputComponent: Array<JSX.Element | string> = [];
    let result = regex.exec(translatedString);
    let leftToTreat;
    let index = 0;

    while (result) {
        if (result[0] && result[1]) {
            const stringToBeReplaced = result[0];
            const rawMatchedString = result[1];
            // We split on the string to replace so we can isolate the part "before the regexp match"
            // The "regexp match"
            // And the part "after the regexp match"
            let translatedStringInParts;
            // If it is the first time in the loop, use the whole string as a reference
            if (!leftToTreat) {
                translatedStringInParts = translatedString.split(stringToBeReplaced);
                // Otherwise, only the "leftovers"
            } else {
                translatedStringInParts = leftToTreat.split(stringToBeReplaced);
            }
            // First part should be ok, as already treated by previous loop
            outputComponent.push(translatedStringInParts[0]);
            // Second part, we'll put the captured string surrounded by a `BoldSpan` component
            outputComponent.push(<BoldSpan key={`bold-${index}`}>{rawMatchedString}</BoldSpan>);
            // Third part might still be treated in next loop
            leftToTreat = translatedStringInParts[1];
        }
        index++;

        if (leftToTreat) {
            result = regex.exec(translatedString);
        } else {
            result = null;
        }
    }

    // Once we are done treating the regexp, if there is something left in "leftToTreat", we push it
    if (leftToTreat) {
        outputComponent.push(leftToTreat);
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment -- in wait for a better option
    return <>{outputComponent}</>;
};

export default MarkAsBold;
