import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Permission } from '../slices/authSlice';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import type { Site, ISiteFormData } from '../slices/siteSlice';
import type { Operation, OperationFormData } from '../slices/operationSlice';

// Components
import Address from './Address';
import type { ColumnSize } from './GridCol';
import GridCol from './GridCol';
import PermissionAwareText from './PermissionAwareText';

type Props = {
    addressLabelTranslationKey?: string;
    editable?: boolean;
    permissionsRequired: Permission[];
    'data-testid'?: string;
    data: Site | ISiteFormData | Operation | OperationFormData | undefined; // add other applicable data ex. Operation, OperationFormData(when creating a new one)
    colSizes?: ColumnSize[];
    fieldsToUse: Array<{ name: string; isOptional: boolean }>;
    hasTooltip?: boolean;
};

const PermissionAwareAddress: React.FC<Props> = ({
    addressLabelTranslationKey,
    editable,
    permissionsRequired,
    data,
    colSizes,
    fieldsToUse = [
        { name: 'label', isOptional: false },
        { name: 'constructionSiteName', isOptional: false },
        { name: 'address1', isOptional: false },
        { name: 'address2', isOptional: false },
        { name: 'postalCode', isOptional: false },
        { name: 'city', isOptional: false },
        { name: 'country', isOptional: false },
    ],
    hasTooltip = false,
    ...props
}) => {
    const { t } = useTranslation();
    const hasRightToEdit = usePermissionsCheck(permissionsRequired);

    let address = `-`;
    if (data?.address1 && data.address2) {
        address = `${data.address1}, ${data.address2}`;
    } else if (data?.address1 || data?.address2) {
        address = String(data.address1 ?? data.address2);
    }

    // Viewable Data
    const addressViewableData = {
        [addressLabelTranslationKey ? t(addressLabelTranslationKey) : t('address.address')]:
            address,
        [t('address.postalCode')]: data?.postalCode ?? '-',
        [t('address.city')]: data?.city ?? '-',
        [t('address.country')]: data?.country ?? '-',
    };

    const searchIsOptional = (nameKey: string) => {
        for (let i = 0; i < fieldsToUse.length; i++) {
            if (fieldsToUse[i].name === nameKey) {
                return fieldsToUse[i].isOptional;
            }
        }
    };

    return hasRightToEdit && editable ? (
        <>
            <GridCol defaultScreen={6} smallScreen={6}>
                {fieldsToUse.find((item) => item.name === 'label') && (
                    <PermissionAwareText
                        marginTop="0"
                        permissionsRequired={permissionsRequired}
                        label={t('address.label')}
                        name="label"
                        type="text"
                        placeholder={t('address.label')}
                        data-testid="addressLabel"
                        errorTestId="errorAddressLabel"
                        isOptional={searchIsOptional('label')}
                        whiteBackground
                        editable
                    />
                )}
                {fieldsToUse.find((item) => item.name === 'constructionSiteName') && (
                    <PermissionAwareText
                        marginTop="0"
                        permissionsRequired={permissionsRequired}
                        label={t('address.label')}
                        name="constructionSiteName"
                        type="text"
                        placeholder={t('address.label')}
                        data-testid="constructionSiteName"
                        errorTestId="errorSiteNameLabel"
                        isOptional={searchIsOptional('constructionSiteName')}
                        whiteBackground
                        editable
                    />
                )}
                {fieldsToUse.find((item) => item.name === 'address1') && (
                    <PermissionAwareText
                        marginTop="0"
                        permissionsRequired={permissionsRequired}
                        label={t('address.address1')}
                        name="address1"
                        type="text"
                        placeholder={t('address.address1')}
                        data-testid="address1"
                        errorTestId="errorAddress1"
                        isOptional={searchIsOptional('address1')}
                        whiteBackground
                        editable
                    />
                )}
                {fieldsToUse.find((item) => item.name === 'address2') && (
                    <PermissionAwareText
                        marginTop="0"
                        permissionsRequired={permissionsRequired}
                        label={t('address.address2')}
                        name="address2"
                        type="text"
                        placeholder={t('address.address2')}
                        data-testid="address2"
                        errorTestId="errorAddress2"
                        isOptional={searchIsOptional('address2')}
                        whiteBackground
                        editable
                    />
                )}
            </GridCol>
            <GridCol defaultScreen={6} smallScreen={6}>
                {fieldsToUse.find((item) => item.name === 'postalCode') && (
                    <PermissionAwareText
                        marginTop="0"
                        permissionsRequired={permissionsRequired}
                        label={t('address.postalCode')}
                        name="postalCode"
                        type="text"
                        placeholder={t('address.postalCode')}
                        data-testid="postalCode"
                        errorTestId="errorPostalCode"
                        isOptional={searchIsOptional('postalCode')}
                        whiteBackground
                        editable
                    />
                )}
                {fieldsToUse.find((item) => item.name === 'city') && (
                    <PermissionAwareText
                        marginTop="0"
                        permissionsRequired={permissionsRequired}
                        label={t('address.city')}
                        name="city"
                        type="text"
                        placeholder={t('address.city')}
                        data-testid="city"
                        errorTestId="errorCity"
                        isOptional={searchIsOptional('city')}
                        whiteBackground
                        editable
                    />
                )}
                {fieldsToUse.find((item) => item.name === 'country') && (
                    <PermissionAwareText
                        marginTop="0"
                        permissionsRequired={permissionsRequired}
                        label={t('address.country')}
                        name="country"
                        type="text"
                        placeholder={t('address.country')}
                        data-testid="country"
                        errorTestId="errorCountry"
                        isOptional={searchIsOptional('country')}
                        whiteBackground
                        editable
                    />
                )}
            </GridCol>
        </>
    ) : (
        <Address data={addressViewableData} colSizes={colSizes} hasTooltip={hasTooltip} />
    );
};

export default PermissionAwareAddress;
