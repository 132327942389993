import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import type { MaybeElement } from '@blueprintjs/core';
import { AnchorButton, Button, Icon as CustomIcon } from '@blueprintjs/core';
import throttle from 'lodash.throttle';

import { colors } from '../constants/colors';
import type { IconName } from './Icon';
import Icon from './Icon';
import RollingLoader from '../assets/images/loaders/ring-loader.gif';
import Tooltip from './Tooltip';

type ContainerProps = {
    aspect?: 'primary' | 'secondary' | 'dropdown' | 'onlyIcon';
    size?: 'large' | 'medium' | 'small';
    iconName?: IconName;
    hoverColor?: string;
    isPressed?: boolean;
};

const Spinner = styled.img<ContainerProps>`
    position: float;
    width: 1.875rem;
    height: 1.875rem;
    margin: ${(props) => {
        if (props.aspect === 'dropdown') {
            return '-0.12rem -0.12rem -0.12rem 0.5rem';
        } else if (props.aspect === 'onlyIcon') {
            return '-0.375rem';
        } else if (props.size === 'large') {
            return '-0.875rem -0.875rem -0.875rem 0.5rem';
        } else if (props.size === 'medium') {
            return '-0.625rem -0.625rem -0.625rem 0.5rem';
        } else if (props.size === 'small') {
            return '-0.375rem -0.375rem -0.375rem 0.5rem';
        }
    }};
`;

const Container = styled.div<ContainerProps>`
    .bp4-button {
        padding: ${(props) => {
            if (props.aspect === 'dropdown') {
                return '0.12rem 0.5rem 0.12rem 1.25rem';
            } else if (props.aspect === 'onlyIcon') {
                return '0.375rem';
            } else if (props.size === 'large') {
                return '0.875rem 1.25rem';
            } else if (props.size === 'medium') {
                return '0.625rem 1.25rem';
            } else if (props.size === 'small') {
                return '0.375rem 1.405rem';
            } else {
                console.error("value for props size doesn't exist");
            }
        }};
        border-radius: 0.375rem;
        background-color: ${(props) => {
            if (props.aspect === 'primary') {
                return colors.blue.B400;
            } else if (props.aspect === 'dropdown' || props.isPressed) {
                return colors.other.white;
            } else {
                return colors.neutral.N75;
            }
        }};
        color: ${(props) => {
            if (props.aspect === 'primary') {
                return colors.other.white;
            } else if (props.aspect === 'dropdown') {
                return colors.neutral.N500;
            } else {
                return colors.neutral.N400;
            }
        }};
        font-family: Gilroy;
        font-weight: ${(props) => (props.aspect === 'dropdown' ? '500' : '800')};
        font-size: ${(props) => (props.size === 'large' ? '1rem' : '0.875rem')};
        line-height: 1.25rem;
        cursor: pointer;
        margin: auto;
        ${({ aspect, isPressed }) =>
            (aspect === 'dropdown' || isPressed) &&
            `border: 0.0625rem solid ${colors.neutral.N200};`}
        :disabled {
            background-color: ${colors.neutral.N75};
            color: ${colors.neutral.N300};
            :hover {
                background-color: ${colors.neutral.N75};
                color: ${colors.neutral.N300};
            }
        }
        :focus {
            outline-color: ${(props) => {
                if (props.aspect === 'primary') {
                    return colors.blue.B400;
                } else if (props.aspect === 'dropdown') {
                    return 'none';
                } else {
                    return colors.neutral.N75;
                }
            }};
            box-shadow: ${(props) =>
                props.aspect === 'primary' || props.aspect === 'secondary'
                    ? `0 0 0 0.125rem ${colors.other.white}`
                    : 'none'};
        }
        :hover {
            background-color: ${(props) => {
                if (props.aspect === 'primary') {
                    return colors.blue.B500;
                } else if (props.aspect === 'dropdown' || props.isPressed) {
                    return colors.other.white;
                } else {
                    return colors.neutral.N75;
                }
            }};
            color: ${(props) => {
                if (props.hoverColor) {
                    return props.hoverColor;
                } else if (props.aspect === 'primary') {
                    return colors.other.white;
                } else if (props.aspect === 'dropdown') {
                    return colors.neutral.N500;
                } else {
                    return colors.green.G400;
                }
            }};
            .bp4-button-text {
                > svg {
                    color: ${(props) => (props.aspect === 'dropdown' ? colors.green.G400 : '')};
                }
            }
            outline-color: ${(props) => {
                if (props.aspect === 'primary') {
                    return colors.green.G200;
                } else if (props.aspect === 'dropdown') {
                    return 'none';
                } else {
                    return colors.neutral.N75;
                }
            }};
            box-shadow: none;
        }
        :active {
            background-color: ${(props) => {
                if (props.aspect === 'primary') {
                    return colors.blue.B400;
                } else if (props.aspect === 'dropdown') {
                    return colors.other.white;
                } else {
                    return colors.neutral.N75;
                }
            }};
            color: ${(props) => {
                if (props.hoverColor) {
                    return props.hoverColor;
                } else if (props.aspect === 'primary') {
                    return colors.other.white;
                } else if (props.aspect === 'dropdown') {
                    return colors.neutral.N500;
                } else {
                    return colors.neutral.N400;
                }
            }};
            outline-color: ${(props) =>
                props.aspect === 'dropdown' ? 'none' : colors.other.transparent};
            box-shadow: none;
        }
    }
`;

export const checkIfButtonTextIsAReactNode = (text: ButtonProps['text']) =>
    React.isValidElement(text);

type Props = {
    text?: string | React.ReactNode;
    onClick?: () => void;
    'data-testid'?: string;
    disabled?: boolean;
    toggle?: boolean;
    isPressed?: boolean;
    aspect?: ContainerProps['aspect'];
    size?: ContainerProps['size'];
    iconName?: ContainerProps['iconName'];
    type?: 'button' | 'submit' | 'reset' | undefined;
    iconSize?: string;
    throttled?: boolean;
    isLoading?: boolean;
    hoverColor?: string;
    error?: boolean;
    tooltip?: string;
    tooltipWidth?: string;
    bluePrintJsIconName?: MaybeElement;
    bluePrintJsRightIconName?: MaybeElement;
    padding?: string;
    border?: string;
    customSvgIcon?: MaybeElement;
    renderAsAnchorButton?: boolean;
};

export type ButtonProps = Props;

const StyledButton: React.FC<
    Props & ContainerProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
    aspect,
    size = 'medium',
    text,
    onClick,
    style,
    iconName,
    iconSize = '1.875rem',
    toggle,
    isPressed,
    type = 'button',
    throttled,
    isLoading,
    hoverColor,
    error,
    tooltip,
    tooltipWidth,
    bluePrintJsIconName,
    bluePrintJsRightIconName,
    padding,
    border,
    customSvgIcon,
    renderAsAnchorButton = false,
    ...props
}) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    const handleClickThrottled = useMemo(
        () => throttle(() => handleClick(), 1500, { trailing: false }),
        // eslint-disable-next-line react-hooks/exhaustive-deps -- no need to update memo
        [],
    );
    const iconButtonStyle = () => {
        if (isPressed && toggle) {
            return {
                color: colors.neutral.N400,
            };
        } else if (!isPressed && toggle) {
            return {
                color: colors.neutral.N400,
            };
        }
    };

    const buttonIconToRender = (
        <>
            {iconName && !customSvgIcon && !isLoading && (
                <Icon width={iconSize} name={iconName} data-testid="buttonIcon" />
            )}
            {customSvgIcon && !iconName && !isLoading && <CustomIcon icon={customSvgIcon} />}
            {isLoading && (
                <Spinner aspect={aspect} size={size} src={RollingLoader} alt="loading..." />
            )}
        </>
    );

    const content = (
        <Container
            aspect={aspect}
            size={size}
            style={style}
            hoverColor={hoverColor}
            isPressed={isPressed}
        >
            {!renderAsAnchorButton && (
                <Button
                    {...props}
                    text={text}
                    onClick={throttled ? handleClickThrottled : handleClick}
                    style={{
                        padding,
                        border,
                        ...iconButtonStyle(),
                        ...(error ? { color: colors.red.R400 } : {}),
                    }}
                    type={type}
                    icon={bluePrintJsIconName}
                    rightIcon={bluePrintJsRightIconName}
                    disabled={props.disabled ?? isLoading}
                    minimal
                >
                    {buttonIconToRender}
                </Button>
            )}
            {renderAsAnchorButton && (
                <AnchorButton
                    text={text}
                    onClick={throttled ? handleClickThrottled : handleClick}
                    style={{
                        padding,
                        border,
                        background: colors.neutral.N75,
                        ...iconButtonStyle(),
                        ...(error ? { color: colors.red.R400 } : {}),
                    }}
                    icon={bluePrintJsIconName}
                    rightIcon={bluePrintJsRightIconName}
                    disabled={props.disabled ?? isLoading}
                    minimal
                >
                    {buttonIconToRender}
                </AnchorButton>
            )}
        </Container>
    );

    if (tooltip) {
        return (
            <Tooltip content={tooltip} width={tooltipWidth}>
                {content}
            </Tooltip>
        );
    }

    return content;
};

export default StyledButton;
