import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Classes } from '@blueprintjs/core';

import type {
    EcheancierViewMode,
    EcheancierViewModeOption,
    FilterListMode,
} from './EcheancierEngagementModal';
import {
    COL_WIDTHS,
    TableCol,
    FILTER_LIST_MODE_ALL_ECHEANCES,
    FILTER_LIST_MODE_NON_NULL_ECHEANCES,
    ECHEANCIER_YEAR_VIEW,
} from './EcheancierEngagementModal';
import { colors } from '../../constants/colors';
import { styles } from '../../constants/styles';
import type {
    Engagement,
    EcheanceEngagement,
    IEcheancierEngagementFormData,
    EcheancierMode,
} from '../../slices/engagementSlice';
import { MANUAL_ENTRY } from '../../slices/engagementSlice';
import { CurrencyFormatter } from '../../utils/formatters';
import Text from '../Text';
import EngagementInternalNumberBadge from '../EngagementInternalNumberBadge';
import Icon from '../Icon';
import Button from '../Button';
import FormikOptionDropdown from '../dropdown/FormikOptionDropdown';
import Tooltip from '../Tooltip';
import Popover, { PopoverContent, PopoverItem } from '../Popover';
import CustomElevation from '../Elevation';
import { ReactComponent as MagicWand } from '../../assets/images/magic_wand.svg';
import PercentageBadge from '../PercentageBadge';

const InformationContainer = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: ${colors.other.white};
    border-radius: ${styles.borderRadiusSmall};
    padding: 1.125rem 0 0.75rem 0;
    margin-bottom: 0.5rem;
`;

const HeaderTitle = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
`;

const HideDetailsIcon = styled(Icon)`
    margin-top: -0.9375rem;
    cursor: pointer;
`;

const ShowDetailsIcon = styled(Icon)`
    cursor: pointer;
`;

const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const Labels = styled(Line)`
    margin-bottom: 0.25rem;
`;

const LineText = styled(Text)`
    margin: 0;
`;

const Label = styled(LineText)`
    color: ${colors.neutral.N300};
`;

const Difference = styled(LineText)<{
    isValueDifferentThanZero?: boolean;
}>`
    min-width: 6rem;
    text-align: right;
    margin-left: 30px;
    margin-right: 0.75rem;
    ${({ isValueDifferentThanZero }) => isValueDifferentThanZero && `color: ${colors.red.R400};`}
`;

const HeaderLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: ${styles.borderRadiusSmall};
    padding: 0.25rem;
    margin-bottom: 0.25rem;
`;

const NumberInfoIcon = styled(Icon)`
    margin-left: 0.3125rem;
`;

const FilterListButton = styled(Icon)<{ active?: boolean }>`
    ${({ active }) => active && `color: ${colors.green.G400};`}
    cursor: pointer;
`;

const CopySuggestionsToRecalagesButton = styled.div`
    position: absolute;
    right: -1rem;
    cursor: pointer;
`;

const PopoverItemCustomStyle = styled(PopoverItem)<{
    active?: boolean;
}>`
    padding: 0.5rem 0.25rem 0.5rem 1.125rem;

    ${({ active }) =>
        active &&
        `background-color: ${colors.blue.B50}; color: ${colors.green.G500}; pointer-events: none`}
`;

const CheckedIcon = styled(Icon)`
    margin-left: 1rem;
`;

type Props = {
    engagementAndMarketLabels: string;
    editMode: boolean;
    durationInMonths: number;
    engagementAmountHt: Engagement['amountHt'];
    avancementHtRatio: number;
    monthOfLatestEngagementInvoice: IEcheancierEngagementFormData['monthOfLatestEngagementInvoice'];
    cumulatedAvancementHt: IEcheancierEngagementFormData['cumulatedAvancementHt'];
    restToInvoiceHt: Engagement['amountHt'];
    sumOfPrevisionsHt: EcheanceEngagement['amountHt'];
    difference: Engagement['amountHt'];
    shouldDisableDistributeDifferenceButton: boolean;
    onDistributeDifferenceClick: () => void;
    onCopySuggestionsToRecalagesButtonClick: () => void;
    echeancierViewModes: EcheancierViewModeOption[];
    echeancierViewModeOption?: EcheancierViewModeOption;
    onEcheancierViewModeChange: (viewMode: EcheancierViewMode) => void;
    shouldShowFilterListButton: boolean;
    appliedFilterListMode: FilterListMode;
    onFilterListClick: (filterListMode: FilterListMode) => void;
    echeancierMode?: EcheancierMode;
};

const EcheancierEngagementHeader: React.FC<Props> = ({
    engagementAndMarketLabels,
    editMode,
    durationInMonths,
    engagementAmountHt,
    avancementHtRatio,
    monthOfLatestEngagementInvoice,
    cumulatedAvancementHt,
    restToInvoiceHt,
    sumOfPrevisionsHt,
    difference,
    shouldDisableDistributeDifferenceButton,
    onDistributeDifferenceClick,
    onCopySuggestionsToRecalagesButtonClick,
    echeancierViewModes,
    echeancierViewModeOption,
    onEcheancierViewModeChange,
    shouldShowFilterListButton,
    appliedFilterListMode,
    onFilterListClick,
    echeancierMode,
}) => {
    const { t } = useTranslation();

    const [showDetails, setShowDetails] = useState(true);

    const EcheancierEngagementLineHeader = useCallback(
        () => (
            <HeaderLine>
                <TableCol width={COL_WIDTHS[0]}>
                    {shouldShowFilterListButton && (
                        <Popover renderGlobalStyle={false}>
                            <FilterListButton
                                name="FilterList"
                                width="1.125rem"
                                color={colors.neutral.N500}
                                active={
                                    appliedFilterListMode === FILTER_LIST_MODE_NON_NULL_ECHEANCES
                                }
                            />
                            <CustomElevation elevation="default">
                                <PopoverContent>
                                    <PopoverItemCustomStyle
                                        className={Classes.POPOVER_DISMISS}
                                        data-testid="allEcheances"
                                        onClick={() =>
                                            onFilterListClick(FILTER_LIST_MODE_ALL_ECHEANCES)
                                        }
                                        active={
                                            appliedFilterListMode === FILTER_LIST_MODE_ALL_ECHEANCES
                                        }
                                        first
                                    >
                                        {t('echeancierEngagement.allEcheances')}
                                        {appliedFilterListMode ===
                                            FILTER_LIST_MODE_ALL_ECHEANCES && (
                                            <CheckedIcon name="Done" color={colors.green.G400} />
                                        )}
                                    </PopoverItemCustomStyle>
                                    <PopoverItemCustomStyle
                                        className={Classes.POPOVER_DISMISS}
                                        data-testid="nonNullEcheances"
                                        onClick={() =>
                                            onFilterListClick(FILTER_LIST_MODE_NON_NULL_ECHEANCES)
                                        }
                                        active={
                                            appliedFilterListMode ===
                                            FILTER_LIST_MODE_NON_NULL_ECHEANCES
                                        }
                                    >
                                        {t('echeancierEngagement.nonNullEcheances')}
                                        {appliedFilterListMode ===
                                            FILTER_LIST_MODE_NON_NULL_ECHEANCES && (
                                            <CheckedIcon name="Done" color={colors.green.G400} />
                                        )}
                                    </PopoverItemCustomStyle>
                                </PopoverContent>
                            </CustomElevation>
                        </Popover>
                    )}
                </TableCol>
                <TableCol width={COL_WIDTHS[1]} justifyContent="flex-start">
                    <Text type="H400" style={{ margin: 0 }} size="0.6875rem" uppercased>
                        {t('echeancierEngagement.number')}
                    </Text>
                    <Tooltip content={t('echeancierEngagement.numberTooltip')} isOneLine={false}>
                        <NumberInfoIcon name="Info" width="0.8125rem" />
                    </Tooltip>
                </TableCol>
                <TableCol width={COL_WIDTHS[2]}>
                    <Text type="H400" style={{ margin: 0 }} size="0.6875rem" uppercased>
                        {t('echeancierEngagement.date')}
                    </Text>
                </TableCol>
                <TableCol width={COL_WIDTHS[3]} justifyContent="flex-end">
                    <Text type="H400" style={{ margin: 0 }} size="0.6875rem" uppercased>
                        {t('echeancierEngagement.previsionHt')}
                    </Text>
                </TableCol>
                <TableCol width={COL_WIDTHS[4]} justifyContent="flex-end">
                    <Text type="H400" style={{ margin: 0 }} size="0.6875rem" uppercased>
                        %
                    </Text>
                </TableCol>
                <TableCol width={COL_WIDTHS[5]} justifyContent="flex-end">
                    <Text type="H400" style={{ margin: 0 }} size="0.6875rem" uppercased>
                        {t('echeancierEngagement.situation')}
                    </Text>
                </TableCol>
                <TableCol width={COL_WIDTHS[6]} justifyContent="flex-end">
                    <Text type="H400" style={{ margin: 0 }} size="0.6875rem" uppercased>
                        {t('echeancierEngagement.avancementHt')}
                    </Text>
                </TableCol>
                <TableCol
                    width={COL_WIDTHS[7]}
                    justifyContent="flex-end"
                    style={{ position: 'relative' }}
                >
                    {editMode && (
                        <>
                            <Text type="H400" style={{ margin: 0 }} size="0.6875rem" uppercased>
                                {t('echeancierEngagement.suggestionHT')}
                            </Text>
                            {echeancierViewModeOption?.value !== ECHEANCIER_YEAR_VIEW && (
                                <CopySuggestionsToRecalagesButton>
                                    <Tooltip
                                        content={t('echeancierEngagement.copySuggestionToRecalage')}
                                    >
                                        <Icon
                                            data-testid="copySuggestionsToRecalagesButton"
                                            name="ContentCopy"
                                            width="1rem"
                                            onClick={onCopySuggestionsToRecalagesButtonClick}
                                        />
                                    </Tooltip>
                                </CopySuggestionsToRecalagesButton>
                            )}
                        </>
                    )}
                </TableCol>
                <TableCol width={COL_WIDTHS[8]} justifyContent="flex-end">
                    <Text type="H400" style={{ margin: 0 }} size="0.6875rem" uppercased>
                        {editMode && t('echeancierEngagement.recalage')}
                    </Text>
                </TableCol>
                <TableCol width={COL_WIDTHS[9]} justifyContent="flex-end" />
            </HeaderLine>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps -- needed
        [
            editMode,
            shouldShowFilterListButton,
            echeancierViewModeOption?.value,
            appliedFilterListMode,
        ],
    );

    return (
        <>
            <InformationContainer>
                <HeaderTitle>
                    <TableCol width={COL_WIDTHS[0]} justifyContent="flex-end">
                        {showDetails && (
                            <HideDetailsIcon
                                name="Minimize"
                                onClick={() => setShowDetails(false)}
                            />
                        )}
                        {!showDetails && (
                            <ShowDetailsIcon name="Add" onClick={() => setShowDetails(true)} />
                        )}
                    </TableCol>
                    <TableCol>
                        <LineText type="H500">{t('echeancierEngagement.information')}</LineText>
                    </TableCol>
                    <TableCol>
                        <EngagementInternalNumberBadge
                            internalNumber={engagementAndMarketLabels}
                            withTitle={false}
                        />
                    </TableCol>
                    <TableCol flexGrow={1} justifyContent="flex-end">
                        <Label>{t('echeancierEngagement.difference')}</Label>
                        <Difference
                            isValueDifferentThanZero={difference !== 0}
                            data-testid="difference"
                            isNumeral
                        >
                            {CurrencyFormatter.format(difference)}
                        </Difference>
                        {echeancierMode === MANUAL_ENTRY && (
                            <Button
                                onClick={onDistributeDifferenceClick}
                                tooltip={t('echeancierEngagement.magicWand')}
                                tooltipWidth="20rem"
                                disabled={shouldDisableDistributeDifferenceButton}
                                customSvgIcon={<MagicWand />}
                                padding="0"
                                style={
                                    !editMode ||
                                    echeancierViewModeOption?.value === ECHEANCIER_YEAR_VIEW
                                        ? {
                                              visibility: 'hidden',
                                              pointerEvents: 'none',
                                          }
                                        : undefined
                                }
                                throttled
                                renderAsAnchorButton
                            />
                        )}
                    </TableCol>
                    <TableCol width={COL_WIDTHS[9]} justifyContent="flex-end" />
                </HeaderTitle>
                {showDetails && (
                    <>
                        <Labels>
                            <TableCol width={COL_WIDTHS[0]} />
                            <TableCol width={COL_WIDTHS[1]} justifyContent="flex-start">
                                <Label>{t('echeancierEngagement.duration')}</Label>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[2]}>
                                <Label>{t('echeancierEngagement.display')}</Label>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[3]} justifyContent="flex-end">
                                <Label>{t('echeancierEngagement.engagementHt')}</Label>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[4]} justifyContent="flex-end">
                                <Label>{t('echeancierEngagement.avancementPercentage')}</Label>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[5]} justifyContent="flex-end">
                                <Label>{t('echeancierEngagement.lastSituation')}</Label>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[6]} justifyContent="flex-end">
                                <Label>{t('echeancierEngagement.cumulatedAvancement')}</Label>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[7]} justifyContent="flex-end">
                                <Label>
                                    {editMode && t('echeancierEngagement.restToInvoiceHt')}
                                </Label>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[8]} justifyContent="flex-end">
                                <Label>{editMode && t('echeancierEngagement.total')}</Label>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[9]} justifyContent="flex-end" />
                        </Labels>
                        <Line>
                            <TableCol width={COL_WIDTHS[0]} />
                            <TableCol width={COL_WIDTHS[1]} justifyContent="flex-start">
                                <LineText data-testid="durationInMonths">
                                    {durationInMonths}
                                </LineText>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[2]}>
                                <FormikOptionDropdown<EcheancierViewModeOption>
                                    initialNameDiplay={echeancierViewModeOption?.label}
                                    items={echeancierViewModes}
                                    name="view"
                                    marginBottom={false}
                                    buttonPadding="0"
                                    buttonBorder="none"
                                    style={{ marginBottom: '0' }}
                                    handleChange={(viewModeOption) => {
                                        onEcheancierViewModeChange(viewModeOption.value);
                                    }}
                                />
                            </TableCol>
                            <TableCol width={COL_WIDTHS[3]} justifyContent="flex-end">
                                <LineText data-testid="engagementAmountHt" isNumeral>
                                    {CurrencyFormatter.format(engagementAmountHt)}
                                </LineText>
                            </TableCol>
                            <TableCol
                                width={COL_WIDTHS[4]}
                                justifyContent="flex-end"
                                data-testid="avancementHtRatio"
                            >
                                <LineText>
                                    <PercentageBadge value={avancementHtRatio} />
                                </LineText>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[5]} justifyContent="flex-end">
                                <LineText data-testid="monthOfLatestEngagementInvoice">
                                    {monthOfLatestEngagementInvoice !== null &&
                                        format(new Date(monthOfLatestEngagementInvoice), 'MM/yyyy')}
                                </LineText>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[6]} justifyContent="flex-end">
                                <LineText data-testid="cumulatedAvancementHt" isNumeral>
                                    {CurrencyFormatter.format(cumulatedAvancementHt)}
                                </LineText>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[7]} justifyContent="flex-end">
                                <LineText data-testid="restToInvoiceHt" isNumeral>
                                    {editMode && CurrencyFormatter.format(restToInvoiceHt)}
                                </LineText>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[8]} justifyContent="flex-end">
                                <LineText data-testid="sumOfPrevisionsHt" isNumeral>
                                    {editMode && CurrencyFormatter.format(sumOfPrevisionsHt)}
                                </LineText>
                            </TableCol>
                            <TableCol width={COL_WIDTHS[9]} justifyContent="flex-end" />
                        </Line>
                    </>
                )}
            </InformationContainer>
            <EcheancierEngagementLineHeader />
        </>
    );
};

export default EcheancierEngagementHeader;
