import type { ChangeEvent } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import type { Permission } from '../slices/authSlice';
import { selectUser } from '../slices/authSlice';
import type { IUserFormData } from '../slices/userSlice';
import Text from './Text';
import { selectRolesThatCanBeSetOnAnOrganizationByAuthUser } from '../slices/roleSlice';
import type { IOrganization } from '../slices/organizationSlice';
import GridRow from './GridRow';
import GridCol from './GridCol';
import Toggle from './Toggle';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';

const Container = styled.section`
    padding: 0.625rem 1rem;
    display: flex;
    align-items: center;
`;

type Props = {
    organization: IOrganization;
    userRoles: IUserFormData['roles'];
    isEditMode: boolean;
    permissionNeeded: Permission;
    onChange: (roleId: number, checked: boolean, isAdminRole: boolean) => void;
};

const UserRolesOnOrganization: React.FC<Props> = ({
    organization,
    userRoles,
    isEditMode,
    permissionNeeded,
    onChange,
}) => {
    const { t } = useTranslation();

    const rolesHavingAnOrganizationScope = useSelector(
        selectRolesThatCanBeSetOnAnOrganizationByAuthUser(organization),
    );
    const authenticatedUser = useSelector(selectUser);

    const userHasRolesOnHisOrganization = userRoles.some((userRole) =>
        rolesHavingAnOrganizationScope.some(
            (roleHavingAnOrganizationScope) => roleHavingAnOrganizationScope.id === userRole.id,
        ),
    );

    const hasRequiredPermission = usePermissionsCheck([permissionNeeded]);

    const getAuthenticatedUserHighestPrivilegeRoleLevel = (): number => {
        if (authenticatedUser?.roles) {
            const authenticatedUserRolesLevel = authenticatedUser.roles.map((role) => role.level);
            return Math.min(...authenticatedUserRolesLevel);
        }
        return 99;
    };

    return userHasRolesOnHisOrganization || isEditMode ? (
        <GridRow>
            {rolesHavingAnOrganizationScope.map((roleHavingAnOrganizationScope, index) => {
                const correspondingUserRoleIndex = userRoles.findIndex(
                    (userRole) => userRole.id === roleHavingAnOrganizationScope.id,
                );
                const userHasRole = correspondingUserRoleIndex !== -1;
                const isAdminRole =
                    !roleHavingAnOrganizationScope.isCustom &&
                    roleHavingAnOrganizationScope.name === 'ADMIN';
                const authenticatedUserCanGiveRole =
                    getAuthenticatedUserHighestPrivilegeRoleLevel() <=
                    roleHavingAnOrganizationScope.level;
                return isEditMode && hasRequiredPermission ? (
                    <GridCol key={roleHavingAnOrganizationScope.id} defaultScreen={4}>
                        <Container>
                            <Toggle
                                data-testid="userRoleOnOrganizationToggle"
                                disabled={!authenticatedUserCanGiveRole}
                                label={roleHavingAnOrganizationScope.label ?? ''}
                                checked={userHasRole}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    onChange(
                                        roleHavingAnOrganizationScope.id,
                                        e.target.checked,
                                        isAdminRole,
                                    );
                                }}
                                style={{ paddingLeft: 0 }}
                                aspect="large"
                            />
                        </Container>
                    </GridCol>
                ) : (
                    userHasRole && (
                        <GridCol defaultScreen={12}>
                            <Text>{roleHavingAnOrganizationScope.label}</Text>
                        </GridCol>
                    )
                );
            })}
        </GridRow>
    ) : (
        <Text>{t('user.profile.noRoleOnHisOrganization')}</Text>
    );
};

export default UserRolesOnOrganization;
