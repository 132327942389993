import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import Icon from './Icon';

type ContainerProps = {
    rounded?: boolean;
    isLink?: boolean;
    isLeftIcon?: boolean;
};

const Container = styled.div<ContainerProps>`
    color: ${(props) => (props.isLink ? colors.green.G400 : colors.neutral.N400)};
    background: ${colors.neutral.N75};
    font-family: Rubik;
    font-weight: normal;
    display: inline-flex;
    font-size: 0.875rem;
    line-height: 1.3rem;
    border-radius: ${(props) =>
        props.rounded ? styles.borderRadiusMedium : styles.borderRadiusXSmall};

    a {
        line-height: 1.3rem;
    }

    p {
        margin-top: 0.1rem;
        margin-left: ${(props) => (props.isLeftIcon ? '0.3rem' : '0.5rem')};
        margin-right: 0.5rem;
        margin-bottom: 0px;
    }
`;

const LeftIconWrapper = styled.div`
    margin-left: 0.5rem;
`;

const RemoveIconWrapper = styled.div`
    margin-right: 0.5rem;
    cursor: pointer;
`;

type Props = {
    text: string | number;
    rounded?: boolean;
    onRemove?: () => void;
    to?: string;
    leadingIcon?: React.ReactNode;
    style?: CSSProperties;
    'data-testid'?: string;
};

export type TagProps = Props;

const Tag: React.FunctionComponent<Props> = ({
    text,
    rounded = false,
    onRemove,
    to,
    leadingIcon,
    style,
    ...props
}) => (
    <Container
        rounded={rounded}
        isLink={Boolean(to)}
        isLeftIcon={Boolean(leadingIcon)}
        style={style}
        data-testid={props['data-testid'] ?? 'tag'}
    >
        <>
            {leadingIcon && <LeftIconWrapper>{leadingIcon}</LeftIconWrapper>}
            {to ? (
                <Link to={to}>
                    <p>{text}</p>
                </Link>
            ) : (
                <p>{text}</p>
            )}
        </>
        {onRemove && (
            <RemoveIconWrapper>
                <Icon
                    name="Close"
                    width="0.7rem"
                    color={colors.neutral.N500}
                    onClick={onRemove}
                    data-testid="removeTagIcon"
                />
            </RemoveIconWrapper>
        )}
    </Container>
);
export default Tag;
