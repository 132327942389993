import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import GridCol from './GridCol';
import { colors } from '../constants/colors';
import Text from './Text';
import { styles } from '../constants/styles';

const InternalNumberWrapper = styled(GridCol)`
    background-color: ${colors.neutral.N25};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-radius: ${styles.borderRadiusXSmall};
`;

const InternalNumberText = styled(Text)`
    margin: 0.1875rem 0;
`;

const StyledText = styled(Text)`
    margin: 0;
`;

type Props = {
    internalNumber: string;
    withTitle?: boolean;
};

const EngagementInternalNumberBadge: React.FunctionComponent<Props> = ({
    internalNumber,
    withTitle = true,
}) => {
    const { t } = useTranslation();

    return (
        <InternalNumberWrapper>
            {withTitle && (
                <StyledText color={colors.neutral.N300}>
                    {t('engagement.internalNumber')}
                </StyledText>
            )}
            <InternalNumberText type="H500" color={colors.neutral.N500}>
                {internalNumber}
            </InternalNumberText>
        </InternalNumberWrapper>
    );
};

export default EngagementInternalNumberBadge;
