import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import styled from 'styled-components';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';

import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import type { QueryParams } from '../../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../../slices/common';
import type { IUserFormData, User } from '../../slices/userSlice';
import {
    getUsersByOperation,
    selectTotalUsersAffectedToCurrentOperation,
    selectIsUpdateManyUsersFulfilled,
    selectUsersAffectedToCurrentOperationByPage,
    updateManyUsers,
    updateUser,
    selectIsUpdateUserFulfilled,
    selectIsLoading,
} from '../../slices/userSlice';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import MainLayout from '../../components/MainLayout';
import GridRow from '../../components/GridRow';
import GridCol from '../../components/GridCol';
import SearchField from '../../components/SearchField';
import Loader from '../../components/Loader';
import OperationUsersList from '../../components/OperationUsersList';
import EmptyState from '../../components/EmptyState';
import type { ButtonProps } from '../../components/Button';
import Button from '../../components/Button';
import AffectUserToOperationModal from '../../components/AffectUserToOperationModal';
import Pagination from '../../components/Pagination';
import { usePageNumber } from '../../hooks/usePageNumber';
import Icon from '../../components/Icon';
import AffectUserIcon from '../../assets/images/affect_user_icon.svg';
import Avatar from '../../components/Avatar';
import type { IRole } from '../../slices/roleSlice';
import { getRoles, selectRolesHavingAnOperationScope } from '../../slices/roleSlice';
import { getUserFormSchema } from '../userProfile/UserProfile';
import Modal from '../../components/Modal';
import { colors } from '../../constants/colors';
import Text from '../../components/Text';
import { showFlag } from '../../components/Flag';
import {
    getOrganization,
    getOrganizationsByOperation,
    selectOrganization,
    selectTotalOrganizations,
} from '../../slices/organizationSlice';
import { getOperation, selectOperation } from '../../slices/operationSlice';
import { useAppDispatch } from '../../store';
import { getNameAndAvatar } from '../../components/UserList';

const AffectUserIconStyle = styled.img`
    width: 1.125rem;
`;

type Props = {
    headerProps: HeaderProps;
};

const UsersRolesByOperation: React.FunctionComponent<Props> = ({ headerProps }) => {
    const { operationId } = useParams<{ operationId: string }>();
    const location = useLocation();
    const { t } = useTranslation();
    const pageNumber = usePageNumber();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const defaultQueryParams = {
        operationId: Number(operationId),
        page: pageNumber,
        orderBy: '[organization.denomination, profile.firstName, profile.lastName]',
        order: '[asc, asc, asc]',
    };
    const [queryParams, setQueryParams] = useState<QueryParams>(defaultQueryParams);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isAffectUserToOperationModalOpen, setIsAffectUserToOperationModalOpen] = useState(false);
    const [isUpdateManyUsersPending, setIsUpdateManyUsersPending] = useState(false);
    const [isDisaffectingUserPending, setIsDisaffectingUserPending] = useState(false);
    const [isDisaffectUserConfirmationModalOpen, setIsDisaffectUserConfirmationModalOpen] =
        useState<boolean>(false);
    const [userIdToDisaffect, setUserIdToDisaffect] = useState<User['id'] | null>(null);

    const operation = useSelector(selectOperation(Number(operationId)));
    const operationClient = useSelector(selectOrganization(operation?.clientId));
    const isLoading = useSelector(selectIsLoading);
    const users = useSelector(selectUsersAffectedToCurrentOperationByPage(pageNumber));
    const totalUsers = useSelector(selectTotalUsersAffectedToCurrentOperation);
    const totalOrganizations = useSelector(selectTotalOrganizations);
    const rolesHavingAnOperationScope = useSelector(selectRolesHavingAnOperationScope);
    const isUpdateManyUsersFulfilled = useSelector(selectIsUpdateManyUsersFulfilled);
    const isUpdateUserFulfilled = useSelector(selectIsUpdateUserFulfilled);

    const hasRightToEditUsers = usePermissionsCheck([{ code: 'USERS_EDIT', operationId }]);
    const hasRightToAffectUsers = hasRightToEditUsers;
    const hasRightToReadOperationClientRoles = usePermissionsCheck([
        { code: 'ROLES_READ', organizationId: operation?.clientId },
    ]);

    const shouldShowActionMenu = hasRightToEditUsers && !isEditMode;

    useEffect(() => {
        if (!operation && operationId) {
            void dispatch(getOperation(Number(operationId)));
        }
    }, [dispatch, operationId, operation]);

    useEffect(() => {
        if (!operationClient && operation?.clientId) {
            void dispatch(getOrganization(operation.clientId));
        }
    }, [dispatch, operation, operationClient]);

    useEffect(() => {
        void dispatch(getRoles({ operationId: Number(operationId) }));
    }, [dispatch, operationId]);

    useEffect(() => {
        void dispatch(getUsersByOperation(queryParams));
    }, [dispatch, queryParams]);

    useEffect(() => {
        if (isUpdateManyUsersPending && isUpdateManyUsersFulfilled) {
            void dispatch(getUsersByOperation(queryParams));
            setIsUpdateManyUsersPending(false);
        }
    }, [dispatch, isUpdateManyUsersFulfilled, isUpdateManyUsersPending, queryParams]);

    useEffect(() => {
        if (isDisaffectingUserPending && isUpdateUserFulfilled) {
            void dispatch(getUsersByOperation(queryParams));
            setIsDisaffectingUserPending(false);
            setIsDisaffectUserConfirmationModalOpen(false);
            setUserIdToDisaffect(null);
            showFlag(
                'success',
                t('general.success'),
                t('usersByOperation.successfullyDisaffected'),
            );
        }
    }, [dispatch, isDisaffectingUserPending, isUpdateUserFulfilled, queryParams, t]);

    useEffect(() => {
        void dispatch(
            getOrganizationsByOperation({
                page: pageNumber,
                operationId: Number(operationId),
            }),
        );
    }, [dispatch, operationId, pageNumber]);

    const handleSearch = (value: string) => {
        if (value === '') {
            setQueryParams(defaultQueryParams);
        } else {
            setQueryParams({
                ...defaultQueryParams,
                query: value,
                match: 'any',
            });
        }
    };

    const handleDisaffectUserClick = (userId: User['id']) => {
        setIsDisaffectUserConfirmationModalOpen(true);
        setUserIdToDisaffect(userId);
    };

    const disaffectUserFromOperation = (userId: User['id']) => {
        const userToDisaffect = users.find((user) => user.id === userId);
        if (userToDisaffect) {
            const userToUpdate = {
                ...userToDisaffect,
                roles: userToDisaffect.roles
                    ? userToDisaffect.roles.filter(
                          (userRole) => userRole.userRoleOperationId !== Number(operationId),
                      )
                    : [],
            };
            setIsDisaffectingUserPending(true);
            void dispatch(updateUser({ user: userToUpdate, operationId: Number(operationId) }));
        }
    };

    const computeButtonsOutOfProps = (formikProps: FormikProps<FormProps>) => {
        const buttons: ButtonProps[] = [];
        if (hasRightToAffectUsers && !isEditMode) {
            buttons.push({
                'data-testid': 'affectUserButton',
                text: t('operation.affectUser'),
                aspect: 'primary',
                bluePrintJsIconName: (
                    <AffectUserIconStyle
                        src={AffectUserIcon}
                        alt={t('usersByOperation.affectUserAlt')}
                    />
                ),
                onClick: () => setIsAffectUserToOperationModalOpen(true),
            });
        }
        if (hasRightToEditUsers) {
            if (isEditMode) {
                buttons.push({
                    'data-testid': 'cancel',
                    text: t('general.cancel'),
                    aspect: 'primary',
                    onClick() {
                        setIsEditMode(false);
                        formikProps.handleReset();
                    },
                });
                buttons.push({
                    'data-testid': 'submit',
                    text: t('general.submit'),
                    aspect: 'primary',
                    onClick() {
                        formikProps.handleSubmit();
                        setIsEditMode(false);
                    },
                });
            } else {
                buttons.push({
                    'data-testid': 'editUsersRoles',
                    text: t('usersByOperation.editRoles'),
                    aspect: 'primary',
                    bluePrintJsIconName: <Icon name="Edit" width="1.125rem" />,
                    onClick() {
                        formikProps.resetForm();
                        setIsEditMode(true);
                    },
                });
            }
        }
        return buttons;
    };

    const renderCellMap = {
        name: getNameAndAvatar,
        email: (user: User | Partial<User>) => user.email ?? '-',
    };

    const computeColumnSizes = () => {
        const columnSizesOfPermanentColumns = `13% 18% 6% repeat(${rolesHavingAnOperationScope.length}, minmax(13%, 1fr))`;
        if (shouldShowActionMenu) {
            return `${columnSizesOfPermanentColumns} 9%`;
        }
        return columnSizesOfPermanentColumns;
    };

    const userFormSchema = getUserFormSchema(t);
    const usersFormSchema = Yup.array(userFormSchema);

    const usersFormInitialValues = {
        users: users.map((user) => {
            const userFormDataTemp = {
                ...user,
                roles: user.roles ? user.roles : [],
            };
            return userFormDataTemp;
        }),
    };

    type FormProps = { users: IUserFormData[] };

    return (
        <Formik
            initialValues={usersFormInitialValues}
            validationSchema={usersFormSchema}
            onSubmit={(values: FormProps, actions) => {
                void dispatch(
                    updateManyUsers({ users: values.users, operationId: Number(operationId) }),
                );
                setIsUpdateManyUsersPending(true);
                actions.setSubmitting(false);
            }}
            enableReinitialize
        >
            {(formikProps: FormikProps<FormProps>) => {
                const checkIfRoleIsAlreadyAffectedToUserOnOperation = (
                    userId: User['id'],
                    roleId: IRole['id'],
                ): boolean =>
                    formikProps.values.users.some(
                        (user) =>
                            user.id === userId &&
                            user.roles.some(
                                (userRole) =>
                                    userRole.id === roleId &&
                                    userRole.userRoleOperationId === Number(operationId),
                            ),
                    );

                const handleUserRoleClick = (userId: User['id'], roleId: IRole['id']) => {
                    const userIndex = formikProps.values.users.findIndex(
                        (user) => user.id === userId,
                    );
                    const userHasAlreadyUserRole = checkIfRoleIsAlreadyAffectedToUserOnOperation(
                        userId,
                        roleId,
                    );
                    if (userHasAlreadyUserRole) {
                        formikProps.setFieldValue(
                            `users[${userIndex}].roles`,
                            formikProps.values.users[userIndex].roles.filter(
                                (role) =>
                                    role.id !== roleId &&
                                    role.userRoleOperationId &&
                                    role.userRoleOperationId === Number(operationId),
                            ),
                        );
                    } else {
                        formikProps.setFieldValue(`users[${userIndex}].roles`, [
                            ...formikProps.values.users[userIndex].roles,
                            {
                                id: roleId,
                                userRoleOperationId: Number(operationId),
                            },
                        ]);
                    }
                };

                return (
                    <>
                        <MainLayout
                            header={
                                <Header
                                    {...headerProps}
                                    tabs={headerProps.tabs?.map((tab, index) => {
                                        if (index === 0) {
                                            return {
                                                ...tab,
                                                text: `${tab.text} (${totalOrganizations})`,
                                            };
                                        } else if (index === 1) {
                                            return { ...tab, text: `${tab.text} (${totalUsers})` };
                                        }
                                        return tab;
                                    })}
                                    buttons={computeButtonsOutOfProps(formikProps)}
                                    showBackButton
                                />
                            }
                            footer={
                                users.length > 0 && !isEditMode ? (
                                    <Pagination
                                        data-testid="pagination"
                                        initialPage={queryParams.page}
                                        currentPage={queryParams.page}
                                        pageNeighbours={2}
                                        nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                                        nbItemsTotal={totalUsers}
                                        onPageNumberClicked={(page: number) =>
                                            setQueryParams({
                                                ...queryParams,
                                                page,
                                            })
                                        }
                                        pageUrl="roles"
                                    />
                                ) : null
                            }
                            smallContentSidePadding
                        >
                            <GridRow
                                style={{
                                    marginBottom: '1rem',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <GridCol smallScreen={10} defaultScreen={4}>
                                    <SearchField
                                        name="organizationResearch"
                                        data-testid="usersSearch"
                                        onSearch={handleSearch}
                                    />
                                </GridCol>
                                {operation &&
                                    operationClient?.estClient &&
                                    hasRightToReadOperationClientRoles && (
                                        <GridCol
                                            smallScreen={4}
                                            defaultScreen={4}
                                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                                        >
                                            <Button
                                                text={t('usersByOperation.rolesSettings')}
                                                onClick={() =>
                                                    navigate(
                                                        `/organizations/${operation.clientId}/roles`,
                                                        {
                                                            state: { goBackTo: location.pathname },
                                                        },
                                                    )
                                                }
                                            />
                                        </GridCol>
                                    )}
                            </GridRow>
                            <GridRow>
                                <GridCol>
                                    {isLoading ? <Loader /> : null}
                                    {!isLoading && formikProps.values.users.length > 0 && (
                                        <OperationUsersList
                                            operationId={Number(operationId)}
                                            users={formikProps.values.users as User[]}
                                            columnSizes={computeColumnSizes()}
                                            isEditMode={isEditMode}
                                            showActionMenu={shouldShowActionMenu}
                                            operationRoles={rolesHavingAnOperationScope}
                                            onUserRoleClick={handleUserRoleClick}
                                            checkIfRoleIsAlreadyAffectedToUserOnOperation={
                                                checkIfRoleIsAlreadyAffectedToUserOnOperation
                                            }
                                            cellMap={renderCellMap}
                                            onDisaffectUserClick={handleDisaffectUserClick}
                                            isRoleManagementMode
                                        />
                                    )}
                                    {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy */}
                                    {!isLoading && users && users.length === 0 && (
                                        <EmptyState
                                            titleTranslationKey="operation.noUsers"
                                            imageName="User"
                                            button={
                                                hasRightToAffectUsers ? (
                                                    <Button
                                                        onClick={() =>
                                                            setIsAffectUserToOperationModalOpen(
                                                                true,
                                                            )
                                                        }
                                                        text={t('operation.affectUser')}
                                                        aspect="secondary"
                                                        size="medium"
                                                    />
                                                ) : null
                                            }
                                        />
                                    )}
                                </GridCol>
                            </GridRow>
                            {isAffectUserToOperationModalOpen && (
                                <AffectUserToOperationModal
                                    operationId={Number(operationId)}
                                    onModalClosed={() => setIsAffectUserToOperationModalOpen(false)}
                                    stateForNavigation={{
                                        breadcrumbs: headerProps.breadcrumbs,
                                        goBackTo: `${location.pathname}${location.search}`,
                                    }}
                                    userRoutePrefix={`/operations/${operationId}`}
                                />
                            )}
                        </MainLayout>
                        {isDisaffectUserConfirmationModalOpen && (
                            <Modal
                                isOpen={isDisaffectUserConfirmationModalOpen}
                                onCloseButtonPressed={() => {
                                    setIsDisaffectUserConfirmationModalOpen(false);
                                }}
                                buttons={[
                                    {
                                        text: t('general.cancel'),
                                        aspect: 'secondary',
                                        'data-testid': 'cancelDelete',
                                        onClick() {
                                            setIsDisaffectUserConfirmationModalOpen(false);
                                        },
                                    },
                                    {
                                        text: t('general.confirm'),
                                        aspect: 'primary',
                                        'data-testid': 'confirm',
                                        onClick() {
                                            if (userIdToDisaffect) {
                                                disaffectUserFromOperation(userIdToDisaffect);
                                                setIsDisaffectUserConfirmationModalOpen(false);
                                            }
                                        },
                                    },
                                ]}
                                title={t('usersByOperation.disaffectAUser')}
                                size="small"
                                iconName="Warning"
                                iconColor={colors.yellow.Y400}
                                centerTitle
                            >
                                <Text style={{ margin: '0 0 1rem 0' }}>
                                    {t('usersByOperation.confirmDisaffectation')}
                                </Text>
                            </Modal>
                        )}
                    </>
                );
            }}
        </Formik>
    );
};

export default UsersRolesByOperation;
