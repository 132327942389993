import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSelector } from 'react-redux';

import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Modal from './Modal';
import GridRow from './GridRow';
import GridCol from './GridCol';
import type { Permission } from '../slices/authSlice';
import PermissionAwareText from './PermissionAwareText';
import { colors } from '../constants/colors';
import PermissionAwareTextarea from './PermissionAwareTextarea';
import Text from './Text';

import type { Budget, BudgetLabelAndCommentFormData } from '../slices/budgetSlice';

type Props = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    permissionNeeded: Permission[];
    budget: Budget;
    callback: (values: { label: string; comment: string }) => void;
};

const Container = styled.div`
    width: 100%;
`;

const ModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const BudgetTitleAndCommentModal: React.FC<Props> = ({
    isOpen,
    setIsOpen,
    permissionNeeded,
    budget,
    callback,
}) => {
    const { t } = useTranslation();

    const budgetSchema = Yup.object().shape({
        label: Yup.string()
            .required(t('errors.required'))
            .max(60, t('errors.tooLong', { number: 60 })),
        comment: Yup.string()
            .max(512, t('errors.tooLong', { number: 512 }))
            .nullable(),
    });

    const ModalTitle = (
        <ModalHeader>
            <Text type="H700" style={{ marginTop: '0' }}>
                {t('budget.editLabelAndComment')}
            </Text>
        </ModalHeader>
    );

    return (
        <Formik
            initialValues={{
                label: budget.label,
                comment: budget.comment ?? '',
            }}
            validationSchema={budgetSchema}
            onSubmit={(values) => {
                callback(values);
            }}
        >
            {(formikProps: FormikProps<BudgetLabelAndCommentFormData>) => (
                <Modal
                    isOpen={isOpen}
                    onCloseButtonPressed={() => {
                        setIsOpen(false);
                    }}
                    buttons={[
                        {
                            text: t('general.cancel'),
                            aspect: 'secondary',
                            'data-testid': 'cancelDelete',
                            onClick() {
                                setIsOpen(false);
                            },
                        },
                        {
                            text: t('general.submit'),
                            aspect: 'primary',
                            'data-testid': 'submit',
                            async onClick() {
                                await formikProps.validateForm();
                                formikProps.handleSubmit();
                            },
                        },
                    ]}
                    title={ModalTitle}
                    backgroundColor={colors.neutral.N50}
                    specificPadding="0"
                    centerTitle
                    scrollableBody
                >
                    <Container>
                        <GridRow style={{ marginBottom: '1rem' }}>
                            <GridCol smallScreen={10} defaultScreen={12}>
                                <PermissionAwareText
                                    marginTop="0"
                                    permissionsRequired={permissionNeeded}
                                    label={t('budget.label')}
                                    name="label"
                                    type="text"
                                    value={budget.label}
                                    placeholder={t('budget.label')}
                                    data-testid="label"
                                    errorTestId="errorLabel"
                                    noMarginTop
                                    whiteBackground
                                    editable
                                />
                            </GridCol>
                        </GridRow>
                        <GridRow style={{ marginBottom: '1rem' }}>
                            <GridCol smallScreen={12} defaultScreen={12}>
                                <PermissionAwareTextarea
                                    permissionsRequired={permissionNeeded}
                                    label={`${t('budget.comment')} ${t('general.optional')}`}
                                    name="comment"
                                    value={budget.comment ?? ''}
                                    placeholder={t('budget.comment')}
                                    data-testid="edit-comment"
                                    errorTestId="errorComment"
                                    noMarginTop
                                    whiteBackground
                                    editable
                                />
                            </GridCol>
                        </GridRow>
                    </Container>
                </Modal>
            )}
        </Formik>
    );
};

export default BudgetTitleAndCommentModal;
