export const colors = {
    blue: {
        B500: '#0A2C4B',
        B400: '#10406D',
        B300: '#1760A4',
        B200: '#879FB6',
        B100: '#B7C6D3',
        B75: '#CFD9E2',
        B50: '#E9F0FF',
        B25: '#F4F8FF',
    },
    red: {
        R500: '#AC0F48',
        R400: '#CF1156',
        R300: '#DD5889',
        R200: '#E788AB',
        R100: '#F1B8CC',
        R75: '#FAE7EE',
        R50: '#FDF3F7',
        R25: '#FFFAFC',
    },
    yellow: {
        Y500: '#CA6E01',
        Y400: '#FF8B00',
        Y300: '#FFAD4A',
        Y200: '#FFC349',
        Y100: '#FFD54B',
        Y75: '#FFEBA4',
        Y50: '#FFFAE6',
        Y25: '#FFFCF2',
    },
    green: {
        G600: '#12876f',
        G500: '#159E82',
        G400: '#39B398',
        G300: '#20DBB4',
        G200: '#8CDFCE',
        G100: '#BAEBE1',
        G75: '#D1F2EB',
        G50: '#E8F8F5',
        G25: '#F4FCFA',
    },
    purple: {
        P500: '#6D1FEC',
        P400: '#9962F2',
        P300: '#B68FF6',
        P200: '#C8A8FD',
        P100: '#D3BCF9',
        P75: '#EADDFF',
        P50: '#F0E9FD',
        P25: '#F8F4FE',
    },
    teal: {
        T500: '#07778F',
        T400: '#069CBD',
        T300: '#07BAE1',
        T200: '#51CFEA',
        T150: '#9AD8E6',
        T100: '#83DDF0',
        T75: '#B5EAF6',
        T50: '#E6F8FC',
        T25: '#F3FCFD',
    },
    neutral: {
        N600: '#374154',
        N500: '#414F6B',
        N400: '#606F8B',
        N375: '#4B7EAE',
        N350: '#8297BF',
        N300: '#9EA9BD',
        N200: '#C7D0DD',
        N100: '#D4DCE9',
        N75: '#E6ECF6',
        N50: '#F6F8FC',
        N25: '#F9FAFD',
    },
    other: {
        black: '#000000',
        white: '#FFFFFF',
        transparent: 'rgba(0, 0, 0, 0.001)',
        transparentWhite: 'rgba(255, 255, 255, 0.75)',
    },
};
