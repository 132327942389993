import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    getUsers,
    selectUsersByPage,
    selectIsLoading,
    selectTotalUsers,
} from '../../slices/userSlice';
import type { QueryParams } from '../../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../../slices/common';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';

import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import MainLayout from '../../components/MainLayout';
import Pagination from '../../components/Pagination';
import UserList from '../../components/UserList';
import GridCol from '../../components/GridCol';
import GridRow from '../../components/GridRow';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import { usePageNumber } from '../../hooks/usePageNumber';
import SearchField from '../../components/SearchField';
import { useAppDispatch } from '../../store';

type Props = {
    headerProps: HeaderProps;
};

const Users: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const pageNumber = usePageNumber();
    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: pageNumber,
    });
    const users = useSelector(selectUsersByPage(queryParams.page));
    const hasRightToAddUsers = usePermissionsCheck([{ code: 'USERS_ADD' }]);
    const navigate = useNavigate();
    const isLoading = useSelector(selectIsLoading);

    const dispatch = useAppDispatch();
    const totalUsers = useSelector(selectTotalUsers);

    const addUser = () => {
        navigate(`${location.pathname}/new`, {
            state: {
                breadcrumbs: headerProps.breadcrumbs,
                goBackTo: headerProps.goBackTo,
            },
        });
    };

    useEffect(() => {
        void dispatch(getUsers(queryParams));
    }, [dispatch, queryParams]);

    const handleSearch = (value: string) => {
        if (value === '') {
            setQueryParams({ page: 1 });
        } else {
            setQueryParams({
                page: 1,
                query: value,
                match: 'any',
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('user.userList')}</title>
            </Helmet>
            <MainLayout
                header={
                    <Header
                        {...headerProps}
                        buttons={
                            hasRightToAddUsers
                                ? [
                                      {
                                          'data-testid': 'addUserButton',
                                          text: t('user.addUser'),
                                          aspect: 'primary',
                                          onClick: addUser,
                                      },
                                  ]
                                : []
                        }
                    />
                }
                footer={
                    totalUsers > 0 ? (
                        <Pagination
                            data-testid="pagination"
                            initialPage={queryParams.page}
                            currentPage={queryParams.page}
                            pageNeighbours={2}
                            nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                            nbItemsTotal={totalUsers}
                            onPageNumberClicked={(page: number) =>
                                setQueryParams({ ...queryParams, page })
                            }
                            pageUrl="users"
                        />
                    ) : null
                }
                smallContentSidePadding
            >
                <GridRow style={{ marginBottom: '1rem' }}>
                    <GridCol smallScreen={10} defaultScreen={4}>
                        <SearchField
                            name="organizationResearch"
                            data-testid="usersSearch"
                            onSearch={handleSearch}
                        />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol>
                        {isLoading ? <Loader /> : null}
                        {!isLoading && users.length > 0 && (
                            <UserList
                                users={users}
                                stateForNavigation={{
                                    breadcrumbs: headerProps.breadcrumbs,
                                    goBackTo: `${location.pathname}${location.search}`,
                                }}
                                userRoutePrefix="/settings"
                            />
                        )}
                        {!isLoading && users.length === 0 && (
                            <EmptyState
                                titleTranslationKey="site.noUsers"
                                imageName="User"
                                button={
                                    hasRightToAddUsers ? (
                                        <Button
                                            onClick={addUser}
                                            text={t('user.addUser')}
                                            aspect="secondary"
                                            size="medium"
                                        />
                                    ) : null
                                }
                            />
                        )}
                    </GridCol>
                </GridRow>
            </MainLayout>
        </>
    );
};

export default Users;
