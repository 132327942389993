import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { Operation } from './operationSlice';
import type { User } from './userSlice';
import type { QueryParams, PaginatedPayload, ServerError, SimulationStatus } from './common';
import { errorHandler } from './common';
import type { BudgetLine } from './budgetLineSlice';
import type { BudgetPriceHypothesis } from './budgetPriceHypothesisSlice';
import type { EngagementMatching } from '../components/BudgetLinesMatchingEngagementsModal';

export type BudgetStatus = 'draft' | 'cancelled' | 'replaced' | 'approved';

export type Budget = {
    id: number;
    operationId: Operation['id'];
    label: string;
    comment?: string;
    totalAmountHt: number;
    totalAmountHtWithoutVariations: number;
    totalAmountHtVariations: number;
    totalAmountTtc: number;
    totalAmountTtcWithoutVariations: number;
    totalAmountTtcVariations: number;
    targetTotalAmountTtc: number;
    status: BudgetStatus;
    createdBy: User['id'];
    createdByUser: User | undefined;
    createdAt: string;
    updatedBy?: User['id'];
    updatedByUser?: User | undefined;
    updatedAt?: string;
    approvedBy?: User['id'];
    approvedByUser?: User | undefined;
    approvedAt?: string;
    budgetLines?: BudgetLine[];
    budgetPriceHypothesis?: BudgetPriceHypothesis[];
    lastSimulationAt: string | null;
    majorVersionNumber: number;
    minorVersionNumber: number;
    totalEngagedHt?: number;
    totalEngagedTtc?: number;
    initialEngagedHt?: number;
    initialEngagedTtc?: number;
    priceVariationEngagedHt?: number;
    priceVariationEngagedTtc?: number;
    engagementMatching?: EngagementMatching[];
};
export interface IBudgetFormData {
    id?: Budget['id'];
    operationId: Operation['id'];
    label: Budget['label'];
    comment?: Budget['comment'];
    totalAmountTtc?: Budget['totalAmountTtc'];
    status: Budget['status'];
    engagementMatching?: EngagementMatching[];
    targetTotalAmountTtc?: Budget['targetTotalAmountTtc'];
    showTtcValues?: boolean;
}

export interface BudgetLabelAndCommentFormData {
    label: string;
    comment: string;
}

type BudgetsPayload = PaginatedPayload<Budget>;

type BudgetAdded = {
    id: Budget['id'];
    operationId: Operation['id'];
};
interface IBudgetState {
    totalBudgets: number;
    budgetsById: Record<number, Budget>;
    simulationStatusByBudgetId: Record<number, SimulationStatus>;
    budgetAdded: BudgetAdded | null;
    loading: boolean;
    queryString: string;
    error: string | null;
    budgetsByOperationIdAndPage: Record<string, Record<number, Array<Budget['id']>>>;
    getBudgetsLoading: boolean;
}

export const initialState: IBudgetState = {
    totalBudgets: 0,
    budgetsById: {},
    loading: false,
    budgetsByOperationIdAndPage: {},
    simulationStatusByBudgetId: {},
    error: null,
    budgetAdded: null,
    queryString: '',
    getBudgetsLoading: false,
};

export const getBudgets = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    BudgetsPayload & {
        page: number;
        queryString: string;
        operationId: Operation['id'] | undefined;
    },
    // First argument to the payload creator
    QueryParams,
    {
        rejectValue: ServerError;
    }
>(
    'BUDGET/GETALL',
    async (
        { page = 1, query, match = 'any', fields, orderBy, order, pageSize = 12, operationId },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `&match=${match}`;
            if (query) {
                queryString += `&query=${query}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            if (order) {
                queryString += `&order=${order}`;
            }

            const path = `/operations/${operationId}/budgets?page=${
                page - 1
            }&pageSize=${pageSize}${queryString}`;
            const response = await axios.get<BudgetsPayload>(path);

            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { ...response.data, page, queryString, operationId };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey || error.response?.status === 499) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            console.error(err);
            return rejectWithValue({
                message: error.message,
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);
export const getBudget = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Budget,
    // First argument to the payload creator
    { id: Budget['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('BUDGET/GET', async ({ id, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: Budget }>(
            `/operations/${operationId}/budgets/${id}`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});
export const getBudgetEngagedAmounts = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Budget,
    // First argument to the payload creator
    { id: Budget['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('BUDGET/GET_ENGAGED_AMOUNT_TTC', async ({ id, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: Budget }>(
            `/operations/${operationId}/budgets/${id}/engaged-amounts`,
        );
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getBudgetEngagementsMatch = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { id: Budget['id']; engagementMatching: EngagementMatching[] },
    // First argument to the payload creator
    { id: Budget['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('BUDGET/GET_ENGAGEMENT_MATCHING', async ({ id, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: EngagementMatching[] }>(
            `operations/${operationId}/budgets/${id}/engagements-match`,
        );
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return { engagementMatching: response.data.data, id };
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getBudgetSimulation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { status: SimulationStatus; budgetId: Budget['id'] },
    // First argument to the payload creator
    { id: Budget['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('BUDGET_SIMULATION/GET', async ({ id, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/operations/${operationId}/budgets/${id}/simulation`);

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});
export const createBudget = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Budget,
    // First argument to the payload creator
    { budget: IBudgetFormData; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('BUDGET/POST', async ({ budget, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.post<{ data?: Budget }>(
            `/operations/${operationId}/budgets`,
            budget,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});
export const updateBudget = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Budget,
    // First argument to the payload creator
    {
        budget: IBudgetFormData;
        operationId: Operation['id'];
    },
    {
        rejectValue: ServerError;
    }
>('BUDGET/PUT', async ({ budget, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: Budget }>(
            `/operations/${operationId}/budgets/${budget.id}`,
            budget,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updateBudgetLabelAndComment = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Budget,
    // First argument to the payload creator
    {
        budget: Budget;
        budgetLabelAndComment: BudgetLabelAndCommentFormData;
    },
    {
        rejectValue: ServerError;
    }
>('BUDGET-TITLE/PUT', async ({ budget, budgetLabelAndComment }, { rejectWithValue }) => {
    try {
        // create route to edit budget title and comment
        const response = await axios.put<{ data?: Budget }>(
            `/operations/${budget.operationId}/budgets/${budget.id}/update-label-and-comment`,
            {
                budget,
                budgetLabelAndComment,
            },
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});
export const deleteBudget = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { id: Budget['id']; operationId: Operation['id'] },
    // First argument to the payload creator
    { id: Budget['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('BUDGET/DELETE', async ({ id, operationId }, { rejectWithValue }) => {
    try {
        await axios.delete<{ data?: Budget['id'] }>(`/operations/${operationId}/budgets/${id}`);
        return { id, operationId };
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const slice = createSlice({
    name: 'budget',
    initialState,
    reducers: {
        resetBudgetStatus(state, action) {
            state.simulationStatusByBudgetId[action.payload.budgetId] = null;
        },
    },
    extraReducers(builder) {
        // Get all
        builder.addCase(getBudgets.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.getBudgetsLoading = true;
        });
        builder.addCase(getBudgets.fulfilled, (state, { payload }) => {
            if (payload.queryString !== state.queryString) {
                state.queryString = payload.queryString;
            }
            if (payload.data && payload.data.length > 0 && payload.page) {
                const byPage: Array<Budget['id']> = [];
                payload.data.forEach((item: Budget) => {
                    state.budgetsById[item.id] = item;
                    byPage.push(item.id);
                });
                if (payload.operationId) {
                    state.budgetsByOperationIdAndPage[payload.operationId] = {};
                    state.budgetsByOperationIdAndPage[payload.operationId][payload.page] = byPage;
                }
            }
            state.totalBudgets = payload.total;
            state.loading = false;
            state.error = null;
            state.getBudgetsLoading = false;
        });
        builder.addCase(getBudgets.rejected, errorHandler());
        // Get one
        builder.addCase(getBudget.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getBudget.fulfilled, (state, { payload }) => {
            state.budgetsById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getBudget.rejected, errorHandler());
        // Get simulation status
        builder.addCase(getBudgetSimulation.pending, (state, action) => {
            state.error = null;
        });
        builder.addCase(getBudgetSimulation.fulfilled, (state, { payload, meta }) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- needed
            if (payload?.status && payload.status !== null) {
                state.simulationStatusByBudgetId[payload.budgetId] = payload.status;
            } else {
                state.simulationStatusByBudgetId[meta.arg.id] = null;
            }
            state.error = null;
        });
        builder.addCase(getBudgetSimulation.rejected, errorHandler());
        // Get Budget Engagement Matching
        builder.addCase(getBudgetEngagementsMatch.pending, (state) => {
            state.error = null;
        });
        builder.addCase(getBudgetEngagementsMatch.fulfilled, (state, { payload }) => {
            state.budgetsById[payload.id].engagementMatching = payload.engagementMatching;
            state.error = null;
        });
        builder.addCase(getBudgetEngagementsMatch.rejected, errorHandler());
        // Create
        builder.addCase(createBudget.pending, (state) => {
            state.loading = true;
            state.budgetAdded = null;
            state.error = null;
        });
        builder.addCase(createBudget.fulfilled, (state, { payload, meta }) => {
            state.budgetAdded = {
                id: payload.id,
                operationId: meta.arg.operationId,
            };
            state.error = null;
            state.budgetsById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(createBudget.rejected, errorHandler());
        // Update
        builder.addCase(updateBudget.pending, (state) => {
            state.loading = true;
            state.budgetAdded = null;
        });
        builder.addCase(updateBudget.fulfilled, (state, { payload }) => {
            state.budgetsById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateBudget.rejected, errorHandler());
        // Delete
        builder.addCase(deleteBudget.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteBudget.fulfilled, (state, { payload }) => {
            state.loading = false;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
            if (state.budgetsByOperationIdAndPage[payload.operationId] !== undefined) {
                const pageKeys = Object.keys(
                    state.budgetsByOperationIdAndPage[payload.operationId],
                );

                pageKeys.forEach((key) => {
                    const page =
                        state.budgetsByOperationIdAndPage[payload.operationId][Number(key)];
                    if (page.includes(payload.id)) {
                        state.budgetsByOperationIdAndPage[payload.operationId][Number(key)].splice(
                            page.indexOf(payload.id),
                            1,
                        );
                    }
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.budgetsById[payload.id];
            state.error = null;
        });
        builder.addCase(deleteBudget.rejected, errorHandler());
        // get engaged amount ttc of budget
        builder.addCase(getBudgetEngagedAmounts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getBudgetEngagedAmounts.fulfilled, (state, { payload }) => {
            state.budgetsById[payload.id].totalEngagedHt = payload.totalEngagedHt;
            state.budgetsById[payload.id].totalEngagedTtc = payload.totalEngagedTtc;
            state.budgetsById[payload.id].initialEngagedHt = payload.initialEngagedHt;
            state.budgetsById[payload.id].initialEngagedTtc = payload.initialEngagedTtc;
            state.budgetsById[payload.id].priceVariationEngagedHt = payload.priceVariationEngagedHt;
            state.budgetsById[payload.id].priceVariationEngagedTtc =
                payload.priceVariationEngagedTtc;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getBudgetEngagedAmounts.rejected, errorHandler());

        builder.addCase(updateBudgetLabelAndComment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateBudgetLabelAndComment.fulfilled, (state, { payload }) => {
            state.budgetsById[payload.id].label = payload.label;
            state.budgetsById[payload.id].comment = payload.comment;
        });
        builder.addCase(updateBudgetLabelAndComment.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.budget.error;
export const selectBudgets = (state: RootState) => Object.values(state.budget.budgetsById);
export const selectGetBudgetsLoading = (state: RootState) => state.budget.getBudgetsLoading;
export const selectBudget =
    (id: Budget['id']) =>
    (state: RootState): Budget | undefined =>
        state.budget.budgetsById[id];
export const selectBudgetAdded = (state: RootState) => state.budget.budgetAdded;
export const selectBudgetsByOperationIdAndPage =
    (operationId: string | undefined, page: number) => (state: RootState) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
        if (operationId && state.budget.budgetsByOperationIdAndPage[operationId]) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
            return state.budget.budgetsByOperationIdAndPage[operationId][page]?.map(
                (id: number) => state.budget.budgetsById[id],
            );
        }
        return [];
    };
export const selectBudgetEngagementsMatch =
    (id: Budget['id']) =>
    (state: RootState): EngagementMatching[] =>
        state.budget.budgetsById[id].engagementMatching ?? [];
export const selectIsLoading = (state: RootState) => state.budget.loading;
export const selectTotalBudgets = (state: RootState) => state.budget.totalBudgets;
export const selectBudgetSimulation = (budgetId: number | undefined | null) =>
    function (state: RootState) {
        if (budgetId && state.budget.simulationStatusByBudgetId[budgetId]) {
            return state.budget.simulationStatusByBudgetId[budgetId];
        }
        return null;
    };

// Actions added into the `reducers` part
export const { resetBudgetStatus } = slice.actions;

export default slice.reducer;
