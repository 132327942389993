import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const LabelsRedirect: React.FunctionComponent = () => {
    const { operationId }: { operationId?: string } = useParams();

    return (
        <Navigate
            to={{
                pathname: `/operations/${operationId}/labels/market`,
            }}
        />
    );
};

export default LabelsRedirect;
