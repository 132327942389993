import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import type { QueryParams } from '../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../slices/common';
import type { IRole } from '../slices/roleSlice';
import { getRoles, selectRolesHavingAnOperationScope } from '../slices/roleSlice';
import type { User, IUserFormData } from '../slices/userSlice';
import {
    getAllowedUsersNotAlreadyAffectedToOperation,
    selectError,
    selectAffectUserToOperationFulfilled,
    updateUserOperationRoles,
    getUsersByOperation,
    selectTotalUsersFromOrganizationsAffectedToCurrentOperation,
    selectUsersFromOrganizationsAffectedToCurrentOperationByPage,
    selectIsLoading,
} from '../slices/userSlice';
import Avatar from './Avatar';
import type { BreadcrumbsProps } from './Breadcrumbs';
import type { ButtonProps } from './Button';
import { Container } from './FieldErrorMessage';
import { showFlag } from './Flag';
import GridCol from './GridCol';
import GridRow from './GridRow';
import Header from './Header';
import Loader from './Loader';
import Modal from './Modal';
import OperationUsersList from './OperationUsersList';
import SearchField from './SearchField';
import { getUserFormSchema } from '../pages/userProfile/UserProfile';
import type { Operation } from '../slices/operationSlice';
import { checkIfUserHasAtLeastOneRoleOnOperation } from '../utils/userRoles';
import Pagination from './Pagination';
import { useAppDispatch } from '../store';

const StyledText = styled.p`
    margin: auto auto auto 0.5rem;
`;

const renderCellMap = {
    name: (user: User | Partial<User>) => (
        <>
            <Avatar text={`${user.profile?.firstName} ${user.profile?.lastName}`} size="small" />
            <StyledText>
                {user.profile?.firstName} {user.profile?.lastName}
            </StyledText>
        </>
    ),
    email: (user: User | Partial<User>) => user.email ?? '-',
    organization: (user: User | Partial<User>) => user.organization?.denomination,
    occupation: (user: User | Partial<User>) => user.profile?.occupation,
    site: (user: Partial<User>) => user.site?.label ?? '-',
};

type Props = {
    operationId: Operation['id'];
    onModalClosed: () => void;
    stateForNavigation?: {
        breadcrumbs?: BreadcrumbsProps['paths'];
        // see how it works in the header back arrow button section of the README file
        goBackTo?: string;
    };
    userRoutePrefix?: string;
};

const AffectUserToOperationModal: React.FunctionComponent<Props> = ({
    operationId,
    onModalClosed,
    stateForNavigation,
    userRoutePrefix = '',
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const defaultQueryParams = {
        page: 1,
        operationId,
        orderBy: '[organization.denomination, profile.firstName, profile.lastName]',
        order: '[asc, asc, asc]',
    };
    const [queryParams, setQueryParams] = useState<QueryParams>(defaultQueryParams);
    const [userAffectationToOperationIsPending, setUserAffectationToOperationIsPending] =
        useState(false);

    const users = useSelector(
        selectUsersFromOrganizationsAffectedToCurrentOperationByPage(queryParams.page),
    );
    const totalUsers = useSelector(selectTotalUsersFromOrganizationsAffectedToCurrentOperation);
    const isLoading = useSelector(selectIsLoading);
    const [selectedUser, setSelectedUser] = useState<User>();

    const affectUserToOperationFulfilled = useSelector(selectAffectUserToOperationFulfilled);
    const availableOperationRoles = useSelector(selectRolesHavingAnOperationScope);
    const serverError = useSelector(selectError);
    let serverErrorMessage = '';
    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    useEffect(() => {
        void dispatch(getRoles({ operationId: Number(operationId) }));
    }, [dispatch, operationId]);

    useEffect(() => {
        void dispatch(getAllowedUsersNotAlreadyAffectedToOperation(queryParams));
    }, [dispatch, queryParams]);

    useEffect(() => {
        if (serverError && userAffectationToOperationIsPending) {
            showFlag('error', t('errors.error'), serverErrorMessage);
            setUserAffectationToOperationIsPending(false);
        } else {
            if (userAffectationToOperationIsPending && affectUserToOperationFulfilled) {
                setUserAffectationToOperationIsPending(false);
                void dispatch(getUsersByOperation({ operationId, page: 1 }));
                onModalClosed();
                showFlag('success', t('operation.userAffectedWithSuccess'), '');
            }
        }
    }, [
        affectUserToOperationFulfilled,
        userAffectationToOperationIsPending,
        onModalClosed,
        serverError,
        serverErrorMessage,
        t,
        queryParams,
        dispatch,
        operationId,
    ]);

    const headers = {
        name: t('user.list.name'),
        email: t('user.list.email'),
        organization: t('user.organization'),
        occupation: t('user.list.occupation'),
        site: t('user.list.site'),
    };

    const computeButtonsOutOfProps = (formikProps: FormikProps<IUserFormData>): ButtonProps[] => [
        {
            text: t('general.cancel'),
            'data-testid': 'affectUserModalCancel',
            aspect: 'secondary',
            onClick() {
                onModalClosed();
            },
        },
        {
            text: t('general.submit'),
            'data-testid': 'affectUserModalSubmit',
            aspect: 'primary',
            disabled:
                !selectedUser ||
                !checkIfUserHasAtLeastOneRoleOnOperation(formikProps.values.roles, operationId) ||
                userAffectationToOperationIsPending,
            async onClick() {
                await formikProps.validateForm();
                formikProps.handleSubmit();
            },
        },
    ];

    const userFormSchema = getUserFormSchema(t);
    const userFormInitialValues = {
        ...selectedUser,
        email: selectedUser?.email ?? '',
        profile: selectedUser?.profile ?? {
            firstName: '',
            lastName: '',
            title: 'M.',
            mobilePhoneNumber: '',
            phoneNumber: '',
            occupation: '',
        },
        roles: selectedUser?.roles ?? [],
    };

    const handleSearch = (value: string) => {
        if (value === '') {
            setQueryParams(defaultQueryParams);
        } else {
            setQueryParams({
                ...defaultQueryParams,
                query: value,
                match: 'any',
            });
        }
    };

    return (
        <Formik
            initialValues={userFormInitialValues}
            validationSchema={userFormSchema}
            onSubmit={(values: IUserFormData, actions) => {
                if (selectedUser) {
                    void dispatch(
                        updateUserOperationRoles({
                            user: values,
                            operationId,
                        }),
                    );
                    setUserAffectationToOperationIsPending(true);
                    actions.setSubmitting(true);
                }
            }}
            enableReinitialize
        >
            {(formikProps: FormikProps<IUserFormData>) => {
                const checkIfRoleIsAlreadyAffectedToUserOnOperation = (
                    userId: User['id'],
                    roleId: IRole['id'],
                ): boolean => {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- can be falsy
                    if (selectedUser && selectedUser.id === userId && formikProps.values) {
                        return formikProps.values.roles.some(
                            (userRole) =>
                                userRole.id === roleId &&
                                userRole.userRoleOperationId === operationId,
                        );
                    }
                    return false;
                };

                const handleUserRoleClick = (userId: User['id'], roleId: IRole['id']) => {
                    if (checkIfRoleIsAlreadyAffectedToUserOnOperation(userId, roleId)) {
                        formikProps.setFieldValue(
                            `roles`,
                            formikProps.values.roles.filter((role) => role.id !== roleId),
                        );
                    } else {
                        formikProps.setFieldValue(`roles`, [
                            ...formikProps.values.roles,
                            {
                                id: roleId,
                                userRoleOperationId: operationId,
                            },
                        ]);
                    }
                };

                return (
                    <Modal
                        buttons={computeButtonsOutOfProps(formikProps)}
                        onCloseButtonPressed={onModalClosed}
                        title={t('operation.affectUser')}
                        size="full"
                        titleFontSize="1.5rem"
                        backgroundColor={colors.neutral.N50}
                        specificHeight="90vh"
                        specificPadding="0"
                        isOpen
                        centerTitle
                        scrollableBody
                    >
                        {selectedUser ? (
                            <Header
                                title={t('operation.backToList')}
                                backArrowCallBack={() => {
                                    setSelectedUser(undefined);
                                    formikProps.resetForm();
                                }}
                                shouldSetHelmet={false}
                                showBackButton
                                noTitlePadding
                            />
                        ) : (
                            <GridRow style={{ marginBottom: '1rem' }}>
                                <GridCol smallScreen={10} defaultScreen={4}>
                                    <SearchField
                                        name="organizationResearch"
                                        data-testid="usersSearch"
                                        onSearch={handleSearch}
                                    />
                                </GridCol>
                            </GridRow>
                        )}
                        <Container>
                            {isLoading ? <Loader /> : null}
                            {!isLoading &&
                                users.length > 0 &&
                                (selectedUser ? (
                                    <OperationUsersList
                                        operationId={operationId}
                                        users={[formikProps.values] as User[]}
                                        columnSizes={`13% 18% 6% repeat(${availableOperationRoles.length}, minmax(13%, 1fr))`}
                                        showActionMenu={false}
                                        onUserRoleClick={handleUserRoleClick}
                                        operationRoles={availableOperationRoles}
                                        checkIfRoleIsAlreadyAffectedToUserOnOperation={
                                            checkIfRoleIsAlreadyAffectedToUserOnOperation
                                        }
                                        isRoleManagementMode
                                        isAffectationMode
                                        isEditMode
                                    />
                                ) : (
                                    <OperationUsersList
                                        operationId={operationId}
                                        users={users}
                                        addRowText={t('operation.addUser')}
                                        onRowClick={
                                            setSelectedUser as (user: User | Partial<User>) => void
                                        }
                                        onAddRowClick={() =>
                                            navigate(`${userRoutePrefix}/organizations`, {
                                                state: stateForNavigation,
                                            })
                                        }
                                        cellMap={renderCellMap}
                                        headers={headers}
                                        columnSizes="3fr 3fr 3fr 3fr 3fr"
                                        hasAddRow
                                    />
                                ))}
                            {!isLoading && !selectedUser && (
                                <Pagination
                                    data-testid="pagination"
                                    initialPage={queryParams.page}
                                    currentPage={queryParams.page}
                                    pageNeighbours={2}
                                    nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                                    nbItemsTotal={totalUsers}
                                    onPageNumberClicked={(page: number) =>
                                        setQueryParams({ ...queryParams, page })
                                    }
                                    pageUrl="users"
                                    shouldChangePageUrl={false}
                                />
                            )}
                        </Container>
                    </Modal>
                );
            }}
        </Formik>
    );
};

export default AffectUserToOperationModal;
