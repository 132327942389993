import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import { capitalize } from '../utils/capitalizeText';

import { colors } from '../constants/colors';
import Text from './Text';
import Icon from './Icon';

const SPACING = ({ msGridColumn, isDisabled }: CellProps) => `
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: space-between;
    -ms-grid-column: ${msGridColumn};
    align-self: center;
    align-items: center;
    opacity: ${isDisabled && '0.7'};
    cursor: ${isDisabled && 'not-allowed'};
`;

const CELL_FIXING = ({
    isHeader,
    isFixedColumn,
    isLeftFixedColumn,
    isRightFixedColumn,
    shouldShowSeparation,
    fullBorder,
    isLastRow,
}: CellProps) => `
    position: ${isFixedColumn ? 'sticky' : 'relative'};
    ${isHeader && 'z-index: 10'};
    ${isFixedColumn && isHeader && 'z-index: 15'};
    ${isFixedColumn && !isHeader && 'z-index: 5'};

    margin-left: -1px;

    ${
        ((isFixedColumn && shouldShowSeparation && isLeftFixedColumn) || fullBorder) &&
        `border-right: 0.05rem solid ${colors.neutral.N200}`
    };
    ${
        ((isFixedColumn && shouldShowSeparation && isRightFixedColumn) || fullBorder) &&
        `border-left: 0.05rem solid ${colors.neutral.N200}`
    };

    border-top: ${isHeader && fullBorder && `0.05rem solid ${colors.neutral.N200};`};
    border-bottom: ${
        (isHeader && !fullBorder) || (isLastRow && fullBorder)
            ? '0'
            : `0.05rem solid ${colors.neutral.N200};`
    };
`;

const TEXT = ({ isHeader }: CellProps) => `
    font-size: ${isHeader ? '0.6875rem' : '0.75rem'};
    font-weight: ${isHeader ? '500' : '400'};
    line-height: ${isHeader ? '1rem' : '1.5rem'};
    text-transform: ${isHeader && 'uppercase'};
    white-space: ${!isHeader && 'nowrap'};
    text-overflow: ellipsis;
    overflow: hidden;

    :hover {
        text-overflow: clip;
        white-space: normal;
    }
`;

export const CellDiv = styled.div<CellProps>`
    ${(props) => SPACING(props)};
    ${(props) => CELL_FIXING(props)};
    ${({ isLeftFixedColumn, fixedPosition }) => isLeftFixedColumn && `left: ${fixedPosition};`};
    ${({ isRightFixedColumn, fixedPosition }) => isRightFixedColumn && `right: ${fixedPosition};`};

    background: inherit;
    height: 100%;
    min-width: 0;

    & > p,
    h4,
    h6 {
        ${(props) => TEXT(props)};
        ${({ isHeader }) => {
            let color = '';
            if (isHeader) {
                color = colors.neutral.N400;
            } else {
                color = colors.blue.B500;
            }
            return `color: ${color};`;
        }}
    }

    > span > span {
        display: -webkit-box;
        -webkit-box-align: start;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;

const TextContent = styled(Text)<Partial<CellProps>>`
    margin: 0;
`;

const CheckIcon = styled(Icon)`
    color: ${colors.green.G400};
    justify-content: space-between;
    margin: 0 0 0 12px;
`;

// Types
type CellProps = {
    isHeader: boolean;
    isDisabled?: boolean;
    msGridColumn: number;
    isFixedColumn?: boolean;
    isLeftFixedColumn: boolean;
    isRightFixedColumn?: boolean;
    fixedPosition?: string;
    shouldShowSeparation?: boolean;
    fullBorder?: boolean;
    isLastRow?: boolean;
};

type RenderCellMapReturn = string | Element | JSX.Element | null | undefined;

export type RenderCellMapType<T> = Record<
    string,
    (data: T, key: string, shouldCapitalize: boolean) => RenderCellMapReturn
>;

type BaseT = {
    id: string | number;
};

type Props<T> = {
    isDisabled?: boolean;
    isHeader?: boolean;
    headerKeys?: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- necessary
    values: any;
    renderCellMap?: RenderCellMapType<T | Partial<T>>;
    shouldCapitalize?: boolean;
    fixedStartColumnsNumber: number;
    fixedEndColumnsNumber: number;
    numberOfColumns: number;
    columnSizesList: number[];
    isSelectedRow?: boolean;
    fullBorder?: boolean;
    isLastRow?: boolean;
};

const TableRow = <B extends BaseT>({
    isDisabled,
    isHeader = false,
    headerKeys,
    values,
    renderCellMap,
    shouldCapitalize = false,
    fixedStartColumnsNumber,
    fixedEndColumnsNumber,
    numberOfColumns,
    columnSizesList,
    isSelectedRow,
    fullBorder,
    isLastRow = false,
}: Props<B>) => {
    const isLeftFixedColumn = (index: number) => Boolean(index < fixedStartColumnsNumber);
    const isRightFixedColumn = (index: number) =>
        Boolean(index > numberOfColumns - 1 - fixedEndColumnsNumber);

    const isFixedColumn = (index: number): boolean =>
        Boolean(isLeftFixedColumn(index) || isRightFixedColumn(index));

    const getPreviousColumnSizes = (index: number) => {
        let previousColumnSizes = 0;
        if (index !== 0) {
            for (let i = index - 1; i >= 0; i--) {
                previousColumnSizes += columnSizesList[i] / 16;
            }
        }
        return `${previousColumnSizes}rem`;
    };
    const getNextColumnSizes = (index: number) => {
        let nextColumnSizes = 0;
        if (index !== numberOfColumns - 1) {
            for (let i = index; i < numberOfColumns - 1; i++) {
                nextColumnSizes += columnSizesList[i + 1] / 16;
            }
        }
        return `${nextColumnSizes}rem`;
    };

    const getFixedPositions = (index: number) => {
        if (columnSizesList.length) {
            if (isLeftFixedColumn(index)) {
                return getPreviousColumnSizes(index);
            }
            if (isRightFixedColumn(index)) {
                return getNextColumnSizes(index);
            }
            return '0';
        }
        return '0';
    };

    const shouldShowSeparation = (index: number) =>
        isFixedColumn(index) &&
        (index + 1 === fixedStartColumnsNumber ||
            index === numberOfColumns - fixedEndColumnsNumber);

    const isLastCell = (index: number) => index === numberOfColumns - 1;

    const renderValue = (key: string, index: number) => {
        let value: RenderCellMapReturn;
        if (renderCellMap?.[key] && !isHeader) {
            value = renderCellMap[key](values, key, shouldCapitalize);
        } else {
            value = shouldCapitalize ? capitalize(values[key]) : (values[key] as string);
        }

        const isContentAString = typeof value === 'string';
        const isContentANumber = typeof value === 'number';

        // Detect amounts to set numerals font
        let isValueNumeral = false;
        if (typeof value === 'string' && value.includes('€')) {
            isValueNumeral = true;
        }

        return (
            <CellDiv
                key={`${values.id}-${key}-${isHeader ? 'header' : 'row'}`}
                data-testid={`rowItem${values.id}-${key}`}
                isHeader={isHeader}
                isDisabled={isDisabled}
                msGridColumn={index + 1}
                isFixedColumn={isFixedColumn(index)}
                isLeftFixedColumn={isLeftFixedColumn(index)}
                isRightFixedColumn={isRightFixedColumn(index)}
                fixedPosition={getFixedPositions(index)}
                shouldShowSeparation={shouldShowSeparation(index)}
                fullBorder={fullBorder}
                isLastRow={isLastRow}
            >
                <>
                    {isContentAString || isContentANumber ? (
                        <TextContent
                            type={isHeader ? 'H100' : 'default'}
                            isNumeral={isValueNumeral}
                        >
                            {value as ReactNode}
                        </TextContent>
                    ) : (
                        value
                    )}
                    {isLastCell(index) && isSelectedRow && <CheckIcon name="Done" />}
                </>
            </CellDiv>
        );
    };

    // eslint-disable-next-line react/jsx-no-useless-fragment -- needed
    return <>{headerKeys ? headerKeys.map(renderValue) : Object.keys(values).map(renderValue)}</>;
};

export default TableRow;
