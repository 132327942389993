/* eslint-disable react/sort-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any -- needed for lines 329-330 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FormikProps, FieldArrayRenderProps } from 'formik';
import { FieldArray, useField } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import type { TFunction } from 'i18next';

import { Radio, RadioGroup } from '@blueprintjs/core';

import type {
    IndexSetting,
    PriceVariationSettings,
    PriceVariationSettingsType,
} from '../../slices/common';
import { getPriceIndexes } from '../../slices/priceIndexSlice';

import { validateFormFields } from '../../utils/formValidation';

import type { ColProps } from '../GridCol';
import GridCol from '../GridCol';
import GridRow from '../GridRow';
import Text from '../Text';
import PermissionAwareToggle from '../PermissionAwareToggle';
import PermissionAwareDateInputField from '../PermissionAwareDateInputField';
import PermissionAwareText from '../PermissionAwareText';
import FieldErrorMessage from '../FieldErrorMessage';
import PriceVariationIndexSettings from '../PriceVariationIndexSettings';

import { colors } from '../../constants/colors';
import { handleRadioStringChange } from '../../utils/handleRadioButtonGroup';
import type { BudgetLine } from '../../slices/budgetLineSlice';
import type { Engagement } from '../../slices/engagementSlice';
import type { Permission } from '../../slices/authSlice';
import { useAppDispatch } from '../../store';

const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
`;

const DashedSeparator = styled.div`
    border-bottom: 0.0938rem dashed ${colors.neutral.N75};
    width: 100%;
    margin-bottom: 1.25rem;
`;

export const SIMULATED = 'simulated';
export const ENTERED = 'entered';

const checkRevisionFormula = (a: number, b: number, indexSettings: IndexSetting[]) => {
    const indexesSum = indexSettings.reduce((result: number, indexSetting: IndexSetting) => {
        result = (result * 1000 + Number(indexSetting.factor) * 1000) / 1000;
        return result;
    }, 0);
    const resultFormula = a + b * indexesSum;
    return resultFormula === 1;
};

const checkActualisationFormula = (indexSettings: IndexSetting[]) => {
    const indexesSum = indexSettings.reduce((result: number, indexSetting: IndexSetting) => {
        result = (result * 1000 + Number(indexSetting.factor) * 1000) / 1000;
        return result;
    }, 0);
    return indexesSum === 1;
};

export const getDefaultPriceVariationSettings = () => ({
    a: null,
    b: null,
    shift: 0,
    m0: null,
    actualisationMonth: null,
    hasRevision: false,
    hasActualisation: false,
    indexSettings: [
        {
            priceIndexId: null,
            factor: null,
        },
    ],
    isSimulated: true,
    revisionsAmountHt: 0,
    actualisationsAmountHt: 0,
});

export const setInitialPriceVariationSettings = (
    budgetOrEngagement: BudgetLine | Engagement | null | undefined,
): PriceVariationSettings => {
    if (budgetOrEngagement) {
        return {
            ...(budgetOrEngagement.priceVariationSettings ?? getDefaultPriceVariationSettings()),
            isSimulated: budgetOrEngagement.isSimulated,
            actualisationsAmountHt: budgetOrEngagement.actualisationsAmountHt,
            revisionsAmountHt: budgetOrEngagement.revisionsAmountHt,
        };
    }
    return getDefaultPriceVariationSettings();
};

export const duplicatePriceVariationSettingsFromAPreviousEngagement = (
    previousEngagement: Engagement | null | undefined,
): PriceVariationSettings => {
    if (previousEngagement) {
        if (previousEngagement.isSimulated) {
            const initialPriceVariationSettings =
                setInitialPriceVariationSettings(previousEngagement);
            if (initialPriceVariationSettings) {
                const duplicatedPriceVariationSettings: PriceVariationSettings = {
                    ...initialPriceVariationSettings,
                    actualisationsAmountHt: 0,
                    revisionsAmountHt: 0,
                };
                // Deleting priceVariationSettings id
                if (duplicatedPriceVariationSettings.hasOwnProperty('id')) {
                    delete duplicatedPriceVariationSettings.id;
                }
                // Deleting indexSettings ids
                duplicatedPriceVariationSettings.indexSettings =
                    duplicatedPriceVariationSettings.indexSettings.map(
                        ({ id, ...indexSettingWithoutId }) => indexSettingWithoutId,
                    );

                return duplicatedPriceVariationSettings;
            }
        } else {
            const defaultPriceVariationSettings = getDefaultPriceVariationSettings();
            const duplicatedPriceVariationSettings = {
                ...defaultPriceVariationSettings,
                isSimulated: false,
            };
            return duplicatedPriceVariationSettings;
        }
    }
    return getDefaultPriceVariationSettings();
};

export async function validatePriceVariationsTab<
    T extends { priceVariationSettings?: PriceVariationSettings },
>(formikProps: FormikProps<T>) {
    const isValid: boolean = await validateFormFields(
        [
            'priceVariationSettings',
            'priceVariationSettings.a',
            'priceVariationSettings.b',
            'priceVariationSettings.shift',
            'priceVariationSettings.m0',
            'priceVariationSettings.actualisationMonth',
            'priceVariationSettings.indexSettings',
            'priceVariationSettings.actualisationsAmountHt',
            'priceVariationSettings.revisionsAmountHt',
        ],
        formikProps,
    );
    formikProps.values.priceVariationSettings?.indexSettings.forEach(
        (indexSetting: IndexSetting, index: number) => {
            formikProps.setFieldTouched(
                `priceVariationSettings.indexSettings[${index}].priceIndexId`,
                true,
                true,
            );
            formikProps.setFieldTouched(
                `priceVariationSettings.indexSettings[${index}].factor`,
                true,
                true,
            );
        },
    );

    return isValid;
}

export const getYupSchema = (t: TFunction) =>
    Yup.object().shape({
        a: Yup.number()
            .nullable()
            .when(['hasRevision', 'isSimulated'], {
                is: (hasRevision: boolean, isSimulated: boolean) => hasRevision && isSimulated,
                then: Yup.number().nullable().required(t('errors.required')),
            })
            .test('formulaRevision', t('budget.priceVariations.incorrectValue'), function (a) {
                const { b, hasRevision, indexSettings, isSimulated } = this.parent;
                if (hasRevision && isSimulated) {
                    return checkRevisionFormula(Number(a), Number(b), indexSettings);
                }
                return true;
            }),
        b: Yup.number()
            .nullable()
            .when(['hasRevision', 'isSimulated'], {
                is: (hasRevision: boolean, isSimulated: boolean) => hasRevision && isSimulated,
                then: Yup.number().required(t('errors.required')).nullable(),
            })
            .test('formulaRevision', t('budget.priceVariations.incorrectValue'), function (b) {
                const { a, hasRevision, indexSettings, isSimulated } = this.parent;
                if (hasRevision && isSimulated) {
                    return checkRevisionFormula(Number(a), Number(b), indexSettings);
                }
                return true;
            }),
        shift: Yup.number()
            .nullable()
            .when(['hasRevision', 'hasActualisation', 'isSimulated'], {
                is: (hasRevision: boolean, hasActualisation: boolean, isSimulated: boolean) =>
                    isSimulated && (hasRevision || hasActualisation),
                then: Yup.number().required(t('errors.required')),
            }),
        m0: Yup.string()
            .nullable()
            .when(['hasRevision', 'hasActualisation', 'isSimulated'], {
                is: (hasRevision: boolean, hasActualisation: boolean, isSimulated: boolean) =>
                    isSimulated && (hasRevision || hasActualisation),
                then: Yup.string().required(t('errors.required')).nullable(),
            }),
        actualisationMonth: Yup.string()
            .when(['hasActualisation', 'isSimulated'], {
                is: (hasActualisation: boolean, isSimulated: boolean) =>
                    hasActualisation && isSimulated,
                then: Yup.string().required(t('errors.required')).nullable(),
            })
            .when(['m0', 'hasActualisation', 'isSimulated'], {
                is: (
                    m0: PriceVariationSettingsType['m0'],
                    hasActualisation: PriceVariationSettingsType['hasActualisation'],
                    isSimulated: PriceVariationSettingsType['isSimulated'],
                ) => m0 && hasActualisation && isSimulated,
                then: Yup.string().test({
                    name: 'actualisationMonth > m0',
                    test() {
                        const { actualisationMonth, m0 } = this.parent;
                        return new Date(actualisationMonth).getTime() > new Date(m0).getTime();
                    },
                    message: t('budget.priceVariations.actualisationMonthGreaterM0month'),
                }),
            })
            .nullable(),
        actualisationsAmountHt: Yup.number()
            .nullable()
            .when('isSimulated', {
                is: false,
                then: Yup.number().required(t('errors.required')),
            }),
        revisionsAmountHt: Yup.number()
            .nullable()
            .when('isSimulated', {
                is: false,
                then: Yup.number().required(t('errors.required')),
            }),
        indexSettings: Yup.array()
            .of(
                Yup.object().shape({
                    priceIndexId: Yup.number().nullable().required(t('errors.required')).min(1),
                    factor: Yup.number().required(t('errors.required')),
                }),
            )
            .compact((indexSetting) => {
                const values = Object.values(indexSetting);
                const valueFound = Boolean(
                    values.find((value) => typeof value === 'string' || typeof value === 'number'),
                );
                return !valueFound;
            })
            .test('formulaActualisation', 'errorActualisation', (indexSettings, testContext) => {
                const { hasRevision, hasActualisation, isSimulated } = testContext.parent;
                if (!hasRevision && hasActualisation && isSimulated) {
                    return checkActualisationFormula(indexSettings as IndexSetting[]);
                }
                return true;
            })
            .test('formulaRevision', 'errorRevision', (indexSettings, testContext) => {
                const { a, b, hasRevision, isSimulated } = testContext.parent;
                if (hasRevision && isSimulated) {
                    return checkRevisionFormula(
                        Number(a),
                        Number(b),
                        indexSettings as IndexSetting[],
                    );
                }
                return true;
            }),
    });

type ColPropsForPriceVariationSettings = ColProps & { $isEditMode?: boolean };

const StyledPriceVariationSettings = styled(GridCol)<ColPropsForPriceVariationSettings>`
    display: flex;
    flex-direction: row;
    > p {
        width: 2rem;
        align-self: flex-end;
        padding-bottom: ${({ $isEditMode }) => ($isEditMode ? '0.75rem' : '')};
        ${({ $isEditMode }) => !$isEditMode && 'margin: 0'}
    }
    div {
        align-self: flex-end;
    }
`;

const StyledPermissionAwareToggle = styled(GridCol)<{ $isEditMode?: boolean }>`
    display: flex;
    flex-direction: row;
    > div {
        ${({ $isEditMode }) => !$isEditMode && 'justify-content: center; align-items: center;'}
    }
`;

type Props<B> = {
    hasRightToEdit: boolean;
    permissionsNeeded: Permission[];
    formikProps: FormikProps<B>;
    isEditMode?: boolean;
    priceVariationSettingsOfFirstLine?: PriceVariationSettings;
};

const PriceVariationTab = <B extends { priceVariationSettings?: PriceVariationSettings }>({
    hasRightToEdit,
    permissionsNeeded,
    formikProps,
    isEditMode = true,
    priceVariationSettingsOfFirstLine,
}: Props<B>) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [hasActualisation, setHasActualisation] = useState<boolean>(
        Boolean(formikProps.values.priceVariationSettings?.hasActualisation),
    );
    const [hasRevision, setHasRevision] = useState<boolean>(
        Boolean(formikProps.values.priceVariationSettings?.hasRevision),
    );

    useEffect(() => {
        void dispatch(getPriceIndexes({ page: 1, pageSize: 100 }));
    }, [dispatch]);

    const priceVariationSettingsErrors: any = formikProps.errors.priceVariationSettings;
    const priceVariationSettingsTouched: any = formikProps.touched.priceVariationSettings;

    let formulaError = '';
    let fieldError = '';
    if (priceVariationSettingsErrors?.indexSettings) {
        if (
            Array.isArray(priceVariationSettingsErrors.indexSettings) &&
            priceVariationSettingsErrors.indexSettings.find((setting: any) => {
                if (setting?.factor) {
                    return setting.factor;
                }
            })
        ) {
            fieldError = t('budget.priceVariations.incorrectValue');
        }
        if (
            !Array.isArray(priceVariationSettingsErrors.indexSettings) &&
            priceVariationSettingsErrors.indexSettings === 'errorRevision'
        ) {
            formulaError = t('budget.priceVariations.revisionFormula');
            fieldError = t('budget.priceVariations.incorrectValue');
        } else if (!Array.isArray(priceVariationSettingsErrors.indexSettings)) {
            formulaError = t('budget.priceVariations.actualisationFormula');
            fieldError = t('budget.priceVariations.incorrectValue');
        }
    }

    let indexSettingsIsTouched = false;
    if (priceVariationSettingsTouched?.indexSettings) {
        indexSettingsIsTouched = true;
    }

    // ------- Radio button handling -------
    const priceVariationTypeField = useField('priceVariationSettings.isSimulated');
    const handleRadioChange = handleRadioStringChange((value: string) => {
        priceVariationTypeField[2].setValue(value === SIMULATED);
    });
    // ----------------------------------------------

    type PriceVariationSettingKey =
        | 'm0'
        | 'actualisationMonth'
        | 'shift'
        | 'a'
        | 'b'
        | 'indexSettings';

    const PRICE_VARIATION_ACTUALISATION_SETTINGS: PriceVariationSettingKey[] = [
        'm0',
        'actualisationMonth',
        'shift',
        'indexSettings',
    ];

    const PRICE_VARIATION_REVISION_SETTINGS: PriceVariationSettingKey[] = [
        'm0',
        'shift',
        'a',
        'b',
        'indexSettings',
    ];

    const checkIfActivatingEitherActualisationOrRevision = (
        priceVariationType: 'actualisation' | 'revision',
    ): boolean => {
        if (priceVariationType === 'actualisation') {
            return hasActualisation && !hasRevision;
        } else {
            return !hasActualisation && hasRevision;
        }
    };

    const duplicateSpecificPriceVariationSettingsFromAPreviousEngagement = (
        priceVariationType: 'actualisation' | 'revision',
    ) => {
        const specificPriceVariationSettingKeys =
            priceVariationType === 'actualisation'
                ? PRICE_VARIATION_ACTUALISATION_SETTINGS
                : PRICE_VARIATION_REVISION_SETTINGS;

        const isActivatingEitherActualisationOrRevision =
            checkIfActivatingEitherActualisationOrRevision(priceVariationType);

        if (priceVariationSettingsOfFirstLine && isActivatingEitherActualisationOrRevision) {
            specificPriceVariationSettingKeys.forEach((specificPriceVariationSettingKey) => {
                formikProps.setFieldValue(
                    `priceVariationSettings.${specificPriceVariationSettingKey}`,
                    priceVariationSettingsOfFirstLine[specificPriceVariationSettingKey],
                );
            });
        }
    };

    useEffect(() => {
        if (hasActualisation) {
            duplicateSpecificPriceVariationSettingsFromAPreviousEngagement('actualisation');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [hasActualisation]);

    useEffect(() => {
        if (hasRevision) {
            duplicateSpecificPriceVariationSettingsFromAPreviousEngagement('revision');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [hasRevision]);

    const renderCalculatedPriceVariationSettings = () => (
        <>
            <GridRow>
                <GridCol
                    defaultScreen={6}
                    smallScreen={6}
                    style={{ borderRight: `0.0938rem dashed ${colors.neutral.N75}` }}
                >
                    <GridRow>
                        <StyledPermissionAwareToggle $isEditMode={isEditMode}>
                            <Text type="H500">{t('budget.priceVariations.actualisation')}</Text>
                            <PermissionAwareToggle
                                data-testid="priceVariationSettingsHasActualisation"
                                style={{ padding: '0 1rem' }}
                                permissionsRequired={permissionsNeeded}
                                name="priceVariationSettings.hasActualisation"
                                aspect="withText"
                                innerLabelChecked={t('general.active')}
                                innerLabel={t('general.inactive')}
                                editable={hasRightToEdit && isEditMode}
                                onChange={(e) => {
                                    formikProps.setFieldValue(
                                        'priceVariationSettings.indexSettings',
                                        formikProps.values.priceVariationSettings?.indexSettings.filter(
                                            ({ priceIndexId, factor }) => factor && priceIndexId,
                                        ),
                                    );
                                    setHasActualisation(e.target.checked);
                                }}
                            />
                        </StyledPermissionAwareToggle>
                    </GridRow>
                    <GridRow>
                        <GridCol defaultScreen={4} smallScreen={4}>
                            {formikProps.values.priceVariationSettings?.hasActualisation ||
                            formikProps.values.priceVariationSettings?.hasRevision ? (
                                <PermissionAwareDateInputField
                                    editable={hasRightToEdit && isEditMode}
                                    permissionsRequired={permissionsNeeded}
                                    name="priceVariationSettings.m0"
                                    label={t('budget.priceVariations.m0Month')}
                                    data-testid="priceVariationSettingsM0M"
                                    errorTestId="errorPriceVariationSettingsM0M"
                                    subLabel={t('budget.priceVariations.priceReference')}
                                    whiteBackground
                                    isMonthPicker
                                />
                            ) : null}
                        </GridCol>
                        <GridCol defaultScreen={5} smallScreen={5}>
                            {formikProps.values.priceVariationSettings?.hasActualisation ? (
                                <PermissionAwareDateInputField
                                    editable={hasRightToEdit && isEditMode}
                                    permissionsRequired={permissionsNeeded}
                                    name="priceVariationSettings.actualisationMonth"
                                    label={t('budget.priceVariations.actualisationMonth')}
                                    data-testid="priceVariationSettingsActualisationMonth"
                                    errorTestId="errorPriceVariationSettingsActualisationMonth"
                                    whiteBackground
                                    isMonthPicker
                                />
                            ) : null}
                        </GridCol>
                        <GridCol defaultScreen={3} smallScreen={3}>
                            {formikProps.values.priceVariationSettings?.hasActualisation &&
                            formikProps.values.priceVariationSettings.hasRevision ? (
                                <PermissionAwareDateInputField
                                    editable={false}
                                    permissionsRequired={permissionsNeeded}
                                    name="priceVariationSettings.actualisationMonth"
                                    label={t('budget.priceVariations.m1Month')}
                                    whiteBackground
                                    isMonthPicker
                                />
                            ) : null}
                        </GridCol>
                    </GridRow>
                </GridCol>
                <GridCol defaultScreen={6} smallScreen={6}>
                    <GridRow>
                        <StyledPermissionAwareToggle $isEditMode={isEditMode}>
                            <Text type="H500">{t('budget.priceVariations.revision')}</Text>
                            <PermissionAwareToggle
                                data-testid="priceVariationSettingsHasRevision"
                                permissionsRequired={permissionsNeeded}
                                name="priceVariationSettings.hasRevision"
                                aspect="withText"
                                innerLabelChecked={t('general.active')}
                                innerLabel={t('general.inactive')}
                                editable={hasRightToEdit && isEditMode}
                                onChange={(e) => {
                                    formikProps.setFieldValue(
                                        'priceVariationSettings.indexSettings',
                                        formikProps.values.priceVariationSettings?.indexSettings.filter(
                                            ({ priceIndexId, factor }) => factor && priceIndexId,
                                        ),
                                    );
                                    setHasRevision(e.target.checked);
                                }}
                            />
                        </StyledPermissionAwareToggle>
                    </GridRow>
                    <GridRow>
                        <GridCol defaultScreen={5} smallScreen={5}>
                            {formikProps.values.priceVariationSettings?.hasActualisation ||
                            formikProps.values.priceVariationSettings?.hasRevision ? (
                                <PermissionAwareText
                                    marginTop="0"
                                    editable={hasRightToEdit && isEditMode}
                                    permissionsRequired={permissionsNeeded}
                                    label={t('budget.priceVariations.shiftMonth')}
                                    name="priceVariationSettings.shift"
                                    type="text"
                                    data-testid="priceVariationSettingsShift"
                                    errorTestId="errorPriceVariationSettingsShift"
                                    numberType="shift"
                                    whiteBackground
                                />
                            ) : null}
                        </GridCol>
                        <StyledPriceVariationSettings $isEditMode={isEditMode}>
                            {formikProps.values.priceVariationSettings?.hasRevision ? (
                                <>
                                    <Text color={colors.neutral.N300}>{'a = '}</Text>
                                    <PermissionAwareText
                                        marginTop="0"
                                        editable={hasRightToEdit && isEditMode}
                                        permissionsRequired={permissionsNeeded}
                                        name="priceVariationSettings.a"
                                        type="text"
                                        data-testid="priceVariationSettingsA"
                                        errorTestId="errorPriceVariationSettingsA"
                                        numberType="factor"
                                        whiteBackground
                                    />
                                </>
                            ) : null}
                        </StyledPriceVariationSettings>
                        <StyledPriceVariationSettings $isEditMode={isEditMode}>
                            {formikProps.values.priceVariationSettings?.hasRevision ? (
                                <>
                                    <Text color={colors.neutral.N300}>{'b = '}</Text>
                                    <PermissionAwareText
                                        marginTop="0"
                                        editable={hasRightToEdit && isEditMode}
                                        permissionsRequired={permissionsNeeded}
                                        name="priceVariationSettings.b"
                                        type="text"
                                        data-testid="priceVariationSettingsB"
                                        errorTestId="errorPriceVariationSettingsB"
                                        numberType="factor"
                                        whiteBackground
                                    />
                                </>
                            ) : null}
                        </StyledPriceVariationSettings>
                    </GridRow>
                </GridCol>
            </GridRow>
            {formikProps.values.priceVariationSettings?.hasRevision ||
            formikProps.values.priceVariationSettings?.hasActualisation ? (
                <FieldArray
                    name="priceVariationSettings.indexSettings"
                    validateOnChange={false}
                    render={(arrayHelpers: FieldArrayRenderProps) => (
                        <>
                            {formulaError.length > 0 && indexSettingsIsTouched && (
                                <FieldErrorMessage>{formulaError}</FieldErrorMessage>
                            )}
                            <PriceVariationIndexSettings
                                isEditMode={hasRightToEdit && isEditMode}
                                permissionsNeeded={permissionsNeeded}
                                arrayHelpers={arrayHelpers}
                                hasRightToEdit={Boolean(hasRightToEdit && isEditMode)}
                                factorError={
                                    formulaError.length > 0 && indexSettingsIsTouched
                                        ? fieldError
                                        : undefined
                                }
                            />
                        </>
                    )}
                />
            ) : null}
        </>
    );

    const renderEnteredPriceVariationSettings = () => (
        <GridRow>
            <GridCol
                defaultScreen={6}
                smallScreen={6}
                style={{ borderRight: `0.0938rem dashed ${colors.neutral.N75}` }}
            >
                <GridCol defaultScreen={8} smallScreen={8}>
                    <PermissionAwareText
                        marginTop="0"
                        editable={hasRightToEdit && isEditMode}
                        permissionsRequired={permissionsNeeded}
                        label={t('budget.priceVariations.actualisationsAmountHt')}
                        name="priceVariationSettings.actualisationsAmountHt"
                        type="amount"
                        numberType="amount"
                        data-testid="priceVariationSettingsActualisationsAmountHt"
                        errorTestId="errorPriceVariationSettingsActualisationsAmountHt"
                        whiteBackground
                    />
                </GridCol>
            </GridCol>
            <GridCol defaultScreen={6} smallScreen={6}>
                <GridCol defaultScreen={8} smallScreen={8}>
                    <PermissionAwareText
                        marginTop="0"
                        editable={hasRightToEdit && isEditMode}
                        permissionsRequired={permissionsNeeded}
                        label={t('budget.priceVariations.revisionAmountHT')}
                        name="priceVariationSettings.revisionsAmountHt"
                        type="amount"
                        numberType="amount"
                        data-testid="priceVariationSettingsRevisionAmountHT"
                        errorTestId="errorPriceVariationSettingsRevisionsAmountHT"
                        whiteBackground
                    />
                </GridCol>
            </GridCol>
        </GridRow>
    );

    return (
        <>
            <GridRow>
                <GridCol defaultScreen={12} smallScreen={12}>
                    <StyledRadioGroup
                        name="priceVariationSettings.isSimulated"
                        onChange={handleRadioChange}
                        selectedValue={
                            priceVariationTypeField[0].value === true ? SIMULATED : ENTERED
                        }
                        disabled={Boolean(!(hasRightToEdit && isEditMode))}
                    >
                        <Radio
                            data-testid="priceVariationSettings.simulated"
                            value={SIMULATED}
                            style={{ marginRight: '0.5rem' }}
                            label={t('budget.priceVariations.priceVariationType.calculated')}
                        />
                        <Radio
                            data-testid="priceVariationSettings.entered"
                            value={ENTERED}
                            style={{ marginRight: '0.5rem' }}
                            label={t('budget.priceVariations.priceVariationType.entered')}
                        />
                    </StyledRadioGroup>
                </GridCol>
            </GridRow>
            <DashedSeparator />
            {priceVariationTypeField[0].value === true
                ? renderCalculatedPriceVariationSettings()
                : renderEnteredPriceVariationSettings()}
        </>
    );
};
export default PriceVariationTab;
