import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import type { FormikProps, FieldArrayRenderProps } from 'formik';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import { colors } from '../../constants/colors';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';

// Components
import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import type { ButtonProps } from '../../components/Button';
import Button from '../../components/Button';
import PermissionAwareText from '../../components/PermissionAwareText';
import PermissionAwareDisplay from '../../components/PermissionAwareDisplay';
import InformationCard from '../../components/InformationCard';
import GridCol from '../../components/GridCol';
import Text from '../../components/Text';
import type { IconName } from '../../components/Icon';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import { showFlag } from '../../components/Flag';
import LabelValueDropdown from '../../components/dropdown/LabelValueDropdown';
import OrganizationDropdown from '../../components/dropdown/OrganizationDropdown';
import FormikOptionDropdown from '../../components/dropdown/FormikOptionDropdown';
import Loader from '../../components/Loader';
import WarrantyLine from '../../components/WarrantyLine';
import PermissionAwareDateInputField from '../../components/PermissionAwareDateInputField';
import type { Option } from '../../components/SelectField';
import Tooltip from '../../components/Tooltip';

// Data
import { getOperation, selectOperation } from '../../slices/operationSlice';
import {
    selectOrganizationsForDropdown,
    getOrganizationsForDropdown,
    getOrganizationName,
} from '../../slices/organizationSlice';
import type { Label, LabelValue } from '../../slices/labelSlice';
import { getSpecificLabels, selectAllLabelsBy } from '../../slices/labelSlice';
import { styles } from '../../constants/styles';
import type { MarketFormData, Warranty, Market as MarketType } from '../../slices/marketSlice';
import {
    getMarket,
    selectMarket,
    selectError,
    getMarketsForDropdown,
    selectMarketsForDropdown,
    createMarket,
    selectMarketIdAdded,
    deleteMarket,
    updateMarket,
    selectMarketName,
    checkExternalNumberAndOrSliceCodeValidity,
    MARKET_EDIFLEX_IMPORT,
} from '../../slices/marketSlice';
import PermissionAwareToggle from '../../components/PermissionAwareToggle';
import GridRow from '../../components/GridRow';
import { CurrencyFormatter } from '../../utils/formatters';
import MarketDropdown from '../../components/dropdown/MarketDropdown';
import {
    validateMandatoryLabels,
    getMandatoryLabelIds,
} from '../../utils/mandatoryLabelsValidation';
import { useAppDispatch } from '../../store';

type Props = {
    headerProps: Partial<HeaderProps>;
};

const GrayContainer = styled.div`
    background: ${colors.neutral.N25};
    border-radius: ${styles.borderRadiusXSmall};
    display: flex;
    justify-content: space-between;
    padding-left: 0.75rem;
    padding-top: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    align-items: center;
`;

type InfoProps = {
    isEditMode?: boolean;
};

const AdvanceAndDownPaymentPart = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
        width: 50%;
    }
`;

const WrapperToggleWithLabel = styled.div<InfoProps>`
    display: flex;
    flex-direction: ${({ isEditMode }) => (isEditMode ? 'row' : 'column')};
`;
const HoldbackPercentagePart = styled.div`
    display: flex;
    > p {
        display: inline-block;
        padding-top: 0.5rem;
    }
    > div {
        display: contents;
        > div {
            width: 4.5rem;
            margin: 0 1rem;
        }
        > p {
            width: 4.5rem;
            padding-top: 0.5rem;
            display: inline-block;
            text-align: center;
        }
    }
`;

const WarrantiesTable = styled.div`
    background: ${colors.neutral.N25};
    border-radius: ${styles.borderRadiusSmall};
    border: 0.09375rem solid ${colors.neutral.N75};
`;

const WarrantiesTableHeader = styled(GridRow)`
    div > p {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    border-bottom: 0.09375rem solid ${colors.neutral.N75};
`;

export const checkIfMarketHasBeenImportedFromEdiflex = (market: MarketType) =>
    market.marketEntryMode === MARKET_EDIFLEX_IMPORT;

const Market: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const serverError = useSelector(selectError);
    const { operationId, marketId }: { operationId?: string; marketId?: string } = useParams();
    let isCreation = false;
    if (marketId === 'new') {
        isCreation = true;
    }
    let permissionNeeded = {
        code: 'MARKETS_EDIT',
        operationId,
    };
    if (isCreation) {
        permissionNeeded = {
            code: 'MARKETS_ADD',
            operationId,
        };
    }

    const hasRightToEditOrAddMarkets = usePermissionsCheck([permissionNeeded]);
    const hasRightToDeleteMarkets = usePermissionsCheck([{ code: 'MARKETS_DELETE', operationId }]);
    const market = useSelector(selectMarket(Number(marketId)));
    const marketName = useSelector(selectMarketName(Number(marketId)));
    const operation = useSelector(selectOperation(Number(operationId)));
    const marketIdAdded = useSelector(selectMarketIdAdded);
    const organizations = useSelector(selectOrganizationsForDropdown);
    const marketsForDropDown = useSelector(selectMarketsForDropdown).filter(
        ({ id }) => id !== Number(marketId),
    );
    const labels = useSelector(
        selectAllLabelsBy('market', operation?.clientId, Number(operationId)),
    );
    const [marketHolderId, setMarketHolderId] = useState<string | undefined>(
        market?.marketHolderId,
    );
    const [isPending, setIsPending] = useState<boolean>(false);
    const [isDeletePending, setIsDeletePending] = useState<boolean>(false);

    const isMarketImportedFromEdiflex = market
        ? checkIfMarketHasBeenImportedFromEdiflex(market)
        : false;

    const location = useLocation();
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState<boolean>(
        Boolean(location.search === '?edit' || isCreation),
    );
    const [externalNumberError, setExternalNumberError] = useState<string | undefined>();
    const [sliceCodeError, setSliceCodeError] = useState<string | undefined>();
    const [modalOptions, setModalOptions] = useState<{
        title: string;
        text: string;
        confirmationButton: string;
        iconName: IconName;
        iconColor: string;
        callback: () => void;
    } | null>(null);

    const computeInitialSelectedLabelValues = (marketLabelValues: LabelValue[]) =>
        marketLabelValues.map((labelValue) => {
            const labelValueWithLinks = labels
                .find(({ id }) => labelValue.labelId === id)
                ?.labelValues.find(({ id }) => labelValue.id === id);
            const links = labelValueWithLinks?.linkedLabelValues ?? [];
            return {
                ...labelValue,
                hasLinks: links.length > 0,
            };
        });

    const [selectedLabelValues, setSelectedLabelValues] = useState<
        Array<{ labelId: Label['id']; id: LabelValue['id']; hasLinks: boolean; value: string }>
    >([]);

    const state = location.state ?? {};
    const labelsInformationCardRef = useRef<HTMLDivElement>(null);
    const worksReceptionCardRef = useRef<HTMLDivElement>(null);
    const warrantiesCardRef = useRef<HTMLDivElement>(null);

    const marketTypeOptions: Array<Option<string>> = [
        {
            value: 'initial_market',
            label: t('market.identity.initialMarket'),
        },
        {
            value: 'complementary_market',
            label: t('market.identity.complementaryMarket'),
        },
    ];

    const NatureOfServicesOptions: Array<Option<string>> = [
        {
            value: 'lump_sum_market',
            label: t('market.identity.lumpSumMarket'),
        },
        {
            value: 'purchase_order_market',
            label: t('market.identity.purchaseOrderMarket'),
        },
    ];

    const marketEntryModes: Array<Option<string>> = [
        {
            value: 'manual_entry',
            label: t('market.identity.manualEntry'),
        },
        {
            value: 'ediflex_import',
            label: t('market.identity.importFromThirdParty'),
        },
    ];

    useEffect(() => {
        if (isDeletePending && !market) {
            navigate(`/operations/${operationId}/markets`);
            showFlag('success', t('general.success'), t('market.successfullyDeleted'));
        } else {
            if (serverError) {
                showFlag('error', t('errors.error'), t(serverError));
                setIsDeletePending(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on every dependecies change
    }, [operationId, market, navigate, serverError, t]);

    useEffect(() => {
        if (operationId && operation?.clientId) {
            void dispatch(
                getSpecificLabels({
                    type: 'market',
                    operationId: Number(operationId),
                    clientId: operation.clientId,
                }),
            );
        } else {
            void dispatch(getSpecificLabels({ type: 'market' }));
        }
    }, [dispatch, operationId, operation?.clientId]);

    useEffect(() => {
        if (market) {
            setSelectedLabelValues(computeInitialSelectedLabelValues(market.labelValues));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on every dependecies change
    }, [market]);

    useEffect(() => {
        if (serverError === 'errors.marketMustExist') {
            navigate(`/operations/${operationId}/markets`);
        }
    }, [serverError, navigate, operationId]);

    useEffect(() => {
        if (marketId && marketId !== 'new' && operationId) {
            void dispatch(getMarket({ id: Number(marketId), operationId: Number(operationId) }));
            void dispatch(getOrganizationsForDropdown());
        }
    }, [dispatch, marketId, operationId]);

    useEffect(() => {
        if (operationId) {
            void dispatch(getOperation(Number(operationId)));
        }
    }, [dispatch, operationId]);

    useEffect(() => {
        if (isEditMode && operationId) {
            void dispatch(getOrganizationsForDropdown());
            void dispatch(getMarketsForDropdown({ operationId: Number(operationId) }));
        }
    }, [dispatch, isEditMode, operationId]);

    useEffect(() => {
        if (marketHolderId) {
            void dispatch(
                getMarketsForDropdown({ operationId: Number(operationId), marketHolderId }),
            );
        }
    }, [dispatch, marketHolderId, operationId]);

    useEffect(() => {
        if (isPending) {
            if (serverError) {
                showFlag('error', t('errors.error'), t(serverError));
                setIsPending(false);
                setIsEditMode(true);
            } else {
                if (isCreation) {
                    showFlag('success', t('general.success'), t('market.successfullyCreated'));
                    navigate(`${location.pathname.split('/new')[0]}/${marketIdAdded}`, {
                        state,
                        replace: true,
                    });
                } else {
                    showFlag('success', t('general.success'), t('market.successfullyUpdated'));
                }
                setIsPending(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to update on every dependecies change
    }, [serverError, t, isCreation, marketIdAdded]);

    const debouncedValidation = useDebouncedCallback(
        async (externalNumber: string, sliceCode?: string | null) => {
            if (externalNumber.length === 0) {
                setExternalNumberError(undefined);
            }
            if (sliceCode && sliceCode.length === 0) {
                setSliceCodeError(undefined);
            }
            if (externalNumber.length > 0 && sliceCode && sliceCode.length > 0 && marketId) {
                try {
                    await checkExternalNumberAndOrSliceCodeValidity(
                        Number(operationId),
                        Number(marketId),
                        externalNumber,
                        sliceCode,
                    );
                    setSliceCodeError(undefined);
                    setExternalNumberError(undefined);
                } catch (e: unknown) {
                    setSliceCodeError(t('errors.externalNumberSliceCodeUnique'));
                }
            } else if (externalNumber.length > 0 && marketId) {
                try {
                    await checkExternalNumberAndOrSliceCodeValidity(
                        Number(operationId),
                        Number(marketId),
                        externalNumber,
                    );
                    setExternalNumberError(undefined);
                } catch (e: unknown) {
                    setExternalNumberError(t('errors.externalNumberUnique'));
                }
            }
        },
        500,
    );

    if (marketId && marketId !== 'new' && !market) {
        return <Loader overlay />;
    }

    const marketSchema = Yup.object().shape({
        label: Yup.string()
            .required(t('errors.required'))
            .max(120, t('errors.tooLong', { number: 120 })),
        externalNumber: Yup.string()
            .required(t('errors.required'))
            .max(50, t('errors.tooLong', { number: 50 })),
        projectOwnerId: Yup.string().required(t('errors.required')),
        paymentDelay: Yup.number().required(t('errors.required')),
        marketType: Yup.mixed()
            .oneOf(['initial_market', 'complementary_market'])
            .required(t('errors.required')),
        initialAmountHt: Yup.string().required(t('errors.required')),
        natureOfServices: Yup.mixed()
            .oneOf(['lump_sum_market', 'purchase_order_market'])
            .required(t('errors.required')),
        parentMarketId: Yup.number()
            .nullable()
            .when('marketType', {
                is: (value: string) => value === 'complementary_market',
                then: Yup.number().required(t('errors.required')),
            }),
        reserveRemovalDate: Yup.string().nullable(),
        sliceCode: Yup.string()
            .nullable()
            .when('isSlicedMarket', {
                is: true,
                then: Yup.string()
                    .required(t('errors.required'))
                    .max(4, t('errors.tooLong', { number: 4 })),
            }),
        warranties: Yup.array()
            .of(
                Yup.object().shape({
                    number: Yup.string().required(t('errors.required')).nullable(),
                    reference: Yup.string().required(t('errors.required')).nullable(),
                    issuer: Yup.string().required(t('errors.required')).nullable(),
                    date: Yup.string().required(t('errors.required')).nullable(),
                    amountTtc: Yup.number().required(t('errors.required')).nullable(),
                    marketId: Yup.number(),
                    fileId: Yup.number().nullable(),
                }),
            )
            .compact(({ fileId, ...warranty }) => {
                const values = Object.values(warranty);
                const valueFound = Boolean(
                    values.find((value) => typeof value === 'string' || typeof value === 'number'),
                );
                return !valueFound;
            }),
        marketEntryMode: Yup.mixed()
            .oneOf(['manual_entry', 'ediflex_import'])
            .required(t('errors.required')),
    });

    const handleDeleteClick = () => {
        if (market?.hasMarketInvoices) {
            setModalOptions({
                title: 'market.deletion',
                text: 'market.cantDeleteBecauseOfExistingMarketInvoices',
                confirmationButton: 'market.goToListOfInvoices',
                iconName: 'Cancel',
                iconColor: colors.red.R500,
                callback: () => navigate(`/operations/${operationId}/markets/${marketId}/invoices`),
            });
        } else if (market?.hasEngagements) {
            setModalOptions({
                title: 'market.deletion',
                text: 'market.cantDeleteBecauseOfExistingEngagements',
                confirmationButton: 'market.goToListOfEngagements',
                iconName: 'Cancel',
                iconColor: colors.red.R500,
                callback: () =>
                    navigate(`/operations/${operationId}/markets/${marketId}/engagements`),
            });
        } else {
            setModalOptions({
                title: 'market.deletion',
                text: 'market.confirmDeletion',
                confirmationButton: 'general.confirm',
                iconName: 'Warning',
                iconColor: colors.yellow.Y400,
                callback() {
                    void dispatch(
                        deleteMarket({
                            id: Number(marketId),
                            operationId: Number(operationId),
                        }),
                    );
                    setIsDeletePending(true);
                },
            });
        }
    };

    const computeButtonsOutOfProps = (props: FormikProps<MarketFormData>): ButtonProps[] => {
        const buttons: ButtonProps[] = [];
        if (hasRightToEditOrAddMarkets) {
            if (isEditMode) {
                if (hasRightToDeleteMarkets) {
                    buttons.push({
                        'data-testid': 'deleteMarket',
                        aspect: 'onlyIcon',
                        iconName: 'DeleteOutline',
                        onClick: handleDeleteClick,
                    });
                }
                buttons.push(
                    {
                        text: t('general.cancel'),
                        'data-testid': 'cancelModifyMarket',
                        aspect: 'secondary',
                        onClick() {
                            const actualWarrantiesFileIds = props.values.warranties.reduce(
                                (result: number[], warranty) => {
                                    if (warranty.fileId && warranty.fileId !== 0) {
                                        result = [...result, warranty.fileId];
                                    }
                                    return result;
                                },
                                [],
                            );
                            const initialWarrantiesFileIds = market?.warranties.map(
                                (warranty) => warranty.fileId,
                            );
                            if (
                                initialWarrantiesFileIds &&
                                JSON.stringify(initialWarrantiesFileIds.sort()) !==
                                    JSON.stringify(actualWarrantiesFileIds.sort())
                            ) {
                                showFlag(
                                    'error',
                                    t('errors.error'),
                                    t('errors.warrantiesFileIdsShouldBeTheSame'),
                                );
                                return;
                            }
                            props.resetForm();
                            if (isCreation) {
                                navigate(-1);
                            } else {
                                navigate(location.pathname.replace('?edit', ''), {
                                    state,
                                    replace: true,
                                });
                                setIsEditMode(false);
                            }
                        },
                    },
                    {
                        text: isCreation ? t('general.create') : t('general.submit'),
                        'data-testid': 'submitMarket',
                        aspect: 'primary',
                        onClick() {
                            props.handleSubmit();
                            // We use errorKeys here to leverage yup/Formik's validation
                            const errorKeys = Object.keys(props.errors);
                            if (
                                errorKeys.length > 0 &&
                                (errorKeys.includes('label') ||
                                    errorKeys.includes('internalNumber') ||
                                    errorKeys.includes('externalNumber') ||
                                    errorKeys.includes('projectOwnerId') ||
                                    errorKeys.includes('marketType') ||
                                    errorKeys.includes('initialAmountHt') ||
                                    errorKeys.includes('paymentDelay') ||
                                    errorKeys.includes('parentMarketId') ||
                                    errorKeys.includes('sliceCode') ||
                                    errorKeys.includes('natureOfServices'))
                            ) {
                                scrollToTop();
                            } else if (
                                errorKeys.length > 0 &&
                                errorKeys.includes('reserveRemovalDate')
                            ) {
                                scrollToWorksReception();
                            } else if (errorKeys.length > 0 && errorKeys.includes('warranties')) {
                                scrollToWarranties();
                            }
                        },
                    },
                );
            } else {
                buttons.push({
                    text: t('market.modifyMarket'),
                    'data-testid': 'modifyMarket',
                    aspect: 'primary',
                    onClick() {
                        navigate(`${location.pathname}?edit`, { state, replace: true });
                        setIsEditMode(true);
                    },
                });
            }
        }
        return buttons;
    };

    const computeLabelValueDisplay = (marketLabelValues: LabelValue[], label: Label) => {
        let nameDisplay = isEditMode ? t('operation.labels.selectValue') : '-';
        const labelValue = marketLabelValues.find(
            (value: LabelValue) => value.labelId === label.id,
        )?.value;
        if (labelValue) {
            nameDisplay = labelValue;
        }
        return nameDisplay;
    };

    const computeLabelError = (labelId: Label['id']) => {
        const mandatoryLabelIds = getMandatoryLabelIds(labels);
        const labelValueId = selectedLabelValues.find(
            (labelValue) => labelValue.labelId === labelId,
        )?.id;
        return mandatoryLabelIds.includes(labelId) && !labelValueId;
    };

    const handleLabelValueChange = (
        arrayHelpers: FieldArrayRenderProps,
        marketLabelValues: LabelValue[],
        labelValueSelected: LabelValue,
    ) => {
        // formik management
        const index = marketLabelValues.findIndex(
            (labelValue: LabelValue) => labelValue.labelId === labelValueSelected.labelId,
        );

        if (index >= 0) {
            if (labelValueSelected.id !== 0) {
                arrayHelpers.replace(index, labelValueSelected);
            } else {
                arrayHelpers.remove(index);
            }
        } else {
            if (labelValueSelected.id !== 0) {
                arrayHelpers.push(labelValueSelected);
            }
        }

        // linkedLabelValues management
        const { linkedLabelValues, ...selectedValue } = labelValueSelected;
        const newSelectedValue = {
            ...selectedValue,
            hasLinks: Boolean(
                labelValueSelected.linkedLabelValues &&
                    labelValueSelected.linkedLabelValues.length > 0,
            ),
        };
        const newList = [...selectedLabelValues];
        const selectedValuesListIndex = selectedLabelValues.findIndex(
            (labelValue) => labelValue.labelId === labelValueSelected.labelId,
        );
        if (
            selectedValuesListIndex >= 0 &&
            newList[selectedValuesListIndex].id !== labelValueSelected.id
        ) {
            newList[selectedValuesListIndex] = newSelectedValue;
            setSelectedLabelValues(newList);
        } else if (selectedValuesListIndex < 0) {
            newList.push(newSelectedValue);
            setSelectedLabelValues(newList);
        }
    };

    const emptyField = '';

    // Scroll
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error, @typescript-eslint/ban-ts-comment -- needed
    // @ts-ignore
    const isIE11 = Boolean(window.msCrypto);
    const scrollToTop = () => {
        let scrollableParent = document.querySelector('.scrollable-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (scrollableParent) {
            scrollableParent.scrollTop = 0;
        }
    };
    const scrollToLabels = () => {
        let scrollableParent = document.querySelector('.scrollable-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (labelsInformationCardRef.current && scrollableParent) {
            // 100 is the header height
            scrollableParent.scrollTop = labelsInformationCardRef.current.offsetTop - 100;
        }
    };

    const scrollToWorksReception = () => {
        let scrollableParent = document.querySelector('.scrollable-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (worksReceptionCardRef.current && scrollableParent) {
            // 100 is the header height
            scrollableParent.scrollTop = worksReceptionCardRef.current.offsetTop - 100;
        }
    };

    const scrollToWarranties = () => {
        let scrollableParent = document.querySelector('.scrollable-content');

        if (isIE11) {
            scrollableParent = scrollableParent?.childNodes[0] as Element;
        }

        if (warrantiesCardRef.current && scrollableParent) {
            // 100 is the header height
            scrollableParent.scrollTop = warrantiesCardRef.current.offsetTop - 100;
        }
    };

    const getIsJointGroupMarketTooltipContent = (): string => {
        let content = '';
        if (isEditMode && market?.hasEngagements) {
            content = t('market.cantChangeJointGroupBecauseOfExistingEngagements');
        }
        if (isEditMode && isMarketImportedFromEdiflex) {
            content = t('market.cantChangeJointGroupBecauseMarketHasBeenImportedFromThirdParty');
        }
        return content;
    };

    const checkIfIsJointGroupMarketFieldShouldBeDisabled = (): boolean => {
        let isJointGroupMarketFieldShouldBeDisabled = false;
        if (market?.hasEngagements) {
            isJointGroupMarketFieldShouldBeDisabled = true;
        }
        if (isMarketImportedFromEdiflex) {
            isJointGroupMarketFieldShouldBeDisabled = true;
        }
        return isJointGroupMarketFieldShouldBeDisabled;
    };

    const getInitialProjectOwnerId = () => {
        if (market?.projectOwnerId) {
            return market.projectOwnerId;
        }
        if (operation?.projectOwnerId) {
            return operation.projectOwnerId;
        }
        return emptyField;
    };

    const initialValues = {
        id: market?.id,
        operationId: Number(operationId),
        label: market?.label ?? emptyField,
        internalNumber: market?.internalNumber ?? emptyField,
        externalNumber: market?.externalNumber ?? emptyField,
        parentMarketId: market?.parentMarketId ?? null,
        isSlicedMarket: market?.isSlicedMarket ?? false,
        sliceCode: market?.sliceCode ?? emptyField,
        isJointGroupMarket: market?.isJointGroupMarket ?? false,
        marketType: market?.marketType ?? 'initial_market',
        initialAmountHt: market?.initialAmountHt ?? 0,
        paymentDelay: market?.paymentDelay ?? 30,
        projectOwnerId: getInitialProjectOwnerId(),
        marketHolderId: market?.marketHolderId ?? emptyField,
        hasAdvancePayment: market?.hasAdvancePayment ?? false,
        hasDownPayment: market?.hasDownPayment ?? false,
        hasHoldback: market?.hasHoldback ?? false,
        holdbackDefaultPercentage: market?.holdbackDefaultPercentage
            ? Number(market.holdbackDefaultPercentage) * 100
            : 5,
        worksReceptionDate: market?.worksReceptionDate ?? null,
        worksReceptionWithReserve: market?.worksReceptionWithReserve ?? false,
        reserveRemovalDate: market?.reserveRemovalDate ?? null,
        minutesEndDate: market?.minutesEndDate ?? null,
        worksReceptionNotificationDate: market?.worksReceptionNotificationDate ?? null,
        natureOfServices: market?.natureOfServices ?? 'lump_sum_market',
        labelValues: market?.labelValues ?? [],
        warranties: market?.warranties ?? [],
        marketEntryMode: market?.marketEntryMode ?? 'manual_entry',
    } as MarketFormData;

    return operation ? (
        <Formik
            initialValues={initialValues}
            validationSchema={marketSchema}
            onSubmit={(values: MarketFormData, actions) => {
                const mandatoryLabelsAreValid = validateMandatoryLabels(values.labelValues, labels);
                if (mandatoryLabelsAreValid) {
                    if (!values.isSlicedMarket) {
                        values.sliceCode = null;
                    }
                    if (values.marketType === 'initial_market') {
                        values.parentMarketId = null;
                    }
                    if (!values.worksReceptionWithReserve) {
                        values.reserveRemovalDate = null;
                    }
                    if (!values.hasHoldback) {
                        values.holdbackDefaultPercentage = null;
                        values.warranties = [];
                    } else {
                        values.holdbackDefaultPercentage = values.holdbackDefaultPercentage
                            ? values.holdbackDefaultPercentage / 100
                            : null;
                        const removedEmptyWarranty = values.warranties.filter(
                            ({ fileId, ...warranty }) => {
                                const warrantyValues = Object.values(warranty);
                                const valueFound = Boolean(
                                    warrantyValues.find(
                                        (value) =>
                                            typeof value === 'string' || typeof value === 'number',
                                    ),
                                );
                                return valueFound;
                            },
                        );
                        values.warranties = removedEmptyWarranty;
                    }

                    values.parentMarketId = Number(values.parentMarketId);
                    values.paymentDelay = Number(values.paymentDelay);
                    void dispatch(isCreation ? createMarket(values) : updateMarket(values));
                    actions.setSubmitting(false);
                    setIsEditMode(false);
                    navigate(location.pathname.replace('?edit', ''), { state, replace: true });
                    setIsPending(true);
                } else {
                    scrollToLabels();
                }
            }}
            enableReinitialize
        >
            {(props: FormikProps<MarketFormData>) => (
                <MainLayout
                    header={
                        <Header
                            {...headerProps}
                            title={isCreation || !marketName ? t('markets.addMarket') : marketName}
                            goBackTo={`/operations/${operationId}/markets`}
                            showNavigation={!isEditMode}
                            isDirty={Object.keys(props.touched).length > 0}
                            buttons={computeButtonsOutOfProps(props)}
                            showBackButton
                        />
                    }
                >
                    <Form>
                        <InformationCard
                            title={t('market.identity.title')}
                            style={
                                isEditMode
                                    ? { padding: '2rem 2rem 1rem 2rem' }
                                    : { padding: '2rem 2rem 1.5rem 2rem' }
                            }
                            headerTrailingComponent={
                                !isCreation && (
                                    <GrayContainer>
                                        <Text
                                            style={{ paddingRight: '1rem', margin: '0' }}
                                            color={colors.neutral.N300}
                                            data-testid="marketInternalNumber"
                                        >
                                            {t('market.identity.marketNumber')}
                                        </Text>
                                        <Text style={{ margin: 0 }}>{market?.internalNumber}</Text>
                                    </GrayContainer>
                                )
                            }
                        >
                            <GridCol defaultScreen={6} smallScreen={6}>
                                <GridRow>
                                    <GridCol defaultScreen={6} smallScreen={6}>
                                        <PermissionAwareDisplay
                                            label={t('market.identity.operationNumber')}
                                            data-testid="operationNumber"
                                            value={operation.internalNumber}
                                            style={{ marginTop: '0' }}
                                        />
                                    </GridCol>
                                    <GridCol defaultScreen={6} smallScreen={6}>
                                        <PermissionAwareDisplay
                                            label={t('market.identity.siretMOA')}
                                            data-testid="siretMOA"
                                            value={operation.projectOwnerId}
                                            style={{ marginTop: '0' }}
                                        />
                                    </GridCol>
                                </GridRow>
                                <GridRow style={{ marginTop: '1.25rem' }}>
                                    <GridCol defaultScreen={6} smallScreen={6}>
                                        <WrapperToggleWithLabel isEditMode={isEditMode}>
                                            <Text color={colors.neutral.N300}>
                                                {t('market.identity.sliceMarket')}
                                            </Text>
                                            <PermissionAwareToggle
                                                permissionsRequired={[permissionNeeded]}
                                                name="isSlicedMarket"
                                                aspect="large"
                                                editable={isEditMode}
                                                disabled={isMarketImportedFromEdiflex}
                                                style={{ marginTop: '-0.5rem' }}
                                                icon
                                            />
                                        </WrapperToggleWithLabel>
                                    </GridCol>
                                    <GridCol defaultScreen={6} smallScreen={6}>
                                        <PermissionAwareText
                                            marginTop="0"
                                            editable={isEditMode}
                                            disabled={isMarketImportedFromEdiflex}
                                            permissionsRequired={[permissionNeeded]}
                                            label={t('market.identity.externalMarketNumber')}
                                            name="externalNumber"
                                            type="text"
                                            placeholder={t('market.identity.externalMarketNumber')}
                                            data-testid="externalNumber"
                                            errorTestId="errorExternalMarketNumber"
                                            onChange={(e) => {
                                                void debouncedValidation(
                                                    e.target.value,
                                                    props.values.sliceCode,
                                                );
                                            }}
                                            rightComponent={
                                                externalNumberError ? (
                                                    <Icon
                                                        tooltip={externalNumberError}
                                                        name="Cancel"
                                                        color={colors.red.R400}
                                                    />
                                                ) : undefined
                                            }
                                            noMarginTop
                                            whiteBackground
                                        />
                                    </GridCol>
                                </GridRow>
                                <GridRow style={{ marginTop: '1.25rem' }}>
                                    <GridCol defaultScreen={12} smallScreen={12}>
                                        <PermissionAwareText
                                            marginTop="0"
                                            editable={isEditMode}
                                            disabled={isMarketImportedFromEdiflex}
                                            permissionsRequired={[permissionNeeded]}
                                            label={t('market.identity.marketLabel')}
                                            name="label"
                                            type="text"
                                            data-testid="marketLabel"
                                            errorTestId="errorMarketLabel"
                                            noMarginTop
                                            whiteBackground
                                        />
                                    </GridCol>
                                </GridRow>
                                <GridRow style={{ marginTop: '1.25rem' }}>
                                    <GridCol defaultScreen={6} smallScreen={6}>
                                        <WrapperToggleWithLabel isEditMode={isEditMode}>
                                            <Text color={colors.neutral.N300}>
                                                {t('market.identity.jointGroupMarket')}
                                            </Text>
                                            <Tooltip
                                                centeredContent={isEditMode}
                                                content={getIsJointGroupMarketTooltipContent()}
                                            >
                                                <PermissionAwareToggle
                                                    permissionsRequired={[permissionNeeded]}
                                                    disabled={checkIfIsJointGroupMarketFieldShouldBeDisabled()}
                                                    name="isJointGroupMarket"
                                                    aspect="large"
                                                    editable={isEditMode}
                                                    style={{ marginTop: '-0.5rem' }}
                                                    icon
                                                />
                                            </Tooltip>
                                        </WrapperToggleWithLabel>
                                    </GridCol>
                                </GridRow>
                            </GridCol>
                            <GridCol defaultScreen={6} smallScreen={6}>
                                <GridRow>
                                    <GridCol defaultScreen={12} smallScreen={12}>
                                        <PermissionAwareDisplay
                                            label={t('market.identity.amountHt')}
                                            data-testid="initialAmountHT"
                                            value={
                                                market?.initialAmountHt &&
                                                CurrencyFormatter.format(market.initialAmountHt)
                                            }
                                            style={{ marginTop: '0' }}
                                            isNumeral
                                        />
                                    </GridCol>
                                </GridRow>
                                <GridRow
                                    style={
                                        props.values.isSlicedMarket ? { marginTop: '1.25rem' } : {}
                                    }
                                >
                                    <GridCol defaultScreen={6} smallScreen={6}>
                                        {props.values.isSlicedMarket && (
                                            <PermissionAwareText
                                                marginTop="0"
                                                editable={isEditMode}
                                                permissionsRequired={[permissionNeeded]}
                                                label={t('market.identity.slice')}
                                                name="sliceCode"
                                                type="text"
                                                placeholder={t('market.identity.slicePlaceholder')}
                                                data-testid="sliceCode"
                                                errorTestId="errorSliceCode"
                                                onChange={(e) => {
                                                    void debouncedValidation(
                                                        props.values.externalNumber,
                                                        e.target.value,
                                                    );
                                                }}
                                                rightComponent={
                                                    sliceCodeError ? (
                                                        <Icon
                                                            tooltip={sliceCodeError}
                                                            name="Cancel"
                                                            color={colors.red.R400}
                                                        />
                                                    ) : undefined
                                                }
                                                noMarginTop
                                                whiteBackground
                                            />
                                        )}
                                    </GridCol>
                                </GridRow>
                                <GridRow
                                    style={
                                        isEditMode
                                            ? { marginTop: '0.875rem' }
                                            : { marginTop: '1.25rem' }
                                    }
                                >
                                    <GridCol defaultScreen={12} smallScreen={12}>
                                        {isEditMode && hasRightToEditOrAddMarkets ? (
                                            <OrganizationDropdown
                                                items={organizations}
                                                label={t(
                                                    'market.identity.organizationRepresentative',
                                                )}
                                                initialOrganizationId={props.values.marketHolderId}
                                                handleChange={({ id }) => setMarketHolderId(id)}
                                                name="marketHolderId"
                                                data-testid="marketHolderId"
                                                errorTestId="errorMarketHolderId"
                                                filterable
                                                shouldBeResettable
                                            />
                                        ) : (
                                            <PermissionAwareDisplay
                                                label={t(
                                                    'market.identity.organizationRepresentative',
                                                )}
                                                value={getOrganizationName(market?.marketHolder)}
                                                style={{ marginTop: '0' }}
                                            />
                                        )}
                                    </GridCol>
                                </GridRow>
                                <GridRow style={{ marginTop: '1.25rem' }}>
                                    <GridCol defaultScreen={6} smallScreen={6}>
                                        <PermissionAwareText
                                            marginTop="0"
                                            editable={isEditMode}
                                            disabled={isMarketImportedFromEdiflex}
                                            permissionsRequired={[permissionNeeded]}
                                            label={t('market.identity.paymentDelay')}
                                            name="paymentDelay"
                                            type="text"
                                            placeholder={t('market.identity.paymentDelay')}
                                            data-testid="paymentDelay"
                                            errorTestId="errorPaymentDelay"
                                            noMarginTop
                                            whiteBackground
                                        />
                                    </GridCol>
                                </GridRow>
                            </GridCol>
                            <GridCol defaultScreen={12} smallScreen={12}>
                                <GridRow style={{ marginTop: '1.25rem' }}>
                                    <GridCol defaultScreen={3} smallScreen={3}>
                                        {isEditMode && hasRightToEditOrAddMarkets ? (
                                            <FormikOptionDropdown
                                                items={NatureOfServicesOptions}
                                                name="natureOfServices"
                                                initialNameDiplay={
                                                    NatureOfServicesOptions.find(
                                                        (natureOfServicesOption) =>
                                                            natureOfServicesOption.value ===
                                                            props.values.natureOfServices,
                                                    )?.label ??
                                                    t('market.identity.chooseNatureOfServices')
                                                }
                                                label={t('market.identity.natureOfServices')}
                                                data-testid="natureOfServices"
                                                errorTestId="errorNatureOfServices"
                                                style={{ margin: '0' }}
                                            />
                                        ) : (
                                            <PermissionAwareDisplay
                                                label={t('market.identity.natureOfServices')}
                                                data-testid="natureOfServicesDisplay"
                                                value={
                                                    NatureOfServicesOptions.find(
                                                        (natureOfServicesOption) =>
                                                            natureOfServicesOption.value ===
                                                            props.values.natureOfServices,
                                                    )?.label ?? `-`
                                                }
                                                style={{ marginTop: '0' }}
                                            />
                                        )}
                                    </GridCol>
                                    <GridCol defaultScreen={3} smallScreen={3}>
                                        {isEditMode && hasRightToEditOrAddMarkets ? (
                                            <FormikOptionDropdown
                                                items={marketTypeOptions}
                                                name="marketType"
                                                initialNameDiplay={
                                                    marketTypeOptions.find(
                                                        (marketType) =>
                                                            marketType.value ===
                                                            props.values.marketType,
                                                    )?.label ??
                                                    t('market.identity.chooseMarketType')
                                                }
                                                label={t('market.identity.marketType')}
                                                data-testid="marketType"
                                                errorTestId="errorMarketType"
                                                style={{ margin: '0' }}
                                            />
                                        ) : (
                                            <PermissionAwareDisplay
                                                label={t('market.identity.marketType')}
                                                data-testid="marketTypeDisplay"
                                                value={
                                                    marketTypeOptions.find(
                                                        (marketType) =>
                                                            marketType.value === market?.marketType,
                                                    )?.label ?? `-`
                                                }
                                                style={{ marginTop: '0' }}
                                            />
                                        )}
                                    </GridCol>
                                    <GridCol defaultScreen={3} smallScreen={3}>
                                        {props.values.marketType === 'complementary_market' &&
                                            (isEditMode && hasRightToEditOrAddMarkets ? (
                                                <MarketDropdown
                                                    items={marketsForDropDown}
                                                    label={t('market.identity.initialMarket')}
                                                    initialMarketId={props.values.parentMarketId}
                                                    name="parentMarketId"
                                                    data-testid="parentMarketId"
                                                    errorTestId="errorParentMarketId"
                                                    filterable
                                                />
                                            ) : (
                                                <PermissionAwareDisplay
                                                    label={t('market.identity.initialMarket')}
                                                    value={
                                                        market?.parentMarket
                                                            ? `${market.parentMarket.internalNumber} ${market.parentMarket.label}`
                                                            : `-`
                                                    }
                                                    style={{ marginTop: '0' }}
                                                />
                                            ))}
                                    </GridCol>
                                </GridRow>
                                <GridRow style={{ marginTop: '1.25rem' }}>
                                    <GridCol defaultScreen={3} smallScreen={3}>
                                        <PermissionAwareDisplay
                                            label={t('market.identity.marketEntryMode')}
                                            data-testid="marketEntryMode"
                                            value={
                                                marketEntryModes.find(
                                                    (marketEntryMode) =>
                                                        marketEntryMode.value ===
                                                        market?.marketEntryMode,
                                                )?.label ?? `-`
                                            }
                                            style={{ marginTop: '0' }}
                                        />
                                    </GridCol>
                                </GridRow>
                            </GridCol>
                        </InformationCard>
                        <FieldArray
                            name="labelValues"
                            render={(arrayHelpers: FieldArrayRenderProps) => (
                                <InformationCard
                                    title={t('market.labels.title')}
                                    style={
                                        isEditMode
                                            ? { padding: '2rem 2rem 1.5rem 2rem' }
                                            : { padding: '2rem 2rem 1.5rem 2rem' }
                                    }
                                >
                                    <div ref={labelsInformationCardRef} />
                                    {labels.map((label: Label) => (
                                        <GridCol key={label.id} defaultScreen={4} smallScreen={4}>
                                            {isEditMode && hasRightToEditOrAddMarkets ? (
                                                <LabelValueDropdown
                                                    items={label.labelValues}
                                                    label={label.name}
                                                    labelValueDisplay={computeLabelValueDisplay(
                                                        props.values.labelValues,
                                                        label,
                                                    )}
                                                    onItemSelect={(
                                                        labelValueSelected: LabelValue,
                                                    ) =>
                                                        handleLabelValueChange(
                                                            arrayHelpers,
                                                            props.values.labelValues,
                                                            labelValueSelected,
                                                        )
                                                    }
                                                    currentLabelId={label.id}
                                                    selectedLabelValues={selectedLabelValues}
                                                    name="labelValue"
                                                    data-testid={`labelDropdown-${label.id}`}
                                                    error={
                                                        props.touched.labelValues &&
                                                        computeLabelError(label.id)
                                                    }
                                                    isMandatory={label.isMandatory}
                                                />
                                            ) : (
                                                <PermissionAwareDisplay
                                                    label={label.name}
                                                    data-testid={`labelDropdownDisplay-${label.id}`}
                                                    value={computeLabelValueDisplay(
                                                        market?.labelValues ?? [],
                                                        label,
                                                    )}
                                                    style={{ marginTop: '0' }}
                                                />
                                            )}
                                        </GridCol>
                                    ))}
                                </InformationCard>
                            )}
                        />
                        <InformationCard
                            title={t('market.holdback.title')}
                            headerTrailingComponent={
                                <PermissionAwareToggle
                                    permissionsRequired={[permissionNeeded]}
                                    name="hasHoldback"
                                    aspect="withText"
                                    innerLabelChecked="actif"
                                    innerLabel="inactif"
                                    editable={isEditMode}
                                    style={{ padding: '0' }}
                                    icon
                                    statusDisplay
                                />
                            }
                        >
                            <div ref={warrantiesCardRef} />
                            <GridCol defaultScreen={12}>
                                {props.values.hasHoldback && (
                                    <>
                                        <HoldbackPercentagePart>
                                            <Text>{t('market.holdback.percentageFirstPart')} </Text>
                                            <PermissionAwareText
                                                marginTop="0"
                                                editable={isEditMode}
                                                permissionsRequired={[permissionNeeded]}
                                                name="holdbackDefaultPercentage"
                                                type="text"
                                                data-testid="holdbackDefaultPercentage"
                                                errorTestId="errorHoldbackDefaultPercentage"
                                                numberType="percentage"
                                                noMarginTop
                                                whiteBackground
                                            />
                                            <Text>{t('market.holdback.percentageSecondPart')}</Text>
                                        </HoldbackPercentagePart>
                                        <FieldArray
                                            name="warranties"
                                            render={(arrayHelpers: FieldArrayRenderProps) => (
                                                <>
                                                    {isEditMode &&
                                                    props.values.warranties.length === 0 ? (
                                                        <Button
                                                            onClick={() =>
                                                                arrayHelpers.push({
                                                                    marketId: market?.id,
                                                                    number: null,
                                                                    reference: null,
                                                                    issuer: null,
                                                                    date: null,
                                                                    amountTtc: null,
                                                                    fileId: null,
                                                                })
                                                            }
                                                            text={t('market.holdback.addGuarantee')}
                                                            data-testid="addWarrantyLine"
                                                            aspect="primary"
                                                            style={{
                                                                display: 'flex',
                                                                padding: '1rem 0',
                                                            }}
                                                        />
                                                    ) : null}
                                                    {props.values.warranties.length > 0 ? (
                                                        <WarrantiesTable>
                                                            <GridCol defaultScreen={12}>
                                                                <WarrantiesTableHeader>
                                                                    <GridCol
                                                                        defaultScreen={2}
                                                                        gutterWidth="1rem"
                                                                    >
                                                                        <Text
                                                                            color={
                                                                                colors.neutral.N400
                                                                            }
                                                                            type="small"
                                                                            uppercased
                                                                        >
                                                                            {t(
                                                                                'market.holdback.number',
                                                                            )}
                                                                        </Text>
                                                                    </GridCol>
                                                                    <GridCol
                                                                        defaultScreen={2}
                                                                        gutterWidth="1rem"
                                                                    >
                                                                        <Text
                                                                            color={
                                                                                colors.neutral.N400
                                                                            }
                                                                            type="small"
                                                                            uppercased
                                                                        >
                                                                            {t(
                                                                                'market.holdback.reference',
                                                                            )}
                                                                        </Text>
                                                                    </GridCol>
                                                                    <GridCol
                                                                        defaultScreen={3}
                                                                        gutterWidth="1rem"
                                                                    >
                                                                        <Text
                                                                            color={
                                                                                colors.neutral.N400
                                                                            }
                                                                            type="small"
                                                                            uppercased
                                                                        >
                                                                            {t(
                                                                                'market.holdback.issuer',
                                                                            )}
                                                                        </Text>
                                                                    </GridCol>
                                                                    <GridCol
                                                                        defaultScreen={2}
                                                                        gutterWidth="1rem"
                                                                    >
                                                                        <Text
                                                                            color={
                                                                                colors.neutral.N400
                                                                            }
                                                                            type="small"
                                                                            uppercased
                                                                        >
                                                                            {t(
                                                                                'market.holdback.date',
                                                                            )}
                                                                        </Text>
                                                                    </GridCol>
                                                                    <GridCol
                                                                        defaultScreen={2}
                                                                        gutterWidth="1rem"
                                                                    >
                                                                        <Text
                                                                            color={
                                                                                colors.neutral.N400
                                                                            }
                                                                            type="small"
                                                                            uppercased
                                                                        >
                                                                            {t(
                                                                                'market.holdback.amountTtc',
                                                                            )}
                                                                        </Text>
                                                                    </GridCol>
                                                                    <GridCol
                                                                        defaultScreen={1}
                                                                        gutterWidth="1rem"
                                                                    >
                                                                        <Text
                                                                            color={
                                                                                colors.neutral.N400
                                                                            }
                                                                            type="small"
                                                                            uppercased
                                                                        >
                                                                            {t(
                                                                                'market.holdback.file',
                                                                            )}
                                                                        </Text>
                                                                    </GridCol>
                                                                </WarrantiesTableHeader>
                                                            </GridCol>
                                                            {props.values.warranties.map(
                                                                (
                                                                    warranty: Warranty,
                                                                    index: number,
                                                                ) => (
                                                                    <WarrantyLine
                                                                        key={warranty.id}
                                                                        index={index}
                                                                        isEditMode={isEditMode}
                                                                        isLastItem={
                                                                            index ===
                                                                            props.values.warranties
                                                                                .length -
                                                                                1
                                                                        }
                                                                        warranty={warranty}
                                                                        permissionNeeded={[
                                                                            permissionNeeded,
                                                                        ]}
                                                                        handleWarrantyLineAdded={() =>
                                                                            arrayHelpers.push({
                                                                                marketId:
                                                                                    market?.id,
                                                                                number: null,
                                                                                reference: null,
                                                                                issuer: null,
                                                                                date: null,
                                                                                amountTtc: null,
                                                                                fileId: null,
                                                                            })
                                                                        }
                                                                        handleWarrantyLineRemoved={(
                                                                            indexToRemove,
                                                                        ) =>
                                                                            arrayHelpers.remove(
                                                                                indexToRemove,
                                                                            )
                                                                        }
                                                                        handleFileUploaded={(
                                                                            fileId,
                                                                        ) =>
                                                                            props.setFieldValue(
                                                                                `warranties[${index}].fileId`,
                                                                                fileId,
                                                                            )
                                                                        }
                                                                    />
                                                                ),
                                                            )}
                                                            <GridCol defaultScreen={12}>
                                                                <WarrantiesTableHeader
                                                                    style={{
                                                                        borderBottom: 'none',
                                                                    }}
                                                                >
                                                                    <GridCol
                                                                        defaultScreen={9}
                                                                        gutterWidth="1rem"
                                                                    >
                                                                        <Text
                                                                            color={
                                                                                colors.neutral.N400
                                                                            }
                                                                            style={{
                                                                                textAlign: 'right',
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'market.holdback.total',
                                                                            )}
                                                                        </Text>
                                                                    </GridCol>
                                                                    <GridCol
                                                                        defaultScreen={3}
                                                                        gutterWidth="1rem"
                                                                    >
                                                                        <Text
                                                                            color={colors.blue.B400}
                                                                            isNumeral
                                                                        >
                                                                            {CurrencyFormatter.format(
                                                                                props.values.warranties.reduce(
                                                                                    (
                                                                                        total,
                                                                                        warranty,
                                                                                    ) => {
                                                                                        total +=
                                                                                            Number(
                                                                                                warranty.amountTtc,
                                                                                            );
                                                                                        return total;
                                                                                    },
                                                                                    0,
                                                                                ),
                                                                            )}
                                                                        </Text>
                                                                    </GridCol>
                                                                </WarrantiesTableHeader>
                                                            </GridCol>
                                                        </WarrantiesTable>
                                                    ) : null}
                                                </>
                                            )}
                                        />
                                    </>
                                )}
                            </GridCol>
                        </InformationCard>
                        <AdvanceAndDownPaymentPart>
                            <InformationCard
                                title={t('market.advance')}
                                style={{ padding: '2rem 1rem 0 2rem' }}
                                headerTrailingComponent={
                                    <PermissionAwareToggle
                                        permissionsRequired={[permissionNeeded]}
                                        name="hasAdvancePayment"
                                        aspect="withText"
                                        innerLabelChecked="actif"
                                        innerLabel="inactif"
                                        editable={isEditMode}
                                        style={{ padding: '0' }}
                                        icon
                                        statusDisplay
                                    />
                                }
                            />
                            <InformationCard
                                title={t('market.downPayment')}
                                style={{ padding: '2rem 1rem 0 2rem' }}
                                headerTrailingComponent={
                                    <PermissionAwareToggle
                                        permissionsRequired={[permissionNeeded]}
                                        name="hasDownPayment"
                                        aspect="withText"
                                        innerLabelChecked="actif"
                                        innerLabel="inactif"
                                        editable={isEditMode}
                                        style={{ padding: '0' }}
                                        icon
                                        statusDisplay
                                    />
                                }
                            />
                        </AdvanceAndDownPaymentPart>
                        <InformationCard
                            title={t('market.worksReception.title')}
                            style={
                                isEditMode
                                    ? { padding: '2rem 2rem 2.5rem 2rem' }
                                    : { padding: '2rem 2rem 1.5rem 2rem' }
                            }
                        >
                            <div ref={worksReceptionCardRef} />
                            <GridCol defaultScreen={12}>
                                <GridRow>
                                    <GridCol
                                        defaultScreen={6}
                                        smallScreen={6}
                                        style={
                                            isEditMode
                                                ? { marginBottom: '1.875rem', marginTop: '0' }
                                                : { marginTop: '0' }
                                        }
                                    >
                                        <PermissionAwareDateInputField
                                            editable={isEditMode}
                                            permissionsRequired={[permissionNeeded]}
                                            name="worksReceptionDate"
                                            label={t('market.worksReception.worksReceptionDate')}
                                            value={props.values.worksReceptionDate}
                                            data-testid="worksReceptionDate"
                                            errorTestId="errorWorksReceptionDate"
                                            noMarginTop
                                            whiteBackground
                                        />
                                    </GridCol>
                                    <GridCol
                                        defaultScreen={6}
                                        smallScreen={6}
                                        style={
                                            isEditMode
                                                ? { marginBottom: '1.875rem', marginTop: '0' }
                                                : { marginTop: '0' }
                                        }
                                    >
                                        <PermissionAwareDateInputField
                                            editable={isEditMode}
                                            permissionsRequired={[permissionNeeded]}
                                            name="worksReceptionNotificationDate"
                                            label={t(
                                                'market.worksReception.worksReceptionNotificationDate',
                                            )}
                                            value={props.values.worksReceptionNotificationDate}
                                            data-testid="worksReceptionNotificationDate"
                                            errorTestId="errorWorksReceptionNotificationDate"
                                            noMarginTop
                                            whiteBackground
                                        />
                                    </GridCol>
                                </GridRow>
                                <GridRow style={isEditMode ? {} : { marginTop: '1.25rem' }}>
                                    <GridCol defaultScreen={6} smallScreen={6}>
                                        <WrapperToggleWithLabel isEditMode={isEditMode}>
                                            <Text color={colors.neutral.N300}>
                                                {t(
                                                    'market.worksReception.worksReceptionWithReserve',
                                                )}
                                            </Text>
                                            <PermissionAwareToggle
                                                permissionsRequired={[permissionNeeded]}
                                                name="worksReceptionWithReserve"
                                                aspect="large"
                                                editable={isEditMode}
                                                style={{ marginTop: '-0.5rem' }}
                                                icon
                                            />
                                        </WrapperToggleWithLabel>
                                    </GridCol>
                                    {props.values.worksReceptionWithReserve && (
                                        <GridCol
                                            defaultScreen={6}
                                            smallScreen={6}
                                            style={
                                                isEditMode
                                                    ? {
                                                          marginBottom: '1.875rem',
                                                          marginTop: '0',
                                                      }
                                                    : { marginTop: '0' }
                                            }
                                        >
                                            <PermissionAwareDateInputField
                                                editable={isEditMode}
                                                permissionsRequired={[permissionNeeded]}
                                                name="reserveRemovalDate"
                                                label={t(
                                                    'market.worksReception.reserveRemovalDate',
                                                )}
                                                value={props.values.reserveRemovalDate}
                                                data-testid="reserveRemovalDate"
                                                errorTestId="errorReserveRemovalDate"
                                                noMarginTop
                                                whiteBackground
                                            />
                                        </GridCol>
                                    )}
                                </GridRow>
                                <GridRow style={isEditMode ? {} : { marginTop: '1.25rem' }}>
                                    <GridCol defaultScreen={6} smallScreen={6}>
                                        <PermissionAwareDateInputField
                                            editable={isEditMode}
                                            permissionsRequired={[permissionNeeded]}
                                            name="minutesEndDate"
                                            label={t('market.worksReception.minutesEndDate')}
                                            value={props.values.minutesEndDate}
                                            data-testid="minutesEndDate"
                                            errorTestId="errorMinutesEndDate"
                                            noMarginTop
                                            whiteBackground
                                        />
                                    </GridCol>
                                </GridRow>
                            </GridCol>
                        </InformationCard>
                    </Form>
                    {modalOptions && (
                        <Modal
                            isOpen={modalOptions.text !== ''}
                            onCloseButtonPressed={() => setModalOptions(null)}
                            buttons={[
                                {
                                    text: t('general.cancel'),
                                    aspect: 'secondary',
                                    'data-testid': 'cancelDelete',
                                    onClick() {
                                        setModalOptions(null);
                                    },
                                },
                                {
                                    text: t(modalOptions.confirmationButton),
                                    aspect: 'primary',
                                    'data-testid': 'confirm',
                                    onClick: modalOptions.callback,
                                },
                            ]}
                            title={t(modalOptions.title)}
                            iconName={modalOptions.iconName}
                            iconColor={modalOptions.iconColor}
                            size="small"
                            shouldRenderGlobalStyle
                            centerTitle
                        >
                            <Text style={{ margin: '0 0 1rem 0' }}>{t(modalOptions.text)}</Text>
                        </Modal>
                    )}
                </MainLayout>
            )}
        </Formik>
    ) : (
        <Loader />
    );
};

export default Market;
