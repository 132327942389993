import type { BadgeProps } from '../components/Badge';
import { colors } from '../constants/colors';
import type { Budget } from '../slices/budgetSlice';

export interface BudgetVersionProps {
    textColor?: string;
    textSize?: string;
    background?: string;
    style?: React.CSSProperties;
}

export const formatVersionNumber = (budget: Budget | Partial<Budget>) => {
    let majorVersionNumber = '';
    let minorVersionNumber = '';

    if (budget.majorVersionNumber === 0 && budget.minorVersionNumber !== 0) {
        majorVersionNumber = '0';
    } else if (budget.majorVersionNumber !== 0) {
        majorVersionNumber = String(budget.majorVersionNumber);
    }

    if (budget.minorVersionNumber && budget.minorVersionNumber !== 0) {
        minorVersionNumber = `-${budget.minorVersionNumber < 10 ? '0' : ''}${
            budget.minorVersionNumber
        }`;
    }

    return majorVersionNumber + minorVersionNumber;
};

export const getBudgetVersion = (
    budget?: Budget | Partial<Budget>,
    budgetVersionProps?: BudgetVersionProps,
): BadgeProps | undefined => {
    if (!budget) return undefined;

    let backgroundColor = null;

    switch (budget.status) {
        case 'approved':
            backgroundColor = colors.green.G400;
            break;
        case 'replaced':
            backgroundColor = colors.teal.T400;
            break;
        case 'draft':
            backgroundColor = colors.yellow.Y400;
            break;
        case 'cancelled':
            backgroundColor = colors.red.R400;
            break;
        default:
            return undefined;
    }

    return {
        text: formatVersionNumber(budget),
        textColor: budgetVersionProps?.textColor ?? colors.other.white,
        textSize: budgetVersionProps?.textSize ?? '1rem',
        background: budgetVersionProps?.background ?? backgroundColor,
        style: budgetVersionProps?.style ?? {
            minWidth: '1rem',
            minHeight: '1rem',
            marginRight: '0.54rem',
            padding: '0.125rem 0.39rem',
            borderRadius: '0.1875rem',
            lineHeight: '1.17rem',
        },
    };
};
