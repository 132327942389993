import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { isAfter, format } from 'date-fns';

import { Classes } from '@blueprintjs/core';

import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import GridRow from '../../components/GridRow';
import GridCol from '../../components/GridCol';
import Pagination from '../../components/Pagination';
import type { ButtonProps } from '../../components/Button';
import Button from '../../components/Button';
import EmptyState from '../../components/EmptyState';
import MarketList from '../../components/MarketList';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import SimulationButton from '../../components/SimulationButton';
import Tooltip from '../../components/Tooltip';
import MarketsPriceVariationsParameters from '../../components/MarketsPriceVariationsParameters';
import Elevation from '../../components/Elevation';
import Popover, { PopoverContent, PopoverItem } from '../../components/Popover';
import { colors } from '../../constants/colors';
import type { QueryParams, ListViewType } from '../../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../../slices/common';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import type { Market } from '../../slices/marketSlice';
import {
    getMarkets,
    selectMarketsByOperationIdAndPage,
    selectTotalMarkets,
    selectIsLoading,
    selectGetMarketsLoading,
    MARKET_EDIFLEX_IMPORT,
    selectMarkets,
    selectError,
} from '../../slices/marketSlice';
import {
    getOperation,
    selectOperation,
    getOperationHasEngagement,
    selectOperationHasEngagement,
    updateOperationEngagementsMatch,
} from '../../slices/operationSlice';
import {
    getAllMarketsPriceHypothesis,
    selectAllMarketsPriceHypothesis,
} from '../../slices/marketsPriceHypothesisSlice';
import { usePageNumber } from '../../hooks/usePageNumber';
import ImportInvoices from '../../components/ImportInvoices';
import ImportMarkets from '../../components/ImportMarkets';
import { showFlag } from '../../components/Flag';
import {
    getBudgetLinesForEngagements,
    selectValidatedBudgetLines,
} from '../../slices/engagementSlice';
import type { EngagementMatching } from '../../components/BudgetLinesMatchingEngagementsModal';
import BudgetLinesMatchingEngagementsModalAfterEngagementsImport from '../../components/BudgetLinesMatchingEngagementsModalAfterEngagementsImport';
import SearchField from '../../components/SearchField';
import { useAppDispatch } from '../../store';

const ViewToggle = styled.div`
    display: flex;
    div:first-child {
        margin: 0 1rem 0 0;
    }
    justify-content: flex-end;
`;

const Flex = styled.div`
    display: flex;
`;

const StyledPopoverItem = styled(PopoverItem)`
    margin: 0;
    padding: '0.5rem 1.125rem 0.5rem 1.125rem';
`;

const StyledIcon = styled(Icon)`
    margin-left: 0.3rem;
`;

type Props = {
    headerProps: HeaderProps;
};

const Markets: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const {
        operationId,
    }: {
        operationId?: string;
    } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const pageNumber = usePageNumber();

    const serverError = useSelector(selectError);
    let serverErrorMessage = '';

    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    const invoicesImportFileInputRef = useRef<HTMLInputElement>(null);
    const marketsImportFileInputRef = useRef<HTMLInputElement>(null);

    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: pageNumber,
        match: 'any',
        orderBy: 'label',
        order: 'asc',
        operationId: Number(operationId),
    });
    const [isPriceVariationsParametersModalOpen, setIsPriceVariationsParametersModalOpen] =
        useState<boolean>(false);
    const [operationHasMarketsImportedFromEdiflex, setOperationHasMarketsImportedFromEdiflex] =
        useState(false);

    const [
        isMatchingBudgetLinesAndEngagementsModalOpen,
        setIsMatchingBudgetLinesAndEngagementsModalOpen,
    ] = useState<boolean>(false);

    const state = {
        goBackTo: `${location.pathname}${location.search}`,
        operationId: operationId ?? undefined,
    };
    const [viewType, setViewType] = useState<ListViewType>(
        localStorage.getItem('market_list_type') === 'table' ? 'table' : 'card',
    );

    const hasRightToAddMarkets = usePermissionsCheck([
        {
            code: 'MARKETS_ADD',
            operationId,
        },
    ]);
    const hasRightToEditMarkets = usePermissionsCheck([
        {
            code: 'MARKETS_EDIT',
            operationId,
        },
    ]);
    const hasRightToAddInvoices = usePermissionsCheck([
        {
            code: 'INVOICES_ADD',
            operationId,
        },
    ]);
    const hasRightToAddEngagements = usePermissionsCheck([
        {
            code: 'ENGAGEMENTS_ADD',
            operationId,
        },
    ]);

    const hasRightToEditEngagements = usePermissionsCheck([
        {
            code: 'ENGAGEMENTS_EDIT',
            operationId,
        },
    ]);

    const totalMarkets = useSelector(selectTotalMarkets);
    const markets = useSelector(selectMarkets);
    const marketsPriceHypothesis = useSelector(selectAllMarketsPriceHypothesis);

    const createViewClickHandler = (type: 'card' | 'table') => () => {
        setViewType(type);
        localStorage.setItem('market_list_type', type);
    };

    const isLoading = useSelector(selectIsLoading);
    const isGetMarketsLoading = useSelector(selectGetMarketsLoading);
    const operation = useSelector(selectOperation(Number(operationId)));
    const operationHasEngagements = useSelector(selectOperationHasEngagement(Number(operationId)));
    const validatedBudgetLines = useSelector(selectValidatedBudgetLines);
    const operationHasValidatedBudgetLines = validatedBudgetLines.length > 0;
    const operationHasUnmatchedEngagements = markets.some(
        (market: Market) => market.hasUnmatchedEngagements,
    );

    useEffect(() => {
        void dispatch(getMarkets(queryParams));
    }, [dispatch, queryParams]);

    useEffect(() => {
        void dispatch(getOperation(Number(operationId)));
        void dispatch(getOperationHasEngagement({ operationId: Number(operationId) }));
        void dispatch(
            getAllMarketsPriceHypothesis({
                operationId: Number(operationId),
            }),
        );
        void dispatch(
            getBudgetLinesForEngagements({
                operationId: Number(operationId),
            }),
        );
    }, [dispatch, operationId, pageNumber]);

    useEffect(() => {
        if (isLoading && serverError) {
            showFlag('error', t('errors.error'), serverErrorMessage);
        }
    }, [isLoading, serverError, serverErrorMessage, t]);

    useEffect(() => {
        if (markets.length) {
            const operationHasMarketsImported = markets.some(
                (market) =>
                    market.marketEntryMode === MARKET_EDIFLEX_IMPORT &&
                    market.operationId === Number(operationId),
            );
            setOperationHasMarketsImportedFromEdiflex(operationHasMarketsImported);
        }
    }, [markets, operationId]);

    const marketsByPage = useSelector(
        selectMarketsByOperationIdAndPage(operationId, queryParams.page),
    ) as Market[] | null;

    if (isLoading && !marketsByPage) {
        return <Loader overlay />;
    }

    const computeButtonsOutOfProps = () => {
        const buttons: ButtonProps[] = [];

        buttons.push({
            'data-testid': 'marketsParameters',
            text: (
                <Popover>
                    <Button
                        data-testid="otherActionsButton"
                        text={t('markets.otherActions')}
                        iconName="KeyboardArrowDown"
                        iconSize="1.5rem"
                        bluePrintJsRightIconName={
                            operationHasUnmatchedEngagements && (
                                <Icon name="Warning" color={colors.yellow.Y400} width="1.1rem" />
                            )
                        }
                    />
                    <Elevation elevation="default">
                        <PopoverContent>
                            {hasRightToAddMarkets &&
                                hasRightToEditMarkets &&
                                hasRightToAddEngagements &&
                                hasRightToEditEngagements &&
                                process.env.REACT_APP_ENABLE_IMPORT_MARKETS_AND_ENGAGEMENTS && (
                                    <StyledPopoverItem
                                        data-testid="importMarketsAndEngagementsButton"
                                        className={Classes.POPOVER_DISMISS}
                                        onClick={() => {
                                            if (!operationHasValidatedBudgetLines) {
                                                showFlag(
                                                    'error',
                                                    t(
                                                        'operation.cantImportMarketsAndEngagementsBecauseNoApprovedBudget',
                                                    ),
                                                    '',
                                                );
                                            } else if (marketsImportFileInputRef.current) {
                                                marketsImportFileInputRef.current.click();
                                            }
                                        }}
                                    >
                                        {t('markets.importMarkets.button')}
                                    </StyledPopoverItem>
                                )}
                            {hasRightToAddInvoices && operationHasMarketsImportedFromEdiflex && (
                                <StyledPopoverItem
                                    data-testid="importInvoicesButton"
                                    className={Classes.POPOVER_DISMISS}
                                    onClick={() => {
                                        if (invoicesImportFileInputRef.current) {
                                            invoicesImportFileInputRef.current.click();
                                        }
                                    }}
                                >
                                    {t('markets.importInvoices')}
                                </StyledPopoverItem>
                            )}

                            {(hasRightToAddEngagements || hasRightToEditEngagements) && (
                                <StyledPopoverItem
                                    className={Classes.POPOVER_DISMISS}
                                    onClick={() => {
                                        setIsPriceVariationsParametersModalOpen(true);
                                    }}
                                >
                                    {t('markets.configurePriceVariations')}
                                </StyledPopoverItem>
                            )}
                            {hasRightToEditEngagements && operationHasEngagements && (
                                <StyledPopoverItem
                                    className={Classes.POPOVER_DISMISS}
                                    onClick={() => {
                                        setIsMatchingBudgetLinesAndEngagementsModalOpen(true);
                                    }}
                                >
                                    {t('markets.affectToBudget')}
                                    {operationHasUnmatchedEngagements && (
                                        <StyledIcon
                                            name="Warning"
                                            color={colors.yellow.Y400}
                                            width="1.1rem"
                                        />
                                    )}
                                </StyledPopoverItem>
                            )}
                        </PopoverContent>
                    </Elevation>
                </Popover>
            ),
        });

        if (hasRightToAddEngagements || hasRightToEditEngagements) {
            buttons.push({
                text: (
                    <SimulationButton
                        hasRightToEdit={hasRightToAddEngagements || hasRightToEditEngagements}
                        operationId={Number(operationId)}
                        type="operation"
                        marketsPriceHypothesis={marketsPriceHypothesis}
                        openPriceVariationModal={() =>
                            setIsPriceVariationsParametersModalOpen(true)
                        }
                    />
                ),
            });
        }

        if (hasRightToAddMarkets) {
            buttons.push({
                'data-testid': 'addMarketButton',
                text: t('markets.addMarket'),
                aspect: 'primary',
                onClick: () => navigate(`${location.pathname}/new`, { state }),
            });
        }
        return buttons;
    };

    const formatDate = (date: string) => format(new Date(date), 'dd/MM/yyyy');
    const formatDateForTooltip = (date: string) => format(new Date(date), 'dd/MM/yyyy HH:mm:ss');

    const handleSearch = (value: string) => {
        if (value === '') {
            setQueryParams({
                page: 1,
                match: 'any',
                orderBy: 'label',
                order: 'asc',
                operationId: Number(operationId),
            });
        } else {
            setQueryParams({
                ...queryParams,
                page: 1,
                query: value,
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('sidebar.markets')}</title>
            </Helmet>
            <MainLayout
                data-testid="markets-layout"
                header={
                    <Header
                        {...headerProps}
                        buttons={computeButtonsOutOfProps()}
                        subTitle={
                            operation?.lastSimulationAt && operationHasEngagements ? (
                                <Flex>
                                    <Tooltip
                                        content={formatDateForTooltip(operation.lastSimulationAt)}
                                    >
                                        <Text color={colors.blue.B400}>
                                            {t('markets.lastSimulationOn')}{' '}
                                            {formatDate(operation.lastSimulationAt)}
                                        </Text>
                                    </Tooltip>
                                    {operation.lastEngagementsUpdatedAt &&
                                        isAfter(
                                            new Date(operation.lastEngagementsUpdatedAt),
                                            new Date(operation.lastSimulationAt),
                                        ) && (
                                            <Icon
                                                name="Error"
                                                color={colors.yellow.Y400}
                                                data-testid="ErrorIcon"
                                                style={{ marginLeft: '0.5rem' }}
                                            />
                                        )}
                                </Flex>
                            ) : null
                        }
                        style={{
                            marginBottom: '1rem',
                        }}
                        showNavigation
                    />
                }
                footer={
                    totalMarkets > 0 ? (
                        <Pagination
                            data-testid="pagination"
                            initialPage={queryParams.page}
                            currentPage={queryParams.page}
                            pageNeighbours={2}
                            nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                            nbItemsTotal={totalMarkets}
                            onPageNumberClicked={(page: number) =>
                                setQueryParams({
                                    ...queryParams,
                                    page,
                                })
                            }
                            pageUrl="markets"
                        />
                    ) : null
                }
                smallContentSidePadding
            >
                <ImportMarkets
                    operationId={Number(operationId)}
                    fileInputRef={marketsImportFileInputRef}
                    onCloseImportMarketsModal={() => dispatch(getMarkets(queryParams))}
                />
                <ImportInvoices
                    operationId={Number(operationId)}
                    fileInputRef={invoicesImportFileInputRef}
                />
                <GridRow
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    <GridCol smallScreen={10} defaultScreen={4}>
                        <SearchField
                            name="marketSearchField"
                            data-testid="marketSearchField"
                            onSearch={handleSearch}
                        />
                    </GridCol>
                    <GridCol smallScreen={2} defaultScreen={8}>
                        <ViewToggle>
                            <Button
                                onClick={createViewClickHandler('table')}
                                iconName="ViewList"
                                isPressed={viewType === 'table'}
                                data-testid="tableViewButton"
                                aspect="onlyIcon"
                                toggle
                            />
                            <Button
                                onClick={createViewClickHandler('card')}
                                iconName="ViewModule"
                                isPressed={viewType === 'card'}
                                data-testid="cardViewButton"
                                aspect="onlyIcon"
                                toggle
                            />
                        </ViewToggle>
                    </GridCol>
                </GridRow>
                {!isGetMarketsLoading ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment -- necessary fragment
                    <>
                        {marketsByPage && marketsByPage.length > 0 ? (
                            <MarketList
                                viewType={viewType}
                                markets={marketsByPage}
                                locationState={state}
                            />
                        ) : (
                            <EmptyState
                                data-testid="errorNoMarkets"
                                imageName="Operation"
                                titleTranslationKey="errors.noMarketsTitle"
                            />
                        )}
                    </>
                ) : (
                    <Loader />
                )}
            </MainLayout>
            <Modal
                title={t('markets.priceVariationParameters')}
                titleFontSize="1.5rem"
                isOpen={isPriceVariationsParametersModalOpen}
                onCloseButtonPressed={() => setIsPriceVariationsParametersModalOpen(false)}
                maxWidth="90%"
                size="large"
            >
                <MarketsPriceVariationsParameters
                    operationId={Number(operationId)}
                    hasRightToEdit={hasRightToAddEngagements || hasRightToEditEngagements}
                    permissionNeeded={
                        hasRightToAddEngagements
                            ? [{ code: 'ENGAGEMENTS_ADD', operationId }]
                            : [{ code: 'ENGAGEMENTS_EDIT', operationId }]
                    }
                />
            </Modal>
            {isMatchingBudgetLinesAndEngagementsModalOpen && hasRightToEditEngagements ? (
                <BudgetLinesMatchingEngagementsModalAfterEngagementsImport
                    onSubmit={(values: EngagementMatching[]) => {
                        setIsMatchingBudgetLinesAndEngagementsModalOpen(false);
                        void dispatch(
                            updateOperationEngagementsMatch({
                                id: Number(operationId),
                                engagementMatching: values,
                            }),
                        );
                        setQueryParams({ page: pageNumber, operationId: Number(operationId) });
                    }}
                    onCancelClick={() => {
                        setIsMatchingBudgetLinesAndEngagementsModalOpen(false);
                    }}
                    operationId={Number(operationId)}
                    isOpen
                />
            ) : null}
        </>
    );
};

export default Markets;
