import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PrivateRoute from './PrivateRoute';
// Routes
import Budget from './pages/budget/Budget';
import Budgets from './pages/budgets/Budgets';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import InactivityLogout from './pages/inactivityLogout/InactivityLogout';
import Labels from './pages/labels/Labels';
import Login from './pages/login/Login';
import NotFound from './pages/notFound/NotFound';
import Operation from './pages/operation/Operation';
import Operations from './pages/operations/Operations';
import Organization from './pages/organization/Organization';
import Organizations from './pages/organizations/Organizations';
import ResetPassword from './pages/resetPassword/ResetPassword';
import ResetPasswordEmailSent from './pages/resetPasswordEmailSent/ResetPasswordEmailSent';
import ResetPasswordSuccess from './pages/resetPasswordSuccess/ResetPasswordSuccess';
import RolePermissionMatrix from './pages/rolePermissionMatrix/RolePermissionMatrix';
import SpendingSchema from './pages/spendingSchema/SpendingSchema';
import PriceIndexes from './pages/priceIndexes/PriceIndexes';
import Site from './pages/site/Site';
import Sites from './pages/sites/Sites';
import UserProfile from './pages/userProfile/UserProfile';
import UsersByOrganization from './pages/usersByOrganization/UsersByOrganization';
import Users from './pages/users/Users';
import Planning from './pages/planning/Planning';
import Markets from './pages/markets/Markets';
import Market from './pages/market/Market';
import Engagements from './pages/engagements/Engagements';
import MarketInvoices from './pages/invoices/MarketInvoices';
import MarketNotes from './pages/market-notes/MarketNotes';
import OperationDashboard from './pages/operationDashboard/OperationDashboard';
import LabelsRedirect from './pages/labelsRedirect/LabelsRedirect';
import MarketInvoice from './pages/invoice/MarketInvoice';
import OrganizationsByOperation from './pages/organizationsByOperation/OrganizationsByOperation';
import UsersByOperation from './pages/usersByOperation/UsersByOperation';
import UsersRolesByOperation from './pages/usersRolesByOperation/UsersRolesByOperation';
import MultiOperationsDashboard from './pages/multiOperationsDashboard/MultiOperationsDashboard';

const Routes: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const dashboardBreadcrumbs = [{ text: t('sidebar.dashboard'), to: '/dashboard' }];
    const operationsBreadcrumbs = [{ text: t('sidebar.myOperations'), to: '/operations' }];
    const organizationsBreadcrumbs = [{ text: t('sidebar.organizations'), to: '/organizations' }];
    const administrationBreadcrumbs = [{ text: t('sidebar.administration'), to: '/settings' }];

    const generalLabelsTabs = [
        { text: t('generalLabels.tabs.operation'), to: '/settings/labels/operation' },
        { text: t('generalLabels.tabs.market'), to: '/settings/labels/market' },
        { text: t('generalLabels.tabs.engagement'), to: '/settings/labels/engagement' },
    ];
    const clientLabelsTabs = [
        {
            text: t('generalLabels.tabs.operation'),
            to: '/organizations/:organizationId/labels/operation',
        },
        {
            text: t('generalLabels.tabs.market'),
            to: '/organizations/:organizationId/labels/market',
        },
        {
            text: t('generalLabels.tabs.engagement'),
            to: '/organizations/:organizationId/labels/engagement',
        },
    ];
    const operationLabelsTabs = [
        {
            text: t('generalLabels.tabs.market'),
            to: '/operations/:operationId/labels/market',
        },
        {
            text: t('generalLabels.tabs.engagement'),
            to: '/operations/:operationId/labels/engagement',
        },
    ];
    const budgetTabs = [
        {
            text: t('budget.tabs.decomposition'),
            to: '/operations/:operationId/budgets/:budgetId',
        },
        {
            text: t('budget.tabs.planning'),
            to: '/operations/:operationId/budgets/:budgetId/planning',
        },
    ];
    const marketTabs = [
        {
            text: t('market.tabs.informations'),
            to: '/operations/:operationId/markets/:marketId',
        },
        {
            text: t('market.tabs.engagements'),
            to: '/operations/:operationId/markets/:marketId/engagements',
            requiredPermissions: [{ code: 'ENGAGEMENTS_READ', operationId: ':operationId' }],
        },
        {
            text: t('market.tabs.invoices'),
            to: '/operations/:operationId/markets/:marketId/invoices',
            requiredPermissions: [{ code: 'INVOICES_READ', operationId: ':operationId' }],
        },
        {
            text: t('market.tabs.notes'),
            to: '/operations/:operationId/markets/:marketId/notes',
            requiredPermissions: [{ code: 'MARKETS_READ', operationId: ':operationId' }],
        },
    ];
    const operationOrganizationsAndUsersTabs = [
        {
            text: t('operation.tabs.organizations'),
            to: '/operations/:operationId/organizations',
        },
        {
            text: t('operation.tabs.users'),
            to: '/operations/:operationId/organizations/users',
        },
        {
            text: t('operation.tabs.roles'),
            to: '/operations/:operationId/organizations/roles',
        },
    ];
    const priceIndexesTabs = [
        {
            text: t('administration.tabs.inseePriceIndexesList'),
            to: '/settings/price-indexes/insee',
        },
        {
            text: t('administration.tabs.otherIndexes'),
            to: '/settings/price-indexes/other',
        },
    ];

    return (
        <RouterRoutes>
            {/* "PRIVATE" routes */}
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <Navigate to="/operations" />
                    </PrivateRoute>
                }
            />
            {/* Multi Operations Dashboard */}
            <Route
                key="dashboard"
                path="/dashboard"
                element={
                    <PrivateRoute>
                        <MultiOperationsDashboard
                            headerProps={{
                                title: t('dashboard.pageTitle'),
                                breadcrumbs: dashboardBreadcrumbs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            {/* Operations */}
            {/*
                Using key attribute to force the Operations component to rebuild
                https://stackoverflow.com/questions/49001001/using-same-component-for-different-route-path-in-react-router-v4
            */}
            <Route
                key="operations"
                path="/operations"
                element={
                    <PrivateRoute>
                        <Operations
                            headerProps={{
                                title: t('operation.pageTitle'),
                                breadcrumbs: operationsBreadcrumbs,
                            }}
                            showRolesOfAuthUser
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId"
                element={
                    <PrivateRoute>
                        <OperationDashboard
                            headerProps={{
                                title: t('sidebar.general'),
                                showBackButton: true,
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />

            <Route
                path="/operations/:operationId/settings"
                element={
                    <PrivateRoute>
                        <Operation
                            headerProps={{
                                showBackButton: true,
                                goBackTo: '/operations',
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.settings'),
                                        to: '/operations/:operationId/settings',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/budgets"
                element={
                    <PrivateRoute>
                        <Budgets
                            headerProps={{
                                title: t('sidebar.budget'),
                                showBackButton: true,
                                goBackTo: '/operations',
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.budget'),
                                        to: '/operations/:operationId/budgets',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/budgets/:budgetId"
                element={
                    <PrivateRoute>
                        <Budget
                            headerProps={{
                                title: t('sidebar.budgets'),
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.budget'),
                                        to: '/operations/:operationId/budgets/:budgetId',
                                    },
                                ],
                                tabs: budgetTabs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/budgets/:budgetId/planning"
                element={
                    <PrivateRoute>
                        <Planning
                            headerProps={{
                                title: t('sidebar.planning'),
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.budget'),
                                        to: '/operations/:operationId/budgets/:budgetId',
                                    },
                                    {
                                        text: t('sidebar.planning'),
                                        to: '/operations/:operationId/budgets/:budgetId/planning',
                                    },
                                ],
                                tabs: budgetTabs,
                            }}
                            type="budget"
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/markets/planning"
                element={
                    <PrivateRoute>
                        <Planning
                            headerProps={{
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.marketsPlanning'),
                                        to: '/operations/:operationId/markets/planning',
                                    },
                                ],
                            }}
                            type="markets"
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/markets"
                element={
                    <PrivateRoute>
                        <Markets
                            headerProps={{
                                title: t('sidebar.markets'),
                                showBackButton: true,
                                goBackTo: '/operations',
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.markets'),
                                        to: '/operations/:operationId/markets',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/markets/:marketId"
                element={
                    <PrivateRoute>
                        <Market
                            headerProps={{
                                title: t('markets.pageTitle'),
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.markets'),
                                        to: '/operations/:operationId/markets',
                                    },
                                    {
                                        text: ':marketName',
                                        to: '/operations/:operationId/markets/:marketId',
                                    },
                                ],
                                tabs: marketTabs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/markets/:marketId/engagements"
                element={
                    <PrivateRoute>
                        <Engagements
                            headerProps={{
                                title: t('markets.pageTitle'),
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.markets'),
                                        to: '/operations/:operationId/markets',
                                    },
                                    {
                                        text: ':marketName',
                                        to: '/operations/:operationId/markets/:marketId',
                                    },
                                ],
                                tabs: marketTabs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/markets/:marketId/invoices"
                element={
                    <PrivateRoute>
                        <MarketInvoices
                            headerProps={{
                                title: t('markets.pageTitle'),
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.markets'),
                                        to: '/operations/:operationId/markets',
                                    },
                                    {
                                        text: ':marketName',
                                        to: '/operations/:operationId/markets/:marketId',
                                    },
                                ],
                                tabs: marketTabs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/markets/:marketId/invoices/:invoiceId"
                element={
                    <PrivateRoute>
                        <MarketInvoice
                            headerProps={{
                                title: t('markets.pageTitle'),
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.markets'),
                                        to: '/operations/:operationId/markets',
                                    },
                                    {
                                        text: ':marketName',
                                        to: '/operations/:operationId/markets/:marketId',
                                    },
                                    {
                                        text: t('sidebar.invoices'),
                                        to: '/operations/:operationId/markets/:marketId/invoices',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/markets/:marketId/notes"
                element={
                    <PrivateRoute>
                        <MarketNotes
                            headerProps={{
                                title: t('markets.pageTitle'),
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.markets'),
                                        to: '/operations/:operationId/markets',
                                    },
                                    {
                                        text: ':marketName',
                                        to: '/operations/:operationId/markets/:marketId',
                                    },
                                ],
                                tabs: marketTabs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/labels"
                element={
                    <PrivateRoute>
                        <LabelsRedirect />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/labels/market"
                element={
                    <PrivateRoute>
                        <Labels
                            headerProps={{
                                title: t('sidebar.operationLabels'),
                                showBackButton: true,
                                goBackTo: '/operations',
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.operationLabels'),
                                        to: '/operations/:operationId/labels/market',
                                    },
                                    {
                                        text: t('generalLabels.tabs.market'),
                                        to: '/operations/:operationId/labels/market',
                                    },
                                ],
                                tabs: operationLabelsTabs,
                            }}
                            labelType="market"
                            isPreviz={false}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/labels/engagement"
                element={
                    <PrivateRoute>
                        <Labels
                            headerProps={{
                                title: t('sidebar.operationLabels'),
                                showBackButton: true,
                                goBackTo: '/operations',
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.operationLabels'),
                                        to: '/operations/:operationId/labels/market',
                                    },
                                    {
                                        text: t('generalLabels.tabs.engagement'),
                                        to: '/operations/:operationId/labels/engagement',
                                    },
                                ],
                                tabs: operationLabelsTabs,
                            }}
                            labelType="engagement"
                            isPreviz={false}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/organizations"
                element={
                    <PrivateRoute>
                        <OrganizationsByOperation
                            headerProps={{
                                title: t('operation.detailsPageTitle'),
                                showBackButton: true,
                                goBackTo: '/operations',
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.operationOrganizationsAndUsers'),
                                        to: '/operations/:operationId/organizations',
                                    },
                                ],
                                tabs: operationOrganizationsAndUsersTabs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/organizations/users"
                element={
                    <PrivateRoute>
                        <UsersByOperation
                            headerProps={{
                                title: t('operation.detailsPageTitle'),
                                showBackButton: true,
                                goBackTo: '/operations',
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.operationOrganizationsAndUsers'),
                                        to: '/operations/:operationId/organizations',
                                    },
                                ],
                                tabs: operationOrganizationsAndUsersTabs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/organizations/roles"
                element={
                    <PrivateRoute>
                        <UsersRolesByOperation
                            headerProps={{
                                title: t('operation.detailsPageTitle'),
                                showBackButton: true,
                                goBackTo: '/operations',
                                breadcrumbs: [
                                    ...operationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                    {
                                        text: t('sidebar.operationOrganizationsAndUsers'),
                                        to: '/operations/:operationId/organizations',
                                    },
                                ],
                                tabs: operationOrganizationsAndUsersTabs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/operations/:operationId/users/:userId"
                element={
                    <PrivateRoute>
                        <UserProfile
                            headerProps={{
                                showBackButton: true,
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    { text: ':operationName', to: '/operations/:operationId' },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            {/*  Organizations */}
            <Route
                path="/organizations"
                element={
                    <PrivateRoute>
                        <Organizations
                            headerProps={{
                                title: t('organization.pageTitle'),
                                breadcrumbs: organizationsBreadcrumbs,
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/organizations/:organizationId"
                element={
                    <PrivateRoute>
                        <Organization
                            headerProps={{
                                title: t('organization.organizationDetailsPageTitle'),
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/organizations/:organizationId/users"
                element={
                    <PrivateRoute>
                        <UsersByOrganization
                            headerProps={{
                                title: t('user.userList'),
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                    {
                                        text: t('sidebar.users'),
                                        to: '/organizations/:organizationId/users',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/organizations/:organizationId/users/:userId"
                element={
                    <PrivateRoute>
                        <UserProfile
                            headerProps={{
                                showBackButton: true,
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/organizations/:organizationId/roles"
                element={
                    <PrivateRoute>
                        <RolePermissionMatrix
                            headerProps={{
                                showBackButton: true,
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                    {
                                        text: t('sidebar.roles'),
                                        to: '/organizations/:organizationId/roles',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/organizations/:organizationId/sites"
                element={
                    <PrivateRoute>
                        <Sites
                            headerProps={{
                                title: t('site.siteList'),
                                goBackTo: '/organizations/',
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                    {
                                        text: t('sidebar.sites'),
                                        to: '/organizations/:organizationId/sites',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/organizations/:organizationId/sites/:siteId"
                element={
                    <PrivateRoute>
                        <Site
                            headerProps={{
                                title: t('site.detailsPageTitle'),
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                    {
                                        text: t('sidebar.sites'),
                                        to: '/organizations/:organizationId/sites',
                                    },
                                    {
                                        text: ':siteName',
                                        to: '/organizations/:organizationId/sites/:siteId',
                                    },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            {/*
                Using key attribute to force the Operations component to rebuild
                https://stackoverflow.com/questions/49001001/using-same-component-for-different-route-path-in-react-router-v4
            */}
            <Route
                key="organization-operations"
                path="/organizations/:organizationId/operations"
                element={
                    <PrivateRoute>
                        <Operations
                            headerProps={{
                                title: t('sidebar.operations'),
                                showBackButton: true,
                                goBackTo: '/organizations/',
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                    {
                                        text: t('sidebar.operations'),
                                        to: '/organizations/:organizationId/operations',
                                    },
                                ],
                            }}
                            showAddButton={false}
                            showAffectationType
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/organizations/:organizationId/labels/operation"
                element={
                    <PrivateRoute>
                        <Labels
                            headerProps={{
                                title: t('generalLabels.clientLabels'),
                                showBackButton: true,
                                goBackTo: '/organizations/',
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                    {
                                        text: t('generalLabels.clientLabels'),
                                        to: '/organizations/:organizationId/operation-labels',
                                    },
                                    {
                                        text: t('generalLabels.tabs.operation'),
                                        to: '/organizations/:organizationId/operation-labels',
                                    },
                                ],
                                tabs: clientLabelsTabs,
                            }}
                            labelType="operation"
                            isPreviz={false}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/organizations/:organizationId/labels/market"
                element={
                    <PrivateRoute>
                        <Labels
                            headerProps={{
                                title: t('generalLabels.clientLabels'),
                                showBackButton: true,
                                goBackTo: '/organizations/',
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                    {
                                        text: t('generalLabels.clientLabels'),
                                        to: '/organizations/:organizationId/operation-labels',
                                    },
                                    {
                                        text: t('generalLabels.tabs.market'),
                                        to: '/organizations/:organizationId/market-labels',
                                    },
                                ],
                                tabs: clientLabelsTabs,
                            }}
                            labelType="market"
                            isPreviz={false}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/organizations/:organizationId/labels/engagement"
                element={
                    <PrivateRoute>
                        <Labels
                            headerProps={{
                                title: t('generalLabels.clientLabels'),
                                showBackButton: true,
                                goBackTo: '/organizations/',
                                breadcrumbs: [
                                    ...organizationsBreadcrumbs,
                                    {
                                        text: ':organizationName',
                                        to: '/organizations/:organizationId',
                                    },
                                    {
                                        text: t('generalLabels.clientLabels'),
                                        to: '/organizations/:organizationId/operation-labels',
                                    },
                                    {
                                        text: t('generalLabels.tabs.engagement'),
                                        to: '/organizations/:organizationId/engagement-labels',
                                    },
                                ],
                                tabs: clientLabelsTabs,
                            }}
                            labelType="engagement"
                            isPreviz={false}
                        />
                    </PrivateRoute>
                }
            />
            {/*  Administration */}
            <Route
                path="/settings"
                element={
                    <PrivateRoute isAdminRoute>
                        <Navigate to="/settings/users" />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/price-indexes"
                element={
                    <PrivateRoute isAdminRoute>
                        <Navigate to="/settings/price-indexes/insee" />
                    </PrivateRoute>
                }
            />
            <Route
                key="insee-price-indexes"
                path="/settings/price-indexes/insee"
                element={
                    <PrivateRoute isAdminRoute>
                        <PriceIndexes
                            headerProps={{
                                title: t('sidebar.priceIndexes'),
                                breadcrumbs: [
                                    ...administrationBreadcrumbs,
                                    {
                                        text: t('sidebar.priceIndexes'),
                                        to: '/settings/price-indexes/insee',
                                    },
                                ],
                                tabs: priceIndexesTabs,
                            }}
                            priceIndexesType="insee"
                        />
                    </PrivateRoute>
                }
            />
            <Route
                key="other-price-indexes"
                path="/settings/price-indexes/other"
                element={
                    <PrivateRoute isAdminRoute>
                        <PriceIndexes
                            headerProps={{
                                title: t('sidebar.priceIndexes'),
                                breadcrumbs: [
                                    ...administrationBreadcrumbs,
                                    {
                                        text: t('sidebar.priceIndexes'),
                                        to: '/settings/price-indexes/other',
                                    },
                                ],
                                tabs: priceIndexesTabs,
                            }}
                            priceIndexesType="manual"
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/users"
                element={
                    <PrivateRoute isAdminRoute>
                        <Users
                            headerProps={{
                                title: t('sidebar.users'),
                                goBackTo: '/settings',
                                breadcrumbs: [
                                    ...administrationBreadcrumbs,
                                    { text: t('sidebar.users'), to: '/settings/users' },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/users/:userId"
                element={
                    <PrivateRoute isAdminRoute>
                        <UserProfile
                            headerProps={{
                                showBackButton: true,
                                breadcrumbs: [
                                    ...administrationBreadcrumbs,
                                    { text: t('sidebar.users'), to: '/settings/users' },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/roles"
                element={
                    <PrivateRoute isAdminRoute>
                        <RolePermissionMatrix
                            headerProps={{
                                showBackButton: true,
                                breadcrumbs: [
                                    ...administrationBreadcrumbs,
                                    { text: t('sidebar.rolesByDefault'), to: '/settings' },
                                ],
                            }}
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/spending-schema"
                element={
                    <PrivateRoute isAdminRoute>
                        <SpendingSchema />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/labels"
                element={
                    <PrivateRoute isAdminRoute>
                        <Navigate to="/settings/labels/operation" />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/labels/operation"
                element={
                    <PrivateRoute isAdminRoute>
                        <Labels
                            headerProps={{
                                title: t('generalLabels.title'),
                                breadcrumbs: [
                                    ...administrationBreadcrumbs,
                                    {
                                        text: t('sidebar.generalLabels'),
                                        to: '/general-labels-operation',
                                    },
                                    {
                                        text: t('generalLabels.tabs.operation'),
                                        to: '/general-labels-operation',
                                    },
                                ],
                                tabs: generalLabelsTabs,
                            }}
                            labelType="operation"
                            isPreviz
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/labels/market"
                element={
                    <PrivateRoute isAdminRoute>
                        <Labels
                            headerProps={{
                                title: t('generalLabels.title'),
                                breadcrumbs: [
                                    ...administrationBreadcrumbs,
                                    {
                                        text: t('sidebar.generalLabels'),
                                        to: '/general-labels-operation',
                                    },
                                    {
                                        text: t('generalLabels.tabs.market'),
                                        to: '/general-labels-market',
                                    },
                                ],
                                tabs: generalLabelsTabs,
                            }}
                            labelType="market"
                            isPreviz
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/labels/engagement"
                element={
                    <PrivateRoute isAdminRoute>
                        <Labels
                            headerProps={{
                                title: t('generalLabels.title'),
                                breadcrumbs: [
                                    ...administrationBreadcrumbs,
                                    {
                                        text: t('sidebar.generalLabels'),
                                        to: '/general-labels-operation',
                                    },
                                    {
                                        text: t('generalLabels.tabs.engagement'),
                                        to: '/general-labels-engagement',
                                    },
                                ],
                                tabs: generalLabelsTabs,
                            }}
                            labelType="engagement"
                            isPreviz
                        />
                    </PrivateRoute>
                }
            />

            {/* "PUBLIC" routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/inactivity-logout" element={<InactivityLogout />} />
            <Route path="/signup" element={<ResetPassword />} />
            <Route path="/signup/:token" element={<ResetPassword isAccountSetup />} />
            <Route path="/password-reset/:token" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password-email-sent" element={<ResetPasswordEmailSent />} />
            <Route path="/password-changed" element={<ResetPasswordSuccess />} />
            <Route element={<NotFound />} />
        </RouterRoutes>
    );
};

export default Routes;
