import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import type { TabId } from '@blueprintjs/core';
import { Tab, Tabs, Radio, RadioGroup } from '@blueprintjs/core';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

// Data
import type { AxiosError } from 'axios';

import type {
    BudgetLine,
    IBudgetLineFormData,
    BudgetLineForDropdown,
} from '../slices/budgetLineSlice';
import { createBudgetLine, updateBudgetLine } from '../slices/budgetLineSlice';
import { getSpendingSchemas } from '../slices/spendingSchemaSlice';

import { colors } from '../constants/colors';
import Text from './Text';
import Modal from './Modal';
import Badge from './Badge';
import PermissionAwareText from './PermissionAwareText';
import PermissionAwareDisplay from './PermissionAwareDisplay';
import Tooltip from './Tooltip';
import FormikOptionDropdown from './dropdown/FormikOptionDropdown';
import type { Option } from './SelectField';
import GridCol from './GridCol';
import GridRow from './GridRow';
import type { ButtonProps } from './Button';
import { showFlag } from './Flag';
import type { TaxOption } from './BudgetLine';
import {
    SPENDING_LINE,
    CALCULATED_LINE,
    GENERAL_BUDGET_LINE_TAB,
    SPENDING_SCHEMA_BUDGET_LINE_TAB,
    VARIATIONS_BUDGET_LINE_TAB,
} from './BudgetLine';
import { CurrencyFormatter } from '../utils/formatters';
import PriceVariationTab, {
    getYupSchema,
    getDefaultPriceVariationSettings,
    validatePriceVariationsTab,
    setInitialPriceVariationSettings,
} from './tabs/PriceVariationTab';
import SpendingRythmTab, {
    getSpendingRythmValidationSchema,
    initialValuesSpendingRythm,
    validateSpendingSchemaTab,
} from './tabs/SpendingRythmTab';
import { validateFormFields } from '../utils/formValidation';
import getBudgetLinesForCalculationDropdown from '../utils/getBudgetLinesForCalculationDropdown';
import type { Permission } from '../slices/authSlice';
import PermissionAwareTextarea from './PermissionAwareTextarea';
import { useAppDispatch } from '../store';
import type { ServerError } from '../slices/common';
import Icon from './Icon';

const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
`;

const Separator = styled.div`
    background-color: ${colors.neutral.N200};
    width: 0.1rem;
    height: 100%;
`;

type Props = {
    budgetId: number;
    budgetLine?: BudgetLine | null;
    calculateTvaAmount: (amountHt: number, tvaCodeId: number) => number;
    calculateTtcAmount: (amountHt: number, tvaCodeId: number) => number;
    getTvaCodeDisplayLabel: (codeId?: number) => React.ReactNode;
    hasRightToEdit: boolean;
    nextWBSIndex: string;
    operationId: number;
    permissionNeeded: Permission;
    taxOptions: TaxOption[];
    setIsModalOpen: (isOpen: boolean) => void;
    setIsPending: (isPending: boolean) => void;
    isPending?: boolean | string;
    indexPadding?: string;
    isEditMode: boolean;
};

const BudgetLineModal: React.FunctionComponent<Props> = ({
    budgetId,
    budgetLine,
    hasRightToEdit,
    operationId,
    permissionNeeded,
    nextWBSIndex,
    taxOptions,
    getTvaCodeDisplayLabel,
    calculateTvaAmount,
    calculateTtcAmount,
    setIsModalOpen,
    setIsPending,
    isPending,
    indexPadding,
    isEditMode,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [typeOfLine, setTypeOfLine] = useState<string>(
        budgetLine?.isCalculated ? CALCULATED_LINE : SPENDING_LINE,
    );
    const [selectedTab, setSelectedTab] = useState<TabId>(GENERAL_BUDGET_LINE_TAB);

    const isCreation = !budgetLine || budgetLine.isParent;

    const [areReferenceLinesFetched, setAreReferenceLinesFetched] = useState(false);
    const [referenceLines, setReferenceLines] = useState<BudgetLineForDropdown[]>([]);
    const [referenceLineOptions, setReferenceLineOptions] = useState<Array<Option<number>>>([]);

    const initialModalContentValues: IBudgetLineFormData = budgetLine?.isParent
        ? {
              id: undefined,
              budgetId,
              label: '',
              index: nextWBSIndex,
              amountTtc: 0,
              amountHt: 0,
              parentId: budgetLine.id,
              taxes: 0,
              tvaCodeId: taxOptions[0]?.value,
              isCalculated: false,
              calculatedFactor: null,
              calculatedFrom: null,
              spendingRythm: initialValuesSpendingRythm,
              priceVariationSettings: getDefaultPriceVariationSettings(),
          }
        : {
              id: budgetLine?.id ?? undefined,
              budgetId,
              label: budgetLine?.label ?? '',
              index: !isCreation && budgetLine.index ? budgetLine.index : nextWBSIndex,
              amountTtc: budgetLine?.amountTtc ?? 0,
              amountHt: budgetLine?.amountHt ? Number(budgetLine.amountHt).toFixed(2) : 0,
              parentId: budgetLine?.parentId ?? null,
              taxes: budgetLine?.taxes ?? 0,
              tvaCodeId: budgetLine?.tvaCodeId ?? 1,
              isCalculated: budgetLine?.isCalculated ?? false,
              calculatedFactor: budgetLine?.calculatedFactor
                  ? (Number(budgetLine.calculatedFactor) * 100).toFixed(2)
                  : null,
              calculatedFrom: budgetLine?.calculatedFrom ?? null,
              priceVariationSettings: budgetLine?.id
                  ? setInitialPriceVariationSettings(budgetLine)
                  : getDefaultPriceVariationSettings(),
              spendingRythm: budgetLine?.spendingRythm
                  ? {
                        ...budgetLine.spendingRythm,
                        fixedSpendingFactor:
                            budgetLine.spendingRythm.fixedSpendingFactor !== null
                                ? (
                                      Number(budgetLine.spendingRythm.fixedSpendingFactor) * 100
                                  ).toFixed(2)
                                : 100,
                        variableSpendingFactor:
                            budgetLine.spendingRythm.variableSpendingFactor !== null
                                ? (
                                      Number(budgetLine.spendingRythm.variableSpendingFactor) * 100
                                  ).toFixed(2)
                                : 0,
                    }
                  : initialValuesSpendingRythm,
          };

    useEffect(() => {
        const fetchBudgetLinesForCalculationDropdown = async (): Promise<
            BudgetLineForDropdown[]
        > => {
            const budgetLines = await getBudgetLinesForCalculationDropdown(
                operationId,
                budgetId,
                initialModalContentValues,
            );
            budgetLines.forEach((referenceLine) => {
                setReferenceLineOptions((oldArray) => [
                    ...oldArray,
                    {
                        value: referenceLine.id as number,
                        label: `${referenceLine.index} ${referenceLine.label}`,
                    },
                ]);
            });
            return budgetLines;
        };

        // Get budget lines that can be used a reference for calculation
        fetchBudgetLinesForCalculationDropdown()
            .then((budgetLines: BudgetLineForDropdown[]) => {
                setReferenceLines(budgetLines);
                setAreReferenceLinesFetched(true);
            })
            .catch((err: unknown) => {
                const error = err as AxiosError<ServerError>;
                showFlag('error', error.message, t('errors.unexpectedError'));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [budgetId, operationId]);

    useEffect(() => {
        if (budgetLine?.isCalculated) {
            setTypeOfLine(CALCULATED_LINE);
        } else {
            setTypeOfLine(SPENDING_LINE);
        }
    }, [budgetLine, setTypeOfLine]);

    const calculatedAmountHtWhenIsCalculated = (
        calculatedFromId: number,
        inputCalculatedFactor = 0,
    ) => {
        if (calculatedFromId && inputCalculatedFactor) {
            const calculatedFactor = inputCalculatedFactor / 100;

            const calculatedFromBudgetLine = referenceLines.find(
                (line) => line.id === Number(calculatedFromId),
            );

            if (calculatedFromBudgetLine) {
                return calculatedFromBudgetLine.amountHt * calculatedFactor;
            }
        }

        return 0;
    };

    const handleTabsNavigation = (navBarTabId: TabId) => {
        setSelectedTab(navBarTabId);
        if (navBarTabId === SPENDING_SCHEMA_BUDGET_LINE_TAB) {
            void dispatch(getSpendingSchemas());
        }
    };

    const resetModalState = () => {
        handleTabsNavigation(GENERAL_BUDGET_LINE_TAB);
        setIsModalOpen(false);
        setTypeOfLine(SPENDING_LINE);
    };

    const renderBadge = (indicator: string, isActive: boolean) => (
        <Badge
            text={indicator}
            textSize="1rem"
            textColor={colors.other.white}
            background={isActive ? colors.green.G400 : colors.neutral.N400}
            style={{
                marginRight: '0.5rem',
                width: '2rem',
                height: '2rem',
                borderRadius: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        />
    );

    const renderGeneralTab = (props: FormikProps<IBudgetLineFormData>) => {
        const handleRadioChange = () => {
            if (typeOfLine === SPENDING_LINE) {
                setTypeOfLine(CALCULATED_LINE);
                props.setFieldValue('isCalculated', true);
            } else if (typeOfLine === CALCULATED_LINE) {
                setTypeOfLine(SPENDING_LINE);
                props.setValues({
                    ...props.values,
                    isCalculated: false,
                    calculatedFactor: null,
                    calculatedFrom: null,
                });
            }
        };

        let radioGroup = (
            <StyledRadioGroup
                name="isCalculated"
                onChange={handleRadioChange}
                selectedValue={typeOfLine}
                disabled={
                    referenceLineOptions.length === 0 ||
                    budgetLine?.isCalculationOrigin ||
                    Boolean(!(hasRightToEdit && isEditMode))
                }
            >
                <Radio label={t('budget.spendingLine')} value={SPENDING_LINE} />
                <Radio label={t('budget.calculatedLine')} value={CALCULATED_LINE} />
            </StyledRadioGroup>
        );

        if (budgetLine?.isCalculationOrigin) {
            radioGroup = (
                <Tooltip content={t('budget.isOriginOfCalculation')}>{radioGroup}</Tooltip>
            );
        } else if (referenceLineOptions.length === 0) {
            radioGroup = <Tooltip content={t('budget.mustAddSpendingLines')}>{radioGroup}</Tooltip>;
        }

        const handleTvaCodeChange = (option: TaxOption) => {
            props.setValues({
                ...props.values,
                tvaCodeId: option.value,
            });
        };

        const getAmountHt = (): number => {
            if (props.values.calculatedFrom && props.values.calculatedFactor) {
                return calculatedAmountHtWhenIsCalculated(
                    props.values.calculatedFrom,
                    Number(props.values.calculatedFactor),
                );
            } else {
                return 0;
            }
        };

        const getAmountTva = (): number => {
            if (
                typeOfLine === CALCULATED_LINE &&
                props.values.calculatedFrom &&
                props.values.calculatedFactor
            ) {
                return calculateTvaAmount(
                    calculatedAmountHtWhenIsCalculated(
                        props.values.calculatedFrom,
                        Number(props.values.calculatedFactor),
                    ),
                    props.values.tvaCodeId,
                );
            } else {
                return calculateTvaAmount(
                    props.values.amountHt ? Number(props.values.amountHt) : 0,
                    props.values.tvaCodeId,
                );
            }
        };

        const getAmountTtc = (): number => {
            if (typeOfLine === CALCULATED_LINE)
                if (props.values.calculatedFrom && props.values.calculatedFactor) {
                    return calculateTtcAmount(getAmountHt(), props.values.tvaCodeId);
                } else {
                    return 0;
                }
            else {
                return calculateTtcAmount(
                    props.values.amountHt ? Number(props.values.amountHt) : 0,
                    props.values.tvaCodeId,
                );
            }
        };

        return (
            <>
                <Text type="H500" style={{ width: '100%', textAlign: 'left' }}>
                    {t('budget.information')}
                </Text>
                <GridRow>
                    <GridCol defaultScreen={12} smallScreen={12}>
                        {radioGroup}
                    </GridCol>
                </GridRow>
                {typeOfLine === CALCULATED_LINE && (
                    <GridRow>
                        <GridCol defaultScreen={9} smallScreen={9}>
                            {hasRightToEdit && isEditMode ? (
                                <FormikOptionDropdown
                                    items={referenceLineOptions}
                                    name="calculatedFrom"
                                    initialNameDiplay={
                                        referenceLineOptions.find(
                                            (referenceLine) =>
                                                referenceLine.value === props.values.calculatedFrom,
                                        )?.label ?? t('budget.chooseBudgetLine')
                                    }
                                    label={t('budget.referenceLine')}
                                    data-testid="modalReferenceLineDropdown"
                                    errorTestId="errorCalculatedFrom"
                                    style={{ margin: '0' }}
                                />
                            ) : (
                                <>
                                    <Text
                                        style={{ margin: '0' }}
                                        color={colors.neutral.N300}
                                        data-testid="modalDropdownNotEditable"
                                    >
                                        {t('budget.referenceLine')}
                                    </Text>
                                    <Text>
                                        {referenceLineOptions.find(
                                            (referenceLine) =>
                                                referenceLine.value === props.values.calculatedFrom,
                                        )?.label ?? t('budget.chooseBudgetLine')}
                                    </Text>
                                </>
                            )}
                        </GridCol>
                        <GridCol defaultScreen={3} smallScreen={3}>
                            <PermissionAwareText
                                marginTop="0"
                                editable={hasRightToEdit && isEditMode}
                                permissionsRequired={[permissionNeeded]}
                                label="%"
                                name="calculatedFactor"
                                type="text"
                                placeholder="%"
                                data-testid="calculatedFactorInput"
                                errorTestId="errorCalculatedFactor"
                                numberType="percentage"
                                noMarginTop
                                whiteBackground
                            />
                        </GridCol>
                    </GridRow>
                )}
                <GridRow>
                    <GridCol defaultScreen={12} smallScreen={12}>
                        <PermissionAwareText
                            marginTop="0"
                            editable={hasRightToEdit && isEditMode}
                            permissionsRequired={[permissionNeeded]}
                            label={t('budget.label')}
                            name="label"
                            type="text"
                            placeholder={t('budget.label')}
                            data-testid="modalLabel"
                            errorTestId="errorLabel"
                            leftComponent={
                                <Text
                                    style={{
                                        margin: '0',
                                        paddingTop: '0.3rem',
                                        flex: '1',
                                    }}
                                    type="H400"
                                    color={colors.blue.B400}
                                    size="0.88rem"
                                >
                                    {props.values.index || nextWBSIndex}
                                </Text>
                            }
                            leftComponentPadding={indexPadding}
                            autoFocus
                            noMarginTop
                            whiteBackground
                        />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol defaultScreen={3} smallScreen={3}>
                        {typeOfLine === CALCULATED_LINE && isEditMode ? (
                            <PermissionAwareDisplay
                                label={t('budget.amountHt')}
                                data-testid="modalAmountHtCalculatedLine"
                                style={{ marginTop: 0, width: '100%' }}
                                value={CurrencyFormatter.format(getAmountHt())}
                                isNumeral
                            />
                        ) : (
                            <PermissionAwareText
                                marginTop="0"
                                editable={hasRightToEdit && isEditMode}
                                permissionsRequired={[permissionNeeded]}
                                label={t('budget.amountHt')}
                                name="amountHt"
                                type="text"
                                placeholder={t('budget.amountHt')}
                                data-testid="modalAmountHt"
                                errorTestId="errorAmountHt"
                                numberType="amount"
                                noMarginTop
                                whiteBackground
                            />
                        )}
                    </GridCol>
                    <GridCol
                        defaultScreen={3}
                        smallScreen={3}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                        {hasRightToEdit && isEditMode ? (
                            <FormikOptionDropdown<TaxOption>
                                items={taxOptions}
                                name="tvaCodeId"
                                initialNameDiplay={getTvaCodeDisplayLabel(props.values.tvaCodeId)}
                                label={t('budget.taxeCode')}
                                data-testid="modalTaxes"
                                errorTestId="errorTaxes"
                                style={{ margin: '0' }}
                                handleChange={handleTvaCodeChange}
                            />
                        ) : (
                            <>
                                <Text
                                    style={{ margin: '0' }}
                                    color={colors.neutral.N300}
                                    data-testid="modalTaxes"
                                >
                                    {t('budget.taxeCode')}
                                </Text>
                                <Text>{getTvaCodeDisplayLabel(props.values.tvaCodeId)}</Text>
                            </>
                        )}
                    </GridCol>
                    <GridCol defaultScreen={3} smallScreen={3}>
                        <PermissionAwareDisplay
                            label={t('budget.amountTva')}
                            data-testid="modalAmountTva"
                            style={{ marginTop: 0 }}
                            value={CurrencyFormatter.format(getAmountTva())}
                            isNumeral
                        />
                    </GridCol>
                    <GridCol defaultScreen={3} smallScreen={3}>
                        <PermissionAwareDisplay
                            label={t('budget.amountTtc')}
                            data-testid="modalAmountTtc"
                            style={{ marginTop: 0 }}
                            value={CurrencyFormatter.format(getAmountTtc())}
                            isNumeral
                        />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol
                        defaultScreen={12}
                        smallScreen={12}
                        style={{
                            marginBottom: '1rem',
                            textAlign: 'left',
                        }}
                    >
                        <PermissionAwareTextarea
                            editable={hasRightToEdit}
                            permissionsRequired={[permissionNeeded]}
                            label={t('engagement.comment')}
                            name="comment"
                            placeholder={t('engagement.comment')}
                            value={budgetLine?.comment ?? ''}
                            data-testid="comment"
                            errorTestId="errorComment"
                            displayStyle={{
                                whiteSpace: 'unset',
                                marginTop: '0.75rem',
                            }}
                            whiteBackground
                            isOptional
                        />
                    </GridCol>
                </GridRow>
            </>
        );
    };

    const renderSpendingSchemaTab = (props: FormikProps<IBudgetLineFormData>) => (
        <SpendingRythmTab
            hasRightToEdit={hasRightToEdit}
            permissionsNeeded={[permissionNeeded]}
            isEditMode={isEditMode}
            formikProps={props}
        />
    );
    const renderVariationsTab = (props: FormikProps<IBudgetLineFormData>) => (
        <PriceVariationTab
            hasRightToEdit={hasRightToEdit}
            permissionsNeeded={[permissionNeeded]}
            isEditMode={isEditMode}
            formikProps={props}
        />
    );

    const computeButtonsOutOfProps = (props: FormikProps<IBudgetLineFormData>): ButtonProps[] => {
        const buttons: ButtonProps[] = [
            {
                text: t('general.cancel'),
                aspect: 'secondary',
                'data-testid': 'cancelDelete',
                onClick() {
                    props.resetForm();
                    resetModalState();
                },
            },
        ];
        buttons.push({
            text: t('general.previous'),
            aspect: 'secondary',
            'data-testid': 'previous',
            onClick() {
                const tabToSelect =
                    selectedTab === SPENDING_SCHEMA_BUDGET_LINE_TAB
                        ? GENERAL_BUDGET_LINE_TAB
                        : SPENDING_SCHEMA_BUDGET_LINE_TAB;
                setSelectedTab(tabToSelect);
            },
            disabled: selectedTab === GENERAL_BUDGET_LINE_TAB,
            size: 'small',
            bluePrintJsIconName: <Icon width="1.3rem" name="ArrowBack" />,
        });

        buttons.push({
            text: t('general.next'),
            aspect: 'primary',
            'data-testid': 'next',
            async onClick() {
                if (selectedTab === GENERAL_BUDGET_LINE_TAB) {
                    const fieldsToTouch = [
                        'label',
                        'amountHt',
                        'calculatedFrom',
                        'calculatedFactor',
                    ];
                    const isValid = await validateFormFields(fieldsToTouch, props);
                    if (isValid) {
                        setSelectedTab(SPENDING_SCHEMA_BUDGET_LINE_TAB);
                    }
                    void dispatch(getSpendingSchemas());
                } else if (selectedTab === SPENDING_SCHEMA_BUDGET_LINE_TAB) {
                    const isValid = await validateSpendingSchemaTab<IBudgetLineFormData>(props);

                    if (isValid) {
                        handleTabsNavigation(VARIATIONS_BUDGET_LINE_TAB);
                    }
                }
            },
            disabled: selectedTab === VARIATIONS_BUDGET_LINE_TAB,
            size: 'small',
            bluePrintJsIconName: <Icon width="1.3rem" name="ArrowForward" />,
        });

        if (isEditMode) {
            buttons.push({
                text: t('general.record'),
                aspect: 'primary',
                'data-testid': 'record',
                isLoading: Boolean(isPending),
                async onClick() {
                    if (props.dirty) {
                        const isValid = await validatePriceVariationsTab<IBudgetLineFormData>(
                            props,
                        );
                        if (isValid) {
                            props.handleSubmit();
                        }
                    } else {
                        props.resetForm();
                        showFlag('warning', t('general.warning'), t('general.nothingToUpdate'));
                        handleTabsNavigation(GENERAL_BUDGET_LINE_TAB);
                        setIsModalOpen(false);
                    }
                },
            });
        }

        return buttons;
    };

    const budgetLineSchema = Yup.object().shape({
        label: Yup.string().required(t('errors.required')).min(1),
        amountHt: Yup.number()
            .nullable()
            .when('isCalculated', {
                is: false,
                then: Yup.number().required(t('errors.required')),
            }),
        calculatedFrom: Yup.string()
            .nullable()
            .when('isCalculated', {
                is: true,
                then: Yup.string().required(t('errors.required')).min(1),
            }),
        calculatedFactor: Yup.number()
            .nullable()
            .when('isCalculated', {
                is: true,
                then: Yup.number().required(t('errors.required')),
            }),
        spendingRythm: getSpendingRythmValidationSchema(t),
        priceVariationSettings: getYupSchema(t),
    });

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment -- fragment needed
        <>
            {areReferenceLinesFetched && (
                <Formik
                    initialValues={initialModalContentValues}
                    validationSchema={budgetLineSchema}
                    onSubmit={(values: IBudgetLineFormData, actions) => {
                        let payload = {
                            ...values,
                            amountHt: values.amountHt ? Number(values.amountHt) : 0,
                            spendingRythm: {
                                ...values.spendingRythm,
                                fixedSpendingEndShift: Number(
                                    values.spendingRythm?.fixedSpendingEndShift,
                                ),
                                fixedSpendingFactor:
                                    Number(values.spendingRythm?.fixedSpendingFactor) / 100,
                                fixedSpendingStartShift: Number(
                                    values.spendingRythm?.fixedSpendingStartShift,
                                ),
                                variableSpendingEndShift: Number(
                                    values.spendingRythm?.variableSpendingEndShift,
                                ),
                                variableSpendingFactor:
                                    Number(values.spendingRythm?.variableSpendingFactor) / 100,
                                variableSpendingStartShift: Number(
                                    values.spendingRythm?.variableSpendingStartShift,
                                ),
                                variableSpendingSchemaId:
                                    Number(values.spendingRythm?.variableSpendingFactor) === 0 ||
                                    values.spendingRythm?.variableSpendingSchemaId === 0
                                        ? null
                                        : values.spendingRythm?.variableSpendingSchemaId,
                            },
                        };

                        if (payload.priceVariationSettings?.isSimulated) {
                            delete payload.priceVariationSettings.isSimulated;
                            delete payload.priceVariationSettings.actualisationsAmountHt;
                            delete payload.priceVariationSettings.revisionsAmountHt;
                            payload.isSimulated = true;

                            if (
                                payload.priceVariationSettings.hasActualisation &&
                                !payload.priceVariationSettings.hasRevision
                            ) {
                                payload.priceVariationSettings = {
                                    ...payload.priceVariationSettings,
                                    a: null,
                                    b: null,
                                };
                            } else if (
                                !payload.priceVariationSettings.hasActualisation &&
                                payload.priceVariationSettings.hasRevision
                            ) {
                                payload.priceVariationSettings = {
                                    ...payload.priceVariationSettings,
                                    actualisationMonth: null,
                                };
                            } else if (
                                !payload.priceVariationSettings.hasActualisation &&
                                !payload.priceVariationSettings.hasRevision
                            ) {
                                payload.priceVariationSettings = null;
                            }

                            if (payload.priceVariationSettings?.indexSettings) {
                                const formattedIndexSettings =
                                    payload.priceVariationSettings.indexSettings.map((index) => ({
                                        ...index,
                                        factor: Number(index.factor),
                                    }));
                                payload.priceVariationSettings = {
                                    ...payload.priceVariationSettings,
                                    indexSettings: formattedIndexSettings,
                                };
                            }
                        } else if (payload.priceVariationSettings) {
                            payload.isSimulated = false;
                            payload.actualisationsAmountHt = Number(
                                payload.priceVariationSettings.actualisationsAmountHt,
                            );
                            payload.revisionsAmountHt = Number(
                                payload.priceVariationSettings.revisionsAmountHt,
                            );
                            payload.priceVariationSettings = null;
                        }

                        if (typeOfLine === CALCULATED_LINE) {
                            const calculatedValues = values as IBudgetLineFormData & {
                                calculatedFrom: number;
                                calculatedFactor: number;
                            };

                            payload = {
                                ...payload,
                                isCalculated: true,
                                calculatedFactor: parseFloat(
                                    Number(calculatedValues.calculatedFactor / 100).toFixed(4),
                                ),
                                calculatedFrom: Number(calculatedValues.calculatedFrom),
                                amountHt: calculatedAmountHtWhenIsCalculated(
                                    calculatedValues.calculatedFrom,
                                    calculatedValues.calculatedFactor,
                                ),
                                amountTtc: calculateTtcAmount(
                                    calculatedAmountHtWhenIsCalculated(
                                        calculatedValues.calculatedFrom,
                                        calculatedValues.calculatedFactor,
                                    ),
                                    values.tvaCodeId,
                                ),
                                taxes: calculateTvaAmount(
                                    calculatedAmountHtWhenIsCalculated(
                                        calculatedValues.calculatedFrom,
                                        calculatedValues.calculatedFactor,
                                    ),
                                    values.tvaCodeId,
                                ),
                            };
                        } else {
                            payload = {
                                ...payload,
                                isCalculated: false,
                                calculatedFactor: null,
                                calculatedFrom: null,
                                amountTtc: calculateTtcAmount(payload.amountHt, values.tvaCodeId),
                                taxes: calculateTvaAmount(payload.amountHt, values.tvaCodeId),
                            };
                        }

                        void dispatch(
                            isCreation
                                ? createBudgetLine({
                                      budgetLine: payload,
                                      operationId,
                                      budgetId,
                                  })
                                : updateBudgetLine({
                                      budgetLine: payload,
                                      operationId,
                                      budgetId,
                                  }),
                        );
                        setIsPending(true);
                    }}
                    enableReinitialize
                >
                    {(props: FormikProps<IBudgetLineFormData>) => (
                        <Modal
                            onOpening={() => {
                                if (props.values.isCalculated) {
                                    setTypeOfLine(CALCULATED_LINE);
                                }
                            }}
                            onCloseButtonPressed={() => {
                                props.resetForm();
                                resetModalState();
                            }}
                            buttons={computeButtonsOutOfProps(props)}
                            title={
                                props.values.id
                                    ? t('budget.modifyBudgetLine')
                                    : t('budget.addBudgetLine')
                            }
                            titleFontSize="1.5rem"
                            canOutsideClickClose={false}
                            size="full"
                            isOpen
                            centerTitle
                            backgroundColor={colors.neutral.N50}
                            scrollableBody
                            specificPadding="0"
                        >
                            {/* Width 100% needed to fix the modal changing size according to content */}
                            <Form data-testid="modalContent" style={{ width: '100%' }}>
                                <Tabs
                                    id="budgetLineTabs"
                                    selectedTabId={selectedTab}
                                    onChange={async (newTabId: TabId) => {
                                        let isValid = true;
                                        const generalFields = [
                                            'label',
                                            'amountHt',
                                            'calculatedFrom',
                                            'calculatedFactor',
                                        ];
                                        let generalTabIsValid = true;
                                        let spendingRythmTabIsValid = true;
                                        if (selectedTab === GENERAL_BUDGET_LINE_TAB) {
                                            if (newTabId === SPENDING_SCHEMA_BUDGET_LINE_TAB) {
                                                isValid = await validateFormFields(
                                                    generalFields,
                                                    props,
                                                );
                                            } else {
                                                generalTabIsValid = await validateFormFields(
                                                    generalFields,
                                                    props,
                                                );
                                                spendingRythmTabIsValid =
                                                    await validateSpendingSchemaTab(props);
                                                isValid =
                                                    generalTabIsValid && spendingRythmTabIsValid;
                                            }
                                        } else if (
                                            selectedTab === SPENDING_SCHEMA_BUDGET_LINE_TAB
                                        ) {
                                            isValid = await validateSpendingSchemaTab(props);
                                        } else if (selectedTab === VARIATIONS_BUDGET_LINE_TAB) {
                                            isValid =
                                                await validatePriceVariationsTab<IBudgetLineFormData>(
                                                    props,
                                                );
                                        }

                                        if (isValid) {
                                            handleTabsNavigation(newTabId);
                                        } else {
                                            if (generalTabIsValid && !spendingRythmTabIsValid) {
                                                handleTabsNavigation(
                                                    SPENDING_SCHEMA_BUDGET_LINE_TAB,
                                                );
                                            }
                                        }
                                    }}
                                >
                                    <Tab
                                        id={GENERAL_BUDGET_LINE_TAB}
                                        title={
                                            <>
                                                {renderBadge(
                                                    '1',
                                                    selectedTab === GENERAL_BUDGET_LINE_TAB,
                                                )}
                                                <Text style={{ margin: '0' }}>
                                                    {t('budget.general')}
                                                </Text>
                                            </>
                                        }
                                        panel={renderGeneralTab(props)}
                                    />
                                    <Separator>&nbsp;</Separator>
                                    <Tab
                                        id={SPENDING_SCHEMA_BUDGET_LINE_TAB}
                                        title={
                                            <>
                                                {renderBadge(
                                                    '2',
                                                    selectedTab === SPENDING_SCHEMA_BUDGET_LINE_TAB,
                                                )}
                                                <Text style={{ margin: '0' }}>
                                                    {t('spendingRythm.title')}
                                                </Text>
                                            </>
                                        }
                                        panel={renderSpendingSchemaTab(props)}
                                    />
                                    <Separator>&nbsp;</Separator>
                                    <Tab
                                        id={VARIATIONS_BUDGET_LINE_TAB}
                                        title={
                                            <>
                                                {renderBadge(
                                                    '3',
                                                    selectedTab === VARIATIONS_BUDGET_LINE_TAB,
                                                )}
                                                <Text style={{ margin: '0' }}>
                                                    {t('budget.variations')}
                                                </Text>
                                            </>
                                        }
                                        panel={renderVariationsTab(props)}
                                    />
                                </Tabs>
                            </Form>
                        </Modal>
                    )}
                </Formik>
            )}
        </>
    );
};
export default BudgetLineModal;
