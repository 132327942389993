import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Classes } from '@blueprintjs/core';
import styled from 'styled-components';

import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import type { TableHeaders } from '../../components/Table';
import Table from '../../components/Table';
import type { PriceIndex, PriceIndexFormData, PriceIndexType } from '../../slices/priceIndexSlice';
import {
    selectPriceIndexes,
    getPriceIndexes,
    deletePriceIndex,
    selectError,
    resetError,
    selectIsDeletePriceIndexFulfilled,
} from '../../slices/priceIndexSlice';
import { formatDateFromDb } from '../../utils/date';
import type { ButtonProps } from '../../components/Button';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import Icon from '../../components/Icon';
import type { RenderCellMapType } from '../../components/TableRow';
import Popover, { PopoverContent, PopoverItem } from '../../components/Popover';
import Elevation from '../../components/Elevation';
import PriceIndexEditModal from '../../components/PriceIndexEditModal';
import { showFlag } from '../../components/Flag';
import Modal from '../../components/Modal';
import Text from '../../components/Text';
import { colors } from '../../constants/colors';
import EmptyState from '../../components/EmptyState';
import { useAppDispatch } from '../../store';

const ContextualMenuIcon = styled(Icon)`
    margin-right: 1rem;
    cursor: pointer;
`;

const ContextualMenuContainer = styled.div`
    display: flex;
    justify-self: flex-end;
    margin-left: auto;
`;

type Props = {
    headerProps: HeaderProps;
    priceIndexesType: PriceIndexType;
};

const PriceIndexes: React.FC<Props> = ({ headerProps, priceIndexesType }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const serverError = useSelector(selectError);
    const priceIndexes = useSelector(selectPriceIndexes);
    const isDeletePriceIndexFulfilled = useSelector(selectIsDeletePriceIndexFulfilled);

    const [addPriceIndexModalOpen, setAddPriceIndexModalOpen] = useState(false);
    const [editPriceIndexModalOpen, setEditPriceIndexModalOpen] = useState(false);
    const [priceIndexIdToEdit, setPriceIndexIdToEdit] = useState<PriceIndex['id'] | null>(null);
    const [priceIndexToEdit, setPriceIndexToEdit] = useState<PriceIndexFormData | null>(null);
    const [isDeletePriceIndexPending, setIsDeletePriceIndexPending] = useState(false);
    const [isDeletePriceIndexConfirmationModalOpen, setIsDeletePriceIndexConfirmationModalOpen] =
        useState(false);
    const [priceIndexIdToDelete, setPriceIndexIdToDelete] = useState<PriceIndex['id'] | null>(null);

    const permissionNeededToAddPriceIndexes = { code: 'PRICE_INDEXES_ADD' };
    const permissionNeededToEditPriceIndexes = { code: 'PRICE_INDEXES_EDIT' };
    const permissionNeededToDeletePriceIndexes = { code: 'PRICE_INDEXES_DELETE' };
    const hasRightToAddPriceIndexes = usePermissionsCheck([permissionNeededToAddPriceIndexes]);
    const hasRightToEditPriceIndexes = usePermissionsCheck([permissionNeededToEditPriceIndexes]);
    const hasRightToDeletePriceIndexes = usePermissionsCheck([
        permissionNeededToDeletePriceIndexes,
    ]);

    const isInseePriceIndexesList = priceIndexesType === 'insee';
    const shouldShowActionsMenu =
        !isInseePriceIndexesList && (hasRightToEditPriceIndexes || hasRightToDeletePriceIndexes);

    const errorMessage = t('errors.error');
    let serverErrorMessage = '';
    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    const priceIndexesRef = useRef(priceIndexes);

    useEffect(() => {
        void dispatch(
            getPriceIndexes({
                page: 1,
                priceIndexesType,
            }),
        );
    }, [dispatch, priceIndexesType]);

    useEffect(() => {
        if (priceIndexIdToEdit) {
            setPriceIndexToEdit(
                priceIndexesRef.current.find(
                    (priceIndex) => priceIndex.id === priceIndexIdToEdit,
                ) ?? null,
            );
        } else {
            setPriceIndexToEdit(null);
        }
    }, [priceIndexIdToEdit, priceIndexesRef]);

    useEffect(() => {
        if (priceIndexToEdit) {
            setEditPriceIndexModalOpen(true);
        }
    }, [priceIndexToEdit]);

    useEffect(() => {
        if (priceIndexIdToDelete) {
            setIsDeletePriceIndexConfirmationModalOpen(true);
        }
    }, [priceIndexIdToDelete]);

    useEffect(() => {
        if (isDeletePriceIndexPending) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
                dispatch(resetError());
                setIsDeletePriceIndexPending(false);
                setPriceIndexIdToDelete(null);
            } else if (isDeletePriceIndexFulfilled) {
                showFlag('success', t('general.success'), t('priceIndex.successfullyDeleted'));
                void dispatch(
                    getPriceIndexes({
                        page: 1,
                        priceIndexesType,
                    }),
                );
                setIsDeletePriceIndexPending(false);
                setPriceIndexIdToDelete(null);
            }
        }
    }, [
        dispatch,
        errorMessage,
        isDeletePriceIndexFulfilled,
        isDeletePriceIndexPending,
        priceIndexesType,
        serverError,
        serverErrorMessage,
        t,
    ]);

    const computeButtonsOutOfProps = (): ButtonProps[] => {
        const buttons: ButtonProps[] = [];
        if (!isInseePriceIndexesList && hasRightToAddPriceIndexes) {
            buttons.push({
                'data-testid': 'addPriceIndex',
                text: t('priceIndexes.addAPriceIndex'),
                aspect: 'primary',
                size: 'small',
                bluePrintJsIconName: <Icon name="AddBox" />,
                onClick: () => setAddPriceIndexModalOpen(true),
            });
        }
        return buttons;
    };

    const handleEditModalClose = () => {
        setAddPriceIndexModalOpen(false);
        setEditPriceIndexModalOpen(false);
        setPriceIndexIdToEdit(null);
    };

    const headers: TableHeaders = {
        name: t('priceIndex.name'),
        month: t('priceIndex.month'),
        value: t('priceIndex.value'),
        lastUpdatedAt: t('priceIndex.lastUpdatedAt'),
    };

    if (shouldShowActionsMenu) {
        headers.actions = '';
    }

    const renderCellMap: RenderCellMapType<PriceIndex | Partial<PriceIndex>> = {
        name: (priceIndex: PriceIndex | Partial<PriceIndex>) => priceIndex.name,
        month: (priceIndex: PriceIndex | Partial<PriceIndex>) =>
            priceIndex.lastValue?.month
                ? formatDateFromDb(priceIndex.lastValue.month, 'MM/yyyy')
                : '',
        value: (priceIndex: PriceIndex | Partial<PriceIndex>) =>
            priceIndex.lastValue?.value ? String(priceIndex.lastValue.value) : '',
        lastUpdatedAt: (priceIndex: PriceIndex | Partial<PriceIndex>) =>
            priceIndex.lastValue?.lastUpdatedAt
                ? formatDateFromDb(priceIndex.lastValue.lastUpdatedAt, 'dd/MM/yyyy')
                : '',
    };

    if (shouldShowActionsMenu) {
        renderCellMap.actions = (priceIndex: PriceIndex | Partial<PriceIndex>) => (
            <ContextualMenuContainer>
                <Popover renderGlobalStyle={false}>
                    <ContextualMenuIcon name="MoreHoriz" data-testid="popOverOptions" />
                    <Elevation elevation="default">
                        {hasRightToEditPriceIndexes && (
                            <PopoverContent>
                                <PopoverItem
                                    className={Classes.POPOVER_DISMISS}
                                    onClick={() => {
                                        if (priceIndex.id) {
                                            setPriceIndexIdToEdit(priceIndex.id);
                                        }
                                    }}
                                    data-testid="editPriceIndexValues"
                                >
                                    {t('priceIndex.editValues')}
                                </PopoverItem>
                            </PopoverContent>
                        )}
                        {hasRightToDeletePriceIndexes && (
                            <PopoverContent>
                                <PopoverItem
                                    className={Classes.POPOVER_DISMISS}
                                    onClick={() => {
                                        if (priceIndex.id) {
                                            setPriceIndexIdToDelete(priceIndex.id);
                                        }
                                    }}
                                    data-testid="deletePriceIndex"
                                >
                                    {t('priceIndex.delete')}
                                </PopoverItem>
                            </PopoverContent>
                        )}
                    </Elevation>
                </Popover>
            </ContextualMenuContainer>
        );
    }
    return (
        <>
            <Helmet>
                <title>{t('sidebar.settings')}</title>
            </Helmet>
            <MainLayout
                header={<Header {...headerProps} buttons={computeButtonsOutOfProps()} />}
                smallContentSidePadding
            >
                {priceIndexes.length > 0 ? (
                    <Table
                        headers={headers}
                        rows={priceIndexes}
                        columnSizes={
                            isInseePriceIndexesList ? '2fr 2fr 2fr 5fr' : '2fr 2fr 2fr 5fr 1fr'
                        }
                        renderCellMap={renderCellMap}
                        data-testid="priceIndexesTableList"
                    />
                ) : (
                    <EmptyState imageName="Operation" titleTranslationKey="errors.noPriceIndexes" />
                )}
            </MainLayout>
            {addPriceIndexModalOpen && (
                <PriceIndexEditModal
                    isOpen={addPriceIndexModalOpen}
                    onClose={handleEditModalClose}
                    permissionNeeded={permissionNeededToAddPriceIndexes}
                    isCreationMode
                />
            )}
            {editPriceIndexModalOpen && priceIndexToEdit && (
                <PriceIndexEditModal
                    priceIndex={priceIndexToEdit}
                    isOpen={editPriceIndexModalOpen}
                    onClose={handleEditModalClose}
                    permissionNeeded={permissionNeededToEditPriceIndexes}
                />
            )}
            {isDeletePriceIndexConfirmationModalOpen && (
                <Modal
                    isOpen={isDeletePriceIndexConfirmationModalOpen}
                    onCloseButtonPressed={() => {
                        setIsDeletePriceIndexConfirmationModalOpen(false);
                        setIsDeletePriceIndexPending(false);
                        setPriceIndexIdToDelete(null);
                    }}
                    buttons={[
                        {
                            text: t('general.cancel'),
                            aspect: 'secondary',
                            'data-testid': 'cancelDelete',
                            onClick() {
                                setIsDeletePriceIndexConfirmationModalOpen(false);
                            },
                        },
                        {
                            text: t('general.confirm'),
                            aspect: 'primary',
                            'data-testid': 'confirm',
                            onClick() {
                                if (priceIndexIdToDelete) {
                                    setIsDeletePriceIndexPending(true);
                                    void dispatch(deletePriceIndex(priceIndexIdToDelete));
                                    setIsDeletePriceIndexConfirmationModalOpen(false);
                                }
                            },
                        },
                    ]}
                    title={t('priceIndex.deletion')}
                    size="small"
                    iconName="Warning"
                    iconColor={colors.yellow.Y400}
                    centerTitle
                >
                    <Text style={{ margin: '0 0 1rem 0' }}>{t('priceIndex.confirmDeletion')}</Text>
                </Modal>
            )}
        </>
    );
};

export default PriceIndexes;
