import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { format, parse, subMonths, addDays } from 'date-fns';
import { createGlobalStyle } from 'styled-components';

// Style
import { colors } from '../../constants/colors';

// Components
import MainLayout from '../../components/MainLayout';
import ScrollableSidebarLayout from '../../components/ScrollableSidebarLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import type { ButtonProps } from '../../components/Button';
import { showFlag } from '../../components/Flag';
import GridRow from '../../components/GridRow';
import GridCol from '../../components/GridCol';
import Text from '../../components/Text';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import InformationCard from '../../components/InformationCard';
import MarketInvoiceSummary from '../../components/invoice/MarketInvoiceSummary';
import MarketInvoiceAvancement from '../../components/invoice/MarketInvoiceAvancement';
import MarketInvoicePricesVariation from '../../components/invoice/MarketInvoicePricesVariation';
import PermissionAwareDateInputField from '../../components/PermissionAwareDateInputField';

// Slices
import { getMarket, selectMarket } from '../../slices/marketSlice';
import type { Engagement } from '../../slices/engagementSlice';
import { getEngagements, selectEngagements } from '../../slices/engagementSlice';
import type {
    EngagementInvoiceFormData,
    MarketInvoiceFormData,
    MarketInvoiceCalculatedData,
    MarketInvoice as MarketInvoiceType,
    EngagementInvoice,
} from '../../slices/invoiceSlice';
import {
    getMarketInvoice,
    getMarketInvoiceForEdit,
    selectMarketInvoice,
    getLastMarketInvoices,
    selectLastMarketInvoices,
    updateMarketInvoice,
    deleteMarketInvoice,
    selectError,
    getPreviousMarketInvoice,
    selectPreviousMarketInvoice,
} from '../../slices/invoiceSlice';
import type { Permission } from '../../slices/authSlice';
import { getTvaCodes, selectTvaCodesById } from '../../slices/tvaCodeSlice';

// Hooks
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';

// Utils
import { CurrencyFormatter, formatNumberWithLeadingZeros } from '../../utils/formatters';
import {
    getTotal,
    calculateAmountTtc,
    getTvaRate,
    getTotalOfProperty,
    getTotalTtcOfProperty,
} from '../../utils/invoiceCalculation';
import { useAppDispatch } from '../../store';

const GlobalStyle = createGlobalStyle`
    .bp4-form-group {
        margin-bottom: 0.3rem !important;
    }
`;

type Props = {
    headerProps: Partial<HeaderProps>;
};

const MarketInvoice: React.FC<Props> = ({ headerProps }) => {
    const { width } = useWindowDimensions();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        operationId,
        marketId,
        invoiceId,
    }: { operationId?: string; marketId?: string; invoiceId?: string } = useParams();

    const location = useLocation();

    const state = location.state ?? {};
    const { isCreation } = state;

    const [isPending, setIsPending] = useState<boolean>(false);
    const [isDeletePending, setIsDeletePending] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const invoice = useSelector(selectMarketInvoice(Number(invoiceId)));
    const invoiceGroupCode = invoice?.groupCode ?? null;
    const lastMarketInvoicesId = useSelector(selectLastMarketInvoices);
    const previousMarketInvoice = useSelector(selectPreviousMarketInvoice);
    const market = useSelector(selectMarket(Number(marketId)));
    const engagements = useSelector(selectEngagements);
    const tvaCodesById = useSelector(selectTvaCodesById);
    const serverError = useSelector(selectError);

    const getEngagementById = useCallback(
        (engagementId: number): Engagement | undefined =>
            engagements.find((engagement) => engagement.id === engagementId),
        [engagements],
    );

    const [isEditMode, setIsEditMode] = useState<boolean>(
        Boolean(location.search === '?edit' || isCreation),
    );

    const permissionNeededToRead = { code: 'INVOICES_READ', operationId };
    const permissionNeededToEdit: Permission = { code: 'INVOICES_EDIT', operationId };
    const permissionNeededToDelete: Permission = { code: 'INVOICES_DELETE', operationId };

    const hasRightToReadInvoices = usePermissionsCheck([permissionNeededToRead]);
    let hasRightToEditInvoices = usePermissionsCheck([permissionNeededToEdit]);
    let hasRightToDeleteInvoices = usePermissionsCheck([permissionNeededToDelete]);

    // Editing is only possible if invoice is the last one of it's group code
    if (!lastMarketInvoicesId.includes(Number(invoiceId))) {
        hasRightToEditInvoices = false;
        hasRightToDeleteInvoices = false;
    }

    const today = new Date();
    const previousMonth = format(subMonths(new Date(), 1), 'MM/yyyy');

    const getEngagementInvoicesData = (engagementInvoices: EngagementInvoice[]) =>
        engagementInvoices.map((engagementInvoice) => {
            const {
                currentCumulatedAvancementsAmountHt,
                currentCumulatedRevisionsAmountHt,
                ...engagementInvoiceRest
            } = engagementInvoice;
            const formattedCurrentCumulatedAvancementsAmountHt = Number(
                currentCumulatedAvancementsAmountHt,
            ).toFixed(2);
            const formattedCurrentCumulatedRevisionsAmountHt = Number(
                currentCumulatedRevisionsAmountHt,
            ).toFixed(2);
            const engagementInvoiceData: EngagementInvoiceFormData = {
                currentCumulatedAvancementsAmountHt: formattedCurrentCumulatedAvancementsAmountHt,
                currentCumulatedRevisionsAmountHt: formattedCurrentCumulatedRevisionsAmountHt,
                ...engagementInvoiceRest,
            };
            return engagementInvoiceData;
        });

    const initialValues: MarketInvoiceFormData = {
        month: format(
            new Date(parse(invoice?.month ?? previousMonth, 'MM/yyyy', new Date())),
            'yyyy-MM-dd',
        ),
        presentedAt: invoice?.presentedAt ?? format(today, 'yyyy-MM-dd'),
        approvalProjectOwnerAt: invoice?.approvalProjectOwnerAt ?? format(today, 'yyyy-MM-dd'),
        taxesAdjustment: invoice?.taxesAdjustment
            ? invoice.taxesAdjustment.toFixed(2)
            : Number(0).toFixed(2),
        currentCumulatedHoldback: invoice?.currentCumulatedHoldback
            ? invoice.currentCumulatedHoldback.toFixed(2)
            : Number(0).toFixed(2),
        currentCumulatedInitialAdvancePaymentTtc: invoice?.currentCumulatedInitialAdvancePaymentTtc
            ? invoice.currentCumulatedInitialAdvancePaymentTtc.toFixed(2)
            : Number(0).toFixed(2),
        currentCumulatedAdvancePaymentTakebackTtc:
            invoice?.currentCumulatedAdvancePaymentTakebackTtc
                ? invoice.currentCumulatedAdvancePaymentTakebackTtc.toFixed(2)
                : Number(0).toFixed(2),
        currentCumulatedBonusesTtc: invoice?.currentCumulatedBonusesTtc
            ? invoice.currentCumulatedBonusesTtc.toFixed(2)
            : Number(0).toFixed(2),
        currentCumulatedDeductions: invoice?.currentCumulatedDeductions
            ? invoice.currentCumulatedDeductions.toFixed(2)
            : Number(0).toFixed(2),
        currentCumulatedPenalties: invoice?.currentCumulatedPenalties
            ? invoice.currentCumulatedPenalties.toFixed(2)
            : Number(0).toFixed(2),
        engagementInvoices: invoice?.engagementInvoices
            ? getEngagementInvoicesData(invoice.engagementInvoices)
            : [],
        initialMarketEngagementInvoices: [],
        otherEngagementInvoices: [],
    };

    // Group engagement invoices by engagement type
    initialValues.engagementInvoices.forEach((engagementInvoice: EngagementInvoiceFormData) => {
        // Note: in engagements we have only this month engagements, it's why we do calculations only if we find engagement
        const engagement = engagements.find(
            (engagementItem: Engagement) =>
                engagementItem.id === engagementInvoice.engagementId &&
                engagementItem.groupCode === engagementInvoice.groupCode &&
                engagementItem.groupCode === invoiceGroupCode,
        );

        // Group engagement invoices by engagement type
        if (engagement && engagement.engagementType === 'market') {
            initialValues.initialMarketEngagementInvoices.push({
                ...engagementInvoice,
                engagementLabel: engagement.label,
                engagementOrder: engagement.order,
            });
        }
        if (engagement && engagement.engagementType !== 'market') {
            initialValues.otherEngagementInvoices.push({
                ...engagementInvoice,
                engagementLabel: engagement.label,
                engagementOrder: engagement.order,
            });
        }
    });

    // Sort engagement invoices by engagement order
    const sortEngagementInvoices = (engagementInvoicesList: EngagementInvoiceFormData[]) =>
        engagementInvoicesList.sort((a, b) => {
            const marketA = a.engagementOrder ?? 0;
            const marketB = b.engagementOrder ?? 0;
            return marketA > marketB ? 1 : -1;
        });

    sortEngagementInvoices(initialValues.initialMarketEngagementInvoices);
    sortEngagementInvoices(initialValues.otherEngagementInvoices);

    const makeCalculation = useCallback(
        (formikValues: MarketInvoiceFormData): MarketInvoiceCalculatedData | undefined => {
            const calculatedData: MarketInvoiceCalculatedData = {
                id: invoiceId ? Number(invoiceId) : undefined,
                marketId: Number(marketId),
                month: formikValues.month,
                presentedAt: formikValues.presentedAt,
                approvalProjectOwnerAt: formikValues.approvalProjectOwnerAt,
                dueDate: '',
                currentCumulatedInitialMarketAvancementsHt: 0,
                currentCumulatedInitialMarketAvancementsTtc: 0,
                previousCumulatedInitialMarketAvancementsHt:
                    invoice?.previousCumulatedInitialMarketAvancementsHt ?? 0,
                initialMarketAvancementsHt: 0,
                initialMarketAvancementsTtc: 0,
                currentCumulatedOtherWorksAvancementsHt: 0,
                previousCumulatedOtherWorksAvancementsHt:
                    invoice?.previousCumulatedOtherWorksAvancementsHt ?? 0,
                otherWorksAvancementsHt: 0,
                otherWorksAvancementsTtc: 0,
                currentCumulatedAvancementsTotalHt: 0,
                previousCumulatedAvancementsTotalHt:
                    invoice?.previousCumulatedAvancementsTotalHt ?? 0,
                currentCumulatedRevisionsAmountHt: 0,
                previousCumulatedRevisionsAmountHt:
                    invoice?.previousCumulatedRevisionsAmountHt ?? 0,
                currentCumulatedRevisionsAmountTtc: 0,
                avancementsTotalHt: 0,
                avancementsTotalTtc: 0,
                avancementsTva: 0,
                revisionsAmountHt: 0,
                revisionsAmountTtc: 0,
                revisionTva: 0,
                taxesAdjustment: Number(formikValues.taxesAdjustment),
                currentCumulatedAvancementsAndRevisionsTotalHt: 0,
                previousCumulatedAvancementsAndRevisionsTotalHt:
                    invoice?.previousCumulatedAvancementsAndRevisionsTotalHt ?? 0,
                avancementsAndRevisionsTaxes: 0,
                avancementsAndRevisionsTotalHt: 0,
                avancementsAndRevisionsTotalTtc: 0,
                currentCumulatedHoldback: Number(formikValues.currentCumulatedHoldback),
                previousCumulatedHoldback: invoice?.previousCumulatedHoldback ?? 0,
                holdback: 0,
                currentCumulatedInitialAdvancePaymentTtc: Number(
                    formikValues.currentCumulatedInitialAdvancePaymentTtc,
                ),
                previousCumulatedInitialAdvancePaymentTtc:
                    invoice?.previousCumulatedInitialAdvancePaymentTtc ?? 0,
                initialAdvancePaymentTtc: 0,
                currentCumulatedAdvancePaymentTakebackTtc: Number(
                    formikValues.currentCumulatedAdvancePaymentTakebackTtc,
                ),
                previousCumulatedAdvancePaymentTakebackTtc:
                    invoice?.previousCumulatedAdvancePaymentTakebackTtc ?? 0,
                advancePaymentTakebackTtc: 0,
                currentCumulatedDeductions: Number(formikValues.currentCumulatedDeductions),
                previousCumulatedDeductions: invoice?.previousCumulatedDeductions ?? 0,
                deductions: 0,
                currentCumulatedBonusesTtc: Number(formikValues.currentCumulatedBonusesTtc),
                previousCumulatedBonusesTtc: invoice?.previousCumulatedBonusesTtc ?? 0,
                bonusesTtc: 0,
                currentCumulatedPenalties: Number(formikValues.currentCumulatedPenalties),
                previousCumulatedPenalties: invoice?.previousCumulatedPenalties ?? 0,
                penalties: 0,
                totalAmountTtc: 0,
                initialMarketEngagementInvoices: [],
                otherEngagementInvoices: [],
                engagementInvoices: [],
                isImported: invoice?.isImported ?? false,
            };

            calculatedData.dueDate = format(
                addDays(
                    new Date(parse(calculatedData.presentedAt, 'yyyy-MM-dd', new Date())),
                    market?.paymentDelay ?? 0,
                ),
                'yyyy-MM-dd',
            );

            // Method to calculate all data of each engagement invoice
            const makeEngagementInvoiceCalculation = (
                formikEngagementInvoices: EngagementInvoiceFormData[],
            ): EngagementInvoiceFormData[] =>
                formikEngagementInvoices.map(
                    (formikEngagementInvoice: EngagementInvoiceFormData) => {
                        const calculatedEngagementInvoice = { ...formikEngagementInvoice };

                        calculatedEngagementInvoice.currentCumulatedAvancementsAmountHt = Number(
                            formikEngagementInvoice.currentCumulatedAvancementsAmountHt,
                        );

                        calculatedEngagementInvoice.avancementsAmountHt =
                            calculatedEngagementInvoice.currentCumulatedAvancementsAmountHt -
                            calculatedEngagementInvoice.previousCumulatedAvancementsAmountHt;
                        // To prevent case of difference 0 - 0 which returns NaN
                        calculatedEngagementInvoice.avancementsAmountHt =
                            calculatedEngagementInvoice.avancementsAmountHt || 0;

                        calculatedEngagementInvoice.avancementsAmountTtc = calculateAmountTtc(
                            calculatedEngagementInvoice.avancementsAmountHt,
                            getTvaRate(tvaCodesById, calculatedEngagementInvoice.tvaCodeId),
                        );

                        calculatedEngagementInvoice.taxesOnAvancements =
                            calculatedEngagementInvoice.avancementsAmountTtc -
                            calculatedEngagementInvoice.avancementsAmountHt;

                        const engagementAmountHt =
                            getEngagementById(calculatedEngagementInvoice.engagementId)?.amountHt ??
                            0;
                        calculatedEngagementInvoice.restToInvoiceHt =
                            engagementAmountHt -
                            calculatedEngagementInvoice.currentCumulatedAvancementsAmountHt;

                        return calculatedEngagementInvoice;
                    },
                );

            // Calculate amount of each initial market engagement invoice (amount of the month = current cumulated - previous cumulated)
            calculatedData.initialMarketEngagementInvoices = makeEngagementInvoiceCalculation(
                formikValues.initialMarketEngagementInvoices,
            );

            // Calculate amount of each other engagement invoice (amount of the month = current cumulated - previous cumulated)
            calculatedData.otherEngagementInvoices = makeEngagementInvoiceCalculation(
                formikValues.otherEngagementInvoices,
            );

            calculatedData.engagementInvoices = [
                ...calculatedData.initialMarketEngagementInvoices,
                ...calculatedData.otherEngagementInvoices,
            ];

            // Calculate amount of each revision (amount of the month = current cumulated - previous cumulated)
            calculatedData.engagementInvoices.forEach(
                (engagementInvoice: EngagementInvoiceFormData, index: number) => {
                    engagementInvoice.currentCumulatedRevisionsAmountHt = Number(
                        formikValues.engagementInvoices[index].currentCumulatedRevisionsAmountHt,
                    );

                    engagementInvoice.revisionsAmountHt =
                        engagementInvoice.currentCumulatedRevisionsAmountHt -
                        engagementInvoice.previousCumulatedRevisionsAmountHt;
                    // To prevent case of difference 0 - 0 which returns NaN
                    engagementInvoice.revisionsAmountHt = engagementInvoice.revisionsAmountHt || 0;

                    engagementInvoice.revisionsAmountTtc = calculateAmountTtc(
                        engagementInvoice.revisionsAmountHt,
                        getTvaRate(tvaCodesById, engagementInvoice.tvaCodeId),
                    );

                    return engagementInvoice;
                },
            );

            calculatedData.currentCumulatedInitialMarketAvancementsHt = getTotalOfProperty(
                calculatedData.initialMarketEngagementInvoices,
                'currentCumulatedAvancementsAmountHt',
            );

            calculatedData.currentCumulatedInitialMarketAvancementsTtc = getTotalTtcOfProperty(
                calculatedData.initialMarketEngagementInvoices,
                'currentCumulatedAvancementsAmountHt',
                tvaCodesById,
            );

            calculatedData.initialMarketAvancementsHt = getTotalOfProperty(
                calculatedData.initialMarketEngagementInvoices,
                'avancementsAmountHt',
            );

            calculatedData.initialMarketAvancementsTtc = getTotalOfProperty(
                calculatedData.initialMarketEngagementInvoices,
                'avancementsAmountTtc',
            );

            calculatedData.currentCumulatedOtherWorksAvancementsHt = getTotalOfProperty(
                calculatedData.otherEngagementInvoices,
                'currentCumulatedAvancementsAmountHt',
            );

            calculatedData.otherWorksAvancementsHt = getTotalOfProperty(
                calculatedData.otherEngagementInvoices,
                'avancementsAmountHt',
            );

            calculatedData.otherWorksAvancementsTtc = getTotalOfProperty(
                calculatedData.otherEngagementInvoices,
                'avancementsAmountTtc',
            );

            calculatedData.currentCumulatedAvancementsTotalHt =
                calculatedData.currentCumulatedInitialMarketAvancementsHt +
                calculatedData.currentCumulatedOtherWorksAvancementsHt;

            calculatedData.avancementsTotalHt =
                calculatedData.initialMarketAvancementsHt + calculatedData.otherWorksAvancementsHt;

            calculatedData.avancementsTotalTtc =
                calculatedData.initialMarketAvancementsTtc +
                calculatedData.otherWorksAvancementsTtc;

            calculatedData.currentCumulatedRevisionsAmountHt = getTotalOfProperty(
                calculatedData.engagementInvoices,
                'currentCumulatedRevisionsAmountHt',
            );

            calculatedData.currentCumulatedRevisionsAmountTtc = getTotalTtcOfProperty(
                calculatedData.engagementInvoices,
                'currentCumulatedRevisionsAmountHt',
                tvaCodesById,
            );

            calculatedData.previousCumulatedRevisionsAmountHt = getTotalOfProperty(
                calculatedData.engagementInvoices,
                'previousCumulatedRevisionsAmountHt',
            );

            calculatedData.revisionsAmountHt = getTotalOfProperty(
                calculatedData.engagementInvoices,
                'revisionsAmountHt',
            );

            calculatedData.revisionsAmountTtc = getTotalOfProperty(
                calculatedData.engagementInvoices,
                'revisionsAmountTtc',
            );

            calculatedData.currentCumulatedAvancementsAndRevisionsTotalHt =
                calculatedData.currentCumulatedAvancementsTotalHt +
                calculatedData.currentCumulatedRevisionsAmountHt;

            calculatedData.avancementsAndRevisionsTotalHt =
                calculatedData.avancementsTotalHt + calculatedData.revisionsAmountHt;

            calculatedData.avancementsTva =
                calculatedData.avancementsTotalTtc - calculatedData.avancementsTotalHt;
            calculatedData.avancementsTva = calculatedData.avancementsTva || 0;

            calculatedData.revisionTva =
                calculatedData.revisionsAmountTtc - calculatedData.revisionsAmountHt;
            calculatedData.revisionTva = calculatedData.revisionTva || 0;

            calculatedData.avancementsAndRevisionsTotalTtc = getTotal([
                calculatedData.avancementsAndRevisionsTotalHt,
                calculatedData.avancementsTva,
                calculatedData.revisionTva,
                calculatedData.taxesAdjustment,
            ]);

            calculatedData.holdback =
                calculatedData.currentCumulatedHoldback - calculatedData.previousCumulatedHoldback;

            calculatedData.initialAdvancePaymentTtc =
                calculatedData.currentCumulatedInitialAdvancePaymentTtc -
                calculatedData.previousCumulatedInitialAdvancePaymentTtc;

            calculatedData.advancePaymentTakebackTtc =
                calculatedData.currentCumulatedAdvancePaymentTakebackTtc -
                calculatedData.previousCumulatedAdvancePaymentTakebackTtc;

            calculatedData.deductions =
                calculatedData.currentCumulatedDeductions -
                calculatedData.previousCumulatedDeductions;

            calculatedData.bonusesTtc =
                calculatedData.currentCumulatedBonusesTtc -
                calculatedData.previousCumulatedBonusesTtc;

            calculatedData.penalties =
                calculatedData.currentCumulatedPenalties -
                calculatedData.previousCumulatedPenalties;

            calculatedData.totalAmountTtc = getTotal([
                calculatedData.avancementsAndRevisionsTotalTtc,
                calculatedData.holdback,
                calculatedData.initialAdvancePaymentTtc,
                calculatedData.advancePaymentTakebackTtc,
                calculatedData.deductions,
                calculatedData.bonusesTtc,
                calculatedData.penalties,
            ]);

            return calculatedData;
        },
        [getEngagementById, invoice, invoiceId, market, marketId, tvaCodesById],
    );

    const createPayload = useCallback(
        (values: MarketInvoiceFormData) => {
            const calculatedData = makeCalculation(values) as
                | Partial<MarketInvoiceCalculatedData>
                | undefined;
            if (calculatedData) {
                // Delete data that is not stored in database
                calculatedData.month = String(calculatedData.month);
                delete calculatedData.currentCumulatedInitialMarketAvancementsTtc;
                delete calculatedData.initialMarketAvancementsTtc;
                delete calculatedData.otherWorksAvancementsTtc;
                delete calculatedData.currentCumulatedRevisionsAmountTtc;
                delete calculatedData.avancementsTotalTtc;
                delete calculatedData.avancementsTva;
                delete calculatedData.revisionsAmountTtc;
                delete calculatedData.revisionTva;
                delete calculatedData.initialMarketEngagementInvoices;
                delete calculatedData.otherEngagementInvoices;
                calculatedData.engagementInvoices?.forEach((engagementInvoice) => {
                    const engagement = engagementInvoice as Partial<EngagementInvoiceFormData>;
                    delete engagement.engagementLabel;
                    delete engagement.engagementOrder;
                    return engagement;
                });
            }

            return calculatedData;
        },
        [makeCalculation],
    );

    useEffect(() => {
        if (invoiceId && marketId && operationId) {
            void dispatch(getMarket({ id: Number(marketId), operationId: Number(operationId) }));
            void dispatch(
                getEngagements({ marketId: Number(marketId), operationId: Number(operationId) }),
            );
            void dispatch(
                getMarketInvoice({
                    id: Number(invoiceId),
                    marketId: Number(marketId),
                    operationId: Number(operationId),
                }),
            );
            void dispatch(getTvaCodes({ operationId: Number(operationId) }));
            void dispatch(
                getLastMarketInvoices({
                    marketId: Number(marketId),
                    operationId: Number(operationId),
                }),
            );
            void dispatch(
                getPreviousMarketInvoice({
                    id: Number(invoiceId),
                    marketId: Number(marketId),
                    operationId: Number(operationId),
                }),
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps -- Don't want to rerender on every dependencies change
    }, [invoiceId, marketId, operationId]);

    useEffect(() => {
        if (isDeletePending && !invoice) {
            navigate(`/operations/${operationId}/markets/${marketId}/invoices`);
            showFlag('success', t('general.success'), t('invoice.successfullyDeleted'));
        } else {
            if (serverError) {
                showFlag('error', t('errors.error'), t(serverError));
                setIsDeletePending(false);
            }
        }
    }, [navigate, invoice, isDeletePending, marketId, operationId, serverError, t]);

    useEffect(() => {
        if (serverError === 'errors.invoiceMustExist') {
            navigate(`/operations/${operationId}/markets/${marketId}/invoices`);
        }
    }, [navigate, isPending, marketId, serverError, operationId]);

    useEffect(() => {
        if (isPending) {
            if (serverError) {
                showFlag('error', t('errors.error'), t(serverError));
                setIsEditMode(true);
            } else {
                showFlag('success', t('general.success'), t('invoice.successfullyUpdated'));
            }
        }
    }, [isPending, serverError, t]);

    // When switching to edit mode, get market invoice
    useEffect(() => {
        if (isEditMode) {
            void dispatch(
                getMarketInvoiceForEdit({
                    id: Number(invoiceId),
                    marketId: Number(marketId),
                    operationId: Number(operationId),
                }),
            );
        }
    }, [dispatch, invoiceId, isEditMode, marketId, operationId]);

    // Scroll menu
    const summaryCardRef = useRef<HTMLDivElement>(null);
    const advancementsCardRef = useRef<HTMLDivElement>(null);
    const priceVariationCardRef = useRef<HTMLDivElement>(null);
    // const beneficiariesCardRef = useRef<HTMLDivElement>(null);

    const scrollMenuTabs = [
        {
            text: t('invoice.summary.title'),
            ref: summaryCardRef,
        },
        {
            text: t('invoice.advance.title'),
            ref: advancementsCardRef,
        },
        {
            text: t('invoice.priceVariation.title'),
            ref: priceVariationCardRef,
        },
        // {
        //     text: t('invoice.beneficiaries.title'),
        //     ref: beneficiariesCardRef,
        // },
    ];

    const scrollableContent = (
        formikValues: MarketInvoiceFormData,
        calculatedData: MarketInvoiceCalculatedData,
    ) => (
        <>
            <GlobalStyle />
            <InformationCard title={t('invoice.summary.title')} style={{ padding: '1rem' }}>
                <div ref={summaryCardRef} />
                <MarketInvoiceSummary
                    calculatedData={calculatedData}
                    windowWidth={width}
                    isEditMode={isEditMode}
                    permissionsRequired={[permissionNeededToEdit]}
                />
            </InformationCard>
            <InformationCard title={t('invoice.advance.title')} style={{ padding: '1rem' }}>
                <div ref={advancementsCardRef} />
                <MarketInvoiceAvancement
                    invoiceNumber={invoice?.invoiceNumber ?? null}
                    formikValues={formikValues}
                    calculatedData={calculatedData}
                    windowWidth={width}
                    isEditMode={isEditMode}
                    permissionsRequired={[permissionNeededToEdit]}
                />
            </InformationCard>
            <InformationCard title={t('invoice.priceVariation.title')} style={{ padding: '1rem' }}>
                <div ref={priceVariationCardRef} />
                <MarketInvoicePricesVariation
                    invoiceNumber={invoice?.invoiceNumber ?? null}
                    formikValues={formikValues}
                    calculatedData={calculatedData}
                    windowWidth={width}
                    isEditMode={isEditMode}
                    permissionsRequired={[permissionNeededToEdit]}
                />
            </InformationCard>
        </>
    );

    const engagementInvoiceSchema = Yup.object().shape({
        id: Yup.number(),
        marketInvoiceId: Yup.number(),
        engagementId: Yup.number(),
        previousCumulatedAvancementsAmountHt: Yup.number(),
        currentCumulatedAvancementsAmountHt: Yup.string(),
        avancementsAmountHt: Yup.number(),
        avancementsAmountTtc: Yup.number(),
        taxesOnAvancements: Yup.number(),
        restToInvoiceHt: Yup.number(),
        previousCumulatedRevisionsAmountHt: Yup.number(),
        currentCumulatedRevisionsAmountHt: Yup.number(),
        revisionsAmountHt: Yup.number(),
        revisionsAmountTtc: Yup.number(),
        tvaCodeId: Yup.number(),
        tvaRate: Yup.number(),
        isImported: Yup.boolean(),
        groupCode: Yup.string().nullable(),
        engagementLabel: Yup.string(),
    });

    const marketInvoiceSchema = Yup.object().shape({
        month: Yup.string().required(t('errors.required')).nullable(),
        presentedAt: Yup.string().required(t('errors.required')).nullable(),
        approvalProjectOwnerAt: Yup.string().required(t('errors.required')).nullable(),
        taxesAdjustment: Yup.number(),
        currentCumulatedHoldback: Yup.number().required(t('errors.required')),
        currentCumulatedInitialAdvancePaymentTtc: Yup.number().required(t('errors.required')),
        currentCumulatedAdvancePaymentTakebackTtc: Yup.number().required(t('errors.required')),
        currentCumulatedBonusesTtc: Yup.number().required(t('errors.required')),
        currentCumulatedDeductions: Yup.number().required(t('errors.required')),
        currentCumulatedPenalties: Yup.number().required(t('errors.required')),
        engagementInvoices: Yup.array().of(engagementInvoiceSchema),
        initialMarketEngagementInvoices: Yup.array().of(engagementInvoiceSchema),
        otherEngagementInvoices: Yup.array().of(engagementInvoiceSchema),
    });

    const handleDeleteClick = () => setIsDeleteModalOpen(true);

    const computeButtonsOutOfProps = (props: FormikProps<MarketInvoiceFormData>): ButtonProps[] => {
        if (isEditMode) {
            const buttons: ButtonProps[] = [];

            if (hasRightToDeleteInvoices) {
                buttons.push({
                    'data-testid': 'deleteMarketInvoice',
                    aspect: 'onlyIcon',
                    iconName: 'DeleteOutline',
                    onClick: handleDeleteClick,
                });
            }

            buttons.push({
                text: t('general.cancel'),
                'data-testid': 'cancelModifyMarketInvoice',
                aspect: 'secondary',
                onClick() {
                    props.resetForm();
                    if (isCreation) {
                        navigate(-1);
                    } else {
                        navigate(location.pathname.replace('?edit', ''), { state, replace: true });
                        setIsEditMode(false);
                    }
                },
            });

            buttons.push({
                text: t('general.submit'),
                'data-testid': 'submitMarketInvoice',
                aspect: 'primary',
                onClick() {
                    props.handleSubmit();
                },
            });

            return buttons;
        } else if (hasRightToEditInvoices) {
            return [
                {
                    text: t('general.edit'),
                    'data-testid': 'modifyMarketInvoice',
                    aspect: 'primary',
                    onClick() {
                        navigate(`${location.pathname}?edit`, { state, replace: true });
                        setIsEditMode(true);
                    },
                },
            ];
        } else {
            return [];
        }
    };

    const getMinimumInvoiceDate = (marketInvoice: MarketInvoiceType) => {
        const { month } = marketInvoice;
        const [invoiceMonth, invoiceYear] = month.split('/');
        const completeDate = `${invoiceYear}/${invoiceMonth}/01`;
        return new Date(completeDate);
    };

    return market && invoice && hasRightToReadInvoices ? (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={marketInvoiceSchema}
                onSubmit={(values: MarketInvoiceFormData, actions) => {
                    const payload = createPayload(values);
                    if (payload) {
                        void dispatch(
                            updateMarketInvoice({
                                marketInvoice: payload,
                                operationId: Number(operationId),
                                marketId: Number(marketId),
                            }),
                        );
                        actions.setSubmitting(false);
                        setIsEditMode(false);
                        navigate(location.pathname.replace('edit', ''), {
                            state: { isCreation: false },
                            replace: true,
                        });
                        setIsPending(true);
                    }
                }}
                enableReinitialize
            >
                {(props: FormikProps<MarketInvoiceFormData>) => {
                    const calculatedData = makeCalculation(props.values);
                    return (
                        calculatedData && (
                            <MainLayout
                                header={
                                    <Header
                                        {...headerProps}
                                        title={`${market.externalNumber} - ${market.label} /
                    ${invoice.groupCode ?? 'S'} ${formatNumberWithLeadingZeros(
                                            invoice.invoiceNumber,
                                            3,
                                        )}`}
                                        goBackTo={`/operations/${operationId}/markets/${marketId}/invoices`}
                                        buttons={computeButtonsOutOfProps(props)}
                                        showBackButton
                                        showNavigation
                                    />
                                }
                            >
                                <>
                                    <InformationCard
                                        title=""
                                        style={{ padding: '2rem 2rem 1rem 2rem' }}
                                    >
                                        <GridRow style={{ width: '100%' }}>
                                            <GridCol>
                                                <Text type="small" uppercased>
                                                    {t('invoice.generalHeader.month')}
                                                </Text>
                                                <PermissionAwareDateInputField
                                                    editable={isEditMode}
                                                    permissionsRequired={[permissionNeededToEdit]}
                                                    name="month"
                                                    data-testid="month"
                                                    errorTestId="errorMonth"
                                                    minAllowedDate={
                                                        previousMarketInvoice
                                                            ? getMinimumInvoiceDate(
                                                                  previousMarketInvoice,
                                                              )
                                                            : undefined
                                                    }
                                                    whiteBackground
                                                    noMarginTop
                                                    isMonthPicker
                                                />
                                            </GridCol>
                                            <GridCol>
                                                <Text type="small" uppercased>
                                                    {t('invoice.generalHeader.presentedAt')}
                                                </Text>
                                                <PermissionAwareDateInputField
                                                    editable={isEditMode}
                                                    permissionsRequired={[permissionNeededToEdit]}
                                                    name="presentedAt"
                                                    data-testid="presentedAt"
                                                    errorTestId="errorPresentedAt"
                                                    whiteBackground
                                                    noMarginTop
                                                />
                                            </GridCol>
                                            <GridCol>
                                                <Text type="small" uppercased>
                                                    {t('invoice.generalHeader.validOn')}
                                                </Text>
                                                <PermissionAwareDateInputField
                                                    editable={isEditMode}
                                                    permissionsRequired={[permissionNeededToEdit]}
                                                    name="approvalProjectOwnerAt"
                                                    data-testid="approvalProjectOwnerAt"
                                                    errorTestId="errorApprovalProjectOwnerAt"
                                                    whiteBackground
                                                    noMarginTop
                                                />
                                            </GridCol>
                                            <GridCol>
                                                <Text type="small" uppercased>
                                                    {t('invoice.generalHeader.dueDate')}
                                                </Text>
                                                <PermissionAwareDateInputField
                                                    permissionsRequired={[permissionNeededToEdit]}
                                                    name="dueDate"
                                                    value={calculatedData.dueDate}
                                                    whiteBackground
                                                    noMarginTop
                                                />
                                            </GridCol>
                                            <GridCol>
                                                <Text type="small" uppercased>
                                                    {t('invoice.generalHeader.ttc')}
                                                </Text>
                                                <Text uppercased isNumeral>
                                                    {CurrencyFormatter.format(
                                                        calculatedData.totalAmountTtc,
                                                    )}
                                                </Text>
                                            </GridCol>
                                        </GridRow>
                                    </InformationCard>
                                    {width > 1375 ? (
                                        <GridRow style={{ width: '100%' }}>
                                            <ScrollableSidebarLayout tabs={scrollMenuTabs}>
                                                {scrollableContent(props.values, calculatedData)}
                                            </ScrollableSidebarLayout>
                                        </GridRow>
                                    ) : (
                                        <GridCol style={{ padding: 0 }}>
                                            {scrollableContent(props.values, calculatedData)}
                                        </GridCol>
                                    )}
                                </>
                            </MainLayout>
                        )
                    );
                }}
            </Formik>
            <Modal
                isOpen={isDeleteModalOpen}
                onCloseButtonPressed={() => setIsDeleteModalOpen(false)}
                buttons={[
                    {
                        text: t('general.cancel'),
                        aspect: 'secondary',
                        'data-testid': 'cancelDelete',
                        onClick() {
                            setIsDeleteModalOpen(false);
                        },
                    },
                    {
                        text: t('general.confirm'),
                        aspect: 'primary',
                        'data-testid': 'confirm',
                        onClick() {
                            void dispatch(
                                deleteMarketInvoice({
                                    id: Number(invoiceId),
                                    marketId: Number(marketId),
                                    operationId: Number(operationId),
                                }),
                            );
                            setIsDeletePending(true);
                        },
                    },
                ]}
                title={t('invoice.deletion')}
                size="small"
                iconName="Warning"
                iconColor={colors.yellow.Y400}
                centerTitle
            >
                <Text style={{ margin: '0 0 1rem 0' }}>{t('invoice.confirmDeletion')}</Text>
            </Modal>
        </>
    ) : (
        <Loader />
    );
};

export default MarketInvoice;
