import { roundAmount } from './calculationMethods';

export const calculateAmountRatio = (
    numerator: number | undefined,
    denominator: number | undefined,
): number => {
    // We check if numerator === 0 to avoid returning a NaN value
    // But we do want to let denominator = 0 to return an Infinite result
    if (numerator === undefined || numerator === 0 || denominator === undefined) {
        return 0;
    }
    const ratio = numerator / denominator;
    // We round at 4 decimals because ratio will be multiplied per 100 to be displayed and we'll lose 2 decimal
    // Example: 0,0677943 must be rounded to 0,0678 to be displayed 6,78%
    return roundAmount(ratio, 4);
};
