import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Components
import Text from '../../components/Text';
import MainLayout from '../../components/MainLayout';
import GridCol from '../../components/GridCol';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import InformationCard from '../../components/InformationCard';
import Tooltip from '../../components/Tooltip';
import type { ButtonProps } from '../../components/Button';
import Avatar from '../../components/Avatar';
import EmptyState from '../../components/EmptyState';
import { showFlag } from '../../components/Flag';
import Loader from '../../components/Loader';
import PermissionAwareAddress from '../../components/PermissionAwareAddress';

// Assets, constants & utils
import { colors } from '../../constants/colors';

import type { ISiteFormData } from '../../slices/siteSlice';
import {
    getSite,
    selectSite,
    createSite,
    updateSite,
    selectError,
    selectSiteIdAdded,
} from '../../slices/siteSlice';
import type { User } from '../../slices/userSlice';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import { useAppDispatch } from '../../store';

const UserContainer = styled(GridCol)`
    display: flex;
    margin: 0 0 1rem 0;
`;
const UserInfos = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 0.5rem;
`;
const UserFullname = styled(Text)`
    display: flex;
    margin: 0;
`;

const UserMail = styled(Text)`
    display: flex;
    margin: 0;
`;

type Props = {
    headerProps: HeaderProps;
};

type LocationState = {
    organizationId?: string;
    goBackTo?: string;
    breadcrumbs?: Array<{ to: string; text: string }>;
};

const Site: React.FunctionComponent<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { organizationId, siteId } = useParams<{ organizationId?: string; siteId: string }>();
    const navigate = useNavigate();
    const hasRightToAddSites = usePermissionsCheck([
        { code: 'ORGANIZATIONS_EDIT', organizationId },
    ]);
    const site = useSelector(selectSite(Number(siteId)));
    const [isPending, setIsPending] = useState<boolean>(false);
    const serverError = useSelector(selectError);
    const siteIdAdded = useSelector(selectSiteIdAdded);
    let isCreation = false;
    if (siteId === 'new') {
        isCreation = true;
    }
    const permissionNeeded = { code: 'ORGANIZATIONS_EDIT', organizationId };
    const location = useLocation();
    const state: LocationState = location.state ?? {};
    const [isEditMode, setIsEditMode] = useState<boolean>(
        location.search === '?edit' || isCreation,
    );

    useEffect(() => {
        if (siteId && siteId !== 'new' && organizationId) {
            void dispatch(
                getSite({
                    id: Number(siteId),
                    organizationId,
                }),
            );
        }
    }, [dispatch, siteId, organizationId]);

    useEffect(() => {
        if (isPending) {
            if (serverError) {
                showFlag('error', t('errors.error'), serverError);
                setIsPending(false);
                setIsEditMode(true);
            } else {
                showFlag('success', t('general.success'), t('site.saved'));
                setIsPending(false);
                if (isCreation) {
                    navigate(`${location.pathname.split('/new')[0]}/${siteIdAdded}`, {
                        state,
                        replace: true,
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- necessary
    }, [serverError, t, siteIdAdded, isCreation, site]);

    const computeButtonsOutOfProps = (props: FormikProps<ISiteFormData>): ButtonProps[] => {
        if (site?.isDefault === false || site?.isDefault === undefined) {
            if (hasRightToAddSites) {
                if (isEditMode) {
                    return [
                        {
                            text: t('general.cancel'),
                            'data-testid': 'cancelSite',
                            aspect: 'secondary',
                            onClick() {
                                if (isCreation) {
                                    navigate(-1);
                                } else {
                                    navigate(location.pathname.replace('?edit', ''), {
                                        state,
                                        replace: true,
                                    });
                                    setIsEditMode(false);
                                }
                            },
                        },
                        {
                            text: t('general.submit'),
                            'data-testid': 'submitSite',
                            aspect: 'primary',
                            onClick: props.handleSubmit,
                        },
                    ];
                } else {
                    return [
                        {
                            text: t('site.modifySite'),
                            'data-testid': 'modifySite',
                            aspect: 'primary',
                            onClick() {
                                navigate(`${location.pathname}?edit`, { state, replace: true });
                                setIsEditMode(true);
                            },
                        },
                    ];
                }
            }
        }
        return [];
    };

    if (siteId && siteId !== 'new' && !site) {
        return <Loader overlay />;
    }

    const signupSchema = Yup.object().shape({
        label: Yup.string()
            .max(255, String(t('errors.tooLong', { number: '255' })))
            .required(t('errors.required')),
        postalCode: Yup.string()
            .max(
                10,
                String(
                    t('errors.tooLong', {
                        number: '10',
                    }),
                ),
            )
            .required(t('errors.required')),
        address1: Yup.string()
            .max(
                512,
                String(
                    t('errors.tooLong', {
                        number: '512',
                    }),
                ),
            )
            .required(t('errors.required')),
        city: Yup.string()
            .max(50, String(t('errors.tooLong', { number: '50' })))
            .required(t('errors.required')),
        address2: Yup.string().max(512, String(t('errors.tooLong', { number: '512' }))),
        country: Yup.string()
            .max(50, String(t('errors.tooLong', { number: '50' })))
            .required(t('errors.required')),
    });

    let siteFormInitialValues: ISiteFormData = {
        organizationId: organizationId ?? '',
        label: '',
        postalCode: '',
        address1: '',
        city: '',
        address2: '',
        country: '',
        isDefault: false,
    };
    if (site) {
        siteFormInitialValues = site;
    }

    return (
        <Formik
            initialValues={siteFormInitialValues}
            validationSchema={signupSchema}
            onSubmit={(values: ISiteFormData, actions) => {
                void dispatch(isCreation ? createSite(values) : updateSite(values));
                actions.setSubmitting(false);
                setIsPending(true);
                setIsEditMode(false);
                navigate(location.pathname.replace('?edit', ''), { state, replace: true });
            }}
            enableReinitialize
        >
            {(props: FormikProps<ISiteFormData>) => (
                <MainLayout
                    header={
                        <Header
                            {...headerProps}
                            title={isCreation || !site ? t('site.addSite') : String(site.label)}
                            showNavigation={!isEditMode}
                            isDirty={Object.keys(props.touched).length > 0}
                            buttons={computeButtonsOutOfProps(props)}
                            showBackButton
                        />
                    }
                >
                    <Form>
                        <InformationCard title={t('site.billingAddress')}>
                            <PermissionAwareAddress
                                editable={isEditMode}
                                permissionsRequired={[permissionNeeded]}
                                addressLabelTranslationKey="site.billingAddress"
                                data={site}
                                colSizes={[5, 2, 3, 2]}
                                fieldsToUse={[
                                    { name: 'label', isOptional: false },
                                    { name: 'address1', isOptional: false },
                                    { name: 'address2', isOptional: true },
                                    { name: 'postalCode', isOptional: false },
                                    { name: 'city', isOptional: false },
                                    { name: 'country', isOptional: false },
                                ]}
                                hasTooltip
                            />
                        </InformationCard>
                        {!isCreation && (
                            <InformationCard
                                title={t('user.userList')}
                                badgeText={site?.users ? site.users.length : 0}
                                badgeTextColor={colors.blue.B400}
                                badgeBackground={colors.neutral.N75}
                                showBadge
                            >
                                {site?.users && site.users.length > 0 ? (
                                    site.users.map(
                                        (user: User) =>
                                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
                                            user.profile && (
                                                <UserContainer
                                                    key={`user${user.id}`}
                                                    smallScreen={4}
                                                    defaultScreen={4}
                                                >
                                                    <Avatar
                                                        size="large"
                                                        text={`${user.profile.firstName} ${user.profile.lastName}`}
                                                        isActive
                                                    />
                                                    <UserInfos data-testid="userInfos">
                                                        <Tooltip
                                                            content={`${user.profile.firstName} ${user.profile.lastName}`}
                                                            isOneLine
                                                        >
                                                            <UserFullname color={colors.blue.B400}>
                                                                {`${user.profile.firstName} ${user.profile.lastName}`}
                                                            </UserFullname>
                                                        </Tooltip>
                                                        <Tooltip content={user.email} isOneLine>
                                                            <UserMail color={colors.blue.B400}>
                                                                {user.email}
                                                            </UserMail>
                                                        </Tooltip>
                                                    </UserInfos>
                                                </UserContainer>
                                            ),
                                    )
                                ) : (
                                    <EmptyState
                                        imageName="User"
                                        titleTranslationKey={t('errors.noUserTitle')}
                                        textTranslationKey={t('errors.noUserText')}
                                    />
                                )}
                            </InformationCard>
                        )}
                    </Form>
                </MainLayout>
            )}
        </Formik>
    );
};

export default Site;
