import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import type { Budget } from '../slices/budgetSlice';
import type { BudgetVersionProps } from '../utils/getBudgetVersion';
import { getBudgetVersion } from '../utils/getBudgetVersion';
import type { BadgeProps } from './Badge';
import Badge from './Badge';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Label = styled.div`
    margin: 0;
    white-space: nowrap;
`;

const Version = styled.div`
    margin: 0;
    display: flex;
    white-space: nowrap;
`;
type Props = {
    budget: Budget | Partial<Budget>;
    isStatusLabelDisplayed?: boolean;
    labelStyle?: React.CSSProperties;
    fontWeight?:
        | number
        | '-moz-initial'
        | 'inherit'
        | 'initial'
        | 'revert'
        | 'unset'
        | 'bold'
        | 'normal'
        | 'bolder'
        | 'lighter'
        | undefined;
};

const BudgetVersionWrapper: React.FC<Props> = ({
    budget,
    labelStyle,
    fontWeight,
    isStatusLabelDisplayed = true,
}) => {
    const { t } = useTranslation();
    let color: string;
    let translationKey: string;

    switch (budget.status) {
        case 'draft':
            color = colors.yellow.Y400;
            translationKey = 'budget.draft';
            break;
        case 'cancelled':
            color = colors.red.R400;
            translationKey = 'budget.cancelled';
            break;
        case 'replaced':
            color = colors.teal.T400;
            translationKey = 'budget.replaced';
            break;
        case 'approved':
            color = colors.green.G400;
            translationKey = 'budget.approved';
            break;
        default:
            color = colors.red.R500;
            translationKey = 'errors.noStatus';
            break;
    }

    const budgetProps: BudgetVersionProps = {
        textColor: colors.other.white,
        background: color,
        textSize: '0.75rem',
        style: {
            minWidth: '1rem',
            minHeight: '1rem',
            marginRight: '0.5rem',
            borderRadius: '0.1875rem',
            lineHeight: '1.17rem',
            fontWeight,
        },
    };

    return (
        <Container>
            <Version>
                <Badge {...(getBudgetVersion(budget, budgetProps) as BadgeProps)} />
            </Version>
            {isStatusLabelDisplayed && <Label style={labelStyle}>{t(translationKey)}</Label>}
        </Container>
    );
};

export default BudgetVersionWrapper;
