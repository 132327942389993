import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError, PriceVariationSettings, SpendingRythm } from './common';
import { errorHandler } from './common';
import type { Budget } from './budgetSlice';
import type { Operation } from './operationSlice';

export type BudgetLine = {
    id: number;
    label: string;
    index: string;
    parentId: BudgetLine['id'] | undefined | null;
    budgetId: Budget['id'];
    amountHt: number;
    amountTtc: number;
    tvaCodeId: number;
    taxes: number;
    isCalculationOrigin: boolean;
    isCalculated: boolean;
    calculatedFactor: number | null;
    calculatedFrom: BudgetLine['id'] | null;
    budgetLineCalculationReference?: { index: BudgetLine['index'] };
    isParent: boolean;
    priceVariationSettings: PriceVariationSettings;
    spendingRythm: SpendingRythm;
    revisionsAmountHt: number;
    revisionsTaxes: number;
    revisionsAmountTtc: number;
    actualisationsAmountHt: number;
    actualisationsTaxes: number;
    actualisationsAmountTtc: number;
    variationsAmountHt: number;
    variationsTaxes: number;
    variationsAmountTtc: number;
    engagedAmountHt: number;
    engagedAmountTtc: number;
    startDate: string;
    directChildrenCount?: number;
    order: number;
    isSimulated: boolean;
    engagedActualisationsAmountHt: number;
    engagedActualisationsAmountTtc: number;
    engagedRevisionsAmountHt: number;
    engagedRevisionsAmountTtc: number;
    engagedVariationsAmountHt: number;
    engagedVariationsAmountTtc: number;
    comment: string;
};

export type BudgetLineForDropdown = {
    id: number;
    label: string;
    index: string;
    amountHt: number;
    calculatedFrom: number | null;
};

export interface IBudgetLineFormData {
    id?: BudgetLine['id'];
    label: BudgetLine['label'];
    index: BudgetLine['index'];
    parentId?: BudgetLine['parentId'] | undefined | null;
    budgetId: Budget['id'];
    amountHt: BudgetLine['amountHt'] | string | null;
    amountTtc: BudgetLine['amountTtc'];
    tvaCodeId: BudgetLine['tvaCodeId'];
    taxes: BudgetLine['taxes'];
    isCalculated: BudgetLine['isCalculated'];
    calculatedFactor?: BudgetLine['calculatedFactor'] | string;
    calculatedFrom?: BudgetLine['calculatedFrom'] | null;
    priceVariationSettings?: PriceVariationSettings;
    spendingRythm?: SpendingRythm;
    isSimulated?: BudgetLine['isSimulated'];
    actualisationsAmountHt?: BudgetLine['actualisationsAmountHt'];
    revisionsAmountHt?: BudgetLine['revisionsAmountHt'];
    comment?: BudgetLine['comment'];
}

export type ConvertedToGroupData = {
    childLine: BudgetLine;
    parentLine: BudgetLine;
};

export const getBudgetLines = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: BudgetLine[]; operationId: Operation['id']; budgetId: Budget['id'] },
    // First argument to the payload creator
    {
        operationId: Operation['id'];
        budgetId: Budget['id'];
        parentId?: BudgetLine['id'] | null;
        all?: boolean;
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>(
    'BUDGET_LINE/GETALL',
    async ({ operationId, budgetId, parentId, all }, { rejectWithValue, getState }) => {
        try {
            let params = '';
            if (all) {
                const allIds = selectBudgetLinesIds(getState());
                params = `?ids=${JSON.stringify(allIds)}`;
            } else if (parentId) {
                params = `?parentId=${parentId}`;
            }

            const response = await axios.get(
                `/operations/${operationId}/budgets/${budgetId}/lines${params}`,
            );
            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { data: response.data.data, operationId, budgetId };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.status === 400 || error.response?.status === 499) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: error.message,
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const getBudgetLinesForRefDropdown = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: BudgetLineForDropdown[] },
    // First argument to the payload creator
    { operationId: Operation['id']; budgetId?: Budget['id'] },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('BUDGET_LINE/GETALL_FORDROPDOWN', async ({ operationId, budgetId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `/operations/${operationId}/budgets/${budgetId}/lines/dropdown`,
        );

        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return { data: response.data.data };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.status === 400 || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getBudgetLine = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    BudgetLine,
    // First argument to the payload creator
    { operationId: Operation['id']; budgetId: Budget['id']; id: BudgetLine['id'] },
    {
        rejectValue: ServerError;
    }
>('BUDGET_LINE/GET', async ({ operationId, budgetId, id }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: BudgetLine }>(
            `/operations/${operationId}/budgets/${budgetId}/lines/${id}`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const createBudgetLine = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    BudgetLine,
    // First argument to the payload creator
    {
        budgetLine: IBudgetLineFormData;
        operationId: Operation['id'];
        budgetId: Budget['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>(
    'BUDGET_LINE/POST',
    async ({ budgetLine, operationId, budgetId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post<{ data?: BudgetLine }>(
                `/operations/${operationId}/budgets/${budgetId}/lines`,
                budgetLine,
            );

            if (!response.data.data) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return response.data.data;
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;

            if (error.response?.status === 400) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const updateBudgetLineFromMove = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    null,
    // First argument to the payload creator
    {
        budgetLine: Partial<IBudgetLineFormData>;
        operationId: Operation['id'];
        budgetId: Budget['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>(
    'BUDGET_LINE/PUT_FROM_MOVE',
    async ({ budgetLine, operationId, budgetId }, { rejectWithValue, dispatch }) => {
        try {
            await axios.put<{ data?: BudgetLine }>(
                `/operations/${operationId}/budgets/${budgetId}/lines/${budgetLine.id}/move`,
                budgetLine,
            );
            return null;
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.status === 400) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const updateBudgetLine = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    BudgetLine,
    // First argument to the payload creator
    {
        budgetLine: Partial<IBudgetLineFormData>;
        operationId: Operation['id'];
        budgetId: Budget['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>(
    'BUDGET_LINE/PUT',
    async ({ budgetLine, operationId, budgetId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put<{ data?: BudgetLine }>(
                `/operations/${operationId}/budgets/${budgetId}/lines/${budgetLine.id}`,
                budgetLine,
            );

            if (!response.data.data) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return response.data.data;
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.status === 400) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const convertToGroupBudgetLine = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: ConvertedToGroupData; previousParentId: Budget['id'] | undefined },
    // First argument to the payload creator
    {
        id: BudgetLine['id'];
        operationId: Operation['id'];
        budgetId: Budget['id'];
        previousParentId?: Budget['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>(
    'BUDGET_LINE_GROUP/POST',
    async ({ id, operationId, budgetId, previousParentId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post<{
                childLine: BudgetLine;
                parentLine: BudgetLine;
            }>(`/operations/${operationId}/budgets/${budgetId}/lines/group`, { id });

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always falsy
            if (!response.data?.childLine || !response.data?.parentLine) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { data: response.data, previousParentId };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;

            if (error.response?.status === 400) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);
export const deleteBudgetLine = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { id: BudgetLine['id']; operationId: Operation['id']; budgetId: Budget['id'] },
    // First argument to the payload creator
    { id: BudgetLine['id']; operationId: Operation['id']; budgetId: Budget['id'] },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('BUDGET_LINE/DELETE', async ({ id, operationId, budgetId }, { rejectWithValue, dispatch }) => {
    try {
        await axios.delete<{ data?: BudgetLine['id'] }>(
            `/operations/${operationId}/budgets/${budgetId}/lines/${id}`,
        );

        return { id, operationId, budgetId };
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

interface IBudgetLineState {
    budgetLinesById: Record<number, BudgetLine>;
    linesForDropdown: BudgetLineForDropdown[];
    budgetLineIdAdded: number | null;
    budgetLineIdDeleted: number | null;
    budgetLineConvertedToGroup: ConvertedToGroupData | null;
    loading: boolean;
    error: string | null;
    childrenIdByParentId: Record<number, Array<BudgetLine['id']>>;
    topBudgetLinesIds: Array<BudgetLine['id']>;
    selectedBudgetId: number | null;
}

export const initialState: IBudgetLineState = {
    budgetLinesById: {},
    linesForDropdown: [],
    childrenIdByParentId: {},
    topBudgetLinesIds: [],
    loading: false,
    error: null,
    budgetLineIdAdded: null,
    budgetLineIdDeleted: null,
    budgetLineConvertedToGroup: null,
    selectedBudgetId: null,
};

export const slice = createSlice({
    name: 'budgetLine',
    initialState,
    reducers: {
        resetBudgetLineAdded(state) {
            state.budgetLineIdAdded = null;
        },
        resetBudgetLineDeleted(state) {
            state.budgetLineIdDeleted = null;
        },
        resetBudgetLineConvertedToGroup(state) {
            state.budgetLineConvertedToGroup = null;
        },
    },
    extraReducers(builder) {
        // Get all
        builder.addCase(getBudgetLines.pending, (state, { meta }) => {
            if (meta.arg.budgetId !== state.selectedBudgetId) {
                state.selectedBudgetId = meta.arg.budgetId;
                state.budgetLinesById = {};
                state.linesForDropdown = [];
                state.topBudgetLinesIds = [];
            }
            state.loading = true;
            state.error = null;
            state.budgetLineIdDeleted = null;
            state.budgetLineIdAdded = null;
            state.budgetLineConvertedToGroup = null;
        });
        // if budgetLines.parentId !== null -> rangement dans le store des enfants else ce quil y a deja ici.
        builder.addCase(getBudgetLines.fulfilled, (state, { payload }) => {
            payload.data.forEach((item: BudgetLine) => {
                if (item.parentId) {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- If there is no array yet with this parent id
                    state.childrenIdByParentId[item.parentId] = !state.childrenIdByParentId[
                        item.parentId
                    ]
                        ? []
                        : state.childrenIdByParentId[item.parentId];
                    // Add child to parent if it is not already in the array
                    if (!state.childrenIdByParentId[item.parentId].includes(item.id)) {
                        state.childrenIdByParentId[item.parentId].push(item.id);
                    }
                } else {
                    if (!state.topBudgetLinesIds.includes(item.id)) {
                        state.topBudgetLinesIds.push(item.id);
                    }
                }
                state.budgetLinesById[item.id] = item;
            });
            state.selectedBudgetId = payload.budgetId;
            state.loading = false;
        });
        builder.addCase(getBudgetLines.rejected, errorHandler());
        // Get for dropdown
        builder.addCase(getBudgetLinesForRefDropdown.pending, (state, { payload }) => {
            state.loading = true;
            state.error = null;
            state.budgetLineIdDeleted = null;
            state.budgetLineIdAdded = null;
            state.budgetLineConvertedToGroup = null;
        });
        builder.addCase(getBudgetLinesForRefDropdown.fulfilled, (state, { payload }) => {
            state.linesForDropdown = payload.data;
            state.loading = false;
        });
        builder.addCase(getBudgetLinesForRefDropdown.rejected, errorHandler());
        // Get one
        builder.addCase(getBudgetLine.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.budgetLineIdDeleted = null;
            state.budgetLineIdAdded = null;
            state.budgetLineConvertedToGroup = null;
        });
        builder.addCase(getBudgetLine.fulfilled, (state, { payload }) => {
            state.budgetLinesById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(getBudgetLine.rejected, errorHandler());
        // Create
        builder.addCase(createBudgetLine.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.budgetLineIdDeleted = null;
            state.budgetLineIdAdded = null;
            state.budgetLineConvertedToGroup = null;
        });
        builder.addCase(createBudgetLine.fulfilled, (state, { payload }) => {
            state.budgetLineIdAdded = payload.id;
            state.budgetLinesById[payload.id] = payload;

            if (payload.parentId) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- If there is no array yet with this parent id
                state.childrenIdByParentId[payload.parentId] = !state.childrenIdByParentId[
                    payload.parentId
                ]
                    ? []
                    : state.childrenIdByParentId[payload.parentId];
                // Add child to parent if it is not already in the array
                if (!state.childrenIdByParentId[payload.parentId].includes(payload.id)) {
                    state.childrenIdByParentId[payload.parentId].push(payload.id);
                }
            } else {
                if (!state.topBudgetLinesIds.includes(payload.id)) {
                    state.topBudgetLinesIds.push(payload.id);
                }
            }

            state.error = null;
            state.loading = false;
        });
        builder.addCase(createBudgetLine.rejected, errorHandler());
        // Update
        builder.addCase(updateBudgetLine.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.budgetLineIdDeleted = null;
            state.budgetLineIdAdded = null;
            state.budgetLineConvertedToGroup = null;
        });
        builder.addCase(updateBudgetLine.fulfilled, (state, { payload }) => {
            const previousValue = state.budgetLinesById[payload.id];

            state.budgetLinesById[payload.id] = payload;
            state.loading = false;

            // Parent changed
            if (payload.parentId !== previousValue.parentId) {
                if (payload.parentId) {
                    // Line moved from root into Parent
                    state.childrenIdByParentId[payload.parentId].push(payload.id);
                }
                if (previousValue.parentId) {
                    // We need to remove from previous parent
                    state.childrenIdByParentId[previousValue.parentId] = state.childrenIdByParentId[
                        previousValue.parentId
                    ].filter((id) => id !== payload.id);
                }
                if (!previousValue.parentId) {
                    // Was in the root, we need to remove it from topLines
                    if (state.topBudgetLinesIds.includes(payload.id)) {
                        state.topBudgetLinesIds = state.topBudgetLinesIds.filter(
                            (id) => id !== payload.id,
                        );
                    }
                }
                if (!payload.parentId) {
                    // We need to add it to top lines
                    if (!state.topBudgetLinesIds.includes(payload.id)) {
                        state.topBudgetLinesIds.push(payload.id);
                    }
                }
            }
        });
        builder.addCase(updateBudgetLine.rejected, errorHandler());
        // Update
        builder.addCase(updateBudgetLineFromMove.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateBudgetLineFromMove.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.childrenIdByParentId = {};
            state.topBudgetLinesIds = [];
        });
        builder.addCase(updateBudgetLineFromMove.rejected, errorHandler());
        // Delete
        builder.addCase(deleteBudgetLine.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.budgetLineIdDeleted = null;
            state.budgetLineIdAdded = null;
            state.budgetLineConvertedToGroup = null;
        });
        builder.addCase(deleteBudgetLine.fulfilled, (state, { payload }) => {
            const budgetLine = state.budgetLinesById[payload.id];
            if (budgetLine.isParent) {
                deleteChildrenLines(budgetLine.id, state);
            }
            if (budgetLine.parentId) {
                deleteParentLines(budgetLine.parentId, payload.id, state);
            } else {
                if (state.topBudgetLinesIds.includes(payload.id)) {
                    state.topBudgetLinesIds.splice(state.topBudgetLinesIds.indexOf(payload.id), 1);
                }
            }
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.budgetLinesById[payload.id];

            state.budgetLineIdAdded = null;
            state.budgetLineIdDeleted = payload.id;
            state.loading = false;
        });
        builder.addCase(
            deleteBudgetLine.rejected,
            errorHandler((state, { meta }) => {
                state.budgetLineIdDeleted = meta.arg.id;
            }),
        );
        // Converted to group
        builder.addCase(convertToGroupBudgetLine.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.budgetLineIdDeleted = null;
            state.budgetLineIdAdded = null;
            state.budgetLineConvertedToGroup = null;
        });
        builder.addCase(convertToGroupBudgetLine.fulfilled, (state, { payload }) => {
            state.budgetLineConvertedToGroup = payload.data;
            // Add new line to state
            state.budgetLinesById[payload.data.parentLine.id] = payload.data.parentLine;
            // Filter previous parent group line and add new parent line to previous parent
            if (payload.previousParentId && payload.previousParentId > 0) {
                state.childrenIdByParentId[payload.previousParentId] = state.childrenIdByParentId[
                    payload.previousParentId
                ].filter((childId: number) => childId !== payload.data.childLine.id);
                state.childrenIdByParentId[payload.previousParentId].push(
                    payload.data.parentLine.id,
                );
            } else {
                state.topBudgetLinesIds = state.topBudgetLinesIds.filter(
                    (childId: number) => childId !== payload.data.childLine.id,
                );
                state.topBudgetLinesIds.push(payload.data.parentLine.id);
            }
            if (state.topBudgetLinesIds.includes(payload.data.childLine.id)) {
                state.topBudgetLinesIds.splice(
                    state.topBudgetLinesIds.indexOf(payload.data.childLine.id),
                    1,
                );
            }
            state.loading = false;
            state.error = null;
        });
        builder.addCase(
            convertToGroupBudgetLine.rejected,
            errorHandler((state, { meta }) => {
                state.budgetLineConvertedToGroup = meta.arg.id;
            }),
        );
    },
});

const deleteChildrenLines = (sourceId: number, state: IBudgetLineState) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
    delete state.budgetLinesById[sourceId];
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
    delete state.childrenIdByParentId[sourceId];

    Object.values(state.budgetLinesById).forEach(({ id, parentId }) => {
        if (parentId === sourceId) {
            deleteChildrenLines(id, state);
        }
    });
};

const deleteParentLines = (parentId: number, lineId: number, state: IBudgetLineState) => {
    if (
        state.childrenIdByParentId[parentId].length === 1 &&
        state.childrenIdByParentId[parentId].includes(lineId)
    ) {
        state.childrenIdByParentId[parentId].splice(
            state.childrenIdByParentId[parentId].indexOf(lineId),
            1,
        );
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
        delete state.childrenIdByParentId[parentId];
        const parentLine = state.budgetLinesById[parentId];
        if (parentLine.parentId) {
            deleteParentLines(parentLine.parentId, parentId, state);
        }
        if (state.topBudgetLinesIds.includes(parentId)) {
            state.topBudgetLinesIds.splice(state.topBudgetLinesIds.indexOf(parentId), 1);
        }
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
        delete state.budgetLinesById[parentId];
    } else {
        state.childrenIdByParentId[parentId].splice(
            state.childrenIdByParentId[parentId].indexOf(lineId),
            1,
        );
    }
};

const sortLinesByIndex = (lines: BudgetLine[]) =>
    lines.sort((a, b) => a.index.localeCompare(b.index, undefined, { numeric: true }));

export const selectError = (state: RootState) => state.budgetLine.error;
export const selectBudgetLineIdAdded = (state: RootState) => state.budgetLine.budgetLineIdAdded;
export const selectBudgetLineIdDeleted = (state: RootState) => state.budgetLine.budgetLineIdDeleted;
export const selectBudgetLinesForDropdown = (state: RootState) => state.budgetLine.linesForDropdown;
export const selectTopBudgetLines = (state: RootState) =>
    sortLinesByIndex(
        Object.values(state.budgetLine.topBudgetLinesIds).map(
            (id) => state.budgetLine.budgetLinesById[id],
        ),
    );

export const selectBudgetLines = (state: RootState) =>
    Object.values(state.budgetLine.budgetLinesById);
export const selectBudgetLine = (id: BudgetLine['id'] | undefined | null) =>
    function (state: RootState) {
        if (id) {
            return state.budgetLine.budgetLinesById[id];
        }
        return null;
    };
export const selectIsLoading = (state: RootState) => state.budgetLine.loading;
export const selectChildrenIdByParentId =
    (parentId: number | undefined | null) =>
    (state: RootState): Array<BudgetLine['id']> => {
        if (
            parentId !== null &&
            parentId !== undefined &&
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
            state.budgetLine.childrenIdByParentId[parentId]
        ) {
            const childrenIds = state.budgetLine.childrenIdByParentId[parentId];
            const childrenBudgetLines = childrenIds.reduce((result: BudgetLine[], id: number) => {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
                if (state.budgetLine.budgetLinesById[id] !== undefined) {
                    result.push(state.budgetLine.budgetLinesById[id]);
                }
                return result;
            }, []);
            return sortLinesByIndex(childrenBudgetLines).map((line: BudgetLine) => line.id);
        }
        return [];
    };
export const selectBudgetLinesIds = (state: RootState) =>
    Object.keys(state.budgetLine.budgetLinesById).map((id) => Number(id));
// This is used is to calculate the wbs index of a new line
export const selectLastBudgetLine = (state: RootState): BudgetLine | undefined => {
    // We use selectTopBudgetLines to have them ordered by `index`
    const lines = selectTopBudgetLines(state);

    if (lines.length > 0) {
        return lines[lines.length - 1];
    } else {
        return undefined;
    }
};

// Actions added into the `reducers` part
export const { resetBudgetLineAdded, resetBudgetLineDeleted, resetBudgetLineConvertedToGroup } =
    slice.actions;

export default slice.reducer;

export const selectBudgetLineConvertedToGroup = (state: RootState) =>
    state.budgetLine.budgetLineConvertedToGroup;
