import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectUser, logout } from '../slices/authSlice';
import logoutImage from '../assets/images/logout.svg';
import { colors } from '../constants/colors';
import Text from './Text';
import { useAppDispatch } from '../store';

const Container = styled.section`
    padding: 1.25rem;
    display: flex;
    margin-top: auto;
    cursor: pointer;
    align-items: center;
    background-color: ${colors.blue.B400};
    width: inherit;
    justify-content: center;
    z-index: 1;
`;

const EmailContainer = styled(Text)`
    margin-bottom: 0;
    color: ${colors.other.white};
    text-overflow: ellipsis;
    height: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
`;

const LogoutImg = styled.img`
    width: 1.25em;
    height: auto;
    margin-left: 0.6875rem;
`;

const UserMenu: React.FC = () => {
    const user = useSelector(selectUser);
    const dispatch = useAppDispatch();

    return (
        <Container
            data-testid="logout"
            onClick={() => {
                void dispatch(logout());
            }}
            role="button"
            tabIndex={0}
        >
            <EmailContainer>{user ? user.email : 'Inconnu'}</EmailContainer>
            <LogoutImg src={logoutImage} />
        </Container>
    );
};

export default UserMenu;
