/* eslint-disable camelcase -- gantt plugin use data without camel case*/
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- needed */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Just to test
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import type { GanttStatic } from 'dhtmlx-gantt';
import 'dhtmlx-gantt';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    format,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    addDays,
    differenceInDays,
    differenceInWeeks,
    differenceInMonths,
    addMonths,
} from 'date-fns';

// Style
import { Json } from 'styled-icons/simple-icons';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import Add from '../assets/images/planning_add.svg';
import Remove from '../assets/images/planning_remove.svg';

// Components
import { showFlag } from './Flag';

// Hook
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';

// Data
import {
    updatePlanningDates,
    selectError,
    createPlanningLink,
    deletePlanningLink,
    selectWarningLines,
    selectMinDate,
    selectMaxDate,
} from '../slices/planningSlice';
import { useAppDispatch } from '../store';

type ContainerProps = {
    planningType: 'budget' | 'markets';
};

const GanttContainer = styled.div<ContainerProps>`
    width: 100%;
    height: 95%;
    .gantt_layout_cell_border_bottom {
        border-bottom: 0.0938rem solid ${colors.neutral.N75};
        border-bottom-left-radius: ${styles.borderRadiusSmall};
        border-bottom-right-radius: ${styles.borderRadiusSmall};
    }
    .gantt_layout_cell_border_right {
        border-right: 0.0938rem solid ${colors.neutral.N75};
    }
    .gantt_layout_cell_border_top {
        border-top: 0.0938rem solid ${colors.neutral.N75};
        border-top-left-radius: ${styles.borderRadiusSmall};
        border-top-right-radius: ${styles.borderRadiusSmall};
    }
    .gantt_layout_cell_border_left {
        border-left: 0.0938rem solid ${colors.neutral.N75};
    }
    .gantt_grid {
        background: ${colors.neutral.N50};
        .gantt_folder_open,
        .gantt_folder_closed,
        .gantt_file {
            display: none;
        }
        .gantt_tree_icon img {
            width: 1rem;
        }
        .gantt_grid_scale {
            border-bottom: 0.0625rem solid ${colors.neutral.N50};

            .gantt_grid_head_cell {
                color: ${colors.neutral.N300};
                font-family: Rubik;
                font-style: normal;
                font-weight: 500;
                font-size: 0.75rem;
                text-transform: uppercase;
            }
        }
        .gantt_grid_data .gantt_cell {
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 0.75rem;
            color: ${colors.blue.B400};
            span {
                margin: 0 0.5rem;
                color: ${colors.green.G200};
                b {
                    font-weight: 500;
                }
            }
        }
    }
    .gantt_task .gantt_task_scale .gantt_scale_cell {
        font-family: Rubik;
        font-size: 0.75rem;
        color: ${colors.blue.B400};
        border-right: 0.0938rem solid ${colors.neutral.N75};
    }
    .gantt_row.odd,
    .gantt_task_row.odd,
    .gantt_row,
    .gantt_task_row,
    .gantt_row.odd:hover,
    .gantt_task_row.odd:hover,
    .gantt_row:hover,
    .gantt_task_row:hover {
        background: ${colors.other.white};
        box-shadow: 0 0.0625rem 0.25rem ${colors.neutral.N75};
        border-radius: ${styles.borderRadiusSmall};
        color: ${colors.blue.B400};
        margin: 0.25rem 0;
    }
    .gantt_grid_data .gantt_row.gantt_selected,
    .gantt_grid_data .gantt_row.odd.gantt_selected,
    .gantt_task_row.gantt_selected {
        background: ${colors.other.white};
    }
    .gantt_layout_content {
        background: ${colors.neutral.N50};
    }
    .gantt_task_line {
        background-color: ${({ planningType }) =>
            planningType === 'budget' ? colors.neutral.N375 : colors.green.G400};
        border-color: ${({ planningType }) =>
            planningType === 'budget' ? colors.neutral.N375 : colors.green.G400};
        .gantt_task_content {
            font-family: Rubik;
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            color: ${colors.other.white};
        }
    }
    .gantt_task_line.parent_task {
        background-color: ${({ planningType }) =>
            planningType === 'budget' ? colors.blue.B400 : colors.green.G600};
        border-color: ${({ planningType }) =>
            planningType === 'budget' ? colors.blue.B400 : colors.green.G600};
    }
    .gantt_scale_line {
        border-top: none;
        border-bottom: 0.0938rem solid ${colors.neutral.N75};
    }
    .gantt_task_row.gantt_selected .gantt_task_cell,
    .gantt_task_cell {
        border-right: 0.0938rem solid ${colors.neutral.N75};
    }
    .gantt_grid_scale,
    .gantt_task_scale {
        border-bottom: 0.0625rem solid ${colors.neutral.N50};
    }
    .gantt_task_line.gantt_selected {
        box-shadow: 0 0 0.5rem
            ${({ planningType }) =>
                planningType === 'budget' ? colors.blue.B100 : colors.teal.T200};
    }
    .gantt_tree_content {
        .italic {
            font-style: italic !important;
        }
        .not_italic {
            font-style: normal !important;
        }
    }
`;

// API https://docs.dhtmlx.com/gantt/api__refs__gantt.html
// Types: https://github.com/DefinitelyTyped/DefinitelyTyped/tree/87f2846309a0a2c0c1f5e85d8e53c465811cfef7/types/dhtmlxgantt
// but not gantt.i18n dans les types, https://docs.dhtmlx.com/gantt/api__gantt_i18n_other.html
// declare let gantt: GanttStatic;
declare let gantt: GanttStatic;

type Props = {
    data: unknown;
    planningType: 'budget' | 'markets';
    zoom: 'month' | 'week' | 'year';
    isReadOnly: boolean;
};

const Gantt: React.FC<Props> = ({ data, planningType, zoom, isReadOnly }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { operationId, budgetId }: { operationId?: string; budgetId?: string } = useParams();
    const hasRightToEditBudgets = usePermissionsCheck([{ code: 'BUDGETS_EDIT', operationId }]);
    const hasRightToEditMarketsPlanning = usePermissionsCheck([
        { code: 'MARKETS_PLANNING_EDIT', operationId },
    ]);
    const hasRightToEditPlanning =
        (planningType === 'budget' && hasRightToEditBudgets) ||
        (planningType === 'markets' && hasRightToEditMarketsPlanning);
    const error = useSelector(selectError);
    const warningLines = useSelector(selectWarningLines);
    const minDate = useSelector(selectMinDate);
    const maxDate = useSelector(selectMaxDate);

    const [isStartChange, setIsStartChange] = useState<boolean>(false);
    const [isEndChange, setIsEndChange] = useState<boolean>(false);
    const [openedTasks, setOpenedTasks] = useState<string[]>([]);
    const [scrollState, setScrollState] = useState<{ x: number | null; y: number | null }>({
        x: null,
        y: null,
    });

    const warningLinesRef = useRef(warningLines);

    useEffect(() => {
        if (warningLinesRef.current.length > 0) {
            showFlag(
                'warning',
                t('general.warning'),
                t('planning.m0monthsStartDatesInconsistent', {
                    list: warningLinesRef.current.join(', '),
                }),
            );
        }
    }, [t, warningLinesRef]);

    useEffect(() => {
        const getLabelDisplay = (task: unknown) => {
            let label = task.label;
            if (planningType === 'markets') {
                label = '';
                if (task.external_number) {
                    label += String(task.external_number);
                }
                if (task.slice_code) {
                    label += `-${task.slice_code}`;
                }
                label += ` ${task.label}`;
            }
            return label;
        };
        gantt.plugins({
            tooltip: true,
            quick_info: true,
        });
        gantt.i18n.setLocale('fr');
        gantt.config.autoscroll = true;
        gantt.config.scroll_size = 10;
        gantt.config.date_format = '%Y-%m-%d'; //  https://docs.dhtmlx.com/gantt/api__gantt_date_format_config.html / https://docs.dhtmlx.com/gantt/desktop__date_format.html
        gantt.config.columns = [
            {
                name: 'label',
                label: t('planning.label'),
                align: 'left',
                tree: true,
                width: '*',
                template(task: unknown) {
                    let text;
                    if (task.parent) {
                        text = `${
                            task.index ? `<span>${task.index}</span>` : ''
                        }&thinsp; ${getLabelDisplay(task)}`;
                    } else {
                        text = `${
                            task.index ? `<span><b>${task.index}</span></b>` : ''
                        }&thinsp; ${getLabelDisplay(task)}`;
                    }
                    return text;
                },
            },
            {
                name: 'start_date',
                label: t('planning.startDate'),
                align: 'center',
                template(task: unknown) {
                    let text;
                    const formatFunc = gantt.date.date_to_str('%d-%m-%Y');
                    if (task.$target.length > 0) {
                        text = `<p class='italic'>${formatFunc(task.start_date)}<p/>`;
                    } else {
                        text = `<p class='not_italic'>${formatFunc(task.start_date)}<p/>`;
                    }
                    return text;
                },
            },
            {
                name: 'durationInWeeks',
                label: t('planning.duration'),
                align: 'center',
                template(task: unknown) {
                    let text = `<p class='not_italic'>${differenceInWeeks(
                        task.end_date,
                        task.start_date,
                    )}${t('planning.durationTimeUnit')}<p/>`;
                    if (task.$target.length > 0) {
                        let hasStartConstraint = false;
                        let hasEndConstraint = false;
                        task.$target.forEach((id: number) => {
                            const link = gantt.getLink(id);
                            if (link.type === 0 || link.type === 1) {
                                hasStartConstraint = true;
                            } else if (link.type === 2 || link.type === 3) {
                                hasEndConstraint = true;
                            }
                        });
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
                        if (hasStartConstraint && hasEndConstraint) {
                            text = `<p class='italic'>${differenceInWeeks(
                                task.end_date,
                                task.start_date,
                            )}<p/>`;
                        }
                    }
                    return text;
                },
            },
        ];
        gantt.config.date_grid = '%d-%m-%Y';
        gantt.config.duration_unit = 'week';
        gantt.config.scales = [
            { unit: 'year', step: 1, format: '%Y' },
            { unit: 'month', step: 1, format: '%M' },
        ];

        gantt.config.scale_height = 60;
        gantt.config.row_height = 35;
        gantt.config.task_height = 27;
        gantt.config.drag_progress = false;
        gantt.config.details_on_dblclick = true; // enable to open popup for updating informations
        gantt.config.round_dnd_dates = false;
        gantt.config.show_quick_info = false;
        gantt.config.quickinfo_buttons = [];
        gantt.config.show_tasks_outside_timescale = true;
        gantt.config.min_column_width = 30; // to set the width cell
        gantt.config.buttons_left = [];
        gantt.config.buttons_right = ['gantt_save_btn', 'gantt_cancel_btn'];
        gantt.locale.labels.section_dates = 'Dates';

        // give good task class
        gantt.templates.task_class = (start: unknown, end: unknown, task: unknown) => {
            if (task.is_parent || String(task.id).split('-')[0] === 'market') {
                return 'parent_task';
            }
            return null;
        };

        // display good icon for open child rows
        gantt.templates.grid_open = (item: unknown) =>
            `<div class='gantt_tree_icon gantt_${item.$open ? 'close' : 'open'}'><img src=${
                item.$open ? Remove : Add
            } /></div>`;
        gantt.templates.task_text = (start: unknown, end: unknown, task: unknown) =>
            getLabelDisplay(task);

        // diplay tooltip with good format
        gantt.templates.tooltip_date_format = (date: unknown) => {
            const formatFunc = gantt.date.date_to_str('%d-%m-%Y');
            return formatFunc(date);
        };
        gantt.templates.tooltip_text = (start: Date, end: Date, task: unknown) =>
            t('planning.tooltip', {
                label: getLabelDisplay(task),
                startDate: gantt.templates.tooltip_date_format(start),
                endDate: gantt.templates.tooltip_date_format(end),
                durationInWeeks: differenceInWeeks(end, start),
            });

        // display quick_info with good format (when we select a row, can add delete button for example)
        gantt.templates.quick_info_content = (start: Date, end: Date, task: unknown) =>
            `${t('planning.duration')}: ${differenceInWeeks(end, start)} ${t('planning.weeks')}`;
        gantt.templates.quick_info_title = (start: unknown, end: unknown, task: unknown) =>
            getLabelDisplay(task);
        gantt.templates.quick_info_date = (start: unknown, end: unknown, task: unknown) => {
            const formatFunc = gantt.date.date_to_str('%d-%m-%Y');
            return `${formatFunc(start)} / ${formatFunc(end)}`;
        };

        // links part
        // display info message when creating a link
        gantt.templates.drag_link = (
            from: unknown,
            from_start: unknown,
            to: unknown,
            to_start: unknown,
        ) => {
            from = gantt.getTask(from);
            let text = t('planning.linkTooltipFrom', {
                from: getLabelDisplay(from),
                fromSide: from_start ? t('planning.start') : t('planning.end'),
            });
            if (to) {
                to = gantt.getTask(to);
                text += t('planning.linkTooltipTo', {
                    to: getLabelDisplay(to),
                    toSide: to_start ? t('planning.start') : t('planning.end'),
                });
            }
            return text;
        };
        gantt.templates.link_class = ({ isAutomatic }: { isAutomatic: boolean }) => '';
        // display confirm / cancel message after double-click on a link to delete it
        gantt.templates.link_description = (link: unknown) => {
            const from = gantt.getTask(link.source);
            const to = gantt.getTask(link.target);
            const fromIsStart = link.type === 1;
            const toIsStart = link.type === 1 || link.type === 0;

            return t('planning.deleteLinkModal', {
                from: getLabelDisplay(from),
                fromSide: fromIsStart ? t('planning.start') : t('planning.end'),
                to: getLabelDisplay(to),
                toSide: toIsStart ? t('planning.start') : t('planning.end'),
            });
        };
        gantt.init('gantt');
    }, [t, planningType]);

    const getGanttDates = (ganttDatesZoom: Props['zoom']) => {
        const totalTasksDurationInMonths = differenceInMonths(new Date(maxDate), new Date(minDate));
        let minGanttDuration;
        switch (ganttDatesZoom) {
            case 'week':
                // min 6 months
                minGanttDuration = 6;
                break;
            case 'month':
                // min 24 months
                minGanttDuration = 24;
                break;
            case 'year':
                // min 210 months
                minGanttDuration = 210;
                break;
            default:
                minGanttDuration = 24;
                break;
        }
        let gantStartDate;
        let gantEndDate;
        let fitTask = false;
        if (totalTasksDurationInMonths < minGanttDuration) {
            const resizeCursorSafeZone = 1;
            const monthsToAdd =
                (minGanttDuration - totalTasksDurationInMonths) / 2 + resizeCursorSafeZone;
            gantStartDate = addMonths(new Date(minDate), -monthsToAdd);
            gantEndDate = addMonths(new Date(maxDate), monthsToAdd);
        } else {
            fitTask = true;
        }
        return {
            fitTask,
            gantStartDate,
            gantEndDate,
        };
    };

    useEffect(() => {
        gantt.config.lightbox.sections = [
            {
                name: 'dates',
                height: 50,
                map_to: 'auto',
                type: 'time',
                time_format: ['%d', '%m', '%Y'],
                year_range: [
                    addMonths(new Date(minDate), -120).getFullYear(),
                    addMonths(new Date(maxDate), 120).getFullYear(),
                ],
            },
        ];
        const gantDates = getGanttDates(zoom);
        if (gantDates.fitTask) {
            gantt.config.fit_tasks = true;
            gantt.config.start_date = undefined;
            gantt.config.end_date = undefined;
        } else {
            gantt.config.start_date = gantDates.gantStartDate;
            gantt.config.end_date = gantDates.gantEndDate;
        }
        gantt.render();
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [minDate, maxDate, zoom]);

    useEffect(() => {
        const formattedData = formatDataDate(data.data);
        gantt.clearAll();
        gantt.parse({ ...data, data: formattedData });
        openedTasks.forEach((task) => {
            gantt.open(task);
        });
        gantt.config.fit_tasks = true;
        gantt.config.readonly = isReadOnly;
        gantt.scrollTo(scrollState.x, scrollState.y);
        if (error) {
            showFlag('error', t('errors.error'), t(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [data, error, isReadOnly]);

    const formatDataDate = (dataToFormat) =>
        dataToFormat.map((planningItem) => ({
            ...planningItem,
            start_date: new Date(planningItem.start_date).toISOString(),
        }));

    const handleChangeDate = (task: unknown, areSpecificDates: boolean) => {
        let startDate = new Date(task.start_date);
        let endDate = new Date(task.end_date);
        const durationInDays = differenceInDays(new Date(task.end_date), new Date(task.start_date));
        if (zoom === 'month' && !areSpecificDates) {
            if (isStartChange && isEndChange) {
                startDate = startOfWeek(startDate, { weekStartsOn: 1 });
                endDate = addDays(startDate, durationInDays);
            } else if (isStartChange) {
                startDate = startOfWeek(startDate, { weekStartsOn: 1 });
            } else if (isEndChange) {
                endDate = endOfWeek(endDate, { weekStartsOn: 6 });
            }
        } else if (zoom === 'year' && !areSpecificDates) {
            if (isStartChange && isEndChange) {
                startDate = startOfMonth(startDate);
                endDate = addDays(startDate, durationInDays);
            } else if (isStartChange) {
                startDate = startOfMonth(startDate);
            } else if (isEndChange) {
                endDate = endOfMonth(endDate);
            }
        }

        const newLine: unknown = {
            id: task.id,
            label: task.label,
            parent: task.parent,
            start_date: format(startDate, 'yyyy-MM-dd'),
            end_date: format(endDate, 'yyyy-MM-dd'),
        };
        if (planningType === 'budget') {
            newLine.index = task.index;
            newLine.budget_id = task.budget_id;
        } else {
            newLine.internal_number = task.internal_number;
            newLine.market_id = task.market_id;
        }

        if (planningType === 'budget') {
            void dispatch(
                updatePlanningDates({ budgetLine: newLine, operationId: Number(operationId) }),
            );
        } else {
            void dispatch(
                updatePlanningDates({ engagementLine: newLine, operationId: Number(operationId) }),
            );
        }
    };

    useEffect(() => {
        const event = gantt.attachEvent('onAfterTaskDrag', (id: unknown) => {
            const scroll = gantt.getScrollState();
            setScrollState({ x: scroll.x, y: scroll.y });
            handleChangeDate(gantt.getTask(id), false);
            gantt.render();
            return true;
        });
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    useEffect(() => {
        const event = gantt.attachEvent('onLightboxSave', (id: unknown, task: unknown) => {
            handleChangeDate(task, true);
            gantt.render();
            return true;
        });
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    // used to set start and end dates of the gantt according to tasks
    useEffect(() => {
        const event = gantt.attachEvent(
            'onTaskDrag',
            (id: unknown, mode: unknown, task: unknown, original: unknown) => {
                setIsStartChange(task.start_date !== original.start_date);
                setIsEndChange(task.end_date !== original.end_date);

                // pass new dates to getGanttDates() and adapt according those new dates
                const gantDates = getGanttDates(zoom);
                if (gantDates.fitTask) {
                    gantt.config.fit_tasks = true;
                    gantt.config.start_date = undefined;
                    gantt.config.end_date = undefined;
                } else {
                    gantt.config.start_date = gantDates.gantStartDate;
                    gantt.config.end_date = gantDates.gantEndDate;
                }
                gantt.render();
                return true;
            },
        );
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    useEffect(() => {
        const event = gantt.attachEvent('onBeforeTaskDrag', (id: unknown, task: unknown) => {
            if (gantt.getTask(id).is_parent || id.split('-')[0] === 'market') {
                showFlag('error', t('errors.error'), t('errors.impossibleOperation'));
                return false;
            }
            return true;
        });
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    useEffect(() => {
        const event = gantt.attachEvent('onBeforeLightbox', (id: unknown) => {
            gantt.resetLightbox();
            if (id.split('-')[0] === 'market' || gantt.getTask(id).is_parent) {
                showFlag('error', t('errors.error'), t('errors.impossibleOperation'));
                return false;
            }
            return true;
        });
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    useEffect(() => {
        const event = gantt.attachEvent('onTaskOpened', (id: unknown) => {
            setOpenedTasks([...openedTasks, id]);
            return true;
        });
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    useEffect(() => {
        const event = gantt.attachEvent('onTaskClosed', (id: unknown) => {
            const newList = openedTasks.filter((taskId) => taskId !== id);
            setOpenedTasks(newList);
            return true;
        });
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    useEffect(() => {
        const event = gantt.attachEvent('onLinkValidation', ({ source, target, type }: unknown) => {
            const sourceIsMarketLine = String(source).split('-')[0] === 'market';
            const targetIsMarketLine = String(target).split('-')[0] === 'market';
            if (type === '3' || sourceIsMarketLine || targetIsMarketLine) {
                // start_to_finish link is not supported by previz
                // link with a maket is unauthorized
                return false;
            }
            return true;
        });
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    useEffect(() => {
        const event = gantt.attachEvent(
            'onBeforeLinkDelete',
            (id: number, { isAutomatic }: unknown) => {
                const scroll = gantt.getScrollState();
                setScrollState({ x: scroll.x, y: scroll.y });
                if (isAutomatic) {
                    showFlag('error', t('errors.error'), t('errors.impossibleOperation'));
                    return false;
                } else {
                    if (planningType === 'budget') {
                        void dispatch(
                            deletePlanningLink({
                                linkId: id,
                                budgetId: Number(budgetId),
                                operationId: Number(operationId),
                            }),
                        );
                    } else {
                        void dispatch(
                            deletePlanningLink({
                                linkId: id,
                                operationId: Number(operationId),
                            }),
                        );
                    }
                }
                return true;
            },
        );
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    useEffect(() => {
        // onLinkValidation
        // { "finish_to_start":"0", "start_to_start":"1", "finish_to_finish":"2", "start_to_finish":"3" }
        // if type === "3" passe pas la validation
        const event = gantt.attachEvent(
            'onAfterLinkAdd',
            (linkId: unknown, { source, target, type }: unknown) => {
                const scroll = gantt.getScrollState();
                setScrollState({ x: scroll.x, y: scroll.y });
                const link = {
                    source: Number(source),
                    target: Number(target),
                    type: Number(type),
                };
                if (planningType === 'budget') {
                    void dispatch(
                        createPlanningLink({
                            link,
                            budgetId: Number(budgetId),
                            operationId: Number(operationId),
                        }),
                    );
                } else {
                    void dispatch(
                        createPlanningLink({
                            link,
                            operationId: Number(operationId),
                        }),
                    );
                }
                return true;
            },
        );
        return () => {
            if (event) {
                gantt.detachEvent(event);
            }
        };
    });

    useEffect(() => {
        if (hasRightToEditPlanning) {
            gantt.detachEvent('update-unauthorized');
        } else {
            gantt.attachEvent(
                'onBeforeTaskDrag',
                () => {
                    showFlag('error', t('errors.error'), t('errors.cantUpdateThisPlanning'));
                    return false;
                },
                { id: 'update-unauthorized' },
            );
        }
    }, [dispatch, hasRightToEditPlanning, operationId, t, planningType]);

    useEffect(() => {
        switch (zoom) {
            case 'week':
                gantt.config.scales = [
                    { unit: 'month', step: 1, format: '%F - %Y' },
                    { unit: 'week', step: 1, format: '%W' },
                ];
                gantt.config.min_column_width = 30; // to set the width cell
                gantt.config.scale_height = 60;
                break;
            case 'month':
                gantt.config.scales = [
                    { unit: 'year', step: 1, format: '%Y' },
                    { unit: 'month', step: 1, format: '%M' },
                ];
                gantt.config.min_column_width = 30; // to set the width cell
                gantt.config.scale_height = 60;
                break;
            case 'year':
                gantt.config.scales = [{ unit: 'year', step: 1, format: '%Y' }];
                gantt.config.min_column_width = 40; // to set the width cell
                gantt.config.scale_height = 35;
                break;
            default:
                break;
        }
        gantt.render();
    }, [zoom]);

    // clean tooltips on unmount
    useEffect(
        () => () => {
            const tooltips = document.getElementsByClassName(
                'gantt_tooltip',
            ) as unknown as HTMLElement[];
            // tooltips.forEach((tooltip: HTMLElement) => (tooltip.style.display = 'none'));
        },
        [],
    );

    return <GanttContainer planningType={planningType} id="gantt" />;
};

export default Gantt;
