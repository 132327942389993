import { useTranslation } from 'react-i18next';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { PASSWORD_REGEXP } from '../../utils/validation';
import FormikTextField from '../../components/TextFieldWithFormik';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import ErrorMessage from '../../components/ErrorMessage';
import Loader from '../../components/Loader';
import Text from '../../components/Text';
import { colors } from '../../constants/colors';
import Logo from '../../components/Logo';

import {
    selectUser,
    selectServerError,
    selectBlockedAt,
    login,
    selectLoading,
} from '../../slices/authSlice';
import { useAppDispatch } from '../../store';

const Container = styled.section`
    display: flex;
    height: 100%;
`;

const LeftSide = styled.div`
    flex: 2 2 40%;
    background-color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const FormContainer = styled.div`
    width: 60%;
    text-align: center;
    margin: auto;
`;

interface IImageProp {
    imageSrc: string;
}

const Image = styled.div<IImageProp>`
    flex: 3 3 60%;
    background-image: url('
        ${(props) => (props.imageSrc ? props.imageSrc : '/images/loginPageImages/2.jpg')}
    ');
    background-size: cover;
    background-position: center;
`;

const FormPart = styled.div`
    font-family: Rubik;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface IFormValues {
    email: string;
    password: string;
}

const Login: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const signupSchema = Yup.object().shape({
        email: Yup.string().email(t('errors.invalidEmail')).required(t('errors.required')),
        password: Yup.string()
            .matches(PASSWORD_REGEXP, t('errors.invalidPassword'))
            .required(t('errors.required')),
    });
    const user = useSelector(selectUser);
    const serverError = useSelector(selectServerError);
    const blockedAt = useSelector(selectBlockedAt);
    const isLoading = useSelector(selectLoading);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location: { state?: { from: { pathname: string } } } = useLocation();
    const [randomImage, setRandomImage] = useState('');

    useEffect(() => {
        const refreshToken = localStorage.getItem('refresh_token');
        // This missing check on token seems to be what was causing an infinite loop
        if (user && refreshToken) {
            const { from } = location.state ?? { from: { pathname: '/' } };
            navigate(from);
        }
    }, [user, navigate, location.state]);

    useEffect(() => {
        const numberOfImages = 5;
        // +1 since zero-based
        const randomNumber = Math.floor(Math.random() * Math.floor(numberOfImages)) + 1;
        setRandomImage(`/images/loginPageImages/${randomNumber}.jpg`);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('login.title')}</title>
            </Helmet>
            {isLoading ? <Loader overlay /> : null}
            <Container>
                <LeftSide>
                    <FormContainer>
                        <Logo />
                        <Text type="H700" style={{ marginTop: '1.875rem' }}>
                            {t('login.title')}
                        </Text>
                        <Text color={colors.neutral.N300} style={{ margin: '1.875rem 0 0 0' }}>
                            {t('login.text')}
                        </Text>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={signupSchema}
                            onSubmit={(values, actions) => {
                                void dispatch(login(values));
                                actions.setSubmitting(false);
                            }}
                        >
                            {(props: FormikProps<IFormValues>) => (
                                <Form>
                                    <FormPart>
                                        <FormikTextField
                                            name="email"
                                            type="email"
                                            placeholder={t('login.emailPlaceholder')}
                                            data-testid="loginEmail"
                                            errorTestId="errorEmail"
                                            whiteBackground
                                        />
                                        <FormikTextField
                                            name="password"
                                            type="password"
                                            placeholder={t('login.passwordPlaceholder')}
                                            data-testid="loginPassword"
                                            errorTestId="errorPassword"
                                            eyeIcon
                                            whiteBackground
                                        />
                                        {serverError && (
                                            <ErrorMessage
                                                data-testid="errorServer"
                                                translationKey={serverError}
                                            />
                                        )}
                                        <Link
                                            to={`/forgot-password${
                                                props.values.email
                                                    ? `?email=${props.values.email}`
                                                    : ''
                                            }`}
                                        >
                                            <Text
                                                color={colors.neutral.N300}
                                                style={{
                                                    textDecoration: 'underline',
                                                    margin: '1.875rem 0 0 0',
                                                }}
                                            >
                                                {t('login.forgotPassword')}
                                            </Text>
                                        </Link>
                                        <Button
                                            size="large"
                                            type="submit"
                                            aspect="primary"
                                            data-testid="submitLogin"
                                            disabled={Boolean(blockedAt)}
                                            text={t('login.submitMessage')}
                                            style={{
                                                marginTop: '1.875rem',
                                            }}
                                        />
                                    </FormPart>
                                </Form>
                            )}
                        </Formik>
                    </FormContainer>
                    <Footer />
                </LeftSide>
                <Image imageSrc={randomImage} />
            </Container>
        </>
    );
};

export default Login;
