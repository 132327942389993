import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import {
    addMonths,
    differenceInCalendarMonths,
    endOfMonth,
    format,
    getYear,
    isAfter,
    isBefore,
    startOfMonth,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import type { FormikProps } from 'formik';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Modal from '../Modal';
import type {
    Engagement,
    EcheanceEngagementInvoice,
    EcheancierEngagement,
    IEcheancierEngagementFormData,
    EcheanceEngagement,
    IEcheanceEngagementFormData,
} from '../../slices/engagementSlice';
import {
    updateEcheancierEngagement,
    updateEngagementEcheancierMode,
    getEngagement,
    selectUpdateEcheancierEngagementFulfilled,
    resetUpdateEcheancierEngagementFulfilled,
    selectEcheancierEngagement,
    getEcheancierEngagement,
    selectGetEcheancierEngagementFulfilled,
    resetGetEcheancierEngagementFulfilled,
    AUTOMATIC_CALCULATION,
    MANUAL_ENTRY,
} from '../../slices/engagementSlice';
import EcheancierEngagementLine from './EcheancierEngagementLine';
import { styles } from '../../constants/styles';
import { colors } from '../../constants/colors';
import Text from '../Text';
import EcheancierEngagementHeader from './EcheancierEngagementHeader';
import { getExactDecimalValue, roundAmount } from '../../utils/calculationMethods';
import type { ButtonProps } from '../Button';
import type { Permission } from '../../slices/authSlice';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import { getOperation } from '../../slices/operationSlice';
import Icon from '../Icon';
import Loader from '../Loader';
import { showFlag } from '../Flag';
import type { Option } from '../SelectField';
import { formatNumberWithLeadingZeros } from '../../utils/formatters';
import Tooltip from '../Tooltip';
import { ReactComponent as EcheancierAutomaticIcon } from '../../assets/images/echeancier_automatic.svg';
import { ReactComponent as EcheancierManualIcon } from '../../assets/images/echeancier_manual.svg';
import { calculateAmountRatio } from '../../utils/calculateAmountRatio';
import { useAppDispatch } from '../../store';

export const COL_WIDTHS = ['3%', '7%', '12%', '13%', '10.5%', '12%', '13%', '16.5%', '12%', '1%'];

const Container = styled.div`
    width: 100%;
`;

const Echeances = styled.div`
    margin-bottom: 8px;
`;

export const TableCol = styled.div<{
    justifyContent?: string;
    flex?: string;
    flexGrow?: number;
    width?: string;
}>`
    @media print {
        ${({ width }) => width === '15vw' && `justify-content: space-between;`}
        width:  ${({ width }) => (width === '6vw' ? `6vw !important;` : `20vw !important;`)};
        padding: 0rem;
        margin: 0rem !important;
    }
    display: flex;
    padding: 0 0.5rem;
    align-items: center;
    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
    ${({ flex }) => flex && `flex: ${flex};`}
    ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
    ${({ width }) => width && `width: ${width};`}
    p {
        padding: 0;
    }
`;

const AddEcheanceLine = styled.div`
    background-color: ${colors.blue.B50};
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 1rem;
    border-radius: ${styles.borderRadiusSmall};
`;

const LineText = styled(Text)`
    margin: 0;
`;

const ModalTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ModalTitleInfoIcon = styled(Icon)`
    margin-left: 10px;
`;

const LastUpdateInfo = styled(Text)`
    margin-left: 0.625rem;
    margin-bottom: 0;
    align-self: flex-end;
`;

const EcheancierIcon = styled.span`
    margin-left: 0.375rem;
`;

const checkIfEcheanceHasEngagementInvoices = (
    echeance: IEcheanceEngagementFormData | EcheanceEngagement,
): boolean => echeance.engagementInvoices.length !== 0;

export const checkIfEcheanceIsEditable = (
    echeancierViewMode: EcheancierViewMode,
    monthOfLatestEngagementInvoice: IEcheancierEngagementFormData['monthOfLatestEngagementInvoice'],
    engagement: Engagement,
    echeance: IEcheanceEngagementFormData,
): boolean => {
    let isEcheanceEditable = true;
    if (echeancierViewMode === ECHEANCIER_YEAR_VIEW) {
        isEcheanceEditable = false;
    }
    const echeanceHasEngagementInvoices = checkIfEcheanceHasEngagementInvoices(echeance);
    const isEcheanceBeforeEngagementStartDate = isBefore(
        new Date(echeance.month),
        startOfMonth(new Date(engagement.startDate)),
    );
    const isEcheanceBeforeLastSituationMonth =
        monthOfLatestEngagementInvoice !== null
            ? isBefore(new Date(echeance.month), new Date(monthOfLatestEngagementInvoice))
            : false;
    if (
        echeanceHasEngagementInvoices ||
        isEcheanceBeforeEngagementStartDate ||
        isEcheanceBeforeLastSituationMonth
    ) {
        isEcheanceEditable = false;
    }
    return isEcheanceEditable;
};

export const checkIfEcheanceisOutOfEcheancier = (
    engagement: Engagement,
    echeance: IEcheanceEngagementFormData,
): boolean => {
    const isEcheanceBeforeEngagementStartMonth = isBefore(
        new Date(echeance.month),
        startOfMonth(new Date(engagement.startDate)),
    );

    const isEcheanceAfterEngagementEndMonth = isAfter(
        new Date(echeance.month),
        endOfMonth(new Date(engagement.endDate)),
    );

    return isEcheanceBeforeEngagementStartMonth || isEcheanceAfterEngagementEndMonth;
};

export const calculateNbEcheancesWithinEngagementRangeDates = (engagement: Engagement): number =>
    differenceInCalendarMonths(new Date(engagement.endDate), new Date(engagement.startDate)) + 1;

export const calculateSumOfPrevisionsHt = (
    echeances: IEcheancierEngagementFormData['echeances'],
) => {
    const sumOfPrevisionsHt = echeances.reduce(
        (sum, currentEditablePrevisionHt) => sum + Number(currentEditablePrevisionHt.amountHt),
        0,
    );
    return getExactDecimalValue(sumOfPrevisionsHt, 2);
};

export const calculateDifference = (
    sumOfPrevisionsHt: EcheanceEngagement['amountHt'],
    engagementAmountHt: Engagement['amountHt'],
): EcheanceEngagement['amountHt'] => {
    let difference = getExactDecimalValue(engagementAmountHt - sumOfPrevisionsHt, 2);
    // To make sure it doesn't return -0 (https://stackoverflow.com/a/53135516/4321128)
    difference += 0;
    return difference;
};

const getInitialAmount = (
    echeancierViewMode: EcheancierViewMode,
    echeance: IEcheanceEngagementFormData,
    initialEcheance?: EcheanceEngagement,
): number => {
    if (echeancierViewMode === ECHEANCIER_YEAR_VIEW) {
        return Number(echeance.amountHt);
    }
    const initialAmountHt = initialEcheance?.amountHt ?? 0;
    return Number(initialAmountHt);
};
export const calculatePrevisionHtPercentage = (
    engagement: Engagement,
    initialAmountHt: EcheanceEngagement['amountHt'],
): number =>
    engagement.amountHt !== 0 ? roundAmount((initialAmountHt / engagement.amountHt) * 100, 2) : 0;

export const getNewEcheanceDefaultValues = (
    currentEcheances: IEcheanceEngagementFormData[],
): IEcheanceEngagementFormData => {
    const lastEcheanceMonth = currentEcheances[currentEcheances.length - 1].month;
    const newEcheanceMonth = format(addMonths(new Date(lastEcheanceMonth), 1), 'yyyy-MM-dd');
    const newEcheanceDefaultValues = {
        number: null,
        month: newEcheanceMonth,
        amountHt: 0,
        suggestionHt: 0,
        engagementInvoices: [],
        engagementAvancementHt: 0,
    };
    return newEcheanceDefaultValues;
};

export const getEcheancesToEditToDistributeDifferenceAndGetRemainingToDistribute = (
    echeancierViewMode: EcheancierViewMode,
    monthOfLatestEngagementInvoice: IEcheancierEngagementFormData['monthOfLatestEngagementInvoice'],
    engagement: Engagement,
    echeances: IEcheancierEngagementFormData['echeances'],
    remainsToDistribute: number,
): { echeancesToEdit: EcheanceToEdit[]; remainingToDistribute: number } => {
    let remainingToDistribute = remainsToDistribute;
    const echeancesToEdit = [];
    // Looping starting from the last echeance
    for (let i = echeances.length - 1; i >= 0; i--) {
        if (remainingToDistribute === 0) {
            break;
        }
        const echeance = echeances[i];
        const isEcheanceEditable = checkIfEcheanceIsEditable(
            echeancierViewMode,
            monthOfLatestEngagementInvoice,
            engagement,
            echeance,
        );
        if (isEcheanceEditable) {
            const echeanceAmountHt = Number(echeance.amountHt);
            if (echeanceAmountHt > 0) {
                const amountToAdd =
                    echeanceAmountHt + remainingToDistribute <= 0
                        ? -echeanceAmountHt
                        : remainingToDistribute;
                echeancesToEdit.push({
                    echeanceIndexToEdit: i,
                    newEcheanceAmountHt: (echeanceAmountHt + amountToAdd).toFixed(2),
                });
                remainingToDistribute = getExactDecimalValue(
                    remainingToDistribute - amountToAdd,
                    2,
                );
            }
        }
    }
    return { echeancesToEdit, remainingToDistribute };
};

const unFormatEcheancier = (
    echeancierEngagement: IEcheancierEngagementFormData,
): EcheancierEngagement => {
    const echeancierWithAmountsInNumbers: EcheancierEngagement = {
        ...echeancierEngagement,
        echeances: unFormatEcheancesByMonth(echeancierEngagement.echeances),
    };
    return echeancierWithAmountsInNumbers;
};

const getFirstAndLastMarketInvoicesNumberByEcheance = (
    engagementInvoices: EcheanceEngagement['engagementInvoices'],
): RangeInvoiceNumberByEcheance => {
    const firstEcheanceMarketInvoiceNumber = engagementInvoices.length
        ? engagementInvoices[0].marketInvoiceNumber
        : null;
    const lastEcheanceMarketInvoiceNumber = engagementInvoices.length
        ? engagementInvoices[engagementInvoices.length - 1].marketInvoiceNumber
        : null;
    return { firstEcheanceMarketInvoiceNumber, lastEcheanceMarketInvoiceNumber };
};

const aggregrateEcheancesByYearObject = (
    echeances: EcheancierEngagement['echeances'],
): EcheancesEngagementByYearObject => {
    const echeancesByYearObjectProcessed = echeances.reduce(
        (
            echeancesByYearObject: EcheancesEngagementByYearObject,
            currentEcheance: EcheanceEngagement,
            currentIndex: number,
            initialEcheancesArray,
        ) => {
            const currentEcheanceYear = getYear(new Date(currentEcheance.month));
            const previousEcheanceYear =
                currentIndex > 0
                    ? getYear(new Date(initialEcheancesArray[currentIndex - 1].month))
                    : null;
            const { firstEcheanceMarketInvoiceNumber, lastEcheanceMarketInvoiceNumber } =
                getFirstAndLastMarketInvoicesNumberByEcheance(currentEcheance.engagementInvoices);
            if (currentEcheanceYear === previousEcheanceYear) {
                const currentEcheanceYearSumOfEcheanceEngagementInvoicesAvancementHt =
                    calculateSumOfEcheanceEngagementInvoicesAvancementHt(
                        echeancesByYearObject[currentEcheanceYear].engagementInvoices,
                    );
                const currentEcheanceEngagementInvoicesAvancementHt =
                    calculateSumOfEcheanceEngagementInvoicesAvancementHt(
                        currentEcheance.engagementInvoices,
                    );
                echeancesByYearObject[currentEcheanceYear] = {
                    ...echeancesByYearObject[currentEcheanceYear],
                    rangeNumberFirst:
                        echeancesByYearObject[currentEcheanceYear].rangeNumberFirst ??
                        currentEcheance.number,
                    rangeNumberLast:
                        currentEcheance.number ??
                        echeancesByYearObject[previousEcheanceYear].rangeNumberLast,
                    rangeMarketInvoiceNumberFirst:
                        echeancesByYearObject[currentEcheanceYear].rangeMarketInvoiceNumberFirst ??
                        firstEcheanceMarketInvoiceNumber,
                    rangeMarketInvoiceNumberLast:
                        lastEcheanceMarketInvoiceNumber ??
                        echeancesByYearObject[previousEcheanceYear].rangeMarketInvoiceNumberLast,
                    amountHt:
                        echeancesByYearObject[currentEcheanceYear].amountHt +
                        currentEcheance.amountHt,
                    suggestionHt:
                        echeancesByYearObject[currentEcheanceYear].suggestionHt +
                        currentEcheance.suggestionHt,
                    engagementAvancementHt:
                        currentEcheanceYearSumOfEcheanceEngagementInvoicesAvancementHt +
                        currentEcheanceEngagementInvoicesAvancementHt,
                    engagementInvoices: [
                        ...echeancesByYearObject[currentEcheanceYear].engagementInvoices,
                        ...currentEcheance.engagementInvoices,
                    ],
                };
            } else {
                echeancesByYearObject[currentEcheanceYear] = {
                    month: currentEcheance.month,
                    rangeNumberFirst: currentEcheance.number,
                    rangeNumberLast: currentEcheance.number,
                    rangeMarketInvoiceNumberFirst: firstEcheanceMarketInvoiceNumber,
                    rangeMarketInvoiceNumberLast: lastEcheanceMarketInvoiceNumber,
                    amountHt: currentEcheance.amountHt,
                    suggestionHt: currentEcheance.suggestionHt,
                    engagementAvancementHt: calculateSumOfEcheanceEngagementInvoicesAvancementHt(
                        currentEcheance.engagementInvoices,
                    ),
                    engagementInvoices: currentEcheance.engagementInvoices,
                };
            }
            return echeancesByYearObject;
        },
        {},
    );
    return echeancesByYearObjectProcessed;
};

export const formatRangeWithLeadingZeros = (
    rangeFirst: RangeNumber,
    rangeLast: RangeNumber,
    nbMinOfDigits: number,
): string | null => {
    const formattedRangeFirst =
        rangeFirst !== null ? formatNumberWithLeadingZeros(rangeFirst, nbMinOfDigits) : null;
    const formattedRangeLast =
        rangeLast !== null ? formatNumberWithLeadingZeros(rangeLast, nbMinOfDigits) : null;

    let formattedRange: string | null = null;
    if (formattedRangeFirst !== null && formattedRangeLast === null) {
        formattedRange = formattedRangeFirst;
    } else if (formattedRangeFirst === null && formattedRangeLast !== null) {
        formattedRange = formattedRangeLast;
    } else if (formattedRangeFirst !== null && formattedRangeLast !== null) {
        if (formattedRangeFirst !== formattedRangeLast) {
            formattedRange = `${formattedRangeFirst} > ${formattedRangeLast}`;
        } else {
            formattedRange = formattedRangeFirst;
        }
    }
    return formattedRange;
};

const calculateSumOfEcheanceEngagementInvoicesAvancementHt = (
    engagementInvoices: EcheanceEngagement['engagementInvoices'],
): IEcheanceEngagementFormData['engagementAvancementHt'] => {
    const echeanceEngagementInvoicesAvancementHt = engagementInvoices.reduce(
        (sum, currentEngagementInvoice) => sum + currentEngagementInvoice.avancementsAmountHt,
        0,
    );
    return getExactDecimalValue(echeanceEngagementInvoicesAvancementHt, 2);
};

const transformEcheancesByYearObjectToFormattedArray = (
    echeancesByYearObject: EcheancesEngagementByYearObject,
): IEcheancierEngagementFormData['echeances'] => {
    const echeancesByYearArray = Object.keys(echeancesByYearObject).map((year: string) => {
        const {
            rangeNumberFirst,
            rangeNumberLast,
            rangeMarketInvoiceNumberFirst,
            rangeMarketInvoiceNumberLast,
            ...echeance
        } = echeancesByYearObject[year];
        return {
            ...echeance,
            number: formatRangeWithLeadingZeros(rangeNumberFirst, rangeNumberLast, 2),
            month: year,
            amountHt: getExactDecimalValue(echeance.amountHt, 2),
            suggestionHt: getExactDecimalValue(echeance.suggestionHt, 2),
            engagementAvancementHt: calculateSumOfEcheanceEngagementInvoicesAvancementHt(
                echeance.engagementInvoices,
            ),
        };
    });

    return echeancesByYearArray;
};

export const formatAndAggregrateEcheancesByYear = (
    echeances: EcheancierEngagement['echeances'],
): IEcheancierEngagementFormData['echeances'] => {
    const echeancesByYearObject = aggregrateEcheancesByYearObject(echeances);
    const echeancesByYearFormattedArray =
        transformEcheancesByYearObjectToFormattedArray(echeancesByYearObject);

    return echeancesByYearFormattedArray;
};

const getEcheanceEngagementMarketInvoicesNumbersRange = (
    engagementInvoices: EcheanceEngagement['engagementInvoices'],
): string | null => {
    const { firstEcheanceMarketInvoiceNumber, lastEcheanceMarketInvoiceNumber } =
        getFirstAndLastMarketInvoicesNumberByEcheance(engagementInvoices);
    return formatRangeWithLeadingZeros(
        firstEcheanceMarketInvoiceNumber,
        lastEcheanceMarketInvoiceNumber,
        3,
    );
};

export const formatEcheancesByMonth = (
    echeances: EcheancierEngagement['echeances'],
): IEcheancierEngagementFormData['echeances'] =>
    echeances.map((echeance) => ({
        ...echeance,
        number: echeance.number !== null ? formatNumberWithLeadingZeros(echeance.number, 2) : null,
        engagementAvancementHt: calculateSumOfEcheanceEngagementInvoicesAvancementHt(
            echeance.engagementInvoices,
        ),
    }));

const unFormatEcheancesByMonth = (
    echeances: IEcheancierEngagementFormData['echeances'],
): EcheancierEngagement['echeances'] =>
    echeances.map((echeance) => ({
        ...echeance,
        number: echeance.number !== null ? Number(echeance.number) : null,
        amountHt: Number(echeance.amountHt),
        engagementInvoices: echeance.engagementInvoices,
    }));

export const computeEcheancesForRecalageEdition = (
    monthOfLatestEngagementInvoice: IEcheancierEngagementFormData['monthOfLatestEngagementInvoice'],
    engagement: Engagement,
    echeances: EcheancierEngagement['echeances'],
): EcheancierEngagement['echeances'] =>
    echeances.map((echeance) => {
        let echeanceImposedAmount = echeance.amountHt;
        const echeanceAvancementHt = calculateSumOfEcheanceEngagementInvoicesAvancementHt(
            echeance.engagementInvoices,
        );

        const echeanceHasEngagementInvoices = checkIfEcheanceHasEngagementInvoices(echeance);
        const isEcheanceBeforeEngagementStartDate = isBefore(
            new Date(echeance.month),
            startOfMonth(new Date(engagement.startDate)),
        );
        const isEcheanceBeforeLastSituationMonth =
            monthOfLatestEngagementInvoice !== null
                ? isBefore(new Date(echeance.month), new Date(monthOfLatestEngagementInvoice))
                : false;

        if (echeanceHasEngagementInvoices) {
            echeanceImposedAmount = echeanceAvancementHt;
        } else if (isEcheanceBeforeEngagementStartDate || isEcheanceBeforeLastSituationMonth) {
            echeanceImposedAmount = 0;
        }
        const computedEcheanceForRecalageEdition = {
            ...echeance,
            amountHt: echeanceImposedAmount,
            engagementAvancementHt: echeanceAvancementHt,
        };
        return computedEcheanceForRecalageEdition;
    });

export type FilterListMode = 'allEcheances' | 'echeancesWithANonNullAmount';
export const FILTER_LIST_MODE_ALL_ECHEANCES: FilterListMode = 'allEcheances';
export const FILTER_LIST_MODE_NON_NULL_ECHEANCES: FilterListMode = 'echeancesWithANonNullAmount';

export type EcheancierViewMode = 'month' | 'year';
export const ECHEANCIER_MONTH_VIEW: EcheancierViewMode = 'month';
export const ECHEANCIER_YEAR_VIEW: EcheancierViewMode = 'year';
export type EcheancierViewModeOption = Option<EcheancierViewMode>;

type EcheanceToEdit = {
    echeanceIndexToEdit: number;
    newEcheanceAmountHt: IEcheanceEngagementFormData['amountHt'];
};

type RangeNumber = number | null;
type EcheanceEngagementByYear = Omit<EcheanceEngagement, 'number' | 'marketInvoiceNumber'> & {
    rangeNumberFirst: RangeNumber;
    rangeNumberLast: RangeNumber;
    rangeMarketInvoiceNumberFirst: RangeNumber;
    rangeMarketInvoiceNumberLast: RangeNumber;
    engagementAvancementHt: IEcheanceEngagementFormData['engagementAvancementHt'];
};
type EcheancesEngagementByYearObject = Record<string, EcheanceEngagementByYear>;

type RangeInvoiceNumberByEcheance = {
    firstEcheanceMarketInvoiceNumber: EcheanceEngagementInvoice['marketInvoiceNumber'] | null;
    lastEcheanceMarketInvoiceNumber: EcheanceEngagementInvoice['marketInvoiceNumber'] | null;
};

type Props = {
    permissionNeededToEdit: Permission;
    engagementAndMarketLabels: string;
    engagement: Engagement;
    onClose: (event?: React.SyntheticEvent<HTMLElement> | undefined) => void;
    operationId: number;
    marketId: number;
};

const ModalTitle: React.FC<{
    updatedBy: IEcheancierEngagementFormData['updatedBy'];
    updatedAt: IEcheancierEngagementFormData['updatedAt'];
    engagement: Engagement;
}> = ({ updatedBy, updatedAt, engagement }) => {
    const { t } = useTranslation();

    return (
        <ModalTitleContainer>
            <LineText type="H700">
                {t('echeancierEngagement.title')}{' '}
                {engagement.echeancierMode === AUTOMATIC_CALCULATION
                    ? t('echeancierEngagement.automatic')
                    : t('echeancierEngagement.manual')}
                <EcheancierIcon>
                    {engagement.echeancierMode === AUTOMATIC_CALCULATION ? (
                        <EcheancierAutomaticIcon />
                    ) : (
                        <EcheancierManualIcon />
                    )}
                </EcheancierIcon>
            </LineText>
            <LastUpdateInfo color={colors.blue.B400}>
                {engagement.echeancierMode === AUTOMATIC_CALCULATION
                    ? t('echeancierEngagement.lastUpdated.automaticCalculation')
                    : t('echeancierEngagement.lastUpdated.lastModification')}
                {updatedBy?.firstName && updatedBy.lastName && (
                    <>
                        {' '}
                        {t('echeancierEngagement.lastUpdated.by')} {updatedBy.firstName}{' '}
                        {updatedBy.lastName}
                    </>
                )}{' '}
                {t('echeancierEngagement.lastUpdated.on')}{' '}
                {format(new Date(updatedAt), 'dd/MM/yyyy HH:mm')}
            </LastUpdateInfo>
            <Tooltip content={t('echeancierEngagement.editModeWarning')} isOneLine={false}>
                <ModalTitleInfoIcon name="Info" width="1.25rem" />
            </Tooltip>
        </ModalTitleContainer>
    );
};

const EcheancierEngagementModal: React.FC<Props> = ({
    permissionNeededToEdit,
    engagementAndMarketLabels,
    engagement,
    onClose,
    operationId,
    marketId,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const hasRightToEdit = usePermissionsCheck([permissionNeededToEdit]);
    const echeancierEngagement: EcheancierEngagement | undefined = useSelector(
        selectEcheancierEngagement(engagement.id),
    );
    const getEcheancierEngagementFulfilled = useSelector(selectGetEcheancierEngagementFulfilled);
    const updateEcheancierEngagementFulfilled = useSelector(
        selectUpdateEcheancierEngagementFulfilled,
    );

    const [isModalOpen, setIsModalOpen] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [isSwitchToEditModeModalOpen, setIsSwitchToEditModeModalOpen] = useState<boolean>(false);
    const [hasUserSwitchedEcheancierToEditMode, setHasUserSwitchedEcheancierToEditMode] =
        useState<boolean>(false);
    const [echeancierViewMode, setEcheancierViewMode] =
        useState<EcheancierViewMode>(ECHEANCIER_MONTH_VIEW);
    const [filterListMode, setFilterListMode] = useState<FilterListMode>(
        FILTER_LIST_MODE_ALL_ECHEANCES,
    );

    const echeancierViewModes: EcheancierViewModeOption[] = [
        {
            value: ECHEANCIER_MONTH_VIEW,
            label: t('echeancierEngagement.month'),
        },
        {
            value: ECHEANCIER_YEAR_VIEW,
            label: t('echeancierEngagement.year'),
        },
    ];

    const getEcheancesByMonth = useCallback(
        (
            monthOfLatestEngagementInvoice: EcheancierEngagement['monthOfLatestEngagementInvoice'],
            echeances: EcheancierEngagement['echeances'],
        ): IEcheancierEngagementFormData['echeances'] =>
            formatEcheancesByMonth(
                computeEcheancesForRecalageEdition(
                    monthOfLatestEngagementInvoice,
                    engagement,
                    echeances,
                ),
            ),
        [engagement],
    );

    const getEcheancesByYear = useCallback(
        (
            echeances: IEcheancierEngagementFormData['echeances'],
        ): IEcheancierEngagementFormData['echeances'] =>
            formatAndAggregrateEcheancesByYear(unFormatEcheancesByMonth(echeances)),

        [],
    );

    const [initialFormValues, setInitialFormValues] = useState<
        IEcheancierEngagementFormData | undefined
    >();
    const [formValues, setFormValues] = useState<IEcheancierEngagementFormData | undefined>();
    const [lastEditedEcheancesValues, setLastEditedEcheancesValues] = useState<
        IEcheancierEngagementFormData['echeances'] | undefined
    >();
    const [
        sumOfSuggestionsIsDifferentThanEngagementAmount,
        setSumOfSuggestionsIsDifferentThanEngagementAmount,
    ] = useState<boolean>();

    useEffect(() => {
        if (echeancierEngagement && getEcheancierEngagementFulfilled) {
            setInitialFormValues({
                ...echeancierEngagement,
                echeances: getEcheancesByMonth(
                    echeancierEngagement.monthOfLatestEngagementInvoice,
                    echeancierEngagement.echeances,
                ),
            });
            setFormValues({
                ...echeancierEngagement,
                echeances: getEcheancesByMonth(
                    echeancierEngagement.monthOfLatestEngagementInvoice,
                    echeancierEngagement.echeances,
                ),
            });
            setLastEditedEcheancesValues(
                getEcheancesByMonth(
                    echeancierEngagement.monthOfLatestEngagementInvoice,
                    echeancierEngagement.echeances,
                ),
            );
            setSumOfSuggestionsIsDifferentThanEngagementAmount(
                checkIfSumOfSuggestionsIsDifferenceThanEngagementAmount(
                    engagement,
                    echeancierEngagement.echeances,
                ),
            );
        }
    }, [echeancierEngagement, engagement, getEcheancesByMonth, getEcheancierEngagementFulfilled]);

    useEffect(
        () => () => {
            dispatch(resetGetEcheancierEngagementFulfilled());
        },
        [dispatch],
    );

    useEffect(() => {
        if (
            editMode &&
            sumOfSuggestionsIsDifferentThanEngagementAmount &&
            engagement.echeancierMode === AUTOMATIC_CALCULATION
        ) {
            showFlag('error', '', t('echeancierEngagement.suggestionCannotBeApplied'));
        }
    }, [editMode, sumOfSuggestionsIsDifferentThanEngagementAmount, t, engagement]);

    const echeanceEngagementSchema = Yup.object().shape({
        month: Yup.string().required(),
        amountHt: Yup.number(),
    });
    const echeancierEngagementSchema = Yup.object().shape({
        echeances: Yup.array().of(echeanceEngagementSchema),
    });

    const durationInMonths = calculateNbEcheancesWithinEngagementRangeDates(engagement);

    const computeButtonsOutOfProps = (
        formikProps: FormikProps<IEcheancierEngagementFormData>,
        difference: number,
    ) => {
        const buttons: ButtonProps[] = [];
        if (hasRightToEdit) {
            if (!editMode) {
                buttons.push({
                    text: t('general.close'),
                    aspect: 'secondary',
                    'data-testid': 'close',
                    onClick() {
                        setIsModalOpen(false);
                        setEditMode(false);
                        onClose();
                    },
                });
                buttons.push({
                    text: t('general.edit'),
                    aspect: 'primary',
                    'data-testid': 'edit',
                    onClick() {
                        if (
                            engagement.echeancierMode === AUTOMATIC_CALCULATION &&
                            !hasUserSwitchedEcheancierToEditMode
                        ) {
                            setIsSwitchToEditModeModalOpen(true);
                        } else {
                            setEditMode(true);
                        }
                    },
                });
            }
            if (editMode) {
                buttons.push({
                    text: t('general.cancel'),
                    aspect: 'secondary',
                    'data-testid': 'cancel',
                    onClick() {
                        if (initialFormValues) {
                            setLastEditedEcheancesValues(initialFormValues.echeances);
                            setEcheancierViewMode(ECHEANCIER_MONTH_VIEW);
                        }
                        formikProps.handleReset();
                        setEditMode(false);
                    },
                });
                buttons.push({
                    text: t('general.record'),
                    aspect: 'primary',
                    'data-testid': 'save',
                    onClick() {
                        formikProps.handleSubmit();
                        setEditMode(false);
                    },
                    disabled: echeancierViewMode === ECHEANCIER_YEAR_VIEW || difference !== 0,
                });
            }
        }
        return buttons;
    };

    const handleSwitchToEditModeConfirmation = () => {
        void dispatch(
            updateEngagementEcheancierMode({
                echeancierMode: MANUAL_ENTRY,
                id: engagement.id,
                marketId: Number(marketId),
                operationId: Number(operationId),
            }),
        );
        setIsSwitchToEditModeModalOpen(false);
        setHasUserSwitchedEcheancierToEditMode(true);
        setEditMode(true);
    };

    const handleEcheancierViewModeChange = (viewMode: EcheancierViewMode) => {
        setEcheancierViewMode(viewMode);
    };

    const onCloseButtonPressed = () => {
        setIsModalOpen(false);
        onClose();
    };

    const checkIfSumOfSuggestionsIsDifferenceThanEngagementAmount = (
        engagementToCheck: Engagement,
        echeances: EcheancierEngagement['echeances'],
    ): boolean => {
        const sumOfSuggestions = echeances.reduce(
            (sum, currentEcheance) => sum + currentEcheance.suggestionHt,
            0,
        );
        return sumOfSuggestions !== engagementToCheck.amountHt;
    };

    const distributeDifference = (
        monthOfLatestEngagementInvoice: IEcheancierEngagementFormData['monthOfLatestEngagementInvoice'],
        difference: EcheanceEngagement['amountHt'],
        echeances: IEcheancierEngagementFormData['echeances'],
        setFieldValue: (field: string, value: unknown) => void,
    ): void => {
        if (difference !== 0) {
            const { echeancesToEdit, remainingToDistribute } =
                getEcheancesToEditToDistributeDifferenceAndGetRemainingToDistribute(
                    echeancierViewMode,
                    monthOfLatestEngagementInvoice,
                    engagement,
                    echeances,
                    difference,
                );

            const areThereEcheancesToEdit = echeancesToEdit.length > 0;
            if (areThereEcheancesToEdit) {
                for (const echeanceToEdit of echeancesToEdit) {
                    const { echeanceIndexToEdit, newEcheanceAmountHt } = echeanceToEdit;
                    setFieldValue(`echeances.${echeanceIndexToEdit}.amountHt`, newEcheanceAmountHt);
                }
            }

            if (remainingToDistribute !== 0) {
                showFlag('error', '', t('echeancierEngagement.cantDistributeDifference'));
            }
        }
    };

    const copySuggestionsToRecalages = (
        monthOfLatestEngagementInvoice: IEcheancierEngagementFormData['monthOfLatestEngagementInvoice'],
        echeances: IEcheancierEngagementFormData['echeances'],
        setFieldValue: (field: string, value: unknown) => void,
    ) => {
        const areThereEcheancesToEdit = echeances.length > 0;
        if (areThereEcheancesToEdit) {
            for (let i = 0; i < echeances.length; i++) {
                const echeance = echeances[i];
                const isEcheanceEditable = checkIfEcheanceIsEditable(
                    echeancierViewMode,
                    monthOfLatestEngagementInvoice,
                    engagement,
                    echeance,
                );
                if (isEcheanceEditable) {
                    setFieldValue(`echeances.${i}.amountHt`, echeance.suggestionHt);
                }
            }
        }
    };

    const handleFilterListClick = (filterListModeClicked: FilterListMode) => {
        setFilterListMode(filterListModeClicked);
    };

    useEffect(() => {
        void dispatch(
            getEcheancierEngagement({
                operationId: Number(operationId),
                marketId: Number(marketId),
                id: engagement.id,
            }),
        );
    }, [dispatch, engagement.id, marketId, operationId]);

    useEffect(() => {
        if (updateEcheancierEngagementFulfilled) {
            dispatch(resetUpdateEcheancierEngagementFulfilled());
            // Fetching engagement because the echeancier update has updated engagement.echeancierLastCalculationAt
            void dispatch(
                getEngagement({
                    operationId: Number(operationId),
                    marketId: Number(marketId),
                    id: engagement.id,
                }),
            );
            // Fetching operation because the echeancier update has updated operation.lastEngagementsUpdatedAt
            void dispatch(getOperation(Number(operationId)));
        }
    }, [dispatch, engagement.id, marketId, operationId, updateEcheancierEngagementFulfilled]);

    const currentEcheancesValues = useRef<IEcheancierEngagementFormData['echeances']>([]);
    const currentDifference = useRef<EcheanceEngagement['amountHt']>(0);

    useEffect(() => {
        if (echeancierEngagement && lastEditedEcheancesValues) {
            if (echeancierViewMode === ECHEANCIER_MONTH_VIEW) {
                setFormValues({
                    ...echeancierEngagement,
                    echeances: lastEditedEcheancesValues,
                });
            } else {
                if (filterListMode === FILTER_LIST_MODE_NON_NULL_ECHEANCES) {
                    setFormValues({
                        ...echeancierEngagement,
                        echeances: getEcheancesByYear(lastEditedEcheancesValues),
                    });
                    setFilterListMode(FILTER_LIST_MODE_ALL_ECHEANCES);
                } else {
                    setLastEditedEcheancesValues(currentEcheancesValues.current);
                    setFormValues({
                        ...echeancierEngagement,
                        echeances: getEcheancesByYear(currentEcheancesValues.current),
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [
        echeancierEngagement,
        echeancierViewMode,
        getEcheancesByMonth,
        getEcheancesByYear,
        lastEditedEcheancesValues,
    ]);

    const getEcheancesWithNonNullAmountAndNonNullAvancement = (
        echeancierEngagementToCheck: EcheancierEngagement,
        echeances: IEcheancierEngagementFormData['echeances'],
    ): IEcheancierEngagementFormData['echeances'] =>
        echeances.filter((echeance) => {
            const initialEcheance = echeancierEngagementToCheck.echeances.find(
                (initialEcheanceItem) => initialEcheanceItem.month === echeance.month,
            );
            const initialAmountHt = getInitialAmount(echeancierViewMode, echeance, initialEcheance);
            const engagementAvancementHt = Number(echeance.engagementAvancementHt);

            const echeanceHasOnlyNullValues = initialAmountHt === 0 && engagementAvancementHt === 0;

            return !echeanceHasOnlyNullValues;
        });

    const getEcheancesWithNonNullValues = (
        echeancierEngagementToCheck: EcheancierEngagement,
        echeances: IEcheancierEngagementFormData['echeances'],
    ): IEcheancierEngagementFormData['echeances'] =>
        echeances.filter((echeance) => {
            const initialEcheance = echeancierEngagementToCheck.echeances.find(
                (initialEcheanceItem) => initialEcheanceItem.month === echeance.month,
            );
            const initialAmountHt = getInitialAmount(echeancierViewMode, echeance, initialEcheance);
            const amountHt = Number(echeance.amountHt);
            const suggestionHt = Number(echeance.suggestionHt);
            const engagementAvancementHt = Number(echeance.engagementAvancementHt);

            const echeanceHasOnlyNullValues =
                initialAmountHt === 0 &&
                amountHt === 0 &&
                suggestionHt === 0 &&
                engagementAvancementHt === 0;

            return !echeanceHasOnlyNullValues;
        });

    useEffect(() => {
        if (
            echeancierEngagement &&
            lastEditedEcheancesValues &&
            echeancierViewMode === ECHEANCIER_MONTH_VIEW
        ) {
            if (filterListMode === FILTER_LIST_MODE_NON_NULL_ECHEANCES) {
                setLastEditedEcheancesValues(currentEcheancesValues.current);
                const echeancesWithNonNullAmountAndNonNullAvancement =
                    getEcheancesWithNonNullAmountAndNonNullAvancement(
                        echeancierEngagement,
                        currentEcheancesValues.current,
                    );
                const echeancesWithNonNullValues = getEcheancesWithNonNullValues(
                    echeancierEngagement,
                    currentEcheancesValues.current,
                );
                const filteredEcheances = editMode
                    ? echeancesWithNonNullValues
                    : echeancesWithNonNullAmountAndNonNullAvancement;
                if (filteredEcheances.length > 0) {
                    setFormValues({
                        ...echeancierEngagement,
                        echeances: filteredEcheances,
                    });
                }
            } else {
                setFormValues({
                    ...echeancierEngagement,
                    echeances: lastEditedEcheancesValues,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [filterListMode, echeancierEngagement]);

    return getEcheancierEngagementFulfilled && echeancierEngagement && formValues ? (
        <>
            <Formik
                initialValues={formValues}
                validationSchema={echeancierEngagementSchema}
                onSubmit={(values: IEcheancierEngagementFormData, actions) => {
                    void dispatch(
                        updateEcheancierEngagement({
                            echeancierEngagement: unFormatEcheancier(values),
                            id: engagement.id,
                            marketId: Number(marketId),
                            operationId: Number(operationId),
                        }),
                    );
                    actions.setSubmitting(false);
                    actions.resetForm();
                }}
                enableReinitialize
            >
                {(formikProps: FormikProps<IEcheancierEngagementFormData>) => {
                    currentEcheancesValues.current = formikProps.values.echeances;
                    const avancementHtRatio = calculateAmountRatio(
                        echeancierEngagement.cumulatedAvancementHt,
                        engagement.amountHt,
                    );
                    const restToInvoiceHt = formikProps.values.restToInvoiceHt;
                    const sumOfPrevisionsHt = calculateSumOfPrevisionsHt(
                        formikProps.values.echeances,
                    );
                    const difference = calculateDifference(sumOfPrevisionsHt, engagement.amountHt);
                    currentDifference.current = difference;

                    return (
                        <Modal
                            isOpen={isModalOpen}
                            size="full"
                            title={
                                <ModalTitle
                                    updatedBy={formikProps.values.updatedBy}
                                    updatedAt={formikProps.values.updatedAt}
                                    engagement={engagement}
                                />
                            }
                            backgroundColor={colors.neutral.N50}
                            onCloseButtonPressed={onCloseButtonPressed}
                            buttons={computeButtonsOutOfProps(formikProps, difference)}
                            specificHeight="90vh"
                            specificPadding="0"
                            scrollableBody
                        >
                            <Container data-testid="echeancierEngagementModal">
                                <EcheancierEngagementHeader
                                    engagementAndMarketLabels={engagementAndMarketLabels}
                                    editMode={editMode}
                                    durationInMonths={durationInMonths}
                                    engagementAmountHt={engagement.amountHt}
                                    avancementHtRatio={avancementHtRatio}
                                    monthOfLatestEngagementInvoice={
                                        echeancierEngagement.monthOfLatestEngagementInvoice
                                    }
                                    cumulatedAvancementHt={
                                        echeancierEngagement.cumulatedAvancementHt
                                    }
                                    restToInvoiceHt={restToInvoiceHt}
                                    sumOfPrevisionsHt={sumOfPrevisionsHt}
                                    difference={difference}
                                    shouldDisableDistributeDifferenceButton={difference === 0}
                                    onDistributeDifferenceClick={() => {
                                        // We use useRef to make sure the callback uses the current stade
                                        // https://stackoverflow.com/a/60643670
                                        distributeDifference(
                                            echeancierEngagement.monthOfLatestEngagementInvoice,
                                            currentDifference.current,
                                            currentEcheancesValues.current,
                                            formikProps.setFieldValue,
                                        );
                                    }}
                                    onCopySuggestionsToRecalagesButtonClick={() =>
                                        copySuggestionsToRecalages(
                                            echeancierEngagement.monthOfLatestEngagementInvoice,
                                            currentEcheancesValues.current,
                                            formikProps.setFieldValue,
                                        )
                                    }
                                    echeancierViewModes={echeancierViewModes}
                                    echeancierViewModeOption={echeancierViewModes.find(
                                        (mode) => mode.value === echeancierViewMode,
                                    )}
                                    onEcheancierViewModeChange={handleEcheancierViewModeChange}
                                    shouldShowFilterListButton={
                                        echeancierViewMode !== ECHEANCIER_YEAR_VIEW
                                    }
                                    appliedFilterListMode={filterListMode}
                                    onFilterListClick={handleFilterListClick}
                                    echeancierMode={engagement.echeancierMode}
                                />
                                <Echeances>
                                    <FieldArray
                                        name="echeances"
                                        render={(arrayHelpers) => (
                                            <>
                                                {formikProps.values.echeances.map(
                                                    (echeance, index) => {
                                                        const initialEcheance =
                                                            echeancierEngagement.echeances.find(
                                                                (initialEcheanceItem) =>
                                                                    initialEcheanceItem.month ===
                                                                    echeance.month,
                                                            );
                                                        const initialAmountHt = getInitialAmount(
                                                            echeancierViewMode,
                                                            echeance,
                                                            initialEcheance,
                                                        );
                                                        const initialPercentageHt =
                                                            calculatePrevisionHtPercentage(
                                                                engagement,
                                                                initialAmountHt,
                                                            );
                                                        return (
                                                            <EcheancierEngagementLine
                                                                key={`${echeance.month}-${echeance.number}`}
                                                                index={index}
                                                                number={echeance.number}
                                                                month={echeance.month}
                                                                initialAmountHt={initialAmountHt}
                                                                initialPercentageHt={
                                                                    initialPercentageHt
                                                                }
                                                                marketInvoiceNumber={getEcheanceEngagementMarketInvoicesNumbersRange(
                                                                    echeance.engagementInvoices,
                                                                )}
                                                                engagementAvancementHt={
                                                                    echeance.engagementAvancementHt
                                                                }
                                                                suggestionHt={echeance.suggestionHt}
                                                                editMode={editMode}
                                                                echeanceEditable={checkIfEcheanceIsEditable(
                                                                    echeancierViewMode,
                                                                    echeancierEngagement.monthOfLatestEngagementInvoice,
                                                                    engagement,
                                                                    echeance,
                                                                )}
                                                                echeanceOutOfEngagementSchedule={checkIfEcheanceisOutOfEcheancier(
                                                                    engagement,
                                                                    echeance,
                                                                )}
                                                                permissionNeededToEdit={
                                                                    permissionNeededToEdit
                                                                }
                                                                echeancierViewMode={
                                                                    echeancierViewMode
                                                                }
                                                            />
                                                        );
                                                    },
                                                )}
                                                {editMode &&
                                                    echeancierViewMode ===
                                                        ECHEANCIER_MONTH_VIEW && (
                                                        <AddEcheanceLine
                                                            onClick={() =>
                                                                arrayHelpers.push(
                                                                    getNewEcheanceDefaultValues(
                                                                        formikProps.values
                                                                            .echeances,
                                                                    ),
                                                                )
                                                            }
                                                            data-testid="addEcheanceLine"
                                                        >
                                                            <Icon
                                                                name="AddBox"
                                                                color={colors.blue.B400}
                                                                style={{ marginRight: '1rem' }}
                                                            />
                                                            <LineText
                                                                type="H500"
                                                                size="0.875rem"
                                                                color={colors.neutral.N400}
                                                            >
                                                                {t(
                                                                    'echeancierEngagement.addEcheance',
                                                                )}
                                                            </LineText>
                                                        </AddEcheanceLine>
                                                    )}
                                            </>
                                        )}
                                    />
                                </Echeances>
                            </Container>
                        </Modal>
                    );
                }}
            </Formik>
            {isSwitchToEditModeModalOpen && !hasUserSwitchedEcheancierToEditMode && (
                <Modal
                    onCloseButtonPressed={() => setIsSwitchToEditModeModalOpen(false)}
                    title={t('general.irreversibleModification')}
                    iconName="Warning"
                    iconColor={colors.yellow.Y400}
                    specificWidth="25rem"
                    buttons={[
                        {
                            text: t('general.cancel'),
                            aspect: 'secondary',
                            'data-testid': 'cancel',
                            onClick() {
                                setIsSwitchToEditModeModalOpen(false);
                            },
                        },
                        {
                            text: t('general.confirm'),
                            aspect: 'primary',
                            'data-testid': 'confirm',
                            onClick() {
                                handleSwitchToEditModeConfirmation();
                            },
                        },
                    ]}
                    shouldRenderGlobalStyle={false}
                    isOpen
                >
                    <Text style={{ marginBottom: '2rem', whiteSpace: 'pre-line' }}>
                        {t('echeancierEngagement.switchToEditMode')}
                    </Text>
                </Modal>
            )}
        </>
    ) : (
        <Loader overlay />
    );
};

export default EcheancierEngagementModal;
