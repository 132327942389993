import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { colors } from '../constants/colors';

import type { IconName } from './Icon';
import Icon from './Icon';
import SidebarSubLinks from './SidebarSubLinks';
import type { Permission } from '../slices/authSlice';

type ElemProps = {
    sub?: string;
    iscollapsed: string;
};

const StyledNavLink = styled(NavLink)<ElemProps>`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: ${(props) => (props.iscollapsed === 'true' ? '0 1.625rem' : '0 1.625rem')};
    margin: ${(props) => (props.sub ? '1.875rem 0' : '1.25rem 0')};
    color: ${(props) => (props.sub ? colors.neutral.N300 : colors.blue.B75)};
    ${(props) => Boolean(props.sub) && 'letter-spacing: 0.03em;'}
    font-family: 'Gilroy';
    font-weight: ${(props) => (props.sub ? '500' : '800')};
    font-size: ${(props) => (props.sub ? '0.875rem' : '1rem')};
    line-height: 1.1875rem;
    outline: none;
    align-items: center;
    ${(props) => props.iscollapsed === 'true' && 'justify-content: center;'}

    :visited {
        text-decoration: none;
        color: ${(props) => (props.sub ? colors.neutral.N300 : colors.blue.B75)};
        ${(props) => Boolean(props.sub) && 'margin-left: 1.5rem;'}
        ${(props) => Boolean(props.sub) && 'padding-left: 1rem;'}
        ${(props) => Boolean(props.sub) && 'letter-spacing: 0.03em;'}
        cursor: pointer;
    }

    :hover {
        font-family: 'Gilroy';
        color: ${(props) => (props.sub ? colors.blue.B400 : colors.other.white)};
        font-weight: ${(props) => (props.sub ? '500' : '800')};
        font-size: ${(props) => (props.sub ? '0.875rem' : '1rem')};
        line-height: 1.1875rem;
        ${(props) =>
            Boolean(props.sub) &&
            `
            ::before {
                content: "";
                width: 0.1875rem;
                height: 1.1875rem;
                background: ${colors.green.G400};
                position: absolute;
                z-index: 1;
                left: 0;
                border-radius: 0.3125rem;
            }
        `}
    }

    &.active {
        ${(props) =>
            Boolean(props.sub) &&
            `
            ::before {
                content: "";
                width: 0.1875rem;
                height: 1.1875rem;
                background: ${colors.green.G400};
                position: absolute;
                z-index: 1;
                left: 0;
                border-radius: 0.3125rem;
            }
        `}
        color: ${(props) => (props.sub ? colors.blue.B400 : colors.other.white)};
    }

    /* List Item Icon */
    svg {
        margin: ${(props) => (props.iscollapsed === 'true' ? 'margin: 0' : 'auto 1rem auto 0')};
    }

    :last-child {
        margin-bottom: 0;
    }
`;

const LinksContainer = styled.div`
    position: relative;
`;

export type ChildItem = {
    to: string;
    translationKey: string;
    permissionsRequired?: Permission[];
    organizationNeedsToBeClient?: boolean;
    isActiveIfExact?: boolean;
    activeMatchExclusions?: string[];
};

type Props = {
    translationKey: string;
    activeIcon: IconName;
    defaultIcon: IconName;
    activePaths: string[];
    to: string;
    childrenItems?: ChildItem[];
    isCollapsed: boolean;
};

const SidebarItem: React.FC<Props> = ({
    translationKey,
    to,
    defaultIcon,
    activeIcon,
    activePaths,
    childrenItems,
    isCollapsed,
}) => {
    const { t } = useTranslation();
    const location: { pathname: string } = useLocation();
    const [hovered, setHovered] = useState<boolean>(false);
    let stripedPathname = location.pathname;

    if (stripedPathname.substring(stripedPathname.length - 1) === '/') {
        stripedPathname = stripedPathname.substring(0, stripedPathname.length - 1);
    }

    return (
        <LinksContainer>
            <StyledNavLink
                to={to}
                className={() => (location.pathname.startsWith(to) ? 'active' : '')}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                iscollapsed={isCollapsed.toString()}
            >
                <Icon
                    name={
                        activePaths.includes(location.pathname) || hovered
                            ? activeIcon
                            : defaultIcon
                    }
                    color={
                        location.pathname.startsWith(to) || hovered
                            ? colors.green.G300
                            : colors.blue.B75
                    }
                    width="1.5rem"
                />
                {!isCollapsed && t(translationKey)}
            </StyledNavLink>
            {childrenItems && location.pathname.startsWith(to) && stripedPathname !== to && (
                <SidebarSubLinks childrenItems={childrenItems} startsWith={to} />
            )}
        </LinksContainer>
    );
};

export default SidebarItem;
