import { Collapse } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import type { Budget } from '../slices/budgetSlice';

// Components
import GridCol from './GridCol';
import GridRow from './GridRow';
import Icon from './Icon';
import Text from './Text';
import Toggle from './Toggle';

const Container = styled.div`
    border-radius: ${styles.borderRadiusSmall};
    background-color: ${colors.other.white};
    padding: 0.625rem 0 0.3125rem 0;
`;

type CollapseProps = {
    isOpen?: boolean;
};

const HeaderContainer = styled.div<CollapseProps>`
    padding: 0 1.375rem 0 1rem;
`;

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 1rem;
`;

const ToggleWrapper = styled.span`
    margin: 0 0.5rem;
`;

const SubtotalsAndTargetContainer = styled.div`
    padding: 0 1.375rem 0 1rem;
`;

const FooterContainer = styled.div`
    padding: 0 3rem;
`;

const GridColEntry = styled(GridCol)`
    display: flex;
    align-items: center;
`;

const GridColValue = styled(GridCol)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Separator = styled.div`
    margin: 0.25rem 0;
    background-color: ${colors.neutral.N75};
    height: 0.0625rem;
    width: 100%;
`;

const FooterDataValue = styled.div`
    margin: 0;
    color: ${colors.blue.B400};
`;

const EmptySpace = styled.div<{ width: string }>`
    width: ${(props) => props.width};
`;

export type Props = {
    budget: Budget;
    totalData: Record<string, React.ReactNode>;
    subtotalsData: Record<string, React.ReactNode>;
    targetData: Record<string, React.ReactNode>;
    footerData?: Record<string, React.ReactNode>;
    footerTrailingComponent?: React.ReactNode;
    bodyNode?: React.ReactNode;
    showTtcValues: boolean;
    setShowTtcValues: React.Dispatch<React.SetStateAction<boolean>>;
};

const BudgetInformationCard: React.FC<Props> = ({
    budget,
    totalData,
    subtotalsData,
    targetData,
    footerData,
    footerTrailingComponent,
    bodyNode,
    showTtcValues,
    setShowTtcValues,
}) => {
    const { t } = useTranslation();

    const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(true);

    const renderTotalData = ([entry, value]: [string, React.ReactNode], key: number) => (
        <React.Fragment key={key}>
            <GridColEntry defaultScreen={7} smallScreen={7}>
                <EmptySpace width="2rem" />
                <Text
                    type="H500"
                    style={{ margin: '0.5rem 0' }}
                    color={colors.blue.B400}
                    size="0.875rem"
                >
                    {entry}
                </Text>
            </GridColEntry>
            <GridColValue defaultScreen={5} smallScreen={5}>
                {value}
            </GridColValue>
        </React.Fragment>
    );
    const renderSubtotalsData = ([entry, value]: [string, React.ReactNode], key: number) => (
        <React.Fragment key={key}>
            <GridColEntry defaultScreen={7} smallScreen={7}>
                <EmptySpace width="2rem" />
                <Text
                    type="H500"
                    style={{ margin: '0.5rem 0 0.5rem 1rem', fontWeight: 400 }}
                    color={colors.blue.B400}
                    size="0.875rem"
                >
                    {entry}
                </Text>
                {key === renderSubtotalsData.length - 1 && bodyNode}
            </GridColEntry>
            <GridColValue defaultScreen={5} smallScreen={5}>
                {value}
            </GridColValue>
        </React.Fragment>
    );
    const renderTargetAndDifferenceData = (
        [entry, value]: [string, React.ReactNode],
        key: number,
    ) => (
        <React.Fragment key={key}>
            <GridColEntry defaultScreen={7} smallScreen={7}>
                <EmptySpace width="2rem" />
                <Text
                    type="H500"
                    style={{ margin: '0.5rem 0' }}
                    color={colors.blue.B400}
                    size="0.875rem"
                >
                    {entry}
                </Text>
            </GridColEntry>
            <GridColValue defaultScreen={5} smallScreen={5}>
                {value}
            </GridColValue>
        </React.Fragment>
    );
    const renderFooterData = ([entry, value]: [string, React.ReactNode], key: number) => (
        <GridCol key={key} defaultScreen={3} smallScreen={3}>
            <Text style={{ marginBottom: '0.3125rem' }} color={colors.neutral.N300}>
                {entry}
            </Text>
            <FooterDataValue>{value}</FooterDataValue>
        </GridCol>
    );
    return (
        <Container>
            <HeaderContainer isOpen={isCollapseOpen}>
                <GridRow>
                    <GridColEntry defaultScreen={7} smallScreen={7}>
                        <Icon
                            name={isCollapseOpen ? 'Remove' : 'Add'}
                            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                        <Text
                            type="H500"
                            style={{
                                margin: '0 0 0 0.5rem',
                                cursor: 'pointer',
                                fontWeight: 700,
                            }}
                            color={colors.blue.B500}
                        >
                            {t('budget.title')}
                        </Text>
                        <ToggleContainer>
                            <Text
                                type="H400"
                                color={!showTtcValues ? colors.blue.B400 : colors.neutral.N300}
                                style={{ margin: 0 }}
                            >
                                {t('budget.ht')}
                            </Text>
                            <ToggleWrapper>
                                <Toggle
                                    onChange={(event) =>
                                        setShowTtcValues((event.target as HTMLInputElement).checked)
                                    }
                                    aspect="large"
                                    defaultChecked
                                    noMargin
                                />
                            </ToggleWrapper>
                            <Text
                                type="H400"
                                color={showTtcValues ? colors.blue.B400 : colors.neutral.N300}
                                style={{ margin: 0 }}
                            >
                                {t('budget.ttc')}
                            </Text>
                        </ToggleContainer>
                    </GridColEntry>
                    <GridColValue defaultScreen={5} smallScreen={5}>
                        <GridColValue defaultScreen={4} smallScreen={4}>
                            <Text
                                type="H400"
                                style={{ margin: '0.5rem 0' }}
                                color={colors.blue.B500}
                                size="0.6875rem"
                                uppercased
                            >
                                {t('budget.title')}
                            </Text>
                        </GridColValue>
                        {budget.status === 'approved' && (
                            <>
                                <GridColValue defaultScreen={3} smallScreen={3}>
                                    <Text
                                        type="H400"
                                        style={{ margin: '0.5rem 0' }}
                                        color={colors.blue.B500}
                                        size="0.6875rem"
                                    >
                                        %
                                    </Text>
                                </GridColValue>
                                <GridColValue defaultScreen={4} smallScreen={4}>
                                    <Text
                                        type="H500"
                                        style={{ margin: '0.5rem 0' }}
                                        color={colors.neutral.N400}
                                        size="0.6875rem"
                                        uppercased
                                    >
                                        {t('budget.engaged')}
                                    </Text>
                                </GridColValue>
                            </>
                        )}
                    </GridColValue>
                </GridRow>
                <GridRow>{Object.entries(totalData).map(renderTotalData)}</GridRow>
            </HeaderContainer>
            <Collapse isOpen={isCollapseOpen}>
                <SubtotalsAndTargetContainer>
                    <GridRow>{Object.entries(subtotalsData).map(renderSubtotalsData)}</GridRow>
                    <GridRow>
                        {Object.entries(targetData).map(renderTargetAndDifferenceData)}
                    </GridRow>
                </SubtotalsAndTargetContainer>
                {footerData && (
                    <>
                        <Separator />
                        <FooterContainer>
                            <GridRow
                                style={{
                                    borderRadius: `0 0 ${styles.borderRadiusSmall} ${styles.borderRadiusSmall}`,
                                }}
                            >
                                {Object.entries(footerData).map(renderFooterData)}
                                {footerTrailingComponent}
                            </GridRow>
                        </FooterContainer>
                    </>
                )}
            </Collapse>
        </Container>
    );
};

export default BudgetInformationCard;
