import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import Check from '../assets/images/check_circle.svg';
import Uncheck from '../assets/images/uncheck_circle.svg';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 0 1rem 0;
    img {
        width: 1rem;
    }
`;

const Text = styled.p`
    font-family: Rubik;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${colors.blue.B400};
    margin: 0 0 0 1rem;
`;

type Props = {
    text: string;
    isValid: boolean;
    style?: React.CSSProperties;
};

const PasswordValidation: React.FC<Props> = ({ text, isValid, style, ...props }) => {
    const { t } = useTranslation();
    return (
        <Container {...props} style={style}>
            {isValid ? (
                <img src={Check} alt={t('altMessage.check')} />
            ) : (
                <img src={Uncheck} alt={t('altMessage.uncheck')} />
            )}
            <Text>{text}</Text>
        </Container>
    );
};

export default PasswordValidation;
