import { useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { CurrencyFormatter } from '../utils/formatters';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import GridRow from './GridRow';
import GridCol from './GridCol';
import Text from './Text';
import Icon from './Icon';

import {
    selectImportStatus,
    selectImportMarkets,
    selectImportEngagements,
} from '../slices/invoiceSlice';
import type {
    EngagementInvoiceFromImport,
    MarketFromImport,
    EngagementFromImport,
    ImportResultType,
} from '../slices/common';

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 80vh;
`;

const HeaderRow = styled(GridRow)`
    width: 100%;
    background-color: ${colors.neutral.N75};
    line-height: 1.5rem;
    border-radius: ${styles.borderRadiusSmall};
`;

const HeaderText = styled(Text)`
    margin: 0;
    line-height: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
`;

const MarketRow = styled(GridRow)<{ isWarning: boolean }>`
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    width: 100%;
`;

const InvoiceRow = styled(GridRow)`
    width: 100%;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: solid 0.0625rem ${colors.neutral.N75};
    line-height: 2rem;
`;

type Props = {
    importMessage: ImportResultType['message'];
    importServerError: string | null;
};

const ImportInvoicesResultModal: React.FC<Props> = ({ importMessage, importServerError }) => {
    const { t } = useTranslation();
    const status = useSelector(selectImportStatus);
    const markets = useSelector(selectImportMarkets);
    const engagements = useSelector(selectImportEngagements) ?? null;

    const renderWarning = () =>
        importMessage ? (
            <Text type="H500" style={{ margin: '0 0 2rem 0' }}>
                {t(importMessage)}
            </Text>
        ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment -- necessary
            <></>
        );

    const renderErrors = (serverError?: string | null) => (
        <>
            <Text type="H500" style={{ margin: '0 0 2rem 0' }}>
                {t('invoices.importError')}
            </Text>
            {serverError && <Text>{t(serverError)}</Text>}
            {markets?.map(
                (market: MarketFromImport) =>
                    market.error && (
                        <GridRow
                            style={{ marginBottom: '0.5rem', marginLeft: '0rem', width: '100%' }}
                        >
                            <Icon name="Error" color={colors.red.R500} width="1.5rem" />
                            <Text
                                type="H400"
                                style={{ margin: '0 0 0 0.5rem', lineHeight: '1.6rem' }}
                            >
                                {t('invoices.marketLabel', {
                                    label: `${market.externalNumber} ${market.sliceCode ?? ''} `,
                                })}
                                <span style={{ fontWeight: 400 }}>{t(market.error || '')}</span>
                            </Text>
                        </GridRow>
                    ),
            )}
            {engagements?.map(
                (engagement: EngagementFromImport) =>
                    engagement.error && (
                        <GridRow
                            style={{ marginBottom: '0.5rem', marginLeft: '0rem', width: '100%' }}
                        >
                            <Icon name="Error" color={colors.red.R500} width="1.5rem" />
                            <Text
                                type="H400"
                                style={{ margin: '0 0 0 0.5rem', lineHeight: '1.6rem' }}
                            >
                                {t('invoices.engagementLabel', {
                                    label: `${engagement.label} ${engagement.externalNumber}`,
                                })}{' '}
                                <span style={{ fontWeight: 400 }}>{t(engagement.error || '')}</span>
                            </Text>
                        </GridRow>
                    ),
            )}
        </>
    );

    const warningHeader = (
        <HeaderRow>
            <GridCol smallScreen={1} defaultScreen={1}>
                <HeaderText type="H100" uppercased>
                    {t('invoices.header.number')}
                </HeaderText>
            </GridCol>
            <GridCol smallScreen={3} defaultScreen={3}>
                <HeaderText type="H100" uppercased>
                    {t('invoices.header.label')}
                </HeaderText>
            </GridCol>
            <GridCol smallScreen={2} defaultScreen={2}>
                <HeaderText type="H100" uppercased>
                    {t('invoices.header.nature')}
                </HeaderText>
            </GridCol>
            <GridCol smallScreen={2} defaultScreen={2}>
                <HeaderText type="H100" uppercased>
                    {t('invoices.header.htAmount')}
                </HeaderText>
            </GridCol>
            <GridCol smallScreen={4} defaultScreen={4}>
                <HeaderText type="H100" uppercased>
                    {t('invoices.header.error')}
                </HeaderText>
            </GridCol>
        </HeaderRow>
    );

    const marketContainsEngagementInvoicesWithErrors = (market: MarketFromImport): boolean =>
        Boolean(market.engagementInvoices?.find((engagementInvoice) => engagementInvoice.error));

    const renderImportedInvoices = () =>
        markets?.map((market: MarketFromImport) => {
            const nbMarketInvoices = market.marketInvoices?.length;
            const nbEngagementInvoices = market.engagementInvoices?.length;
            const isWarning = marketContainsEngagementInvoicesWithErrors(market);

            if (nbEngagementInvoices && nbEngagementInvoices > 0) {
                const marketLabel = t('invoices.marketLabel', {
                    label: `${market.externalNumber} ${market.sliceCode ?? ''} : `,
                });

                return (
                    <>
                        <MarketRow isWarning>
                            {isWarning ? (
                                <Icon name="Warning" color={colors.yellow.Y500} width="1.5rem" />
                            ) : (
                                <Icon name="CheckCircle" color={colors.green.G400} width="1.5rem" />
                            )}
                            <Text
                                type="H500"
                                style={{ margin: '0 0 0 0.5rem', lineHeight: '1.8rem' }}
                            >
                                {marketLabel}
                                <span
                                    style={{
                                        fontWeight: 500,
                                        fontFamily: 'Rubik',
                                        fontSize: '0.875rem',
                                    }}
                                >
                                    {nbMarketInvoices}{' '}
                                    {t('invoices.importedInvoices', {
                                        count: nbMarketInvoices,
                                    })}
                                </span>
                            </Text>
                        </MarketRow>
                        {isWarning && warningHeader}
                        {isWarning &&
                            market.engagementInvoices?.map(
                                (invoice: EngagementInvoiceFromImport) =>
                                    invoice.error && (
                                        <InvoiceRow>
                                            <GridCol smallScreen={1} defaultScreen={1}>
                                                <Text type="H400" style={{ fontWeight: 300 }}>
                                                    {invoice.displayNumber}
                                                </Text>
                                            </GridCol>
                                            <GridCol smallScreen={3} defaultScreen={3}>
                                                <Text type="H400" style={{ fontWeight: 300 }}>
                                                    {invoice.engagementLabel}
                                                </Text>
                                            </GridCol>
                                            <GridCol smallScreen={2} defaultScreen={2}>
                                                <Text type="H400" style={{ fontWeight: 300 }}>
                                                    {t(`sidebar.${invoice.nature}`)}
                                                </Text>
                                            </GridCol>
                                            <GridCol smallScreen={2} defaultScreen={2}>
                                                <Text
                                                    type="H400"
                                                    style={{ fontWeight: 300 }}
                                                    isNumeral
                                                >
                                                    {CurrencyFormatter.format(invoice.amountHt)}
                                                </Text>
                                            </GridCol>
                                            <GridCol smallScreen={4} defaultScreen={4}>
                                                {invoice.error && (
                                                    <GridRow style={{ marginTop: '0.5rem' }}>
                                                        <Icon
                                                            name="Warning"
                                                            color={colors.neutral.N300}
                                                            width="1.5rem"
                                                        />
                                                        <Text
                                                            style={{
                                                                margin: '0 0 0 0.5rem',
                                                                lineHeight: '1.7rem',
                                                                fontWeight: 300,
                                                            }}
                                                            type="H400"
                                                        >
                                                            {t(String(invoice.error))}
                                                        </Text>
                                                    </GridRow>
                                                )}
                                            </GridCol>
                                        </InvoiceRow>
                                    ),
                            )}
                    </>
                );
            } else {
                return null;
            }
        });

    return (
        <ModalContentWrapper>
            {status === 'success' && renderImportedInvoices()}
            {status === 'warning' && renderWarning()}
            {status === 'error' && renderErrors()}
            {importServerError && renderErrors(importServerError)}
        </ModalContentWrapper>
    );
};

export default ImportInvoicesResultModal;
