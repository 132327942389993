import axios from 'axios';

import type { TvaCode, TvaCodeFormData } from '../slices/tvaCodeSlice';

const NB_TVA_CODES = 3;

const emptyTvaCode: TvaCodeFormData = {
    value: '',
    isUsedInDb: false,
};

export const createEmptyTvaCodesForInputs = (): TvaCodeFormData[] => {
    const emptyTvaCodes: TvaCodeFormData[] = [];
    for (let i = 0; i < NB_TVA_CODES; i++) {
        emptyTvaCodes.push(emptyTvaCode);
    }
    return emptyTvaCodes;
};

const checkIfTvaCodeIsUsed = async (tvaCode: TvaCode): Promise<boolean> => {
    const result = await axios.get(`/tva-codes/${tvaCode.id}/check-if-used`);
    return result.data.data;
};

const computeTvaCode = async (tvaCode: TvaCode): Promise<TvaCodeFormData> => {
    const isUsedInDb: boolean = await checkIfTvaCodeIsUsed(tvaCode);
    return {
        ...tvaCode,
        value: tvaCode.value || tvaCode.value === 0 ? Number(tvaCode.value * 100).toFixed(2) : null,
        isUsedInDb,
    };
};

export const createTvaCodesForInputs = async (tvaCodes: TvaCode[]): Promise<TvaCodeFormData[]> => {
    const computedTvaCodesPromises = tvaCodes.map((tvaCode) => computeTvaCode(tvaCode));
    const computedTvaCodes: TvaCodeFormData[] = await Promise.all(computedTvaCodesPromises);
    const nbMissingTvaCodes = NB_TVA_CODES - computedTvaCodes.length;
    if (nbMissingTvaCodes > 0) {
        for (let i = computedTvaCodes.length; i < NB_TVA_CODES; i++) {
            computedTvaCodes.push(emptyTvaCode);
        }
    }
    return computedTvaCodes;
};
