import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import IframeResizer from 'iframe-resizer-react';

import { colors } from '../constants/colors';

const Container = styled.div`
    height: calc(100% - 2rem);
    width: 100%;
    background-color: ${colors.blue.B50};
    margin: 0 1rem 1rem 1rem;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1.5625rem;
        background-color: ${colors.neutral.N50};
        top: -1.5625rem;
        position: relative;
    }
`;

type Props = {
    operationId?: string;
};

const Dashboard: React.FC<Props> = ({ operationId }) => {
    const [url, setUrl] = useState<string>('');

    useEffect(() => {
        axios
            .get(
                operationId
                    ? `/operations/${operationId}/serenytics-dashboard-url`
                    : `/operations/serenytics-dashboard-url`,
            )
            .then(({ data }) => {
                setUrl(data as string);
            })
            .catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every changes
    }, [axios]);

    return (
        <Container>
            <IframeResizer
                src={url}
                width="100%"
                height="100%"
                frameBorder="0"
                id="my_dashboard"
                title="Dashboard"
                heightCalculationMethod="bodyScroll"
                scrolling
                log
            />
        </Container>
    );
};

export default Dashboard;
