import 'react-app-polyfill/ie11'; // This must be the first line in src/index.js
import 'react-app-polyfill/stable';

import type { Canceler, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import jwtDecode from 'jwt-decode';
import camelcaseKeys from 'camelcase-keys';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import type { IPermissionToken, IIsAdminToken } from './slices/authSlice';
import { setPermissions, setIsAdmin, logout } from './slices/authSlice';
import { getNewTokens } from './utils/tokens';

import * as serviceWorker from './serviceWorker';
import store from './store';

import './i18n';

// CSS reset
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import { GlobalStyle } from './constants/globalStyles';
import App from './App';
import type { ServerError } from './slices/common';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const token: string | null = localStorage.getItem('access_token');
const permissionToken: string | null = localStorage.getItem('permission_token');
const isAdminToken: string | null = localStorage.getItem('is_admin_token');
if (token && permissionToken && isAdminToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const permissionTokenDecoded: IPermissionToken = jwtDecode(permissionToken);
    const isAdminTokenDecoded: IIsAdminToken = jwtDecode(isAdminToken);
    store.dispatch(setPermissions(permissionTokenDecoded.permissions));
    store.dispatch(setIsAdmin(isAdminTokenDecoded.isAdmin));
}

axios.defaults.validateStatus = (status) => status < 400;

const CancelToken = axios.CancelToken;
let cancel: Canceler | null = null;
let previousRoute: { method: string | undefined; url: string | undefined } = {
    method: '',
    url: '',
};

// Add a request interceptor
axios.interceptors.request.use(
    (config) => {
        const urlsToSkip = [
            '/auth/logout',
            '/auth/refresh',
            '/auth/login',
            '/users/send-password-reset',
            '/users/reset-password',
            '/users/finish-account-setup',
        ];

        if (
            config.url &&
            !urlsToSkip.includes(config.url) &&
            // for our urls, config.url not contain env.REACT_APP_API_URL for exemple config.url = /auth/logout
            // so we check if config.url includes 'http' to exclude all others urls
            !config.url.includes('http')
        ) {
            const accessToken = localStorage.getItem('access_token');

            config.headers.Authorization = `Bearer ${accessToken}`;
        } else if (config.url?.includes('http')) {
            config.headers.Authorization = null;
        }

        if (
            previousRoute.method === config.method &&
            previousRoute.url?.split('?')[0] === config.url?.split('?')[0] &&
            config.url !== '/auth/refresh' &&
            !config.url?.includes('/simulation')
        ) {
            cancel?.('request cancelled');
            config.cancelToken = new CancelToken((c) => {
                cancel = c;
            });
        }

        previousRoute = {
            url: config.url,
            method: config.method,
        };

        return config;
    },
    (error) => {
        console.error(error);
        // Do something with request error
        return Promise.reject(error);
    },
);

// Axios response interceptor
axios.interceptors.response.use(
    (response) => {
        const isPlanningUrl =
            Boolean(response.config.url?.includes('planning')) ||
            Boolean(response.config.url?.includes('lines/links')) ||
            Boolean(response.config.url?.includes('markets/links'));
        if (!isPlanningUrl) {
            // Forcing returned type because `camelcaseKeys` method is potentially returning `Partial<RawAxiosHeaders>`
            // on the key `headers` which is not accepted as a returned value of the interceptor method
            // The library `camelcase-keys` doesn't handle types properly
            // https://github.com/sindresorhus/camelcase-keys/issues/60
            return camelcaseKeys(response, { deep: true }) as AxiosResponse<unknown, unknown>;
        }
        return response;
    },
    async (error: AxiosError) => {
        if (axios.isCancel(error)) {
            return Promise.reject({
                response: {
                    status: 499,
                    data: { message: error.message, translationKey: 'errors.status499' },
                },
            });
        }
        const err = error as AxiosError<ServerError>;
        const originalRequest: AxiosRequestConfig & { _retry?: boolean } =
            err.config as AxiosRequestConfig;

        if (
            err.response?.status &&
            err.response.status === 401 &&
            originalRequest.url &&
            (originalRequest.url.includes('/auth/login') ||
                originalRequest.url.includes('/auth/refresh')) &&
            !originalRequest.url.includes('http') &&
            !originalRequest.url.includes('/auth/login-as-user')
        ) {
            await Promise.resolve(store.dispatch(logout(true)));
            return Promise.reject(error);
        }

        if (err.response?.status && err.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken = localStorage.getItem('refresh_token');
            await getNewTokens(refreshToken);

            return axios(originalRequest);
        }

        if (!err.response) {
            return Promise.reject(error);
        }

        // Do something with response error
        if (!err.response.data.translationKey) {
            const message =
                err.response.data.message ??
                `https://developer.mozilla.org/fr/docs/Web/HTTP/Status/${err.response.status}`;
            return Promise.reject({
                response: {
                    status: err.response.status,
                    data: {
                        message,
                        translationKey: `errors.status${err.response.status}`,
                    },
                },
            });
        } else {
            return Promise.reject(error);
        }
    },
);

/* Sentry config */
if (
    process.env.REACT_APP_ENVIRONMENT === 'production' ||
    process.env.REACT_APP_ENVIRONMENT === 'preprod' ||
    process.env.REACT_APP_ENVIRONMENT === 'staging'
) {
    // Array of Route Config Objects
    const TRANSACTION_ROUTES_TO_INGORE = [
        {
            path: '/login',
        },
        {
            path: '/inactivity-logout',
        },
        {
            path: '/signup/:token',
        },
        {
            path: '/password-reset/:token',
        },
        {
            path: '/forgot-password',
        },
        {
            path: '/reset-password-email-sent',
        },
        {
            path: '/password-changed',
        },
    ];
    const checkIfShouldDropTransaction = (transactionName: string) => {
        const transactionNameContainsAPathToIgnore = TRANSACTION_ROUTES_TO_INGORE.some(
            (transactionNameToIgnore) => transactionName === transactionNameToIgnore.path,
        );
        return transactionNameContainsAPathToIgnore;
    };

    Sentry.init({
        dsn: 'https://c2aefbd91fcf43b7a2b4c5ae9e601c81@o448886.ingest.sentry.io/5430968',
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampler(samplingContext) {
            const transactionName = samplingContext.transactionContext.name;
            if (checkIfShouldDropTransaction(transactionName)) {
                return 0;
            }
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            return process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
                ? Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
                : 1.0;
        },
        environment: process.env.REACT_APP_ENVIRONMENT,
    });
}
/* Sentry config */

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- necessary
const root = ReactDOM.createRoot(container! as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
            <GlobalStyle />
        </Provider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
