import React from 'react';

import type { QueryParams } from '../slices/common';
import SearchField from './SearchField';
import { stringSearch, siretSearch, siretInvalid } from '../utils/organizationsRegex';

type Props = {
    defaultValue?: string;
    setQueryParams: (value: React.SetStateAction<QueryParams>) => void;
    setIsSearchReset: (value: React.SetStateAction<boolean>) => void;
    setSearchError: (value: React.SetStateAction<string | null>) => void;
    setIsQueryCorrespondingToValidSiret?: (value: React.SetStateAction<boolean>) => void;
};

const OrganizationsSearch: React.FunctionComponent<Props> = ({
    defaultValue,
    setQueryParams,
    setIsSearchReset,
    setSearchError,
    setIsQueryCorrespondingToValidSiret,
}) => {
    const handleSearch = (value: string) => {
        const cleanedSiretSearch = value.replace(/\s+/g, '');

        if (stringSearch.test(value.toLowerCase())) {
            setIsQueryCorrespondingToValidSiret?.(false);
            // Warning: make sure to set the queryParams after in this case
            setQueryParams({
                page: 1,
                query: value,
                match: 'any',
                fields: ['denomination, denomination_usuelle, denomination_usuelle1'],
                orderBy: 'denomination',
            });
        } else if (siretSearch.test(cleanedSiretSearch)) {
            // Warning: make sure to set the queryParams before in this case
            setQueryParams({
                page: 1,
                query: cleanedSiretSearch,
                match: 'start',
                fields: ['siret'],
            });
            setIsQueryCorrespondingToValidSiret?.(true);
        }

        if (siretInvalid.test(cleanedSiretSearch) && !stringSearch.test(value.toLowerCase())) {
            setSearchError('errors.invalidSiret');
            setIsQueryCorrespondingToValidSiret?.(false);
            return;
        }
        setSearchError(null);

        if (value === '') {
            setSearchError(null);
            setIsQueryCorrespondingToValidSiret?.(false);
            setQueryParams({ page: 1 });
            setIsSearchReset(true);
            return;
        }
        setIsSearchReset(false);
    };

    return (
        <SearchField
            name="organizationResearch"
            data-testid="organizationsSearch"
            onSearch={handleSearch}
            defaultValue={defaultValue}
            autoFocus
        />
    );
};

export default OrganizationsSearch;
