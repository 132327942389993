import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';

import type { ServerError } from './common';
import { errorHandler } from './common';
import type { Budget } from './budgetSlice';
import type { Market } from './marketSlice';
import type { Operation } from './operationSlice';
import { colors } from '../constants/colors';

export interface PlanningItem {
    id: number;
    label: string;
    parent: number;
    start_date: string;
    end_date: string;
}

export interface PlanningLink {
    id: number;
    target: number;
    source: number;
    type: number; // https://docs.dhtmlx.com/gantt/api__gantt_links_config.html { "finish_to_start":"0", "start_to_start":"1", "finish_to_finish":"2", "start_to_finish":"3" }
    isAutomatic?: boolean;
    color?: string;
    parent: number | null;
}

export type BudgetPlanningItem = PlanningItem & {
    budget_id: Budget['id'];
    index: string;
    is_parent: boolean;
};
export type EngagementPlanningItem = PlanningItem & {
    market_id: Market['id'];
    internal_number: string;
};

type PlanningState = {
    loading: boolean;
    error: string | null;
    itemsById: Record<number, BudgetPlanningItem>;
    linksById: Record<number, PlanningLink>;
    warningIds: Array<PlanningItem['id']>;
};

export const initialState: PlanningState = {
    loading: false,
    error: null,
    itemsById: {},
    linksById: {},
    warningIds: [],
};

export const getPlanning = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { items: BudgetPlanningItem[]; links: PlanningLink[] },
    // First argument to the payload creator
    { operationId: Operation['id']; budgetId?: Budget['id'] },
    {
        rejectValue: ServerError;
    }
>('PLANNING/GET', async ({ operationId, budgetId }, { rejectWithValue }) => {
    try {
        let path = `/operations/${operationId}/markets/planning`;

        if (budgetId) {
            path = `/operations/${operationId}/budgets/${budgetId}/lines/planning`;
        }
        const response = await axios.get(path);

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }
        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const updatePlanningDates = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { items: BudgetPlanningItem[]; warningIds: Array<PlanningItem['id']> },
    // First argument to the payload creator
    {
        budgetLine?: BudgetPlanningItem;
        operationId: Operation['id'];
        engagementLine?: EngagementPlanningItem;
    },
    {
        rejectValue: ServerError;
    }
>(
    'PLANNING/DATES/PUT',
    async ({ operationId, budgetLine, engagementLine }, { rejectWithValue }) => {
        try {
            let path = '';
            if (engagementLine) {
                path = `/operations/${operationId}/markets/engagement/${engagementLine.id}/planning`;
            } else if (budgetLine) {
                path = `/operations/${operationId}/budgets/${budgetLine.budget_id}/lines/${budgetLine.id}/planning`;
            }

            const response = await axios.put(path, budgetLine ?? engagementLine);

            if (!response.data.data) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }
            return response.data.data;
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    },
);

export const createPlanningLink = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { items: BudgetPlanningItem[]; links: PlanningLink[]; warningIds: Array<PlanningItem['id']> },
    // First argument to the payload creator
    {
        link: Omit<PlanningLink, 'id' | 'parent'>;
        operationId: Operation['id'];
        budgetId?: Budget['id'];
    },
    {
        rejectValue: ServerError;
    }
>('PLANNING/LINK/POST', async ({ link, operationId, budgetId }, { rejectWithValue }) => {
    try {
        let path = `/operations/${operationId}/markets/links`;
        if (budgetId) {
            path = `/operations/${operationId}/budgets/${budgetId}/lines/links`;
        }
        const response = await axios.post(path, link);

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }
        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const deletePlanningLink = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { linkId: PlanningLink['id'] },
    // First argument to the payload creator
    { linkId: PlanningLink['id']; operationId: Operation['id']; budgetId?: Budget['id'] },
    {
        rejectValue: ServerError;
    }
>('PLANNING/LINK/DELETE', async ({ linkId, operationId, budgetId }, { rejectWithValue }) => {
    try {
        let path = `/operations/${operationId}/markets/links/${linkId}`;
        if (budgetId) {
            path = `/operations/${operationId}/budgets/${budgetId}/lines/links/${linkId}`;
        }
        const response = await axios.delete(path);

        if (response.status === 204) {
            return { linkId };
        }
        return rejectWithValue({
            message: 'Problem when delete link between budget lines',
            translationKey: 'errors.deleteBudgetPlanningLink',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const slice = createSlice({
    name: 'planning',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get one
        builder.addCase(getPlanning.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getPlanning.fulfilled, (state, { payload }) => {
            state.itemsById = {};
            state.linksById = {};
            payload.items.forEach((item: BudgetPlanningItem) => {
                state.itemsById[item.id] = item;
            });

            payload.links.forEach((link: PlanningLink) => {
                if (link.isAutomatic) {
                    link.color = colors.neutral.N200;
                }
                state.linksById[link.id] = link;
            });
            state.loading = false;
        });
        builder.addCase(getPlanning.rejected, errorHandler());
        // Update Planning dates
        builder.addCase(updatePlanningDates.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.warningIds = [];
        });
        builder.addCase(updatePlanningDates.fulfilled, (state, { payload }) => {
            payload.items.forEach((item: BudgetPlanningItem) => {
                state.itemsById[item.id] = item;
            });
            if (payload.warningIds.length > 0) {
                state.warningIds = payload.warningIds;
            }
            state.loading = false;
        });
        builder.addCase(updatePlanningDates.rejected, errorHandler());
        // Create Planning link
        builder.addCase(createPlanningLink.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.warningIds = [];
        });
        builder.addCase(createPlanningLink.fulfilled, (state, { payload }) => {
            payload.items.forEach((item: BudgetPlanningItem) => {
                state.itemsById[item.id] = item;
            });
            payload.links.forEach((link: PlanningLink) => {
                if (link.isAutomatic) {
                    link.color = colors.neutral.N200;
                }
                state.linksById[link.id] = link;
            });
            if (payload.warningIds.length > 0) {
                state.warningIds = payload.warningIds;
            }
            state.loading = false;
        });
        builder.addCase(createPlanningLink.rejected, errorHandler());
        // Delete Planning link
        builder.addCase(deletePlanningLink.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deletePlanningLink.fulfilled, (state, { payload }) => {
            deleteLinks(payload.linkId, state);
            state.loading = false;
        });
        builder.addCase(deletePlanningLink.rejected, errorHandler());
    },
});

const deleteLinks = (linkId: number, state: PlanningState) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
    delete state.linksById[linkId];

    Object.values(state.linksById).forEach(({ id, parent }) => {
        if (parent && parent === linkId) {
            deleteLinks(id, state);
        }
    });
};

export const selectPlanning = (state: RootState) => {
    const sortedData = Object.values(state.planning.itemsById).sort((a, b) => {
        if (a.index && b.index) {
            return a.index.localeCompare(b.index, undefined, {
                numeric: true,
            });
        } else {
            // If no index, keep same order of planning items
            return 0;
        }
    });
    return {
        data: sortedData,
        links: Object.values(state.planning.linksById),
    };
};
export const selectMinDate = (state: RootState) => {
    const sortedData = Object.values(state.planning.itemsById).sort((a, b) =>
        new Date(a.start_date)
            .getTime()
            .toString()
            .localeCompare(new Date(b.start_date).getTime().toString(), undefined, {
                numeric: true,
            }),
    );
    return sortedData[0].start_date;
};
export const selectMaxDate = (state: RootState) => {
    const sortedData = Object.values(state.planning.itemsById).sort((a, b) =>
        new Date(a.end_date)
            .getTime()
            .toString()
            .localeCompare(new Date(b.end_date).getTime().toString(), undefined, { numeric: true }),
    );
    return sortedData[sortedData.length - 1].end_date;
};
export const selectError = (state: RootState) => state.planning.error;
export const selectLoading = (state: RootState) => state.planning.loading;
export const selectWarningLines = (state: RootState) =>
    state.planning.warningIds.map((id) => state.planning.itemsById[id].label);

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
