import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type {
    PaginatedPayload,
    QueryParams,
    ServerError,
    ImportResultType,
    FileData,
} from './common';
import { errorHandler } from './common';
import type { IOrganization } from './organizationSlice';
import type { User } from './userSlice';
import type { LabelValue } from './labelSlice';
import type { Operation } from './operationSlice';

export type Warranty = {
    id: number;
    number: string;
    reference: string;
    issuer: string;
    date: string | null;
    amountTtc: number;
    fileId: number;
    marketId: Market['id'];
};

type MarketEntryMode = 'manual_entry' | 'ediflex_import';

export const MARKET_MANUAL_ENTRY: MarketEntryMode = 'manual_entry';
export const MARKET_EDIFLEX_IMPORT: MarketEntryMode = 'ediflex_import';

export type Market = {
    id: number;
    label: string;
    operationId: number;
    operation: Operation;
    internalNumber: string;
    externalNumber: string;
    parentMarket?: Market;
    parentMarketId: Market['id'] | null;
    isSlicedMarket: boolean;
    sliceCode: string | null;
    isJointGroupMarket: boolean;
    marketType: 'initial_market' | 'complementary_market';
    initialAmountHt: number;
    paymentDelay: number | null;
    projectOwnerId: IOrganization['id'];
    projectOwnerOrganization?: IOrganization;
    marketHolderId?: IOrganization['id'];
    marketHolder?: IOrganization;
    hasAdvancePayment: boolean;
    hasHoldback: boolean;
    holdbackDefaultPercentage?: number | null;
    hasDownPayment: boolean;
    plannedEndDate: string | null;
    plannedStartDate: string | null;
    worksReceptionDate: string | null;
    worksReceptionWithReserve: boolean;
    reserveRemovalDate: string | null;
    minutesEndDate: string | null;
    createdBy: number;
    createdByUser: User;
    updatedBy: number;
    updatedByUser: User;
    updatedAt: string | null;
    worksReceptionNotificationDate: string;
    labelValues: LabelValue[];
    natureOfServices: 'lump_sum_market' | 'purchase_order_market';
    warranties: Warranty[];
    marketEntryMode: MarketEntryMode;
    hasEngagements: boolean;
    hasMarketInvoices: boolean;
    hasOutdatedEcheancierEngagements: boolean;
    hasUnmatchedEngagements: boolean;
};

export interface MarketFormData {
    id?: number;
    label: string;
    internalNumber: string;
    externalNumber: string;
    operationId: number;
    parentMarketId?: Market['id'] | null;
    isSlicedMarket: boolean;
    sliceCode: string | null;
    isJointGroupMarket: boolean;
    marketType: 'initial_market' | 'complementary_market';
    initialAmountHt: number;
    paymentDelay: number | null;
    projectOwnerId: IOrganization['id'];
    marketHolderId?: IOrganization['id'];
    hasAdvancePayment: boolean;
    hasDownPayment: boolean;
    hasHoldback: boolean;
    holdbackDefaultPercentage?: number | null;
    worksReceptionDate: string | null;
    worksReceptionWithReserve: boolean;
    reserveRemovalDate: string | null;
    minutesEndDate: string | null;
    worksReceptionNotificationDate: string | null;
    labelValues: LabelValue[];
    natureOfServices: 'lump_sum_market' | 'purchase_order_market';
    warranties: Warranty[];
    marketEntryMode: 'manual_entry' | 'ediflex_import';
}

type MarketPayload = PaginatedPayload<Market>;

export type MarketForDropDown = Pick<Market, 'id' | 'internalNumber' | 'label'>;

type MarketState = {
    loading: boolean;
    error: string | null;
    marketsById: Record<number, Market>;
    marketsByOperationIdAndPage: Record<string, Record<number, Array<Market['id']>>>;
    queryString: string;
    totalMarkets: number;
    marketsForDropdown: MarketForDropDown[];
    marketIdAdded: Market['id'] | null;
    getMarketsLoading: boolean;
    importResult: ImportResultType | null;
    stringifiedFileDataToImport: string | null;
};

export const initialState: MarketState = {
    marketsById: {},
    loading: false,
    error: null,
    marketsByOperationIdAndPage: {},
    queryString: '',
    totalMarkets: 0,
    marketsForDropdown: [],
    marketIdAdded: null,
    getMarketsLoading: false,
    importResult: null,
    stringifiedFileDataToImport: null,
};

export const getMarket = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Market,
    // First argument to the payload creator
    { id: Market['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('MARKET/GET', async ({ id, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: Market }>(
            `/operations/${operationId}/markets/${id}`,
        );
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getMarkets = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    MarketPayload & { page: number; queryString: string; operationId: Operation['id'] | undefined },
    // First argument to the payload creator
    QueryParams,
    {
        rejectValue: ServerError;
    }
>(
    'MARKET/GETALL',
    async (
        { page = 1, query, match = 'any', fields, orderBy, order, pageSize = 12, operationId },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `&match=${match}`;
            if (query) {
                queryString += `&query=${query}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            if (order) {
                queryString += `&order=${order}`;
            }

            const path = `/operations/${operationId}/markets?page=${
                page - 1
            }&pageSize=${pageSize}${queryString}`;

            const response = await axios.get<MarketPayload>(path);

            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { ...response.data, page, queryString, operationId };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey || error.response?.status === 499) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    },
);

export const getMarketsForDropdown = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    MarketForDropDown[],
    // First argument to the payload creator
    { operationId: Operation['id']; marketHolderId?: IOrganization['id'] },
    {
        rejectValue: ServerError;
    }
>('MARKET/GET_ALL_FOR_DROPDOWN', async ({ operationId, marketHolderId }, { rejectWithValue }) => {
    try {
        let path = `/operations/${operationId}/markets/all-for-dropdown`;
        if (marketHolderId) {
            path += `?marketHolderId=${marketHolderId}`;
        }
        const response = await axios.get<{ data?: MarketForDropDown[] }>(path);

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const createMarket = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Market,
    // First argument to the payload creator
    MarketFormData,
    {
        rejectValue: ServerError;
    }
>('MARKET/POST', async (marketValues, { rejectWithValue }) => {
    try {
        const response = await axios.post<{ data?: Market; token: string }>(
            `/operations/${marketValues.operationId}/markets`,
            marketValues,
        );
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        if (response.data.token) {
            const newToken = response.data.token;
            // eslint-disable-next-line require-atomic-updates -- necessary
            axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
            localStorage.setItem('access_token', newToken);
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const importMarkets = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    ImportResultType,
    // First argument to the payload creator
    {
        fileData: FileData;
        importConfirmed: boolean;
        operationId?: Operation['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('MARKETS/IMPORT', async ({ fileData, importConfirmed, operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.post(
            `/operations/${operationId}/markets/import-markets-and-engagements`,
            {
                fileData,
                importConfirmed,
            },
        );

        if (!response.data) {
            return rejectWithValue({
                message: 'Problem when importing markets',
                translationKey: 'errors.importMarkets',
            });
        }

        if (
            response.data &&
            response.data.status === 'confirmation_needed' &&
            (!response.data.operations || response.data.operations.length === 0)
        ) {
            return rejectWithValue({
                message: 'Problem when importing markets',
                translationKey: 'errors.importMarkets',
            });
        }

        return {
            filename: response.data.filename,
            status: response.data.status,
            message: response.data.message,
            operations: response.data.operations,
            markets: response.data.markets,
            engagements: response.data.engagements ?? null,
        };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const updateMarket = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Market,
    // First argument to the payload creator
    MarketFormData,
    {
        rejectValue: ServerError;
    }
>('MARKET/PUT', async (market, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: Market }>(
            `/operations/${market.operationId}/markets/${market.id}`,
            market,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});
export const deleteMarket = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { id: Market['id']; operationId: Operation['id'] },
    // First argument to the payload creator
    { id: Market['id']; operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('MARKET/DELETE', async ({ id, operationId }, { rejectWithValue }) => {
    try {
        await axios.delete<{ data?: Market['id'] }>(`/operations/${operationId}/markets/${id}`);
        return { id, operationId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const checkExternalNumberAndOrSliceCodeValidity = async (
    operationId: Operation['id'],
    marketId: Market['id'],
    externalNumber: string,
    sliceCode?: string,
) => {
    let path = `/operations/${operationId}/markets/${marketId}/unique-check?externalNumber=${externalNumber}`;
    if (sliceCode) {
        path += `&sliceCode=${sliceCode}`;
    }
    const response = await axios.get(path);
    return response;
};

export const slice = createSlice({
    name: 'market',
    initialState,
    reducers: {
        resetImportState(state) {
            state.importResult = null;
            state.loading = false;
            state.error = null;
        },
        setStringifiedFileDataToImport(state, action) {
            state.stringifiedFileDataToImport = action.payload;
        },
        resetStringifiedFileDataToImport(state) {
            state.stringifiedFileDataToImport = null;
        },
    },
    extraReducers(builder) {
        // Get all
        builder.addCase(getMarkets.pending, (state, { meta }) => {
            state.loading = true;
            state.error = null;
            state.getMarketsLoading = true;
        });
        builder.addCase(getMarkets.fulfilled, (state, { payload }) => {
            if (payload.queryString !== state.queryString) {
                state.queryString = payload.queryString;
            }
            if (payload.data && payload.page) {
                const byPage: Array<Market['id']> = [];
                payload.data.forEach((item: Market) => {
                    state.marketsById[item.id] = item;
                    byPage.push(item.id);
                });
                if (payload.operationId) {
                    state.marketsByOperationIdAndPage[payload.operationId] = {};
                    state.marketsByOperationIdAndPage[payload.operationId][payload.page] = byPage;
                }
            }
            if (payload.data && payload.data.length === 0) {
                state.marketsById = {};
            }
            state.totalMarkets = payload.total;
            state.loading = false;
            state.error = null;
            state.getMarketsLoading = false;
        });
        builder.addCase(getMarkets.rejected, errorHandler());
        // get one market
        builder.addCase(getMarket.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getMarket.fulfilled, (state, { payload }) => {
            state.marketsById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getMarket.rejected, errorHandler());
        // get markets for dropdown
        builder.addCase(getMarketsForDropdown.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getMarketsForDropdown.fulfilled, (state, { payload }) => {
            state.marketsForDropdown = payload;
            state.loading = false;
        });
        builder.addCase(
            getMarketsForDropdown.rejected,
            errorHandler((state, action) => {
                state.marketsForDropdown = [];
            }),
        );
        // Create
        builder.addCase(createMarket.pending, (state) => {
            state.loading = true;
            state.marketIdAdded = null;
            state.error = null;
        });
        builder.addCase(createMarket.fulfilled, (state, { payload }) => {
            state.marketIdAdded = payload.id;
            state.error = null;
            state.marketsById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(createMarket.rejected, errorHandler());
        // Get all imported invoices
        builder.addCase(importMarkets.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.importResult = null;
        });
        builder.addCase(importMarkets.fulfilled, (state, { payload }) => {
            state.importResult = payload;
            state.error = null;
            state.loading = false;
        });
        builder.addCase(importMarkets.rejected, errorHandler());
        // Update
        builder.addCase(updateMarket.pending, (state) => {
            state.loading = true;
            state.marketIdAdded = null;
        });
        builder.addCase(updateMarket.fulfilled, (state, { payload }) => {
            state.marketsById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateMarket.rejected, errorHandler());
        // Delete
        builder.addCase(deleteMarket.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteMarket.fulfilled, (state, { payload }) => {
            state.loading = false;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- We want to go through the condition even if null
            if (state.marketsByOperationIdAndPage[payload.operationId] !== undefined) {
                const pageKeys = Object.keys(
                    state.marketsByOperationIdAndPage[payload.operationId],
                );
                pageKeys.forEach((key) => {
                    const page =
                        state.marketsByOperationIdAndPage[payload.operationId][Number(key)];
                    if (page.includes(payload.id)) {
                        state.marketsByOperationIdAndPage[payload.operationId][Number(key)].splice(
                            page.indexOf(payload.id),
                            1,
                        );
                    }
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.marketsById[payload.id];
            state.loading = false;
        });
        builder.addCase(deleteMarket.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.market.error;
export const selectMarketsByOperationIdAndPage =
    (operationId: string | undefined, page: number) => (state: RootState) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
        if (operationId && state.market.marketsByOperationIdAndPage[operationId]) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
            return state.market.marketsByOperationIdAndPage[operationId][page]?.map(
                (id: number) => state.market.marketsById[id],
            );
        }
        return [];
    };
export const selectMarket =
    (id: Market['id']) =>
    (state: RootState): Market | undefined =>
        state.market.marketsById[id];
export const selectMarkets = (state: RootState) => Object.values(state.market.marketsById);
export const selectGetMarketsLoading = (state: RootState) => state.market.getMarketsLoading;
export const selectTotalMarkets = (state: RootState) => state.market.totalMarkets;
export const selectIsLoading = (state: RootState) => state.market.loading;
export const selectMarketsForDropdown = (state: RootState) => state.market.marketsForDropdown;
export const selectMarketName =
    (id: Market['id'] | undefined) =>
    (state: RootState): string | null => {
        let name = '';
        if (id) {
            const market = selectMarket(id)(state);
            if (market?.externalNumber) {
                name += String(market.externalNumber);
            }
            if (market?.sliceCode) {
                name += `-${market.sliceCode} `;
            }
            if (market?.label) {
                name += ` ${market.label}`;
            }
        }
        return name;
    };
export const selectMarketInternalNumber =
    (id: Market['id'] | undefined) =>
    (state: RootState): string | null => {
        let internalNumber = null;
        if (id) {
            const market = selectMarket(id)(state);
            if (market?.internalNumber) {
                internalNumber = market.internalNumber;
            }
        }
        return internalNumber;
    };
export const selectMarketIdAdded = (state: RootState) => state.market.marketIdAdded;
export const selectMarketEntryMode =
    (id: Market['id'] | undefined) =>
    (state: RootState): string | null => {
        let marketEntryMode = null;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
        if (id && state.market.marketsById[id]) {
            marketEntryMode = state.market.marketsById[id].marketEntryMode;
        }
        return marketEntryMode;
    };
export const selectImportResult = (state: RootState) => state.market.importResult;
export const selectImportStatus = (state: RootState) => state.market.importResult?.status;
export const selectExcelFilename = (state: RootState): string | null =>
    state.market.importResult?.filename ?? null;
export const selectOperationsToConfirm = (state: RootState) =>
    state.market.importResult?.operations ?? null;
export const selectImportedMarkets = (state: RootState) => state.market.importResult?.markets;
export const selectImportedEngagements = (state: RootState) =>
    state.market.importResult?.engagements;
export const selectStringifiedFileDataToImport = (state: RootState) =>
    state.market.stringifiedFileDataToImport;

export const {
    resetImportState,
    setStringifiedFileDataToImport,
    resetStringifiedFileDataToImport,
} = slice.actions;

export default slice.reducer;
