import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { TFunction } from 'i18next';

import type { RootState } from '../store';
import type { QueryParams, PaginatedPayload, ServerError } from './common';
import { errorHandler } from './common';
import { selectUser } from './authSlice';
import type { Operation } from './operationSlice';

export type IOrganization = {
    id: string;
    activitePrincipale: string | null;
    anneeCategorieEntreprise: string | null;
    categorieEntreprise: string | null;
    categorieJuridique: string | null;
    codeCedex: string | null;
    codeCommune: string | null;
    codePaysEtranger: string | null;
    codePostal: string | null;
    complementAdresse: string | null;
    denomination: string | null;
    denominationUsuelle: string | null;
    denominationUsuelle1: string | null;
    distributionSpeciale: string | null;
    etablissementSiegeSiret: string | null;
    etablissementSiege?: IOrganization | null;
    indiceRepetition: string | null;
    libelleCedex: string | null;
    libelleCommune: string | null;
    libelleCommuneEtranger: string | null;
    libellePaysEtranger: string | null;
    libelleVoie: string | null;
    nic: string | null;
    numeroRcs?: string | null;
    numeroTva?: string | null;
    numeroVoie: string | null;
    siren: string | null;
    siret: string;
    typeVoie: string | null;
    estActif: boolean;
    estClient?: boolean | null;
    estEtranger: boolean;
    estPrive: boolean;
    estPublic: boolean;
    operationsAffected?: Operation[];
};

export interface IOrganizationFormData {
    id?: string;
    activitePrincipale: string | null;
    anneeCategorieEntreprise: string | null;
    categorieEntreprise: string | null;
    categorieJuridique: string | null;
    codeCedex: string | null;
    codeCommune: string | null;
    codePaysEtranger: string | null;
    codePostal: string | null;
    complementAdresse: string | null;
    denomination: string | null;
    denominationUsuelle: string | null;
    denominationUsuelle1: string | null;
    distributionSpeciale: string | null;
    etablissementSiegeSiret: string | null;
    etablissementSiege?: IOrganizationFormData | null;
    indiceRepetition: string | null;
    libelleCedex: string | null;
    libelleCommune: string | null;
    libelleCommuneEtranger: string | null;
    libellePaysEtranger: string | null;
    libelleVoie: string | null;
    nic: string | null;
    numeroVoie: string | null;
    siren: string | null;
    siret: string;
    typeVoie: string | null;
    estActif: boolean;
    estEtranger: boolean;
    estPrive: boolean;
    estPublic: boolean;
}

export interface IOrganizationEditableFormData {
    id: string;
    numeroRcs: string | null;
    numeroTva: string | null;
    estClient: boolean;
}

type OrganizationsPayload = PaginatedPayload<IOrganization>;

export type OrganizationForDropDown = Pick<
    IOrganization,
    'id' | 'estClient' | 'denomination' | 'denominationUsuelle' | 'denominationUsuelle1'
>;

export type OrganizationsByPageData = {
    page: number;
    organizations: IOrganization[];
    total?: number | null | undefined;
};

export type IOperationOrganization = {
    id?: number;
    operationId: Operation['id'];
    organizationId: IOrganization['id'];
    cantBeDeleted: boolean;
    affectationType:
        | 'client_previz'
        | 'maitrise_ouvrage'
        | 'maitrise_oeuvre'
        | 'travaux'
        | 'prestataires'
        | 'concessionnaires'
        | 'collectivites_services_publics'
        | 'titulaires'
        | 'autres';
};

export type OperationOrganizationsQueryParams = QueryParams & {
    affectationType?: IOperationOrganization['affectationType'] | 'no_selection';
    shouldOrderByAffectationType?: boolean;
};

export const AFFECTATION_TYPES: Array<IOperationOrganization['affectationType']> = [
    'client_previz',
    'maitrise_ouvrage',
    'maitrise_oeuvre',
    'travaux',
    'prestataires',
    'concessionnaires',
    'collectivites_services_publics',
    'titulaires',
    'autres',
];

export type AffectationTypeOption = {
    label: string;
    value: IOperationOrganization['affectationType'] | 'no_selection';
};

export type AffectationTypeData = {
    affectationType: IOperationOrganization['affectationType'] | null;
};

export const getAffectationTypeOptions = (t: TFunction) =>
    AFFECTATION_TYPES.reduce(
        (
            arr: AffectationTypeOption[],
            affectationType: IOperationOrganization['affectationType'],
        ) => [
            ...arr,
            {
                label: t(`operationOrganization.affectationTypes.${affectationType}`),
                value: affectationType,
            },
        ],
        [],
    );

export type OperationOrganizationPayload = IOperationOrganization & {
    id: number;
    organization: IOrganization;
};

type OperationOrganizationPaginatedPayload = PaginatedPayload<OperationOrganizationPayload>;
interface IOrganizationState {
    totalOrganizations: number;
    organizationsById: Record<string, IOrganization>;
    loading: boolean;
    getOrganizationsLoading: boolean;
    getOrganizationsForAffectationLoading: boolean;
    error: string | null;
    headOfficeAdded: boolean;
    organizationAdded: boolean;
    organizationsByPage: Record<number, string[] | null>;
    queryString: string;
    organizationsByIdForAffectation: Record<string, IOrganization>;
    organizationsForDropdown: OrganizationForDropDown[];
    organizationIdsByOperationId: Record<string, Array<IOrganization['id']>>;
    organizationIdsByOperationIdAndPage: Record<
        string,
        Record<number, OperationOrganizationPayload[]>
    >;
    affectOrganizationToOperationFulfilled: boolean;
    disaffectOrganizationFromOperationFulfilled: boolean;
    updateOperationOrganizationFulfulled: boolean;
}

export const initialState: IOrganizationState = {
    totalOrganizations: 0,
    organizationsById: {},
    organizationsByPage: {},
    loading: false,
    getOrganizationsLoading: false,
    getOrganizationsForAffectationLoading: false,
    error: null,
    headOfficeAdded: false,
    organizationAdded: false,
    queryString: '',
    organizationsForDropdown: [],
    organizationsByIdForAffectation: {},
    organizationIdsByOperationId: {},
    organizationIdsByOperationIdAndPage: {},
    affectOrganizationToOperationFulfilled: false,
    disaffectOrganizationFromOperationFulfilled: false,
    updateOperationOrganizationFulfulled: false,
};

export const getOrganizations = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    OrganizationsPayload & { page: number; queryString: string },
    // First argument to the payload creator
    QueryParams,
    {
        rejectValue: ServerError;
    }
>(
    'ORGANIZATION/GETALL',
    async (
        { page = 1, query, match = 'any', fields, orderBy, pageSize = 12 },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `&match=${match}`;
            if (query) {
                queryString += `&query=${query}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            const path = `/organizations?page=${page - 1}&pageSize=${pageSize}${queryString}`;

            const response = await axios.get<OrganizationsPayload>(path);

            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { ...response.data, page, queryString };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey || error.response?.status === 499) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            if (error.response?.status === 403) {
                return rejectWithValue({
                    status: 403,
                    translationKey: 'errors.noOrganizationYouHaveAccessTo',
                });
            }
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    },
);

export const getOrganizationsForDropdown = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    OrganizationForDropDown[],
    // First argument to the payload creator
    QueryParams['query'],
    {
        rejectValue: ServerError;
    }
>('ORGANIZATION/GET_ALL_FOR_DROPDOWN', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: OrganizationForDropDown[] }>(
            `/organizations/all-for-dropdown`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getOrganizationsForAffectation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    OrganizationsPayload & { page: number; queryString: string },
    // First argument to the payload creator
    QueryParams,
    {
        rejectValue: ServerError;
    }
>('ORGANIZATION/GET_ALL_FOR_AFFECTATION', async ({ query }, { rejectWithValue }) => {
    try {
        // Non editable query params for this specific method
        const page = 1;
        const match = 'start';
        const pageSize = 100;
        const fields = ['siret'];
        const orderBy = 'siret';
        const order = 'asc';

        let queryString = `&match=${match}`;
        if (query) {
            queryString += `&query=${query}`;
        }
        queryString += `&fields=[${fields.toString()}]`;

        queryString += `&orderBy=${orderBy}`;

        queryString += `&order=${order}`;
        const path = `/organizations?page=${
            page - 1
        }&pageSize=${pageSize}${queryString}&gaowpc=true`;

        const response = await axios.get<OrganizationsPayload>(path);

        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return { ...response.data, page, queryString };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getOrganization = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IOrganization,
    // First argument to the payload creator
    IOrganization['id'],
    {
        rejectValue: ServerError;
    }
>('ORGANIZATION/GET', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: IOrganization }>(`/organizations/${id}`);

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const createOrganization = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IOrganization,
    // First argument to the payload creator
    IOrganizationFormData,
    {
        rejectValue: ServerError;
    }
>('ORGANIZATION/POST', async (organizationValues, { rejectWithValue }) => {
    try {
        const response = await axios.post<{ data?: IOrganization }>(
            '/organizations',
            organizationValues,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const updateOrganization = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IOrganization,
    // First argument to the payload creator
    IOrganizationFormData | IOrganizationEditableFormData,
    {
        rejectValue: ServerError;
    }
>('ORGANIZATION/PUT', async (organizationValues, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: IOrganization }>(
            `/organizations/${organizationValues.id}`,
            organizationValues,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});
export const deleteOrganization = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IOrganization['id'],
    // First argument to the payload creator
    IOrganization['id'],
    {
        rejectValue: ServerError;
    }
>('ORGANIZATION/DELETE', async (id, { rejectWithValue }) => {
    try {
        await axios.delete<{ data?: IOrganization['id'] }>(`/organizations/${id}`);

        // We don't really care about the data returned here, as long as it is a 200
        return id;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const getOrganizationsByOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    OperationOrganizationPaginatedPayload & {
        page: number;
        queryString: string;
        operationId: Operation['id'] | undefined;
    },
    // First argument to the payload creator
    OperationOrganizationsQueryParams,
    {
        rejectValue: ServerError;
    }
>(
    'OPERATION_ORGANIZATION/GETALL',
    async (
        {
            page = 1,
            query,
            match = 'any',
            fields,
            orderBy = 'id',
            order = 'desc',
            pageSize = 12,
            operationId,
            affectationType,
            shouldOrderByAffectationType,
        },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `&match=${match}`;
            if (query) {
                queryString += `&query=${query}`;
            }
            if (fields) {
                queryString += `&fields=[${fields.toString()}]`;
            }
            if (orderBy) {
                queryString += `&orderBy=${orderBy}`;
            }
            if (shouldOrderByAffectationType) {
                queryString += `&shouldOrderByAffectationType=true`;
            }
            queryString += `&order=${order}`;

            if (
                affectationType &&
                Object.values<string>(AFFECTATION_TYPES).includes(affectationType)
            ) {
                queryString += `&affectationType=${affectationType}`;
            }

            const path = `/operations/${operationId}/organizations?page=${
                page - 1
            }&pageSize=${pageSize}${queryString}`;

            const response = await axios.get<OperationOrganizationPaginatedPayload>(path);

            if (!response.data.data || !Array.isArray(response.data.data)) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return { ...response.data, page, queryString, operationId };
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.data.translationKey || error.response?.status === 499) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    },
);

export const affectOrganizationToOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IOperationOrganization,
    // First argument to the payload creator
    IOperationOrganization,
    {
        rejectValue: ServerError;
    }
>('OPERATION_ORGANIZATION/POST', async (operationOrganizationValues, { rejectWithValue }) => {
    try {
        const response = await axios.post<{ data?: IOperationOrganization }>(
            `/operations/${operationOrganizationValues.operationId}/organizations`,
            operationOrganizationValues,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const disaffectOrganizationFromOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IOperationOrganization['id'],
    // First argument to the payload creator
    {
        operationOrganizationId: OperationOrganizationPayload['id'];
        operationId: OperationOrganizationPayload['operationId'];
    },
    {
        rejectValue: ServerError;
    }
>(
    'OPERATION_ORGANIZATION/DELETE',
    async ({ operationOrganizationId, operationId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete<{ data?: IOperationOrganization['id'] }>(
                `/operations/${operationId}/organizations/${operationOrganizationId}`,
            );
            if (response.status === 204) {
                return operationOrganizationId;
            }
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;

            if (error.response?.data.translationKey) {
                return rejectWithValue({
                    translationKey: error.response.data.translationKey,
                });
            }
            return rejectWithValue({
                message: 'Unexpected error',
                translationKey: 'errors.unexpectedError',
            });
        }
    },
);

export const updateOrganizationAffectationTypeToOperation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IOperationOrganization,
    // First argument to the payload creator
    IOperationOrganization,
    {
        rejectValue: ServerError;
    }
>('OPERATION_ORGANIZATION/UPDATE', async (operationOrganization, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: IOperationOrganization }>(
            `/operations/${operationOrganization.operationId}/organizations/${operationOrganization.id}`,
            operationOrganization,
        );
        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }
        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;

        if (error.response?.data.translationKey) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Unexpected error',
            translationKey: 'errors.unexpectedError',
        });
    }
});

export const slice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        gotUserOrganization(state, action: PayloadAction<IOrganization>) {
            state.organizationsById[action.payload.id] = action.payload;
        },
        resetOrganizationsByIdForAffectation(state) {
            state.organizationsByIdForAffectation = {};
        },
        resetError(state) {
            state.error = null;
        },
    },
    extraReducers(builder) {
        // Get all
        builder.addCase(getOrganizations.pending, (state) => {
            state.loading = true;
            state.getOrganizationsLoading = true;
            state.error = null;
        });
        builder.addCase(getOrganizations.fulfilled, (state, { payload }) => {
            if (payload.queryString !== state.queryString) {
                state.queryString = payload.queryString;
                state.organizationsByPage = {};
            }
            if (payload.data && payload.data.length > 0) {
                payload.data.forEach((item: IOrganization) => {
                    state.organizationsById[item.id] = item;
                });
                if (payload.page) {
                    state.organizationsByPage[payload.page] = payload.data.map(
                        (item: IOrganization) => item.id,
                    );
                }
            }
            state.totalOrganizations = payload.total;

            state.loading = false;
            state.getOrganizationsLoading = false;
        });
        builder.addCase(
            getOrganizations.rejected,
            errorHandler((state: IOrganizationState) => {
                state.getOrganizationsLoading = false;
            }),
        );
        // Get all for dropdown
        builder.addCase(getOrganizationsForDropdown.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getOrganizationsForDropdown.fulfilled, (state, { payload }) => {
            state.organizationsForDropdown = payload;
            state.loading = false;
        });
        builder.addCase(getOrganizationsForDropdown.rejected, errorHandler());
        // Get all for affectation
        builder.addCase(getOrganizationsForAffectation.pending, (state) => {
            state.loading = true;
            state.getOrganizationsForAffectationLoading = true;
            state.error = null;
        });
        builder.addCase(getOrganizationsForAffectation.fulfilled, (state, { payload }) => {
            if (payload.queryString !== state.queryString) {
                state.queryString = payload.queryString;
                state.organizationsByIdForAffectation = {};
            }
            if (payload.data && payload.data.length > 0) {
                payload.data.forEach((item: IOrganization) => {
                    state.organizationsByIdForAffectation[item.id] = item;
                });
            }
            state.loading = false;
            state.getOrganizationsForAffectationLoading = false;
        });
        builder.addCase(
            getOrganizationsForAffectation.rejected,
            errorHandler((state: IOrganizationState) => {
                state.getOrganizationsForAffectationLoading = false;
            }),
        );
        // Get one
        builder.addCase(getOrganization.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getOrganization.fulfilled, (state, { payload }) => {
            state.organizationsById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(getOrganization.rejected, errorHandler());
        // Create
        builder.addCase(createOrganization.pending, (state) => {
            state.loading = true;
            state.headOfficeAdded = false;
            state.organizationAdded = false;
            state.error = null;
        });
        builder.addCase(createOrganization.fulfilled, (state, { payload }) => {
            if (payload.etablissementSiege) {
                state.organizationsById[payload.etablissementSiege.id] = payload.etablissementSiege;
                state.headOfficeAdded = true;
                delete payload.etablissementSiege;
            } else {
                state.headOfficeAdded = false;
            }
            state.organizationsById[payload.id] = payload;
            state.organizationAdded = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(
            createOrganization.rejected,
            errorHandler((state, action) => {
                state.headOfficeAdded = false;
                state.organizationAdded = false;
            }),
        );
        // Update
        builder.addCase(updateOrganization.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateOrganization.fulfilled, (state, { payload }) => {
            state.organizationsById[payload.id] = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateOrganization.rejected, errorHandler());
        // Delete
        builder.addCase(deleteOrganization.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteOrganization.fulfilled, (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.organizationsById[payload];
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteOrganization.rejected, errorHandler());
        // Get all organization by operation
        builder.addCase(getOrganizationsByOperation.pending, (state, { meta }) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getOrganizationsByOperation.fulfilled, (state, { payload }) => {
            if (payload.queryString !== state.queryString) {
                state.queryString = payload.queryString;
            }
            if (payload.data && payload.data.length > 0 && payload.page) {
                const byPage: OperationOrganizationPayload[] = [];
                payload.data.forEach((item: OperationOrganizationPayload) => {
                    state.organizationsById[item.organization.id] = item.organization;
                    byPage.push(item);
                });
                if (payload.operationId) {
                    state.organizationIdsByOperationIdAndPage[payload.operationId] = {};
                    state.organizationIdsByOperationIdAndPage[payload.operationId][payload.page] =
                        byPage;
                }
            } else {
                state.organizationIdsByOperationIdAndPage = {};
            }
            state.totalOrganizations = payload.total;

            state.loading = false;
        });
        builder.addCase(getOrganizationsByOperation.rejected, errorHandler());
        // Affect an organization to an operation
        builder.addCase(affectOrganizationToOperation.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.affectOrganizationToOperationFulfilled = false;
        });
        builder.addCase(affectOrganizationToOperation.fulfilled, (state, { payload }) => {
            if (payload.operationId && payload.organizationId) {
                if (!state.organizationIdsByOperationId.hasOwnProperty(payload.operationId)) {
                    state.organizationIdsByOperationId[payload.operationId] = [];
                }
                state.organizationIdsByOperationId[payload.operationId].push(
                    payload.organizationId,
                );
            }

            state.loading = false;
            state.affectOrganizationToOperationFulfilled = true;
        });
        builder.addCase(affectOrganizationToOperation.rejected, errorHandler());
        // Disaffect an organization from an operation
        builder.addCase(disaffectOrganizationFromOperation.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.disaffectOrganizationFromOperationFulfilled = false;
        });
        builder.addCase(disaffectOrganizationFromOperation.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
            state.disaffectOrganizationFromOperationFulfilled = true;
        });
        builder.addCase(disaffectOrganizationFromOperation.rejected, errorHandler());
        builder.addCase(updateOrganizationAffectationTypeToOperation.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.updateOperationOrganizationFulfulled = false;
        });
        builder.addCase(
            updateOrganizationAffectationTypeToOperation.fulfilled,
            (state, { payload }) => {
                if (payload.operationId && payload.organizationId) {
                    if (!state.organizationIdsByOperationId.hasOwnProperty(payload.operationId)) {
                        state.organizationIdsByOperationId[payload.operationId] = [];
                    }
                    state.organizationIdsByOperationId[payload.operationId].push(
                        payload.organizationId,
                    );
                }

                state.loading = false;
                state.updateOperationOrganizationFulfulled = true;
            },
        );
        builder.addCase(updateOrganizationAffectationTypeToOperation.rejected, errorHandler());
    },
});

export const getOrganizationName = (
    organization: OrganizationForDropDown | IOrganization | null | undefined,
) => {
    let name = null;
    if (organization) {
        if (organization.denomination) {
            name = organization.denomination;
        } else if (organization.denominationUsuelle) {
            name = organization.denominationUsuelle;
        } else if (organization.denominationUsuelle1) {
            name = organization.denominationUsuelle1;
        }
    }
    return name;
};

export const selectError = (state: RootState) => state.organization.error;
export const selectOrganizations = (state: RootState) =>
    Object.values(state.organization.organizationsById);
export const selectOrganizationsForDropdown = (state: RootState) =>
    Object.values(state.organization.organizationsForDropdown);
export const selectClientOrganizationsForDropdown = (state: RootState) => {
    const clientOrganizations = state.organization.organizationsForDropdown.filter(
        (organization) => organization.estClient === true,
    );
    return Object.values(clientOrganizations);
};
export const selectUserOrganization = (state: RootState): IOrganization | null => {
    const user = selectUser(state);

    if (user) {
        return selectOrganization(user.organizationId)(state);
    }

    return null;
};
export const selectOrganization = (id: IOrganization['id'] | undefined | null) =>
    function (state: RootState): IOrganization | null {
        return id ? state.organization.organizationsById[id] : null;
    };
export const selectOrganizationName =
    (id: IOrganization['id'] | undefined | null) =>
    (state: RootState): string | null => {
        let name = null;

        if (id) {
            const organization = selectOrganization(id)(state);
            name = getOrganizationName(organization);
        }
        return name;
    };
export const selectHeadOfficeAdded = (state: RootState) => state.organization.headOfficeAdded;
export const selectOrganizationAdded = (state: RootState) => state.organization.organizationAdded;
export const selectTotalOrganizations = (state: RootState) => state.organization.totalOrganizations;
export const selectOrganizationsByPage = (page: number) => (state: RootState) =>
    state.organization.organizationsByPage[page]?.map(
        (id: string) => state.organization.organizationsById[id],
    ) ?? [];
export const selectIsLoading = (state: RootState) => state.organization.loading;
export const selectGetOrganizationsLoading = (state: RootState) =>
    state.organization.getOrganizationsLoading;
export const selectGetOrganizationsForAffectationLoading = (state: RootState) =>
    state.organization.getOrganizationsForAffectationLoading;
export const selectOrganizationsByOperationIdAndPage =
    (operationId: string | undefined, page: number) =>
    (state: RootState): OperationOrganizationPayload[] => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always truthy
        if (operationId && state.organization.organizationIdsByOperationIdAndPage[operationId]) {
            return state.organization.organizationIdsByOperationIdAndPage[operationId][page].map(
                (operationOrganization) => operationOrganization,
            );
        }
        return [];
    };
export const selectOrganizationsForAffectation = (state: RootState) =>
    Object.values(state.organization.organizationsByIdForAffectation);
export const selectAffectOrganizationToOperationFulfilled = (state: RootState) =>
    state.organization.affectOrganizationToOperationFulfilled;
export const selectDisaffectOrganizationToOperationFulfilled = (state: RootState) =>
    state.organization.disaffectOrganizationFromOperationFulfilled;
export const selectUpdateOperationOrganizationFulfulled = (state: RootState) =>
    state.organization.updateOperationOrganizationFulfulled;

export const { gotUserOrganization, resetOrganizationsByIdForAffectation, resetError } =
    slice.actions;

export default slice.reducer;
