import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import type { Operation } from '../slices/operationSlice';
import {
    selectOperationsByPage,
    selectTotalOperations,
    getOperations,
} from '../slices/operationSlice';
import type { QueryParams } from '../slices/common';
import { DEFAULT_ITEMS_PER_PAGE } from '../slices/common';

import OperationList from './OperationList';
import EmptyState from './EmptyState';
import Modal from './Modal';
import SearchField from './SearchField';
import Text from './Text';
import type { ButtonProps } from './Button';

import { colors } from '../constants/colors';
import Pagination from './Pagination';
import { useAppDispatch } from '../store';

const OperationModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const OperationSearchField = styled(SearchField)`
    width: 23.5rem;
`;

const OperationPagination = styled(Pagination)`
    background-color: ${colors.neutral.N50};
`;

type Props = {
    isOperationListModalOpen: boolean;
    setIsOperationListModalOpen: (isOperationListModalOpen: boolean) => void;
    cloneBudgetToAnotherOperation: (destinationOperationId: Operation['id']) => void;
};

const BudgetCloneChooseOperationTargetModal = ({
    isOperationListModalOpen,
    setIsOperationListModalOpen,
    cloneBudgetToAnotherOperation,
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: 1,
        pageSize: DEFAULT_ITEMS_PER_PAGE,
        permissionNeeded: 'BUDGETS_ADD',
    });
    const [selectedDestinationOperation, setSelectedDestinationOperation] = useState<
        Operation | Partial<Operation> | null
    >(null);

    const operations = useSelector(selectOperationsByPage(queryParams.page)) as Operation[] | null;
    const totalOperations = useSelector(selectTotalOperations);

    useEffect(() => {
        void dispatch(getOperations(queryParams));
    }, [dispatch, queryParams]);

    const handleRowClick = (row: Operation | Partial<Operation>) => {
        setSelectedDestinationOperation(row);
        setIsConfirmationModalOpen(true);
    };

    const handleConfirmDuplication = () => {
        if (selectedDestinationOperation?.id) {
            const { id } = selectedDestinationOperation;
            cloneBudgetToAnotherOperation(id);
        }
        setIsConfirmationModalOpen(false);
        setIsOperationListModalOpen(false);
    };

    const handleSearch = (value: string) => {
        if (value === '') {
            setQueryParams({
                ...queryParams,
                page: 1,
                query: value,
            });
        } else {
            setQueryParams({
                ...queryParams,
                page: 1,
                query: value,
                match: 'any',
                fields: [
                    'internal_number',
                    'label',
                    'long_label',
                    'postal_code',
                    'city',
                    'clientOrganization.denomination',
                    'clientOrganization.denomination_usuelle',
                    'clientOrganization.denomination_usuelle1',
                    'projectOwnerOrganization.denomination',
                    'projectOwnerOrganization.denomination_usuelle',
                    'projectOwnerOrganization.denomination_usuelle1',
                    'delegateProjectOwnerOrganization.denomination',
                    'delegateProjectOwnerOrganization.denomination_usuelle',
                    'delegateProjectOwnerOrganization.denomination_usuelle1',
                ],
                orderBy: 'label',
                order: 'asc',
            });
        }
    };

    const OperationModalTitle = (
        <OperationModalHeader>
            <Text type="H700" style={{ marginTop: '0' }}>
                {t('budget.otherOperations')}
            </Text>
            <OperationSearchField
                name="operationResearch"
                data-testid="operationsSearch"
                onSearch={handleSearch}
            />
        </OperationModalHeader>
    );

    const confirmationModalButtons: ButtonProps[] = [
        {
            text: t('general.cancel'),
            'data-testid': 'cancelCloneBudgetToOperation',
            aspect: 'secondary',
            onClick() {
                setIsOperationListModalOpen(false);
                setIsConfirmationModalOpen(false);
            },
        },
        {
            text: t('general.confirm'),
            'data-testid': 'confirmCloneBudgetToOperation',
            aspect: 'primary',
            onClick: handleConfirmDuplication,
        },
    ];

    return (
        <>
            <Modal
                shouldRenderGlobalStyle={isOperationListModalOpen}
                size="full"
                specificHeight="80vh"
                specificPadding="0 1rem"
                backgroundColor={colors.neutral.N50}
                title={OperationModalTitle}
                isOpen={isOperationListModalOpen && !isConfirmationModalOpen}
                onCloseButtonPressed={() => setIsOperationListModalOpen(false)}
                scrollableBody
            >
                {operations && operations.length > 0 ? (
                    <>
                        <OperationList
                            viewType="table"
                            operations={operations}
                            onRowClick={handleRowClick}
                        />
                        <OperationPagination
                            data-testid="pagination"
                            initialPage={queryParams.page}
                            currentPage={queryParams.page}
                            pageNeighbours={2}
                            nbItemsPerPage={DEFAULT_ITEMS_PER_PAGE}
                            nbItemsTotal={totalOperations}
                            onPageNumberClicked={(page: number) =>
                                setQueryParams({
                                    ...queryParams,
                                    page,
                                })
                            }
                            pageUrl="operations"
                            shouldChangePageUrl={false}
                        />
                    </>
                ) : (
                    <EmptyState
                        data-testid="errorNoOperations"
                        imageName="Operation"
                        titleTranslationKey="errors.noOperationYouHaveAccessTo"
                    />
                )}
            </Modal>
            {selectedDestinationOperation?.label && (
                <Modal
                    isOpen={isConfirmationModalOpen}
                    onCloseButtonPressed={() => setIsConfirmationModalOpen(false)}
                    iconName="LibraryAdd"
                    title={t('budget.cloneBudgetToAnotherOperation')}
                    iconColor={colors.blue.B400}
                    buttons={confirmationModalButtons}
                    size="small"
                    specificHeight="15.125rem"
                    centerBody={false}
                >
                    <Text>{t('budget.destinationOperation')}</Text>
                    <Text style={{ margin: '1.5rem' }}>{selectedDestinationOperation.label}</Text>
                </Modal>
            )}
        </>
    );
};

export default BudgetCloneChooseOperationTargetModal;
