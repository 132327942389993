import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError, QueryParams } from './common';
import { errorHandler } from './common';

export type PriceIndexValue = {
    id: number;
    month: string;
    value: number;
    lastUpdatedAt: string;
};

export type PriceIndex = {
    id: number;
    name: string;
    inseeId: number;
    lastValue: PriceIndexValue;
    values: PriceIndexValue[];
};

export type PriceIndexValueFormData = {
    id?: PriceIndexValue['id'];
    month: PriceIndexValue['month'];
    value: PriceIndexValue['value'] | string;
};

export type PriceIndexFormData = {
    id?: PriceIndex['id'];
    name: PriceIndex['name'];
    values: PriceIndexValueFormData[];
};

interface IPriceIndexState {
    priceIndexesById: Record<number, PriceIndex>;
    loading: boolean;
    isCreatePriceIndexFulfilled: boolean;
    isUpdatePriceIndexFulfilled: boolean;
    isDeletePriceIndexFulfilled: boolean;
    selectedPriceIndexesType?: PriceIndexType;
    error: string | null;
}

export const initialState: IPriceIndexState = {
    priceIndexesById: {},
    loading: false,
    isCreatePriceIndexFulfilled: false,
    isUpdatePriceIndexFulfilled: false,
    isDeletePriceIndexFulfilled: false,
    selectedPriceIndexesType: undefined,
    error: null,
};

export type PriceIndexType = 'insee' | 'manual';

export const getPriceIndexes = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: PriceIndex[]; priceIndexesType?: PriceIndexType },
    // First argument to the payload creator
    QueryParams & { priceIndexesType?: PriceIndexType },
    {
        rejectValue: ServerError;
    }
>(
    'PRICE_INDEX/GETALL',
    async (
        { page = 1, pageSize = 100, orderBy = 'name', order = 'asc', priceIndexesType },
        { rejectWithValue },
    ) => {
        try {
            let queryString = `page=${
                page - 1
            }&pageSize=${pageSize}&orderBy=${orderBy}&order=${order}`;

            if (priceIndexesType) {
                queryString += `&priceIndexesType=${priceIndexesType}`;
            }

            const response = await axios.get<{ data: PriceIndex[] }>(
                `/price-indexes?${queryString}`,
            );
            if (Array.isArray(response.data.data)) {
                return { data: response.data.data, priceIndexesType };
            }
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        } catch (err: unknown) {
            return rejectWithValue({
                message: 'Something went wrong',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const createPriceIndex = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    PriceIndex,
    // First argument to the payload creator
    PriceIndexFormData,
    {
        rejectValue: ServerError;
    }
>('PRICE_INDEX/POST', async (priceIndexValues, { rejectWithValue }) => {
    try {
        const response = await axios.post<{ data?: PriceIndex }>(
            `/price-indexes`,
            priceIndexValues,
        );
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when creating new price index',
            translationKey: 'errors.setNewPriceIndex',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.status === 400 || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updatePriceIndex = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    PriceIndex,
    // First argument to the payload creator
    PriceIndexFormData,
    {
        rejectValue: ServerError;
    }
>('PRICE_INDEX/PUT', async (priceIndexValues, { rejectWithValue }) => {
    try {
        const { id } = priceIndexValues;
        const response = await axios.put<{ data?: PriceIndex }>(
            `/price-indexes/${id}`,
            priceIndexValues,
        );
        if (response.data.data) {
            return response.data.data;
        }
        return rejectWithValue({
            message: 'Problem when updating price index',
            translationKey: 'errors.updatePriceIndex',
        });
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.status === 400 || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const deletePriceIndex = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    PriceIndex['id'],
    // First argument to the payload creator
    PriceIndex['id'],
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('PRICE_INDEX/DELETE', async (id, { rejectWithValue }) => {
    try {
        await axios.delete(`/price-indexes/${id}`);
        return id;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.status === 400 || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const slice = createSlice({
    name: 'priceIndex',
    initialState,
    reducers: {
        resetError(state) {
            state.error = null;
        },
    },
    extraReducers(builder) {
        // Get all
        builder.addCase(getPriceIndexes.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPriceIndexes.fulfilled, (state, { meta, payload }) => {
            if (meta.arg.priceIndexesType !== state.selectedPriceIndexesType) {
                state.selectedPriceIndexesType = meta.arg.priceIndexesType;
                state.priceIndexesById = {};
            }
            payload.data.forEach((item: PriceIndex) => {
                state.priceIndexesById[item.id] = item;
            });
            state.loading = false;
        });
        builder.addCase(getPriceIndexes.rejected, errorHandler());
        // Create
        builder.addCase(createPriceIndex.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.isCreatePriceIndexFulfilled = false;
        });
        builder.addCase(createPriceIndex.fulfilled, (state, { payload }) => {
            state.priceIndexesById[payload.id] = payload;
            state.loading = false;
            state.isCreatePriceIndexFulfilled = true;
        });
        builder.addCase(createPriceIndex.rejected, errorHandler());
        // Update
        builder.addCase(updatePriceIndex.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.isUpdatePriceIndexFulfilled = false;
        });
        builder.addCase(updatePriceIndex.fulfilled, (state, { payload }) => {
            state.priceIndexesById[payload.id] = payload;
            state.loading = false;
            state.isUpdatePriceIndexFulfilled = true;
        });
        builder.addCase(updatePriceIndex.rejected, errorHandler());
        // Delete
        builder.addCase(deletePriceIndex.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.isDeletePriceIndexFulfilled = false;
        });
        builder.addCase(deletePriceIndex.fulfilled, (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary
            delete state.priceIndexesById[payload];
            state.loading = false;
            state.isDeletePriceIndexFulfilled = true;
        });
        builder.addCase(deletePriceIndex.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.priceIndex.error;
export const selectPriceIndexes = (state: RootState) => {
    const priceIndexes = Object.values(state.priceIndex.priceIndexesById);
    priceIndexes.sort((a, b) => a.name.localeCompare(b.name));
    return priceIndexes;
};
export const selectPriceIndex = (id: PriceIndex['id'] | null) => (state: RootState) =>
    id ? state.priceIndex.priceIndexesById[id] : undefined;
export const selectIsCreatePriceIndexFulfilled = (state: RootState) =>
    state.priceIndex.isCreatePriceIndexFulfilled;
export const selectIsUpdatePriceIndexFulfilled = (state: RootState) =>
    state.priceIndex.isUpdatePriceIndexFulfilled;
export const selectIsDeletePriceIndexFulfilled = (state: RootState) =>
    state.priceIndex.isDeletePriceIndexFulfilled;

// Actions added into the `reducers` part
export const { resetError } = slice.actions;

export default slice.reducer;
