import type { Operation } from '../slices/operationSlice';
import type { IUserFormData } from '../slices/userSlice';

export const checkIfUserHasAtLeastOneRoleOnOperation = (
    userRoles: IUserFormData['roles'],
    operationId: Operation['id'],
) => userRoles.some((userRole) => userRole.userRoleOperationId === operationId);

export const checkHowManyRolesUserHasOnOperation = (
    userRoles: IUserFormData['roles'],
    operationId: Operation['id'],
) =>
    userRoles.reduce((total, currentUserRole) => {
        if (currentUserRole.userRoleOperationId === operationId) {
            return total + 1;
        }
        return total;
    }, 0);
