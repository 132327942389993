import { useTranslation } from 'react-i18next';
import type { CSSProperties } from 'react';
import React from 'react';

import LogoImageLight from '../assets/images/logos/logo_previz_blanc.svg';
import LogoImageMedium from '../assets/images/logos/logo_previz_blanc_fonce.svg';
import LogoImageDark from '../assets/images/logos/logo_previz_fonce.svg';

// This must stay in px
// <img /> does not receive rems for the width
const sizes = {
    big: '228px',
    medium: '136px',
    small: '100px',
    tiny: '50px',
};

type Props = {
    size?: 'big' | 'medium' | 'small' | 'tiny';
    background?: 'light' | 'medium' | 'dark';
    style?: CSSProperties;
};

const Logo: React.FunctionComponent<Props> = ({ size, background, ...props }) => {
    const { t } = useTranslation();

    let src = '';
    switch (background) {
        case 'light':
            src = LogoImageDark;
            break;
        case 'medium':
            src = LogoImageLight;
            break;
        case 'dark':
            src = LogoImageMedium;
            break;
        default:
            src = LogoImageDark; // set default to light background
    }

    return (
        <img
            width={size ? sizes[size] : sizes.big}
            src={src}
            alt={t('altMessage.logo')}
            {...props}
        />
    );
};
export default Logo;
