import React from 'react';
import styled from 'styled-components';
import type { LinkProps } from 'react-router-dom';
import { Link, useLocation, useParams } from 'react-router-dom';

import { colors } from '../constants/colors';
import type { Permission } from '../slices/authSlice';

const Container = styled.div`
    @media print {
        display: none;
    }
    display: flex;
    flex: 1;
    margin: 0 2rem 0.5313rem 2rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0.0625rem;
        background-color: ${colors.neutral.N300};
        width: 100%;
        height: 0.125rem;
    }
`;

const Tab = styled.div<{ isActive: boolean }>`
    padding: 0.625rem 0;
    position: relative;
    margin-right: 2rem;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0.0625rem;
        background-color: ${({ isActive }) => (isActive ? colors.green.G400 : colors.neutral.N300)};
        width: 100%;
        height: 0.125rem;
        z-index: 999;
    }

    &:hover::after {
        background-color: ${colors.green.G200};
    }
`;

const IntermediateLink: React.FC<{ isActive: boolean } & LinkProps> = ({ isActive, ...rest }) => (
    <Link {...rest} />
);

const StyledLink = styled(IntermediateLink)<{ isActive: boolean }>`
    &,
    &:visited {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 800;
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${({ isActive }) => (isActive ? colors.green.G400 : colors.neutral.N300)};
    }

    &:hover {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 800;
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${colors.green.G200};
    }
`;

type Tab = {
    text: string;
    to: string;
    requiredPermissions?: Permission[];
};

export type TabsProps = Tab[];

type Props = {
    tabs: TabsProps;
};

const Tabs: React.FC<Props> = ({ tabs }) => {
    const location = useLocation();
    const params = useParams() as Record<string, string>;
    const paramsKeys = Object.keys(params);

    return (
        <Container>
            {tabs.map(({ text, to }) => {
                let computedTo = to;

                paramsKeys.forEach((key: string) => {
                    if (computedTo.includes(`:${key}`) && params[key]) {
                        computedTo = computedTo.replace(`:${key}`, params[key]);
                    }
                });

                return (
                    <Tab key={text} isActive={location.pathname === computedTo}>
                        <StyledLink
                            to={computedTo}
                            isActive={location.pathname === computedTo}
                            data-testid={`tab-${text}`}
                        >
                            {text}
                        </StyledLink>
                    </Tab>
                );
            })}
        </Container>
    );
};

export default Tabs;
