import styled from 'styled-components';
import React, { useState } from 'react';

import Text from './Text';
import Icon from './Icon';
import PercentageBadge from './PercentageBadge';
import { colors } from '../constants/colors';
import { styles } from '../constants/styles';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0.5rem;
`;

const Line = styled.div<{ isEditMode?: boolean }>`
    margin: 0 0 0 1rem;
    display: flex;
    width: 100%;
    background-color: ${({ isEditMode }) => (isEditMode ? colors.other.white : colors.neutral.N50)};
`;

const ExpandIcon = styled.div`
    @media print {
        display: none;
    }
    width: 0.8125rem;
    background-color: ${colors.neutral.N400};
    margin: 0 -0.3rem 0 0;
    border-radius: ${styles.borderRadiusXSmall};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
`;

const HeaderText = styled(Text)`
    font-weight: 500;
    margin: 0;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: -0.1rem;
`;

const LineContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const HeaderLine = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    background-color: ${colors.neutral.N75};
`;

const TableCol = styled.div<{
    justifyContent?: string;
    flex?: string;
    width?: string;
    isEditMode?: boolean;
}>`
    @media print {
        ${({ width }) => width === '15vw' && `justify-content: space-between;`};
        width: ${({ width }) => (width === '6vw' ? `6vw !important;` : `20vw !important;`)};
        padding: 0rem;
        margin: 0rem !important;
        > p {
            margin: 0;
        }
    }
    display: flex;
    padding: 0 0.5rem;
    align-items: center;
    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
    ${({ flex }) => flex && `flex: ${flex};`}
    ${({ width }) => width && `width: ${width};`}
    p {
        padding: ${({ isEditMode }) => (isEditMode ? '0 2rem 0 0' : '0')};
        margin: 0;
    }
    white-space: pre-wrap;
`;

type HeaderCell = {
    openContentLabel?: string;
    openContentValue?: string;
    closedContentLabel?: string;
    closedContentValue?: string;
    percentage?: number;
    width: string;
    textColor?: string;
} | null;

type HeaderLine = {
    label: string;
    cells: HeaderCell[];
};

type Cell = {
    value: string;
    percentage?: number;
    width: string;
    textColor?: string;
} | null;

type SubLine = {
    label: string;
    cells: Cell[];
};

type Props = {
    data: {
        headerLine: HeaderLine;
        subLines: SubLine[];
    };
    isEditMode?: boolean;
};

const CollapsableLine: React.FC<Props> = ({ data, isEditMode }) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    const handleHeaderData = (item: HeaderCell, key: number) =>
        item && (
            <TableCol
                key={key}
                justifyContent={item.percentage !== undefined ? 'space-between' : 'flex-end'}
                width={item.width}
                isEditMode={isEditMode}
            >
                {item.percentage !== undefined && (
                    <PercentageBadge
                        value={item.percentage}
                        style={{
                            margin: '0 1rem',
                        }}
                    />
                )}
                <HeaderText color={item.textColor} isNumeral>
                    {isCollapsed ? item.closedContentLabel : item.openContentLabel}
                </HeaderText>
                <HeaderText color={item.textColor} isNumeral>
                    {isCollapsed ? item.closedContentValue : item.openContentValue}
                </HeaderText>
            </TableCol>
        );

    const handleLineData = (item: Cell, key: number) =>
        item && (
            <TableCol
                key={key}
                justifyContent={item.percentage !== undefined ? 'space-between' : 'flex-end'}
                width={item.width}
                isEditMode={isEditMode}
            >
                {item.percentage !== undefined ? (
                    <PercentageBadge
                        value={item.percentage}
                        style={{
                            margin: '0 1rem',
                        }}
                    />
                ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment -- necessary
                    <></>
                )}
                <Text style={{ fontWeight: 'normal' }} color={item.textColor} isNumeral>
                    {item.value}
                </Text>
            </TableCol>
        );

    const handleData = (item: SubLine, key: number) => (
        <LineContainer>
            <Line key={key} isEditMode={isEditMode}>
                <TableCol key={key} flex="1" isEditMode={isEditMode}>
                    <Text style={{ fontWeight: 'normal' }}>{item.label}</Text>
                </TableCol>
                {item.cells.map(handleLineData)}
            </Line>
        </LineContainer>
    );

    const handleCollapseIconClick = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Container>
            <HeaderContainer>
                <ExpandIcon>
                    <Icon
                        name={isCollapsed ? 'KeyboardArrowUp' : 'KeyboardArrowDown'}
                        color={colors.other.white}
                        onClick={() => handleCollapseIconClick()}
                    />
                </ExpandIcon>
                <HeaderLine>
                    <TableCol flex="1" isEditMode={isEditMode}>
                        <HeaderText>{data.headerLine.label}</HeaderText>
                    </TableCol>
                    {data.headerLine.cells.map(handleHeaderData)}
                </HeaderLine>
            </HeaderContainer>
            {!isCollapsed && data.subLines.map(handleData)}
        </Container>
    );
};

export default CollapsableLine;
