import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import type { UserRole } from '../slices/userSlice';
import Badge from './Badge';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

type Props = {
    userRoles: UserRole[];
};

const UserRolesBadges: React.FC<Props> = ({ userRoles }) => {
    const nbUserRoles = userRoles.length;
    return (
        <Container>
            {userRoles.map((userRole, index) => (
                <Badge
                    key={userRole.userRoleId}
                    text={userRole.label ?? ''}
                    textColor={colors.blue.B500}
                    background={colors.neutral.N75}
                    style={{
                        marginBottom: nbUserRoles > 1 && index < nbUserRoles - 1 ? '8px' : '0',
                    }}
                />
            ))}
        </Container>
    );
};
export default UserRolesBadges;
