import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import type { TFunction } from 'i18next';

import type { AxiosError } from 'axios';

import MainLayout from '../../components/MainLayout';
import GridCol from '../../components/GridCol';
import GridRow from '../../components/GridRow';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import type { EmptyStateProps } from '../../components/EmptyState';
import Button from '../../components/Button';
import { showFlag } from '../../components/Flag';
import { getSireneInfo } from '../../utils/getSireneInfo';
import type { IOrganization, IOrganizationFormData } from '../../slices/organizationSlice';
import {
    getOrganizations,
    selectOrganizationsByPage,
    selectTotalOrganizations,
    createOrganization,
    selectOrganization,
    selectHeadOfficeAdded,
    selectOrganizationAdded,
    selectIsLoading,
    selectGetOrganizationsLoading,
} from '../../slices/organizationSlice';
import type { QueryParams, ListViewType, ServerError } from '../../slices/common';
import { usePageNumber } from '../../hooks/usePageNumber';
import OrganizationsList from '../../components/OrganizationsList';
import OrganizationsSearch from '../../components/OrganizationsSearch';
import Tooltip from '../../components/Tooltip';
import { capitalize } from '../../utils/capitalizeText';
import Status from '../../components/Status';
import { colors } from '../../constants/colors';
import OrganizationsPagination from '../../components/OrganizationsPagination';
import { stringSearch, siretSearch, siretValid } from '../../utils/organizationsRegex';
import { useAppDispatch } from '../../store';

const ViewToggle = styled.div`
    display: flex;
    div:first-child {
        margin: 0 1rem 0 0;
    }
    justify-content: flex-end;
`;

const statusLabelStyle: React.CSSProperties = {
    color: colors.blue.B400,
    fontSize: '0.75rem',
    fontWeight: 400,
};

const emptyStateImage: { imageName: EmptyStateProps['imageName'] } = {
    imageName: 'SearchDocument',
};

export const getRenderCellMap = (t: TFunction) => ({
    denomination(
        organismeData: IOrganizationFormData | Partial<IOrganizationFormData>,
        key: string,
        shouldCapitalize?: boolean,
    ) {
        const denomination =
            typeof organismeData.denomination === 'string' && shouldCapitalize
                ? capitalize(organismeData.denomination)
                : organismeData.denomination;
        return denomination as string;
    },
    estPrive(
        organismeData: IOrganizationFormData | Partial<IOrganizationFormData>,
        key: string,
        shouldCapitalize?: boolean,
    ) {
        if (organismeData.estPublic) {
            return shouldCapitalize
                ? capitalize(t('organization.public'))
                : t('organization.public');
        } else if (organismeData.estPrive) {
            return shouldCapitalize
                ? capitalize(t('organization.private'))
                : t('organization.private');
        }
        return '';
    },
    codePostal(
        organismeData: IOrganizationFormData | Partial<IOrganizationFormData>,
        key: string,
        shouldCapitalize?: boolean,
    ) {
        const ville = organismeData.libelleCommune ?? '';
        const codePostal = organismeData.codePostal ?? '';
        const address = shouldCapitalize
            ? `${codePostal}, ${capitalize(ville)}`
            : `${codePostal}, ${ville}`;
        return (
            <Tooltip
                content={address}
                textStyle={{
                    type: 'H300',
                    style: { overflow: 'hidden', fontWeight: 400, margin: '0px' },
                }}
                isOneLine
            >
                {address}
            </Tooltip>
        );
    },
    estActif(organismeData: IOrganizationFormData | Partial<IOrganizationFormData>) {
        return (
            <Status
                status={organismeData.estActif ? 'active' : 'inactive'}
                squareStyle={{ margin: '0.0625rem 0.5rem 0' }}
                labelStyle={statusLabelStyle}
            />
        );
    },
    siret(organismeData: IOrganizationFormData | Partial<IOrganizationFormData>) {
        // Format siret number: 11001401600015 -> 110 014 016 00015
        const siret = organismeData.siret?.toString().trim();

        if (!siret) {
            return 'No SIRET';
        }

        return `${siret.slice(0, 3)} ${siret.slice(3, 6)} ${siret.slice(6, 9)} ${siret.slice(9)}`;
    },
});

type Props = {
    headerProps: HeaderProps;
};

const Organizations: React.FunctionComponent<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const pageNumber = usePageNumber();

    const { organizationSiretToAdd } = location.state ?? {};
    useEffect(() => {
        if (organizationSiretToAdd) {
            const { pathname } = location;
            navigate(pathname, { replace: true });
        }
    }, [organizationSiretToAdd, navigate, location]);

    // State
    const [organization, setOrganization] = useState<IOrganizationFormData | null>(null);
    const [info, setInfo] = useState<string | null>(null);
    const [searchError, setSearchError] = useState<string | null>(null);
    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: pageNumber,
    });
    const [emptyState, setEmptyState] = useState<EmptyStateProps | null>(null);
    const [viewType, setViewType] = useState<ListViewType>(
        localStorage.getItem('organizations_list_type') === 'table' ? 'table' : 'card',
    );
    const [isSireneLoading, setIsSireneLoading] = useState<boolean>(false);
    const [isSearchReset, setIsSearchReset] = useState<boolean>(false);

    // Selectors
    const totalOrganizations = useSelector(selectTotalOrganizations);
    const organizations = useSelector(selectOrganizationsByPage(queryParams.page)) as
        | IOrganization[]
        | null;
    const isLoading = useSelector(selectIsLoading);
    const getOrganizationsLoading = useSelector(selectGetOrganizationsLoading);
    const newOrganization = useSelector(selectOrganization(organization?.siret));
    const newHeadOffice = useSelector(selectOrganization(organization?.etablissementSiege?.siret));
    const headOfficeAdded = useSelector(selectHeadOfficeAdded);
    const organizationAdded = useSelector(selectOrganizationAdded);

    useEffect(() => {
        if (!searchError) {
            if (isSearchReset) {
                void dispatch(getOrganizations({ page: pageNumber }));
            } else {
                void dispatch(getOrganizations(queryParams));
            }
        }
    }, [dispatch, queryParams, searchError, isSearchReset, pageNumber]);

    useEffect(() => {
        if (organizationAdded && newOrganization) {
            const text = t('organizationCreateSuccess', {
                organization: String(newOrganization.denomination),
            });
            showFlag('success', '', text);
            if (headOfficeAdded && newHeadOffice) {
                const headText = t('headOfficeCreateSuccess', {
                    headOffice: String(newHeadOffice.denomination),
                    organization: String(newOrganization.denomination),
                });
                showFlag('success', '', headText);
            }

            navigate(`/organizations/${newOrganization.siret}?edit`);
        }
    }, [organizationAdded, newOrganization, headOfficeAdded, newHeadOffice, t, navigate]);

    // Handle view toggler
    const createViewClickHandler = (type: 'card' | 'table') => () => {
        setViewType(type);
        localStorage.setItem('organizations_list_type', type);
    };

    // Organizations Table(List)
    const fetchSirene = useCallback(
        async (query: string) => {
            try {
                const result: IOrganizationFormData = await getSireneInfo(query);
                if (result.etablissementSiegeSiret) {
                    const headOffice: IOrganizationFormData = await getSireneInfo(
                        result.etablissementSiegeSiret,
                    );
                    result.etablissementSiege = headOffice;
                }
                setOrganization(result);

                setInfo(t('errors.getFromSirene'));
                setIsSireneLoading(false);
            } catch (err: unknown) {
                const error = err as AxiosError<ServerError>;

                setEmptyState({
                    ...emptyStateImage,
                    titleTranslationKey: 'errors.notFoundOurServerTitle',
                    textTranslationKey: error.response?.data.message ?? error.message,
                    'data-testid': error.response?.data.message ?? error.message,
                });
                setIsSireneLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps -- necessary
        [emptyStateImage, t],
    );

    useEffect(() => {
        if (isLoading) {
            setEmptyState(null);
            setInfo(null);
            setOrganization(null);
        } else if (
            (!organizations || organizations.length === 0) &&
            queryParams.query &&
            !emptyState &&
            !searchError
        ) {
            if (stringSearch.test(queryParams.query.toLowerCase())) {
                setSearchError(null);
                setEmptyState({
                    ...emptyStateImage,
                    titleTranslationKey: 'errors.notFoundOurServerTitle',
                    textTranslationKey: 'errors.checkSpelling',
                    'data-testid': 'errors.checkSpelling',
                });
            } else if (siretSearch.test(queryParams.query) && !siretValid.test(queryParams.query)) {
                setSearchError('errors.invalidSiret');
            } else if (!organization && siretValid.test(queryParams.query)) {
                setSearchError(null);
                setIsSireneLoading(true);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- necessary
                fetchSirene(queryParams.query);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- necessary
    }, [
        emptyState,
        emptyStateImage,
        fetchSirene,
        isLoading,
        organization,
        queryParams.query,
        searchError,
    ]);

    const onOrganizationClick = (organizationClicked: IOrganization | Partial<IOrganization>) =>
        navigate(`/organizations/${organizationClicked.id}`, {
            state: { goBackTo: `${location.pathname}${location.search}` },
        });

    const shouldShowList = Boolean(
        !organization && !emptyState && organizations && organizations.length > 0,
    );

    const createButton = (
        <Button
            data-testid="createOrganization"
            text={t('general.create')}
            aspect="secondary"
            size="medium"
            onClick={() => organization !== null && dispatch(createOrganization(organization))}
            style={{ margin: 'auto' }}
        />
    );

    const tableHeaders = {
        denomination: t('organization.list.headers.denomination'),
        siret: t('organization.list.headers.siret'),
        estActif: t('organization.list.headers.estActif'),
        estPrive: t('organization.list.headers.estPrive'),
        codePostal: t('organization.list.headers.codePostal'),
    };

    const noErrorOrEmptyState = !searchError && !emptyState;
    const noFetching = !isLoading && !isSireneLoading;

    const getColumnSizes = () => {
        if (noFetching && noErrorOrEmptyState && organization) {
            return '4fr 4fr 3fr 3fr 4fr 4fr';
        }
        return '5fr 3fr 2fr 2fr 4fr';
    };

    return (
        <MainLayout
            header={<Header {...headerProps} />}
            footer={
                totalOrganizations > 0 ? (
                    <OrganizationsPagination
                        data-testid="pagination"
                        initialPage={queryParams.page}
                        currentPage={queryParams.page}
                        nbItemsTotal={totalOrganizations}
                        queryParams={queryParams}
                        setIsSearchReset={setIsSearchReset}
                        setQueryParams={setQueryParams}
                    />
                ) : null
            }
            smallContentSidePadding
        >
            <GridRow style={{ marginBottom: '1rem' }}>
                <GridCol smallScreen={10} defaultScreen={4}>
                    <OrganizationsSearch
                        defaultValue={organizationSiretToAdd}
                        setQueryParams={setQueryParams}
                        setIsSearchReset={setIsSearchReset}
                        setSearchError={setSearchError}
                    />
                </GridCol>
                <GridCol smallScreen={2} defaultScreen={8}>
                    <ViewToggle>
                        <Button
                            onClick={createViewClickHandler('table')}
                            iconName="ViewList"
                            isPressed={viewType === 'table'}
                            data-testid="tableViewButton"
                            aspect="onlyIcon"
                            toggle
                        />
                        <Button
                            onClick={createViewClickHandler('card')}
                            iconName="ViewModule"
                            isPressed={viewType === 'card'}
                            data-testid="cardViewButton"
                            aspect="onlyIcon"
                            toggle
                        />
                    </ViewToggle>
                </GridCol>
            </GridRow>
            <OrganizationsList
                organization={organization}
                organizations={organizations}
                shouldShowList={shouldShowList}
                createButton={createButton}
                onOrganizationClick={onOrganizationClick}
                columnSizes={getColumnSizes()}
                tableHeaders={tableHeaders}
                renderCellMap={getRenderCellMap(t)}
                viewType={viewType}
                emptyState={emptyState}
                info={info}
                searchError={searchError}
                isLoading={getOrganizationsLoading}
                isSireneLoading={isSireneLoading}
            />
        </MainLayout>
    );
};

export default Organizations;
