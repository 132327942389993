import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import Tooltip from './Tooltip';
import Text from './Text';
import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import Popover from './Popover';
import Icon from './Icon';
import Elevation from './Elevation';

type ContainerProps = {
    onClick?: () => void;
};

export type Props = {
    headerTitle: string | undefined;
    headerSubtitle?: string;
    headerLeadingComponent?: React.ReactNode;
    headerTrailingComponent?: React.ReactNode;
    bodyImageUrl?: string;
    bodyImageAlt?: string;
    bodyData: Record<string, string | JSX.Element | undefined>;
    popoverMenu?: React.ReactNode;
    isTooltipOneLine: boolean;
    footerComponent?: React.ReactNode;
    style?: React.CSSProperties;
} & ContainerProps;

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    margin-bottom: ${styles.gutterWidth};
    background: none;
    ${({ onClick }) => onClick && `cursor: pointer`};
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.other.white};
    padding-bottom: 1rem;
    margin-bottom: 0.09375rem;
    border-radius: ${styles.borderRadiusSmall} ${styles.borderRadiusSmall} 0 0;
`;

const LeadingComponentContainer = styled.div`
    margin-right: 0.8rem;
`;

const TrailingComponentContainer = styled.div`
    margin-left: 0.8rem;
`;

const TopHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem 0;
`;

const TopLeftHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: content-box;
`;

const TopRightHeader = styled.div`
    display: flex;
`;

const SubHeader = styled.div`
    padding: 0 1.5rem;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled(Text)`
    margin-right: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Subtitle = styled.div`
    display: flex;
    margin-top: 0.2rem;
`;

const InfoContainer = styled.div<Props>`
    display: flex;
    background-color: ${colors.other.white};
    padding: 1.5rem 1.5rem 1.5rem 0;
    ${({ footerComponent }) =>
        !footerComponent &&
        `border-radius: 0 0 ${styles.borderRadiusSmall} ${styles.borderRadiusSmall};`};
`;

const ImageContainer = styled.div`
    background-color: ${colors.neutral.N75};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: auto;
    border-radius: ${styles.borderRadiusSmall};
    margin-left: 1.5rem;
    width: 5rem;
    height: 5rem;
`;

const TopRightInfoMenu = styled.div`
    display: flex;
    justify-self: flex-end;
    margin-left: auto;
`;

const ContextualMenuIcon = styled(Icon)`
    margin-right: 1rem;
    cursor: pointer;

    &:hover {
        color: ${colors.green.G400};
    }
`;

const InfoContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const InfoLine = styled.div`
    display: flex;
    margin-left: 1.5rem;
    box-sizing: content-box;
`;

const StyledEntry = styled(Text)`
    margin: 0;
`;

const StyledValue = styled(Text)`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: ${colors.other.white};
    margin-top: 0.15rem;
    padding: 0.875rem 1.5rem;
    border-radius: 0 0 ${styles.borderRadiusSmall} ${styles.borderRadiusSmall};
`;

const Card: React.FC<Props> = (props) => {
    const [isContextualMenuOpen, setIsContextualMenuOpen] = useState<boolean>(false);

    const topRightIngoMenuRef = useRef<HTMLDivElement>(null);
    const popoverMenuRef = useRef<HTMLDivElement>(null);

    const handleClickOutsideContextualMenuIcon = (event: Event) => {
        if (
            topRightIngoMenuRef.current &&
            !topRightIngoMenuRef.current.contains(event.target as Element) &&
            popoverMenuRef.current &&
            !popoverMenuRef.current.contains(event.target as Element)
        ) {
            setIsContextualMenuOpen(false);
        }
    };

    useEffect(() => {
        // https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
        document.addEventListener('mousedown', handleClickOutsideContextualMenuIcon);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideContextualMenuIcon);
        };
    }, []);

    const handleBodyInfos = (
        [entry, value]: [string, string | JSX.Element | undefined],
        key: number,
    ) => {
        if (value && typeof value !== 'string') {
            return (
                <InfoLine key={key}>
                    <StyledEntry color={colors.neutral.N300}>
                        {entry}
                        :&nbsp;
                    </StyledEntry>
                    {value}
                </InfoLine>
            );
        }

        return (
            <InfoLine key={key}>
                <StyledEntry color={colors.neutral.N300}>
                    {entry}
                    :&nbsp;
                </StyledEntry>
                {value ? (
                    <StyledValue data-testid={`bodyItem${entry}-${value}`}>{value}</StyledValue>
                ) : (
                    <StyledValue>-</StyledValue>
                )}
            </InfoLine>
        );
    };

    return (
        <Container onClick={props.onClick} style={props.style}>
            <Header>
                <TopHeader>
                    <TopLeftHeader>
                        <TitleContainer>
                            {props.headerLeadingComponent && (
                                <LeadingComponentContainer>
                                    {props.headerLeadingComponent}
                                </LeadingComponentContainer>
                            )}
                            <Tooltip
                                content={props.headerTitle ?? ''}
                                isOneLine={props.isTooltipOneLine}
                            >
                                <Title type="H500">{props.headerTitle}</Title>
                            </Tooltip>
                        </TitleContainer>
                    </TopLeftHeader>
                    <TopRightHeader>
                        {props.headerTrailingComponent && (
                            <TrailingComponentContainer>
                                {props.headerTrailingComponent}
                            </TrailingComponentContainer>
                        )}
                    </TopRightHeader>
                </TopHeader>
                {props.headerSubtitle && (
                    <SubHeader>
                        <Subtitle>
                            <StyledEntry color={colors.neutral.N300} />
                            <StyledValue>{props.headerSubtitle}</StyledValue>
                        </Subtitle>
                    </SubHeader>
                )}
            </Header>
            <InfoContainer {...props}>
                {props.bodyImageUrl && (
                    <ImageContainer>
                        <img src={props.bodyImageUrl} alt={props.bodyImageAlt} />
                    </ImageContainer>
                )}
                <InfoContent>{Object.entries(props.bodyData).map(handleBodyInfos)}</InfoContent>
                {props.popoverMenu && (
                    <TopRightInfoMenu
                        ref={topRightIngoMenuRef}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            // We stop event propagation to avoid triggering the Card global onClick
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            setIsContextualMenuOpen(!isContextualMenuOpen);
                        }}
                    >
                        <Popover isOpen={isContextualMenuOpen}>
                            <ContextualMenuIcon name="MoreHoriz" data-testid="popOverOptions" />
                            <Elevation elevation="default">
                                <span ref={popoverMenuRef}>{props.popoverMenu}</span>
                            </Elevation>
                        </Popover>
                    </TopRightInfoMenu>
                )}
            </InfoContainer>
            {props.footerComponent && <Footer>{props.footerComponent}</Footer>}
        </Container>
    );
};

export default Card;
