import type { PropsWithChildren } from 'react';
import React from 'react';

import styled from 'styled-components';

import { colors } from '../constants/colors';

type ContainerProps = {
    footer?: JSX.Element | null;
};
const Container = styled.div`
    @media print {
        height: 100%;
        overflow-y: visible !important;
    }
    height: 100%;
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto 1fr;
    grid-template-rows: auto 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'header' 'contentAndFooter';
`;

const HeaderContainer = styled.div`
    -ms-grid-row: 1;
    grid-area: header;
    width: 100%;
    background-color: ${colors.neutral.N50};
    max-height: 15.125rem; // 242px
    top: 0;
    z-index: 20;
`;

const ScrollContainer = styled.div<ContainerProps>`
    -ms-grid-row: 2;
    height: 100%;
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: ${({ footer }) => (footer ? '1fr 4.875rem' : '1fr')};
    grid-template-rows: ${({ footer }) => (footer ? '1fr 4.875rem' : '1fr')};
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: ${({ footer }) => (footer ? "'content' 'footer'" : "'content'")};
    overflow-y: auto;
`;

const Content = styled.div<{ sidePadding: string }>`
    @media print {
        padding: 0 !important;
    }
    -ms-grid-row: 1;
    grid-area: content;
    background-color: ${colors.neutral.N50};
    padding: ${({ sidePadding }) => `0 ${sidePadding}`}}
    // Confirmed with Aicha but might be changed later thus I leave this comment here
    // Uncomment this if you want just the content to scroll(have a sticky footer)
    // This is needed on IE11
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        overflow-y: auto;
    }
`;

const FooterContainer = styled.div`
    -ms-grid-row: 2;
    grid-area: footer;
    width: 100%;
    background-color: ${colors.other.white};
`;

type Props = {
    smallContentSidePadding?: boolean;
    header: JSX.Element;
    footer?: JSX.Element | null;
};

const MainLayout: React.FC<PropsWithChildren<Props>> = ({
    children,
    smallContentSidePadding = false,
    header,
    footer,
    ...rest
}) => (
    <Container {...rest}>
        <HeaderContainer>{header}</HeaderContainer>
        <ScrollContainer footer={footer} className="scrollable-content">
            <Content sidePadding={smallContentSidePadding ? '2rem' : '3rem'}>{children}</Content>
            {footer && <FooterContainer>{footer}</FooterContainer>}
        </ScrollContainer>
    </Container>
);
export default MainLayout;
