import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useParams, useMatch, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { PASSWORD_REGEXP } from '../../utils/validation';
import FormikTextField from '../../components/TextFieldWithFormik';
import Button from '../../components/Button';
import PasswordValidation from '../../components/PasswordValidation';
import { resetPassword, selectUpdateSucceeded } from '../../slices/authSlice';
import { colors } from '../../constants/colors';
import Footer from '../../components/Footer';
import Logo from '../../components/Logo';
import PublicPageLayout from '../../components/PublicPageLayout';
import Text from '../../components/Text';
import { useAppDispatch } from '../../store';

const FormContainer = styled.div`
    -ms-grid-row: 1;
    grid-area: content;
    flex: 1 1 25%;
    text-align: center;
    margin: auto;
    width: 23rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

type Props = {
    isAccountSetup?: boolean;
};

const ResetPassword: React.FC<Props> = ({ isAccountSetup }) => {
    const { token } = useParams<{ token: string }>();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const didUpdateSucceed = useSelector(selectUpdateSucceeded);

    const numberCharacter = /^.{8,}$/;
    const lowercase = /(?=.*?[a-z])/;
    const uppercase = /(?=.*?[A-Z])/;
    const digit = /(?=.*?[0-9])/;

    return (
        <PublicPageLayout>
            <FormContainer>
                {didUpdateSucceed ? <Navigate to="/password-changed" /> : null}
                <Logo />
                <Text type="H700" style={{ marginTop: '1.875rem' }}>
                    {t('passwordReset.title')}
                </Text>
                <Text color={colors.neutral.N300} style={{ margin: '1.875rem 0 0 0' }}>
                    {t('passwordReset.text')}
                </Text>
                <Formik
                    initialValues={{
                        password: '',
                        passwordConfirmation: '',
                    }}
                    onSubmit={(values, actions) => {
                        if (token) {
                            void dispatch(
                                resetPassword({ password: values.password, token, isAccountSetup }),
                            );
                        }

                        actions.setSubmitting(false);
                    }}
                >
                    {(props) => (
                        <Form>
                            <FormikTextField
                                name="password"
                                type="password"
                                data-testid="passwordField"
                                errorTestId="passwordFieldError"
                                placeholder={t('passwordReset.firstPlaceholder')}
                                eyeIcon
                                hideError
                                whiteBackground
                            />
                            <FormikTextField
                                name="passwordConfirmation"
                                type="password"
                                data-testid="passwordConfirmationField"
                                errorTestId="passwordConfirmationFieldError"
                                placeholder={t('passwordReset.secondPlaceholder')}
                                eyeIcon
                                hideError
                                whiteBackground
                            />
                            <PasswordValidation
                                text={t('errors.numberCharacterError')}
                                isValid={Boolean(numberCharacter.exec(props.values.password))}
                                style={{ marginTop: '1.875rem' }}
                            />
                            <PasswordValidation
                                text={t('errors.digitError')}
                                isValid={Boolean(digit.exec(props.values.password))}
                            />
                            <PasswordValidation
                                text={t('errors.uppercaseError')}
                                isValid={Boolean(uppercase.exec(props.values.password))}
                            />
                            <PasswordValidation
                                text={t('errors.lowercaseError')}
                                isValid={Boolean(lowercase.exec(props.values.password))}
                            />
                            <PasswordValidation
                                text={t('errors.identicalError')}
                                isValid={Boolean(
                                    props.values.password.length > 0 &&
                                        props.values.password === props.values.passwordConfirmation,
                                )}
                            />
                            <Button
                                size="large"
                                type="submit"
                                aspect="primary"
                                data-testid="submitButton"
                                disabled={
                                    Boolean(!PASSWORD_REGEXP.exec(props.values.password)) ||
                                    props.values.password !== props.values.passwordConfirmation
                                }
                                text={t('passwordReset.submit')}
                                style={{
                                    marginTop: '1.875rem',
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </FormContainer>
            <Footer />
        </PublicPageLayout>
    );
};

export default ResetPassword;
