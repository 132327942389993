import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
    background-color: white;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 90% 10%;
    grid-template-rows: 90% 10%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        'content'
        'footer';
    padding: 1rem;
    height: 100%;
`;

interface Props {
    children?: React.ReactElement | React.ReactElement[];
}

const PublicPageLayout: React.FunctionComponent<Props> = (props: Props) => (
    <Container>{props.children}</Container>
);

export default PublicPageLayout;
