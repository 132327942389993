import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import type { IPopoverProps } from '@blueprintjs/core';
import { Popover } from '@blueprintjs/core';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import Text from './Text';

export const GlobalStyle = createGlobalStyle<{ iconWrapperLineHeight?: string }>`
    .bp4-popover-wrapper{
        ${(props) => props.iconWrapperLineHeight && `line-height: ${props.iconWrapperLineHeight}`};
    }

    .custom-popover {
        .bp4-popover {
            box-shadow: 0 0 1.25rem ${colors.neutral.N100};
            border-radius: ${styles.borderRadiusMedium};
            padding: 0;
            width: 21rem;

            .bp4-popover-content {
                padding: 0.5rem;
                background-color: transparent;

                & > div {
                    background-color: ${colors.other.white};
                    border-radius: ${styles.borderRadiusMedium};
                    overflow: hidden;
                }
            }
        }
        .bp4-popover-arrow {
            display: none;
        }
    }
`;

export const PopoverContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PopoverItem = styled(Text)<{ className: string; first?: boolean; last?: boolean }>`
    cursor: pointer;
    margin: 0;
    padding: 0.5rem 1.125rem;

    :hover {
        background-color: ${colors.blue.B50};
        ${({ first }) => first && `border-top-left-radius: ${styles.borderRadiusMedium};`}
        ${({ first }) => first && `border-top-right-radius: ${styles.borderRadiusMedium};`}
        ${({ last }) => last && `border-bottom-left-radius: ${styles.borderRadiusMedium};`}
        ${({ last }) => last && `border-bottom-right-radius: ${styles.borderRadiusMedium};`}
        color: ${colors.green.G400};
    }
`;

type Props = {
    isOpen?: boolean;
    renderGlobalStyle?: boolean;
    iconWrapperLineHeight?: string;
};

const AEOPopover: React.FC<Props & IPopoverProps> = ({
    isOpen,
    children,
    renderGlobalStyle = true,
    iconWrapperLineHeight,
    ...rest
}) => {
    const content = (
        <Popover {...rest} isOpen={isOpen} popoverClassName="custom-popover">
            {children}
        </Popover>
    );

    if (renderGlobalStyle) {
        return (
            <>
                <GlobalStyle iconWrapperLineHeight={iconWrapperLineHeight} />
                {content}
            </>
        );
    }

    return content;
};

export default AEOPopover;
