import React from 'react';

import styled from 'styled-components';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';

import Sidebar from './Sidebar';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

const Container = styled.div`
    @media print {
        display: initial;
    }
    height: 100%;
    width: 100%;
    /* A 2 rows x 2 columns css grid */
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 15rem 1fr;
    grid-template-columns: 15rem 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'sidebar main-content';
    @media (max-width: ${styles.gridBreakpoint}) {
        -ms-grid-columns: 6rem 1fr;
        grid-template-columns: 6rem 1fr;
    }
    overflow-y: hidden;
`;

const Content = styled.div`
    height: 100%;
    width: 100%;
    -ms-grid-column: 2;
    grid-area: main-content;
    background-color: ${colors.neutral.N50};
    overflow-y: auto;
`;

const SideBarLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { width } = useWindowDimensions();

    return (
        <Container>
            <Sidebar isCollapsed={width < Number(styles.gridBreakpoint.split('rem')[0]) * 16} />
            <Content>{children}</Content>
        </Container>
    );
};
export default SideBarLayout;
