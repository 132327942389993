import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import styled from 'styled-components';

import MainLayout from '../../components/MainLayout';
import type { HeaderProps } from '../../components/Header';
import Header from '../../components/Header';
import { selectMarketName, getMarket } from '../../slices/marketSlice';
import { getNotes, selectNotes } from '../../slices/noteSlice';
import type { NoteValueMarketFormData } from '../../slices/noteValueMarketSlice';
import {
    getMarketLastUpdateInfo,
    getMarketNotesValues,
    selectError,
    selectIsLoading,
    selectLastUpdateInfoByMarketId,
    selectNotesValuesByMarketId,
    updateManyNoteValueMarket,
} from '../../slices/noteValueMarketSlice';
import PermissionAwareTextarea from '../../components/PermissionAwareTextarea';
import type { ButtonProps } from '../../components/Button';
import { showFlag } from '../../components/Flag';
import InformationCard from '../../components/InformationCard';
import Text from '../../components/Text';
import Loader from '../../components/Loader';
import GridCol from '../../components/GridCol';
import { colors } from '../../constants/colors';
import { usePermissionsCheck } from '../../hooks/usePermissionsCheck';
import { useAppDispatch } from '../../store';

const LastUpdateInfoWrapper = styled.div`
    display: flex;
    white-space: pre;
`;

type Props = {
    headerProps?: Partial<HeaderProps>;
};

const LastUpdateInfo: React.FC<{ firstName: string; lastName: string; date: string }> = ({
    firstName,
    lastName,
    date,
}): JSX.Element => {
    const { t } = useTranslation();

    return (
        <LastUpdateInfoWrapper>
            <Text color={colors.neutral.N300}>{t('market.notes.lastUpdatedBy')}</Text>
            <Text>
                {firstName} {lastName} {t('market.notes.on')} {format(new Date(date), 'dd/MM/yyyy')}
            </Text>
        </LastUpdateInfoWrapper>
    );
};

const MarketInvoices: React.FC<Props> = ({ headerProps }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { operationId, marketId }: { operationId?: string; marketId?: string } = useParams();

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isPending, setIsPending] = useState<boolean>(false);

    const hasRightToEdit = usePermissionsCheck([{ code: 'MARKETS_EDIT', operationId }]);

    const marketName = useSelector(selectMarketName(Number(marketId)));
    const notes = useSelector(selectNotes);
    const notesValuesMarket = useSelector(selectNotesValuesByMarketId(Number(marketId)));
    const lastUpdateInfo = useSelector(selectLastUpdateInfoByMarketId(Number(marketId)));
    const isLoading = useSelector(selectIsLoading);
    const serverError = useSelector(selectError);

    const notesAndTheirValueMarket = notes.map((note) => {
        const noteValue = notesValuesMarket.find((value) => value.noteId === note.id);
        const newNoteValue = { noteId: note.id, marketId: Number(marketId), content: '', note };
        if (!noteValue) {
            return newNoteValue;
        }
        return noteValue;
    });

    const permissionNeededToEdit = { code: 'MARKETS_EDIT', operationId };

    useEffect(() => {
        if (marketId && marketId !== 'new' && operationId) {
            void dispatch(getMarket({ id: Number(marketId), operationId: Number(operationId) }));
        }
    }, [dispatch, marketId, operationId]);

    useEffect(() => {
        void dispatch(getNotes());
    }, [dispatch]);

    useEffect(() => {
        void dispatch(
            getMarketNotesValues({ operationId: Number(operationId), marketId: Number(marketId) }),
        );
        void dispatch(
            getMarketLastUpdateInfo({
                operationId: Number(operationId),
                marketId: Number(marketId),
            }),
        );
    }, [dispatch, marketId, operationId]);

    let serverErrorMessage = '';
    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    useEffect(() => {
        // Should only handle update and create flags
        if (isPending && !isLoading) {
            if (serverError) {
                showFlag('error', t('errors.error'), serverErrorMessage);
                setIsPending(false);
            } else {
                showFlag('success', t('general.success'), t('market.notesSaved'));
                setIsPending(false);
                setIsEditMode(false);
                void dispatch(
                    getMarketLastUpdateInfo({
                        operationId: Number(operationId),
                        marketId: Number(marketId),
                    }),
                );
            }
        }
    }, [isPending, isLoading, serverError, serverErrorMessage, t, dispatch, operationId, marketId]);

    const notesSchema = Yup.array().of(
        Yup.object().shape({
            name: Yup.string(),
        }),
    );

    type FormValues = { noteValuesMarket: NoteValueMarketFormData[] };

    const computeButtonsOutOfProps = (props: FormikProps<FormValues>): ButtonProps[] => {
        const buttons: ButtonProps[] = [];
        if (hasRightToEdit) {
            if (isEditMode) {
                buttons.push({
                    text: t('general.cancel'),
                    onClick: () => setIsEditMode(false),
                });
                buttons.push({
                    text: t('general.submit'),
                    type: 'submit',
                    aspect: 'primary',
                    isLoading: isPending,
                    onClick() {
                        props.handleSubmit();
                    },
                });
            } else {
                buttons.push({
                    text: t('general.edit'),
                    aspect: 'primary',
                    onClick: () => setIsEditMode(true),
                });
            }
        }
        return buttons;
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not always falsy
    if (isLoading || !notes || !notesValuesMarket) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={{ noteValuesMarket: notesAndTheirValueMarket }}
            validationSchema={notesSchema}
            onSubmit={(formValues: FormValues, actions) => {
                void dispatch(
                    updateManyNoteValueMarket({
                        operationId: Number(operationId),
                        marketId: Number(marketId),
                        notesValues: formValues.noteValuesMarket,
                    }),
                );
                setIsPending(true);
                actions.setSubmitting(false);
            }}
            enableReinitialize
        >
            {(formikProps) => (
                <MainLayout
                    header={
                        <Header
                            {...headerProps}
                            title={!marketName ? t('market.defaultLabel') : marketName}
                            goBackTo={`/operations/${operationId}/markets`}
                            buttons={computeButtonsOutOfProps(formikProps)}
                            showBackButton
                            showNavigation
                        />
                    }
                >
                    <Form>
                        <InformationCard
                            title={t('market.notes.title')}
                            style={
                                isEditMode
                                    ? { padding: '2rem 2rem 1rem 2rem' }
                                    : { padding: '2rem 2rem 1.5rem 2rem' }
                            }
                            headerTrailingComponent={
                                !isEditMode &&
                                lastUpdateInfo && (
                                    <LastUpdateInfo
                                        firstName={lastUpdateInfo.firstName}
                                        lastName={lastUpdateInfo.lastName}
                                        date={lastUpdateInfo.date}
                                    />
                                )
                            }
                            bigHeaderSpace={false}
                        >
                            {formikProps.values.noteValuesMarket.map((noteValueMarket, index) => (
                                <GridCol
                                    key={`${noteValueMarket.marketId}-${noteValueMarket.noteId}`}
                                    defaultScreen={6}
                                    smallScreen={6}
                                >
                                    <PermissionAwareTextarea
                                        editable={isEditMode}
                                        permissionsRequired={[permissionNeededToEdit]}
                                        label={t(`market.notes.names.${noteValueMarket.note.name}`)}
                                        name={`noteValuesMarket[${index}].content`}
                                        value={noteValueMarket.content}
                                        data-testid="comment"
                                        errorTestId="errorComment"
                                        displayStyle={{
                                            whiteSpace: 'unset',
                                            marginTop: '0.75rem',
                                        }}
                                        defaultEmptyValue={t('market.notes.defaultEmptyValue')}
                                        whiteBackground
                                    />
                                </GridCol>
                            ))}
                        </InformationCard>
                    </Form>
                </MainLayout>
            )}
        </Formik>
    );
};

export default MarketInvoices;
