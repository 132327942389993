import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import type { FormikProps, FieldArrayRenderProps } from 'formik';
import { FieldArray } from 'formik';

import type { IEngagementFormData, EngagementType } from '../../slices/engagementSlice';
import { selectEngagement, selectValidatedBudgetLines } from '../../slices/engagementSlice';
import { selectTvaCodes, selectTvaCodesById } from '../../slices/tvaCodeSlice';
import type { BudgetLineForDropdown } from '../../slices/budgetLineSlice';
import type { LabelValue, Label } from '../../slices/labelSlice';
import { getSpecificLabels, selectAllLabelsBy } from '../../slices/labelSlice';
import { selectOperation } from '../../slices/operationSlice';
import { selectMarket } from '../../slices/marketSlice';

import PermissionAwareText from '../PermissionAwareText';
import Text from '../Text';
import GridCol from '../GridCol';
import GridRow from '../GridRow';
import type { Option } from '../SelectField';
import FormikOptionDropdown from '../dropdown/FormikOptionDropdown';
import PermissionAwareDateInputField from '../PermissionAwareDateInputField';
import PermissionAwareDisplay from '../PermissionAwareDisplay';
import PermissionAwareTextarea from '../PermissionAwareTextarea';
import LabelValueDropdown from '../dropdown/LabelValueDropdown';

import { colors } from '../../constants/colors';
import { CurrencyFormatter, PercentageFormatter } from '../../utils/formatters';

import { getMandatoryLabelIds } from '../../utils/mandatoryLabelsValidation';
import { convertWeeksToMonths } from '../../utils/date';
import {
    calculateAdvancePaymentAmountTtc,
    calculateDownPaymentAmountTtc,
    calculateWarrantyTtcAmount,
} from '../../utils/engagementCalculation';
import { selectGroupCodeSequence } from '../../slices/groupCodeSequenceSlice';
import EngagementInternalNumberBadge from '../EngagementInternalNumberBadge';
import Loader from '../Loader';
import useEngagementInternalNumber from '../../hooks/useEngagementInternalNumber';
import { checkIfMarketHasBeenImportedFromEdiflex } from '../../pages/market/Market';
import type { Permission } from '../../slices/authSlice';
import WarrantyAdvance from '../WarrantyAdvance';
import { useAppDispatch } from '../../store';

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 1rem !important;
    border-radius: 10px;
`;

const StyledDivFullWidth = styled(StyledDiv)`
    background-color: ${colors.other.white};
    width: 95%;
    margin: auto;
    padding: 0 1rem 1rem 1rem;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: auto;
    align-items: flex-start;
`;

const StyledDivHalfWidth = styled(StyledDiv)`
    width: 49.5%;
`;

const FirstStyledDivHalfWidth = styled(StyledDivHalfWidth)`
    margin-right: 1rem;
`;

const StyledWhiteDivHalfWidth = styled(StyledDivHalfWidth)`
    background-color: ${colors.other.white};
    padding: 0% 2rem 1rem 2rem;
`;

const StyledGridRow = styled(GridRow)`
    display: flex;
    width: 100%;
    margin-top: 1.25rem !important;
    align-items: flex-end;
`;

const StyledGridRowTop = styled(GridRow)`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 2rem !important;
`;

const StyledText = styled(Text)`
    margin: 0;
`;

type TaxeOption = Option<number | string>;

type GroupCodeOption = Option<string>;

type Props<T> = {
    entityId?: number;
    hasRightToEdit: boolean;
    formikProps: FormikProps<T>;
    permissionsNeeded: Permission[];
};

const EngagementGeneralTab = <B extends Omit<IEngagementFormData, 'priceVariationSettings'>>({
    entityId,
    hasRightToEdit,
    formikProps,
    permissionsNeeded,
}: Props<B>) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { operationId, marketId }: { operationId?: string; marketId?: string } = useParams();

    const market = useSelector(selectMarket(Number(marketId)));
    const engagement = useSelector(selectEngagement(Number(entityId)));
    const operation = useSelector(selectOperation(Number(operationId)));
    const groupCodeSequence = useSelector(selectGroupCodeSequence(operation?.groupCodeSequenceId));
    const tvaCodes = useSelector(selectTvaCodes);
    const tvaCodesById = useSelector(selectTvaCodesById);
    const budgetLinesForDropdown: BudgetLineForDropdown[] = useSelector(selectValidatedBudgetLines);
    const labels = useSelector(
        selectAllLabelsBy('engagement', operation?.clientId, Number(operationId)),
    );

    const isMarketImportedFromEdiflex = market
        ? checkIfMarketHasBeenImportedFromEdiflex(market)
        : false;

    const computeInitialSelectedLabelValues = (engagementLabelValues: LabelValue[]) =>
        engagementLabelValues.map((labelValue) => {
            const labelValueWithLinks = labels
                .find(({ id }) => labelValue.labelId === id)
                ?.labelValues.find(({ id }) => labelValue.id === id);
            const links = labelValueWithLinks?.linkedLabelValues ?? [];
            return {
                ...labelValue,
                hasLinks: links.length > 0,
            };
        });

    const [selectedLabelValues, setSelectedLabelValues] = useState<
        Array<{
            labelId: Label['id'];
            id: LabelValue['id'];
            hasLinks: boolean;
            value: string;
        }>
    >([]);

    useEffect(() => {
        if (operationId && operation) {
            void dispatch(
                getSpecificLabels({
                    type: 'engagement',
                    clientId: operation.clientId,
                    operationId: Number(operationId),
                }),
            );
        }
    }, [dispatch, operationId, operation]);

    useEffect(() => {
        if (engagement?.labelValues) {
            setSelectedLabelValues(computeInitialSelectedLabelValues(engagement.labelValues));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [engagement]);

    const internalNumber = useEngagementInternalNumber(Number(marketId), engagement?.id);

    // Budget line
    const budgetLinesOptions: Array<Option<number>> = budgetLinesForDropdown.map((budgetLine) => ({
        value: budgetLine.id,
        label: `${budgetLine.index} ${budgetLine.label}`,
    }));

    // Engagement type
    const typeOptions: Array<Option<EngagementType>> = [
        { value: 'market', label: t('engagement.market') },
        { value: 'amendment', label: t('engagement.amendment') },
        { value: 'service_order', label: t('engagement.service_order') },
        { value: 'pursuit_descision', label: t('engagement.pursuitDescision') },
        { value: 'other', label: t('engagement.other') },
    ];

    // Group Code Sequence
    let groupCodeOptions: GroupCodeOption[] | null = null;
    if (groupCodeSequence !== undefined) {
        groupCodeOptions = groupCodeSequence.value.split(',').map((item) => ({
            value: item,
            label: item,
        }));
    }

    // TVA Code and taxes
    const taxeOptions: TaxeOption[] = tvaCodes.map((tvaCode, index) => ({
        value: tvaCode.id,
        label: `${index + 1} - ${PercentageFormatter.format(tvaCode.value)}`,
    }));

    const getTvaCodeDisplayLabel = (codeId?: number) => {
        const tvaCode = codeId ? tvaCodesById[codeId] : null;
        const tvaCodeDisplayOption = tvaCode
            ? taxeOptions.find(({ value }) => value === formikProps.values.tvaCodeId)
            : null;
        return tvaCodeDisplayOption ? tvaCodeDisplayOption.label : '';
    };

    const getTvaCodeValue = (codeId?: number) => {
        const tvaCode = codeId ? tvaCodesById[codeId] : null;
        return tvaCode ? tvaCode.value : 0;
    };

    const computeLabelValueDisplay = (
        engagementLabelValues: LabelValue[] | undefined,
        label: Label,
    ) => {
        let nameDisplay = hasRightToEdit ? t('operation.labels.selectValue') : '-';
        if (engagementLabelValues) {
            const labelValue = engagementLabelValues.find(
                (value: LabelValue) => value.labelId === label.id,
            )?.value;
            if (labelValue) {
                nameDisplay = labelValue;
            }
        }
        return nameDisplay;
    };

    const computeLabelError = (labelId: Label['id']) => {
        const mandatoryLabelIds = getMandatoryLabelIds(labels);
        const labelValueId = selectedLabelValues.find(
            (labelValue) => labelValue.labelId === labelId,
        )?.id;
        return mandatoryLabelIds.includes(labelId) && !labelValueId;
    };

    const handleLabelValueChange = (
        arrayHelpers: FieldArrayRenderProps,
        engagementLabelValues: LabelValue[],
        labelValueSelected: LabelValue,
    ) => {
        // formik management
        const index = engagementLabelValues.findIndex(
            (labelValue: LabelValue) => labelValue.labelId === labelValueSelected.labelId,
        );

        if (index >= 0) {
            if (labelValueSelected.id !== 0) {
                arrayHelpers.replace(index, labelValueSelected);
            } else {
                arrayHelpers.remove(index);
            }
        } else {
            if (labelValueSelected.id !== 0) {
                arrayHelpers.push(labelValueSelected);
            }
        }

        // linkedLabelValues management
        const { linkedLabelValues, ...selectedValue } = labelValueSelected;
        const newSelectedValue = {
            ...selectedValue,
            hasLinks: Boolean(
                labelValueSelected.linkedLabelValues &&
                    labelValueSelected.linkedLabelValues.length > 0,
            ),
        };
        const newList = [...selectedLabelValues];
        const selectedValuesListIndex = selectedLabelValues.findIndex(
            (labelValue) => labelValue.labelId === labelValueSelected.labelId,
        );
        if (
            selectedValuesListIndex >= 0 &&
            newList[selectedValuesListIndex].id !== labelValueSelected.id
        ) {
            newList[selectedValuesListIndex] = newSelectedValue;
            setSelectedLabelValues(newList);
        } else if (selectedValuesListIndex < 0) {
            newList.push(newSelectedValue);
            setSelectedLabelValues(newList);
        }
    };

    const calculateTaxesAndAmountTtc = (amountHt: number, tvaCodeId: number) => {
        const taxes = getTvaCodeValue(tvaCodeId) * amountHt;
        const amountTtc = Number(amountHt) + taxes;

        return {
            taxes,
            amountTtc,
        };
    };

    const handleAmountHtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const amountHt = event.target.value ? Number(event.target.value) : 0;
        const amountTtc = calculateTaxesAndAmountTtc(
            amountHt,
            formikProps.values.tvaCodeId,
        ).amountTtc;
        const advancePaymentPercentage = formikProps.values.advancePaymentPercentage
            ? Number(formikProps.values.advancePaymentPercentage)
            : 0;
        const downPaymentPercentage = formikProps.values.downPaymentPercentage
            ? Number(formikProps.values.downPaymentPercentage)
            : 0;
        const holdbackPercentage = formikProps.values.holdbackPercentage
            ? Number(formikProps.values.holdbackPercentage)
            : 0;

        const durationInMonth = convertWeeksToMonths(formikProps.values.durationInWeeks ?? 0);
        const advancePaymentAmountTtc = calculateAdvancePaymentAmountTtc(
            amountTtc,
            advancePaymentPercentage,
            durationInMonth,
        );

        formikProps.setValues({
            ...formikProps.values,
            ...calculateTaxesAndAmountTtc(Number(event.target.value), formikProps.values.tvaCodeId),
            advancePaymentAmountTtc,
            downPaymentAmountTtc: calculateDownPaymentAmountTtc(amountTtc, downPaymentPercentage),
            warrantiesTotalTtc: calculateWarrantyTtcAmount(amountTtc, holdbackPercentage),
        });
    };

    const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const durationInWeeks = event.target.value ? Number(event.target.value) : 0;
        const durationInMonth = convertWeeksToMonths(durationInWeeks);
        const advancePaymentPercentage = formikProps.values.advancePaymentPercentage
            ? Number(formikProps.values.advancePaymentPercentage)
            : 0;
        const downPaymentPercentage = formikProps.values.downPaymentPercentage
            ? Number(formikProps.values.downPaymentPercentage)
            : 0;

        const amountTtc = formikProps.values.amountTtc ? Number(formikProps.values.amountTtc) : 0;
        const advancePaymentAmountTtc = calculateAdvancePaymentAmountTtc(
            amountTtc,
            advancePaymentPercentage,
            durationInMonth,
        );

        formikProps.setValues({
            ...formikProps.values,
            durationInWeeks,
            advancePaymentAmountTtc,
            downPaymentAmountTtc: calculateDownPaymentAmountTtc(amountTtc, downPaymentPercentage),
        });
    };

    const handleTvaCodeChange = (option: TaxeOption) => {
        const durationInMonth = convertWeeksToMonths(formikProps.values.durationInWeeks ?? 0);
        const advancePaymentPercentage = formikProps.values.advancePaymentPercentage
            ? Number(formikProps.values.advancePaymentPercentage)
            : 0;
        const downPaymentPercentage = formikProps.values.downPaymentPercentage
            ? Number(formikProps.values.downPaymentPercentage)
            : 0;

        const amountTtc = formikProps.values.amountHt
            ? calculateTaxesAndAmountTtc(Number(formikProps.values.amountHt), Number(option.value))
                  .amountTtc
            : 0;
        const advancePaymentAmountTtc = calculateAdvancePaymentAmountTtc(
            amountTtc,
            advancePaymentPercentage,
            durationInMonth,
        );
        const downPaymentAmountTtc = calculateDownPaymentAmountTtc(
            amountTtc,
            downPaymentPercentage,
        );
        if (formikProps.values.amountHt) {
            formikProps.setValues({
                ...formikProps.values,
                ...calculateTaxesAndAmountTtc(
                    Number(formikProps.values.amountHt),
                    Number(option.value),
                ),
                tvaCodeId: Number(option.value),
                advancePaymentAmountTtc,
                downPaymentAmountTtc,
            });
        }
    };
    return market ? (
        <>
            <StyledDivFullWidth>
                <StyledGridRowTop data-testid="generalTab">
                    <GridCol defaultScreen={8} smallScreen={8}>
                        <Text type="H500" style={{ width: '100%' }}>
                            {t('engagement.general')}
                        </Text>
                    </GridCol>
                    <GridCol defaultScreen={4} smallScreen={4}>
                        <EngagementInternalNumberBadge internalNumber={internalNumber} />
                    </GridCol>
                </StyledGridRowTop>
                <StyledGridRow>
                    <GridCol defaultScreen={4} smallScreen={4}>
                        <PermissionAwareText
                            marginTop="0"
                            marginBottom="0"
                            editable={hasRightToEdit}
                            disabled={isMarketImportedFromEdiflex}
                            permissionsRequired={permissionsNeeded}
                            label={t('engagement.label')}
                            name="label"
                            type="text"
                            placeholder={t('engagement.label')}
                            data-testid="modalLabel"
                            errorTestId="errorLabel"
                            autoFocus
                            noMarginTop
                            whiteBackground
                        />
                    </GridCol>
                    <GridCol defaultScreen={2} smallScreen={2}>
                        <FormikOptionDropdown
                            items={typeOptions}
                            name="engagementType"
                            disabled={isMarketImportedFromEdiflex}
                            initialNameDiplay={
                                typeOptions.find(
                                    (type) => type.value === formikProps.values.engagementType,
                                )?.label ?? t('engagement.chooseType')
                            }
                            label={t('engagement.type')}
                            data-testid="modalTaxes"
                            errorTestId="errorTaxes"
                            style={{ margin: '0' }}
                        />
                    </GridCol>
                    <GridCol defaultScreen={2} smallScreen={2}>
                        <PermissionAwareText
                            marginTop="0"
                            marginBottom="0"
                            editable={hasRightToEdit}
                            disabled={isMarketImportedFromEdiflex}
                            permissionsRequired={permissionsNeeded}
                            label={t('engagement.externalNumber')}
                            name="externalNumber"
                            type="text"
                            placeholder={t('engagement.externalNumber')}
                            data-testid="externalNumber"
                            errorTestId="errorExternalNumber"
                            noMarginTop
                            whiteBackground
                            isOptional
                        />
                    </GridCol>
                    {market.isJointGroupMarket && groupCodeOptions && (
                        <GridCol defaultScreen={2} smallScreen={2}>
                            <FormikOptionDropdown
                                items={groupCodeOptions}
                                name="groupCode"
                                disabled={isMarketImportedFromEdiflex}
                                initialNameDiplay={
                                    groupCodeOptions.find(
                                        (code) => code.value === formikProps.values.groupCode,
                                    )?.label ?? t('engagement.chooseGroupCode')
                                }
                                label={t('engagement.groupCode')}
                                data-testid="modalCode"
                                errorTestId="errorCode"
                                style={{ margin: '0' }}
                            />
                        </GridCol>
                    )}
                    <GridCol defaultScreen={2} smallScreen={2}>
                        <PermissionAwareText
                            marginTop="0"
                            marginBottom="0"
                            editable={hasRightToEdit}
                            permissionsRequired={permissionsNeeded}
                            label={t('engagement.order')}
                            name="order"
                            type="text"
                            placeholder={t('engagement.order')}
                            data-testid="order"
                            errorTestId="errorOrder"
                            noMarginTop
                            whiteBackground
                        />
                    </GridCol>
                </StyledGridRow>
                <StyledGridRow>
                    <GridCol defaultScreen={2} smallScreen={2}>
                        <PermissionAwareDateInputField
                            editable={hasRightToEdit}
                            disabled={isMarketImportedFromEdiflex}
                            permissionsRequired={permissionsNeeded}
                            name="notificationDate"
                            label={t('engagement.notificationDate')}
                            value={engagement?.notificationDate}
                            data-testid="notificationDate"
                            errorTestId="errorNotificationDate"
                            whiteBackground
                            noMarginTop
                        />
                    </GridCol>
                    <GridCol defaultScreen={2} smallScreen={2}>
                        <PermissionAwareText
                            marginTop="0"
                            marginBottom="0"
                            editable={hasRightToEdit}
                            permissionsRequired={permissionsNeeded}
                            label={t('engagement.durationInWeeks')}
                            onChange={handleDurationChange}
                            name="durationInWeeks"
                            type="text"
                            placeholder={t('engagement.durationInWeeks')}
                            data-testid="durationInWeeks"
                            errorTestId="errorDurationInWeeks"
                            noMarginTop
                            whiteBackground
                        />
                    </GridCol>
                    <GridCol defaultScreen={2} smallScreen={2}>
                        <PermissionAwareText
                            marginTop="0"
                            marginBottom="0"
                            editable={hasRightToEdit}
                            disabled={isMarketImportedFromEdiflex}
                            permissionsRequired={permissionsNeeded}
                            label={t('engagement.amountHt')}
                            name="amountHt"
                            type="text"
                            numberType="amount"
                            placeholder={t('engagement.amountHt')}
                            onChange={handleAmountHtChange}
                            data-testid="amountHt"
                            errorTestId="errorAmountHt"
                            noMarginTop
                            whiteBackground
                        />
                    </GridCol>
                    <GridCol defaultScreen={2} smallScreen={2}>
                        {tvaCodes.length > 0 && (
                            <FormikOptionDropdown
                                items={taxeOptions}
                                name="tvaCodeId"
                                disabled={isMarketImportedFromEdiflex}
                                initialNameDiplay={getTvaCodeDisplayLabel(
                                    formikProps.values.tvaCodeId,
                                )}
                                label={t('budget.taxeCode')}
                                data-testid="modalTaxes"
                                errorTestId="errorTaxes"
                                style={{ margin: '0' }}
                                handleChange={handleTvaCodeChange}
                            />
                        )}
                    </GridCol>
                    <GridCol defaultScreen={2} smallScreen={2}>
                        <PermissionAwareDisplay
                            label={t('engagement.taxes')}
                            value={CurrencyFormatter.format(formikProps.values.taxes)}
                            style={{ marginTop: '0' }}
                            isNumeral
                        />
                    </GridCol>
                    <GridCol defaultScreen={2} smallScreen={2}>
                        <PermissionAwareDisplay
                            label={t('engagement.amountTtc')}
                            value={CurrencyFormatter.format(Number(formikProps.values.amountTtc))}
                            style={{ marginTop: '0' }}
                            isNumeral
                        />
                    </GridCol>
                </StyledGridRow>
                <StyledGridRow>
                    <GridCol defaultScreen={4} smallScreen={4}>
                        {hasRightToEdit && budgetLinesForDropdown.length > 0 ? (
                            <FormikOptionDropdown
                                items={budgetLinesOptions}
                                name="budgetLineId"
                                initialNameDiplay={
                                    budgetLinesOptions.find(
                                        (referenceLine) =>
                                            referenceLine.value === formikProps.values.budgetLineId,
                                    )?.label ?? t('budget.chooseBudgetLine')
                                }
                                label={t('engagement.budgetLine')}
                                data-testid="budgetLineId"
                                errorTestId="errorBudgetLineId"
                                style={{ margin: '0' }}
                                filterable
                            />
                        ) : null}
                    </GridCol>
                    <GridCol defaultScreen={8} smallScreen={8}>
                        <PermissionAwareTextarea
                            editable={hasRightToEdit}
                            permissionsRequired={permissionsNeeded}
                            label={t('engagement.comment')}
                            name="comment"
                            placeholder={t('engagement.comment')}
                            value={engagement?.comment ?? ''}
                            data-testid="comment"
                            errorTestId="errorComment"
                            displayStyle={{
                                whiteSpace: 'unset',
                                marginTop: '0.75rem',
                            }}
                            rows={1}
                            whiteBackground
                            isOptional
                        />
                    </GridCol>
                </StyledGridRow>
            </StyledDivFullWidth>
            <StyledContainer>
                <FirstStyledDivHalfWidth>
                    <WarrantyAdvance
                        hasRightToEdit={hasRightToEdit}
                        formikProps={formikProps}
                        permissionsNeeded={permissionsNeeded}
                    />
                </FirstStyledDivHalfWidth>
                <StyledWhiteDivHalfWidth>
                    <StyledGridRow>
                        <GridCol defaultScreen={12} smallScreen={12}>
                            <StyledText
                                type="H500"
                                style={{ width: '100%', marginBottom: '1.5rem' }}
                            >
                                {t('engagement.engagementLabel')}
                            </StyledText>
                        </GridCol>
                        <FieldArray
                            name="labelValues"
                            render={(arrayHelpers: FieldArrayRenderProps) => (
                                <>
                                    {labels.map((label: Label) => (
                                        <GridCol key={label.id} defaultScreen={11} smallScreen={11}>
                                            {hasRightToEdit ? (
                                                <LabelValueDropdown
                                                    items={label.labelValues}
                                                    label={label.name}
                                                    labelValueDisplay={computeLabelValueDisplay(
                                                        formikProps.values.labelValues,
                                                        label,
                                                    )}
                                                    onItemSelect={(
                                                        labelValueSelected: LabelValue,
                                                    ) =>
                                                        handleLabelValueChange(
                                                            arrayHelpers,
                                                            formikProps.values.labelValues,
                                                            labelValueSelected,
                                                        )
                                                    }
                                                    currentLabelId={label.id}
                                                    selectedLabelValues={selectedLabelValues}
                                                    name="labelValues"
                                                    data-testid={`labelDropdown-${label.id}`}
                                                    error={
                                                        formikProps.touched.labelValues &&
                                                        computeLabelError(label.id)
                                                    }
                                                    isMandatory={label.isMandatory}
                                                />
                                            ) : (
                                                <PermissionAwareDisplay
                                                    label={label.name}
                                                    data-testid={`labelDropdownDisplay-${label.id}`}
                                                    value={computeLabelValueDisplay(
                                                        engagement?.labelValues ?? [],
                                                        label,
                                                    )}
                                                    style={{ marginTop: '0' }}
                                                />
                                            )}
                                        </GridCol>
                                    ))}
                                </>
                            )}
                        />
                    </StyledGridRow>
                </StyledWhiteDivHalfWidth>
            </StyledContainer>
        </>
    ) : (
        <Loader />
    );
};
export default EngagementGeneralTab;
