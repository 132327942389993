import type { PropsWithChildren } from 'react';
import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

import type { IJWTToken } from './slices/authSlice';
import {
    selectIsAdmin,
    selectInactivityLogout,
    selectLoggedOut,
    setUserImpersonating,
} from './slices/authSlice';
import SideBarLayout from './components/SideBarLayout';
import { useAppDispatch } from './store';

type PrivateRouteProps = RouteProps & {
    isAdminRoute?: boolean;
};

const PrivateRoute: React.FC<PropsWithChildren<PrivateRouteProps>> = ({
    isAdminRoute = false,
    children,
    ...rest
}) => {
    const dispatch = useAppDispatch();

    const inactivityLogout = useSelector(selectInactivityLogout);
    const loggedOut = useSelector(selectLoggedOut);
    const isAuthenticatedUserAdmin = useSelector(selectIsAdmin);

    let jwtSeemsValid = false;

    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
        const refreshTokenDecoded: IJWTToken = jwtDecode(refreshToken);

        if (refreshTokenDecoded.exp * 1000 > Date.now()) {
            jwtSeemsValid = true;
            dispatch(setUserImpersonating(refreshTokenDecoded.userImpersonating));
        }
    } else {
        jwtSeemsValid = false;
    }

    let componentToReturn = null;

    if (isAdminRoute && !isAuthenticatedUserAdmin) {
        componentToReturn = <Navigate to={{ pathname: '/' }} />;
    } else if (jwtSeemsValid && !loggedOut) {
        componentToReturn = <SideBarLayout>{children}</SideBarLayout>;
    } else {
        componentToReturn = (
            <Navigate
                to={{ pathname: inactivityLogout ? '/inactivity-logout' : '/login' }}
                // eslint-disable-next-line no-restricted-globals -- necessary
                state={{ from: location }}
            />
        );
    }

    return componentToReturn;
};
export default PrivateRoute;
