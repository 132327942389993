import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import GridCol from './GridCol';
import GridRow from './GridRow';
import Text from './Text';
import Icon from './Icon';

const Container = styled.div`
    border-radius: ${styles.borderRadiusSmall};
    padding: 1.25rem 3rem;
    background-color: ${colors.other.white};
`;

const MainTotal = styled(GridRow)`
    align-items: center;
    &:not(:last-of-type) {
        margin-bottom: 0.625rem;
    }
`;

const IconContainer = styled.div`
    width: 1.5rem;
    margin-right: 0.3125rem;
`;

const GridColLabel = styled(GridCol)`
    display: flex;
    align-items: center;
    padding-left: 0.3125rem !important;
`;

const SubTotal = styled(GridRow)`
    justify-content: flex-end;
    &:not(:last-of-type) {
        margin-bottom: 0.625rem;
    }
`;

const SubTotalLabel = styled(Text)`
    font-weight: 500;
`;

type SubTotal = {
    label: string;
    value: number | string;
};

type Total = {
    label: string;
    value: number | string;
    subtotals?: SubTotal[] | undefined;
    isCollapsable?: boolean | undefined;
    tooltipContent?: string;
};

type CollapseStateByTotalIndex = Record<number, boolean>;

export type Props = {
    totals: Total[];
};

const EngagementInformationCard: React.FC<Props> = ({ totals }) => {
    const [collapseStateByTotalIndex, setCollapseStateByTotalIndex] =
        useState<CollapseStateByTotalIndex>({});
    const totalsRef = useRef(totals);

    useEffect(() => {
        totalsRef.current.forEach((total, totalIndex) => {
            if (total.isCollapsable) {
                setCollapseStateByTotalIndex({ ...collapseStateByTotalIndex, [totalIndex]: false });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [totalsRef]);

    return (
        <Container>
            {totals.map((total, totalIndex) => (
                <React.Fragment key={`${total.label}-${total.value}`}>
                    <MainTotal>
                        <GridColLabel defaultScreen={7} smallScreen={7}>
                            <IconContainer>
                                {total.isCollapsable && (
                                    <Icon
                                        name={
                                            collapseStateByTotalIndex[totalIndex]
                                                ? 'KeyboardArrowUp'
                                                : 'KeyboardArrowDown'
                                        }
                                        onClick={() => {
                                            setCollapseStateByTotalIndex({
                                                ...collapseStateByTotalIndex,
                                                [totalIndex]:
                                                    !collapseStateByTotalIndex[totalIndex],
                                            });
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    />
                                )}
                            </IconContainer>
                            <Text
                                type="H500"
                                style={{
                                    margin: '0',
                                    cursor: total.isCollapsable ? 'pointer' : 'default',
                                }}
                                color={colors.neutral.N500}
                                onClick={() => {
                                    if (total.isCollapsable) {
                                        setCollapseStateByTotalIndex({
                                            ...collapseStateByTotalIndex,
                                            [totalIndex]: !collapseStateByTotalIndex[totalIndex],
                                        });
                                    }
                                }}
                            >
                                {total.label}
                            </Text>
                            {total.tooltipContent && (
                                <Icon
                                    tooltip={total.tooltipContent}
                                    name="Info"
                                    color={colors.neutral.N400}
                                    style={{ marginLeft: '0.5rem' }}
                                />
                            )}
                        </GridColLabel>
                        <GridCol defaultScreen={5} smallScreen={5}>
                            <Text type="H800" style={{ margin: '0', textAlign: 'right' }} isNumeral>
                                {total.value}
                            </Text>
                        </GridCol>
                    </MainTotal>
                    {total.subtotals &&
                        total.subtotals.length > 0 &&
                        collapseStateByTotalIndex[totalIndex] &&
                        total.subtotals.map((subtotal) => (
                            <SubTotal key={`${subtotal.value}-${subtotal.value}`}>
                                <GridColLabel defaultScreen={7} smallScreen={7}>
                                    <IconContainer />
                                    <SubTotalLabel
                                        type="H500"
                                        style={{ margin: '0' }}
                                        color={colors.neutral.N500}
                                    >
                                        {subtotal.label}
                                    </SubTotalLabel>
                                </GridColLabel>
                                <GridCol defaultScreen={5} smallScreen={5}>
                                    <Text
                                        type="H700"
                                        style={{ margin: '0', textAlign: 'right' }}
                                        color={colors.neutral.N300}
                                        isNumeral
                                    >
                                        {subtotal.value}
                                    </Text>
                                </GridCol>
                            </SubTotal>
                        ))}
                </React.Fragment>
            ))}
        </Container>
    );
};

export default EngagementInformationCard;
