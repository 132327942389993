import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';

import type { Operation } from '../slices/operationSlice';
import CardList from './CardList';
import type { TableHeaders } from './Table';
import Table from './Table';
import Text from './Text';
import Tag from './Tag';
import { colors } from '../constants/colors';
import { getOrganizationName } from '../slices/organizationSlice';
import type { LabelValue } from '../slices/labelSlice';
import type { ListViewType } from '../slices/common';
import { selectUser } from '../slices/authSlice';
import UserRolesBadges from './UserRolesBadges';
import type { RenderCellMapType } from './TableRow';

type Props = {
    viewType: ListViewType;
    operations: Operation[];
    showRolesOfAuthUser?: boolean;
    showAffectationType?: boolean;
    locationState?: {
        organizationId?: string;
        goBackTo?: string;
    };
    onRowClick?: (row: Operation | Partial<Operation>) => void;
};

const OperationList: React.FunctionComponent<Props> = ({
    viewType,
    operations,
    showRolesOfAuthUser = false,
    showAffectationType = false,
    locationState,
    onRowClick,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authUser = useSelector(selectUser);

    const headers: TableHeaders = {
        internalNumber: t('operation.list.headers.internalNumber'),
        label: t('operation.list.headers.label'),
        projectOwnerId: t('operation.list.headers.projectOwner'),
        city: t('operation.list.headers.city'),
    };
    if (showRolesOfAuthUser) {
        headers.roles = t('operation.list.headers.roles');
    }
    if (showAffectationType) {
        headers.affectationType = t('operation.list.headers.affectationType');
    }

    const snakeToCamel = (value: string) =>
        value.replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''));

    type RenderCellMapOperations = RenderCellMapType<Operation | Partial<Operation>>;
    const renderCellMap: RenderCellMapOperations = {
        internalNumber: (operationData: Operation | Partial<Operation>) =>
            operationData.internalNumber,
        label: (operationData: Operation | Partial<Operation>) => String(operationData.label),
        projectOwnerId: (operationData: Operation | Partial<Operation>) =>
            getOrganizationName(operationData.projectOwnerOrganization) ?? '-',
        city: (operationData: Operation | Partial<Operation>) =>
            `${operationData.postalCode} ${operationData.city}` || '-',
    };
    if (showRolesOfAuthUser) {
        renderCellMap.roles = (operationData: Operation | Partial<Operation>) => {
            if (authUser?.roles && authUser.roles.length > 0) {
                const authUserRolesOnOperation = authUser.roles.filter(
                    (userRole) => userRole.userRoleOperationId === operationData.id,
                );
                return <UserRolesBadges userRoles={authUserRolesOnOperation} />;
            } else {
                return null;
            }
        };
    }
    if (showAffectationType) {
        renderCellMap.affectationType = (operationData: Operation | Partial<Operation>) =>
            operationData.operationOrganizations && operationData.operationOrganizations.length > 0
                ? t(
                      `operationOrganization.affectationTypes.${operationData.operationOrganizations[0].affectationType}`,
                  )
                : '';
    }

    const mapDataToCardProps = (operationData: Operation | Partial<Operation>) => {
        let city = '-';
        if (operationData.postalCode && operationData.city) {
            city = `${operationData.postalCode}, ${operationData.city}`;
        } else if (operationData.postalCode || operationData.city) {
            city = operationData.postalCode ?? operationData.city ?? '-';
        }
        return {
            headerTitle: String(operationData.label),
            isTooltipOneLine: true,
            bodyData: {
                [String(headers.internalNumber)]: String(operationData.internalNumber),
                [String(headers.city)]: city,
                [String(headers.projectOwnerId)]:
                    getOrganizationName(operationData.projectOwnerOrganization) ?? '-',
            },
            headerTrailingComponent: (
                <Text style={{ marginBottom: '0' }} color={colors.neutral.N500}>
                    {t(
                        `operation.statuses.${
                            operationData.status ? snakeToCamel(operationData.status) : 'missing'
                        }`,
                    )}
                </Text>
            ),
            footerComponent: (
                <>
                    <Text style={{ marginBottom: '0' }} color={colors.neutral.N300}>
                        {t('operation.labels.labels')}:&nbsp;
                    </Text>
                    {operationData.labelValues?.map((labelValue: LabelValue) => (
                        <Tag key={labelValue.value} text={labelValue.value} rounded />
                    ))}
                </>
            ),
        };
    };

    const handleClick = (row: Operation | Partial<Operation>) =>
        navigate(`/operations/${row.id}`, { state: locationState });

    return (
        <>
            {viewType === 'card' && (
                <CardList
                    data={operations}
                    mapDataToCardProps={mapDataToCardProps}
                    onCardClick={handleClick}
                    data-testid="operationCardList"
                />
            )}
            {viewType === 'table' && (
                <Table
                    headers={headers}
                    rows={operations}
                    columnSizes={
                        showRolesOfAuthUser || showAffectationType
                            ? '1fr 2fr 2fr 1.5fr 1fr'
                            : '1fr 2fr 2fr 1.5fr'
                    }
                    renderCellMap={renderCellMap}
                    onRowClick={onRowClick ?? handleClick}
                    data-testid="operationTableList"
                />
            )}
        </>
    );
};

export default OperationList;
