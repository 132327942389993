import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Classes } from '@blueprintjs/core';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import type {
    Engagement,
    EcheancierMode,
    EcheancierEngagement as EcheancierEngagementType,
    IEcheancierEngagementFormData,
} from '../slices/engagementSlice';
import {
    selectError,
    selectEngagement,
    deleteEngagement,
    getEngagements,
    selectIsLoading,
    selectEngagementCreated,
    resetEngagementCreated,
    selectUpdateEngagementFulfilled,
    selectCreateEngagementFulfilled,
    MANUAL_ENTRY,
    selectEcheancierEngagement,
    getEcheancierEngagement,
    selectGetEcheancierEngagementFulfilled,
} from '../slices/engagementSlice';

import { getOperation } from '../slices/operationSlice';
import { selectTvaCodeById } from '../slices/tvaCodeSlice';

import { colors } from '../constants/colors';
import type { IconName } from './Icon';
import Icon from './Icon';
import Text from './Text';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';
import Tooltip from './Tooltip';
import Button from './Button';
import { styles } from '../constants/styles';
import Elevation from './Elevation';
import Popover, { PopoverContent, PopoverItem } from './Popover';
import Loader from './Loader';
import EmptyState from './EmptyState';
import { showFlag } from './Flag';
import Tag from './Tag';
import CollapsableLine from './CollapsableLine';
import Modal from './Modal';
import EngagementModal from './EngagementModal';
import EcheancierEngagement from './echeancier-engagement/EcheancierEngagement';
import {
    CurrencyFormatter,
    formatNumberWithLeadingZeros,
    PercentageFormatter,
} from '../utils/formatters';
import EcheancierEngagementSimulationModal from './echeancier-engagement/EcheancierEngagementSimulationModal';
import { useAppDispatch } from '../store';
import { getExactDecimalValue } from '../utils/calculationMethods';
import {
    calculateDifference,
    calculateSumOfPrevisionsHt,
    computeEcheancesForRecalageEdition,
    formatEcheancesByMonth,
} from './echeancier-engagement/EcheancierEngagementModal';

const LineIndex = styled(Elevation)<{ indexLevel?: string | undefined }>`
    margin-left: ${({ indexLevel }) => (indexLevel ? `calc(1rem * ${indexLevel})` : '0rem')};
`;

const Item = styled.div`
    background-color: ${colors.other.white};
    user-select: none;
    margin: 0 0 0.5rem;
    border-radius: ${styles.borderRadiusSmall};
`;

const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0.5rem 0.5rem 0.5rem 0;
`;

const AddItemLine = styled.div`
    background-color: ${colors.blue.B50};
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 1rem;
    border-radius: ${styles.borderRadiusSmall};
`;

const ContextualMenuIcon = styled(Icon)`
    margin-right: 0;
    cursor: pointer;
`;

const ExpandEngagement = styled.div`
    position: relative;
    width: 0.8125rem;
    height: 2.5rem;
    background-color: ${colors.neutral.N400};
    margin: 0 1.5rem 0 -2.5rem;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const LineText = styled(Text)`
    margin: 0;
`;

const StyledGridCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ExpandedContent = styled.div`
    display: flex;
    width: 100%;
    padding: 0 2rem 1rem;
`;

type Props = {
    id?: number | null;
    // popoverOpen?: Engagement['id']; TODO: check if it's ok to remove
    // handlePopover?: (id: Engagement['id']) => void;
    isFirstEngagement?: boolean;
    marketInternalNumber?: string | null;
    engagementHasOutdatedEcheancier?: boolean;
    openEcheancierModalOnLastCreatedEngagement: boolean;
    setOpenEcheancierModalOnLastCreatedEngagement: (
        openEcheancierModalOnLastCreatedEngagement: boolean,
    ) => void;
};

const EngagementLine: React.FC<Props> = ({
    id,
    isFirstEngagement,
    marketInternalNumber,
    engagementHasOutdatedEcheancier = false,
    openEcheancierModalOnLastCreatedEngagement,
    setOpenEcheancierModalOnLastCreatedEngagement,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { operationId, marketId }: { operationId?: string; marketId?: string } = useParams();
    const [isEngagementModalOpen, setIsEngagementModalOpen] = useState<boolean>(false);
    const [isEcheancierEngagementOpen, setIsEcheancierEngagementOpen] = useState<boolean>(false);
    const [modalOptions, setModalOptions] = useState<{
        title: string;
        text: string;
        confirmationButton: string;
        iconName: IconName;
        iconColor: string;
        callback: () => void;
    } | null>(null);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [expandedLine, setExpandedLine] = useState<boolean>(false);
    const [openEcheancierModalAfterEngagementSaving, setOpenEcheancierModalAfterEngagementSaving] =
        useState<boolean>(false);
    const [isSimulationDone, setIsSimulationDone] = useState(false);
    const [hasDifference, setHasDifference] = useState(false);
    // Selectors
    const serverError = useSelector(selectError);
    const permissionNeededToEditEngagement = { code: 'ENGAGEMENTS_EDIT', operationId };
    const permissionNeededToAddEngagement = { code: 'ENGAGEMENTS_ADD', operationId };
    const permissionNeededToReadEngagement = { code: 'ENGAGEMENTS_READ', operationId };
    const permissionNeededToDeleteEngagement = { code: 'ENGAGEMENTS_DELETE', operationId };
    const hasRightToEditEngagements = usePermissionsCheck([permissionNeededToEditEngagement]);
    const hasRightToAddEngagements = usePermissionsCheck([permissionNeededToAddEngagement]);
    const hasRightToReadEngagements = usePermissionsCheck([permissionNeededToReadEngagement]);
    const hasRightToDeleteEngagements = usePermissionsCheck([permissionNeededToDeleteEngagement]);
    const permissionNeededToReadEcheancierEngagement = {
        code: 'ECHEANCIERS_ENGAGEMENT_READ',
        operationId,
    };
    const hasRightToReadEcheanciersEngagement = usePermissionsCheck([
        permissionNeededToReadEcheancierEngagement,
    ]);

    const engagement = useSelector(selectEngagement(Number(id)));
    const isLoading = useSelector(selectIsLoading);
    const createEngagementFulfilled = useSelector(selectCreateEngagementFulfilled);
    const engagementCreated = useSelector(selectEngagementCreated);
    const updateEngagementFulfilled = useSelector(selectUpdateEngagementFulfilled);
    const engagementTvaCode = useSelector(selectTvaCodeById(Number(engagement?.tvaCodeId)));
    const echeancierEngagement = useSelector(selectEcheancierEngagement(Number(id)));
    const getEcheancierEngagementFulfilled = useSelector(selectGetEcheancierEngagementFulfilled);

    // Messages
    const successMessage = t('general.success');
    const errorMessage = t('errors.error');
    const savedMessage = t('engagement.lineSaved');

    let serverErrorMessage = '';

    useEffect(() => {
        if (id !== 0) {
            void dispatch(
                getEcheancierEngagement({
                    operationId: Number(operationId),
                    marketId: Number(marketId),
                    id: Number(id),
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, []);

    useEffect(() => {
        if (
            getEcheancierEngagementFulfilled &&
            (engagementCreated || engagement) &&
            echeancierEngagement
        ) {
            const difference = engagementHasDifference();
            setHasDifference(difference);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to rerender on every dependencies change
    }, [getEcheancierEngagementFulfilled, engagement, engagementCreated, echeancierEngagement]);

    const engagementHasDifference = () => {
        // For every line, check if there is a price difference
        if (engagement && echeancierEngagement) {
            const echeances = getEcheancesByMonth(
                engagement,
                echeancierEngagement.monthOfLatestEngagementInvoice,
                echeancierEngagement.echeances,
            );
            const sumOfPrevisionsHt = calculateSumOfPrevisionsHt(echeances);
            const difference = calculateDifference(sumOfPrevisionsHt, engagement.amountHt);
            return Boolean(difference);
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (isPending && !isLoading) {
            if (serverError) {
                showFlag('error', errorMessage, serverErrorMessage);
                setIsPending(false);
            } else if (createEngagementFulfilled || updateEngagementFulfilled) {
                showFlag('success', successMessage, savedMessage);
                setIsPending(false);
                setIsEngagementModalOpen(false);
                void dispatch(
                    getEngagements({
                        operationId: Number(operationId),
                        marketId: Number(marketId),
                    }),
                );
                void dispatch(getOperation(Number(operationId)));
            }
        }
    }, [
        createEngagementFulfilled,
        dispatch,
        errorMessage,
        isLoading,
        isPending,
        marketId,
        operationId,
        savedMessage,
        serverError,
        serverErrorMessage,
        successMessage,
        updateEngagementFulfilled,
    ]);

    if (serverError) {
        serverErrorMessage = t(String(serverError));
    }

    if (id && !engagement) {
        return <Loader overlay />;
    }

    const handleExpandClick = () => {
        setExpandedLine(!expandedLine);
    };

    const getEcheancesByMonth = (
        engagementToCheck: Engagement,
        monthOfLatestEngagementInvoice: EcheancierEngagementType['monthOfLatestEngagementInvoice'],
        echeances: EcheancierEngagementType['echeances'],
    ): IEcheancierEngagementFormData['echeances'] =>
        formatEcheancesByMonth(
            computeEcheancesForRecalageEdition(
                monthOfLatestEngagementInvoice,
                engagementToCheck,
                echeances,
            ),
        );

    const handleCloseEcheancierEngagementModal = () => {
        setOpenEcheancierModalAfterEngagementSaving(false);
        setIsEcheancierEngagementOpen(false);
        dispatch(resetEngagementCreated());
        setOpenEcheancierModalOnLastCreatedEngagement(false);
    };

    const handleCloseEngagementModal = () => {
        setIsEngagementModalOpen(false);
    };

    const handleInternalNumber = () => {
        const internalNumber = `${marketInternalNumber}E${
            engagement?.order ? formatNumberWithLeadingZeros(engagement.order, 3) : '-'
        }`;
        return internalNumber;
    };

    const handleEchancierModeDisplay = (mode?: EcheancierMode) =>
        mode === MANUAL_ENTRY ? 'M' : 'A';
    // Information for engagement to be displayed in lines
    const engagementInformationForLine = [
        {
            colWidth: '15%',
            justifyContent: 'center',
            children: <LineText>{handleInternalNumber()}</LineText>,
        },
        ...(engagement?.groupCode
            ? [
                  {
                      colWidth: '5%',
                      justifyContent: 'center',
                      children: <LineText>{engagement.groupCode}</LineText>,
                  },
              ]
            : []),
        {
            colWidth: '10%',
            justifyContent: 'center',
            children: (
                <Tooltip content={engagement ? engagement.label : '-'}>
                    <LineText
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {engagement ? engagement.label : '-'}
                    </LineText>
                </Tooltip>
            ),
        },
        {
            colWidth: '7.5%',
            justifyContent: 'center',
            children: (
                <LineText>
                    {engagement ? format(new Date(engagement.notificationDate), 'dd/MM/yyyy') : '-'}
                </LineText>
            ),
        },
        {
            colWidth: '7.5%',
            justifyContent: 'center',
            children: (
                <LineText>
                    {engagement ? t(`engagement.${engagement.engagementType}`) : '-'}
                </LineText>
            ),
        },
        {
            colWidth: '5%',
            justifyContent: 'center',
            children: engagement && (
                <Tag text={engagement.labelValues ? engagement.labelValues.length : 0} rounded />
            ),
        },
        {
            colWidth: '15%',
            justifyContent: 'flex-end',
            children: (
                <LineText isNumeral>
                    {engagement ? CurrencyFormatter.format(engagement.amountHt) : '-'}
                </LineText>
            ),
        },
        {
            colWidth: '6%',
            justifyContent: 'flex-end',
            children: (
                <LineText isNumeral>
                    {engagement
                        ? PercentageFormatter.format(Number(engagementTvaCode?.value))
                        : '-'}
                </LineText>
            ),
        },
        {
            colWidth: '6%',
            justifyContent: 'center',
            children:
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 0 is a possibility
                engagement?.warrantiesTotalTtc !== null ? (
                    <Icon name="CheckCircle" color={colors.green.G300} />
                ) : (
                    <Icon name="Cancel" color={colors.red.R300} />
                ),
        },
        {
            colWidth: '6%',
            justifyContent: 'center',
            children:
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition  -- 0 is a possibility
                engagement?.advancePaymentAmountTtc !== null ||
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition  -- 0 is a possibility
                engagement.downPaymentAmountTtc !== null ? (
                    <Icon name="CheckCircle" color={colors.green.G300} />
                ) : (
                    <Icon name="Cancel" color={colors.red.R300} />
                ),
        },
        {
            colWidth: '6%',
            justifyContent: 'center',
            children:
                engagement?.priceVariationSettings?.hasActualisation ||
                engagement?.priceVariationSettings?.hasRevision ||
                !engagement?.isSimulated ? (
                    <Icon name="CheckCircle" color={colors.green.G300} />
                ) : (
                    <Icon name="Cancel" color={colors.red.R300} />
                ),
        },
        {
            colWidth: '6%',
            justifyContent: 'flex-start',
            colPaddingLeft: '2.5%',
            children: (
                <>
                    <LineText>{handleEchancierModeDisplay(engagement?.echeancierMode)}</LineText>
                    {(engagementHasOutdatedEcheancier || hasDifference) && (
                        <Tooltip content={t('engagement.list.outdatedEcheancierEngagement')}>
                            <Icon name="Warning" color={colors.yellow.Y400} />
                        </Tooltip>
                    )}
                </>
            ),
        },
    ];

    const handleLineContent = (
        item: {
            colWidth: string;
            justifyContent: string;
            colPaddingLeft?: string;
            children: React.ReactNode;
        },
        key: number,
    ) => (
        <StyledGridCol
            key={key}
            style={{
                width: item.colWidth,
                paddingLeft: item.colPaddingLeft,
                justifyContent: item.justifyContent,
            }}
        >
            {item.children}
        </StyledGridCol>
    );

    const handleDeleteClick = () => {
        if (engagement && hasRightToDeleteEngagements) {
            if (engagement.hasEngagementInvoices) {
                setModalOptions({
                    title: 'engagement.deletion',
                    text: 'engagement.cantDeleteBecauseOfExistingInvoices',
                    confirmationButton: 'engagement.goToListOfInvoices',
                    iconName: 'Cancel',
                    iconColor: colors.red.R500,
                    callback: () =>
                        navigate(`/operations/${operationId}/markets/${marketId}/invoices`),
                });
            } else {
                setModalOptions({
                    title: 'engagement.deletion',
                    text: 'engagement.confirmDeletion',
                    confirmationButton: 'general.confirm',
                    iconName: 'Warning',
                    iconColor: colors.yellow.Y400,
                    callback() {
                        void dispatch(
                            deleteEngagement({
                                id: engagement.id,
                                marketId: Number(marketId),
                                operationId: Number(operationId),
                            }),
                        );
                    },
                });
            }
        }
    };

    if (isFirstEngagement) {
        return (
            <>
                <EmptyState
                    imageName="Lines"
                    data-testid="noEngagementMessage"
                    titleTranslationKey="engagement.noEngagementTitle"
                    button={
                        <Button
                            onClick={() => {
                                setIsEngagementModalOpen(true);
                            }}
                            text={t('engagement.addEngagement')}
                            aspect="primary"
                            size="medium"
                            data-testid="addEngagementBtn"
                        />
                    }
                />
                {isEngagementModalOpen && (
                    <EngagementModal
                        isPending={isPending}
                        setIsPending={setIsPending}
                        isOpen={isEngagementModalOpen}
                        onClose={handleCloseEngagementModal}
                        hasRightToEdit={hasRightToAddEngagements}
                        permissionsNeeded={[permissionNeededToAddEngagement]}
                        setIsEngagementModalOpen={setIsEngagementModalOpen}
                        setOpenEcheancierModalAfterEngagementSaving={
                            setOpenEcheancierModalAfterEngagementSaving
                        }
                        setOpenEcheancierModalOnLastCreatedEngagement={
                            setOpenEcheancierModalOnLastCreatedEngagement
                        }
                        isEngagementCreationModal
                    />
                )}
            </>
        );
    }

    if (!id && !isFirstEngagement) {
        // if id null, then modal will be a creation modal
        if (hasRightToAddEngagements) {
            return (
                <>
                    <AddItemLine
                        data-testid="addEngagementLine"
                        onClick={() => {
                            setIsEngagementModalOpen(true);
                        }}
                    >
                        <Icon
                            name="AddBox"
                            color={colors.green.G400}
                            style={{ marginRight: '1rem' }}
                        />
                        <LineText type="H500" size="0.875rem" color={colors.neutral.N400}>
                            {t('engagement.addEngagement')}
                        </LineText>
                    </AddItemLine>
                    {isEngagementModalOpen && (
                        <EngagementModal
                            isPending={isPending}
                            setIsPending={setIsPending}
                            isOpen={isEngagementModalOpen}
                            onClose={handleCloseEngagementModal}
                            hasRightToEdit={hasRightToAddEngagements}
                            permissionsNeeded={[permissionNeededToAddEngagement]}
                            setIsEngagementModalOpen={setIsEngagementModalOpen}
                            setOpenEcheancierModalAfterEngagementSaving={
                                setOpenEcheancierModalAfterEngagementSaving
                            }
                            setOpenEcheancierModalOnLastCreatedEngagement={
                                setOpenEcheancierModalOnLastCreatedEngagement
                            }
                            isEngagementCreationModal
                        />
                    )}
                </>
            );
        }
        return null;
    }

    const shouldShowVariationsTable =
        engagement && (!engagement.isSimulated || engagement.priceVariationSettings);

    const isOpenFromAnExistingEngagement =
        isEcheancierEngagementOpen ||
        (updateEngagementFulfilled && openEcheancierModalAfterEngagementSaving);

    const isOpenFromACreatedEngagement =
        createEngagementFulfilled &&
        id === engagementCreated?.id &&
        openEcheancierModalOnLastCreatedEngagement &&
        !isEcheancierEngagementOpen;

    return (
        <LineIndex elevation="default">
            <>
                <Item data-testid="EngagementLine">
                    <Line>
                        {hasRightToReadEngagements && shouldShowVariationsTable ? (
                            <ExpandEngagement onClick={handleExpandClick}>
                                <Icon
                                    name={expandedLine ? 'KeyboardArrowDown' : 'KeyboardArrowUp'}
                                    color={colors.other.white}
                                />
                            </ExpandEngagement>
                        ) : null}
                        <Content>
                            {/* All engagement lines are displayed from here */}
                            {engagementInformationForLine.map(handleLineContent)}
                        </Content>
                        {hasRightToReadEngagements && (
                            <Popover>
                                <ContextualMenuIcon name="MoreHoriz" data-testid="popOverOptions" />
                                <Elevation elevation="default">
                                    <PopoverContent>
                                        {hasRightToReadEcheanciersEngagement && (
                                            <PopoverItem
                                                className={Classes.POPOVER_DISMISS}
                                                onClick={() => {
                                                    setIsEcheancierEngagementOpen(true);
                                                }}
                                                data-testid="accessEcheancier"
                                                first
                                            >
                                                {t('engagement.accessEcheancier')}
                                            </PopoverItem>
                                        )}
                                        {hasRightToEditEngagements && (
                                            <PopoverItem
                                                className={Classes.POPOVER_DISMISS}
                                                onClick={() => {
                                                    setIsEngagementModalOpen(true);
                                                }}
                                                data-testid="modifyEngagement"
                                            >
                                                {t('engagement.modifyEngagement')}
                                            </PopoverItem>
                                        )}
                                        {hasRightToDeleteEngagements && (
                                            <PopoverItem
                                                className={Classes.POPOVER_DISMISS}
                                                onClick={handleDeleteClick}
                                                last
                                            >
                                                {t('engagement.deleteEngagement')}
                                            </PopoverItem>
                                        )}
                                    </PopoverContent>
                                </Elevation>
                            </Popover>
                        )}
                    </Line>
                    {expandedLine && engagement && shouldShowVariationsTable ? (
                        <ExpandedContent>
                            <CollapsableLine
                                data={{
                                    headerLine: {
                                        label: t('engagement.variation'),
                                        cells: [
                                            {
                                                openContentLabel: t('engagement.list.amountHt'),
                                                closedContentLabel: `${t(
                                                    'engagement.list.amountHt',
                                                )} : `,
                                                closedContentValue: CurrencyFormatter.format(
                                                    engagement.variationsAmountHt,
                                                ),
                                                width: '25vw',
                                            },
                                            {
                                                openContentLabel: t('engagement.list.amountTtc'),
                                                closedContentLabel: `${t(
                                                    'engagement.list.amountTtc',
                                                )} : `,
                                                closedContentValue: CurrencyFormatter.format(
                                                    engagement.variationsAmountTtc,
                                                ),
                                                width: '25vw',
                                            },
                                        ],
                                    },
                                    subLines: [
                                        {
                                            label: t('engagement.actualisation'),
                                            cells: [
                                                {
                                                    value: CurrencyFormatter.format(
                                                        engagement.actualisationsAmountHt,
                                                    ),
                                                    width: '25vw',
                                                },
                                                {
                                                    value: CurrencyFormatter.format(
                                                        engagement.actualisationsAmountTtc,
                                                    ),
                                                    width: '25vw',
                                                },
                                            ],
                                        },
                                        {
                                            label: t('engagement.revision'),
                                            cells: [
                                                {
                                                    value: CurrencyFormatter.format(
                                                        engagement.revisionsAmountHt,
                                                    ),
                                                    width: '25vw',
                                                },
                                                {
                                                    value: CurrencyFormatter.format(
                                                        engagement.revisionsAmountTtc,
                                                    ),
                                                    width: '25vw',
                                                },
                                            ],
                                        },
                                    ],
                                }}
                            />
                        </ExpandedContent>
                    ) : null}
                </Item>
                {engagement && isEngagementModalOpen && (
                    <EngagementModal
                        isPending={isPending}
                        setIsPending={setIsPending}
                        engagement={engagement}
                        isOpen={isEngagementModalOpen}
                        onClose={handleCloseEngagementModal}
                        hasRightToEdit={hasRightToEditEngagements}
                        permissionsNeeded={[permissionNeededToEditEngagement]}
                        setIsEngagementModalOpen={setIsEngagementModalOpen}
                        setOpenEcheancierModalAfterEngagementSaving={
                            setOpenEcheancierModalAfterEngagementSaving
                        }
                        setIsEcheancierEngagementOpen={setIsEcheancierEngagementOpen}
                        isEngagementCreationModal={false}
                    />
                )}
                {engagement && isOpenFromAnExistingEngagement && (
                    <EcheancierEngagement
                        engagement={engagement}
                        onClose={handleCloseEcheancierEngagementModal}
                    />
                )}

                {engagementCreated && isOpenFromACreatedEngagement && (
                    <EcheancierEngagement
                        engagement={engagementCreated}
                        onClose={handleCloseEcheancierEngagementModal}
                    />
                )}
                {modalOptions && (
                    <Modal
                        isOpen={modalOptions.text !== ''}
                        onCloseButtonPressed={() => setModalOptions(null)}
                        buttons={[
                            {
                                text: t('general.cancel'),
                                aspect: 'secondary',
                                'data-testid': 'cancelDelete',
                                onClick() {
                                    setModalOptions(null);
                                },
                            },
                            {
                                text: t(modalOptions.confirmationButton),
                                aspect: 'primary',
                                'data-testid': 'confirm',
                                onClick: modalOptions.callback,
                            },
                        ]}
                        title={t(modalOptions.title)}
                        iconName={modalOptions.iconName}
                        iconColor={modalOptions.iconColor}
                        size="small"
                        shouldRenderGlobalStyle
                        centerTitle
                    >
                        <Text style={{ margin: '0 0 1rem 0' }}>{t(modalOptions.text)}</Text>
                    </Modal>
                )}
                {engagementCreated &&
                    createEngagementFulfilled &&
                    id === engagementCreated.id &&
                    !isSimulationDone &&
                    !openEcheancierModalOnLastCreatedEngagement && (
                        <EcheancierEngagementSimulationModal
                            operationId={Number(operationId)}
                            marketId={Number(marketId)}
                            engagementId={Number(engagementCreated.id)}
                            onFinishSimulating={() => setIsSimulationDone(true)}
                            disableSimulationCreationDelay
                        />
                    )}
            </>
        </LineIndex>
    );
};

export default EngagementLine;
