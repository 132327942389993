import type { FieldAttributes } from 'formik';
import { Field, useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';
import { styles } from '../constants/styles';
import { StyledLabel } from './PermissionAwareDisplay';
import FieldErrorMessage from './FieldErrorMessage';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.3125rem;
`;

const StyledField = styled(Field)<FieldAttributes<unknown> & { whiteBackground?: boolean }>`
    border: ${({ whiteBackground }) =>
        whiteBackground ? `0.0938rem solid ${colors.neutral.N100}` : 'none'};
    padding: 0.625rem 1rem;
    color: ${colors.blue.B400};
    border-radius: ${styles.borderRadiusSmall};
    background-color: ${({ whiteBackground }) => whiteBackground && String(colors.other.white)};

    ::placeholder {
        color: ${colors.neutral.N200};
    }
    :focus {
        outline: none;
        border: 0.0938rem solid ${colors.green.G400};
    }
    :valid {
        outline: none;
    }
    :-webkit-autofill {
        -webkit-box-shadow: 0 0 0 3.125rem ${colors.other.white} inset;
        -webkit-text-fill-color: ${colors.blue.B400};
    }
`;

export type TextareaProps = {
    name: string;
    label?: string;
    'data-testid'?: string;
    placeholder?: string;
    value?: string | number;
    useTranslation?: boolean;
    whiteBackground?: boolean;
    rows?: number;
    errorTestId?: string;
};

const TextareaField: React.FC<TextareaProps> = ({
    name,
    label,
    value,
    rows = 5,
    errorTestId,
    ...props
}) => {
    const [field, meta] = useField(name);
    const errorExtraCSSProperties = {
        border: `0.0938rem solid ${colors.red.R300}`,
        boxShadow: `0px 0px 0px 0.219rem ${colors.red.R75}`,
    };
    return (
        <Container>
            <StyledLabel color={colors.neutral.N300} style={{ marginBottom: '0.3125rem' }}>
                {label}
            </StyledLabel>
            <StyledField
                as="textarea"
                {...props}
                {...field}
                rows={rows}
                style={meta.error && meta.touched ? errorExtraCSSProperties : {}}
            >
                {value}
            </StyledField>
            {meta.error && meta.touched && (
                <FieldErrorMessage data-testid={errorTestId}>{meta.error}</FieldErrorMessage>
            )}
        </Container>
    );
};

export default TextareaField;
