import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { ServerError } from './common';
import { errorHandler } from './common';
import type { Budget } from './budgetSlice';
import type { Operation } from './operationSlice';

export type BudgetPriceHypothesis = {
    id: number;
    year: number;
    increasingFactor: number | null;
    budgetId: number;
};

export type BudgetPriceHypothesisFormData = {
    id: number;
    year: number;
    increasingFactor: string;
    budgetId: number;
};

interface IBudgetPriceHypothesisState {
    budgetPriceHypothesisById: Record<number, BudgetPriceHypothesis>;
    loading: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    error: string | null;
    selectedBudgetId: number | null;
}

export const initialState: IBudgetPriceHypothesisState = {
    budgetPriceHypothesisById: {},
    loading: false,
    isCreating: false,
    isUpdating: false,
    error: null,
    selectedBudgetId: null,
};

export const getAllBudgetPriceHypothesis = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: BudgetPriceHypothesis[]; operationId: Operation['id']; budgetId: Budget['id'] },
    // First argument to the payload creator
    {
        operationId: Operation['id'];
        budgetId: Budget['id'];
    },
    {
        rejectValue: ServerError;
    }
>('BUDGET_PRICE_HYPOTHESIS/GETALL', async ({ operationId, budgetId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: BudgetPriceHypothesis[] }>(
            `/operations/${operationId}/budgets/${budgetId}/hypotheses`,
        );

        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return { data: response.data.data, operationId, budgetId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.status === 400 || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const createBudgetPriceHypothesis = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    BudgetPriceHypothesis[],
    // First argument to the payload creator
    {
        operationId: Operation['id'];
        budgetId: Budget['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>(
    'BUDGET_PRICE_HYPOTHESIS/POST',
    async ({ operationId, budgetId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(
                `/operations/${operationId}/budgets/${budgetId}/hypotheses`,
            );

            if (!response.data.data) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return response.data.data;
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;

            if (error.response?.status === 400) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const updateBudgetPriceHypothesis = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    BudgetPriceHypothesis,
    // First argument to the payload creator
    {
        budgetPriceHypothesis: BudgetPriceHypothesisFormData;
        operationId: Operation['id'];
        budgetId: Budget['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>(
    'BUDGET_PRICE_HYPOTHESIS/PUT',
    async ({ budgetPriceHypothesis, operationId, budgetId }, { rejectWithValue, dispatch }) => {
        const budgetPriceHypothesisData = {
            ...budgetPriceHypothesis,
            increasingFactor: Number(budgetPriceHypothesis.increasingFactor),
        };

        try {
            const response = await axios.put<{ data?: BudgetPriceHypothesis }>(
                `/operations/${operationId}/budgets/${budgetId}/hypotheses/${budgetPriceHypothesis.id}`,
                budgetPriceHypothesisData,
            );

            if (!response.data.data) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return response.data.data;
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;
            if (error.response?.status === 400) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const slice = createSlice({
    name: 'budgetPriceHypothesis',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get all
        builder.addCase(getAllBudgetPriceHypothesis.pending, (state, { meta }) => {
            if (meta.arg.budgetId !== state.selectedBudgetId) {
                state.budgetPriceHypothesisById = {};
            }
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllBudgetPriceHypothesis.fulfilled, (state, { payload }) => {
            payload.data.forEach((item: BudgetPriceHypothesis) => {
                state.budgetPriceHypothesisById[item.id] = item;
            });
            state.selectedBudgetId = payload.budgetId;
            state.loading = false;
        });
        builder.addCase(getAllBudgetPriceHypothesis.rejected, errorHandler());
        // Create
        builder.addCase(createBudgetPriceHypothesis.pending, (state) => {
            state.loading = true;
            state.isCreating = true;
            state.error = null;
        });
        builder.addCase(createBudgetPriceHypothesis.fulfilled, (state, { payload }) => {
            payload.forEach((item: BudgetPriceHypothesis) => {
                state.budgetPriceHypothesisById[item.id] = item;
            });
            state.loading = false;
            state.isCreating = false;
        });
        builder.addCase(
            createBudgetPriceHypothesis.rejected,
            errorHandler((state) => {
                state.isCreating = false;
            }),
        );
        // Update
        builder.addCase(updateBudgetPriceHypothesis.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateBudgetPriceHypothesis.fulfilled, (state, { payload }) => {
            state.budgetPriceHypothesisById[payload.id] = payload;
            state.loading = false;
        });
        builder.addCase(updateBudgetPriceHypothesis.rejected, errorHandler());
    },
});

export const selectIsCreating = (state: RootState) => state.budgetPriceHypothesis.isCreating;
export const selectError = (state: RootState) => state.budgetPriceHypothesis.error;
export const selectAllBudgetPriceHypothesis = (state: RootState) =>
    Object.values(state.budgetPriceHypothesis.budgetPriceHypothesisById);

// Actions added into the `reducers` part
export const actions = slice.actions;

export default slice.reducer;
